import { roleLabels } from "../data/labels"
import { useData } from "../providers/DataProvider"

/**
 * @param {String} email existing user email
 * @returns {String} user roles in String
 */
export default function getUserRolesString(email) {
  const { users } = useData()

  const userRoles = users.find((u) => u.email === email)?.roles || []
  console.debug("USER ROLES", userRoles)

  let string = "["
  userRoles.forEach((role, index) => (string += `${roleLabels[role]}${index < userRoles.length - 1 ? ", " : "]"}`))
  return string
}
