import React, { useState } from "react"
import { Text, StyleSheet, ScrollView } from "react-native"
import { TextInput } from "react-native-paper"
import { firebase } from "../../firebase/config"
import NextButton from "../../components/NextButton"
import Validation from "../../utils/validation"
import DefaultTheme from "../../themes/DefaultTheme"
import { statusEnum } from "../../data/enums"
import { useStatus } from "../../providers/StatusProvider"
import Screen from "../../components/Screen"

export default function PasswordRenewal({ navigation }) {
  const { setTitle, setStatus, setMessage } = useStatus()
  const [email, setEmail] = useState()

  const handlePasswordRenewal = async () => {
    if (Validation.isEmailValid(email)) {
      setTitle("Obnova hesla na email")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      firebase.apps[0]
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          setMessage(
            `Váš požadavek na změnu hesla byl úspěšně zpracován.\nDalší instrukce vám byly zaslány na váš email: ${email}`
          )
          setStatus(statusEnum.SUCCESS)
        })
        .catch((error) => {
          setMessage(error.message)
          setStatus(statusEnum.ERROR)
        })
      navigation.pop()
    }
  }

  return (
    <Screen>
      <ScrollView contentContainerStyle={{ width: "100%", flexGrow: 1, justifyContent: "center" }}>
        <Text style={styles.footerText}>
          Pro obnovení hesla zadejte Váš email, na který se Vám pošle nové náhodně vygenerované heslo. Následně si heslo
          můžete změnit po přihlášení.
        </Text>
        <TextInput
          error={!Validation.shouldWarningEmail(email)}
          style={DefaultTheme.input}
          label={Validation.shouldWarningEmail(email) ? "Email" : "Vyplňte prosím validní email"}
          placeholderTextColor={DefaultTheme.colors.placeholder}
          placeholder="Váš email"
          onChangeText={(value) => setEmail(value)}
          value={email}
          autoCapitalize="none"
        />
      </ScrollView>
      <NextButton disabled={!Validation.isEmailValid(email)} text="Obnovit heslo" onPress={handlePasswordRenewal} />
    </Screen>
  )
}

const styles = StyleSheet.create({
  footerText: {
    fontSize: DefaultTheme.fonts.regular,
    color: DefaultTheme.colors.black,
    textAlign: "justify",
    paddingHorizontal: DefaultTheme.padding.m,
    paddingVertical: DefaultTheme.padding.s,
  },
})
