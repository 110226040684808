import React, { useEffect, useRef, useState } from "react"
import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from "react-native"
import { TextInput } from "react-native-paper"
import NextButton from "../../components/NextButton"
import AppPicker from "../../components/AppPicker"
import { documentCategoryLabels, paymentLabels } from "../../data/labels"
import {
  documentAccountingTypeEnum,
  scanTypeEnum,
  documentViewTypeEnum,
  scansOverviewViewTypeEnum,
  viewTypeEnum,
  documentCategoryEnum,
} from "../../data/enums"
import Validation from "../../utils/validation"
import { MaterialCommunityIcons, Ionicons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import { useStatus } from "../../providers/StatusProvider"
import { useData } from "../../providers/DataProvider"
import FSStoreContent from "../../firebase/FSStoreContent"
import Screen from "../../components/Screen"
import { formatDecimalNumber } from "../../utils/general"
import AppDatePicker from "../../components/AppDatePicker"
import { CheckBox } from "react-native-elements"
import PlusMinusButtons from "../../components/PlusMinusButtons"
import AppMultipleDataPicker from "../../components/AppMultipleDataPicker"
import DocumentImage from "../../components/DocumentImage"
import { openFileStorage } from "../../actions/platformFeatureRequestor"
import { uploadFileOnFirebase } from "../../firebase/FSGeneral"
import NavigationPicker from "../../components/NavigationPicker"
import ComponentSwitch from "../../components/ComponentSwitch"

export default function AccountingDocumentDetail({ navigation, route }) {
  const { projects, currentUser, accountingDocuments } = useData()
  const viewType = route.params?.viewType
  const [document, setDocument] = useState(route.params.document || {})
  const [imageLoading, setImageLoading] = useState(route.params.document?.type === scanTypeEnum.IMAGE)
  const [plusMinusValues, setPlusMinusValues] = useState(
    document?.accounting_info_internal?.direction === 1 ? [true, false] : [false, true] || []
  )
  const documentRef = useRef({}) // to hold last doc values before navigating to temporary picking page
  const defaultPickedData = accountingDocuments
    .filter(
      (doc) =>
        doc.accounting_info_internal?.type === "ADVANCE" && doc.project === document?.project && doc.id !== document?.id
    )
    .map((doc) => {
      if (document?.accounting_info_internal?.type === documentAccountingTypeEnum.INVOICE) {
        return {
          ...doc,
          isSelected: document?.accounting_info_internal.advance_documents.includes(doc.id),
        }
      } else return { ...doc, isSelected: false }
    })
  const [pickedData, setPickedData] = useState(defaultPickedData)
  const [isAdvance, setIsAdvance] = useState(
    document.accounting_info_internal?.type
      ? document?.accounting_info_internal?.type === documentAccountingTypeEnum.ADVANCE
        ? false
        : true
      : null
  )
  const [manualSupplier, setManualSupplier] = useState(false)

  console.debug("ACC DOC DETAIL", document)
  console.debug("ACC DOC VIEW TYPE", viewType)

  const { setStatus, statusEnum, setTitle, setMessage, updatePercentage } = useStatus()
  const [isUploadingNewImage, setIsUploadingNewImage] = useState(false)
  const logParamsRef = useRef(route.params?.toScreen?.params)

  const appPickerPaymentItems = Object.keys(paymentLabels).map((key) => {
    return { label: paymentLabels[key], value: key, key }
  })

  const appPickerProjectItems = projects.map((project) => {
    return { label: `[${project.id}]-[${project.name}]`, value: project.id, key: project.id }
  })

  const appPickerCategoryItems = Object.keys(documentCategoryLabels).map((key) => ({
    label: documentCategoryLabels[key],
    value: key,
    key,
  }))

  useEffect(() => {
    ;(async () => {
      if (document.id) {
        const updatedDoc = await FSStoreContent.loadDocument(document.id)

        let logProps = {}
        if (viewType === documentViewTypeEnum.VEHICLE_LOG) {
          logProps = { category: documentCategoryEnum.UBYTOVANI, filestore_uri_list: [] }
        }

        setDocument({
          ...(updatedDoc || {
            ...logProps,
            created_by: currentUser.email,
            created_at: new Date(),
            date: new Date(),
          }),
          ...documentRef.current,
        })
      }
    })()
  }, [accountingDocuments])

  useEffect(() => {
    setDocument(route.params?.document || {})
  }, [route.params])

  function changePropertyValue(propName, value) {
    setDocument({ ...document, [propName]: value })
  }

  function handleAdvanceCheck(value) {
    if (isAdvance === null) {
      if (value === 1) {
        setDocument({
          ...document,
          accounting_info_internal: { type: documentAccountingTypeEnum.INVOICE, advance_documents: [] },
        })
        setPickedData(defaultPickedData)
        setIsAdvance(true)
      } else {
        setDocument({
          ...document,
          accounting_info_internal: { type: documentAccountingTypeEnum.ADVANCE, direction: 1 },
        })
        // const tempObj = document
        // delete tempObj.accounting_info_internal
        // setDocument(tempObj)
        setPlusMinusValues(
          document?.accounting_info_internal
            ? document?.accounting_info_internal.direction === 0
              ? [false, true]
              : [true, false]
            : [false, false]
        )

        setIsAdvance(false)
      }
    } else if (isAdvance && value === 1) {
      setDocument({ ...document, accounting_info_internal: {} })

      setPlusMinusValues([])
      setIsAdvance(null)
    } else if (!isAdvance && value === 2) {
      setDocument({ ...document, accounting_info_internal: {} })
      setIsAdvance(null)
    } else {
      setPickedData(defaultPickedData)
      setPlusMinusValues([false, false])
      setDocument({
        ...document,
        accounting_info_internal: { type: documentAccountingTypeEnum.INVOICE, advance_documents: [] },
      })
      setIsAdvance(!isAdvance)
    }
  }

  async function handleChangePdf() {
    try {
      const { localUri } = await openFileStorage()

      if (localUri) {
        setIsUploadingNewImage(true)
        setTitle("Nahrávám pdf dokladu")
        setStatus(statusEnum.PROGRESS_DETERMINED)
        navigation.navigate("StatusScreen")

        const remoteUri = await uploadFileOnFirebase(localUri, updatePercentage, "accountingDocuments")

        setStatus(statusEnum.SUCCESS)
        setMessage("Pdf dokladu byl úspěšně nahrán!")
        setDocument({ ...document, type: scanTypeEnum.FILE, filestore_uri_list: [remoteUri] })
        setIsUploadingNewImage(false)
      }
    } catch (error) {
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  function handleSupplierPick() {
    navigation.navigate("CompanyCatalogOverview", {
      onPickSupplier: (supplier) => {
        documentRef.current = document
        navigation.navigate("AccountingDocumentDetail", {
          ...(route.params || {}),
          document: { ...document, supplier: supplier.name, supplier_id: supplier.id },
        })
      },
      viewType: viewTypeEnum.PICK,
    })
  }

  const updateDocument = async () => {
    if (isNaN(Number(document.price))) {
      throw new Error("Price have to be number")
    }
    let sanitizeDocument = {
      ...document,
      price: Number(document.price),
      updated_by: currentUser.email,
      updated_at: new Date(),
    }

    let docId = document.id

    if (docId) {
      await FSStoreContent.updateAccountingDocument(sanitizeDocument)
    } else {
      const { id } = await FSStoreContent.addAccountingDocument(sanitizeDocument)
      docId = id
    }

    if (viewType === documentViewTypeEnum.VEHICLE_LOG) {
      console.debug("LOG PARAMS REF", logParamsRef.current)
      navigation.navigate("LogDetail", { document: { ...document, id: docId }, ...logParamsRef.current })
    } else {
      if (typeof route.params?.toScreen === "string" && route.params?.toScreen !== "") {
        navigation.navigate(route.params?.toScreen)
      } else if (typeof route.params?.toScreen === "object") {
        navigation.navigate(route.params?.toScreen.screen, route.params?.toScreen.params)
      } else {
        navigation.pop()
      }
    }
  }

  const isSaveDisabled = () => {
    if (document.locked) {
      if (document.accounting_info_internal && Object.keys(document.accounting_info_internal)?.length === 0) {
        return false
      } else {
        return isAdvance === null || (isAdvance && document?.accounting_info_internal?.advance_documents?.length === 0)
        // (isAdvance !== null && !isAdvance && plusMinusValues[0] === plusMinusValues[1])
      }
    } else {
      return (
        !(
          document.payment &&
          Validation.isNumber(document.price) &&
          document.name &&
          document.project &&
          (document.supplier || viewType === documentViewTypeEnum.VEHICLE_LOG) &&
          document.category &&
          !document.locked &&
          (typeof document.date?.getFullYear === "function"
            ? document.date?.getFullYear()
            : document.date?.toDate()?.getFullYear()) > 1980 &&
          document.filestore_uri_list?.length > 0
        ) ||
        (isAdvance && document?.accounting_info_internal?.advance_documents?.length === 0)
        // (isAdvance !== null && !isAdvance && plusMinusValues[0] === plusMinusValues[1])
      )
    }
  }

  return (
    <Screen>
      <View style={{ flex: 1 }}>
        {(imageLoading || document.filestore_uri_list?.length === 0) && (
          <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
            <Text style={{ fontSize: DefaultTheme.fonts.regular }}>
              {document.filestore_uri_list?.length > 0 ? "Načítám obrázek dokladu" : "Nahrajte prosím obrázek dokladu"}
            </Text>
          </View>
        )}
        <View
          style={[
            { flex: 1, overflow: "hidden" },
            (imageLoading || document.filestore_uri_list?.length === 0) && {
              transform: [{ scale: 0 }],
              flex: 0,
            },
          ]}
        >
          {!isUploadingNewImage && (
            <DocumentImage
              onLoadEnd={() => setImageLoading(false)}
              type={document.type}
              uriList={document.filestore_uri_list}
            />
          )}
        </View>
        {!imageLoading && (
          <View style={{ position: "absolute", width: "100%" }}>
            <TouchableOpacity
              style={styles.imageRetake}
              onPress={() => {
                navigation.navigate("ScansOverview", {
                  viewType:
                    viewType === documentViewTypeEnum.VEHICLE_LOG
                      ? documentViewTypeEnum.VEHICLE_LOG
                      : scansOverviewViewTypeEnum.DOC_CHANGE,
                  uriList: document.type === scanTypeEnum.FILE ? [] : document.filestore_uri_list,
                  document,
                })
              }}
              disabled={document.locked}
            >
              <Ionicons
                name="documents-sharp"
                size={DefaultTheme.icons.l}
                color={document.locked ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary}
              />
            </TouchableOpacity>
            <TouchableOpacity style={styles.fileSystemRetake} onPress={handleChangePdf} disabled={document.locked}>
              <MaterialCommunityIcons
                name="file-pdf-box"
                size={DefaultTheme.icons.l}
                color={document.locked ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary}
              />
            </TouchableOpacity>
          </View>
        )}
      </View>
      <ScrollView style={{ flex: 1 }}>
        <TextInput
          error={!document.name}
          style={[DefaultTheme.input, { marginTop: 10 }]}
          label={document.name ? "Název dokladu" : "Zadejte název dokladu"}
          onChangeText={(value) => changePropertyValue("name", value)}
          value={document.name}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
          disabled={document.locked}
        />
        <TextInput
          error={!Validation.isNumber(document.price)}
          style={DefaultTheme.input}
          label={Validation.isNumber(document.price) ? "Cena v Kč s DPH" : "Zadejte cenu v Kč s DPH"}
          keyboardType="numeric"
          onChangeText={(value) => changePropertyValue("price", formatDecimalNumber(value))}
          value={document.price?.toString()}
          disabled={document.locked}
        />

        <AppDatePicker
          label="Datum dokladu"
          webLabel={["Den vystavení dokladu", "Měsíc vystavení dokladu", "Rok vystavení dokladu"]}
          value={document.date}
          onConfirm={(date) => changePropertyValue("date", date || new Date())}
          disabled={document.locked}
        />
        <AppPicker
          value={document.project}
          descriptionLabel="Projekt"
          placeholder={{ label: "Vyberte projekt", value: "" }}
          onValueChange={(value) => changePropertyValue("project", value)}
          items={appPickerProjectItems}
          disabled={document.locked}
        />
        <CheckBox
          containerStyle={DefaultTheme.checkBoxContainer}
          size={30}
          onPress={() => setManualSupplier(!manualSupplier)}
          title="Chci zadat dodavatele ručně"
          checkedColor={document.locked ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary}
          checked={manualSupplier}
          disabled={document.locked}
        />
        {manualSupplier ? (
          <TextInput
            error={!document.supplier}
            style={DefaultTheme.input}
            label={document.supplier ? "Dodavatel" : "Zadejte dodavatele"}
            onChangeText={(value) => {
              delete document.supplier_id
              changePropertyValue("supplier", value)
            }}
            value={document.supplier}
            disabled={document.locked}
          />
        ) : (
          <NavigationPicker
            description={!!document.supplier && "Dodavatel"}
            onPress={handleSupplierPick}
            error={!document.supplier}
            label={document.supplier || "Vyberte dodavatele"}
            disabled={document.locked}
          />
        )}

        <AppPicker
          value={document.category}
          descriptionLabel="Kategorie"
          placeholder={{ label: "Vyberte kategorii", value: "" }}
          onValueChange={(value) => changePropertyValue("category", value)}
          items={appPickerCategoryItems}
          disabled={document.locked}
        />
        <AppPicker
          value={document.payment}
          descriptionLabel="Způsob platby"
          placeholder={{ label: "Vyberte způsob platby", value: "" }}
          onValueChange={(value) => changePropertyValue("payment", value)}
          items={appPickerPaymentItems}
          disabled={document.locked}
        />
        <View style={styles.invoiceCheckWrapper}>
          <TouchableOpacity
            style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            onPress={() => handleAdvanceCheck(1)}
          >
            <Text>Připojit k dokladu zálohovou fakturu</Text>
            <CheckBox checked={isAdvance === null ? false : isAdvance} onPress={() => handleAdvanceCheck(1)} />
          </TouchableOpacity>
          {isAdvance !== null && isAdvance && (
            <AppMultipleDataPicker
              document={document}
              setDocument={setDocument}
              pickedData={pickedData}
              setPickedData={setPickedData}
            />
          )}
        </View>
        <View style={styles.invoiceCheckWrapper}>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onPress={() => handleAdvanceCheck(2)}
          >
            <Text>Označit jako zálohovou fakturu</Text>
            <CheckBox checked={isAdvance === null ? false : !isAdvance} onPress={() => handleAdvanceCheck(2)} />
          </TouchableOpacity>
          {/* {isAdvance !== null && !isAdvance && (
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", paddingBottom: 10 }}>
              <Text style={{ paddingRight: 10 }}>Výdejová</Text>
              <PlusMinusButtons
                plusMinusValues={plusMinusValues}
                setPlusMinusValues={setPlusMinusValues}
                document={document}
                setDocument={setDocument}
              />
              <Text style={{ paddingLeft: 10 }}>Příjmová</Text>
            </View>
          )} */}
        </View>
      </ScrollView>
      <NextButton disabled={isSaveDisabled()} text="Uložit" onPress={updateDocument} />
    </Screen>
  )
}

const styles = StyleSheet.create({
  imageRetake: {
    position: "absolute",
    left: 10,
    top: 10,
  },
  invoiceCheckWrapper: {
    backgroundColor: DefaultTheme.colors.white,
    marginBottom: 10,
    paddingLeft: 20,
    minHeight: 70,
    marginHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.light,
    justifyContent: "center",
  },
  fileSystemRetake: {
    position: "absolute",
    right: 10,
    top: 10,
  },
})
