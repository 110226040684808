import React, { useEffect, useState } from "react"
import { FlatList, Text, View } from "react-native"

import { useData } from "../../providers/DataProvider"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import FSStoreContent from "../../firebase/FSStoreContent"
import AppPicker from "../../components/AppPicker"
import Screen from "../../components/Screen"
import NextButton from "../../components/NextButton"
import {
  projectStateEnum,
  propertyViewTypeEnum,
  statusEnum,
  userStateEnum,
  viewTypeEnum,
  userRoleEnum,
  propertyOperationTypeEnum,
  approveEnum,
} from "../../data/enums"
import FlatListPropertyItem from "../../components/FlatLists/FlatListPropertyItem"
import { useStatus } from "../../providers/StatusProvider"
import { useNavigation } from "@react-navigation/native"
import { isAdmin, isExecutive, isOfficeManager, isProjectManager, isTechnicalDirector } from "../../utils/validation"

export default function ProjectProperty({ route }) {
  const { users, setProperty, currentUser } = useData()
  const { setTitle, setStatus, setMessage } = useStatus()
  const navigation = useNavigation()
  const [projectPropertyList, setProjectPropertyList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const viewType = route.params.viewType || propertyViewTypeEnum.PROJECT
  const project = route.params.project
  const isResponsibleUserDeleted =
    users.filter((u) => u.email === project.property_responsible_user).state === userStateEnum.DELETED
  const [responsibleUser, setResponsibleUser] = useState(
    (!isResponsibleUserDeleted && project.property_responsible_user) || ""
  )

  const hasEditPermission =
    isAdmin(currentUser) ||
    isExecutive(currentUser) ||
    isTechnicalDirector(currentUser) ||
    isProjectManager(currentUser) ||
    isOfficeManager(currentUser)

  console.debug(
    "USERS",
    users.filter((u) => project.users?.some((us) => us.email === u.email))
  )
  console.debug("VIEW TYPE", viewType)

  const appPickerUserItems = users
    .filter(
      (u) =>
        project.users?.some((us) => us.email === u.email) &&
        u.state !== userStateEnum.DELETED &&
        !u.roles.includes(userRoleEnum.INVESTOR)
    )
    .map((user) => {
      return { label: `${user.name} - ${user.email}`, value: user.email }
    })

  useEffect(() => {
    ;(async () => {
      const prop = await FSStoreContent.onSnapshotProperty(setProperty)
      setProjectPropertyList(prop.filter((p) => p.assigned_project === project.id))
      setIsLoading(false)
    })()
  }, [])

  async function handleSave() {
    try {
      console.debug("PROP SAVE", projectPropertyList)
      setTitle("Upravuji majetek projektu")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")
      await FSStoreContent.updateProject({ ...project, property_responsible_user: responsibleUser })
      for (const prop of projectPropertyList) {
        console.debug("Property upload: ", prop)
        if (
          prop?.operations &&
          prop?.operations.length > 0 &&
          prop.operations.some(
            (op) =>
              op.action_type === propertyOperationTypeEnum.STATE_RETURNED && op.action_value === approveEnum.ACCEPTED
          )
        ) {
          const tempProp = { ...prop }
          delete tempProp.assigned_project
          await FSStoreContent.uploadProperty(tempProp, false)
        } else {
          await FSStoreContent.uploadProperty(prop)
        }
      }
      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  function HeaderComponent() {
    return (
      <>
        {project.state === projectStateEnum.CLOSED && (
          <Text style={{ paddingHorizontal: 25 }}>Pro provádění změn je potřeba projekt odemknout!</Text>
        )}
        <AppPicker
          value={responsibleUser}
          descriptionLabel="Odpovědná osoba"
          placeholder={{ label: "Vyberte zodpovědnou osobu", value: "" }}
          onValueChange={(value) => setResponsibleUser(value)}
          items={appPickerUserItems}
          disabled={project.state === projectStateEnum.CLOSED}
        />
      </>
    )
  }

  return (
    <Screen>
      <View style={{ flex: 1, paddingTop: viewType == viewTypeEnum.DIARY ? 0 : 10 }}>
        {projectPropertyList.length > 0 ? (
          <FlatList
            data={projectPropertyList}
            ItemSeparatorComponent={FlatListSeparator}
            ListHeaderComponent={viewType !== viewTypeEnum.DIARY && <HeaderComponent />}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => (
              <FlatListPropertyItem
                project={project}
                projectPropertyList={projectPropertyList}
                prop={item}
                setProjectPropertyList={setProjectPropertyList}
                viewType={viewType}
                onPress={route.params.onPress}
              />
            )}
          />
        ) : (
          <AppEmptyScreenView
            message={isLoading ? "Načítám majetek projektu" : "K projektu není přiřazen žádný majetek"}
          />
        )}
      </View>
      {viewType !== viewTypeEnum.DIARY && (
        <NextButton
          disabled={!responsibleUser || !hasEditPermission || project.state === projectStateEnum.CLOSED}
          onPress={handleSave}
          text="Uložit"
        />
      )}
    </Screen>
  )
}
