import React, { useEffect, useState } from "react"
import { Text, TouchableOpacity, View, StyleSheet } from "react-native"
import { STATE_VIEW_PARAMS_PREMIUM, STATE_VIEW_PARAMS_USER } from "../../data/params"
import DefaultTheme from "../../themes/DefaultTheme"
import { Ionicons } from "@expo/vector-icons"
import { useData } from "../../providers/DataProvider"
import { documentViewTypeEnum } from "../../data/enums"

export default function FlatListDocumentsUserItem({ navigation, user }) {
  const { companyDocuments } = useData()
  const userCompanyDocs = companyDocuments.filter((doc) => doc.belongs_to === user.email)

  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate("DocumentsOverview", {
          title: `Dokumenty uživatele ${user.name}`,
          belongsTo: user.email,
          viewType: documentViewTypeEnum.USER,
        })
      }
      style={[styles.card, { borderLeftColor: STATE_VIEW_PARAMS_PREMIUM[user.disabled].color }]}
    >
      <View style={{ paddingRight: 15 }}>
        <Ionicons {...STATE_VIEW_PARAMS_USER[user.disabled]} style={{ paddingLeft: 1 }} />
      </View>
      <View style={{ flex: 1 }}>
        <Text>{user.name}</Text>
        <Text>{user.email}</Text>
      </View>
      <View style={{ flex: 1, justifyContent: "flex-end", alignItems: "center", flexDirection: "row" }}>
        <Text style={{ paddingRight: 5 }}>{`${userCompanyDocs.length} ks`}</Text>
        <Ionicons
          name="document-text"
          color={user.disabled ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary}
          size={DefaultTheme.icons.flatlistSize}
        />
      </View>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  card: {
    height: 100,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingHorizontal: 15,
    flexDirection: "row",
    borderLeftWidth: 10,
    backgroundColor: DefaultTheme.colors.white,
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
})
