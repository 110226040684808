import React, { useEffect, useState } from "react"
import { View, StyleSheet } from "react-native"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import { TextInput } from "react-native-paper"
import NextButton from "../../components/NextButton"
import DefaultTheme from "../../themes/DefaultTheme"
import { useStatus } from "../../providers/StatusProvider"
import { statusEnum } from "../../data/enums"
import FSStoreContent from "../../firebase/FSStoreContent"

export default function TriviAPICredentials({ navigation }) {
  const { settings } = useData()
  const { setStatus, setTitle, setMessage } = useStatus()
  const [triviSettings, setTriviSettings] = useState({ id: "trivi", api_id: "", api_secret: "" })

  const updateSettings = async () => {
    navigation.navigate("StatusScreen")
    setTitle("Nahrávam Trivi credentials")
    setStatus(statusEnum.PROGRESS_UNDETERMINED)

    Promise.resolve(FSStoreContent.updateSettings(triviSettings))
      .then(() => {
        setMessage("Trivi credentials byly nahrané úspěšně!")
        setStatus(statusEnum.SUCCESS)

        navigation.pop()
      })
      .catch((err) => {
        setMessage(err.message)
        setStatus(statusEnum.ERROR)
      })
  }

  useEffect(() => {
    setTriviSettings(settings.trivi || { id: "trivi", api_id: "", api_secret: "" })
  }, [settings])

  return (
    <Screen>
      <View style={styles.inputsWrapper}>
        <TextInput
          error={!triviSettings.api_id}
          style={[DefaultTheme.input, { marginVertical: 10 }]}
          label={triviSettings.api_id ? "API id" : "Zadejte API id"}
          onChangeText={(value) => setTriviSettings({ ...triviSettings, api_id: value })}
          value={triviSettings.api_id}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          error={!triviSettings.api_secret}
          style={DefaultTheme.input}
          label={triviSettings.api_secret ? "API secret" : "Zadejte API secret"}
          onChangeText={(value) => setTriviSettings({ ...triviSettings, api_secret: value })}
          value={triviSettings.api_secret}
        />
      </View>
      <NextButton
        disabled={!(triviSettings.api_id && triviSettings.api_secret)}
        text="Uložit"
        onPress={updateSettings}
      />
    </Screen>
  )
}

const styles = StyleSheet.create({
  inputsWrapper: {
    flex: 1,
  },
})
