import React from "react"
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { MaterialIcons, MaterialCommunityIcons, FontAwesome5, FontAwesome } from "@expo/vector-icons"

import DefaultTheme from "../../themes/DefaultTheme"
import { toCurrentDate } from "../../utils/general"
import { useNavigation } from "@react-navigation/native"
import { itemStateEnum, diaryFlatListItem } from "../../data/enums"
import WeatherIcons from "../../utils/WeatherIcons"

export default function FlatListProjectDiaryItem({
  item: {
    date_interval,
    attachments,
    construction_work,
    material,
    mechanization,
    records,
    users,
    weather,
    id,
    state,
    selected = false,
  },
  projectId,
  type,
  onPress,
}) {
  const navigation = useNavigation()
  const isEntryClosed = state === itemStateEnum.CLOSED
  const styles = getStyles()

  const items = [
    [
      {
        icon: <FontAwesome5 name="people-carry" size={DefaultTheme.icons.flatlistSize} />,
        count: users?.length || 0,
      },
      {
        icon: <FontAwesome name="gears" size={DefaultTheme.icons.flatlistSize} />,
        count: mechanization?.length || 0,
      },
    ],
    [
      {
        icon: <MaterialCommunityIcons name="layers-triple" size={DefaultTheme.icons.flatlistSize} />,
        count: material?.length || 0,
      },
      {
        icon: <MaterialCommunityIcons name="hammer-wrench" size={DefaultTheme.icons.flatlistSize} />,
        count: construction_work?.length || 0,
      },
    ],
    [
      {
        icon: <MaterialIcons name="list-alt" size={DefaultTheme.icons.flatlistSize} />,
        count: records?.length || 0,
      },
      {
        icon: <MaterialCommunityIcons name="attachment" size={DefaultTheme.icons.flatlistSize} />,
        count: attachments?.length || 0,
      },
    ],
  ]

  const itemIcons = () => {
    return (
      <View style={{ flexDirection: "column" }}>
        {items.map((el, index) => {
          const first = el[0]
          const second = el[1]
          return (
            <View
              style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}
              key={"row" + index}
            >
              <View key={"icon" + index + "0"} style={{ padding: 5 }}>
                {first.icon}
                <Text style={{ paddingLeft: 10 }}>{first.count}</Text>
              </View>
              <View key={"icon" + index + "1"} style={{ padding: 5 }}>
                {second.icon}
                <Text style={{ paddingLeft: 10 }}>{second.count}</Text>
              </View>
            </View>
          )
        })}
      </View>
    )
  }

  if (type === diaryFlatListItem.BULK_SIGNATURE) {
    return (
      <TouchableOpacity
        style={[
          styles.card,
          ,
          { borderLeftColor: !selected ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary },
        ]}
        onPress={onPress}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ justifyContent: "center", alignItems: "center", paddingRight: 15 }}>
            <MaterialIcons
              name="list-alt"
              size={DefaultTheme.icons.flatlistSize + 4}
              color={!selected ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary}
            />
          </View>
          <View>
            <Text>Denní zápis</Text>
            <Text>
              {toCurrentDate(date_interval.from)} - {toCurrentDate(date_interval.to)}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    )
  }
  return (
    <TouchableOpacity
      style={[
        styles.card,
        { borderLeftColor: isEntryClosed ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary },
      ]}
      onPress={() => navigation.navigate("DiaryEntryDetail", { projectId, diaryEntryId: id })}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={{ justifyContent: "center", alignItems: "center", paddingRight: 15 }}>
          <MaterialIcons
            name="list-alt"
            size={DefaultTheme.icons.flatlistSize + 4}
            color={isEntryClosed ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary}
          />
          {isEntryClosed && (
            <FontAwesome name="lock" color={DefaultTheme.colors.disabled} size={DefaultTheme.icons.flatlistSize} />
          )}
        </View>
        <View>
          <Text>Denní zápis</Text>
          <Text>
            {toCurrentDate(date_interval.from)} - {toCurrentDate(date_interval.to)}
          </Text>
          <View style={{ flexDirection: "row", alignItems: "center", paddingTop: 10 }}>
            {weather.hours ? (
              weather.hours.map((h) => (
                <View key={h.datetime} style={{ paddingRight: 10, alignItems: "center" }}>
                  <Text>{h.datetime.slice(0, -3)}</Text>
                  <Image source={WeatherIcons[h.icon]} style={{ height: 24, width: 24 }} />
                </View>
              ))
            ) : (
              <Text style={{ paddingLeft: 10 }}>Počasí: {weather.value || "neuvedeno"}</Text>
            )}
          </View>
        </View>
      </View>
      {itemIcons()}
      {/* <View>
        {items.map(({ icon, count }, index) => (
          <View
            key={index}
            style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center", paddingVertical: 5 }}
          >
            {icon}
            <Text style={{ paddingLeft: 10 }}>{count}</Text>
          </View>
        ))}
      </View> */}
    </TouchableOpacity>
  )
}

function getStyles() {
  return StyleSheet.create({
    card: {
      minHeight: 100,
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      paddingHorizontal: 15,
      paddingVertical: 5,
      backgroundColor: DefaultTheme.colors.white,
      borderLeftWidth: 10,
      flexDirection: "row",
    },
  })
}
