import Constants from "expo-constants"

import { firebase } from "../firebase/config"
import { getWeatherUrl } from "../utils/backendUrls"

export async function getWeather(weather, location, configId) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()
    console.debug("TOKEN", token)

    const url = getWeatherUrl(configId)
    console.debug("url", url)

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        date: weather.date,
        latitude: location.latitude,
        longitude: location.longitude,
      }),
    })

    if (response.status !== 200) {
      console.debug("RES", response.status)
      throw await response.json()
    }

    return await response.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}
