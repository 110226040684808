import React from "react"
import { KeyboardAvoidingView, StyleSheet, Platform } from "react-native"
import { useHeaderHeight } from "@react-navigation/elements"
import FocusAwareStatusBar from "./FocusAwareStatusBar"
import { useData } from "../providers/DataProvider"

export default function Screen({ children, style, ...otherProps }) {
  const { currentUser } = useData()
  return (
    <KeyboardAvoidingView
      style={[Platform.OS !== "web" ? styles.container : styles.containerWeb, style]}
      behavior={Platform.OS === "android" ? undefined : "padding"}
      keyboardVerticalOffset={useHeaderHeight() + (currentUser.email ? 44 : 0)} // TEMPORARY REPORTING (currentUser.email ? 68 : 0)
      // keyboardVerticalOffset={useHeaderHeight() + (currentUser.email ? 78 : 0)} // TEMPORARY REPORTING (currentUser.email ? 68 : 0)
      collapsable
    >
      {Platform.OS === "android" ? (
        <FocusAwareStatusBar />
      ) : (
        <FocusAwareStatusBar barStyle="dark-content" {...otherProps} />
      )}
      {children}
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  containerWeb: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "#F8F8F8",
  },
})
