import React from "react"
import AppPicker from "./AppPicker"
import { View } from "react-native"

export default function DayPicker({ value, callback, style, extra = undefined }) {
  const dayItems = Array.from(new Array(31), (x, i) => i).map((el) => {
    return { value: (el + 1).toString(), label: (el + 1).toString() }
  })

  return (
    <View style={[{ flexDirection: "row" }, style]}>
      <View style={{ flex: 1 }}>
        <AppPicker
          descriptionLabel="Den"
          value={value.toString()}
          onValueChange={(value) => callback(value, "day", extra)}
          items={dayItems}
          style={{ flex: 1 }}
        />
      </View>
    </View>
  )
}
