import React, { useState, useEffect, useMemo, useRef } from "react"
import { View, Text, TouchableOpacity, Dimensions, ActivityIndicator, Platform, ScrollView, Alert } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import { cashFlowTypeEnum } from "../../data/enums"
import {
  VictoryChart,
  VictoryLine,
  VictoryBar,
  VictoryAxis,
  VictoryLabel,
  VictoryScatter,
  VictoryContainer,
} from "victory-native"
import { monthNameLabels } from "../../data/labels"
import StateDependantButton from "../StateDependantButton"
import CurrencyFormatter from "../../components/CurrencyFormatter"
import { getCashflowChartData } from "../../utils/backendUrls"
import Constants from "expo-constants"
import { useData } from "../../providers/DataProvider"
import { firebase } from "../../firebase/config"

export default function CashflowDashboard({}) {
  const [pickedFreq, setPickedFreq] = useState(cashFlowTypeEnum.MONTHLY)
  const [dimensions, setDimensions] = useState({
    width: Dimensions.get("screen").width,
    height: Dimensions.get("screen").height,
  })
  const [chartData, setChartData] = useState(null)
  const [pressedComp, setPressedComp] = useState("")
  const dimensionsHandler = useRef(null)
  const [axesTicks, setAxesTicks] = useState([])
  const { firebaseConfig } = useData()

  useEffect(() => {
    dimensionsHandler.current = Dimensions.addEventListener("change", ({ _, screen }) => {
      setDimensions({ width: screen.width, height: screen.height })
    })
    return () => {
      if (dimensionsHandler.current) dimensionsHandler.current.remove()
    }
  }, [])

  useEffect(() => {
    const getData = async () => {
      const token = await firebase.apps[0].auth().currentUser.getIdToken()
      const url = getCashflowChartData(firebaseConfig)

      const dataReq = await fetch(
        `${url}?type=${pickedFreq}&extra=${JSON.stringify({ year: new Date().getFullYear() })}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
      const chart_data = await dataReq.json()
      console.debug("BE RESPONSE: ", chart_data)
      const yValues = [...chart_data.inc, ...chart_data.out, ...chart_data.yValues]
      const max = Math.max(...yValues)
      const min = Math.min(...yValues)

      setChartData({ ...chart_data, domain: [min, max] })
    }
    getData()
  }, [pickedFreq])

  const handlePress = (type, data, dir) => {
    console.debug(data)
    let timeFreqLabel = chartData.chartLabels[data.index]
    if (pickedFreq === cashFlowTypeEnum.MONTHLY) {
      timeFreqLabel = `${monthNameLabels[chartData.chartLabels[data.index]]}`
    }
    if (pickedFreq === cashFlowTypeEnum.QUARTERLY) {
      timeFreqLabel = `${chartData.chartLabels[data.index]}`
    }

    let newStr = ""
    switch (type) {
      case "POINT":
        newStr = `Zůstatek na účtu na začátku: `
        break
      case "BAR":
        newStr = `${dir ? "Příjmy" : "Výdaje"}: `
        break
    }
    newStr += `${timeFreqLabel} ${new Intl.NumberFormat("cs-CZ").format(data.datum._y)}`
    setPressedComp(newStr)
  }

  return !chartData ? (
    <View style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
      <ActivityIndicator />
    </View>
  ) : (
    <View style={{ flex: 1, flexDirection: "column", padding: 10 }}>
       <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
            {[
              { value: cashFlowTypeEnum.MONTHLY, text: "Měsíční" },
              { value: cashFlowTypeEnum.QUARTERLY, text: "Čtvrtletní" },
              { value: cashFlowTypeEnum.YEARLY, text: "Roční" },
            ].map((el) => {
              return (
                <StateDependantButton
                  key={el.value}
                  text={el.text}
                  state={pickedFreq}
                  onPress={() => {
                    setPickedFreq(el.value)
                    setAxesTicks([])
                  }}
                  style={{
                    backgroundColor:
                      pickedFreq === el.value ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled,
                    padding: 10,
                    borderRadius: 5,
                    marginHorizontal: 5,
                  }}
                />
              )
            })}
       </View>
      <View>
        <Text style={{ fontSize: DefaultTheme.fonts.medium, padding: 5, textAlign: "left" }}>{pressedComp}</Text>
      </View>


      {/*
      <View style={{ padding: 20 }}>
        <Text>{pickedFreq !== cashFlowTypeEnum.YEARLY ? `Zobrazení pro rok ${new Date().getFullYear()}` : ""}</Text>
      </View>
      <View style={{ padding: 20 }}>
        <Text style={{ fontSize: DefaultTheme.fonts.medium, padding: 5, textAlign: "left" }}>hello{pressedComp}</Text>
      </View>
*/}
      <View style={{ flex: 1, alignItems: "center" }}>

        <VictoryChart
          responsive={false}
          containerComponent={<VictoryContainer />}
          domain={{ x: [-2, 11] }}
        >
          <VictoryAxis
            tickValues={chartData.chartValues.map((el) => el.x)}
            tickLabelComponent={
              <VictoryLabel
                  angle={90}
                  dy={-8}
                  textAnchor={"start"}
                  text={ ({ datum }) => {
                      if (pickedFreq === cashFlowTypeEnum.MONTHLY) return monthNameLabels[chartData.chartLabels[datum]]
                      if (pickedFreq === cashFlowTypeEnum.QUARTERLY) return chartData.chartLabels[datum]
                      else return chartData.chartLabels[datum]
                    }
                  }
              />
            }
            domain={{ x: [0, 11] }}
          />
          <VictoryAxis
            tickValues={axesTicks}
            dependentAxis
            crossAxis={true}
            tickLabelComponent={
              <VictoryLabel
                  textAnchor={"end"}
                  style={{fontSize: 10 }}
                  text={({ datum }) => CurrencyFormatter(datum, "CZK", -1)}
              />
            }
            style={{
              axis: { stroke: "rgba(0,0,0,0)" },
            }}
            domain={{ x: [-2, 0] }}
          />
          <VictoryLine
            interpolation="catmullRom"
            data={chartData.chartValues}
            style={{ data: { stroke: DefaultTheme.colors.primary, strokeWidth: 1 } }}
            scale={{ x: "time", y: "linear" }}
          />
          <VictoryBar
            barWidth={15}
            data={[...chartData.inc]}
            style={{
              data: {
                fillOpacity: 0.3,
                fill: DefaultTheme.colors.success,
              },
            }}
//            labels={"x"}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: () => {
                    return [{ target: "data", mutation: (props) => handlePress("BAR", props, 1) }]
                  },
                  onPressOut: () => {
                    return [{ target: "data", mutation: (props) => handlePress("BAR", props, 1) }]
                  },
                },
              },
            ]}
          />
          <VictoryBar
            barWidth={15}
            data={[...chartData.out]}
            style={{
              data: {
                fillOpacity: 0.3,
                fill: DefaultTheme.colors.danger,
              },
            }}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: () => {
                    return [{ target: "data", mutation: (props) => handlePress("BAR", props, 0) }]
                  },
                  onPressOut: () => {
                    return [{ target: "data", mutation: (props) => handlePress("BAR", props, 0) }]
                  },
                },
              },
            ]}
          />
          <VictoryScatter
            style={{ data: { fill: "#c43a31" } }}
            size={5}
            data={chartData.chartValues}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: () => {
                    return [{ target: "data", mutation: (props) => handlePress("POINT", props) }]
                  },
                  onPressOut: () => {
                    return [{ target: "data", mutation: (props) => handlePress("POINT", props) }]
                  },
                },
              },
            ]}
          />
        </VictoryChart>
      </View>
    </View>
  )
}
