import React, { useRef, useState, useEffect } from "react"
import { TouchableOpacity, Platform } from "react-native"
import { Menu } from "react-native-paper"
import { MaterialCommunityIcons, Ionicons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import { propertyViewTypeEnum, statusEnum } from "../../data/enums"
import { importProperty, exportProperty } from "../../actions/headerItemActions"
import { isAdmin, isExecutive, isOfficeManager } from "../../utils/validation"
import { useData } from "../../providers/DataProvider"
import { useStatus } from "../../providers/StatusProvider"
import * as DocumentPicker from "expo-document-picker"
import { firebase } from "../../firebase/config"
import FSStoreContent from "../../firebase/FSStoreContent"

export default function PropertyOverviewHeaderMenu({ navigation }) {
  const { setStatus, setMessage, setTitle, setPercentUploaded } = useStatus()
  const { currentUser, settings, firebaseConfig } = useData()
  const [showMenu, setShowMenu] = useState(false)
  const [userSettings, setUserSettings] = useState({})
  const hasEditPermission = isAdmin(currentUser) || isExecutive(currentUser) || isOfficeManager(currentUser)

  const updatePercentage = useRef()

  async function handleExportProperty() {
    setTitle("Generuji majetek do CSV souboru")

    try {
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      const { message } = await exportProperty(currentUser.email, firebaseConfig)

      setStatus(statusEnum.SUCCESS)
      setMessage(message)
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  useEffect(() => {
    setUserSettings(settings["property"] || { show_discarded_property: false })
  }, [settings])

  async function handleShowDeletedUsers() {
    try {
      await FSStoreContent.updateSettings({
        ...userSettings,
        id: "property",
        show_discarded_property: !userSettings.show_discarded_property,
      })
    } catch (error) {
      setTitle("Nahrávání nastavení")
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  async function openFileStorage() {
    const result = await DocumentPicker.getDocumentAsync({})

    if (result.type !== "cancel") {
      const fileExt = result.name.substring(result.name.length - 3)

      setTitle("Nahrávám CSV soubor")
      setStatus(statusEnum.PROGRESS_DETERMINED)
      navigation.navigate("StatusScreen")

      try {
        if (fileExt.toLowerCase() !== "csv") {
          throw new Error("Soubor musí být formátu CSV!")
        }

        await saveCsvFile(result)

        setTitle("Importuji majetek")

        setStatus(statusEnum.PROGRESS_UNDETERMINED)

        const res = await importProperty(result.name, firebaseConfig)

        setMessage(res.message)
        setStatus(statusEnum.SUCCESS)
      } catch (error) {
        setMessage(error.message)
        setStatus(statusEnum.ERROR)
      }
    }
  }

  async function saveCsvFile(result) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        resolve(xhr.response)
      }
      xhr.onerror = function (e) {
        setStatus(statusEnum.ERROR)
        reject(e)
      }
      xhr.responseType = "blob"
      xhr.open("GET", result.uri, true)
      xhr.send(null)
    })

    const ref = firebase.apps[0].storage().ref("property-import").child(result.name)
    const snapshot = ref.put(blob)

    snapshot.on("state_changed", updatePercentage.current)

    const downloaded = await snapshot
    if (Platform.OS !== "web") {
      blob.close()
    }
    const remoteUri = await downloaded.ref.getDownloadURL()
    return remoteUri
  }

  updatePercentage.current = (snap) => {
    const p = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
    setPercentUploaded(p)
  }

  return (
    <Menu
      visible={showMenu}
      onDismiss={() => setShowMenu(false)}
      anchor={
        <TouchableOpacity onPress={() => setShowMenu(true)} style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.headerSecondary}
          />
        </TouchableOpacity>
      }
    >
      <Menu.Item
        style={{ direction: "ltr" }}
        onPress={() => {
          setShowMenu(false), navigation.navigate("PropertyOverview", { viewType: propertyViewTypeEnum.USERS })
        }}
        title="Majetek lidí"
        icon={() => <Ionicons name="people" size={24} color={DefaultTheme.colors.primary} />}
      />
      {/* <Divider /> */}
      <Menu.Item
        onPress={() => {
          setShowMenu(false), navigation.navigate("PropertyOverview", { viewType: propertyViewTypeEnum.ALL })
        }}
        icon={() => <MaterialCommunityIcons name="clipboard-text" size={24} color={DefaultTheme.colors.primary} />}
        title="Přehled majetku"
      />
      {hasEditPermission && (
        <>
          {/* <Menu.Item
            onPress={() => {
              setShowMenu(false), openFileStorage()
            }}
            icon={() => <MaterialCommunityIcons name="file-import" size={24} color={DefaultTheme.colors.primary} />}
            title="Import majetku"
          /> */}
          <Menu.Item
            onPress={() => {
              setShowMenu(false), handleExportProperty()
            }}
            icon={() => <MaterialCommunityIcons name="file-export" size={24} color={DefaultTheme.colors.primary} />}
            title="Export majetku"
          />
        </>
      )}
      <Menu.Item
        style={{ direction: "ltr" }}
        onPress={() => {
          setShowMenu(false), handleShowDeletedUsers()
        }}
        title="Vyřazený majetek"
        icon={() => (
          <MaterialCommunityIcons
            name={userSettings.show_discarded_property ? "eye-off-outline" : "eye-outline"}
            size={DefaultTheme.icons.menuSize}
            color={DefaultTheme.colors.primary}
          />
        )}
      />
    </Menu>
  )
}
