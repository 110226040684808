import React, { useEffect, useState } from "react"
import { Text, TouchableOpacity, View, StyleSheet } from "react-native"
import {
  STATE_VIEW_PARAMS_ATTENDANCE_DAY,
  STATE_VIEW_PARAMS_USER,
  STATE_VIEW_PARAMS_USER_ATTENDANCE,
} from "../../data/params"
import DefaultTheme from "../../themes/DefaultTheme"
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons"
import { useData } from "../../providers/DataProvider"
import { firebase } from "../../firebase/config"
import { attendanceOperationTypeEnum, attendanceTypeEnum, holidayStateEnum } from "../../data/enums"
import {holidaysPerYearTotal, sickDaysPerYearTotal} from "../../data/companyConstants"
import { getDayDeclensionForm, getHolidayColor } from "../../utils/general"

export default function FlatListAttendanceOverviewItem({ navigation, user, isHeader = false }) {
  // if (isHeader) {
  //   return (
  //     <TouchableOpacity style={[styles.card, { borderLeftColor: DefaultTheme.colors.disabled, height: 70 }]}>
  //       <Ionicons {...STATE_VIEW_PARAMS_USER[true]} size={24} />
  //       <MaterialCommunityIcons {...STATE_VIEW_PARAMS_USER_ATTENDANCE[true]} size={24} />
  //       <View style={{ flex: 1, paddingLeft: 20 }}>
  //         <Text>Meno</Text>
  //         <Text>Email</Text>
  //       </View>
  //       <View style={{ flex: 1, alignItems: "flex-end" }}>
  //         <Text style={{ textAlign: "right" }}>Dovolená do konce roku</Text>
  //       </View>
  //     </TouchableOpacity>
  //   )
  // }

  const { users } = useData()
  const [approvedHolidaysThisYear, setApprovedHolidaysThisYear] = useState(0)
  const freeDaysCount = holidaysPerYearTotal - approvedHolidaysThisYear
  const [sickDays, setSickDays] = useState(0)
  const freeSickDaysCount = sickDaysPerYearTotal - sickDays

  useEffect(() => {
    user = users.find((u) => u.email === user.email)
    onSnapshotHolidays()
  }, [])

  const actualYear = new Date().getFullYear()

  function onSnapshotHolidays() {
    try {
      if (users.length > 0) {
        firebase.apps[0]
          .firestore()
          .collection("attendances")
          .doc(user.email)
          .onSnapshot((doc) => {
            if (doc.data()) {
              const monthlyThisYear = doc.data()?.[`monthly_${actualYear}`]
              let counter = 0
              for (const month in monthlyThisYear) {
                counter += monthlyThisYear[month].reduce((a, c) => {
                  const holidayAprroveAction =
                    c.operations?.find((o) => o.action_type === attendanceOperationTypeEnum.HOLIDAY_APPROVE) || null

                  return (
                    a +
                    (c.attendance_date.toDate().getFullYear() === actualYear &&
                    holidayAprroveAction?.action_value === holidayStateEnum.ACCEPTED
                      ? 1
                      : 0)
                  )
                }, 0)
              }
              setApprovedHolidaysThisYear(counter)

              console.debug("calculate free sickdays per actual year:", new Date().getFullYear())
              counter = 0
              for (const month in monthlyThisYear) {
                counter += monthlyThisYear[month].reduce((a, c) => {
                  return (a + (c.attendance_type === attendanceTypeEnum.SICKDAY ? 1 : 0))
                }, 0)
              }
              console.debug("sickdays sickdays per actual year:", counter)
              setSickDays(counter)
            }
          })
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate("AttendanceMonths", {
          user: user,
          title: `Docházka ${user.name}`,
        })
      }
      style={[styles.card, { borderLeftColor: STATE_VIEW_PARAMS_USER_ATTENDANCE[user.disabled].color }]}
    >
      <View style={{ paddingRight: 15 }}>
        <Ionicons {...STATE_VIEW_PARAMS_USER[user.disabled]} style={{ paddingLeft: 1 }} />
        <MaterialCommunityIcons
          {...STATE_VIEW_PARAMS_USER_ATTENDANCE[user.disabled]}
          size={DefaultTheme.icons.flatlistSize}
        />
      </View>
      <View style={{ flex: 2 }}>
        <Text>{user.name}</Text>
        <Text>{user.email}</Text>
      </View>
      <View style={{ flex: 1, justifyContent: "flex-end", alignItems: "left", flexDirection: "column" }}>
        <View style={{ flex: 1, justifyContent: "flex-end", alignItems: "left", flexDirection: "row" }}>
          <Text style={{ textAlign: "right", paddingRight: 5 }}>
            <Text style={{ fontWeight: "bold" }}>Dovolená: {freeDaysCount}</Text> {getDayDeclensionForm(freeDaysCount)} tento rok
          </Text>
          <MaterialCommunityIcons
            {...STATE_VIEW_PARAMS_ATTENDANCE_DAY[attendanceTypeEnum.HOLIDAY]}
            color={getHolidayColor(freeDaysCount)}
            size={DefaultTheme.icons.s}
          />
        </View>
        <View style={{ flex: 1, justifyContent: "flex-end", alignItems: "left", flexDirection: "row" }}>
          <Text style={{ textAlign: "right", paddingRight: 5 }}>
            <Text style={{ fontWeight: "bold" }}>Sickdays: {freeSickDaysCount}</Text> {getDayDeclensionForm(freeSickDaysCount)} tento rok
          </Text>
          <MaterialCommunityIcons
            {...STATE_VIEW_PARAMS_ATTENDANCE_DAY[attendanceTypeEnum.SICKDAY]}
            color={"red"}
            size={DefaultTheme.icons.s}
          />
        </View>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    height: 100,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingHorizontal: 15,
    flexDirection: "row",
    borderLeftWidth: 10,
    backgroundColor: DefaultTheme.colors.white,
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
})
