import React, { useEffect, useState } from "react"
import { TouchableOpacity } from "react-native"
import { Menu } from "react-native-paper"
import { MaterialCommunityIcons, FontAwesome, FontAwesome5 } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"

import DefaultTheme from "../../themes/DefaultTheme"
import { useStatus } from "../../providers/StatusProvider"
import { actionEnum, itemStateEnum, projectStateEnum, statusEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"
import * as FSDiary from "../../firebase/FSDiary"
import { isOnlyInvestor } from "../../utils/validation"

export default function DiaryEntryDetailHeaderMenu({ params }) {
  const navigation = useNavigation()
  const { projects, currentUser } = useData()
  const [showMenu, setShowMenu] = useState(false)
  const { setTitle, setStatus, setAction, setMessage } = useStatus()
  const [isProjectClosed, setIsProjectClosed] = useState(false)
  const [isEntryClosed, setIsEntryClosed] = useState(false)

  useEffect(() => {
    setIsProjectClosed(projects.find((proj) => proj.id === params?.projectId)?.state === projectStateEnum.CLOSED)
  }, [projects])

  useEffect(() => {
    const projectId = params?.projectId
    const diaryEntryId = params?.diaryEntryId
    if (diaryEntryId) {
      FSDiary.onSnapshotEntry(projectId, diaryEntryId, ({ state }) => {
        setIsEntryClosed(state === itemStateEnum.CLOSED)
      })
    }
  }, [])

  async function handleDuplicate() {
    setShowMenu(false)
    try {
      setTitle("Vytvářím duplicitní zápis")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      await FSDiary.duplicateEntry(params.projectId, params.diaryEntryId)

      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      console.error("Error creating diary entry duplicity")
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
  }

  function handleDelete() {
    setShowMenu(false)
    setTitle(`Opravdu chcete smazat tento zápis?`)
    setStatus(statusEnum.PROGRESS_DECIDE)
    setAction({
      type: actionEnum.DELETE_DIARY_ENTRY,
      params: { ...params, toScreen: { screen: "ProjectDiaryDetail", params } },
    })
    navigation.navigate("StatusScreen")
  }

  return (
    <Menu
      visible={showMenu}
      onDismiss={() => setShowMenu(false)}
      anchor={
        <TouchableOpacity onPress={() => setShowMenu(true)} style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.headerSecondary}
          />
        </TouchableOpacity>
      }
    >
      <Menu.Item
        style={{ direction: "ltr" }}
        onPress={handleDuplicate}
        disabled={isOnlyInvestor(currentUser) || isProjectClosed}
        title="Duplikovat"
        icon={() => (
          <MaterialCommunityIcons
            name="content-duplicate"
            size={DefaultTheme.icons.menuSize}
            color={isProjectClosed ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary}
          />
        )}
      />
      <Menu.Item
        style={{ direction: "ltr" }}
        onPress={handleDelete}
        title="Smazat"
        disabled={isOnlyInvestor(currentUser) || isProjectClosed || isEntryClosed}
        icon={() => (
          <FontAwesome
            name="trash"
            size={DefaultTheme.icons.menuSize}
            color={isProjectClosed ? DefaultTheme.colors.disabled : DefaultTheme.colors.danger}
          />
        )}
      />
    </Menu>
  )
}
