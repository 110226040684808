import React, { useEffect, useState } from "react"
import { SafeAreaView, Text, StyleSheet, View, ScrollView, TouchableOpacity, FlatList } from "react-native"
import NextButton from "../../components/NextButton"
import AppPicker from "../../components/AppPicker"
import { Platform } from "react-native"
import { useData } from "../../providers/DataProvider"
import FSStoreContent from "../../firebase/FSStoreContent"
import { actionEnum, projectStateEnum, statusEnum, userRoleEnum, userStateEnum } from "../../data/enums"
import { useStatus } from "../../providers/StatusProvider"
import { getExistingUsers, getUserName } from "../../utils/general"
import Screen from "../../components/Screen"
import { isAdmin, isExecutive, isTechnicalDirector } from "../../utils/validation"
import FlatListProjectPeopleItem from "../../components/FlatLists/FlatListProjectPeopleItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"

export default function ProjectPeople({ route, navigation }) {
  const { projects, users, currentUser } = useData()
  const [project, setProject] = useState({})
  const [picked, setPicked] = useState("")
  const hasEditPermission = isAdmin(currentUser) || isExecutive(currentUser) || isTechnicalDirector(currentUser)

  useEffect(() => {
    const filteredProject = projects.filter((project) => project.id === route.params.project.id)[0]
    if (filteredProject) {
      filteredProject.users?.sort((a, b) => (getUserName(users, a.email) > getUserName(users, b.email) ? 1 : -1))
      setProject(filteredProject)
    }
  }, [projects])

  const getNotAssignedUsers = () => {
    const projectUsers = project.users ? project.users.map((user) => user.email) : []

    let out = users
      .filter((u) => u.state !== userStateEnum.DELETED)
      .filter((user) => !projectUsers.includes(user.email))
    out.sort()
    return out
  }

  const addUser = (email) => {
    console.debug("addUser: picked: " + email)
    const projectUsers = project.users ? project.users : []
    if (email != null && email !== "") {
      projectUsers.push({ email: email, roles: [] })
      setProject({ ...project, users: projectUsers })
    }
    setPicked("")
  }

  const handleValueChange = (email) => {
    setPicked(email)

    if (Platform.OS !== "ios") {
      addUser(email)
    }
  }

  const updateProject = async () => {
    await FSStoreContent.updateProject(project).then(() => {
      navigation.goBack()
    })
  }

  return (
    // <Screen barStyle="light-content">
    <Screen>
      {/* TEMPORARY REPORTING*/}
      <View style={{ flex: 1 }}>
        <FlatList
          data={getExistingUsers(project.users, users)}
          renderItem={({ item }) => (
            <FlatListProjectPeopleItem user={item} navigation={navigation} project={project} setProject={setProject} />
          )}
          keyExtractor={(item) => item.email}
          ItemSeparatorComponent={FlatListSeparator}
          extraData={project}
        />
      </View>
      {hasEditPermission && getNotAssignedUsers() != null && getNotAssignedUsers().length > 0 && (
        <AppPicker
          value={picked}
          style={appPickerStyles}
          placeholder={{ label: "--- Vyberte uživatele ---", value: "" }}
          onValueChange={(email) => handleValueChange(email)}
          onClose={() => addUser(picked)}
          items={getNotAssignedUsers().map((user) => {
            return { label: user.name, value: user.email }
          })}
          disabled={!(hasEditPermission && project.state !== projectStateEnum.CLOSED)}
        />
      )}
      <NextButton
        disabled={!(hasEditPermission && project.state !== projectStateEnum.CLOSED)}
        onPress={updateProject}
        text="Uložit"
      />
    </Screen>
  )
}

const appPickerStyles = StyleSheet.create({
  inputIOS: {
    height: 100,
    width: "100%",
    paddingHorizontal: 15,
  },
  inputAndroid: {
    height: 100,
    paddingHorizontal: 15,
    paddingTop: 0,
  },
  inputWeb: {
    paddingLeft: 18,
    height: 65,
    color: "black",
  },
  placeholder: {
    color: "black",
  },
  iconContainer: {
    top: "45%",
    right: 20,
  },
})
