import React, { useState } from "react"
import { TouchableOpacity, Text, StyleSheet, View } from "react-native"
import { FontAwesome } from "@expo/vector-icons"
import { approveEnum, documentAccountingTypeEnum } from "../data/enums"
import DefaultTheme from "../themes/DefaultTheme"

export default function PlusMinusButtons({
  plusMinusValues,
  setPlusMinusValues,
  document,
  setDocument,
  disabled = false,
}) {
  function handleChange(buttonIndex) {
    if (
      (plusMinusValues[0] && buttonIndex === 0) ||
      (plusMinusValues[1] && buttonIndex === 1) ||
      (!plusMinusValues[0] && !plusMinusValues[1])
    ) {
      setPlusMinusValues(plusMinusValues.map((val, index) => (index === buttonIndex ? !val : val)))

      if (!plusMinusValues[0] && !plusMinusValues[1]) {
        setDocument({
          ...document,
          accounting_info_internal: { type: documentAccountingTypeEnum.ADVANCE, direction: buttonIndex === 0 ? 1 : 0 },
        })
      } else if ((plusMinusValues[0] && buttonIndex === 0) || (plusMinusValues[1] && buttonIndex === 1)) {
        const tempDoc = document
        delete tempDoc.accounting_info_internal
        setDocument(tempDoc)
      }
    }
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={[
          styles.button,
          {
            backgroundColor: plusMinusValues[0] ? DefaultTheme.colors.primary : DefaultTheme.colors.white,
            marginRight: 10,
          },
        ]}
        onPress={() => handleChange(0)}
        disabled={disabled}
      >
        <Text style={styles.buttonTitle}>
          <FontAwesome name="plus" size={24} color="green" />
        </Text>
      </TouchableOpacity>
      <View />
      <TouchableOpacity
        style={[
          styles.button,
          { backgroundColor: plusMinusValues[1] ? DefaultTheme.colors.primary : DefaultTheme.colors.white },
        ]}
        onPress={() => handleChange(1)}
        disabled={disabled}
      >
        <Text style={styles.buttonTitle}>
          <FontAwesome name="minus" size={24} color="red" />
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  button: {
    minWidth: 50,
    height: 50,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    borderColor: DefaultTheme.colors.light,
    borderWidth: 1,
  },
})
