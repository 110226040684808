import React, { useState } from "react"
import { TouchableOpacity, Text, StyleSheet, View } from "react-native"
import { FontAwesome } from "@expo/vector-icons"
import { approveEnum } from "../data/enums"

export default function ApproveButtonsModular({ value, onAccept, onReject, disabled = false, style }) {
  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity
        style={[
          styles.button,
          { backgroundColor: value === approveEnum.ACCEPTED ? "#788eec" : "white", marginRight: 10 },
        ]}
        onPress={onAccept}
        disabled={disabled}
      >
        <Text style={styles.buttonTitle}>
          <FontAwesome name="check" size={24} color="green" />
        </Text>
      </TouchableOpacity>
      <View />
      <TouchableOpacity
        style={[styles.button, { backgroundColor: value === approveEnum.REJECTED ? "#788eec" : "white" }]}
        onPress={onReject}
        disabled={disabled}
      >
        <Text style={styles.buttonTitle}>
          <FontAwesome name="remove" size={24} color="red" />
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  button: {
    minWidth: 50,
    height: 50,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
})
