import React from "react"
import AppPicker from "./AppPicker"
import { monthNameLabels } from "../data/labels"
import { View } from "react-native"

export default function MonthYearPicker({ max, min, value, callback, style, extra = undefined, disabled }) {
  const today = new Date()

  let monthItems = []
  for (let key of Object.keys(monthNameLabels)) {
    monthItems.push({ value: key, label: monthNameLabels[key] })
  }

  const yearItems = []
  for (let year = today.getFullYear() + 1; year > 2020; year--) {
    yearItems.push({ label: year.toString(), value: year.toString() })
  }

  return (
    <View style={[{ flexDirection: "row" }, style]}>
      <View style={{ flex: 1 }}>
        <AppPicker
          descriptionLabel="Měsíc"
          value={value.getMonth().toString()}
          onValueChange={(value) => callback(value, true, extra)}
          items={monthItems}
          disabled={disabled}
        />
      </View>
      <View style={{ flex: 1 }}>
        <AppPicker
          descriptionLabel="Rok"
          value={value.getFullYear().toString()}
          onValueChange={(value) => callback(value, false, extra)}
          items={yearItems}
          style={{ flex: 1 }}
          disabled={disabled}
        />
      </View>
    </View>
  )
}
