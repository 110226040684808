import React, { useEffect, useState } from "react"
import { Platform, StyleSheet, View, Text, Dimensions } from "react-native"
import Slider from "@react-native-community/slider"
import { TextInput } from "react-native-paper"
import DefaultTheme from "../../themes/DefaultTheme"
import { useData } from "../../providers/DataProvider"
import { formatDecimalNumber, getUserName } from "../../utils/general"
import { isAdmin, isExecutive, isNumberPositive, isTechnicalDirector } from "../../utils/validation"
import { projectStateEnum } from "../../data/enums"
import { STATE_VIEW_PARAMS_USER } from "../../data/params"
import { Ionicons } from "@expo/vector-icons"

export default function FlatListProjectPremiumItem({ projUser, project, setProject }) {
  const { users, currentUser } = useData()
  const [premium, setPremium] = useState(projUser.premium * 100 || "0.00")
  const [isPremiumValid, setIsPremiumValid] = useState(true)
  const hasEditPermission = isAdmin(currentUser) || isExecutive(currentUser) || isTechnicalDirector(currentUser)

  const disabled = !(hasEditPermission && project.state !== projectStateEnum.CLOSED)
  const userDisabled = users.find((u) => u.email === projUser.email).disabled

  const windowWidth = Dimensions.get("window").width

  function handleChangeText(value) {
    if (!(formatDecimalNumber(value) >= 0 && formatDecimalNumber(value) <= 1)) {
      value = isNumberPositive(formatDecimalNumber(value)) ? formatDecimalNumber(value) : ""
    }

    setProject({
      ...project,
      users: project.users.map((u) => {
        if (u.email === projUser.email) {
          return { ...projUser, premium: formatDecimalNumber(value) / 100 }
        } else {
          return u
        }
      }),
    })

    setIsPremiumValid(() => {
      if (value >= 0 && value <= 100) {
        return true
      }
      return false
    })
    setPremium(formatDecimalNumber(value))
  }

  function getSliderValue() {
    return isNumberPositive(formatDecimalNumber(premium)) ? Number(premium) || 0 : 0
  }

  return (
    <View
      style={[
        styles.card,
        { borderLeftColor: userDisabled || disabled ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary },
      ]}
    >
      <View style={{ flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
        <View style={{ flexDirection: "row" }}>
          <Ionicons {...STATE_VIEW_PARAMS_USER[userDisabled || disabled]} />
          <Text style={{ paddingLeft: 15 }}>{getUserName(users, projUser.email)}</Text>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text>Prémie z nad zisku</Text>
          <TextInput
            keyboardType="numeric"
            style={{ marginHorizontal: 5, padding: 0, backgroundColor: "#EEEEEE", width: 75 }}
            error={!isPremiumValid}
            value={String(premium)}
            onChangeText={(value) => handleChangeText(value)}
            onTouchStart={() => (premium === "0.00" ? setPremium("") : "")}
            disabled={disabled}
          />
          <Text>&nbsp;%</Text>
        </View>
      </View>
      <View style={{ alignItems: "center", width: "100%" }}>
        {Platform.OS !== "web" && (
          <Slider
            minimumTrackTintColor={isPremiumValid ? DefaultTheme.colors.primary : "red"}
            thumbTintColor={isPremiumValid ? DefaultTheme.colors.primary : "red"}
            style={{ width: windowWidth - 100 }}
            minimumValue={0}
            maximumValue={100}
            step={0.25}
            onValueChange={(value) => handleChangeText(value.toFixed(2))}
            value={getSliderValue()}
            disabled={disabled}
          />
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    width: "100%",
    padding: 15,
    backgroundColor: DefaultTheme.colors.white,
    borderLeftWidth: 10,
  },
})
