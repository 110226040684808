import React, { useEffect, useState } from "react"
import { TouchableOpacity } from "react-native"
import { Menu } from "react-native-paper"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import { useStatus } from "../../providers/StatusProvider"
import { statusEnum } from "../../data/enums"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useData } from "../../providers/DataProvider"

export default function UserOverviewHeaderMenu({ navigation }) {
  const { settings } = useData()
  const [showMenu, setShowMenu] = useState(false)
  const [userSettings, setUserSettings] = useState({})
  const { setTitle, setStatus, setMessage } = useStatus()

  useEffect(() => {
    setUserSettings(settings["users"] || { id: "users", show_deleted_users: false })
  }, [settings])

  async function handleShowDeletedUsers() {
    try {
      await FSStoreContent.updateSettings({
        ...userSettings,
        show_deleted_users: !userSettings.show_deleted_users,
      })
    } catch (error) {
      setTitle("Nahrávání nastavení")
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  return (
    <Menu
      visible={showMenu}
      onDismiss={() => setShowMenu(false)}
      anchor={
        <TouchableOpacity onPress={() => setShowMenu(true)} style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.headerSecondary}
          />
        </TouchableOpacity>
      }
    >
      <Menu.Item
        style={{ direction: "ltr" }}
        onPress={() => {
          setShowMenu(false), handleShowDeletedUsers()
        }}
        title="Smazaní uživatelé"
        icon={() => (
          <MaterialCommunityIcons
            name={userSettings.show_deleted_users ? "eye-off-outline" : "eye-outline"}
            size={DefaultTheme.icons.menuSize}
            color={DefaultTheme.colors.primary}
          />
        )}
      />
    </Menu>
  )
}
