import React, { useRef } from "react"
import { View, Text, StyleSheet, Platform, Dimensions } from "react-native"
import DefaultTheme from "../themes/DefaultTheme"
import RNPickerSelect from "react-native-picker-select"
import { MaterialCommunityIcons } from "@expo/vector-icons"

export default function AppPicker({
  placeholder,
  descriptionLabel,
  style,
  onValueChange,
  value,
  disabled = false,
  items,
  ...otherProps
}) {
  const windowHeight = Dimensions.get("window").height

  const appPickerStyles = StyleSheet.create({
    inputIOS: {
      borderRadius: 5,
      overflow: "hidden",
      backgroundColor: "white",
      marginBottom: DefaultTheme.margin.s,
      marginHorizontal: DefaultTheme.margin.s,
      paddingTop: value ? 24 : 8,
      paddingLeft: 22,
      paddingBottom: 8,
      paddingRight: 30, // to ensure the text is never behind the icon
      height: 65,
      borderBottomWidth: 1,
      borderBottomColor: value ? DefaultTheme.colors.light : DefaultTheme.colors.error,
      fontSize: DefaultTheme.fonts.regular,
    },
    inputAndroid: {
      borderRadius: 5,
      overflow: "hidden",
      backgroundColor: "white",
      color: "white",
      paddingTop: value ? 24 : 8,
      paddingLeft: 22,
      paddingBottom: 8,
      marginBottom: DefaultTheme.margin.s,
      marginHorizontal: DefaultTheme.margin.s,
      height: 65,
      borderBottomWidth: 1,
      borderBottomColor: value ? DefaultTheme.colors.light : DefaultTheme.colors.error,
      paddingRight: 30, // to ensure the text is never behind the icon
      fontSize: DefaultTheme.fonts.regular,
    },
    inputWeb: {
      paddingTop: value === "" ? 10 : 24,
      paddingRight: 15,
      paddingLeft: 18,
      paddingBottom: 8,
      height: 65,
      marginBottom: DefaultTheme.margin.s,
      marginHorizontal: DefaultTheme.margin.s,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 1,
      borderBottomColor: value === "" ? DefaultTheme.colors.error : DefaultTheme.colors.light,
      fontSize: DefaultTheme.fonts.regular,
      color: value === "" ? DefaultTheme.colors.error : "black",
    },
    iconContainer: {
      top: 20,
      right: 20,
    },
    placeholder: {
      color: value === "" ? DefaultTheme.colors.error : "black",
    },
    modalViewTop: {
      height: 215,
      marginTop: "auto",
      // marginTop: windowHeight - 215, // 215 component predefined height
    },
  })

  return (
    <View>
      {value !== "" ? (
        value === "NULL" ? (
          <View />
        ) : (
          <Text style={DefaultTheme.textInputHint}>{descriptionLabel}</Text>
        )
      ) : (
        <View />
      )}
      {/* {Platform.OS === "ios" ? (
        // TODO
        // celu komponentuhodit pod tlacitko ulozit
        // https://github.com/react-native-datetimepicker/datetimepicker#mode-optional
        <Picker onValueChange={onValueChange} selectedValue={value}>
          {renderPickerItems()}
        </Picker>
      ) : ( */}
      <RNPickerSelect
        modalProps={{ style: { margin: 0, justifyContent: "flex-end" } }}
        items={items}
        value={value}
        onValueChange={onValueChange}
        placeholder={placeholder ?? { label: "", value: "" }}
        useNativeAndroidPickerStyle={false}
        style={{ ...appPickerStyles, ...style }}
        Icon={() => Platform.OS !== "web" && <MaterialCommunityIcons name="chevron-down" size={20} />}
        {...otherProps}
        disabled={disabled}
      />
      {/* )} */}
    </View>
  )
}
