import React from "react"
import { Text, TouchableOpacity, View, StyleSheet } from "react-native"
import { MaterialCommunityIcons, FontAwesome, FontAwesome5 } from "@expo/vector-icons"
import { useTheme } from "react-native-paper"

import DefaultTheme from "../../themes/DefaultTheme"
import { getDocumentState, getProjectName, toCurrentDate, toCurrentDateTime } from "../../utils/general"
import {
  documentClosingStateEnum,
  documentViewTypeEnum,
  paymentEnum,
  paymentStateEnum,
  viewTypeEnum,
} from "../../data/enums"
import { useData } from "../../providers/DataProvider"
import CurrencyFormatter from "../CurrencyFormatter"
import { paymentLabels } from "../../data/labels"
import { STATE_VIEW_PARAMS_DOCUMENT } from "../../data/params"
import PrimButton from "../PrimButton"
import { useStatus } from "../../providers/StatusProvider"
import { useNavigation } from "@react-navigation/native"

export default function FlatListAccountingDocumentItem({ document, viewType, toScreen, accDocs, setAccDocs }) {
  const navigation = useNavigation()
  const { setStatus, setMessage, setTitle } = useStatus()
  const { projects } = useData()
  const PickedTheme = useTheme()
  const styles = getStyles(document)

  console.log(JSON.stringify(document))

  function handleOnPress() {
    if (viewType === viewTypeEnum.BULK_ACTION) {
      setAccDocs(accDocs.map((doc) => (doc.id === document.id ? { ...doc, isSelected: !doc.isSelected } : doc)))
    } else if (viewType === documentViewTypeEnum.BATCH) {
      setAccDocs(
        accDocs.map((doc) => {
          if (doc.id === document.id) {
            if (doc.payment_state === paymentStateEnum.REQUEST) {
              delete doc.payment_state
              return doc
            } else {
              return { ...doc, payment_state: paymentStateEnum.REQUEST }
            }
          } else {
            return doc
          }
        })
      )
    } else {
      navigation.navigate("AccountingDocumentDetail", {
        document,
        toScreen: toScreen || "",
        viewType,
      })
    }
  }

  function getBorderLeftColor() {
    if (viewType === viewTypeEnum.BULK_ACTION) {
      return document.isSelected ? PickedTheme.colors.primary : PickedTheme.colors.disabled
    } else if (viewType === documentViewTypeEnum.BATCH) {
      return document.payment_state === paymentStateEnum.REQUEST
        ? DefaultTheme.colors.primary
        : DefaultTheme.colors.disabled
    } else {
      return STATE_VIEW_PARAMS_DOCUMENT[`${getDocumentState(document)}${document.locked ? "_LOCKED" : ""}`].color
    }
  }

  return (
    <TouchableOpacity onPress={handleOnPress}>
      <View style={[styles.card, { borderLeftColor: getBorderLeftColor() }]}>
        <View style={{ justifyContent: "center", alignItems: "center", width: 25, paddingVertical: 5 }}>
          <FontAwesome5
            name="file-invoice-dollar"
            size={DefaultTheme.icons.flatlistSize}
            color={STATE_VIEW_PARAMS_DOCUMENT[`${getDocumentState(document)}${document.locked ? "_LOCKED" : ""}`].color}
          />
          <FontAwesome
            {...STATE_VIEW_PARAMS_DOCUMENT[`${getDocumentState(document)}${document.locked ? "_LOCKED" : ""}`]}
            size={DefaultTheme.icons.flatlistSize}
          />
          {document.locked && (
            <FontAwesome name="lock" color={DefaultTheme.colors.disabled} size={DefaultTheme.icons.flatlistSize} />
          )}
          {(document.accounting_info?.processingState === 1 || document.accounting_info?.processingState === 2) && (
            <View style={styles.triviLogo}>
              <Text style={{ fontWeight: "bold", color: DefaultTheme.colors.disabled }}>T</Text>
            </View>
          )}
        </View>
        <View
          style={{
            flex: viewType === documentViewTypeEnum.BATCH ? 1 : 1.8,
            padding: 10,
            justifyContent: "space-between",
          }}
        >
          <View>
            <Text style={styles.name}>{document.name || "- - -"} [ {document?.payment_info?.variable} ]</Text>
            <Text style={{ paddingBottom: 10 }}>
              {document.project
                ? `${document.project}${
                    document.project === getProjectName(projects, document.project)
                      ? ""
                      : " - " + getProjectName(projects, document.project)
                  }`
                : "Projekt nepřiřazený"}
            </Text>
          </View>
          <Text>{`${toCurrentDateTime(document.created_at)}`.slice(0, 24)}</Text>
        </View>
        <View
          style={{
            flex: 1.2,
            padding: 10,
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <View>
            <Text style={{ textAlign: "right", fontWeight: "bold" }}>
              {CurrencyFormatter(document.price, "CZK", 1, true)}
            </Text>
            <Text style={{ textAlign: "right" }}>{paymentLabels[document.payment]}</Text>
          </View>
          {viewType === documentViewTypeEnum.BATCH && (
            <Text>Splatnost: {toCurrentDate(document.accounting_info.due_date?.toDate())}</Text>
          )}
          {viewType === documentViewTypeEnum.BATCH &&
            document.payment_state &&
            document.payment_state === paymentStateEnum.REQUEST && (
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                {document.payment_info?.pay_again && <Text style={{ paddingRight: 5 }}>Opakovaná platba</Text>}
                <MaterialCommunityIcons
                  name="credit-card-refund"
                  color={document.payment_info?.pay_again ? DefaultTheme.colors.danger : DefaultTheme.colors.primary}
                  size={24}
                  style={{ paddingVertical: 10 }}
                />
              </View>
            )}
          {viewType === documentViewTypeEnum.OVERVIEW && (
            <>
              {document.payment_info?.paid ||
              document.payment_info?.paidConfirmed ||
              document.payment_info?.paidPartly ||
              document.payment === paymentEnum.CASH ||
              document.payment === paymentEnum.DEBIT_CARD ||
              document.id === "T8eCzrdQf8Mno8ZYe2MN" ? (
                <View style={{ flexDirection: "row", alignItems: "center", paddingVertical: 5 }}>
                  <Text style={{ paddingRight: 10 }}>
                    {(document.hasOwnProperty("exec_labeled") && document?.exec_labeled) ||
                    document.payment_info?.paidConfirmed ||
                    document.payment === paymentEnum.CASH ||
                    document.payment === paymentEnum.DEBIT_CARD ||
                    document.id === "T8eCzrdQf8Mno8ZYe2MN"
                      ? "Zaplaceno"
                      : document.payment_info?.paidPartly
                      ? "Částečně zaplaceno"
                      : "Čeká na platbu"}
                  </Text>
                  {(document.hasOwnProperty("exec_labeled") && document?.exec_labeled) ||
                  document.payment_info?.paidConfirmed ||
                  document.payment === paymentEnum.CASH ||
                  document.payment === paymentEnum.DEBIT_CARD ||
                  document.id === "T8eCzrdQf8Mno8ZYe2MN" ? (
                    <View style={[styles.paymentIcon, styles.paymentDone]}>
                      <FontAwesome name="check" color="white" size={18} />
                    </View>
                  ) : document.payment_info?.paidPartly ? (
                    <View style={[styles.paymentIcon, styles.paymentPartly]}>
                      <FontAwesome name="check" color="white" size={18} />
                    </View>
                  ) : (
                    <View style={[styles.paymentIcon, styles.paymentWaiting]}>
                      <FontAwesome name="dollar" size={18} color="white" />
                    </View>
                  )}
                </View>
              ) : (
                <Text style={{ textAlign: "right" }}>
                  {document.accounting_info?.due_date
                    ? `do ${toCurrentDate(document.accounting_info?.due_date.toDate())}`
                    : "do [ - - - ]"}
                </Text>
              )}
            </>
          )}
        </View>
        {viewType === documentViewTypeEnum.CASH_DESK && document.closing_state && (
          <View style={styles.closingStateWrapper}>
            {document.closing_state && (
              <Text style={styles.closingStateText}>
                {document.closing_state === documentClosingStateEnum.MATCHED
                  ? "shoda"
                  : document.closing_state === documentClosingStateEnum.MISSED
                  ? "chyběl"
                  : "neshoduje se"}
              </Text>
            )}
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

function getStyles(document) {
  return StyleSheet.create({
    card: {
      minHeight: 100,
      maxHeight: 140,
      width: "100%",
      paddingLeft: 15,
      flexDirection: "row",
      borderLeftWidth: 10,
      backgroundColor: DefaultTheme.colors.white,
    },
    closingStateText: {
      width: 100,
      height: 30,
      fontWeight: "bold",
      textAlign: "center",
      lineHeight: 30,
      transform: [{ rotate: "-90deg" }],
      color: document.closing_state === documentClosingStateEnum.MATCHED ? "white" : "black",
    },
    closingStateWrapper: {
      width: 30,
      height: "100%",
      backgroundColor:
        document.closing_state === documentClosingStateEnum.MATCHED
          ? "green"
          : document.closing_state === documentClosingStateEnum.MISSED
          ? "yellow"
          : "red",
      justifyContent: "center",
      alignItems: "center",
    },
    paymentIcon: {
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "orange",
      width: 24,
      height: 24,
      borderRadius: 12,
    },
    paymentDone: {
      backgroundColor: "green",
    },
    paymentPartly: {
      backgroundColor: "purple",
    },
    paymentWaiting: {
      backgroundColor: "orange",
    },
    hint: {
      fontStyle: "italic",
      color: DefaultTheme.colors.placeholder,
      paddingLeft: 10,
    },
    name: {
      fontWeight: "bold",
      paddingBottom: 10,
    },
    triviLogo: {
      marginTop: 3,
      width: 25,
      height: 25,
      borderWidth: 2,
      borderRadius: 15,
      borderColor: DefaultTheme.colors.disabled,
      justifyContent: "center",
      alignItems: "center",
    },
  })
}
