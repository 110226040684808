import React from "react"
import { StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { Ionicons, AntDesign, FontAwesome5, FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons"

import DefaultTheme from "../../themes/DefaultTheme"
import { STATE_VIEW_PARAMS_USER } from "../../data/params"
import { userRoleEnum, userStateEnum, usersViewTypeEnum } from "../../data/enums"
import PhoneNumberFormatter from "../PhoneNumberFormatter"
import { isOnlyInvestor } from "../../utils/validation"

export default function FlatListUserItem({ user, navigation, viewType }) {
  const isCompanyView = viewType === usersViewTypeEnum.COMPANY

  const duties1 = [
    { propName: "bozp", icon: <AntDesign name="Safety" size={18} color={DefaultTheme.colors.primary} /> },
    { propName: "po", icon: <FontAwesome5 name="fire-extinguisher" size={17} color={DefaultTheme.colors.primary} /> },
    { propName: "first_aid", icon: <FontAwesome5 name="first-aid" size={16} color={DefaultTheme.colors.primary} /> },
  ]

  const duties2 = [
    { propName: "driver_training", icon: <FontAwesome name="car" size={17} color={DefaultTheme.colors.primary} /> },
    {
      propName: "medical_examination",
      icon: <MaterialCommunityIcons name="doctor" size={25} color={DefaultTheme.colors.primary} />,
    },
    {
      label: "Revize elektrozařízení",
      propName: "el_equipment_revision",
      icon: <FontAwesome name="bolt" size={17} color={DefaultTheme.colors.primary} />,
    },
  ]

  return (
    <TouchableOpacity key={user.email} onPress={() => navigation.navigate("UserDetail", { user, viewType })}>
      <View
        key={user.email}
        style={[
          styles.card,
          {
            borderLeftWidth: 10,
            borderLeftColor:
              user.state === userStateEnum.DELETED
                ? STATE_VIEW_PARAMS_USER.deleted.color
                : STATE_VIEW_PARAMS_USER[user.disabled].color,
          },
        ]}
      >
        <Ionicons
          {...STATE_VIEW_PARAMS_USER[user.state === userStateEnum.DELETED ? "deleted" : user.disabled]}
          size={DefaultTheme.icons.flatlistSize}
          style={{ paddingRight: 15 }}
        />
        <View style={{ flex: 1 }}>
          <Text>{user.name}</Text>
          <Text style={{ paddingVertical: 5 }}>{user.email}</Text>
          <Text>{PhoneNumberFormatter(user?.phone || "")}</Text>
        </View>
        {!isCompanyView && !isOnlyInvestor(user) && (
          <View
            style={{ flex: 1, padding: 10, flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}
          >
            <View style={{ paddingRight: 15 }}>
              {duties1.map(({ propName, icon }) => (
                <View key={propName} style={{ flexDirection: "row", justifyContent: "flex-end", padding: 2 }}>
                  <View style={{ width: 30, alignItems: "center", justifyContent: "center" }}>{icon}</View>
                  <View style={{ width: 30, alignItems: "center", justifyContent: "center" }}>
                    <FontAwesome
                      name={user[propName] === "ACCEPTED" ? "check" : "remove"}
                      size={24}
                      color={user[propName] === "ACCEPTED" ? DefaultTheme.colors.success : DefaultTheme.colors.danger}
                    />
                  </View>
                </View>
              ))}
            </View>
            <View>
              {duties2.map(({ propName, icon }) => (
                <View key={propName} style={{ flexDirection: "row", justifyContent: "flex-end", padding: 2 }}>
                  <View style={{ width: 30, alignItems: "center", justifyContent: "center" }}>{icon}</View>
                  <View style={{ width: 30, alignItems: "center", justifyContent: "center" }}>
                    <FontAwesome
                      name={user[propName] === "ACCEPTED" ? "check" : "remove"}
                      size={24}
                      color={user[propName] === "ACCEPTED" ? DefaultTheme.colors.success : DefaultTheme.colors.danger}
                    />
                  </View>
                </View>
              ))}
            </View>
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    height: 100,
    justifyContent: "center",
    width: "100%",
    paddingHorizontal: 15,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: DefaultTheme.colors.white,
  },
  buttons: {
    position: "absolute",
    bottom: 0,
    right: 0,
    marginRight: 15,
    marginBottom: 15,
  },
  magnifier: {
    backgroundColor: "red",
    marginBottom: 15,
  },
})
