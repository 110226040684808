import React, { useState } from "react"
import { StyleSheet, Text, TouchableOpacity, View } from "react-native"
import Icon from "react-native-vector-icons/MaterialIcons" // Using MaterialIcons for this example

export default function RBTransactionDetail(transactionItem) {
  const [expanded, setExpanded] = useState(false)
  const toggleExpand = () => setExpanded(!expanded)

  const transaction = {
    "entryReference": 3887196517,
    "amount": {
      "value": -1668.59,
      "currency": "CZK",
    },
    "creditDebitIndication": "DBIT",
    "bookingDate": "2019-08-24T14:15:22Z",
    "valueDate": "2019-08-24T14:15:22Z",
    "bankTransactionCode": {
      "code": "10000401000",
    },
    "entryDetails": {
      "transactionDetails": {
        "references": {
          "endToEndIdentification": "EBGTF015477198",
        },
        "instructedAmount": {
          "value": 61,
          "currency": "EUR",
          "exchangeRate": 0.03656,
        },
        "chargeBearer": "SHAR",
        "paymentCardNumber": "547872XXXXXX9475",
        "relatedParties": {
          "counterParty": {
            "name": null,
            "postalAddress": {},
            "organisationIdentification": {},
            "account": {},
          },
          "intermediaryInstitution": {
            "name": null,
            "bicOrBei": null,
            "bankCode": null,
            "postalAddress": {},
          },
          "ultimateCounterParty": {
            "name": null,
            "postalAddress": {},
          },
        },
        "remittanceInformation": {
          "unstructured": "61 EUR;FIRMA LTD;12345678 RADEK DVA RADEK TRI RADEK CTYRI",
          "creditorReferenceInformation": {
            "variable": null,
            "constant": null,
            "specific": null,
          },
          "originatorMessage": "na?e platba",
        },
      },
    },
  }

  const formattedDate = new Date(transaction?.bookingDate).toLocaleDateString("cs-CZ")

  const TransactionDetailItem = ({ iconName, title, value }) => (
    <View style={styles.lineContainer}>
      <Text style={styles.leftContainer}>
        <Icon name={iconName} size={16} /> {title}:
      </Text>
      <Text style={styles.rightContainer}>
        {value}
      </Text>
    </View>
  )

  const TransactionHeader = ({ transaction }) => (
    <View style={styles.lineContainer}>
      <View style={styles.leftContainer}>
        <Text style={styles.text}><Icon name="fingerprint" size={16} />{transaction.entryReference} | </Text>
        <Text style={styles.text}><Icon name="event" size={16} />{formattedDate}</Text>
      </View>
      <View style={styles.rightContainer}>
        <Text style={styles.text}><Icon name="attach-money" size={16} />
          {`${transaction?.amount?.value} ${transaction?.amount?.currency}`}
        </Text>
        <Icon name={transaction?.creditDebitIndication === "DBIT" ? "remove" : "add"} size={20} />
      </View>
    </View>
  )

  return (
    <TouchableOpacity onPress={toggleExpand} style={styles.container}>
      <View style={styles.row}>
        <Icon name={expanded ? "keyboard-arrow-up" : "keyboard-arrow-down"} size={30} />
        <TransactionHeader transaction={transaction} />
      </View>

      {expanded && (
        <View style={styles.detailContainer}>
          <TransactionDetailItem iconName="format-list-numbered" title="Referenční číslo"
                                 value={transaction.entryDetails.transactionDetails.references.endToEndIdentification} />
          <TransactionDetailItem iconName="attach-money" title="Částka"
                                 value={`${transaction?.amount?.value} ${transaction?.amount?.currency}`} />
          <TransactionDetailItem iconName="sync-alt" title="Směnný kurz"
                                 value={transaction?.entryDetails?.transactionDetails?.instructedAmount?.exchangeRate} />
          <TransactionDetailItem iconName="account-balance-wallet" title="Typ transakce"
                                 value={transaction?.creditDebitIndication === "DBIT" ? "Debit" : "Credit"} />
          <TransactionDetailItem iconName="fingerprint" title="ID" value={transaction.entryReference} />
          <TransactionDetailItem iconName="event" title="Datum" value={formattedDate} />
          <TransactionDetailItem iconName="code" title="Kód bankovní transakce"
                                 value={transaction?.bankTransactionCode?.code} />
          <TransactionDetailItem iconName="payment" title="Číslo platební karty"
                                 value={transaction?.entryDetails?.transactionDetails?.paymentCardNumber} />
          <TransactionDetailItem iconName="message" title="Zpráva odesílatele"
                                 value={transaction?.entryDetails?.transactionDetails?.remittanceInformation?.originatorMessage} />
          <TransactionDetailItem iconName="info" title="Informace o platbě"
                                 value={transaction?.entryDetails?.transactionDetails?.remittanceInformation?.unstructured} />
          <TransactionDetailItem iconName="account-balance" title="Typ poplatku"
                                 value={transaction?.entryDetails?.transactionDetails?.chargeBearer} />
          <TransactionDetailItem iconName="attach-money" title="Instruovaná částka"
                                 value={`${transaction?.entryDetails?.transactionDetails?.instructedAmount?.value} ${transaction?.entryDetails?.transactionDetails?.instructedAmount?.currency}`} />
          <TransactionDetailItem iconName="payment" title="Číslo platební karty"
                                 value={transaction?.entryDetails?.transactionDetails?.paymentCardNumber} />
        </View>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  lineContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    alignItems: "center",
    flex: 1,
  },
  leftContainer: {
    alignItems: "flex-start",
    flexDirection: "row",
  },
  rightContainer: {
    alignItems: "flex-end",
    flexDirection: "row",
  },
  text: {
    fontSize: 14,
    marginLeft: 5,
  },
  detailContainer: {
    marginTop: 10,
    marginLeft: 40,
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  detailItem: {
    fontSize: 14,
    color: "#333",
    marginBottom: 5,
    alignItems: "center",
    flexDirection: "row",
  },
})
