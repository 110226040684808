import React, { useEffect } from "react"
import { View, Text, StyleSheet, BackHandler, Dimensions, Platform } from "react-native"
import { FontAwesome, AntDesign } from "@expo/vector-icons"
import NextButton from "../components/NextButton"
import DefaultTheme from "../themes/DefaultTheme"
import { useStatus } from "../providers/StatusProvider"
import { STATE_VIEW_PARAMS_DOCUMENT } from "../data/params"
import RotateIcon from "../animations/RotateIcon"

const windowWidth = Dimensions.get("window").width
const windowHeight = Dimensions.get("window").height

export default function LoadingScreen() {
  const {
    status,
    setStatus,
    setTitle,
    setMessage,
    setPercentUploaded,
    statusEnum,
    title,
    message,
    percentUploaded,
    action,
    setAction,
    setToScreen,
    toScreen,
  } = useStatus()

  const middleContentHeight = 150

  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", handleBackButton)
    return () => {
      BackHandler.removeEventListener("hardwareBackPress", handleBackButton)
      setStatus(statusEnum.NONE)
      setTitle("")
      setMessage("")
      setPercentUploaded(0)
      setAction({ type: {}, params: {} })
      setToScreen("")
    }
  }, [])

  function handleBackButton() {
    return true
  }

  const handlePress = () => {
    setStatus(statusEnum.NONE)
  }

  return (
    <View style={[styles.overlayContainer]}>
      <View
        style={[
          DefaultTheme.safeTop,
          {
            flex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
            paddingTop: windowHeight / 2 - middleContentHeight,
          },
        ]}
      >
        <Text style={{ fontSize: DefaultTheme.fonts.big, textAlign: "center", padding: DefaultTheme.padding.s }}>
          {title}
        </Text>

        <View
          style={{ height: middleContentHeight, justifyContent: "center", paddingVertical: DefaultTheme.padding.m }}
        >
          <RotateIcon width={50} icon={<AntDesign name="loading1" size={50} color={DefaultTheme.colors.primary} />} />

          {status === statusEnum.SUCCESS || status === statusEnum.ERROR ? (
            <FontAwesome {...STATE_VIEW_PARAMS_DOCUMENT[status]} size={60} />
          ) : (
            <Text></Text>
          )}
        </View>

        <Text style={{ fontSize: 20, textAlign: "center" }}>{message}</Text>
      </View>

      <NextButton
        visible={status === statusEnum.SUCCESS || status === statusEnum.ERROR}
        disabled={!(status === statusEnum.SUCCESS || status === statusEnum.ERROR)}
        text="OK"
        onPress={handlePress}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  overlayContainer: {
    zIndex: 999,
    flex: 1,
    position: "absolute",
    width: windowWidth,
    height: windowHeight,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
  },
})
