import React, { useEffect, useState } from "react"
import { TouchableOpacity, View, StyleSheet, Text, ScrollView } from "react-native"
import { CheckBox } from "react-native-elements"
import { TextInput } from "react-native-paper"
import AppPicker from "../../components/AppPicker"
import NextButton from "../../components/NextButton"
import Screen from "../../components/Screen"
import {
  attendanceTypeEnum,
  attendancePlaceEnum,
  attendanceOperationTypeEnum,
  covidResultEnum,
  holidayStateEnum,
  monthStateEnum,
} from "../../data/enums"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useData } from "../../providers/DataProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import { isAccountant, isAdmin, isExecutive, isOfficeManager } from "../../utils/validation"
import { firebase } from "../../firebase/config"
import ApproveButtons from "../../components/ApproveButtons"
import { changeHolidayState, getHolidayState, isDateDifferent } from "../../utils/general"
import { covidTestLabels } from "../../data/labels"
import {homeOfficePerMonthTotal, sickDaysPerYearTotal} from "../../data/companyConstants";

export default function AttendanceDayDetail({ navigation, route }) {
  const { currentUser } = useData()
  const [dayAttendance, setDayAttendance] = useState(route.params?.dayAttendance || null)
  const [currentYearMonthly, setCurrentYearMonthly] = useState(null)
  const [lastRecords, setLastRecords] = useState([])
  const [lastRecordsCalendar, setLastRecordsCalendar] = useState([])
  const [sickDays, setSickDays] = useState(0)
  const [homeOfficeDays, setHomeOfficeDays] = useState(0)

  const userEmail = route.params?.userEmail
  const task = route.params?.task || { id: dayAttendance?.task_id }
  const monthIndex = route.params?.monthIndex || 0
  const freeDaysCount = route.params?.freeDaysCount || 0
  const closedState = route.params?.closedState || monthStateEnum.OPEN

  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  const hasEditPermission =
    isAdmin(currentUser) ||
    isExecutive(currentUser) ||
    isAccountant(currentUser) ||
    isOfficeManager(currentUser) ||
    monthIndex <= 0 ||
    closedState === monthStateEnum.OPEN

  console.debug("DAY ATTENDANCE", dayAttendance)

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      onSnapshotAttendances()
    }
    return () => {
      isSubscribed = false
    }
  }, [])

  function onSnapshotAttendances() {
    try {
      firebase.apps[0]
        .firestore()
        .collection("attendances")
        .doc(userEmail)
        .onSnapshot((doc) => {
          if (route.params?.taskAttendanceDate) {
            const taskAttendanceDate = route.params.taskAttendanceDate
            const monthlyAttendances = mapMonthlyAttendances(
              doc.data()?.[`monthly_${taskAttendanceDate.getFullYear()}`]
            )
            setCurrentYearMonthly(monthlyAttendances)
            setDayAttendance(
              monthlyAttendances[taskAttendanceDate.getMonth()].find(
                (att) => att.attendance_date.getDate() === taskAttendanceDate.getDate()
              )
            )
          } else {
            setCurrentYearMonthly(
              mapMonthlyAttendances(doc.data()?.[`monthly_${dayAttendance.attendance_date.getFullYear()}`])
            )
          }

          console.debug("calculate free sickdays per actual year:", dayAttendance.attendance_date.getFullYear())
          const monthlyThisYear = doc.data()?.[`monthly_${dayAttendance.attendance_date.getFullYear()}`]
          let counter = 0
          for (const month in monthlyThisYear) {
            counter += monthlyThisYear[month].reduce((a, c) => {
              return (a + (c.attendance_type === attendanceTypeEnum.SICKDAY ? 1 : 0))
            }, 0)
          }
          console.debug("sickdays sickdays per actual year:", counter)
          setSickDays(counter)

          const monthIndex = dayAttendance.attendance_date.getMonth()
          console.debug("calculate homeoffice per actual month:", monthIndex)
          if (monthlyThisYear[monthIndex]) {
            let homeOfficeDays = monthlyThisYear[monthIndex].reduce((a, c) => {
              return (a + (c.attendance_type === attendanceTypeEnum.HOMEOFFICE ? 1 : 0))
            }, 0)
            console.debug("homeoffice per actual month:", homeOfficeDays)
            setHomeOfficeDays(homeOfficeDays)
          }

          setLastRecords(doc.data()?.last_records || [])
          setLastRecordsCalendar(doc.data()?.last_records_calendar || [])
        })
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  function mapMonthlyAttendances(currentYearMonthly) {
    let obj = {}
    for (const monthDate in currentYearMonthly) {
      obj = {
        ...obj,
        [monthDate]: currentYearMonthly[monthDate].map((attendance) => {
          if (attendance.holiday_from) {
            return {
              ...attendance,
              attendance_date: attendance.attendance_date.toDate(),
              created_at: attendance.created_at.toDate(),
              holiday_from: attendance.holiday_from.toDate(),
              holiday_to: attendance.holiday_to.toDate(),
            }
          } else {
            return {
              ...attendance,
              attendance_date: attendance.attendance_date.toDate(),
              created_at: attendance.created_at.toDate(),
            }
          }
        }),
      }
    }
    return obj
  }

  function handleAttendanceTypeChange(attendance_type, overtime_hours) {
    let requiredData = {}
    if (dayAttendance.covid_test) {
      requiredData = { covid_test: dayAttendance.covid_test }
    }

    if (dayAttendance.attendance_type === attendance_type) {
      setDayAttendance({
        ...requiredData,
        attendance_date: dayAttendance.attendance_date,
        attendance_type:
          yesterday.getTime() > dayAttendance.attendance_date.getTime()
            ? attendanceTypeEnum.NOT_STATED_PAST
            : attendanceTypeEnum.NOT_STATED_FUTURE,
      })
    } else if (attendance_type === attendanceTypeEnum.WORK) {
      setDayAttendance({
        ...requiredData,
        attendance_date: dayAttendance.attendance_date,
        attendance_type: attendance_type,
        overtime_hours,
      })
    } else if (attendance_type === attendanceTypeEnum.HOMEOFFICE) {
      setDayAttendance({
        ...requiredData,
        attendance_date: dayAttendance.attendance_date,
        attendance_type: attendance_type,
      })
    } else if (attendance_type === attendanceTypeEnum.SICKDAY) {
      setDayAttendance({
        ...requiredData,
        attendance_date: dayAttendance.attendance_date,
        attendance_type: attendance_type,
      })
    } else if (attendance_type === attendanceTypeEnum.HOLIDAY) {
      setDayAttendance({
        ...requiredData,
        attendance_date: dayAttendance.attendance_date,
        attendance_type: attendance_type,
        attendance_place: attendancePlaceEnum.CZ,
      })
    }
  }

  const appPickerOvertimeHourItems = [
    { label: "1 hodina", value: 1 },
    { label: "2 hodiny", value: 2 },
    { label: "3 hodiny", value: 3 },
    { label: "4 hodiny", value: 4 },
    { label: "5 hodin", value: 5 },
    { label: "6 hodin", value: 6 },
  ]

  const appPickerHolidayPlaceItems = [
    { key: attendancePlaceEnum.CZ, label: "ČR", value: attendancePlaceEnum.CZ },
    { key: attendancePlaceEnum.OTHER, label: "mimo ČR", value: attendancePlaceEnum.OTHER },
  ]

  const appPickerCovidResultItems = Object.keys(covidResultEnum).map((result) => ({
    label: covidTestLabels[result],
    value: result,
  }))

  async function handleAttendanceSave() {
    const month = dayAttendance.attendance_date.getMonth()
    const yearMonthly = `monthly_${dayAttendance.attendance_date.getFullYear()}`

    let newMonth = currentYearMonthly[month] || []
    newMonth = newMonth.map((attendance) =>
      dayAttendance.attendance_date.getDate() === attendance.attendance_date.getDate()
        ? { ...dayAttendance, created_at: new Date(), created_by: currentUser.email }
        : { ...attendance, created_at: new Date(), created_by: currentUser.email }
    )

    if (
      !newMonth.find((attendance) => dayAttendance.attendance_date.getDate() === attendance.attendance_date.getDate())
    ) {
      newMonth.push({ ...dayAttendance, created_at: new Date(), created_by: currentUser.email })
    }

    if (dayAttendance.attendance_type === attendanceTypeEnum.HOLIDAY && !dayAttendance.operations) {
      lastRecords.push({ attendance_date: dayAttendance.attendance_date, yearMonthly, created_at: new Date() })
    }

    // add to last_records_calendar if holiday is approved
    if (
      dayAttendance.attendance_type === attendanceTypeEnum.HOLIDAY &&
      dayAttendance.operations?.find((o) => o.action_type === attendanceOperationTypeEnum.HOLIDAY_APPROVE)
        ?.action_value === holidayStateEnum.ACCEPTED
      // in order to prevent duplicities
      // && !lastRecordsCalendar.some(
      //   (record) => !isDateDifferent(record.attendance_date?.toDate(), dayAttendance.attendance_date)
      // )
    ) {
      lastRecordsCalendar.push({
        attendance_date: dayAttendance.attendance_date,
        attendance_type: dayAttendance.attendance_type,
        created_at: new Date(),
        created_by: currentUser.email,
      })
    }

    // add to last_records_calendar HOMEOFFICE & SICKDAY
    if (
      dayAttendance.attendance_type === attendanceTypeEnum.HOMEOFFICE ||
      dayAttendance.attendance_type === attendanceTypeEnum.SICKDAY
    ) {
      lastRecordsCalendar.push({
        attendance_date: dayAttendance.attendance_date,
        attendance_type: dayAttendance.attendance_type,
        created_at: new Date(),
        created_by: currentUser.email,
      })
    }

    await FSStoreContent.updateAttendance({
      [yearMonthly]: { ...currentYearMonthly, [month]: newMonth },
      userEmail,
      last_records: lastRecords,
      last_records_calendar: lastRecordsCalendar,
    })

    if (task?.id || dayAttendance?.task_id) {
      await FSStoreContent.markTaskAsDone(task?.id || dayAttendance.task_id)
    }
    navigation.goBack()
  }

  function isSaveAttendanceEnabled() {
    const generalApproval =
      dayAttendance.attendance_type === attendanceTypeEnum.HOLIDAY
        ? dayAttendance.attendance_type === attendanceTypeEnum.HOLIDAY && dayAttendance.attendance_place
        : dayAttendance.attendance_type

    return (hasEditPermission && generalApproval) || dayAttendance.covid_test?.result
  }

  console.debug("ATT DETAIL HOLIDAYS", freeDaysCount)
  console.info("CAN SAVVE: ", isSaveAttendanceEnabled())
  return (
    <Screen>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={{ flexShrink: 1, borderBottomWidth: 1, borderBottomColor: DefaultTheme.colors.borderPrimary }}>
          <Text style={styles.sectionTitle}>Pracovní den</Text>
          <CheckBox
            containerStyle={DefaultTheme.checkBoxContainer}
            size={30}
            checkedColor={hasEditPermission ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled}
            checked={dayAttendance.attendance_type === attendanceTypeEnum.WORK || dayAttendance.attendance_type === attendanceTypeEnum.HOMEOFFICE  }
            onPress={() => handleAttendanceTypeChange(attendanceTypeEnum.WORK, 0)}
            title="Odpracovaných 8,5h"
            disabled={!hasEditPermission}
          />
          { (homeOfficeDays < homeOfficePerMonthTotal || dayAttendance.attendance_type === attendanceTypeEnum.HOMEOFFICE) && (
          <CheckBox
              containerStyle={DefaultTheme.checkBoxContainer}
              size={30}
              checkedColor={hasEditPermission ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled}
              checked={dayAttendance.attendance_type === attendanceTypeEnum.HOMEOFFICE}
              onPress={() => handleAttendanceTypeChange(attendanceTypeEnum.HOMEOFFICE, 0)}
              title="Práce z domova"
              disabled={!hasEditPermission}
          /> )
          }
          { (sickDays < sickDaysPerYearTotal || dayAttendance.attendance_type === attendanceTypeEnum.SICKDAY) && (
            <CheckBox
                containerStyle={DefaultTheme.checkBoxContainer}
                size={30}
                checkedColor={hasEditPermission ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled}
                checked={dayAttendance.attendance_type === attendanceTypeEnum.SICKDAY}
                onPress={() => handleAttendanceTypeChange(attendanceTypeEnum.SICKDAY, 0)}
                title="Sickday"
                disabled={!hasEditPermission}
            /> )
          }

          <CheckBox
            containerStyle={DefaultTheme.checkBoxContainer}
            size={30}
            checkedColor={hasEditPermission ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled}
            checked={dayAttendance.overtime_hours}
            onPress={() => {
              let requiredData = {}
              if (dayAttendance.covid_test) {
                requiredData = { covid_test: dayAttendance.covid_test }
              }

              setDayAttendance({
                ...requiredData,
                attendance_date: dayAttendance.attendance_date,
                attendance_type: attendanceTypeEnum.WORK,
                overtime_hours: dayAttendance.overtime_hours > 0 ? 0 : 1,
              })
            }}
            title="Dnes jsem dělal přesčas"
            disabled={!hasEditPermission}
          />
          {dayAttendance.overtime_hours > 0 && (
            <AppPicker
              value={dayAttendance.overtime_hours}
              descriptionLabel="Přesčas"
              placeholder={{ label: "Vyberte počet hodin přesčas", value: 0 }}
              onValueChange={(value) => setDayAttendance({ ...dayAttendance, overtime_hours: Number(value) })}
              items={appPickerOvertimeHourItems}
              disabled={!hasEditPermission}
            />
          )}
        </View>
        <View
          style={{
            flexShrink: 1,
            borderBottomWidth: 1,
            borderBottomColor: DefaultTheme.colors.borderPrimary,
            paddingBottom: 10,
          }}
        >
          <Text style={styles.sectionTitle}>Dovolená</Text>
          {(!hasEditPermission || freeDaysCount <= 0) && (
            <Text style={{ paddingHorizontal: 10 }}>V tomto roce už nemáte žádnou dovolenou</Text>
          )}
          <CheckBox
            containerStyle={DefaultTheme.checkBoxContainer}
            size={30}
            checkedColor={hasEditPermission ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled}
            checked={dayAttendance.attendance_type === attendanceTypeEnum.HOLIDAY}
            onPress={() => handleAttendanceTypeChange(attendanceTypeEnum.HOLIDAY)}
            title="Tento den chci dovolenou"
            disabled={!hasEditPermission || freeDaysCount <= 0}
          />
          {dayAttendance.attendance_type === attendanceTypeEnum.HOLIDAY && (
            <AppPicker
              value={dayAttendance.attendance_place}
              descriptionLabel="Místo dovolené"
              placeholder={{ label: "Vyberte místo dovolené", value: "" }}
              onValueChange={(value) => setDayAttendance({ ...dayAttendance, attendance_place: value })}
              items={appPickerHolidayPlaceItems}
              disabled={!hasEditPermission || freeDaysCount <= 0}
            />
          )}
          {(isAdmin(currentUser) || isExecutive(currentUser)) &&
            dayAttendance.attendance_type === attendanceTypeEnum.HOLIDAY && (
              <View
                style={{
                  paddingHorizontal: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: DefaultTheme.fonts.regular }}>Chcete schválit dovolenou?</Text>
                <ApproveButtons
                  initialState={getHolidayState(dayAttendance)}
                  action_type={attendanceOperationTypeEnum.HOLIDAY_APPROVE}
                  changeValue={(actionType, value) =>
                    setDayAttendance({
                      ...dayAttendance,
                      operations: changeHolidayState(dayAttendance, actionType, value, currentUser),
                    })
                  }
                />
              </View>
            )}
        </View>
        <View style={{ flexShrink: 0.5 }}>
          <Text style={styles.sectionTitle}>Testování COVID</Text>
          <CheckBox
            containerStyle={DefaultTheme.checkBoxContainer}
            size={30}
            onPress={() => {
              if (dayAttendance.covid_test) {
                delete dayAttendance.covid_test
                setDayAttendance({ ...dayAttendance })
              } else {
                setDayAttendance({ ...dayAttendance, covid_test: { result: covidResultEnum.NEGATIVE } })
              }
            }}
            title="Dnes proběhlo testování"
            checkedColor={hasEditPermission ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled}
            checked={dayAttendance.covid_test}
            disabled={!hasEditPermission}
          />
          {dayAttendance.covid_test && (
            <AppPicker
              style={{ flexShrink: 1 }}
              value={dayAttendance.covid_test.result}
              descriptionLabel="Výsledek testu"
              placeholder={{}}
              onValueChange={(value) => setDayAttendance({ ...dayAttendance, covid_test: { result: value } })}
              items={appPickerCovidResultItems}
              disabled={!hasEditPermission}
            />
          )}
        </View>
      </ScrollView>

      <TextInput
        style={{ ...DefaultTheme.input, marginTop: 10 }}
        label="Napište poznámku"
        value={dayAttendance.note}
        onChangeText={(value) => setDayAttendance({ ...dayAttendance, note: value })}
      />
      <NextButton text="Uložit" onPress={handleAttendanceSave} disabled={!isSaveAttendanceEnabled()} />
    </Screen>
  )
}

const styles = StyleSheet.create({
  contentWrapper: {
    flex: 1,
  },
  checkBoxTitle: {
    fontSize: DefaultTheme.fonts.regular,
  },
  checkBoxWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  sectionTitle: { padding: 10, fontSize: DefaultTheme.fonts.medium, fontWeight: "bold" },
})
