import React, { useState, useEffect } from "react"
import { Text, View, StyleSheet, Switch, ScrollView } from "react-native"
import { TextInput } from "react-native-paper"
import { CheckBox, Slider } from "react-native-elements"
import { AntDesign, FontAwesome5, FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons"

import { roleLabels } from "../../data/labels"
import Validation, {
  isAdmin,
  isExecutive,
  isOfficeManager,
  isOnlyInvestor,
  numberOfPasswordChars,
} from "../../utils/validation"
import DefaultTheme from "../../themes/DefaultTheme"
import NextButton from "../../components/NextButton"
import Screen from "../../components/Screen"
import { useStatus } from "../../providers/StatusProvider"
import {
  appPickerViewType,
  approveEnum,
  statusEnum,
  userRoleEnum,
  userStateEnum,
  usersViewTypeEnum,
} from "../../data/enums"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useData } from "../../providers/DataProvider"
import AppDatePicker from "../../components/AppDatePicker"
import ApproveButtonsModular from "../../components/ApproveButtonsModular"
import { toCurrentDate } from "../../utils/general"

export default function UserDetail({ navigation, route }) {
  const today = new Date()
  const birthSample = new Date(today.getFullYear() - 19, today.getMonth(), today.getDay())
  const { currentUser, users } = useData()
  const [user, setUser] = useState(
    route.params
      ? route.params.user
      : {
          email: "",
          name: "",
          password: "",
          phone: "",
          roles: [],
          disabled: false,
          birth: birthSample,
          driver_training: approveEnum.PENDING,
          medical_examination: approveEnum.PENDING,
          el_equipment_revision: approveEnum.PENDING,
          dashboard_closings: approveEnum.PENDING,
          export_user: false,
          export_user_development: false,
        }
  )
  const oldEmail = route.params?.user?.email || ""
  const isCompanyView = route.params?.viewType === usersViewTypeEnum.COMPANY
  const { setTitle, setMessage, setStatus } = useStatus()

  const userExisted = !!route.params?.user

  console.debug("user", user)

  const validatePassword = () => {
    return (
      (userExisted &&
        user?.password !== "" &&
        user?.password?.length > 0 &&
        !Validation.isPasswordValid(user.password)) ||
      (!userExisted && !Validation.isPasswordValid(user.password))
    )
  }
  const hasEditPermission = isAdmin(currentUser) || isExecutive(currentUser) || isOfficeManager(currentUser)

  const duties = [
    { label: "BOZP", propName: "bozp", icon: <AntDesign name="Safety" size={26} color="black" /> },
    { label: "PO", propName: "po", icon: <FontAwesome5 name="fire-extinguisher" size={25} color="black" /> },
    { label: "První pomoc", propName: "first_aid", icon: <FontAwesome5 name="first-aid" size={24} color="black" /> },
    { label: "Školení řidičů", propName: "driver_training", icon: <FontAwesome name="car" size={25} color="black" /> },
    {
      label: "Lékařská prohlídka",
      propName: "medical_examination",
      icon: <MaterialCommunityIcons name="doctor" size={33} color="black" />,
    },
    {
      label: "Revize elektrozařízení",
      propName: "el_equipment_revision",
      icon: <FontAwesome name="bolt" size={25} color="black" />,
    },
  ]

  function changePropertyValue(propName, value) {
    setUser({ ...user, [propName]: value })
  }

  async function renewUser() {
    try {
      setTitle("Obnovuji uživatele")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")
      const updatedUser = user
      updatedUser.dashboard_closings = approveEnum.REJECTED
      delete updatedUser.state
      updatedUser.disabled = false
      await FSStoreContent.setUser(updatedUser)
        .then(() => {
          setMessage("Uživatel byl nahrán úspěšně!")
          setStatus(statusEnum.SUCCESS)
          navigation.pop()
        })
        .catch((error) => {
          setMessage(error.message)
          setStatus(statusEnum.error)
        })
    } catch (error) {
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
    }
  }

  async function saveUser() {
    try {
      setTitle("Nahrávám uživatele")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      if (user.disabled && user.email === currentUser.email) {
        throw { message: "Pokoušíte se deaktivovat sama sebe, tato operace není možná" }
      }

      const tempUser = { ...user }

      if (oldEmail === tempUser.email || oldEmail === "") {
        if (!userExisted || (userExisted && tempUser.password)) tempUser.password_changed = true
        if (isOnlyInvestor(user) || tempUser.disabled) tempUser.dashboard_closings = approveEnum.REJECTED

        await FSStoreContent.updateUser(tempUser)
          .then(() => {
            setMessage("Uživatel byl nahrán úspěšně!")
            setStatus(statusEnum.SUCCESS)
            navigation.pop()
          })
          .catch((error) => {
            setMessage(error.message)
            setStatus(statusEnum.error)
          })
      } else {
        changePropertyValue("email", oldEmail)
        throw new Error("Změna emailu není podporována")
      }
    } catch (error) {
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
    }
  }

  function handleCheckBox(key) {
    const roles = user.roles.includes(key) ? user.roles.filter((role) => role !== key) : user.roles.concat(key)
    setUser({ ...user, roles })
  }

  function getRoleLabels() {
    if (isAdmin(currentUser)) {
      return roleLabels
    } else {
      delete roleLabels[userRoleEnum.ADMIN]
      return roleLabels
    }
  }

  function isUserDisabled() {
    return (
      !(user.name && user.phone && Validation.isEmailValid(user.email) && user.roles.length > 0 && hasEditPermission) ||
      ((!oldEmail || user.password) && !Validation.isPasswordValid(user.password))
    )
  }

  return (
    <Screen>
      <ScrollView>
        {!isCompanyView && (
          <>
            {!isOnlyInvestor(user) && (
              <>
                <View>
                  <Text style={styles.text}>Školení</Text>
                  {/* TODO set date dynamicaly */}
                  {/* <Text style={[DefaultTheme.hint, { paddingHorizontal: 10 }]}>
                Nejbližší obnovení {toCurrentDate(new Date(2022, 4, 10))}
              </Text> */}
                </View>
                {duties.map(({ label, propName, icon }) => (
                  <View
                    key={propName}
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 10,
                      paddingLeft: 10,
                    }}
                  >
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                      <View style={{ width: 50, alignItems: "center" }}>{icon}</View>
                      <Text style={{ paddingLeft: 10 }}>{label}</Text>
                    </View>
                    <ApproveButtonsModular
                      value={user[propName]}
                      onAccept={() =>
                        setUser({
                          ...user,
                          [propName]:
                            user[propName] === approveEnum.ACCEPTED ? approveEnum.PENDING : approveEnum.ACCEPTED,
                        })
                      }
                      onReject={() =>
                        setUser({
                          ...user,
                          [propName]:
                            user[propName] === approveEnum.REJECTED ? approveEnum.PENDING : approveEnum.REJECTED,
                        })
                      }
                    />
                  </View>
                ))}
              </>
            )}
            {!isOnlyInvestor(user) && (
              <>
                <Text style={styles.text}>Přehled</Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 10,
                    paddingLeft: 10,
                    height: 100,
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View style={{ width: 50, alignItems: "center" }}>
                      <MaterialCommunityIcons name="calendar-multiple-check" size={33} color="black" />
                    </View>
                    <Text style={{ paddingLeft: 10 }}>Uzávěrky</Text>
                  </View>
                  <View style={{ flexDirection: "column", alignItems: "flex-end", height: 70 }}>
                    <ApproveButtonsModular
                      value={user?.dashboard_closings}
                      onAccept={() =>
                        setUser({
                          ...user,
                          dashboard_closings:
                            user?.dashboard_closings === approveEnum.ACCEPTED
                              ? approveEnum.PENDING
                              : approveEnum.ACCEPTED,
                        })
                      }
                      onReject={() =>
                        setUser({
                          ...user,
                          dashboard_closings:
                            user?.dashboard_closings === approveEnum.REJECTED
                              ? approveEnum.PENDING
                              : approveEnum.REJECTED,
                        })
                      }
                    />
                  </View>
                </View>
              </>
            )}
            <Text style={styles.text}>Stav uživatele</Text>
            <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
              <Switch
                style={{ alignSelf: "flex-start", marginLeft: 30 }}
                trackColor={{ false: "#767577", true: "#00c650" }}
                thumbColor={user.disabled ? "red" : "green"}
                ios_backgroundColor="#3e3e3e"
                onValueChange={(value) => changePropertyValue("disabled", !value)}
                value={!user.disabled}
                disabled={!hasEditPermission}
              />
              <Text style={{ paddingLeft: 15 }}>{user.disabled ? "Neaktivní" : "Aktivní"}</Text>
            </View>
            <Text style={styles.text}>Přihlášení do DASAR Invest</Text>
            <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
              <Switch
                style={{ alignSelf: "flex-start", marginLeft: 30 }}
                trackColor={{ false: "#767577", true: "#00c650" }}
                thumbColor={!user.export_user ? "red" : "green"}
                ios_backgroundColor="#3e3e3e"
                onValueChange={() => changePropertyValue("export_user", !user?.export_user)}
                value={user.export_user}
                disabled={!hasEditPermission}
              />
              <Text style={{ paddingLeft: 15 }}>{!user.export_user ? "Ne" : "Ano"}</Text>
            </View>

            <Text style={styles.text}>Přihlášení do DASAR Development</Text>
              <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
                <Switch
                  style={{ alignSelf: "flex-start", marginLeft: 30 }}
                  trackColor={{ false: "#767577", true: "#00c650" }}
                  thumbColor={!user.export_user_development ? "red" : "green"}
                  ios_backgroundColor="#3e3e3e"
                  onValueChange={() => changePropertyValue("export_user_development", !user?.export_user_development)}
                  value={user.export_user_development}
                  disabled={!hasEditPermission}
                />
               <Text style={{ paddingLeft: 15 }}>{!user.export_user_development ? "Ne" : "Ano"}</Text>
            </View>
          </>
        )}

        <Text style={styles.text}>Osobní údaje</Text>
        <TextInput
          error={!user.name}
          style={DefaultTheme.input}
          label="Celé jméno"
          placeholder="Celé jméno"
          placeholderTextColor="#aaaaaa"
          onChangeText={(value) => changePropertyValue("name", value)}
          value={user.name}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
          disabled={!hasEditPermission || isCompanyView}
        />

        {!isCompanyView && (
          <AppDatePicker
            label="Datum narození"
            webLabel={["Den narození", "Měsíc narození", "Rok narození"]}
            value={user.birth || birthSample}
            onConfirm={(date) => changePropertyValue("birth", date || new Date())}
            disabled={!hasEditPermission}
            viewType={appPickerViewType.USER}
          />
        )}

        <TextInput
          error={!user.phone}
          style={DefaultTheme.input}
          label={user.phone ? "Telefon" : "Vyplňte prosím telefon"}
          placeholderTextColor="#aaaaaa"
          placeholder="Váš telefon"
          onChangeText={(value) => changePropertyValue("phone", value)}
          value={user.phone}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
          disabled={!hasEditPermission || isCompanyView}
        />
        <TextInput
          error={!Validation.isEmailValid(user.email)}
          style={DefaultTheme.input}
          label={Validation.shouldWarningEmail(user.email) ? "Email" : "Vyplňte prosím validní email"}
          placeholderTextColor="#aaaaaa"
          placeholder="Váš email"
          onChangeText={(value) => changePropertyValue("email", value)}
          value={user.email}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
          disabled={!hasEditPermission || isCompanyView}
        />
        <Text
          style={[DefaultTheme.hint, { padding: 5, paddingLeft: 25 }]}
        >{`* Heslo musí mít minimálně ${numberOfPasswordChars} znaků`}</Text>
        <Text style={[DefaultTheme.hint, { padding: 5, paddingLeft: 25 }]}>* 1 velké písmeno</Text>
        <Text style={[DefaultTheme.hint, { padding: 5, paddingLeft: 25 }]}>* 1 číslici</Text>
        {!isCompanyView && (
          <TextInput
            error={(!oldEmail || user.password) && !Validation.isPasswordValid(user.password)}
            style={DefaultTheme.input}
            placeholderTextColor="#aaaaaa"
            label={Validation.isPasswordValid(user.password) ? "Nové heslo" : `Zadejte nové heslo`}
            placeholder="Heslo"
            onChangeText={(value) => changePropertyValue("password", value)}
            value={user.password}
            underlineColorAndroid="transparent"
            autoCapitalize="none"
            disabled={!hasEditPermission}
            secureTextEntry
          />
        )}
        <Text style={styles.text}>Role</Text>
        <View style={DefaultTheme.checkBoxesWrapper}>
          {Object.keys(getRoleLabels()).map((roleKey) => (
            <CheckBox
              containerStyle={DefaultTheme.checkBoxContainer}
              key={roleKey}
              size={30}
              onPress={() => handleCheckBox(roleKey)}
              title={roleLabels[roleKey]}
              checkedColor={DefaultTheme.colors.primary}
              checked={user.roles.includes(roleKey)}
              disabled={!hasEditPermission || isCompanyView}
            />
          ))}
        </View>
      </ScrollView>
      {!isCompanyView && (
        <NextButton
          disabled={isUserDisabled()}
          text={user.state === userStateEnum.DELETED ? "Obnovit uživatele" : "Uložit"}
          onPress={user.state === userStateEnum.DELETED ? renewUser : saveUser}
        />
      )}
    </Screen>
  )
}

const styles = StyleSheet.create({
  checkBoxWrapper: {
    // marginVertical: 10
  },
  text: {
    fontSize: DefaultTheme.fonts.regular,
    fontWeight: "bold",
    marginLeft: DefaultTheme.margin.s,
    marginTop: DefaultTheme.margin.l,
    marginBottom: DefaultTheme.margin.s,
  },
})
