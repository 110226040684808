import React, { useState, useEffect, useRef, useCallback } from "react"
import { StyleSheet, FlatList, Text, TouchableOpacity, View, ActivityIndicator } from "react-native"
import { FAB, TextInput } from "react-native-paper"
import {
  documentStateEnum,
  scanTypeEnum,
  documentViewTypeEnum,
  statusEnum,
  actionEnum,
  userRoleEnum,
  paymentEnum,
} from "../../data/enums"
import DefaultTheme from "../../themes/DefaultTheme"
import { useData } from "../../providers/DataProvider"
import Screen from "../../components/Screen"
import { useStatus } from "../../providers/StatusProvider"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import FlatListAccountingDocumentItem from "../../components/FlatLists/FlatListAccountingDocumentItem"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import { openFileStorage, openLibrary } from "../../actions/platformFeatureRequestor"
import { FontAwesome } from "@expo/vector-icons"
import FSStoreContent from "../../firebase/FSStoreContent";
const docsPerBatch = 10

export default function AccountingDocumentsOverview({ navigation }) {
  const { accountingDocuments, currentUser } = useData()
  const { setMessage, setStatus } = useStatus()
  const [filteredDocs, setFilteredDocs] = useState(null)
  const [filterVisible, setFilterVisible] = useState(false)
  const [isFABOpen, setIsFABOpen] = useState(false)
  const [paymentStateFilter, setPaymentStateFilter] = useState(0)

  const pageIndex = useRef(0)

  const getNewDocs = () => {

    const adminProjectId = FSStoreContent.getAdminProjectId()

    pageIndex.current += 1
    if (!filterVisible && filteredDocs) {
      let accDocArr =
        currentUser.roles.includes(userRoleEnum.EXECUTIVE) || currentUser.roles.includes(userRoleEnum.OFFICE_MANAGER)
          ? accountingDocuments
          : accountingDocuments.filter((el) => {
              if (el.project !== adminProjectId || (el.project === adminProjectId && el.created_by === currentUser.email)) return el
            })
      accDocArr = accDocArr.filter((el) => {
        if (paymentStateFilter === 0) return el
        else if (paymentStateFilter === 1) {
          if (
            el.payment_info?.paidConfirmed ||
            el.payment === paymentEnum.CASH ||
            el.payment === paymentEnum.DEBIT_CARD ||
            el.id === "T8eCzrdQf8Mno8ZYe2MN"
          )
            return el
        } else {
          if (
            !(
              el?.payment_info?.paidConfirmed ||
              el.payment === paymentEnum.CASH ||
              el.payment === paymentEnum.DEBIT_CARD ||
              el.id === "T8eCzrdQf8Mno8ZYe2MN"
            )
          )
            return el
        }
      })
      accDocArr = accDocArr.slice(0, (pageIndex.current + 1) * docsPerBatch)
      setFilteredDocs(accDocArr)
    }
  }

  useEffect(() => {
    if (!filterVisible) {
      setFilteredDocs([])
      getNewDocs()
    }
  }, [accountingDocuments, paymentStateFilter])

  async function handleOpenPdf() {
    try {
      const { localUri, fileExt } = await openFileStorage()
      console.debug("LOCAL URI", localUri)

      if (localUri) {
        if (!(fileExt === "pdf" || fileExt == "PDF")) {
          throw { message: "Soubor musí být formátu pdf!" }
        }

        navigation.navigate("VerifyScan", { type: scanTypeEnum.FILE, uriList: [localUri] })
      }
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  async function handleOpenLibrary() {
    try {
      const localUri = await openLibrary()
      console.debug("LOCAL URI", localUri)

      if (localUri) {
        navigation.navigate("VerifyScan", { type: scanTypeEnum.IMAGE, uriList: [localUri] })
      }
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  return (
    <Screen>
      {console.debug("currentpage: ", pageIndex.current, " num of docs: ", filteredDocs?.length)}
      {!filteredDocs ? (
        <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignSelf: "center" }}>
          <ActivityIndicator size="large" />
        </View>
      ) : (
        <FlatList
          ListEmptyComponent={<AppEmptyScreenView message="Nemáte žádné doklady" />}
          ListHeaderComponent={
            <FilterHeader
              accountingDocuments={accountingDocuments}
              setFilteredDocs={setFilteredDocs}
              filterVisible={filterVisible}
              setFilterVisible={setFilterVisible}
              pageIndex={pageIndex.current}
              loadNew={getNewDocs}
              setPaymentStateFilter={setPaymentStateFilter}
              paymentStateFilter={paymentStateFilter}
            />
          }
          data={filteredDocs}
          renderItem={({ item }) => (
            <FlatListAccountingDocumentItem
              document={item}
              viewType={documentViewTypeEnum.OVERVIEW}
              toScreen="AccountingDocumentsOverview"
            />
          )}
          initialNumToRender={10}
          keyExtractor={(item) => item.id.toString()}
          ItemSeparatorComponent={FlatListSeparator}
          onEndReached={getNewDocs}
          extraData={accountingDocuments}
        />
      )}
      <FAB.Group
        style={DefaultTheme.fabGroup}
        color={DefaultTheme.colors.fabAdd}
        icon="plus"
        fabStyle={{ backgroundColor: DefaultTheme.colors.white }}
        visible={true}
        loading={true}
        open={isFABOpen}
        onStateChange={(state) => setIsFABOpen(state.open)}
        actions={[
          {
            icon: "camera",
            onPress: () => navigation.navigate("CaptureImage", { action: actionEnum.NEW }),
          },
          {
            icon: "image",
            onPress: handleOpenLibrary,
          },
          {
            icon: "file-pdf-box",
            onPress: handleOpenPdf,
          },
        ]}
      />
    </Screen>
  )
}

function FilterHeader({
  accountingDocuments,
  setFilteredDocs,
  filterVisible,
  setFilterVisible,
  pageIndex,
  loadNew,
  setPaymentStateFilter,
  paymentStateFilter,
}) {
  const [variable, setVariable] = useState("")

  useEffect(() => {
    if (filterVisible) {
      setFilteredDocs(accountingDocuments.filter((doc) => doc.payment_info?.variable?.includes(variable)))
    } else {
      loadNew()
    }
  }, [variable, filterVisible])

  const setPaymentFilter = (option) => {
    if (!filterVisible) {
      setPaymentStateFilter(option)
    }
  }

  const handleFilterVisibility = () => {
    if (filterVisible) {
      setPaymentStateFilter(0)
    }
    setFilterVisible(!filterVisible)
  }

  return (
    <View>
      <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: 60 }}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: 320 }}>
          <TouchableOpacity
            onPress={() => setPaymentFilter(0)}
            style={[
              style.paymentFilterElement,
              {
                backgroundColor: filterVisible
                  ? DefaultTheme.colors.disabled
                  : paymentStateFilter === 0
                  ? "rgba(120, 142, 236,1)"
                  : "rgba(120, 142, 236,0.3)",
              },
            ]}
          >
            <Text style={style.paymentFilterText}>Všechny</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setPaymentFilter(1)}
            style={[
              style.paymentFilterElement,
              {
                backgroundColor: filterVisible
                  ? DefaultTheme.colors.disabled
                  : paymentStateFilter === 1
                  ? "rgba(0,255,0,1)"
                  : "rgba(0,255,0,0.3)",
              },
            ]}
          >
            <Text style={style.paymentFilterText}>Zaplacené</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setPaymentFilter(2)}
            style={[
              style.paymentFilterElement,
              {
                backgroundColor: filterVisible
                  ? DefaultTheme.colors.disabled
                  : paymentStateFilter === 2
                  ? "rgba(255, 165, 0,1)"
                  : "rgba(255, 165, 0,0.3)",
              },
            ]}
          >
            <Text style={style.paymentFilterText}>Nezaplacené</Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          onPress={() => handleFilterVisibility()}
          style={{ justifyContent: "flex-end", padding: 5, flexDirection: "row" }}
        >
          <Text style={{ paddingRight: 5 }}>{!filterVisible ? "Zobrazit filtery" : "Skrýt filtery"}</Text>
          <FontAwesome name="filter" size={DefaultTheme.icons.s} color={DefaultTheme.colors.primary} />
        </TouchableOpacity>
      </View>

      {filterVisible && (
        <View style={{ padding: 10 }}>
          <TextInput
            style={DefaultTheme.input}
            value={variable}
            onChangeText={setVariable}
            label={variable ? "Variabilní symbol" : "Zadejte variabilní symbol"}
          />
        </View>
      )}
    </View>
  )
}

const style = StyleSheet.create({
  paymentFilterElement: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 5,
    borderRadius: 5,
    height: 30,
    width: 100,
  },
  paymentFilterText: {
    color: "white",
    fontWeight: "bold",
  },
})
