import React, { useEffect, useState } from "react"
import {StyleSheet, View, Text, FlatList, Dimensions, ScrollView, Animated, TouchableOpacity} from "react-native"
import {daysInMonth, getDocumentState, loadTriviDocuments, toCurrentDateTime} from "../../utils/general"
import DefaultTheme from "../../themes/DefaultTheme"
import { approveEnum, documentStateEnum, documentViewTypeEnum, userRoleEnum } from "../../data/enums"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import FlatListAccountingDocumentItem from "../../components/FlatLists/FlatListAccountingDocumentItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import CurrencyFormatter from "../../components/CurrencyFormatter"
import { PieChart } from "react-native-chart-kit"
import { FontAwesome5, FontAwesome } from "@expo/vector-icons"
import { STATE_VIEW_PARAMS_DOCUMENT } from "../../data/params"
import FSStoreContent from "../../firebase/FSStoreContent"
import { getGeneratedExtraCashflowDocs } from "../../utils/backendUrls"
import Constants from "expo-constants";
import FlatListExtraCashflow from "../../components/FlatLists/FlatListExtraCashflow";
import FlatListExtraCashflowItem from "../../components/FlatLists/FlatListExtraCashflowItem";
import NumberFormatter from "../../components/NumberFormatter";

export default function ProjectDetail({ route, navigation }) {
  const { firebaseConfig, extraDocs } = useData()
  const projectId = route.params?.project.id || ""
  const { projects, accountingDocuments, currentUser } = useData()
  const [project, setProject] = useState({})
  const [filteredDocuments, setFilteredDocuments] = useState([])
  const [documentsLoading, setDocumentsLoading] = useState(true)

  const draftProfit = Number((project?.draft_income - project?.draft_outcome).toFixed(2)) || 0
  const draftMargin = Number(((1 - project?.draft_outcome / project?.draft_income) * 100).toFixed(2)) || 0
  const draft_blocking_guaranty_in_percentage = Number(project?.draft_blocking_guaranty_in_percentage?.toFixed(2)) || 0
  const draft_blocking_realisation_in_percentage =
    Number(project?.draft_blocking_realisation_in_percentage?.toFixed(2)) || 0
  const [actualIncomeTemp, setActualIncomeTemp] = useState((project.statistics?.income || 0) + (project.statistics?.income_extra || 0))
  const [actualOutcomeTemp, setActualOutcomeTemp] = useState(project.statistics?.outcome || 0)

  const [cashflowIncome, setCashflowIncome] = useState(0)
  const [cashflowOutcome, setCashflowOutcome] = useState( 0)
  const [cashflowDocs, setcashflowDocs] = useState( [])

  const actualOutcome = Number((actualOutcomeTemp + cashflowOutcome).toFixed(0))
  const actualIncome = Number((actualIncomeTemp + cashflowIncome).toFixed(0))
  const actualProfit = Number((actualIncome - actualOutcome).toFixed(0)) || 0
  const actualMargin = Number(((1 - actualOutcome / actualIncome) * 100).toFixed(2)) || 0

  const [sumDocumentsPending, setSumDocumentsPending] = useState(0)
  const [sumDocumentsAccepted, setSumDocumentsAccepted] = useState(0)
  const [sumDocumentsRejected, setSumDocumentsRejected] = useState(0)
  const sumDocuments = sumDocumentsAccepted + sumDocumentsPending + sumDocumentsRejected

  console.debug("PROJECT", project)

  useEffect(() => {
    let isSubscribed = true

    if (isSubscribed) {
      loadProject()
      loadDocuments()
      loadCashflow()
    }

    return () => {
      isSubscribed = false
    }
  }, [projects, accountingDocuments])

  const loadProject = () => {
    if (projects.length > 0) {
      const proj = projects.find((project) => project.id === projectId)
      setProject(proj)
      setActualIncomeTemp((proj.statistics?.income || 0) + (proj.statistics?.income_extra || 0))
      setActualOutcomeTemp(proj.statistics?.outcome || 0)
    }
  }

  function hashInt (str, max = 1000) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = ((hash << 5) - hash) + str.charCodeAt(i);
      hash = hash & hash;
    }
    return Math.round(max * Math.abs(hash) / 2147483648);
  }

  const loadDocuments = async () => {
    const result = accountingDocuments.filter((document) => document.project === projectId)

    setFilteredDocuments(result)

    setSumDocumentsAccepted(countState(result, documentStateEnum.ACCEPTED))
    setSumDocumentsRejected(countState(result, documentStateEnum.REJECTED))
    setSumDocumentsPending(countState(result, documentStateEnum.PENDING))

    setDocumentsLoading(false)
  }

  // load extra CashFlow docs
  const loadCashflow = async () => {

    const docs = extraDocs || []
    console.log("getGeneratedExtraCashflowDocs:" + JSON.stringify(docs))

    if (docs && docs.length > 0) {
      const docsOnProject = docs.filter((document) => document.project === projectId)
      console.log("docsOnProject:" + JSON.stringify(docsOnProject))

      const docsOnProjectIncome = docsOnProject.filter((document) => document.direction === 1)  // příjem
      const docsOnProjectOutcome = docsOnProject.filter((document) => document.direction === 0) // výdej

      let sumCashflowIncomeOnProject = 0
      docsOnProjectIncome.forEach(doc => sumCashflowIncomeOnProject += doc.total)
      let sumCashflowOutcomeOnProject = 0
      docsOnProjectOutcome.forEach(doc => sumCashflowOutcomeOnProject += doc.total)
      console.log("sumCashflowIncomeOnProject " + JSON.stringify(sumCashflowIncomeOnProject))
      console.log("sumCashflowOutcomeOnProject " + JSON.stringify(sumCashflowOutcomeOnProject))
      setCashflowIncome(sumCashflowIncomeOnProject)
      setCashflowOutcome(sumCashflowOutcomeOnProject)

      const docsWithId = docsOnProject.map(doc => { return { ...doc, id: Math.random() } } )

      setcashflowDocs(docsWithId)
    } else {
      setcashflowDocs([])
    }
  }

  const countState = (accountingDocuments, state) => {
    return accountingDocuments.reduce((a, c) => {
      if (c.operations) return a + (getDocumentState(c) === state ? 1 : 0)
      if (state !== documentStateEnum.PENDING) return a + 0
      return a + 1
    }, 0)
  }

  const pieChartData = [
    {
      sumOfDocs: sumDocumentsAccepted,
      color: "green",
    },
    {
      sumOfDocs: sumDocumentsRejected,
      color: "red",
    },
    {
      sumOfDocs: sumDocumentsPending,
      color: "orange",
    },
  ]

  const pieChartConfig = {
    color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
  }

  const isUserPermittedToView = (sourceArr, roles) => {
    if (!sourceArr) return false
    const userIndex = sourceArr?.map((el) => el.email).indexOf(currentUser?.email)
    if (userIndex < 0) return true
    const result = sourceArr[userIndex]?.roles.some((role) => roles.includes(role))
    console.debug("source arr:", sourceArr, "index:", userIndex, "res", result)
    return result
  }

  const [isExpanded, setIsExpanded] = useState(false);

  const ExpandableView = ({ expanded = false }) => {
    const [height] = useState(new Animated.Value(0));

    useEffect(() => {
      Animated.timing(height, {
        toValue: !expanded ? 200 : 0,
        duration: 150,
        useNativeDriver: false
      }).start();
    }, [expanded, height]);

    // console.log('rerendered');

    return (
        <Animated.View
            style={{ height, backgroundColor: "orange" }}
        ></Animated.View>
    );
  };

  console.debug("project", project)
  function getHeader() {
    return (
      <View>
        <View style={styles.row}>
          <Text style={styles.alignText}>Původní náklad</Text>

          <Text>{CurrencyFormatter(project?.draft_outcome || 0, "CZK", 0)}</Text>
        </View>
        <View style={styles.lastRow}>
          <Text style={styles.alignText}>Původní prodej</Text>
          <Text>{CurrencyFormatter(project?.draft_income || 0, "CZK", 0)}</Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.alignText}>Původní realizační zádržné</Text>
          <Text>{draft_blocking_realisation_in_percentage}&nbsp;%</Text>
        </View>
        <View style={styles.lastRow}>
          <Text style={styles.alignText}>Původní záruční zádržné</Text>
          <Text>{draft_blocking_guaranty_in_percentage}&nbsp;%</Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.alignText}>Původní marže</Text>
          <Text>{draftMargin}&nbsp;%</Text>
        </View>
        <View style={styles.lastRow}>
          <Text style={styles.alignText}>Původní zisk</Text>
          <Text>{CurrencyFormatter(draftProfit || 0, "CZK", 0)}&nbsp;</Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.alignText}>Aktuální náklad</Text>
          <Text>{CurrencyFormatter( actualOutcome || 0, "CZK", 0)}&nbsp;</Text>
        </View>
        <View style={styles.lastRow}>
          <Text style={styles.alignText}>Aktuální prodej</Text>
          <Text>{CurrencyFormatter(actualIncome || 0, "CZK", 0)}&nbsp;</Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.alignText}>Aktuální marže</Text>
          <Text>{actualMargin}&nbsp;%</Text>
        </View>
        <View style={styles.lastRow}>
          <Text style={styles.alignText}>Aktuální zisk</Text>
          <Text>{CurrencyFormatter(actualProfit || 0, "CZK", 0)}&nbsp;</Text>
        </View>
        {filteredDocuments.length > 0 ? (
          <View style={{ paddingHorizontal: DefaultTheme.padding.s, backgroundColor: "white" }}>
            <View style={styles.sumDocsWrapper}>
              <Text style={{ fontWeight: "bold" }}>Doklady přiřazené ke zakázce</Text>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={{ fontWeight: "bold", paddingRight: 5 }}>Celkem {sumDocuments} ks</Text>
                <FontAwesome5
                  name="file-invoice-dollar"
                  size={DefaultTheme.icons.m}
                  color={DefaultTheme.colors.primary}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <PieChart
                data={pieChartData}
                width={Dimensions.get("window").width / 2}
                height={180}
                chartConfig={pieChartConfig}
                accessor="sumOfDocs"
                backgroundColor="transparent"
                hasLegend={false}
                paddingLeft={Dimensions.get("window").width / 4 - 50}
              />
              <View style={{ flex: 1, justifyContent: "center" }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: 10,
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <FontAwesome
                      {...STATE_VIEW_PARAMS_DOCUMENT[approveEnum.ACCEPTED]}
                      size={DefaultTheme.icons.m}
                      style={{ paddingRight: 5, width: 30 }}
                    />
                    <Text style={{ fontWeight: "bold" }}>
                      {((sumDocumentsAccepted / sumDocuments) * 100).toFixed(2)} %
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text style={{ paddingRight: 5, fontWeight: "bold" }}>{sumDocumentsAccepted} ks</Text>
                    <FontAwesome5 name="file-invoice-dollar" size={DefaultTheme.icons.m} color="green" />
                  </View>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-between", paddingBottom: 10 }}>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <FontAwesome
                      {...STATE_VIEW_PARAMS_DOCUMENT[approveEnum.REJECTED]}
                      size={DefaultTheme.icons.m}
                      style={{ paddingRight: 5, width: 30 }}
                    />
                    <Text style={{ fontWeight: "bold" }}>
                      {((sumDocumentsRejected / sumDocuments) * 100).toFixed(2)} %
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text style={{ paddingRight: 5, fontWeight: "bold" }}>{sumDocumentsRejected} ks</Text>
                    <FontAwesome5 name="file-invoice-dollar" size={DefaultTheme.icons.m} color="red" />
                  </View>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <FontAwesome
                      {...STATE_VIEW_PARAMS_DOCUMENT[approveEnum.PENDING]}
                      size={DefaultTheme.icons.m}
                      style={{ paddingRight: 5, width: 30 }}
                    />
                    <Text style={{ fontWeight: "bold" }}>
                      {((sumDocumentsPending / sumDocuments) * 100).toFixed(2)} %
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text style={{ paddingRight: 5, fontWeight: "bold" }}>{sumDocumentsPending} ks</Text>
                    <FontAwesome5 name="file-invoice-dollar" size={DefaultTheme.icons.m} color="orange" />
                  </View>
                </View>
              </View>
            </View>
            <FlatListSeparator />

            {/* <View style={styles.documentsInfoRow}>
              <Text style={{}}>Schválené</Text>
              <Text style={{}}>{sumDocumentsAccepted}</Text>
            </View>
            <View style={styles.documentsInfoRow}>
              <Text style={{}}>Neschválené</Text>
              <Text style={{}}>{sumDocumentsRejected}</Text>
            </View>
            <View style={[styles.documentsInfoRow, { paddingBottom: DefaultTheme.padding.s / 2 }]}>
              <Text style={{}}>Čekající</Text>
              <Text style={{}}>{sumDocumentsPending}</Text>
            </View> */}
          </View>
        ) : documentsLoading ? (
          <View>
            <Text style={{ alignSelf: "center", paddingTop: 5, fontWeight: "bold" }}>Doklady se načítají</Text>
          </View>
        ) : (
          <View>
            <Text style={{ textAlign: "center", paddingTop: 5, fontWeight: "bold" }}>
              K projektu nejsou žádné doklady nebo vy nejste přiřazen k projektu
            </Text>
          </View>
        )}
        { currentUser.roles.includes(userRoleEnum.EXECUTIVE) ?
            (
              <View>
                <FlatList
                    ListHeaderComponent={null}
                    data={cashflowDocs}
                    renderItem={({item}) => (
                        <FlatListExtraCashflowItem item={item}/>
                    )}
                    keyExtractor={(item) => item.id.toString()}
                    ItemSeparatorComponent={FlatListSeparator}
                />
              </View>
            ) : (<View></View>)
          }
      </View>
    )
  }

  return (
    // <Screen barStyle="light-content">
    <Screen>
      {/* TEMPORARY REPORTING*/}
      {projectId !== FSStoreContent.getAdminProjectId() ||
      currentUser.roles.includes(userRoleEnum.EXECUTIVE) ||
      currentUser.roles.includes(userRoleEnum.OFFICE_MANAGER) ||
      (projectId === FSStoreContent.getAdminProjectId() && isUserPermittedToView(project?.users, [userRoleEnum.EXECUTIVE])) ? (
        <FlatList
          ListHeaderComponent={getHeader}
          data={filteredDocuments}
          renderItem={({ item }) => (
            <FlatListAccountingDocumentItem
              document={item}
              navigation={navigation}
              viewType={documentViewTypeEnum.OVERVIEW}
              toScreen={{ screen: "ProjectDetail", params: { project: project } }}
            />
          )}
          keyExtractor={(item) => item.id.toString()}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        getHeader()
      )}
    </Screen>
  )
}

const styles = StyleSheet.create({
  alignText: {
    alignSelf: "flex-start",
    flex: 1,
  },
  container: {
    flex: 1,
  },
  documentsInfoRow: {
    flexDirection: "row",
    paddingHorizontal: DefaultTheme.padding.s,
    justifyContent: "space-between",
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 2,
  },
  headerItem: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    padding: 15,
  },
  label: {
    flex: 1,
    alignItems: "center",
  },
  row: {
    flexDirection: "row",
    padding: 10,
    justifyContent: "space-evenly",
  },
  lastRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    padding: 10,
    paddingHorizontal: 0,
    marginHorizontal: 10,
    justifyContent: "space-evenly",
  },
  TextInputStyle: {
    textAlign: "center",
    height: 40,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: "#009688",
    marginBottom: 10,
  },
  card: {
    height: 100,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    paddingRight: 10,
    borderBottomColor: "#bdbdbd",
    borderBottomWidth: 1,
    borderTopColor: "#bdbdbd",
    borderTopWidth: 1,
    flexDirection: "row",
    borderLeftWidth: 10,
  },
  name: {
    paddingLeft: 10,
    marginRight: "auto",
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
  sumDocsWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: DefaultTheme.padding.s,
    backgroundColor: "white",
  },

  app: {},
  toggle: {
    width: 100,
    height: 30,
    backgroundColor: "blue",
    justifyContent: "center",
    alignItems: "center"
  },
  toggleText: {
    color: "#fff"
  }
})
