import React from "react"
import NextButton from "../../components/NextButton"
import Screen from "../../components/Screen"
import { scanTypeEnum, documentTypeEnum, documentViewTypeEnum } from "../../data/enums"
import DocumentImage from "../../components/DocumentImage"
import { useNavigation } from "@react-navigation/core"
import { DocumentDataWithSave } from "../../components/documents/DocumentDataWithSave"

export default function VerifyScan({ route }) {
  const navigation = useNavigation()
  const belongsTo = route.params?.belongsTo
  const hideNextButton = route.params?.hideNextButton
  const isPreview = route.params.isPreview
  const viewType = route.params?.viewType
  const type = route.params?.type
  const uriList = route.params?.uriList
  const uriPreview = route.params?.uriPreview || uriList[0]
  const docType = route.params.docType

  if (viewType === documentViewTypeEnum.COMPANY && !isPreview) {
    return (
      <Screen>
        <DocumentImage type={type} uriList={[uriPreview]} />
        {type === scanTypeEnum.FILE && (
          <DocumentDataWithSave belongsTo={belongsTo} type={type} uriList={[uriPreview]} />
        )}
      </Screen>
    )
  }

  function handlePressNext() {
    if (type === scanTypeEnum.IMAGE) {
      if (docType === documentTypeEnum.CONTRACT || documentTypeEnum.DOCUMENTATION) {
        navigation.navigate("ScansOverview", { type, uriList, viewType, docType })
      } else {
        navigation.navigate("ScansOverview", { type, uriList, viewType, belongsTo })
      }
    } else {
      navigation.navigate("AccountingDocumentInfo", { type, uriList })
    }
  }

  return (
    <Screen>
      <DocumentImage type={type} uriList={[uriPreview]} />
      <NextButton text="Dále" disabled={!uriPreview} onPress={handlePressNext} showIcon visible={!hideNextButton} />
    </Screen>
  )
}
