import React, {useEffect} from "react"
import { LogBox as OriginalLogBox, Platform, StyleSheet } from "react-native"
import { Provider as PaperProvider } from "react-native-paper"
import DefaultTheme from "./src/themes/DefaultTheme"
import StatusProvider from "./src/providers/StatusProvider"
import { NavigationContainer } from "@react-navigation/native"
import RootStackNavigator from "./src/components/Navigation/RootStackNavigator"
import DataProvider from "./src/providers/DataProvider"
import { StatusBar } from "react-native"
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration"
import * as Sentry from "sentry-expo"
import Constants from "expo-constants"
import { environmentEnum } from "./src/data/enums"

Sentry.init({
  dsn: "https://02a46f1e7fd246a7b21d684ab5775722@o1133152.ingest.sentry.io/6179415",
  enableInExpoDevelopment: true,
  environment: Constants.manifest.extra.environment,
  debug: true
})

let LogBox

if (Platform.OS === "web") {
  LogBox = { ignoreLogs: () => {} }
} else {
  LogBox = OriginalLogBox
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

export default function App() {
  //dont show timer warning and others just for now because it is annoying while testing
  LogBox.ignoreLogs([
    "Setting a timer",
    "Animated: `useNativeDriver` was not specified.",
    "Non-serializable values were found in the navigation state",
    "AsyncStorage has been extracted from react-native core and will be removed in a future release.",
  ])
  //-------------------------------------------------------------------------

  return (
    <StatusProvider>
      <DataProvider>
        <PaperProvider theme={DefaultTheme}>
          {/* <StatusBar barStyle={DefaultTheme.barStyle.dark} /> */}

          <NavigationContainer>
            <RootStackNavigator />
          </NavigationContainer>
        </PaperProvider>
      </DataProvider>
    </StatusProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: DefaultTheme.colors.backgroundPrimary,
    alignItems: "center",
    justifyContent: "center",
  },
})
