import React, { useState, useEffect } from "react"
import { Image, ScrollView, View } from "react-native"

export default function FullWidthImageWeb({ uri, width, onLoadEnd, style }) {
  const [ratio, setRatio] = useState(1)
  const [height, setHeight] = useState(2000)

  useEffect(() => {
    if (uri) {
      Image.getSize(uri, (width, height) => {
        setRatio(width / height)
        height < 2000 && setHeight(height)
      })
    }
  }, [uri])

  return (
    <Image
      style={[
        {
          width: width ?? "95%",
          height: height,
          aspectRatio: ratio,
          alignSelf: "center",
          marginVertical: 10,
          borderRadius: 5,
        },
        style,
      ]}
      resizeMode="contain"
      source={{ uri }}
      onLoadEnd={onLoadEnd}
    />
  )
}
