import React, { useEffect, useState } from "react"
import { Platform, Text, TouchableWithoutFeedback, View } from "react-native"
import DefaultTheme from "../themes/DefaultTheme"
import DateTimePickerModal from "react-native-modal-datetime-picker"
import { monthNameLabels } from "../data/labels"
import AppDatePickerWeb from "./AppDatePickerWeb"

export default function AppDatePicker({
  value,
  onConfirm,
  label,
  disabled = false,
  initialDate,
  webLabel = ["Den", "Měsíc", "Rok"],
  viewType,
  extra,
}) {
  const [show, setShow] = useState(false)

  if (!value) {
    value = new Date()
  } else {
    if (typeof value?.getMonth !== "function") {
      value = value.toDate()
    }
  }

  function handleConfirm(date) {
    setShow(false)
    onConfirm(date)
  }

  return (
    <>
      {Platform.OS !== "web" ? (
        <TouchableWithoutFeedback onPress={() => setShow(true)} disabled={disabled}>
          <View
            style={[
              DefaultTheme.input,
              {
                borderBottomWidth: 1,
                borderBottomColor: DefaultTheme.colors.light,

                justifyContent: "flex-end",
                paddingBottom: 12,
                paddingLeft: 0,
                overflow: "visible",
              },
              disabled ? { opacity: 0.4 } : {},
            ]}
          >
            <Text style={[DefaultTheme.textInputHint, { left: 22 }]}>{label}</Text>
            <Text style={{ left: 22 }}>{`${value?.getDate()}. ${
              monthNameLabels[value?.getMonth()]
            } ${value?.getFullYear()}`}</Text>
            <DateTimePickerModal
              confirmTextIOS="Potvrdit"
              cancelTextIOS="Zrušit"
              headerTextIOS="Vyberte datum"
              accessibilityLabel="asd"
              locale="CS"
              isVisible={show}
              mode="date"
              date={initialDate || value}
              onConfirm={handleConfirm}
              onCancel={() => setShow(false)}
            />
          </View>
        </TouchableWithoutFeedback>
      ) : (
        <AppDatePickerWeb
          value={value}
          onConfirm={onConfirm}
          webLabel={webLabel}
          disabled={disabled}
          viewType={viewType}
          extra={extra}
        />
      )}
    </>
  )
}
