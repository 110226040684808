import React, { useEffect, useState } from "react"
import { Text, View, StyleSheet, FlatList, TouchableOpacity } from "react-native"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import FlatListTaskItem from "../../components/FlatLists/FlatListTaskItem"
import { statusEnum, taskBulkActionEnum, viewTypeEnum } from "../../data/enums"
import AppPicker from "../../components/AppPicker"
import NextButton from "../../components/NextButton"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useStatus } from "../../providers/StatusProvider"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import { taskBulkActionLabels } from "../../data/labels"
import { CheckBox } from "react-native-elements/dist/checkbox/CheckBox"
import DefaultTheme from "../../themes/DefaultTheme"
import { STATE_VIEW_PARAMS_ITEM } from "../../data/params"

export default function TasksBulkAction({ navigation }) {
  const { userTasks } = useData()
  const { setTitle, setStatus, setMessage } = useStatus()
  const [bulkTasks, setBulkTasks] = useState(userTasks)

  const appPickerActionOptions = [
    { label: taskBulkActionLabels[taskBulkActionEnum.DONE], value: taskBulkActionEnum.DONE },
  ]
  const [selectedAction, setSelectedAction] = useState(taskBulkActionEnum.DONE)
  const [allSelected, setAllSelected] = useState(false)

  async function handleTasksUpdate() {
    setStatus(statusEnum.PROGRESS_UNDETERMINED)
    setTitle("Provádím akci")
    navigation.navigate("StatusScreen")
    try {
      for (let i = 0; i < bulkTasks.length; i++) {
        await FSStoreContent.updateTask(bulkTasks[i])
      }
      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
  }

  useEffect(() => {
    setBulkTasks(userTasks)
  }, [userTasks])

  function handleSelectAll() {
    setBulkTasks(
      bulkTasks.map((task) => {
        task.isDone = !allSelected
        return task
      })
    )
    setAllSelected(!allSelected)
  }

  return (
    <Screen>
      <AppPicker
        value={selectedAction}
        descriptionLabel="Akce"
        placeholder={{ label: "Vyberte akci", value: "" }}
        onValueChange={(value) => setSelectedAction(value)}
        items={appPickerActionOptions}
      />
      {userTasks.length > 0 ? (
        <View style={{ flex: 1 }}>
          <View style={styles.allSelected}>
            <TouchableOpacity
              onPress={handleSelectAll}
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text>{!allSelected ? "Označit vše " : "Zrušit vše"}</Text>
              <CheckBox
                style={{ padding: 0, margin: 0 }}
                size={30}
                checkedColor={STATE_VIEW_PARAMS_ITEM[!allSelected].color}
                checked={allSelected}
                onPress={handleSelectAll}
              />
            </TouchableOpacity>
          </View>
          <FlatListSeparator />
          <View style={{ flex: 1 }}>
            <FlatList
              data={bulkTasks}
              renderItem={({ item }) => (
                <FlatListTaskItem
                  task={item}
                  navigation={navigation}
                  viewType={viewTypeEnum.BULK_ACTION}
                  setBulkTasks={setBulkTasks}
                  bulkTasks={bulkTasks}
                />
              )}
              keyExtractor={(item) => item.id.toString()}
              ItemSeparatorComponent={FlatListSeparator}
              extraData={bulkTasks}
            />
          </View>

          <NextButton
            text="Uložit"
            disabled={!bulkTasks.some((task) => task.isDone) || selectedAction === ""}
            onPress={handleTasksUpdate}
          />
        </View>
      ) : (
        <AppEmptyScreenView message="Žádné úkoly k provedení akcí" />
      )}
    </Screen>
  )
}

const styles = StyleSheet.create({
  noTasksWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  allSelected: {
    minHeight: 50,
    alignItems: "flex-end",
    width: "100%",
    backgroundColor: DefaultTheme.colors.white,
  },
})
