import React from "react"
import { Text, View } from "react-native"
import { viewTypeEnum } from "../data/enums"
import DefaultTheme from "../themes/DefaultTheme"
import NumberFormatter from "./NumberFormatter"

export default function PieChartLegendItem({
  description,
  value,
  percentage = null,
  color = DefaultTheme.colors.primary,
  viewType,
  icon,
}) {
  if (viewType === viewTypeEnum.DASHBOARD) {
    return (
      <View style={{ flexDirection: "row", justifyContent: "space-between", paddingVertical: 5, alignItems: "center" }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: 100,
          }}
        >
          <View
            style={{
              backgroundColor: color,
              width: 20,
              height: 20,
              borderRadius: 20 / 2,
              marginRight: 5,
            }}
          />
          <Text style={{ flexWrap: "wrap" }}>{description}</Text>
          {icon}
        </View>
        <Text style={{ paddingLeft: 20, textAlign: "right" }}>{NumberFormatter(value.toFixed(0))}</Text>
      </View>
    )
  }

  return (
    <View style={{ flexDirection: "row", justifyContent: "space-between", paddingTop: 10 }}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text style={{ paddingRight: 5, width: 85, textAlign: "right" }}>
          {typeof percentage === "number" && `${NumberFormatter(percentage.toFixed(2))} %`}
        </Text>

        <View
          style={{
            backgroundColor: color,
            width: 20,
            height: 20,
            borderRadius: 10,
            marginBottom: 5,
            marginRight: 5,
          }}
        />
        <View style={{ flexDirection: "row" }}>
          <Text style={{ paddingRight: description ? 5 : 0 }}>{description}</Text>
          {icon}
        </View>
      </View>
      <Text style={{ paddingLeft: 20, textAlign: "right" }}>{NumberFormatter(value.toFixed(0))}</Text>
    </View>
  )
}
