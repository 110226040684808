import React from "react"
import { StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"

import DefaultTheme from "../../themes/DefaultTheme"
import { viewTypeEnum } from "../../data/enums"
import PhoneNumberFormatter from "../PhoneNumberFormatter"

export default function FlatListCompanyCatalogItem({ company, viewType, onPickSupplier }) {
  const navigation = useNavigation()
  const isPickAction = viewType === viewTypeEnum.PICK

  return (
    <TouchableOpacity
      onPress={() => {
        if (isPickAction) {
          onPickSupplier(company)
        } else {
          navigation.navigate("CompanyCatalogDetail", { companyId: company.id, title: company.name })
        }
      }}
    >
      <View style={styles.card}>
        <MaterialCommunityIcons
          name="office-building"
          size={DefaultTheme.icons.menuSize}
          color={DefaultTheme.colors.primary}
        />
        <View style={{ flex: 1, paddingRight: 10 }}>
          <Text style={styles.name}>{company.name}</Text>
        </View>
        <View style={{ flex: 1.5, paddingRight: 10 }}>
          {company.users.map((u) => (
            <Text key={u.email} style={styles.user}>
              {u.name} - {PhoneNumberFormatter(u.phone || "")} - {u.role}
            </Text>
          ))}
        </View>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    minHeight: 100,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    flexDirection: "row",
    borderLeftWidth: 10,
    backgroundColor: DefaultTheme.colors.white,
    borderLeftColor: DefaultTheme.colors.primary,
  },
  name: {
    paddingLeft: 10,
    marginRight: "auto",
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
  user: {
    padding: 5,
    marginLeft: "auto",
  },
})
