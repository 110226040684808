import React, { useEffect, useState } from "react"
import Screen from "../../components/Screen"
import { ActivityIndicator, FlatList, ScrollView, Text, View } from "react-native"
import FSStoreContent from "../../firebase/FSStoreContent"
import FlatListExtraCashflow from "../../components/FlatLists/FlatListExtraCashflow"
import { FAB } from "react-native-paper"
import DefaultTheme from "../../themes/DefaultTheme"
import {cashFlowTypeEnum} from "../../data/enums";
import {VictoryLabel} from "victory-native";
import {firebase as fb} from "../../firebase/config";

export default function ExtraCashflowOverview({ navigation, route }) {
  const [docs, setDocs] = useState(null)

  function getActiveCashflowDocs() {
    const today = new Date()
    const activeDocs = docs.filter((doc) =>
        (doc.type === cashFlowTypeEnum.ONE_TIME && new Date(doc.values[0].from) >= today)
        || (doc.type !== cashFlowTypeEnum.ONE_TIME && new Date(doc.values[0].to) >= today)
    )
    return activeDocs
  }

  function getNonActiveCashflowDocs() {
    const today = new Date()
    const activeDocs = docs.filter((doc) =>
        (doc.type === cashFlowTypeEnum.ONE_TIME && new Date(doc.values[0].from) < today)
        || (doc.type !== cashFlowTypeEnum.ONE_TIME && new Date(doc.values[0].to) < today)
    )
    return activeDocs
  }

  useEffect(() => {
    const unsub = FSStoreContent.onSnapshotExtraCashflowDocs(setDocs)
    console.debug("TYPE", typeof unsub)
    return () => unsub()
  }, [])

  const handleNewCashflow = () => {
    navigation.navigate("ExtraCashflowDetail", { type: "NEW" })
  }

  return (
    <Screen>
      {!docs ? (
        <ActivityIndicator />
      ) : (
      <>
        <ScrollView style={{ height: "50%"}}>
          <Text style={{ fontSize: DefaultTheme.fonts.big }}>Aktivní</Text>
          <FlatList
            data={getActiveCashflowDocs()}
            renderItem={({ item }) => <FlatListExtraCashflow item={item} navigation={navigation} />}
          />
        </ScrollView>
        <ScrollView style={{ height: "50%"}}>
          <Text style={{ fontSize: DefaultTheme.fonts.big }}>Neaktivní</Text>
          <FlatList
            data={getNonActiveCashflowDocs()}
            renderItem={({ item }) => <FlatListExtraCashflow item={item} navigation={navigation} />}
          />
        </ScrollView>
      </>
      )}
      <FAB
        visible={true}
        style={DefaultTheme.fab}
        color={DefaultTheme.colors.fabAdd}
        icon="plus"
        onPress={handleNewCashflow}
      />
    </Screen>
  )
}
