import React, { useEffect, useState } from "react"
import { FlatList, View } from "react-native"
import { FAB, TextInput } from "react-native-paper"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import AppPicker from "../../components/AppPicker"
import FlatListCompanyCatalogItem from "../../components/FlatLists/FlatListCompanyCatalogItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import Screen from "../../components/Screen"
import { viewTypeEnum } from "../../data/enums"
import pickerItems from "../../data/pickerItems"
import FSContactCompanies from "../../firebase/FSContactCompanies"
import DefaultTheme from "../../themes/DefaultTheme"

const filterCategories = pickerItems.filterCategories

export default function CompanyCatalogOverview({ navigation, route }) {
  const [companies, setCompanies] = useState([])
  const [filteredCompanies, setFilteredCompanies] = useState([])
  const [category, setCategory] = useState({ main: "Stavba", sub: " " })
  const [subCategoryItems, setSubCategoryItems] = useState(
    filterCategories["Stavba"].map((cat) => ({ key: cat, value: cat, label: cat }))
  )

  const [textFilter, setTextFilter] = useState({ person: "", company: "" })

  const [companyFilter, setCompanyFilter] = useState("")
  const [personFilter, setPersonFilter] = useState("")
  const viewType = route.params?.viewType
  const isPickAction = viewType === viewTypeEnum.PICK

  console.debug("COMPANY LIST", companies)
  console.debug("CATEGORY", category)

  useEffect(() => {
    FSContactCompanies.onSnapshotCompanyList((list) => setCompanies(list))
  }, [])

  useEffect(() => {
    let comp = companies

    if (companyFilter) {
      comp = comp.filter((el) => el.name && el.name.toLowerCase().includes(companyFilter.toLowerCase()))
    }
    if (personFilter) {
      comp = comp.filter(
        (el) =>
          el.users.length > 0 &&
          el.users.some((u) => u.name && u.name.toLowerCase().includes(personFilter.toLowerCase()))
      )
    }

    if (category.main === " ") {
      setFilteredCompanies(comp)
    } else if (category.sub === " ") {
      setFilteredCompanies(comp.filter((c) => c.main_category === category.main))
      setSubCategoryItems(filterCategories[category.main].map((cat) => ({ key: cat, value: cat, label: cat })))
    } else {
      setFilteredCompanies(comp.filter((c) => c.main_category === category.main && c.sub_category === category.sub))
      setSubCategoryItems(filterCategories[category.main].map((cat) => ({ key: cat, value: cat, label: cat })))
    }

    console.log("CompanyCatalogOverview filter")
  }, [category, companies, companyFilter, personFilter])

  const categoryItems = Object.keys(filterCategories).map((cat) => ({ key: cat, value: cat, label: cat }))

  return (
    <Screen>
      <View>
        <View style={{ marginVertical: 10, flexDirection: "row" }}>
          <View style={{ flex: 1 }}>
            <AppPicker
              value={category.main}
              descriptionLabel="Hlavní kategorie"
              placeholder={{ label: "Všechny", value: " " }}
              onValueChange={(main) => {
                if (main === " ") {
                  setCategory({ main: " ", sub: " " })
                } else if (!subCategoryItems.includes(category.sub)) {
                  setCategory({ main, sub: " " })
                } else {
                  setCategory({ ...category, main })
                  setSubCategoryItems(filterCategories[main].map((cat) => ({ key: cat, value: cat, label: cat })))
                }
              }}
              items={categoryItems}
            />
          </View>
          <View style={{ flex: 1 }}>
            <AppPicker
              value={category.sub}
              descriptionLabel="Subkategorie"
              placeholder={{ label: "Všechny", value: " " }}
              onValueChange={(sub) => setCategory({ ...category, sub })}
              items={subCategoryItems}
            />
          </View>
        </View>
        <TextInput
          label="Zadejte název firmy"
          value={companyFilter}
          onChangeText={(text) => setCompanyFilter(text)}
          style={DefaultTheme.input}
        />
        <TextInput
          label="Zadejte kontaktní osobu"
          value={personFilter}
          onChangeText={(text) => setPersonFilter(text)}
          style={DefaultTheme.input}
        />
      </View>

      {filteredCompanies.length > 0 ? (
        <FlatList
          data={filteredCompanies}
          renderItem={({ item }) => (
            <FlatListCompanyCatalogItem
              company={item}
              onPickSupplier={route.params?.onPickSupplier}
              viewType={viewType}
            />
          )}
          keyExtractor={(item) => String(item.id)}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="V katalogu ve vybrané kategorii nejsou žádné firmy" />
      )}
      {!isPickAction && (
        <FAB
          visible
          style={DefaultTheme.fab}
          color={DefaultTheme.colors.fabAdd}
          icon="plus"
          onPress={() => navigation.navigate("CompanyCatalogDetail")}
        />
      )}
    </Screen>
  )
}
