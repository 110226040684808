import React, { useState } from "react"
import { TouchableOpacity } from "react-native"
import { Menu } from "react-native-paper"
import { MaterialCommunityIcons, Ionicons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import { useStatus } from "../../providers/StatusProvider"
import { exportDocsByProject } from "../../actions/headerItemActions"
import { projectStateEnum, statusEnum, userRoleEnum } from "../../data/enums"
import FSStoreContent from "../../firebase/FSStoreContent"
import {
  isAccountant,
  isAdmin,
  isExecutive,
  isOfficeManager,
  isProjectManager,
  isTechnicalDirector,
} from "../../utils/validation"
import { useData } from "../../providers/DataProvider"
import { isPropertyBorrowed, isUserAllowed } from "../../utils/general"

export default function ProjectDetailHeaderMenu({ params, navigation }) {
  const { currentUser, firebaseConfig } = useData()
  const [showMenu, setShowMenu] = useState(false)
  const { setTitle, setMessage, setStatus } = useStatus()
  const project = params.project
  const hasEditPermission =
    isAdmin(currentUser) || isExecutive(currentUser) || isTechnicalDirector(currentUser) || isAccountant(currentUser)

  const getProjectExportPermission = () => {

    const adminProjectId = FSStoreContent.getAdminProjectId()

    return isUserAllowed(currentUser.roles, [userRoleEnum.ADMIN, userRoleEnum.EXECUTIVE]) || firebaseConfig === "0"
      ? project.id !== adminProjectId
      : project.id !== "03"
  }
  const hasEditPermissionProperty =
    isAdmin(currentUser) ||
    isExecutive(currentUser) ||
    isTechnicalDirector(currentUser) ||
    isProjectManager(currentUser) ||
    isOfficeManager(currentUser)

  async function changeProjectState() {
    setShowMenu(false)

    const newProjectState = project.state === projectStateEnum.CLOSED ? projectStateEnum.OPEN : projectStateEnum.CLOSED

    setTitle(project.state === projectStateEnum.CLOSED ? "Otevírám projekt" : "Uzavírám projekt")
    setStatus(statusEnum.PROGRESS_UNDETERMINED)
    navigation.pop()
    navigation.navigate("StatusScreen")

    try {
      if (project.state === projectStateEnum.OPEN) {
        await isProjectPropertyBorrowed()
      }

      await FSStoreContent.updateProject({
        ...project,
        state: newProjectState,
        end_date: project.state === projectStateEnum.CLOSED ? new Date() : new Date(),
      })
      setMessage(project.state === projectStateEnum.CLOSED ? "Projekt byl otevřen" : "Projekt byl uzavřen")
      setStatus(statusEnum.SUCCESS)
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
  }

  async function exportProjDocs() {
    try {
      setShowMenu(false)
      setTitle("Generuji doklady do CSV souboru")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      await exportDocsByProject(params, firebaseConfig)

      setStatus(statusEnum.SUCCESS)
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
  }

  async function isProjectPropertyBorrowed() {
    const projectPropertyList = (await FSStoreContent.onSnapshotProperty(() => {})).filter(
      (prop) => prop.assigned_project === project.id
    )

    projectPropertyList.forEach((prop) => {
      if (isPropertyBorrowed(prop)) {
        console.debug("property not returned", prop)
        throw { message: "Z projektu nebyl vrácen veškerý majetek." }
      }
    })
  }

  return (
    <Menu
      visible={showMenu}
      onDismiss={() => setShowMenu(false)}
      anchor={
        <TouchableOpacity onPress={() => setShowMenu(true)} style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.headerSecondary}
          />
        </TouchableOpacity>
      }
    >
      {hasEditPermission && (
        <Menu.Item
          style={{ direction: "ltr" }}
          onPress={() => {
            setShowMenu(false), navigation.navigate("ProjectEdit", params)
          }}
          title="Upravit projekt"
          icon={() => (
            <MaterialCommunityIcons
              name="pencil"
              size={DefaultTheme.icons.headerSizeRight}
              color={DefaultTheme.colors.primary}
            />
          )}
        />
      )}
      <Menu.Item
        onPress={() => {
          setShowMenu(false), navigation.navigate("ProjectPeople", params)
        }}
        icon={() => (
          <Ionicons name="people" size={DefaultTheme.icons.headerSizeRight} color={DefaultTheme.colors.primary} />
        )}
        title="Pracující na projektu"
      />
      {hasEditPermissionProperty && (
        <Menu.Item
          onPress={() => {
            setShowMenu(false), navigation.navigate("ProjectProperty", params)
          }}
          icon={() => (
            <MaterialCommunityIcons
              name="clipboard-text"
              size={DefaultTheme.icons.headerSizeRight}
              color={DefaultTheme.colors.primary}
            />
          )}
          title="Přiřazený majetek"
        />
      )}
      {hasEditPermission && (
        <Menu.Item
          onPress={() => {
            setShowMenu(false), navigation.navigate("ProjectPremium", params)
          }}
          icon={() => (
            <MaterialCommunityIcons
              name="cash-multiple"
              size={DefaultTheme.icons.headerSizeRight}
              color={DefaultTheme.colors.primary}
            />
          )}
          title="Prémie"
        />
      )}
      {getProjectExportPermission() && (
        <Menu.Item
          onPress={exportProjDocs}
          icon={() => (
            <MaterialCommunityIcons
              name="file-export"
              size={DefaultTheme.icons.headerSizeRight}
              color={DefaultTheme.colors.primary}
            />
          )}
          title="Export dokladů"
        />
      )}
      {hasEditPermission && (
        <Menu.Item
          onPress={changeProjectState}
          icon={() => (
            <MaterialCommunityIcons
              name={project.state === projectStateEnum.CLOSED ? "lock-open-variant" : "lock"}
              size={DefaultTheme.icons.headerSizeRight}
              color={DefaultTheme.colors.primary}
            />
          )}
          title={project.state === projectStateEnum.CLOSED ? "Otevřít projekt" : "Uzavřít projekt"}
        />
      )}
    </Menu>
  )
}
