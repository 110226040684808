import React from "react"
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { MaterialIcons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"

import DefaultTheme from "../../themes/DefaultTheme"

export default function FlatListTemplatesOverviewItem({ template, docId, propName, screenTitle }) {
  const navigation = useNavigation()
  console.debug("ITEM", template)

  return (
    <TouchableOpacity
      style={[styles.card]}
      onPress={() => navigation.navigate("TemplateDetail", { template, docId, propName, screenTitle })}
      disabled={template.disabled}
    >
      <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
        <MaterialIcons name="title" size={DefaultTheme.icons.menuSize} color={DefaultTheme.colors.primary} />
        <Text style={{ padding: 5 }}>{template.title}</Text>
      </View>
      <Image source={{ uri: template.filestore_uri }} style={{ height: 80, width: "100%" }} resizeMode="contain" />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    minHeight: 100,
    maxHeight: 120,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    borderLeftWidth: 10,
    borderLeftColor: DefaultTheme.colors.primary,
    paddingVertical: 10,
    flexDirection: "row",
    backgroundColor: DefaultTheme.colors.white,
  },
})
