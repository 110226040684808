import React, { useState } from "react"
import { FlatList } from "react-native"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import FlatListTaskItem from "../../components/FlatLists/FlatListTaskItem"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"

export default function TasksOverview({ navigation }) {
  const { userTasks } = useData()

  return (
    <Screen>
      {userTasks.length > 0 ? (
        <FlatList
          data={userTasks}
          renderItem={({ item }) => <FlatListTaskItem task={item} navigation={navigation} />}
          keyExtractor={(item) => item.id.toString()}
          ItemSeparatorComponent={FlatListSeparator}
          extraData={userTasks}
        />
      ) : (
        <AppEmptyScreenView message="Nemáte žádné úkoly" />
      )}
    </Screen>
  )
}
