import React, { useRef, useEffect, useState } from "react"
import { Text, View, StyleSheet, TouchableOpacity } from "react-native"
import { AntDesign } from "@expo/vector-icons"
import DefaultTheme from "../themes/DefaultTheme"

export default function NextButton({
  onPress,
  text,
  showIcon = false,
  disabled = false,
  width = "100%",
  style,
  visible = true,
}) {
  const icon = showIcon ?? true
  const [pressed, setPressed] = useState(false)
  const timer = useRef(null)
  useEffect(() => {
    return () => clearTimeout(timer.current)
  }, [pressed])
  return (
    <>
      {visible ? (
        <TouchableOpacity
          disabled={disabled && pressed}
          style={[disabled ? styles.buttonDisabled : styles.button, { width }, style]}
          onPress={() => {
            if (!pressed && !disabled) {
              onPress()
              setPressed(true)
            }
            timer.current = setTimeout(() => {
              setPressed(false)
            }, 1000)
          }}
        >
          <Text style={styles.buttonTitle}>{text}</Text>
          {icon ? <AntDesign style={styles.icon} name="arrowright" size={24} color="white" /> : <View />}
        </TouchableOpacity>
      ) : (
        <View style={{ height: 70 }} />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  button: {
    width: "100%",
    backgroundColor: DefaultTheme.colors.primary,
    height: 70,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonDisabled: {
    width: "100%",
    backgroundColor: DefaultTheme.colors.disabled,
    height: 70,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonTitle: {
    color: DefaultTheme.colors.white,
    fontSize: DefaultTheme.fonts.regular,
    fontWeight: "bold",
  },
  icon: {
    position: "absolute",
    right: 20,
  },
})
