import React, { useState } from "react"
import Screen from "../../components/Screen"
import NextButton from "../../components/NextButton"
import { TextInput } from "react-native-paper"
import DefaultTheme from "../../themes/DefaultTheme"
import { View, TouchableOpacity, Text, StyleSheet, ScrollView } from "react-native"
import ExtraCashflowManager from "../../components/cashflow/ExtraCashflowManager"
import FSStoreContent from "../../firebase/FSStoreContent"
import DayPicker from "../../components/DayPicker"
import { cashFlowTypeEnum, statusEnum } from "../../data/enums"
import DayNamePicker from "../../components/DayNamePicker"
import AppPicker from "../../components/AppPicker"
import { useData } from "../../providers/DataProvider"
import { useStatus } from "../../providers/StatusProvider"

export default function ExtraCashflowDetail({ route, navigation }) {
  const { projects } = useData()
  const { setMessage, setStatus, setTitle } = useStatus()
  const type = route.params.type
  const today = new Date()
  const doc = {}
  if (route.params.document) {
    for (const key in route.params.document) {
      if (key !== "values") {
        doc[key] = route.params.document[key]
      }
    }
  }
  const [document, setDocument] = useState(
    type !== "EXISTING"
      ? {
          type: cashFlowTypeEnum.MONTHLY,
          direction: 0,
          label: "",
          dateIndex: today.getDate(),
          project: projects ? projects[0]?.id.toString() : "",
        }
      : doc
  )
  const [values, setValues] = useState(
    type !== "EXISTING"
      ? [{ value: 0, from: new Date(), to: new Date(), indefinite: true }]
      : route.params?.document?.values
  )
  console.debug("CASHFLOW DETAIL :", document, values)

  const projectLabels = projects.map((el) => {
    return { value: el.id.toString(), label: `${el.id.toString()} - ${el.name.toString()}` }
  })
  const changePropertyValue = (prop, value) => {
    setDocument({ ...document, [prop]: value })
  }

  const handlePress = async () => {
    const tempValues = [...values].map((el) => {
      if (document.type === cashFlowTypeEnum.ONE_TIME) {
        return { ...el, value: Number.parseInt(el.value), to: el.from, indefinite: false }
      }
      return { ...el, value: Number.parseInt(el.value) }
    })
    if (type !== "EXISTING") {
      try {
        setTitle("Ukládám extra doklad")
        setStatus(statusEnum.PROGRESS_DETERMINED)
        navigation.navigate("StatusScreen")
        await FSStoreContent.saveExtraCashflowDoc({ ...document, values: tempValues })
        setStatus(statusEnum.SUCCESS)
        navigation.pop()
      } catch (error) {
        console.error("error occured while saving extraCashflowDoc: ", error)
        setTitle("Chyba při ukládání extra dokladu")
        setMessage(error.message)
        setStatus(statusEnum.ERROR)
      }
    } else {
      try {
        setTitle("Ukládám extra doklad")
        setStatus(statusEnum.PROGRESS_DETERMINED)
        navigation.navigate("StatusScreen")
        FSStoreContent.setExtraCashflowDoc({ ...document, values: tempValues })
        setStatus(statusEnum.SUCCESS)
        navigation.pop()
      } catch (error) {
        console.error("error occured while saving extraCashflowDoc: ", error)
        setTitle("Chyba při ukládání extra dokladu")
        setMessage(error.message)
        setStatus(statusEnum.ERROR)
      }
    }
  }

  const handleDayMonthPicker = (value, prop, extra) => {
    setDocument({ ...document, dateIndex: Number.parseInt(value) })
  }

  return (
    <Screen style={{ flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
      <ScrollView>
        <View
          style={{
            flexDirection: "row",
            marginHorizontal: 10,
            alignItems: "center",
          }}
        >
          <Text style={{ flex: 1 }}>Frekvence</Text>
          <View
            style={{
              flexDirection: "row",
              marginHorizontal: 10,
              alignItems: "center",
              justifyContent: "space-evenly",
              flex: 3,
            }}
          >
            <TouchableOpacity
              onPress={() =>
                setDocument({ ...document, type: cashFlowTypeEnum.ONE_TIME })
              }
              style={[
                style.typeButton,
                {
                  backgroundColor:
                    document.type === cashFlowTypeEnum.ONE_TIME
                      ? DefaultTheme.colors.primary
                      : DefaultTheme.colors.disabled,
                  borderRadius: 10,
                  height: 60,
                },
              ]}
            >
              <Text style={{ color: DefaultTheme.colors.white, fontWeight: "500" }}>Jednorázově</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setDocument({ ...document, type: cashFlowTypeEnum.DAILY })}
              style={[
                style.typeButton,
                {
                  backgroundColor:
                    document.type === "DAILY"
                      ? DefaultTheme.colors.primary
                      : DefaultTheme.colors.disabled,
                  borderRadius: 10,
                  height: 60,
                },
              ]}
            >
              <Text style={{ color: DefaultTheme.colors.white, fontWeight: "500" }}>Denně</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setDocument({ ...document, type: cashFlowTypeEnum.WEEKLY, dateIndex: 1 })}
              style={[
                style.typeButton,
                {
                  backgroundColor:
                    document.type === "WEEKLY" ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled,
                  borderRadius: 10,
                  height: 60,
                },
              ]}
            >
              <Text style={{ color: DefaultTheme.colors.white, fontWeight: "500" }}>Týdně</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setDocument({ ...document, type: cashFlowTypeEnum.MONTHLY })}
              style={[
                style.typeButton,
                {
                  borderRadius: 10,
                  backgroundColor:
                    document.type === "MONTHLY" ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled,
                  height: 60,
                },
              ]}
            >
              <Text style={{ color: DefaultTheme.colors.white, fontWeight: "500" }}>Měsíčně</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center", marginHorizontal: 10 }}>
          <Text>Účetní operace</Text>
          <TouchableOpacity
            onPress={() => setDocument({ ...document, direction: document.direction ? 0 : 1 })}
            style={[
              style.typeButton,
              {
                marginLeft: 20,
                backgroundColor: document.direction === 0 ? DefaultTheme.colors.danger : DefaultTheme.colors.success,
                marginHorizontal: 10,
                borderRadius: 10,
                width: 60,
                height: 60,
              },
            ]}
          >
            <Text style={{ color: DefaultTheme.colors.white, fontWeight: "500" }}>
              {document.direction ? "+" : "-"}
            </Text>
          </TouchableOpacity>
        </View>
        <AppPicker
          descriptionLabel={document.project ? "Projekt" : "Vyberte projekt"}
          onValueChange={(val) => setDocument({ ...document, project: val })}
          value={document.project}
          items={projectLabels}
        />
        <TextInput
          error={!document.label}
          style={[DefaultTheme.input, { marginTop: 10 }]}
          label={document.label ? "Název dokumentu" : "Zadejte název dokumentu"}
          onChangeText={(value) => changePropertyValue("label", value)}
          value={document.label}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />

        {document.type === cashFlowTypeEnum.MONTHLY ? (
          <DayPicker value={document.dateIndex} callback={handleDayMonthPicker} />
        ) : document.type === cashFlowTypeEnum.WEEKLY ? (
          <DayNamePicker
            value={document.dateIndex}
            setValue={(value) => setDocument({ ...document, dateIndex: Number.parseInt(value) })}
            weekDaysOnly={true}
          />
        ) : (
          <></>
        )}
        <ExtraCashflowManager data={values} setData={setValues} type={document.type} />
      </ScrollView>
      <NextButton
        text="Uložit"
        onPress={handlePress}
        disabled={
          !document.label ||
          !document.project ||
          !values[0].value ||
          (!document.type === cashFlowTypeEnum.ONE_TIME && !values[0].indefinite && values[0].from > values[0].to)
        }
      />
    </Screen>
  )
}

const style = StyleSheet.create({
  typeButton: {
    marginVertical: 10,
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
  },
})
