import React, { useEffect, useState } from "react"
import { ScrollView, View, Text, StyleSheet, Platform, TouchableOpacity } from "react-native"
import { TextInput } from "react-native-paper"
import { getLocation } from "../../actions/platformFeatureRequestor"
import { MaterialIcons } from "@expo/vector-icons"

import AppDatePicker from "../../components/AppDatePicker"
import AppPicker from "../../components/AppPicker"
import DateIntervalButtons from "../../components/DateIntervalButtons"
import NavigationPicker from "../../components/NavigationPicker"
import NextButton from "../../components/NextButton"
import PrimButton from "../../components/PrimButton"
import Screen from "../../components/Screen"
import Signature from "../../components/Signature"
import { projectStateEnum, statusEnum, userRoleEnum, userStateEnum } from "../../data/enums"
import { uploadFileOnFirebase } from "../../firebase/FSGeneral"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useData } from "../../providers/DataProvider"
import { useStatus } from "../../providers/StatusProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import { formatDecimalNumber, getUserName, getUserPhone } from "../../utils/general"
import * as Validation from "../../utils/validation"
import { isOnlyInvestor } from "../../utils/validation"
import AddressAutocomplete from "../../components/AddressAutocomplete"
import PhoneNumberFormatter from "../../components/PhoneNumberFormatter"

export default function DiaryMainDirectory({ navigation, route }) {
  const { projects, users, currentUser } = useData()
  const { setTitle, setStatus, setMessage, updatePercentage, setOnConfirm } = useStatus()
  const projectId = route.params?.projectId || "Z1"
  const [isProjectClosed, setIsProjectClosed] = useState(false)
  const [diary, setDiary] = useState({})
  const [hmgDateInterval, setHmgDateInterval] = useState({ from: new Date(), to: new Date() })
  const [pickerUserItems, setPickerUserItems] = useState([])
  const [pickerUserInvestorItems, setPickerUserInvestorItems] = useState([])

  const navigationPickerItems = [
    {
      title: "Projektový manažeři",
      label: "Vyberte projektové manažery",
      propName: "project_manager_list",
      roles: [userRoleEnum.PROJECT_MANAGER],
    },
    {
      title: "Stavbyvedoucí",
      label: "Vyberte stavbyvedoucí",
      propName: "construction_lead_list",
      roles: [userRoleEnum.CONSTRUCTION_LEAD, userRoleEnum.CONSTRUCTION_STAFF],
      // roles: Object.keys(userRoleEnum).map((key) => key),
    },
    {
      title: "Přípraváři a rozpočtáři",
      label: "Vyberte přípraváře a rozpočtáře",
      propName: "construction_planner_and_budgeter_list",
      roles: [userRoleEnum.CONSTRUCTION_LEAD, userRoleEnum.CONSTRUCTION_STAFF],
    },
    {
      title: "Koordinátor BOZP",
      label: "Vyberte koordinátora BOZP",
      propName: "safety_coordinator",
      roles: [userRoleEnum.CONSTRUCTION_STAFF],
    },

  ]

  const navigationPickerItemsInvestors = [
    {
      title: "Zástupce investora",
      label: "Vyberte zástupce investora",
      propName: "investor_representative",
      roles: [userRoleEnum.INVESTOR],
    },
    {
      title: "Projektový manažer",
      label: "Vyberte projektového manažera",
      propName: "investor_project_manager",
      roles: [userRoleEnum.INVESTOR],
    },
    {
      title: "Technický dozor",
      label: "Vyberte technický dozor",
      propName: "investor_technical_supervision",
      roles: [userRoleEnum.INVESTOR],
    },
    {
      title: "Technický dozor TZB",
      label: "Vyberte technický dozor TZB",
      propName: "investor_technical_supervision_tzb",
      roles: [userRoleEnum.INVESTOR],
    },
    {
      title: "Technický dozor ES",
      label: "Vyberte technický dozor ESI",
      propName: "investor_technical_supervision_esi",
      roles: [userRoleEnum.INVESTOR],
    },
    {
      title: "Generální projektant",
      label: "Vyberte generálního projektanta",
      propName: "investor_general_designer",
      roles: [userRoleEnum.INVESTOR],
    },
    {
      title: "Odpovědný projektant",
      label: "Vyberte odpovědného projektanta",
      propName: "investor_responsible_designer",
      roles: [userRoleEnum.INVESTOR],
    },
    {
      title: "Autorský dozor",
      label: "Vyberte autorský dozor",
      propName: "investor_author_supervision",
      roles: [userRoleEnum.INVESTOR],
    },
    {
      title: "Koordinátor BOZP",
      label: "Vyberte koordinátora BOZP",
      propName: "investor_safety_coordinator",
      roles: [userRoleEnum.INVESTOR],
    },
  ]

  const arrayTypeProps = [
    "project_manager_list",
    "construction_lead_list",
    "construction_planner_and_budgeter_list",
    "safety_coordinator",
    "investor_safety_coordinator",
    "investor_author_supervision",
    "investor_responsible_designer",
    "investor_general_designer",
    "investor_technical_supervision_esi",
    "investor_technical_supervision_tzb",
    "investor_technical_supervision",
    "investor_project_manager",
    "investor_representative",
  ]

  useEffect(() => {
    const project = projects.find((p) => p.id === projectId) || {}
    for (const prop in project.diary) {
      if (arrayTypeProps.includes(prop)) {
        if (typeof project.diary[prop] === "string") {
          const value = project.diary[prop]
          project.diary[prop] = [value]
        }
      }
    }
    console.debug("TRANSFORM DB DATA: ", project.diary)
    setIsProjectClosed(project.state === projectStateEnum.CLOSED)
    setDiary({
      general_contractor: "Dasar PR, s.r.o.",
      project_manager_list: [],
      construction_lead_list: [],
      construction_planner_and_budgeter_list: [],
      safety_coordinator: [],
      investor_safety_coordinator: [],
      investor_author_supervision: [],
      investor_responsible_designer: [],
      investor_general_designer: [],
      investor_technical_supervision_esi: [],
      investor_technical_supervision_tzb: [],
      investor_technical_supervision: [],
      investor_project_manager: [],
      investor_representative: [],
      ...project.diary,
      address: { street: "", latitude: "", longitude: "", ...(project.diary?.address || {}) },
    })
    if (project.diary?.hmg) {
      setHmgDateInterval({ from: project.diary.hmg.from.toDate(), to: project.diary.hmg.to.toDate() })
    }
    setPickerUserItems(
      users
        .filter((u) => u.state !== userStateEnum.DELETED && project.users?.some((user) => user.email === u.email))
        .map((u) => ({ value: u.email, label: u.name }))
    )
  }, [projects, users])

  useEffect(() => {
    setPickerUserInvestorItems(
      users
        .filter((u) => u.state !== userStateEnum.DELETED && u.roles.includes(userRoleEnum.INVESTOR))
        .map((u) => ({ value: u.email, label: u.name }))
    )
  }, [users])

  function changePropertyValue(prop, value) {
    setDiary({ ...diary, [prop]: value })
  }

  async function handleSignatureSave(signature, role) {
    try {
      setTitle("Nahrávám podpis")
      setStatus(statusEnum.PROGRESS_DETERMINED)
      navigation.navigate("StatusScreen")

      const firebaseUri = await uploadFileOnFirebase(signature, updatePercentage, "signatures")
      setDiary({ ...diary, signature: { ...diary.signature, [role]: firebaseUri } })

      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  async function handleDiarySave() {
    try {
      setTitle("Nahrávám základní adresář")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      await FSStoreContent.updateProject({ diary: { ...diary, hmg: hmgDateInterval }, id: projectId })

      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  async function handleGetLocation() {
    setTitle("Opravdu chcete opět vyžádat souřadnice podle vaší aktuální polohy?")
    setStatus(statusEnum.PROGRESS_DECIDE_SIMPLE)
    navigation.navigate("StatusScreen")
    setOnConfirm(() => async () => {
      try {
        setStatus(statusEnum.PROGRESS_UNDETERMINED)
        const { coords } = await getLocation()
        setDiary({ ...diary, address: { ...diary.address, latitude: coords.latitude, longitude: coords.longitude } })
        navigation.navigate("DiaryMainDirectory", route.params)
      } catch (error) {
        console.error(error)
        setStatus(statusEnum.ERROR)
        setTitle(error.title || "")
        setMessage(error.message)
        navigation.navigate("StatusScreen")
      }
    })
  }

  function getUserInvestorLabel(label, email) {
    return email ? `${label} - ${email} - ${getUserPhone(users, email)}` : label
  }

  return (
    <Screen>
      <ScrollView>
        <View
          style={{ borderBottomColor: DefaultTheme.colors.borderPrimary, borderBottomWidth: 1, paddingVertical: 10 }}
        >
          {/* TODO adresa stavby - vyber na mape */}
          <Text style={styles.title}>O stavbě</Text>
          <AddressAutocomplete
            disabled={isProjectClosed || isOnlyInvestor(currentUser)}
            address={diary.address?.street}
            onAddressChange={(value) => changePropertyValue("address", { ...diary.address, street: value })}
            onAddressPick={(address, place) => {
              changePropertyValue("address", {
                ...diary.address,
                street: address,
                latitude: place.geometry.location.lat,
                longitude: place.geometry.location.lng,
              })
            }}
            containerStyle={{ marginBottom: 20 }}
          />
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ flex: 1, flexDirection: Platform.OS === "web" ? "row" : "column" }}>
              <TextInput
                error={!Validation.isCoordinate(diary.address?.latitude)}
                style={[DefaultTheme.input, { flex: 1 }]}
                value={String(diary.address?.latitude)}
                keyboardType="numeric"
                label={
                  Validation.isCoordinate(diary.address?.latitude) ? "Zeměpisná šířka" : "Zadejte zeměpisnou šířku"
                }
                onChangeText={(value) =>
                  setDiary({ ...diary, address: { ...diary.address, latitude: formatDecimalNumber(value) } })
                }
              />
              <TextInput
                error={!Validation.isCoordinate(diary.address?.longitude)}
                style={[DefaultTheme.input, { flex: 1 }]}
                value={String(diary.address?.longitude)}
                keyboardType="numeric"
                label={
                  Validation.isCoordinate(diary.address?.longitude) ? "Zeměpisná délka" : "Zadejte zeměpisnou délku"
                }
                onChangeText={(value) =>
                  setDiary({ ...diary, address: { ...diary.address, longitude: formatDecimalNumber(value) } })
                }
              />
            </View>
            <PrimButton
              icon={<MaterialIcons name="my-location" size={18} color={DefaultTheme.colors.white} />}
              height={40}
              onPress={handleGetLocation}
              style={{ marginRight: 10 }}
              width={40}
            />
          </View>
          {/* Oznámení o výběru na zakázku: */}
          <TextInput
            error={!diary.customer}
            style={[DefaultTheme.input, { marginTop: 10 }]}
            label={diary.customer ? "Objednatel" : "Zadejte objednatele"}
            onChangeText={(value) => changePropertyValue("customer", value)}
            value={diary.customer}
            disabled={isProjectClosed || isOnlyInvestor(currentUser)}
          />

          <Text style={{ paddingHorizontal: 10, alignSelf: "center", paddingTop: 10 }}>
            Orientační HMG dle výstavby
          </Text>
          <DateIntervalButtons
            dateInterval={hmgDateInterval}
            disabled={isProjectClosed}
            setDateInterval={setHmgDateInterval}
          />
        </View>
        <View style={{ borderBottomColor: DefaultTheme.colors.borderPrimary, borderBottomWidth: 1 }}>
          <Text style={styles.title}>Zůčastněné strany - DASAR</Text>
          {/* Generální zhotovitel - Fixed value */}
          <TextInput
            style={[DefaultTheme.input, { marginTop: 10 }]}
            label="Generální zhotovitel"
            value={diary.general_contractor}
            disabled
          />
          {/* Zhotovitel stavební části - Možnost s výběru katalogu investorů viz Investoři TRIVI */}
          <TextInput
            error={!diary.construction_contractor}
            style={[DefaultTheme.input, { marginTop: 10 }]}
            label={diary.construction_contractor ? "Zhotovitel stavební části" : "Zadejte zhotovitele stavební části"}
            onChangeText={(value) => changePropertyValue("construction_contractor", value)}
            value={diary.construction_contractor}
            disabled={isProjectClosed || isOnlyInvestor(currentUser)}
          />
          {/* Zhotovitel technologické části - Možnost s výběru katalogu investorů viz Investoři TRIVI */}
          <TextInput
            error={!diary.technological_contractor}
            style={[DefaultTheme.input, { marginTop: 10 }]}
            label={
              diary.technological_contractor
                ? "Zhotovitel technologické části"
                : "Zadejte zhotovitele technologické části"
            }
            onChangeText={(value) => changePropertyValue("technological_contractor", value)}
            value={diary.technological_contractor}
            disabled={isProjectClosed || isOnlyInvestor(currentUser)}
          />
          {/* Projektový manažer - Možnost výběru viz lidi v přehledu projektu včetně telefoního čísla */}
          {/* Stavbyvedoucí - Možnost výběru viz lidi v přehledu projektu včetně telefoního čísla */}
          {/* Přípravář a rozpočtář staveb - Možnost výběru viz lidi v přehledu projektu včetně telefoního čísla */}
          {navigationPickerItems.map(({ label, propName, roles = [], title }) => {
            const onPress = () => {
              navigation.navigate("UsersPickerScreen", {
                addUsers: (emailList) =>
                  setDiary({
                    ...diary,
                    [propName]: emailList,
                  }),
                choosedUsers: diary[propName],
                projectId,
                roles,
                title: label,
              })
            }

            return diary[propName]?.length > 0 ? (
              <TouchableOpacity
                key={propName}
                disabled={isProjectClosed || isOnlyInvestor(currentUser)}
                onPress={onPress}
                style={{
                  marginHorizontal: 10,
                  backgroundColor: DefaultTheme.colors.white,
                  marginBottom: 10,
                  padding: 10,
                }}
              >
                <Text style={{ fontWeight: "bold" }}>{title}</Text>
                { diary[propName]?.map((email) => (
                  <Text key={email} style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                    {getUserName(users, email)} - {email} - {PhoneNumberFormatter(getUserPhone(users, email) || "")}
                  </Text>
                ))}
              </TouchableOpacity>
            ) : (
              <NavigationPicker error key={propName} label={label} onPress={onPress} />
            )
          })}
          {/* Koordinátor BOZP - Možnost výběru viz lidi v přehledu projektu včetně telefoního čísla */}
          {
            //navigationPickerItemsInvestors
          }
          {/*<AppPicker*/}
          {/*  items={pickerUserItems}*/}
          {/*  descriptionLabel="Koordinátor BOZP"*/}
          {/*  placeholder={{ label: "Vyberte koordinátora BOZP", value: "" }}*/}
          {/*  value={diary.investor_safety_coordinator}*/}
          {/*  onValueChange={(value) => changePropertyValue("investor_safety_coordinator", value)}*/}
          {/*  disabled={isProjectClosed || isOnlyInvestor(currentUser)}*/}
          {/*/>*/}
        </View>
        <View style={{ borderBottomColor: DefaultTheme.colors.borderPrimary, borderBottomWidth: 1 }}>
          <Text style={styles.title}>Zůčastněné strany - Investor</Text>
          {/* Zástupce investora */}
          {/* <TextInput
            error={!diary.investor_representative}
            style={[DefaultTheme.input, { marginTop: 10 }]}
            label={diary.investor_representative ? "Zástupce investora" : "Zadejte zástupce investora"}
            onChangeText={(value) => changePropertyValue("investor_representative", value)}
            value={diary.investor_representative}
            disabled={isProjectClosed}
          /> */}
          {navigationPickerItemsInvestors.map(({ label, propName, roles = [], title }) => {
            const onPress = () => {
              console.debug("PROPNAME ", propName, diary[propName])
              navigation.navigate("UsersPickerScreen", {
                addUsers: (emailList) =>
                  setDiary({
                    ...diary,
                    [propName]: emailList,
                  }),
                choosedUsers: diary[propName],
                projectId,
                roles,
                title: label,
              })
            }

            return diary[propName]?.length > 0 ? (
              <TouchableOpacity
                key={propName}
                disabled={isProjectClosed || isOnlyInvestor(currentUser)}
                onPress={onPress}
                style={{
                  marginHorizontal: 10,
                  backgroundColor: DefaultTheme.colors.white,
                  marginBottom: 10,
                  padding: 10,
                }}
              >
                <Text style={{ fontWeight: "bold" }}>{title}</Text>
                {diary[propName]?.map((email) => (
                  <Text key={email} style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                    {getUserName(users, email)} - {email} - {PhoneNumberFormatter(getUserPhone(users, email) || "")}
                  </Text>
                ))}
              </TouchableOpacity>
            ) : (
              <NavigationPicker error key={propName} label={label} onPress={onPress} />
            )
          })}

          {/* Projektový manažer */}
          {/* <TextInput
            error={!diary.investor_project_manager}
            style={[DefaultTheme.input, { marginTop: 10 }]}
            label={diary.investor_project_manager ? "Projektový manažer" : "Zadejte projektového manažera"}
            onChangeText={(value) => changePropertyValue("investor_project_manager", value)}
            value={diary.investor_project_manager}
            disabled={isProjectClosed}
          /> */}

          {/* Technický dozor investora */}
          {/* <TextInput
            error={!diary.investor_technical_supervision}
            style={[DefaultTheme.input, { marginTop: 10 }]}
            label={diary.investor_technical_supervision ? "Technický dozor" : "Zadejte technický dozor"}
            onChangeText={(value) => changePropertyValue("investor_technical_supervision", value)}
            value={diary.investor_technical_supervision}
            disabled={isProjectClosed}
          /> */}

          {/* Technický dozor Investora TZB */}
          {/* <TextInput
            error={!diary.investor_technical_supervision_tzb}
            style={[DefaultTheme.input, { marginTop: 10 }]}
            label={diary.investor_technical_supervision_tzb ? "Technický dozor TZB" : "Zadejte technický dozor TZB"}
            onChangeText={(value) => changePropertyValue("investor_technical_supervision_tzb", value)}
            value={diary.investor_technical_supervision_tzb}
            disabled={isProjectClosed}
          /> */}

          {/* Technický dozor investora ESI */}
          {/* <TextInput
            error={!diary.investor_technical_supervision_esi}
            style={[DefaultTheme.input, { marginTop: 10 }]}
            label={diary.investor_technical_supervision_esi ? "Technický dozor ESI" : "Zadejte technický dozor ESI"}
            onChangeText={(value) => changePropertyValue("investor_technical_supervision_esi", value)}
            value={diary.investor_technical_supervision_esi}
            disabled={isProjectClosed}
          /> */}

          {/* Generální projektant */}
          {/* <TextInput
            error={!diary.investor_general_designer}
            style={[DefaultTheme.input, { marginTop: 10 }]}
            label={diary.investor_general_designer ? "Generální projektant" : "Zadejte generálního projektanta"}
            onChangeText={(value) => changePropertyValue("investor_general_designer", value)}
            value={diary.investor_general_designer}
            disabled={isProjectClosed}
          /> */}

          {/* Odpovědný projektant */}
          {/* <TextInput
            error={!diary.investor_responsible_designer}
            style={[DefaultTheme.input, { marginTop: 10 }]}
            label={diary.investor_responsible_designer ? "Odpovědný projektant" : "Zadejte odpovědného projektanta"}
            onChangeText={(value) => changePropertyValue("investor_responsible_designer", value)}
            value={diary.investor_responsible_designer}
            disabled={isProjectClosed}
          /> */}

          {/* Autorský dozor */}
          {/* <TextInput
            error={!diary.investor_author_supervision}
            style={[DefaultTheme.input, { marginTop: 10 }]}
            label={diary.investor_author_supervision ? "Autorský dozor" : "Zadejte autorský dozor"}
            onChangeText={(value) => changePropertyValue("investor_author_supervision", value)}
            value={diary.investor_author_supervision}
            disabled={isProjectClosed}
          /> */}

          {/* Koordinátor BOZP - Možnost výběru viz lidi v přehledu projektu včetně telefoního čísla */}
          {/* <TextInput
            error={!diary.investor_safety_coordinator}
            style={[DefaultTheme.input, { marginTop: 10 }]}
            label={diary.investor_safety_coordinator ? "Koordinátor BOZP" : "Zadejte koordinátora BOZP"}
            onChangeText={(value) => changePropertyValue("investor_safety_coordinator", value)}
            value={diary.investor_safety_coordinator}
            disabled={isProjectClosed}
          /> */}
        </View>
        <View>
          <Text style={styles.title}>Závěr</Text>
          {/* Datum založení stavebního deníků */}
          {Platform.OS === "web" && (
            <Text style={{ alignSelf: "center", padding: 10 }}>Datum založení stavebního deníků</Text>
          )}
          <AppDatePicker
            label="Datum založení stavebního deníků"
            value={diary.created_at}
            onConfirm={(value) => changePropertyValue("created_at", value)}
            disabled={isProjectClosed || isOnlyInvestor(currentUser)}
          />
          {Platform.OS === "web" && (
              <Text style={{ alignSelf: "center", padding: 10 }}>Datum uzavření stavebního deníků - musí být explicitně nastaveno!</Text>
          )}
          <AppDatePicker
            label="Datum uzavření stavebního deníků"
            value={diary?.closed_at}
            onConfirm={(value) => changePropertyValue("closed_at", value)}
            disabled={isProjectClosed || isOnlyInvestor(currentUser)}
          />
          <View style={{ flexDirection: "row", justifyContent: "space-between", paddingHorizontal: 10 }}>
            <Signature
              disabled={isProjectClosed}
              onSave={(signature) => handleSignatureSave(signature, "investor_representative")}
              title="Zástupce investora"
              uri={diary.signature?.investor_representative}
            />
            <Signature
              disabled={isProjectClosed}
              onSave={(signature) => handleSignatureSave(signature, "general_contractor")}
              title="Generální zhotovitel"
              uri={diary.signature?.general_contractor}
            />
          </View>
        </View>
      </ScrollView>
      <NextButton
        text="Uložit"
        onPress={handleDiarySave}
        disabled={
          isProjectClosed ||
          (diary.address?.latitude !== "" && !Validation.isCoordinate(diary.address?.latitude)) ||
          (diary.address?.longitude !== "" && !Validation.isCoordinate(diary.address?.longitude))
        }
      />
    </Screen>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: DefaultTheme.fonts.medium,
    fontWeight: "bold",
    padding: 10,
  },
})
