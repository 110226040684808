import React, { useState } from "react"
import { View, Platform, ScrollView, useWindowDimensions } from "react-native"
import { TextInput } from "react-native-paper"
import FullWidthImagePhone from "../components/FullWidthImagePhone"
import NextButton from "../components/NextButton"
import Screen from "../components/Screen"
import { useStatus } from "../providers/StatusProvider"
import DefaultTheme from "../themes/DefaultTheme"
import FSStoreContent from "../firebase/FSStoreContent"
import { useData } from "../providers/DataProvider"
import Constants from "expo-constants"
import FullWidthImageWeb from "../components/FullWidthImageWeb"
import { reportTypeEnum } from "../data/enums"
import AppPicker from "../components/AppPicker"
import { uploadFileOnFirebase } from "../firebase/FSGeneral"
import * as Device from "expo-device"
import { getRoutes } from "../utils/general"

export default function ReportIssueScreen({ navigation, route }) {
  const [description, setDescription] = useState("")
  const [type, setType] = useState("")
  const width = useWindowDimensions().width
  const height = useWindowDimensions().height
  const { currentUser, firebaseConfig } = useData()
  const { setStatus, statusEnum, setTitle, setMessage, updatePercentage } = useStatus()
  const test = {
    device_info: {
      brand: Device.brand,
      manufacturer: Device.manufacturer,
      device: Device.modelName,
      os_version: Device.osVersion,
    },
    screen_dimensions: { width, height },
  }
  console.debug("Test device data: ", test)
  const appPickerReportItems = [
    { label: "Chyba", value: reportTypeEnum.ERROR },
    { label: "Vylepšení", value: reportTypeEnum.IMPROVEMENT },
  ]

  async function handleReportIssue() {
    setTitle("Nahrávám report")
    setStatus(statusEnum.PROGRESS_DETERMINED)
    navigation.navigate("StatusScreen")

    try {
      const remoteUri = await uploadFileOnFirebase(route.params.uri, updatePercentage, "firestore-report")

      await FSStoreContent.reportIssue({
        created_by: currentUser.email,
        firebaseConfig: firebaseConfig,
        created_at: new Date(),
        filestore_screenshot_uri: remoteUri,
        issue_description: description,
        type,
        device_info: JSON.stringify({
          brand: Device.brand,
          manufacturer: Device.manufacturer,
          device: Device.modelName,
          os_version: Device.osVersion,
        }),
        window_dimensions: JSON.stringify({ width, height }),
        platform: JSON.stringify(Platform.constants || Platform),
        routes: getRoutes(navigation),
        commit_hash: Constants.manifest.extra.commitHash,
      })
      navigation.pop()
      setStatus(statusEnum.SUCCESS)
    } catch (error) {
      console.error(error)
      setTitle("Chyba při nahrávání reportu o chybě")
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
    }
  }

  return (
    <Screen>
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        {Platform.OS !== "web" ? (
          <View style={[{ flex: 1, overflow: "hidden" }]}>
            <FullWidthImagePhone uri={route.params.uri} width="95%" />
          </View>
        ) : (
          <FullWidthImageWeb uri={route.params.uri} />
        )}
      </ScrollView>
      <View style={{ justifyContent: "center" }}>
        <TextInput
          multiline
          style={[DefaultTheme.input, { paddingTop: 10 }]}
          label="Popis"
          onChangeText={(value) => setDescription(value)}
          value={description}
        />
        <AppPicker
          value={type}
          descriptionLabel="Důvod nahlášení"
          placeholder={{ label: "Vyberte důvod nahlášení", value: "" }}
          onValueChange={(value) => setType(value)}
          items={appPickerReportItems}
        />
      </View>
      <NextButton onPress={handleReportIssue} text="Nahlásit" disabled={!type} />
    </Screen>
  )
}
