import React from "react"
import { TouchableOpacity, View, Text, StyleSheet } from "react-native"
import { MaterialCommunityIcons as Arrow } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"

const OuterDrawerItem = ({ label, onPress, icon, isCollapsible, style }) => (
  <TouchableOpacity onPress={onPress} style={[styles.touchable, { ...style }]}>
    <View style={styles.wrapper}>
      {icon ? icon : <View />}
      <Text style={styles.label}>{label}</Text>
      {/* {isCollapsible ? <Arrow style={{justifyContent: 'flex-end'}} name="chevron-right" size={20} /> :  <Text>&emsp;&nbsp;&nbsp;</Text>} */}
    </View>
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  touchable: {
    paddingVertical: DefaultTheme.padding.s * 1.5,
    paddingLeft: DefaultTheme.padding.s,
  },
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  label: {
    fontSize: DefaultTheme.fonts.regular,
    paddingLeft: DefaultTheme.padding.s,
  },
})

export default OuterDrawerItem
