import React, { useEffect, useRef, useState } from "react"
import { FlatList, StyleSheet, Text, View } from "react-native"
import FlatListAttendanceDayItem from "../../components/FlatLists/FlatListAttendanceDayItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import { daysInMonth, getDayDeclensionForm, getHolidayColor } from "../../utils/general"
import { firebase } from "../../firebase/config"
import { attendanceTypeEnum, monthStateEnum } from "../../data/enums"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { STATE_VIEW_PARAMS_ATTENDANCE_DAY } from "../../data/params"
import DefaultTheme from "../../themes/DefaultTheme"
import { homeOfficePerMonthTotal } from "../../data/companyConstants"

export default function AttendanceMonthDays({ navigation, route }) {
  const { currentUser } = useData()
  const [currentMonthAttendences, setCurrentMonthAttendences] = useState([])
  const [homeOfficeCount, setHomeOfficeCount] = useState(0)
  const monthIndex = route.params?.monthIndex || 0
  const userEmail = route.params?.userEmail || currentUser.email
  const freeDaysCount = route.params?.freeDaysCount || 0
  const closedState = route.params?.closedState || monthStateEnum.OPEN

  const today = new Date()

  const currentMonthDate = new Date()
  currentMonthDate.setDate(1)
  currentMonthDate.setMonth(today.getMonth() - monthIndex)

  const sumCurrentMonthDays = daysInMonth(currentMonthDate.getMonth() + 1, currentMonthDate.getFullYear())

  console.debug(
    "monthIndex: ",
    monthIndex,
    " currentmonthDate: ",
    currentMonthDate,
    "currentMonthAtts",
    currentMonthAttendences
  )

  function getFreeHomeOfficeDays() {
    return homeOfficePerMonthTotal - homeOfficeCount
  }

  useEffect(() => {
    onSnapshotAttendances()
  }, [])

  function onSnapshotAttendances() {
    firebase.apps[0]
      .firestore()
      .collection("attendances")
      .doc(userEmail)
      .onSnapshot(
        (doc) => {
          try {
            setCurrentMonthAttendences(
              filterAndMapCurrentMonth(doc.data()?.[`monthly_${currentMonthDate.getFullYear()}`])
            )
          } catch (error) {
            console.error(error)
            throw error
          }
        },
        (error) => {
          console.error(error)
          throw error
        }
      )
  }

  console.debug("MONTH DAYS HOLIDAYS ", freeDaysCount)

  function filterAndMapCurrentMonth(monthlyAttendances) {
    for (const attendanceMonthIndex in monthlyAttendances) {
      const index = currentMonthDate.getMonth()

      if (index === Number(attendanceMonthIndex)) {

        let countHomeOffice = 0
        monthlyAttendances[attendanceMonthIndex].forEach((attendance) => {
          if (attendance.attendance_type === attendanceTypeEnum.HOMEOFFICE) countHomeOffice++
        })
        setHomeOfficeCount(countHomeOffice)

        return monthlyAttendances[attendanceMonthIndex].map((attendance) => {
          return {
            ...attendance,
            attendance_date: attendance.attendance_date.toDate(),
            created_at: attendance.created_at.toDate(),
          }
        })
      }
    }
    return []
  }

  const currentMonthDayAttendances = []
  for (let day = sumCurrentMonthDays; day >= 1; day--) {
    const dayData = currentMonthAttendences.find((attendance) => attendance.attendance_date.getDate() === day)
    if (dayData) {
      currentMonthDayAttendances.push(dayData)
    } else {
      currentMonthDayAttendances.push({
        attendance_date: new Date(currentMonthDate.getFullYear(), currentMonthDate.getMonth(), day),
      })
    }
  }

  return (
    <Screen>
      <View style={styles.freeDaysCount}>
        <Text>Zbývající homeoffice tento měsíc:</Text>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={{ paddingRight: 5 }}>
            {getFreeHomeOfficeDays()} {getDayDeclensionForm(getFreeHomeOfficeDays())}
          </Text>
          <MaterialCommunityIcons
            {...STATE_VIEW_PARAMS_ATTENDANCE_DAY[attendanceTypeEnum.HOMEOFFICE]}
            color={getHolidayColor(getFreeHomeOfficeDays())}
            size={24}
          />
        </View>
      </View>
      {currentMonthDayAttendances.length > 0 && (
        <FlatList
          data={currentMonthDayAttendances}
          renderItem={({ item }) => (
            <FlatListAttendanceDayItem
              dayAttendance={item}
              navigation={navigation}
              userEmail={userEmail}
              monthIndex={monthIndex}
              freeDaysCount={freeDaysCount}
              closedState={closedState}
            />
          )}
          keyExtractor={(item) => item.attendance_date.toString()}
          ItemSeparatorComponent={FlatListSeparator}
          // TODO make flatlist to start from current day
          // getItemLayout={(data, index) => ({ length: 60, offset: 60 * index, index })}
          // initialScrollIndex={currentMonthDayAttendances.length - currentMonthDate.getDate() - 1}
        />
      )}
    </Screen>
  )
}

const styles = StyleSheet.create({
  freeDaysCount: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
})
