import React, { useState, useEffect } from "react"
import FSStoreContent from "../../firebase/FSStoreContent"
import Screen from "../../components/Screen"
import NextButton from "../../components/NextButton"
import { TextInput } from "react-native-paper"
import DefaultTheme from "../../themes/DefaultTheme"
import { useStatus } from "../../providers/StatusProvider"
import { ActivityIndicator, FlatList } from "react-native"
import { statusEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"
import { getCashflowInitialValue } from "../../utils/backendUrls"
import Constants from "expo-constants"
import { firebase } from "../../firebase/config"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import PrimButton from "../../components/PrimButton"
import CashflowInitialValueItem from "../../components/cashflow/CashflowInitialValueItem"

export default function CashflowInitialValue({ navigation }) {
  const [startingValues, setStartingValues] = useState(null)
  const { setMessage, setTitle, setStatus } = useStatus()
  const { firebaseConfig } = useData()
  const today = new Date()

  const handlePress = async () => {
    setTitle("Ukládám zůstatek")
    setStatus(statusEnum.PROGRESS_DETERMINED)
    navigation.navigate("StatusScreen")
    try {
      const mappedValues = startingValues.map((el) => {
        return { ...el, amount: Number.parseInt(el.amount) }
      })
      if (mappedValues.some((el) => isNaN(el.amount) || typeof el.amount !== "number")) {
        throw new Error("Špatný vstup, zkontrolujte, zda byla zadána pouze čísla")
      }
      await FSStoreContent.setCashflowInitialValue(mappedValues)
      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      console.error("Error while saving initial value: ", error)
      setTitle("Chyba při ukládání zůstatku")
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
    }
  }

  const handleInitialValueLoad = async () => {
    const docData = await FSStoreContent.getCashflowInitialValue()
    const startingValues = docData.starting_values ? docData.starting_values : []
    console.log("startingValues" + JSON.stringify(startingValues))
    setStartingValues(startingValues)
  }

  const calculateInitialValues = async () => {
    try {
      setTitle("Probíhá aktualizace")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")
      const token = await firebase.apps[0].auth().currentUser.getIdToken()

      const url = getCashflowInitialValue(firebaseConfig)
      const request = await fetch(url, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({ syncParams: { syncYear: today.getFullYear(), syncMonth: today.getMonth() } }),
      })
      const response = await request.json()
      console.debug(response)
      await handleInitialValueLoad()
      setMessage("Aktualizace dokončena")
      setStatus(statusEnum.SUCCESS)
    } catch (error) {
      console.error(error)
      setMessage(JSON.stringify(error))
      setStatus(statusEnum.error)
    }
  }

  useEffect(() => {
    handleInitialValueLoad()
  }, [])

  return (
    <Screen>
      {!startingValues ? (
        <ActivityIndicator />
      ) : (
        <>
          <PrimButton
            style={{ backgroundColor: DefaultTheme.colors.primary, margin: 15 }}
            width={40}
            height={40}
            onPress={calculateInitialValues}
            icon={<MaterialCommunityIcons name="refresh" size={24} color={DefaultTheme.colors.white} />}
          />
          <FlatList
            data={startingValues}
            renderItem={({ item, index }) => (
              <CashflowInitialValueItem
                key={item.month_index + item.amount}
                startingValues={startingValues}
                element={item}
                index={index}
                setStartingValues={setStartingValues}
              />
            )}
          />
          <NextButton text="Uložit" onPress={handlePress} />
        </>
      )}
    </Screen>
  )
}
