import React, { memo } from "react"
import { Platform, ScrollView, View } from "react-native"
import { MaterialCommunityIcons } from "@expo/vector-icons"

import { scanTypeEnum } from "../data/enums"
import FullWidthImagePhone from "./FullWidthImagePhone"
import FullWidthImageWeb from "./FullWidthImageWeb"
import DefaultTheme from "../themes/DefaultTheme"
import { previewPDF } from "../utils/general"

const areArraysEqual = (arr1, arr2) => {
  if (!(Array.isArray(arr1) && Array.isArray(arr2))) return false
  if (arr1.length !== arr2.length) return false
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }
  return true
}

const arePropsEqual = (prev, next) => {
  return prev.type === next.type && areArraysEqual(prev.uriList, next.uriList)
}

export default memo(function DocumentImage({ type, uriList, onLoadEnd }) {
  function Swiper() {
    if (Platform.OS !== "web") {
      const Swiper = require("react-native-swiper")
      return (
        <Swiper
          showsButtons={uriList.length > 1}
          loop={false}
          nextButton={
            <MaterialCommunityIcons name="arrow-right-circle" size={32} color={DefaultTheme.colors.primary} />
          }
          prevButton={<MaterialCommunityIcons name="arrow-left-circle" size={32} color={DefaultTheme.colors.primary} />}
        >
          {uriList.map((uri, index) => (
            <View style={{ flex: 1, overflow: "hidden" }} key={index}>
              <FullWidthImagePhone uri={uri} key={uri} onLoadEnd={onLoadEnd} />
            </View>
          ))}
        </Swiper>
      )
    } else {
      return (
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          {uriList.map((uri) => (
            <FullWidthImageWeb uri={uri} key={uri} onLoadEnd={onLoadEnd} />
          ))}
        </ScrollView>
      )
    }
  }

  if (type === scanTypeEnum.IMAGE) {
    return <View style={{ flex: 1 }}>{uriList?.length > 0 && <Swiper />}</View>
  }

  return <View style={{ flex: 1 }}>{previewPDF(uriList?.[0])}</View>
}, arePropsEqual)
