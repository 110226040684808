import React, { useEffect, useState } from "react"
import { FlatList, View } from "react-native"
import { FAB } from "react-native-paper"
import { openLibrary } from "../../actions/platformFeatureRequestor"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"

import FlatListTemplatesOverviewItem from "../../components/FlatLists/FlatListTemplatesOverviewItem"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import DefaultTheme from "../../themes/DefaultTheme"

export default function TemplatesOverview({ navigation, route }) {
  const { settings } = useData()
  const [isFABOpen, setIsFABOpen] = useState(false)
  const [templates, setTemplates] = useState([])
  const docId = route.params.docId
  const propName = route.params.propName
  const screenTitle = route.params.screenTitle
  console.debug("TEMPLATES", templates)

  useEffect(() => {
    setTemplates(settings[docId]?.[propName] || [])
  }, [settings])

  async function handleOpenLibrary() {
    const localUri = await openLibrary()

    if (localUri) {
      navigation.navigate("TemplateDetail", { screenTitle, docId, propName, template: { localUri } })
    }
  }

  return (
    <Screen>
      <View style={{ flex: 1 }}>
        {templates.length > 0 ? (
          <FlatList
            data={templates}
            renderItem={({ item }) => (
              <FlatListTemplatesOverviewItem
                template={item}
                docId={docId}
                propName={propName}
                screenTitle={screenTitle}
              />
            )}
            ItemSeparatorComponent={FlatListSeparator}
            keyExtractor={(item, index) => index.toString()}
          />
        ) : (
          <AppEmptyScreenView message="Neexistují žádné šablony" />
        )}
      </View>
      <FAB.Group
        style={DefaultTheme.fabGroup}
        color={DefaultTheme.colors.fabAdd}
        icon="plus"
        fabStyle={{ backgroundColor: DefaultTheme.colors.white }}
        visible
        loading
        open={isFABOpen}
        onStateChange={(state) => setIsFABOpen(state.open)}
        actions={[
          {
            icon: "camera",
            onPress: () =>
              navigation.navigate("CaptureImage", {
                onCapture: (localUri) =>
                  navigation.navigate("TemplateDetail", { screenTitle, docId, propName, template: { localUri } }),
              }),
          },
          {
            icon: "image",
            onPress: handleOpenLibrary,
          },
        ]}
      />
    </Screen>
  )
}
