import React from "react"
import { TouchableOpacity, StyleSheet, View } from "react-native"
import { FontAwesome } from "@expo/vector-icons"

import { useNavigation } from "@react-navigation/native"
import { useStatus } from "../providers/StatusProvider"

export default function StatusDecideActionButtonsSimple() {
  const navigation = useNavigation()
  const { onConfirm, onCancel } = useStatus()

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={[styles.button, { backgroundColor: "white", marginRight: "auto" }]}
        onPress={() => (onCancel ? onCancel() : navigation.pop())}
      >
        <FontAwesome name="remove" size={60} color="red" />
      </TouchableOpacity>
      <TouchableOpacity style={[styles.button, { backgroundColor: "white" }]} onPress={() => onConfirm()}>
        <FontAwesome name="check" size={60} color="green" />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    minWidth: 250,
    paddingBottom: 50,
  },
  button: {
    minWidth: 50,
    minHeight: 50,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
})
