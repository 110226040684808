import React, { useEffect, useState } from "react"
import { Text, View, StyleSheet, FlatList } from "react-native"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import {
  statusEnum,
  attendancePlaceEnum,
  attendanceOperationTypeEnum,
  holidayStateEnum,
  attendanceBulkActionEnum,
  viewTypeEnum,
} from "../../data/enums"
import AppPicker from "../../components/AppPicker"
import NextButton from "../../components/NextButton"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useStatus } from "../../providers/StatusProvider"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import { firebase } from "../../firebase/config"
import {
  changeHolidayState,
  daysInMonth,
  getDayDeclensionForm,
  getHolidayColor,
  getHolidayOperationBulkState,
  isDateDifferent,
} from "../../utils/general"
import FlatListAttendanceDayItem from "../../components/FlatLists/FlatListAttendanceDayItem"
import { attendanceTypeEnum } from "../../data/enums"
import { attendanceBulkActionLabels } from "../../data/labels"
import { isAdmin, isExecutive } from "../../utils/validation"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { STATE_VIEW_PARAMS_ATTENDANCE_DAY } from "../../data/params"

export default function AttendanceBulkAction({ navigation, route }) {
  const { currentUser } = useData()
  const { setTitle, setStatus, setMessage } = useStatus()
  const [currentAttendances, setCurrentAttendances] = useState([])
  const [attendancesToChange, setAttendancesToChange] = useState([])
  const [lastRecords, setLastRecords] = useState([])
  const [lastRecordsCalendar, setLastRecordsCalendar] = useState([])
  const [freeDaysCount, setFreeDaysCount] = useState(route.params?.freeDaysCount || 0)

  const monthIndex = route.params?.monthIndex || 0
  const userEmail = route.params?.userEmail || currentUser.email

  const currentMonthDate = new Date()
  currentMonthDate.setMonth(currentMonthDate.getMonth() - monthIndex)
  const sumCurrentMonthDays = daysInMonth(currentMonthDate.getMonth() + 1, currentMonthDate.getFullYear())

  console.debug("ATT BULK FREE HOLIDAYS", freeDaysCount)

  const [selectedAction, setSelectedAction] = useState({
    label: attendanceBulkActionLabels[attendanceBulkActionEnum.WORK],
    value: attendanceBulkActionEnum.WORK,
    type: attendanceTypeEnum.WORK,
  })

  const [currentYearMonthly, setCurrentYearMonthly] = useState(null)

  const appPickerActionOptions = [
    { label: attendanceBulkActionLabels[attendanceBulkActionEnum.WORK], value: attendanceBulkActionEnum.WORK },
    {
      label: attendanceBulkActionLabels[attendanceBulkActionEnum.HOLIDAY_CZ],
      value: attendanceBulkActionEnum.HOLIDAY_CZ,
    },
    {
      label: attendanceBulkActionLabels[attendanceBulkActionEnum.HOLIDAY_OTHER],
      value: attendanceBulkActionEnum.HOLIDAY_OTHER,
    },
  ]

  if (isAdmin(currentUser) || isExecutive(currentUser)) {
    appPickerActionOptions.push(
      {
        label: attendanceBulkActionLabels[attendanceBulkActionEnum.HOLIDAY_APPROVE],
        value: attendanceBulkActionEnum.HOLIDAY_APPROVE,
      },
      {
        label: attendanceBulkActionLabels[attendanceBulkActionEnum.HOLIDAY_REJECT],
        value: attendanceBulkActionEnum.HOLIDAY_REJECT,
      }
    )
  }

  useEffect(() => {
    onSnapshotAttendances()
  }, [])

  function areDaysSelected() {
    for (let i = 0; i < attendancesToChange.length; i++) {
      if (attendancesToChange[i].isSelected) {
        return true
      }
    }
    return false
  }

  console.debug("MAPPED DATE", currentMonthDate)

  function onSnapshotAttendances() {
    firebase.apps[0]
      .firestore()
      .collection("attendances")
      .doc(userEmail)
      .onSnapshot((doc) => {
        try {
          const currentMonthDayAttendances = []
          for (let day = sumCurrentMonthDays; day >= 1; day--) {
            const dayData = filterAndMapCurrentMonth(doc.data()?.[`monthly_${currentMonthDate.getFullYear()}`]).find(
              (attendance) => attendance.attendance_date.getDate() === day
            )
            if (dayData) {
              currentMonthDayAttendances.push(dayData)
            } else {
              currentMonthDayAttendances.push({
                attendance_date: new Date(currentMonthDate.getFullYear(), currentMonthDate.getMonth(), day),
              })
            }
          }
          setAttendancesToChange(currentMonthDayAttendances)
          setCurrentAttendances(currentMonthDayAttendances)
          setCurrentYearMonthly(mapMonthlyAttendances(doc.data()?.[`monthly_${currentMonthDate.getFullYear()}`]))
          setLastRecords(doc.data()?.last_records || [])
          setLastRecordsCalendar(doc.data()?.last_records_calendar || [])
        } catch (error) {
          console.error(error)
          throw error
        }
      })
  }

  function handleAttendanceTypeChange(dayAttendance) {
    let requiredData = {}
    if (dayAttendance.task_id) {
      requiredData = { task_id: dayAttendance.task_id }
    } else if (dayAttendance.covid_test) {
      requiredData = { ...requiredData, covid_test: dayAttendance.covid_test }
    }

    if (selectedAction.value === attendanceTypeEnum.WORK) {
      return {
        ...requiredData,
        attendance_date: dayAttendance.attendance_date,
        attendance_type: selectedAction.type,
        overtime_hours: 0,
      }
    } else if (
      selectedAction.value === attendanceBulkActionEnum.HOLIDAY_APPROVE ||
      selectedAction.value === attendanceBulkActionEnum.HOLIDAY_REJECT
    ) {
      delete dayAttendance.isSelected

      if (!dayAttendance.operations) {
        dayAttendance.operations = []
      }

      const value =
        selectedAction.value === attendanceBulkActionEnum.HOLIDAY_APPROVE
          ? holidayStateEnum.ACCEPTED
          : holidayStateEnum.REJECTED

      if (!dayAttendance.operations.some((item) => item.action_type === attendanceOperationTypeEnum.HOLIDAY_APPROVE)) {
        dayAttendance.operations.push({
          action_type: attendanceOperationTypeEnum.HOLIDAY_APPROVE,
          action_value: value,
          created_at: new Date(),
          created_by: currentUser.email,
        })
      }

      return {
        ...dayAttendance,
        operations: dayAttendance.operations.map((item) => {
          if (item.action_type === attendanceOperationTypeEnum.HOLIDAY_APPROVE)
            return {
              action_type: attendanceOperationTypeEnum.HOLIDAY_APPROVE,
              action_value: value,
              created_at: new Date(),
              created_by: currentUser.email,
            }
          return item
        }),
      }
    } else if (selectedAction.type === attendanceTypeEnum.HOLIDAY) {
      return selectedAction.value === attendanceBulkActionEnum.HOLIDAY_CZ
        ? {
            ...requiredData,
            attendance_date: dayAttendance.attendance_date,
            attendance_type: selectedAction.type,
            attendance_place: attendancePlaceEnum.CZ,
          }
        : {
            ...requiredData,
            attendance_date: dayAttendance.attendance_date,
            attendance_type: selectedAction.type,
            attendance_place: attendancePlaceEnum.OTHER,
          }
    }
  }

  async function foreachHandleAttendanceTypeChange() {
    setStatus(statusEnum.PROGRESS_UNDETERMINED)
    setTitle("Provádím akci")
    navigation.navigate("StatusScreen")

    const attendancesToSave = attendancesToChange
      .filter((attendance) => attendance.isSelected === true)
      .map((attendance) => handleAttendanceTypeChange(attendance))

    try {
      await handleAttendanceSave(attendancesToSave)
      setStatus(statusEnum.SUCCESS)
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
    navigation.pop()
  }

  console.debug("LAST RECS CALENDAR", lastRecordsCalendar)

  async function handleAttendanceSave(attendancesToSave) {
    const dayAttendance = attendancesToSave[0]
    const month = dayAttendance.attendance_date.getMonth()
    const yearMonthly = `monthly_${dayAttendance.attendance_date.getFullYear()}`
    let newMonth = currentYearMonthly[month] || []

    newMonth = newMonth.map((attendance) => {
      const att = attendancesToSave.find(
        (att) => att.attendance_date.getDate() === attendance.attendance_date.getDate()
      )
      return att
        ? { ...att, created_at: new Date(), created_by: currentUser.email }
        : { ...attendance, created_at: new Date(), created_by: currentUser.email }
    })

    const tempCalRecords = []
    for (const att of attendancesToSave) {
      if (!newMonth.find((attendance) => att.attendance_date.getDate() === attendance.attendance_date.getDate())) {
        newMonth.push({ ...att, created_at: new Date(), created_by: currentUser.email })
      }
      if (att.attendance_type === attendanceTypeEnum.HOLIDAY && (!att.operations || att.operations.length === 0)) {
        lastRecords.push({ attendance_date: att.attendance_date, yearMonthly, created_at: new Date() })
      }

      // add to last_records_calendar if holiday is approved
      if (
        att.attendance_type === attendanceTypeEnum.HOLIDAY &&
        att.operations?.find((o) => o.action_type === attendanceOperationTypeEnum.HOLIDAY_APPROVE)?.action_value ===
          holidayStateEnum.ACCEPTED

        // in order to prevent duplicities
        // && !lastRecordsCalendar.some((record) => !isDateDifferent(record.attendance_date.toDate(), att.attendance_date))
      ) {
        tempCalRecords.push({
          attendance_date: att.attendance_date,
          created_at: new Date(),
          created_by: currentUser.email,
        })
      }
      if (att?.task_id) {
        await FSStoreContent.markTaskAsDone(att.task_id)
      }
    }

    await FSStoreContent.updateAttendance({
      [yearMonthly]: { ...currentYearMonthly, [month]: newMonth },
      userEmail,
      last_records: lastRecords,
      last_records_calendar: [...lastRecordsCalendar, ...tempCalRecords],
    })
  }

  function mapMonthlyAttendances(currentYearMonthly) {
    let obj = {}
    for (const monthDate in currentYearMonthly) {
      obj = {
        ...obj,
        [monthDate]: currentYearMonthly[monthDate].map((attendance) => {
          if (attendance.holiday_from) {
            return {
              ...attendance,
              attendance_date: attendance.attendance_date.toDate(),
              created_at: attendance.created_at.toDate(),
              holiday_from: attendance.holiday_from.toDate(),
              holiday_to: attendance.holiday_to.toDate(),
            }
          } else {
            return {
              ...attendance,
              attendance_date: attendance.attendance_date.toDate(),
              created_at: attendance.created_at.toDate(),
            }
          }
        }),
      }
    }
    return obj
  }

  function filterAndMapCurrentMonth(monthlyAttendances) {
    for (let attendanceMonthIndex in monthlyAttendances) {
      attendanceMonthIndex = Number(attendanceMonthIndex)

      if (currentMonthDate.getMonth() === attendanceMonthIndex) {
        return monthlyAttendances[attendanceMonthIndex].map((attendance) => {
          if (attendance.attendance_type === attendanceTypeEnum.HOLIDAY && attendance.operations) {
            return {
              ...attendance,
              attendance_date: attendance.attendance_date.toDate(),
              created_at: attendance.created_at.toDate(),
              operations: attendance.operations.map((o) => ({ ...o, created_at: o.created_at.toDate() })),
            }
          } else {
            return {
              ...attendance,
              attendance_date: attendance.attendance_date.toDate(),
              created_at: attendance.created_at.toDate(),
            }
          }
        })
      }
    }
    return []
  }

  function handleSelectedAction(value) {
    switch (value) {
      case attendanceBulkActionEnum.HOLIDAY_APPROVE:
        setAttendancesToChange(
          mapSelectedAttendances(
            attendancesToChange.filter((attendance) => attendance.attendance_type === attendanceTypeEnum.HOLIDAY),
            attendanceBulkActionEnum.HOLIDAY_APPROVE,
            attendanceTypeEnum.HOLIDAY
          )
        )
        setSelectedAction({ value, type: attendanceTypeEnum.HOLIDAY })
        setFreeDaysCount(route.params?.freeDaysCount || 0)
        break
      case attendanceBulkActionEnum.HOLIDAY_REJECT:
        setAttendancesToChange(
          mapSelectedAttendances(
            attendancesToChange.filter((attendance) => attendance.attendance_type === attendanceTypeEnum.HOLIDAY),
            attendanceBulkActionEnum.HOLIDAY_REJECT,
            attendanceTypeEnum.HOLIDAY
          )
        )
        setSelectedAction({ value, type: attendanceTypeEnum.HOLIDAY })
        setFreeDaysCount(route.params?.freeDaysCount || 0)
        break
      case attendanceBulkActionEnum.HOLIDAY_CZ:
        if (route.params?.freeDaysCount - attendancesToChange.reduce((a, c) => (c.isSelected ? a + 1 : a + 0), 0) < 0) {
          onSnapshotAttendances()
        }
        setAttendancesToChange(
          mapSelectedAttendances(getAllAttendances(), attendanceBulkActionEnum.HOLIDAY_CZ, attendanceTypeEnum.HOLIDAY)
        )
        setSelectedAction({ value, type: attendanceTypeEnum.HOLIDAY })
        setFreeDaysCount(
          route.params?.freeDaysCount - attendancesToChange.reduce((a, c) => (c.isSelected ? a + 1 : a + 0), 0)
        )
        break
      case attendanceBulkActionEnum.HOLIDAY_OTHER:
        setAttendancesToChange(
          mapSelectedAttendances(
            getAllAttendances(),
            attendanceBulkActionEnum.HOLIDAY_OTHER,
            attendanceTypeEnum.HOLIDAY
          )
        )
        setSelectedAction({ value, type: attendanceTypeEnum.HOLIDAY })
        setFreeDaysCount(
          route.params?.freeDaysCount - attendancesToChange.reduce((a, c) => (c.isSelected ? a + 1 : a + 0), 0)
        )
        break
      default:
        setAttendancesToChange(
          mapSelectedAttendances(getAllAttendances(), attendanceBulkActionEnum.WORK, attendanceTypeEnum.WORK)
        )
        setSelectedAction({ value, type: attendanceTypeEnum.WORK })
        setFreeDaysCount(route.params?.freeDaysCount || 0)
        break
    }
  }

  function getAllAttendances() {
    const newAttendances = []
    currentAttendances.forEach((curAtt) => {
      let isFound = false
      attendancesToChange.forEach((chaAtt) => {
        if (curAtt.attendance_date.getDate() === chaAtt.attendance_date.getDate()) {
          newAttendances.push(chaAtt)
          isFound = true
        }
      })
      if (!isFound) {
        newAttendances.push(curAtt)
      }
    })
    return newAttendances
  }

  function mapSelectedAttendances(attendances, actionValue, actionType) {
    return attendances.map((att) => {
      if (att.isSelected) {
        let newDayAttendance = {
          ...att,
          attendance_type: actionType,
        }
        if (actionType === attendanceTypeEnum.WORK) {
          newDayAttendance = { ...newDayAttendance, overtime_hours: 0 }
        } else if (actionType === attendanceTypeEnum.HOLIDAY) {
          newDayAttendance = {
            ...newDayAttendance,
            attendance_place: getAttendancePlace(actionValue, newDayAttendance),
            operations: changeHolidayState(
              newDayAttendance,
              attendanceOperationTypeEnum.HOLIDAY_APPROVE,
              getHolidayOperationBulkState(actionValue),
              currentUser
            ),
          }
        }

        return newDayAttendance
      } else {
        return att
      }
    })
  }

  function getAttendancePlace(actionValue, newDayAttendance) {
    return actionValue === attendanceBulkActionEnum.HOLIDAY_APPROVE ||
      actionValue === attendanceBulkActionEnum.HOLIDAY_REJECT
      ? newDayAttendance.attendance_place
      : actionValue.split("_")[1]
  }

  return (
    <Screen>
      <View style={styles.freeDaysCount}>
        <Text>Zbývající dovolená do konce roku:</Text>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={{ paddingRight: 5 }}>
            {freeDaysCount} {getDayDeclensionForm(freeDaysCount)}
          </Text>
          <MaterialCommunityIcons
            {...STATE_VIEW_PARAMS_ATTENDANCE_DAY[attendanceTypeEnum.HOLIDAY]}
            color={getHolidayColor(freeDaysCount)}
            size={24}
          />
        </View>
      </View>
      <AppPicker
        value={selectedAction.value}
        descriptionLabel="Akce"
        placeholder={{}}
        onValueChange={(value) => handleSelectedAction(value)}
        items={appPickerActionOptions}
      />
      {attendancesToChange.length > 0 ? (
        <>
          <FlatList
            data={attendancesToChange}
            renderItem={({ item }) => (
              <FlatListAttendanceDayItem
                dayAttendance={item}
                navigation={navigation}
                userEmail={userEmail}
                monthIndex={monthIndex}
                viewType={viewTypeEnum.BULK_ACTION}
                selectedAction={selectedAction}
                setAttendances={setAttendancesToChange}
                attendancesToChange={attendancesToChange}
                freeDaysCount={freeDaysCount}
                setFreeDaysCount={setFreeDaysCount}
              />
            )}
            extraData={(attendancesToChange, selectedAction)}
            keyExtractor={(item) => item.attendance_date.toString()}
            ItemSeparatorComponent={FlatListSeparator}
          />

          <NextButton text="Uložit" onPress={foreachHandleAttendanceTypeChange} disabled={!areDaysSelected()} />
        </>
      ) : (
        <AppEmptyScreenView
          message={
            selectedAction.value === attendanceBulkActionEnum.HOLIDAY_APPROVE ||
            selectedAction.value === attendanceBulkActionEnum.HOLIDAY_REJECT
              ? "Žádná dovolená ke schválení"
              : "Dny se načítají"
          }
        />
      )}
    </Screen>
  )
}

const styles = StyleSheet.create({
  noTasksWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  freeDaysCount: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
})
