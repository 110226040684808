import React from "react"
import { StyleSheet, Text, TouchableOpacity, View } from "react-native"

import DefaultTheme from "../../themes/DefaultTheme"
import CurrencyFormatter from "../CurrencyFormatter"
import { useNavigation } from "@react-navigation/native"
import FlatListSeparator from "./FlatListSeparator"
import { FontAwesome5 } from "@expo/vector-icons"
import { useStatus } from "../../providers/StatusProvider"
import { actionEnum, statusEnum } from "../../data/enums"
import { toCurrentDate } from "../../utils/general"

export default function FlatListTriviDocumentItem({ projectId, document }) {
  const navigation = useNavigation()
  const styles = getStyles()

  //console.log(JSON.stringify(document))
  const status = useStatus()

  function showAlertDialog() {
    // must be document.docId as firebase id not trivi id
    console.log("showAlertDialog... document.id: " + document.docId)
    status.setMessage("Chcete opravdu odstranit tento Trivi dokument id: " + document.docId + " ?")
    status.setStatus(statusEnum.PROGRESS_DECIDE)
    status.setAction({
      type: actionEnum.DELETE_TRIVI_DOCUMENT,
      params: { triviDocumentId: document.docId, toScreen: { screen: "Trivi", params: { projectId: projectId, refresh: true  } } },
    })
    navigation.navigate("StatusScreen")
  }

  return (
    <>
      <View style={styles.card}>
        <View style={{ flexDirection: "row", width: "100%" }}>
          <Text style={{ fontWeight: "bold" }}>
            {document?.accountingDocumentNo} [ {document?.syncId} / {toCurrentDate(document?.syncIdDate)} ] type: {document?.type}
          </Text>
          <Text style={{ flexGrow: 1 }}></Text>
          <Text style={{ color: DefaultTheme.colors.black, fontWeight: "bold" }}>
            {CurrencyFormatter(document?.total)} [{document?.taxDate}]
          </Text>
          <TouchableOpacity onPress={showAlertDialog} style={{ padding: 20 }}>
            <FontAwesome5 name="trash" size={DefaultTheme.icons.flatlistSize} color={"red"} />
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: "row", width: "100%" }}>
          <Text style={{ color: DefaultTheme.colors.black }}>
            {document?.processingMessage}
          </Text>
        </View>
        <View style={{ flexDirection: "column", width: "100%" }}>
          {document?.lineItems?.map(lineItem =>
            <>
              <Text style={{ textAlign: "left", padding: 5 }}>
                Projekt[{lineItem.project}]
              </Text>
              <Text style={{ textAlign: "left", padding: 5, flexGrow: 1 }}>
                {lineItem.description}
              </Text>
              <Text style={{ textAlign: "right", padding: 5 }}>
                {CurrencyFormatter(lineItem.total, "CZK", 1, true)}
              </Text>
            </>,
          )}
        </View>
        <FlatListSeparator />
      </View>
    </>
  )
}

function getStyles(document) {
  return StyleSheet.create({
    card: {
      minHeight: 100,
      width: "100%",
      paddingLeft: 15,
      flexDirection: "column",
      borderLeftWidth: 10,
      backgroundColor: DefaultTheme.colors.white,
    },
    closingStateText: {
      width: 100,
      height: 30,
      fontWeight: "bold",
      textAlign: "center",
      lineHeight: 30,
      transform: [{ rotate: "-90deg" }],
      color: "white",
    },
    closingStateWrapper: {
      width: 30,
      height: "100%",
      backgroundColor: "green",
      justifyContent: "center",
      alignItems: "center",
    },
    paymentIcon: {
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "orange",
      width: 24,
      height: 24,
      borderRadius: 12,
    },
    paymentDone: {
      backgroundColor: "green",
    },
    paymentPartly: {
      backgroundColor: "purple",
    },
    paymentWaiting: {
      backgroundColor: "orange",
    },
    hint: {
      fontStyle: "italic",
      color: DefaultTheme.colors.placeholder,
      paddingLeft: 10,
    },
    name: {
      fontWeight: "bold",
      paddingBottom: 10,
    },
    triviLogo: {
      marginTop: 3,
      width: 25,
      height: 25,
      borderWidth: 2,
      borderRadius: 15,
      borderColor: DefaultTheme.colors.disabled,
      justifyContent: "center",
      alignItems: "center",
    },
  })
}
