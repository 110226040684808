import React from "react"
import { Text, View, Button, Platform, StatusBar, StyleSheet, SafeAreaView } from "react-native"

export default function HomeScreen(props) {
  return (
    <SafeAreaView style={(styles.safeTop, styles.container)}>
      <Text>Screen domu</Text>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  safeTop: {
    marginTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
})
