import React from "react"
import { StyleSheet, Text, View, RefreshControl } from "react-native"
import { MaterialCommunityIcons } from "@expo/vector-icons"

import DefaultTheme from "../../themes/DefaultTheme"
import CurrencyFormatter from "../CurrencyFormatter"

export default function FlatListTransactionItem({ transaction }) {
  function formatDate(date) {
    const splitDate = date.split("-")
    return `[${splitDate[2]}.${splitDate[1]}.${splitDate[0]}]`
  }

  return (
    <View style={[styles.card, { borderLeftColor: DefaultTheme.colors.danger }]}>
      <MaterialCommunityIcons
        name="cash-minus"
        size={DefaultTheme.icons.flatlistSize}
        color={DefaultTheme.colors.danger}
      />
      <View style={{ flex: 1.5, padding: 10 }}>
        <Text numberOfLines={1} style={{ overflow: "visible" }}>
          {transaction.counterParty?.name || transaction.counterParty?.iban || " "}
        </Text>
        <Text style={{ fontSize: 12, paddingVertical: 5 }}>{transaction.transactionType}</Text>
        <Text style={{ fontSize: 10 }}>{formatDate(transaction.valueDate)}</Text>
      </View>
      <View style={{ flex: 1, alignItems: "flex-end" }}>
        <Text>- {CurrencyFormatter(transaction.amount.value, "CZK", -1)}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    height: 80,
    paddingHorizontal: 15,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: DefaultTheme.colors.white,
    borderLeftWidth: 10,
  },
})
