import React, { useEffect, useRef, useState } from "react"
import { FlatList, View } from "react-native"

import { openLibrary } from "../../actions/platformFeatureRequestor"
import DefaultTheme from "../../themes/DefaultTheme"
import {
  actionEnum,
  scanTypeEnum,
  documentTypeEnum,
  documentViewTypeEnum,
  scansOverviewViewTypeEnum,
  statusEnum,
} from "../../data/enums"
import Screen from "../../components/Screen"
import { useStatus } from "../../providers/StatusProvider"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import { FAB } from "react-native-paper"
import NextButton from "../../components/NextButton"
import FlatListScanItem from "../../components/FlatLists/FlatListScanItem"
import { uploadFileOnFirebase } from "../../firebase/FSGeneral"
import FSStoreContent from "../../firebase/FSStoreContent"
import { DocumentDataWithSave } from "../../components/documents/DocumentDataWithSave"
import FSDiary from "../../firebase/FSDiary"
import { useData } from "../../providers/DataProvider"

export default function ScansOverview({ navigation, route }) {
  const { setTitle, setStatus, setMessage, updatePercentage, setToScreen } = useStatus()
  const { currentUser } = useData()
  const [isFABOpen, setIsFABOpen] = useState(false)
  const type = scanTypeEnum.IMAGE
  const belongsTo = route.params.belongsTo
  const viewType = route.params.viewType
  const isCompanyDoc = viewType === documentViewTypeEnum.COMPANY
  const isChangeViewType = viewType === scansOverviewViewTypeEnum.DOC_CHANGE

  const isContract = route.params?.docType === documentTypeEnum.CONTRACT
  const isDocumentation = route.params?.docType === documentTypeEnum.DOCUMENTATION
  const isVehicleLog = viewType === documentViewTypeEnum.VEHICLE_LOG
  const projectIdRef = useRef(route.params?.projectId)
  console.debug("SCANS Project ID", projectIdRef.current)

  let documentRef = useRef(route.params.document)
  console.debug("SCANS OVERVIEW DOC", documentRef.current)
  const [localUriList, setLocalUriList] = useState([])
  console.debug("VIEW TYPE", viewType)

  useEffect(() => {
    setLocalUriList(route.params.uriList)
  }, [route.params])

  async function handleCaptureImage() {
    navigation.navigate("CaptureImage", {
      viewType,
      uriList: [...localUriList],
      docType: route.params?.docType,
      action: actionEnum.NEW,
    })
  }

  async function handleOpenLibrary() {
    const localUri = await openLibrary()

    if (localUri) {
      navigation.navigate("VerifyScan", {
        type,
        isPreview: true,
        viewType,
        uriPreview: localUri,
        uriList: [...localUriList, localUri],
        docType: route.params?.docType,
      })
    }
  }

  async function handleButtonPress(document) {
    // in order to get updated doc when company doc
    if (isChangeViewType || isCompanyDoc || isVehicleLog) {
      try {
        setTitle(
          isChangeViewType
            ? `Upravuji ${isContract ? "smlouvu" : isDocumentation ? "dokumentaci" : "doklad"}`
            : "Nahrávám dokument"
        )
        setMessage(
          isChangeViewType
            ? `Nahrávám sken ${isContract ? "smlouvy" : isDocumentation ? "dokumentace" : "dokladu"}`
            : "Nahrávám sken dokumentu"
        )
        setStatus(statusEnum.PROGRESS_DETERMINED)
        navigation.navigate("StatusScreen")

        const remoteUriList = []
        for (let i = 0; i < localUriList.length; i++) {
          setMessage(
            isChangeViewType
              ? `Nahrávám sken ${i + 1} / ${localUriList.length}`
              : `Nahrávám sken dokumentu ${i + 1} / ${localUriList.length}`
          )
          const remoteUri = await uploadFileOnFirebase(
            localUriList[i],
            updatePercentage,
            isContract ? "contracts" : isDocumentation ? "documentation" : "accountingDocuments"
          )
          remoteUriList.push(remoteUri)
        }

        console.debug("REMOTE URI LIST TO SAVE", remoteUriList)

        if (isChangeViewType) {
          if (isContract || isDocumentation) {
            console.debug("NAHRAVAM NOVU ", route.params?.docType)
            documentRef.current = {
              ...documentRef.current,
              filestore_uri_list: remoteUriList,
              type,
              updated_at: new Date(),
              updated_by: currentUser.email,
            }
            if (documentRef.current.id) {
              await FSDiary.updateDocument(projectIdRef.current, documentRef.current)
            } else {
              const newDocRef = await FSDiary.addDocument(projectIdRef.current, documentRef.current)
              documentRef.current = { ...documentRef.current, id: newDocRef.id }
            }
          } else {
            navigation.navigate("AccountingDocumentDetail", {
              document: { ...documentRef.current, type, filestore_uri_list: remoteUriList },
              viewType,
            })
          }
        } else if (isCompanyDoc) {
          // company doc is getting his own document as function argument
          delete document.uriList
          await FSStoreContent.uploadCompanyDocument({
            ...document,
            filestore_uri_list: remoteUriList,
            type,
          })
          setToScreen("DocumentsOverview")
        } else if (isVehicleLog) {
          navigation.navigate("AccountingDocumentDetail", {
            document: { ...documentRef.current, type, filestore_uri_list: remoteUriList },
            viewType,
          })
        }

        if (isChangeViewType) {
          setToScreen(
            isContract || isDocumentation
              ? {
                  screen: "DiaryDocumentDetail",
                  params: {
                    diaryDocument: documentRef.current,
                    viewType: route.params?.docType,
                    projectId: projectIdRef.current,
                  },
                }
              : {
                  screen: "AccountingDocumentDetail",
                  params: { document: documentRef.current },
                }
          )
        }
        setStatus(statusEnum.SUCCESS)
        setMessage(
          isChangeViewType
            ? `${
                isContract
                  ? "Smlouva byla úspěšně upravena"
                  : isDocumentation
                  ? "Dokumentace byla úspěšně upravena"
                  : "Doklad byl úspěšně upraven"
              }!`
            : ""
        )
      } catch (error) {
        console.error(error)
        setTitle(
          isChangeViewType
            ? `Chyba při nahrávání ${isContract ? "smlouvy" : isDocumentation ? "dokumentace" : "dokladu"}`
            : ""
        )
        setMessage(error.message)
        setStatus(statusEnum.ERROR)
        navigation.navigate("StatusScreen")
      }
    } else {
      navigation.navigate("AccountingDocumentInfo", { type, uriList: localUriList })
    }
  }

  return (
    <Screen>
      {localUriList?.length > 0 ? (
        <>
          <FlatList
            data={localUriList}
            renderItem={({ item }) => (
              <FlatListScanItem
                uriPreview={item}
                uriList={localUriList}
                setLocalUriList={setLocalUriList}
                viewType={viewType}
              />
            )}
            numColumns={2}
            keyExtractor={(item, index) => index.toString()}
            columnWrapperStyle={{ flexWrap: "wrap", justifyContent: "space-evenly" }}
          />
          {isCompanyDoc ? (
            <DocumentDataWithSave
              belongsTo={belongsTo}
              handleButtonPress={handleButtonPress}
              type={type}
              uriList={localUriList}
            />
          ) : (
            <NextButton
              text={isChangeViewType || isCompanyDoc || isVehicleLog ? "Uložit" : "Dále"}
              showIcon={!(isChangeViewType || isCompanyDoc || isVehicleLog)}
              onPress={handleButtonPress}
              disabled={localUriList.length <= 0}
            />
          )}
        </>
      ) : (
        <AppEmptyScreenView
          message={`Nahrajte prosím sken ${isContract ? "smlouvy" : isDocumentation ? "dokumentace" : "dokladu"}`}
        />
      )}

      <FAB.Group
        style={[DefaultTheme.fabGroup, { paddingBottom: isCompanyDoc ? 176 : 86 }]}
        color={DefaultTheme.colors.fabAdd}
        icon="plus"
        fabStyle={{ backgroundColor: DefaultTheme.colors.white }}
        visible={true}
        loading={true}
        open={isFABOpen}
        onStateChange={(state) => setIsFABOpen(state.open)}
        actions={[
          {
            icon: "camera",
            onPress: handleCaptureImage,
          },
          {
            icon: "image",
            onPress: handleOpenLibrary,
          },
        ]}
      />
    </Screen>
  )
}
