import React, { useEffect, useState } from "react"
import { Text, TouchableOpacity, View, StyleSheet } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import { monthNameLabels } from "../../data/labels"
import { MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons"
import { STATE_VIEW_PARAMS_LOG_BOOK, STATE_VIEW_PARAMS_MONTH } from "../../data/params"
import { actionEnum, monthStateEnum, statusEnum } from "../../data/enums"
import PrimButton from "../PrimButton"
import { isAccountant, isAdmin, isExecutive, isOfficeManager } from "../../utils/validation"
import { getLogBookMonthClosingState, isOldYearMonth, toCurrentDateTime } from "../../utils/general"
import { useStatus } from "../../providers/StatusProvider"
import { useData } from "../../providers/DataProvider"

export default function FlatListLogBookMonthsItem({ monthLogBook, navigation }) {
  const { currentUser } = useData()
  const { setTitle, setMessage, setStatus, setAction } = useStatus()
  const today = new Date()
  const itemYearMonth = monthLogBook.id

  const hasEditPermission =
    isAdmin(currentUser) || isExecutive(currentUser) || isAccountant(currentUser) || isOfficeManager(currentUser)

  const itemDate = new Date(Number(itemYearMonth.split("_")[0]), Number(itemYearMonth.split("_")[1]))
  const monthTitle = `${monthNameLabels[itemDate.getMonth()]} ${itemDate.getFullYear()}`

  function getLastUserClosing(closingItems) {
    closingItems.sort(
      (a, b) =>
        (b.closing_at.seconds ? b.closing_at.toDate() : b.closing_at) -
        (a.closing_at.seconds ? a.closing_at.toDate() : a.closing_at)
    )

    return closingItems.length === 0
      ? "Ještě nebyla provedena"
      : `${closingItems[0]?.closing_by} \n${
          closingItems[0]?.closing_at.seconds
            ? toCurrentDateTime(closingItems[0]?.closing_at)
            : toCurrentDateTime(closingItems[0]?.closing_at)
        }`
  }

  return (
    <TouchableOpacity
      style={[
        styles.card,
        {
          borderLeftColor:
            STATE_VIEW_PARAMS_MONTH[
              getLogBookMonthClosingState(monthLogBook.closing_items) === monthStateEnum.CLOSED
                ? monthStateEnum.CLOSED
                : monthStateEnum.OPEN
            ].color,
        },
      ]}
      onPress={() => navigation.navigate("LogBookMonthDetail", { monthLogBook })}
    >
      <View style={{ flexDirection: "column", alignItems: "center" }}>
        <FontAwesome
          {...STATE_VIEW_PARAMS_LOG_BOOK[
            getLogBookMonthClosingState(monthLogBook.closing_items) === monthStateEnum.CLOSED
          ]}
          size={DefaultTheme.icons.flatlistSize}
        />
        {getLogBookMonthClosingState(monthLogBook.closing_items) === monthStateEnum.CLOSED && (
          <FontAwesome name="lock" color={DefaultTheme.colors.disabled} size={24} />
        )}
      </View>
      <View style={{ flex: 1 }}>
        <Text style={styles.monthTitle}>{monthTitle}</Text>
      </View>
      <View style={{ flex: 1.5 }}>
        <Text style={DefaultTheme.hint}>Poslední uzávěrka</Text>
        <Text>{getLastUserClosing(monthLogBook.closing_items || [])}</Text>
      </View>
      <View style={{ flex: 0.5, alignItems: "center" }}>
        {getLogBookMonthClosingState(monthLogBook.closing_items) === monthStateEnum.CLOSED
          ? hasEditPermission && (
              <PrimButton
                width={48}
                icon={<MaterialCommunityIcons name="lock-open-variant" color={DefaultTheme.colors.white} size={24} />}
                onPress={() => {
                  setTitle(`Opravdu chcete otevřít měsíc ${monthTitle}?`)
                  setStatus(statusEnum.PROGRESS_DECIDE)
                  setAction({
                    type: actionEnum.LOG_BOOK_OPEN_MONTH,
                    params: {
                      toScreen: "LogBookMonths",
                      monthLogBook,
                    },
                  })
                  navigation.navigate("StatusScreen")
                }}
              />
            )
          : (monthLogBook.user === currentUser.email || hasEditPermission) && (
              <PrimButton
                width={48}
                disabled={getLogBookMonthClosingState(monthLogBook.closing_items) === monthStateEnum.CLOSED}
                icon={<MaterialCommunityIcons name="lock" color={DefaultTheme.colors.white} size={24} />}
                onPress={() => {
                  setTitle(`Opravdu chcete uzavřít měsíc ${monthTitle}?`)
                  setMessage("Tato akce nejde zvrátit!")
                  setStatus(statusEnum.PROGRESS_DECIDE)
                  setAction({
                    type: actionEnum.LOG_BOOK_CLOSE_MONTH,
                    params: {
                      toScreen: "LogBookMonths",
                      monthLogBook,
                    },
                  })
                  navigation.navigate("StatusScreen")
                }}
              />
            )}
      </View>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  card: {
    height: 100,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    paddingRight: 10,
    flexDirection: "row",
    backgroundColor: DefaultTheme.colors.white,
    borderLeftWidth: 10,
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
  name: {
    paddingLeft: 10,
    marginRight: "auto",
  },
  monthTitle: {
    paddingLeft: 10,
  },
})
