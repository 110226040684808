import React from "react"
import { Text, TouchableOpacity, SafeAreaView, View } from "react-native"
import { MaterialIcons } from "@expo/vector-icons"
import Constants from "expo-constants"
import DefaultTheme from "../themes/DefaultTheme"
import { useData } from "../providers/DataProvider"
import FSStoreContent from "../firebase/FSStoreContent"
import { checkAppVersion } from "../utils/general"
import { useStatus } from "../providers/StatusProvider"
import { versionCheckTypeEnum } from "../data/enums"
import { configNames } from "../firebase/config"

export default function AppReportIssueComponentWeb({ navigation, imageRef }) {
  const { currentUser, setAlertData, firebaseConfig, backendDeployment } = useData()
  const { setShowAlert } = useStatus()

  const { useScreenshot } = require("use-react-screenshot")
  const [image, takeScreenshot] = useScreenshot()

  async function handleReportIssueWeb() {
    const resultUri = await takeScreenshot(imageRef.current)
    navigation.navigate("ReportIssueScreen", { uri: resultUri })
  }

  return (
    <SafeAreaView
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: DefaultTheme.colors.background,
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <MaterialIcons
          name="system-update"
          size={24}
          color={DefaultTheme.colors.primary}
          style={{ paddingHorizontal: 15, paddingVertical: 10, cursor: "pointer" }}
          onPress={() =>
            FSStoreContent.appVersionGet((updateData) =>
              checkAppVersion(updateData, setShowAlert, setAlertData, versionCheckTypeEnum.MANUAL)
            )
          }
        />
        <View>
          <View style={{ flexDirection: "row" }}>
            <Text>{`v${Constants.manifest.version}`}</Text>
            <Text
              style={{
                color:
                  Constants.manifest.extra.commitHash?.substring(0, 8) === backendDeployment?.substring(0, 8)
                    ? DefaultTheme.colors.primary
                    : DefaultTheme.colors.danger,
              }}
            >
              {Constants.manifest.extra.commitHash?.substring(0, 8) === backendDeployment?.substring(0, 8)
                ? ` - ${Constants.manifest.extra.commitHash?.substring(0, 8)}`
                : ` - ${Constants.manifest.extra.commitHash?.substring(0, 8)}/${backendDeployment?.substring(0, 8)} `}
            </Text>
            <Text>{`|${Constants.manifest.extra.environment}| - |${configNames[firebaseConfig]}|`}</Text>
          </View>
          <Text>{currentUser.email}</Text>
        </View>
      </View>
      <TouchableOpacity
        style={{ flexDirection: "row", alignItems: "center", paddingRight: 10 }}
        onPress={handleReportIssueWeb}
      >
        <Text>Nahlásit</Text>
        <MaterialIcons name="report-problem" size={24} color={DefaultTheme.colors.primary} />
      </TouchableOpacity>
    </SafeAreaView>
  )
}
