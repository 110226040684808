import React, { useCallback, useEffect, useState } from "react"
import { useNavigation } from "@react-navigation/core"
import { FlatList, Text, View, ScrollView, Platform, RefreshControl, Button } from "react-native"
import Constants from "expo-constants"

import { getAccountBalanceList, getTransactionHistory } from "../../actions/bankActions"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import FlatListTransactionItem from "../../components/FlatLists/FlatListTransactionItem"
import Screen from "../../components/Screen"
import { environmentEnum, statusEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"
import { useStatus } from "../../providers/StatusProvider"
import { toCurrentDateTime } from "../../utils/general"
import DateIntervalButtons from "../../components/DateIntervalButtons"

export default function TransactionsOverview() {
  // if (Platform.OS === "web") {
  //   const { RefreshControl } = require("react-native-web-refresh-control")
  // } else {
  //   const { RefreshControl } = require("react-native")
  // }
  const navigation = useNavigation()
  const { setStatus, setTitle, setMessage } = useStatus()
  const { settings, firebaseConfig } = useData()
  const [balance, setBalance] = useState()
  const [transactionList, setTransactionList] = useState([])
  const [isTransacListLoading, setIsTransacListLoading] = useState(true)

  const today = new Date()
  const [dateInterval, setDateInterval] = useState({
    from: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    to: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7),
  })


  async function loadBalance() {
    try {
      setBalance(null)
      const balanceList = await getAccountBalanceList(firebaseConfig)
      console.debug("BALANCE LIST", balanceList)
      if (balanceList) setBalance(balanceList.find((bal) => bal.type === "CLOSING_AVAILABLE"))
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async function loadTransactionList() {
    try {
      setTransactionList([])
      setIsTransacListLoading(true)

      const transactionHistoryList = await getTransactionHistory(firebaseConfig, dateInterval.from, dateInterval.to)

      console.debug("TRANSACTION LIST", transactionHistoryList)
      if (transactionHistoryList) setTransactionList(transactionHistoryList)
      setIsTransacListLoading(false)

    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return (
    <Screen>
      <ScrollView
        style={{ flex: 1 }}
      >
        <View style={{ padding: 5 }}>
          <Text>DASAR PR s.r.o</Text>
          <Text>{settings.kb_bank?.iban || "Zadejte iban v nastavení -> platební automat"}</Text>
          <Text style={{ fontSize: 24 }}>
            {balance ? `${balance.amount.value} ${balance.amount.currency}` : "Balance není k dispozici"}
          </Text>
          <Button title={"Načíst Balance"} onPress={loadBalance}/>
        </View>
        <Text>{balance && toCurrentDateTime(balance.validAt)}</Text>
        <DateIntervalButtons dateInterval={dateInterval} setDateInterval={setDateInterval} />
        <Button title={"Načíst transakce"} onPress={loadTransactionList}/>

        {transactionList.length > 0 ? (
          <FlatList
            data={transactionList}
            renderItem={({ item }) => <FlatListTransactionItem transaction={item} />}
            ItemSeparatorComponent={FlatListSeparator}
            keyExtractor={(item, index) => index.toString()}
          />
        ) : (
          <AppEmptyScreenView
            message={isTransacListLoading ? "Transakce se načítají" : "Pro zvolené období neexistují žádné transakce"}
          />
        )}
      </ScrollView>
    </Screen>
  )
}
