import { Platform } from "react-native"
import * as ImagePicker from "expo-image-picker"
import * as DocumentPicker from "expo-document-picker"
import * as Location from "expo-location"

export async function openLibrary() {
  if (Platform.OS !== "web") {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    if (status !== "granted") {
      alert("Sorry, we need camera roll permissions to make this work!")
    }
  }
  return await pickImage()
}

async function pickImage() {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    quality: 1,
  })

  if (!result.cancelled) {
    return result.uri
  }
}

export async function openFileStorage() {
  const result = await DocumentPicker.getDocumentAsync({})

  if (result.type !== "cancel") {
    const fileExt = result.name.substring(result.name.length - 3)

    if (!(fileExt === "pdf" || fileExt === "PDF")) {
      throw { message: "Soubor musí být formátu PDF!" }
    }

    return { localUri: result.uri, fileExt }
  }

  return { localUri: null }
}

export async function takePicture(camRef, setCaptured) {
  if (camRef) {
    setCaptured(true)
    const result = await camRef.current.takePictureAsync()
    setCaptured(false)

    if (!result.cancelled) {
      return result.uri
    }
  }
}

export async function getLocation() {
  const { status } = await Location.requestForegroundPermissionsAsync()
  if (status !== "granted") {
    throw {
      title: "Permission to access location was denied",
      message: "Pro získání souřadnic povolte aplikací získat vaši polohu.",
    }
  }

  return await Location.getCurrentPositionAsync({})
}
