import React, { useRef, useState } from "react"
import { firebase } from "../../firebase/config"
import { TouchableOpacity, View, Platform } from "react-native"
import { Menu, Divider } from "react-native-paper"
import { MaterialCommunityIcons, Ionicons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import { useStatus } from "../../providers/StatusProvider"
import { actionEnum, projectStateEnum, statusEnum } from "../../data/enums"
import { monthStateEnum } from "../../data/enums"
import { isAccountant, isAdmin, isExecutive, isOfficeManager } from "../../utils/validation"
import * as DocumentPicker from "expo-document-picker"
import Constants from "expo-constants"
import { useData } from "../../providers/DataProvider"
import { getBucketName, getProcessBankStatementUrl } from "../../utils/backendUrls"

export default function CashDeskMonthDetailHeaderMenu({ route, navigation }) {
  const [showMenu, setShowMenu] = useState(false)
  const { currentUser, firebaseConfig } = useData()
  const { setTitle, setMessage, setPercentUploaded, setStatus } = useStatus()
  const hasEditPermission =
    isAdmin(currentUser) || isExecutive(currentUser) || isAccountant(currentUser) || isOfficeManager(currentUser)

  const monthState = route.params.monthState
  const monthIndex = route.params.monthIndex
  const user = route.params.user
  const cashDesk = route.params.cashDesk
  const yearMonth = route.params.yearMonth

  const updatePercentage = useRef()

  async function openFileStorage(monthIndex, userEmail, configId) {
    try {
      const result = await DocumentPicker.getDocumentAsync({})
      const fileExt = result.name.substring(result.name.length - 3)

      if (result.type !== "cancel" || fileExt === "csv") {
        setTitle("Kontroluji doklady")
        setStatus(statusEnum.PROGRESS_UNDETERMINED)
        navigation.navigate("StatusScreen")
        const downloadUrl = await saveCsvFile(result)

        console.debug("fileName", result.name)
        console.debug("monthIndex", monthIndex)

        const token = await firebase.apps[0].auth().currentUser.getIdToken()

        const url = getProcessBankStatementUrl(firebaseConfig)

        console.debug("URL", url)

        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({
            fileName: result.name,
            monthIndex,
            userEmail,
            currentUserEmail: currentUser.email,
            bucketName: getBucketName(configId),
          }),
        })

        const res = await response.json()

        if (response.status === 400 || res.status === 400) {
          setStatus(statusEnum.ERROR)
          setMessage(res?.message)
        } else if (response.status === 200 || res.status === 200) {
          setStatus(statusEnum.SUCCESS)
          setMessage(res?.message)
        }
      } else {
        throw { message: "Soubor musí být formátu CSV!" }
      }
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  async function saveCsvFile(result) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662
    return new Promise(async (resolve, reject) => {
      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.onload = function () {
          resolve(xhr.response)
        }
        xhr.onerror = function (e) {
          setStatus(statusEnum.ERROR)
          reject(e)
        }
        xhr.responseType = "blob"
        xhr.open("GET", result.uri, true)
        xhr.send(null)
      })

      const ref = firebase.apps[0].storage().ref("cash-desk-imported-csv").child(result.name)
      const snapshot = ref.put(blob)

      snapshot.on(
        "state_changed",
        updatePercentage.current,
        (error) => {
          console.error("Error while uploading csv: ", error)
          reject(new Error(error))
        },

        async () => {
          const downloadUri = await snapshot.snapshot.ref.getDownloadURL()
          console.debug("FINISHED FILE UPLOAD")
          console.debug("FILE AVAILABLE AT: ", downloadUri)
          resolve(downloadUri)
        }
      )
    })
  }

  updatePercentage.current = (snap) => {
    const p = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
    setPercentUploaded(p)
  }

  function handleImportCSV() {
    setShowMenu(false)
    if (monthState === monthStateEnum.CLOSED) {
      openFileStorage(monthIndex, user?.email, firebaseConfig)
    } else {
      setTitle("Nahrání CSV souboru")
      setStatus(statusEnum.ERROR)
      setMessage("Pro nahrání CSV souboru je třeba uzavřít měsíc")
      navigation.navigate("StatusScreen")
    }
  }

  return (
    <Menu
      visible={showMenu}
      onDismiss={() => setShowMenu(false)}
      anchor={
        <TouchableOpacity onPress={() => setShowMenu(true)} style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.headerSecondary}
          />
        </TouchableOpacity>
      }
    >
      <Menu.Item
        style={{ direction: "ltr" }}
        onPress={() => {
          setShowMenu(false)
          navigation.navigate("CashDeskMonthBulkAction", route.params)
        }}
        title="Hromadná akce"
        icon={() => (
          <MaterialCommunityIcons
            name="checkbox-multiple-marked-outline"
            size={DefaultTheme.icons.menuSize}
            color={DefaultTheme.colors.primary}
          />
        )}
      />
      {hasEditPermission && (
        <Menu.Item
          style={{ direction: "ltr" }}
          onPress={() => {
            setShowMenu(false)
            navigation.navigate("CashDeskCharge", {
              user,
              monthTitle: route.params.monthTitle,
              yearMonth,
              cashDesk,
            })
          }}
          title="Dotace pokladny"
          icon={() => (
            <MaterialCommunityIcons
              name="cash-plus"
              size={DefaultTheme.icons.headerSizeRight}
              color={DefaultTheme.colors.primary}
            />
          )}
        />
      )}
      {hasEditPermission && (
        <Menu.Item
          style={{ direction: "ltr" }}
          onPress={handleImportCSV}
          title="Kontrola s výpisem"
          icon={() => (
            <MaterialCommunityIcons
              name="file-import"
              size={DefaultTheme.icons.headerSizeRight}
              color={DefaultTheme.colors.primary}
            />
          )}
        />
      )}
    </Menu>
  )
}
