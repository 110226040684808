import React from "react"
import { View } from "react-native"
import { appPickerViewType } from "../data/enums"
import { monthNameLabels } from "../data/labels"
import AppPicker from "./AppPicker"

export default function AppDatePickerWeb({ value, onConfirm, disabled = false, webLabel, viewType, extra = 0 }) {
  const today = new Date()
  value = value ? (typeof value?.getMonth === "function" ? value : value.toDate()) : new Date()

  const numOfDays = new Date(value.getFullYear(), value.getMonth() + 1, 0).getDate()

  let dayItems = []
  for (let day = 1; day < numOfDays + 1; day++) {
    dayItems.push({ label: day.toString(), value: day, key: day })
  }

  let monthItems = []
  for (let month = 0; month < 12; month++) {
    monthItems.push({ label: monthNameLabels[month], value: month, key: month })
  }

  let yearItems = []
  const minYear = viewType === appPickerViewType.USER ? 99 : 1
  const maxYear = viewType === appPickerViewType.USER ? -14 : 2

  for (let year = today.getFullYear() - (minYear + extra); year < today.getFullYear() + maxYear; year++) {
    yearItems.push({ label: year.toString(), value: year, key: year })
  }

  return (
    <View style={{ flexDirection: "row" }}>
      <View style={{ flex: 1 }}>
        <AppPicker
          placeholder={{}}
          descriptionLabel={webLabel[0]}
          value={value.getDate()}
          onValueChange={(day) => {
            const tempDate = new Date(value)
            tempDate.setDate(Number(day))
            onConfirm(tempDate)
          }}
          items={dayItems}
          disabled={disabled}
        />
      </View>
      <View style={{ flex: 1 }}>
        <AppPicker
          placeholder={{}}
          descriptionLabel={webLabel[1]}
          value={value.getMonth()}
          onValueChange={(month) => {
            const tempDate = new Date(value)
            tempDate.setMonth(Number(month))
            onConfirm(tempDate)
          }}
          items={monthItems}
          disabled={disabled}
        />
      </View>
      <View style={{ flex: 1 }}>
        <AppPicker
          placeholder={{}}
          descriptionLabel={webLabel[2]}
          value={value.getFullYear()}
          onValueChange={(year) => {
            const tempDate = new Date(value)
            tempDate.setFullYear(Number(year))
            onConfirm(tempDate)
          }}
          items={yearItems}
          disabled={disabled}
        />
      </View>
    </View>
  )
}
