import React, { useEffect } from "react"
import { View } from "react-native"
import { TextInput } from "react-native-paper"
import { useState } from "react"
import NextButton from "../../components/NextButton"
import Screen from "../../components/Screen"
import DefaultTheme from "../../themes/DefaultTheme"
import { useStatus } from "../../providers/StatusProvider"
import { isNumber } from "../../utils/validation"
import { useData } from "../../providers/DataProvider"
import { formatDecimalNumber } from "../../utils/general"
import { actionEnum, statusEnum } from "../../data/enums"
import FSStoreContent from "../../firebase/FSStoreContent"

export default function CashDeskCharge({ navigation, route }) {
  const { cashDesks } = useData()
  const { setTitle, setStatus, setMessage, setToScreen } = useStatus()
  const [charges, setCharges] = useState({ initial_charge: "0", residual_charge: "0" })

  const yearMonth = route.params.yearMonth
  const userEmail = route.params.user.email

  const cashDesk = cashDesks.find((cd) => cd.user === userEmail) || {}

  useEffect(() => {
    if (cashDesk[yearMonth]?.charges) {
      setCharges(cashDesk[yearMonth]?.charges)
    }
  }, [cashDesks])

  async function handleSave() {
    const sanitizeCharges = {
      initial_charge: Number(charges.initial_charge),
      residual_charge: Number(charges.residual_charge),
    }

    try {
      setTitle(`Dotace pokladny`)
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      setToScreen("CashDeskMonths")
      navigation.navigate("StatusScreen")

      await FSStoreContent.updateCashDesk({ [yearMonth]: { charges: sanitizeCharges }, user: userEmail })

      setStatus(statusEnum.SUCCESS)
      setMessage("Dotace proběhla úspěšně!")
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
  }

  return (
    <Screen>
      <View style={{ flex: 1, paddingVertical: 10 }}>
        <TextInput
          error={!isNumber(charges.residual_charge)}
          label={
            charges.residual_charge
              ? "Částka dotace přenesena z minulého měsíce"
              : "Zadejte částku dotace přenesené z minulého měsíce"
          }
          keyboardType="numeric"
          style={DefaultTheme.input}
          value={String(charges.residual_charge)}
          onChangeText={(value) => setCharges({ ...charges, residual_charge: formatDecimalNumber(value) })}
        />
        <TextInput
          error={!isNumber(charges.initial_charge)}
          label={charges.initial_charge ? "Částka dotace" : "Zadejte částku dotace"}
          keyboardType="numeric"
          style={DefaultTheme.input}
          value={String(charges.initial_charge)}
          onChangeText={(value) => setCharges({ ...charges, initial_charge: formatDecimalNumber(value) })}
        />
      </View>
      <NextButton
        disabled={!isNumber(charges.initial_charge) || !isNumber(charges.residual_charge)}
        text="Uložit"
        onPress={handleSave}
      />
    </Screen>
  )
}
