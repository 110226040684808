import { firebase as fb } from "./config"
import { filterUserProjects, getProjectState } from "../utils/general"
import { isAccountant, isAdmin, isExecutive, isOfficeManager, isTechnicalDirector } from "../utils/validation"
import { environmentEnum, propertyTypeEnum, userRoleEnum } from "../data/enums"
import { createDocumentUriAbstraction, removeDocumentUriAbstraction } from "../abstractions/documentAbstractions"
import Constants from "expo-constants"
import uuid from "uuid"
import firebase from "firebase/compat/app"
import { generatedExtraCashflowDocs } from "./getExtraCashflowDocs"

export default class FSStoreContent {
  // TODO change to functional component

  static async onSnapshotProjects(setProjects, setAccountingDocuments, user) {
    return new Promise((resolve, reject) => {
      fb.apps[0]
        .firestore()
        .collection("projects")
        .onSnapshot(async (querySnapshot) => {
          try {
            const projects = querySnapshot.docs.map((doc) => {
              const obj = doc.data()
              obj.id = doc.id
              if (obj.start_date) obj.start_date = obj.start_date.toDate()
              if (obj.end_date) obj.end_date = obj.end_date.toDate()
              return obj
            })
            await this.onSnapshotAccountingDocuments(setAccountingDocuments, user)
            if (
              isAdmin(user) ||
              isExecutive(user) ||
              isAccountant(user) ||
              isTechnicalDirector(user) ||
              isOfficeManager(user)
            ) {
              setProjects(projects)
            } else {
              const filteredProjs = filterUserProjects(projects, user)
              setProjects(filteredProjs)
            }
            resolve()
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
    })
  }

  static async onSnapshotAccountingDocuments(setAccountingDocuments, user) {
    return new Promise((resolve, reject) => {
      fb.apps[0]
        .firestore()
        .collection("accountingDocuments")
        .onSnapshot(async (querySnapShot) => {
          try {
            const userProjects = await this.loadUserProjects(user)
            const documents = []
            querySnapShot.docs.forEach((doc) => {
              if (
                isAdmin(user) ||
                isExecutive(user) ||
                isAccountant(user) ||
                isTechnicalDirector(user) ||
                isOfficeManager(user)
              ) {
                let obj = doc.data()
                obj.id = doc.id
                obj = createDocumentUriAbstraction(obj)
                documents.push(obj)
              } else if (
                userProjects.some((project) => project.id === doc.data().project) ||
                doc.data().created_by === user.email
              ) {
                let obj = doc.data()
                obj.id = doc.id
                obj = createDocumentUriAbstraction(obj)
                documents.push(obj)
              }
            })
            documents.sort((a, b) => b.created_at.seconds - a.created_at.seconds)
            setAccountingDocuments(documents)
            resolve()
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
    })
  }

  static async onSnapshotCompanyDocuments(setCompanyDocuments) {
    return new Promise((resolve, reject) => {
      fb.apps[0]
        .firestore()
        .collection("documents")
        .onSnapshot(async (querySnapShot) => {
          try {
            const documents = querySnapShot.docs.map((doc) => {
              let obj = doc.data()
              obj.id = doc.id
              obj = createDocumentUriAbstraction(obj)
              return obj
            })
            setCompanyDocuments(documents)
            resolve()
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
    })
  }

  static async onSnapshotUserTasks(setUserTasks, user) {
    return new Promise((resolve, reject) => {
      fb.apps[0]
        .firestore()
        .collection("tasks")
        .where("action_users", "array-contains", user?.email || fb.apps[0].auth().currentUser.email)
        .onSnapshot(async (querySnapShot) => {
          try {
            const tasks = querySnapShot.docs
              .map((doc) => {
                if (!doc.data().isDone && doc.data().action_users.some((actionUser) => actionUser === user.email)) {
                  const obj = doc.data()
                  obj.id = doc.id
                  return obj
                }
              })
              .filter((task) => task)
            tasks.sort((a, b) => b.created_at.seconds - a.created_at.seconds)
            setUserTasks(tasks)
            resolve()
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
    })
  }

  static async onSnapshotUsers(setUsers) {
    return new Promise((resolve, reject) => {
      fb.apps[0]
        .firestore()
        .collection("users")
        .onSnapshot((querySnapShot) => {
          try {
            const users = querySnapShot.docs.map((doc) => {
              const userDoc = doc.data()

              return {
                ...userDoc,
                disabled: userDoc?.disabled || false,
                roles: userDoc?.roles || [userRoleEnum.VIEWER],
                export_user: userDoc?.export_user || false,
                export_user_development: userDoc?.export_user_development || false,
              }
            })
            if (fb.apps[0].name === "prod") setUsers(users)
            if (fb.apps[0].name === "prod-1") setUsers(users.filter((el) => el.export_user))
            if (fb.apps[0].name === "prod-development") setUsers(users.filter((el) => el.export_user_development))
            resolve(users)
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
    })
  }

  static async onSnapshotSettings(setSettings) {
    return new Promise((resolve, reject) => {
      fb.apps[0]
        .firestore()
        .collection("settings")
        .onSnapshot((querySnapShot) => {
          try {
            const settings = {}
            querySnapShot.docs.forEach((doc) => (settings[doc.id] = doc.data()))
            setSettings(settings)
            resolve(settings)
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
    })
  }

  static async onSnapshotCashDesks(setCashDesks, user) {
    return new Promise((resolve, reject) => {
      fb.apps[0]
        .firestore()
        .collection("cashDesks")
        .onSnapshot((querySnapShot) => {
          try {
            const cashDesks = []
            querySnapShot.docs.forEach((doc) => {
              const obj = doc.data()
              obj.user = doc.id
              if (
                isAdmin(user) ||
                isExecutive(user) ||
                isAccountant(user) ||
                isTechnicalDirector(user) ||
                isOfficeManager(user)
              ) {
                cashDesks.push(obj)
              } else if (doc.id === user.email) {
                cashDesks.push(obj)
              }
            })
            setCashDesks(cashDesks)
            resolve()
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
    })
  }

  static async onSnapshotUserMessages(setUserMessages, currentUser) {
    return new Promise((resolve, reject) => {
      fb.apps[0]
        .firestore()
        .collection("messages")
        .onSnapshot((querySnapShot) => {
          try {
            const messages = querySnapShot.docs.map((doc) => {
              const obj = doc.data()
              obj.createdAt = obj.createdAt.toDate()
              return obj
            })

            const filtered = messages.filter((m) => m.user._id === currentUser.email || m.user.to === currentUser.email)
            const sorted = filtered.sort((a, b) => b.createdAt - a.createdAt)
            setUserMessages(sorted)
            resolve(sorted)
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
    })
  }

  static async enrichPropertyList(propertyList) {
    return await Promise.all(propertyList.map(async (property) => {
      return await FSStoreContent.enrichProperty(property)
    }))
  }
  static async enrichProperty(property) {
    if (property.hasOwnProperty("type") && property.type === propertyTypeEnum.VEHICLE) {
      const lastLog = await FSStoreContent.onLogBookVehicleActualKm(property.vehicle_id)
      if (lastLog && (!property.actual_km_date || lastLog.date > property.actual_km_date || lastLog.created_at > property.actual_km_date))
        return {
          ...property,
          actual_km: lastLog.end_km,
          actual_km_source: "Kniha jízd " + lastLog.from_to,
          actual_km_created_by: lastLog.created_by,
          actual_km_date: lastLog.date
        }
    }
    return property
  }
  static async getProperty(id) {
    try {
      const propSnap = await fb.apps[0].firestore().collection("property").doc(id).get()
      return { ...propSnap.data(), id: propSnap.id }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  static async onSnapshotProperty(setProperty) {
    return new Promise(async (resolve, reject) => {
      fb.apps[0]
        .firestore()
        .collection("property")
        .onSnapshot((querySnapShot) => {
          try {
            const property = querySnapShot.docs.map((doc) => {
              const prop = doc.data()
              prop.id = doc.id
              prop.created_at = doc.data().created_at?.toDate()
              prop.type = prop.type || propertyTypeEnum.NOT_STATED
              return prop
            })
            setProperty(property)
            resolve(property)
          } catch (error) {
            console.error("Error loading property:", error)
            reject(error)
          }
        })
    })
  }

  static async onSnapshotLogBookUser(userEmail, next) {
    fb.apps[0]
      .firestore()
      .collection("logBook")
      .doc(userEmail)
      .collection("logsMonthly")
      .onSnapshot((querySnapshot) => {
        try {
          const logBook = querySnapshot.docs.map((doc) => {
            const obj = doc.data()
            obj.id = doc.id
            obj.user = userEmail
            return obj
          })
          next.current(logBook)
        } catch (error) {
          console.error("Error loading log book:", error)
          reject(error)
        }
      })
  }

  static async onLogBookVehicleActualKm(vehicleId) {
    console.log("onLogBookVehicleActualKm with vehicleId " + vehicleId)
    return new Promise(async (resolve, reject) => {
        let querySnapshot = await fb.apps[0].firestore().collection("logBook").get()
        //console.log("onLogBookVehicleActualKm querySnapshot.docs " + JSON.stringify(querySnapshot.docs))
          try {
            const logBookDocs = await Promise.all(querySnapshot.docs.map( async (doc) => {
              const obj = {}
              obj.id = doc.id
              obj.user =  doc.id
              obj.logsMonthly = (await fb.apps[0].firestore().collection("logBook").doc(doc.id).collection("logsMonthly").get())
                .docs.map( docSnapshot => { return { user: doc.id, logs: docSnapshot.data().logs, id: docSnapshot.id } })
              return obj
            }))
            //console.log("onLogBookVehicleActualKm logBookDocs " + JSON.stringify(logBookDocs))
            let allLogsForVehicle = logBookDocs.flatMap(log => log.logsMonthly)
            //console.log("onLogBookVehicleActualKm allLogsForVehicle " + JSON.stringify(allLogsForVehicle))
            allLogsForVehicle = allLogsForVehicle.flatMap(log => log.logs)
            //console.log("onLogBookVehicleActualKm allLogsForVehicle " + JSON.stringify(allLogsForVehicle))
            const allLogsForVehicleFiltered =  allLogsForVehicle.filter( log => log.vehicle_id === vehicleId)
            //console.log("onLogBookVehicleActualKm allLogsForVehicleFiltered " + JSON.stringify(allLogsForVehicleFiltered))
            allLogsForVehicleFiltered.sort((a, b) => {
              return a.date - b.date
            })
            const lastLog = allLogsForVehicleFiltered[allLogsForVehicleFiltered.length - 1]
            if (lastLog) {
              console.log("onLogBookVehicleActualKm lastLog " + JSON.stringify(lastLog))
              resolve(lastLog)
            } else {
              console.log("onLogBookVehicleActualKm lastLog no last log")
              resolve(undefined)
            }
          } catch (error) {
            console.error("Error loading log book:", error)
            reject(error)
          }
    })
  }

  static async getLogBookUser(userEmail, dateTime) {
    try {
      return (
        await fb.apps[0].firestore().collection("logBook").doc(userEmail).collection("logsMonthly").doc(dateTime).get()
      ).data()
    } catch (error) {
      console.error("Error loading log book:", error)
      reject(error)
    }
  }

  static async loadUserProjects(user) {
    return new Promise((resolve, reject) => {
      fb.apps[0]
        .firestore()
        .collection("projects")
        .onSnapshot((querySnapshot) => {
          try {
            const projects = querySnapshot.docs
              .map((doc) => {
                if (isAdmin(user) || isExecutive(user) || isAccountant(user) || isTechnicalDirector(user)) {
                  return doc.data()
                } else {
                  const isInProject = doc.data().users?.some((u) => u.email === user.email)
                  if (isInProject) {
                    return doc.data()
                  }
                }
              })
              .filter((doc) => doc)
            resolve(projects)
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
    })
  }

  static async onSnapshotUser(setCurrentUser, firebaseConfig) {
    const userEmail = fb.apps[0].auth().currentUser.email
    console.debug("User logging in: ", userEmail)
    return new Promise((resolve, reject) => {
      fb.apps[0]
        .firestore()
        .collection("users")
        .onSnapshot(async (querySnapshot) => {
          try {
            // const mappedData = querySnapshot.docs.map((el) => el.data()?.email || "no doc data" + el.id)
            // console.debug(
            //   "SNAP DOCUMENTS WITHOUT EMAIL EXIST: ",
            //   mappedData.some((el) => el.includes("no doc data"))
            // )
            // const noDocData = mappedData.find((el) => el.includes("no doc data"))
            // if (noDocData) {
            //   const splitData = noDocData.split("no doc data")[1]
            //   const docRef = await fb.apps[0].firestore().collection("users").doc(splitData).get()
            //   console.debug("FOUND DOC DATA THAT SNAPPED WITH NO EMAIL", docRef.data())
            //   console.debug(
            //     "querySnapshot",
            //     querySnapshot.docs.map((doc) => doc.data())
            //   )
            // }

            querySnapshot.docs.map((doc) => {
              const user = doc.data()
              if (user?.email && user?.email?.toLowerCase() === userEmail.toLowerCase()) {
                if (user?.disabled) {
                  fb.apps[0].auth().signOut()
                }
                setCurrentUser({
                  ...user,
                  export_user: user?.export_user || false,
                  export_user_development: user?.export_user_development || false,
                  disabled: user?.disabled || false,
                  roles: user?.roles || [userRoleEnum.VIEWER],
                })
                resolve(user)
              }
            })
          } catch (error) {
            console.error("Error while getting user data: ", " ", error)

            reject(error)
          }
        })
    })
  }

  static async updateUser(user) {
    user.email = user.email.toLowerCase()
    try {
      if (fb.apps[0].name === "prod-1") user = { ...user, export_user: true }
      if (fb.apps[0].name === "prod-development")  user = { ...user, export_user_development: true }

      await fb.apps[0].firestore().collection("users").doc(user.email).set(user, { merge: true })
    } catch (error) {
      throw new Error("userUpdate: " + error.message)
    }
  }

  static async setUser(user) {
    try {
      await fb.apps[0].firestore().collection("users").doc(user.email).set(user)
    } catch (error) {
      throw new Error("userUpdate: " + error.message)
    }
  }

  static async updateProject(project) {
    try {
      project.state = getProjectState(project)
      await fb.apps[0].firestore().collection("projects").doc(project.id).set(project, { merge: true })
    } catch (error) {
      console.error("Error updating project ", error)
      throw error
    }
  }

  static async getAdminProjectId() {
    try {
      // find project id which administration feature
      const adminProject = await fb.apps[0].firestore().collection("projects").where("administration.enabled", '==',  true).where('administration.year', '==', new Date().getFullYear()).get()
      console.log("Found admin project" + JSON.stringify(adminProject))
      const adminProjectId = adminProject?.id || "13"
      return adminProjectId
    } catch (error) {
      console.error("Error updating project ", error)
      throw error
    }
  }


  static async updateAccountingDocument(document) {
    try {
      const tempDoc = removeDocumentUriAbstraction(document)
      console.debug("ACC DOC TO SAVE", tempDoc)
      await fb.apps[0].firestore().collection("accountingDocuments").doc(tempDoc.id).set(tempDoc, { merge: true })
    } catch (error) {
      console.error("Error updating document ", error)
      throw error
    }
  }

  static async setAccountingDocument(document) {
    try {
      const tempDoc = removeDocumentUriAbstraction(document)
      console.debug("ACC DOC TO SAVE", tempDoc)
      await fb.apps[0].firestore().collection("accountingDocuments").doc(tempDoc.id).set(tempDoc)
    } catch (error) {
      console.error("Error updating document ", error)
      throw error
    }
  }

  static async addAccountingDocument(document) {
    try {
      const tempDoc = removeDocumentUriAbstraction(document)
      console.debug("ACC DOC TO SAVE", tempDoc)
      return await fb.apps[0].firestore().collection("accountingDocuments").add(tempDoc)
    } catch (error) {
      console.error("Error updating document ", error)
      throw error
    }
  }

  static async addDocument(document) {
    const tempDoc = removeDocumentUriAbstraction(document)

    try {
      console.debug("ADDING DOC", tempDoc)
      return await fb.apps[0]
        .firestore()
        .collection("accountingDocuments")
        .add({ ...tempDoc, created_at: new Date(), updated_at: new Date() })
    } catch (error) {
      console.error("Error creating document ", error)
      throw error
    }
  }

  static async updateAttendance(attendances) {
    try {
      await fb.apps[0]
        .firestore()
        .collection("attendances")
        .doc(attendances.userEmail)
        .set(attendances, { merge: true })
    } catch (error) {
      console.error("Error updating attendance ", error)
      throw error
    }
  }

  static async markTaskAsDone(taskId) {
    try {
      await fb.apps[0].firestore().collection("tasks").doc(taskId).update({ isDone: true })
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  static async updateTask(task) {
    try {
      await fb.apps[0].firestore().collection("tasks").doc(task.id).set(task, { merge: true })
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  static async deleteTask(taskId) {
    try {
      await fb.apps[0].firestore().collection("tasks").doc(taskId).delete()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  static async getCashDesk(userEmail) {
    try {
      const cashDeskSnap = await fb.apps[0].firestore().collection("cashDesks").doc(userEmail).get()
      return { ...cashDeskSnap.data(), user: cashDeskSnap.id }
    } catch (error) {
      console.error("Error updating attendance ", error)
      throw error
    }
  }

  static async updateCashDesk(cashDesk) {
    try {
      await fb.apps[0].firestore().collection("cashDesks").doc(cashDesk.user).set(cashDesk, { merge: true })
      console.debug("Cash desk updated Succesfully")
    } catch (error) {
      console.error("Error updating attendance ", error)
      throw error
    }
  }

  // musí být vytvořen dokument na úrovní logBook, aby bylo možné prohledávat přes všechny záznamy
  static async setLogBookMonthDetailRecord(monthLogBook) {
    await fb.apps[0]
      .firestore()
      .collection("logBook")
      .doc(monthLogBook.user)
      .set({ user: monthLogBook.user }, { merge: true })
  }

    static async setLogBookMonthDetail(monthLogBook) {
    try {
      await FSStoreContent.setLogBookMonthDetailRecord(monthLogBook)
      await fb.apps[0]
        .firestore()
        .collection("logBook")
        .doc(monthLogBook.user)
        .collection("logsMonthly")
        .doc(monthLogBook.id)
        .set(monthLogBook, { merge: true })
    } catch (error) {
      console.error("Error updating month log book", error)
      throw error
    }
  }

  static async updateMonthLogBook(monthLogBook) {
    try {
      await FSStoreContent.setLogBookMonthDetailRecord(monthLogBook)
      await fb.apps[0]
        .firestore()
        .collection("logBook")
        .doc(monthLogBook.user)
        .collection("logsMonthly")
        .doc(monthLogBook.id)
        .set(monthLogBook, { merge: true })
    } catch (error) {
      console.error("Error updating vehicle log ", error)
      throw error
    }
  }

  static async onSnapshotUserPremiums(setUserPremiumActions, setLastPremiumAction, projectId, userEmail) {
    return new Promise((resolve, reject) => {
      fb.apps[0]
        .firestore()
        .collection("premiums")
        .doc(userEmail)
        .onSnapshot((snapshot) => {
          try {
            if (snapshot.data()?.[projectId]?.requesting_items) {
              const obj = snapshot.data()
              obj.id = snapshot.id
              setUserPremiumActions(obj)

              setLastPremiumAction(
                obj[projectId]?.requesting_items
                  .map((item) => ({
                    ...item,
                    created_at: item.created_at.toDate(),
                  }))
                  .sort((a, b) => b.created_at - a.created_at)[0]
              )
            }
            resolve()
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
    })
  }

  /*
    Return object with pairs -> projectId: [userPremiumActions]
  */
  static async onSnapshotUserPremiumActions(setPremiums) {
    return new Promise((resolve) => {
      fb.apps[0]
        .firestore()
        .collection("premiums")
        .onSnapshot((snap) => {
          try {
            const premiums = snap.docs.map((doc) => ({
              ...doc.data(),
              email: doc.id,
            }))
            setPremiums(premiums)
            resolve()
          } catch (error) {
            console.error(error)
            throw error
          }
        })
    })
  }

  static async onExtraDocument(setExtraDocs) {
    return new Promise(async (resolve, reject) => {
      try {
        const extraDocs = await generatedExtraCashflowDocs()
        setExtraDocs(extraDocs)
        resolve()
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }

  static async updateUserPremiums(user, userPremiumActions) {
    try {
      await fb.apps[0].firestore().collection("premiums").doc(user.email).set(userPremiumActions, { merge: true })
    } catch (error) {
      console.error("Error updating user premiums", error)
      throw error
    }
  }

  static async updateSettings(setting) {
    try {
      await fb.apps[0].firestore().collection("settings").doc(setting.id).set(setting, { merge: true })
    } catch (error) {
      console.error("Error updating setting ", error)
      throw error
    }
  }

  static async uploadCompanyDocument(document) {
    try {
      const tempDoc = removeDocumentUriAbstraction(document)
      console.debug("company doc to save", tempDoc)
      await fb.apps[0]
        .firestore()
        .collection("documents")
        .doc()
        .set({ ...tempDoc, created_at: new Date() }, { merge: true })
    } catch (error) {
      console.error("Error uploading document ", error)
      throw error
    }
  }

  static async uploadProperty(property, merge = true) {
    try {
      await fb.apps[0].firestore().collection("property").doc(property.id).set(property, { merge })
      console.debug("Property uploaded Succesfully")
    } catch (error) {
      console.error("Error uploading document ", error)
      throw error
    }
  }

  static async loadDocument(id) {
    try {
      const docSnap = await fb.apps[0].firestore().collection("accountingDocuments").doc(id).get()
      if (docSnap.data()) {
        let document = docSnap.data()
        document.id = docSnap.id
        document = createDocumentUriAbstraction(document)
        return document
      }
      return null
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  static async loadProject(id) {
    try {
      const docSnap = await fb.apps[0].firestore().collection("projects").doc(id).get()
      if (docSnap.data()) {
        let project = docSnap.data()
        project.id = docSnap.id
        return project
      } else {
        return null
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  static async saveProject(project) {
    try {
      await fb.apps[0].firestore().collection("projects").doc(project.id).set(project)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  static async reportIssue(issue) {
    try {
      const increment = firebase.firestore.FieldValue.increment(1)
      const issueSettRef = fb.apps[0].firestore().collection("settings").doc("issues")

      await fb.apps[0].firestore().runTransaction(async (transaction) => {
        const issueSettings = (await transaction.get(issueSettRef)).data()

        transaction.set(issueSettRef, { last_issue_id: increment }, { merge: true })

        console.debug("DOC LAST ISSUE ID", issueSettings?.last_issue_id)

        issue.id = String((issueSettings?.last_issue_id || 0) + 1)
      })

      await fb.apps[0].firestore().collection("issues").doc(issue.id).set(issue, { merge: true })
    } catch (error) {
      console.error("Error reporting issue ", error)
      throw error
    }
  }

  static async deleteDocument(id) {
    try {
      await fb.apps[0].firestore().collection("accountingDocuments").doc(id).delete()
    } catch (error) {
      console.error("Error deleting document ", error)
      throw error
    }
  }

  static async onSnapshotMonthlyStatistics(setMonthlyStatistics) {
    fb.apps[0]
      .firestore()
      .collection("statisticsMonthly")
      .onSnapshot((monthlyStatsSnap) => {
        try {
          const tempStats = monthlyStatsSnap.docs.map((doc) => {
            const year = doc.id.split("_")[0]
            const month = doc.id.split("_")[1]
            return { ...doc.data(), date: new Date(year, month) }
          })
          setMonthlyStatistics(tempStats)
        } catch (error) {
          console.error(error)
          throw error
        }
      })
  }

  /**
   * @param {Number} direction  - 0/1 - specifices whether function should return incoming or outcoming docs
   * @param {Number} syncId - check if the doc is actual
   * @param {String} projectId - id of the project
   * @returns {Promise<Array>} array of filtered documents or empty array
   */
  static async loadTriviAccountingDocsByProject(direction, syncId, projectId, projStartDate, projEndDate = new Date()) {
    try {
      const accDocs = await fb.apps[0]
        .firestore()
        .collection("triviAccountingDocuments")
        .where("syncId", "==", syncId)
        .get()
      let filteredDocs = []
      accDocs.docs.forEach((doc) => {
        if (doc.data().direction === direction && doc.data()?.lineItems[0]?.project === projectId) {
          filteredDocs.push({ ...doc.data(), fbId: doc.id, internalInfo: doc.data()?.internalInfo })
        }
      })

      if (projStartDate) {
        filteredDocs = filteredDocs.filter(
          (doc) => projStartDate <= new Date(doc.issueDate) && new Date(doc.issueDate) <= projEndDate
        )
      }

      return filteredDocs
    } catch (error) {
      console.error("Error during loadAccountingDocsByProject: ", error)
      throw error
    }
  }

  static async loadTriviDocsByProject(projectId) {
    try {
      const docs = await fb.apps[0]
        .firestore()
        .collection("triviAccountingDocuments")
        .get()
      let filteredDocs = []
      docs.docs.forEach((doc) => {
        if (doc.data()?.lineItems[0]?.project === projectId) {
          filteredDocs.push({ ...doc.data(), docId: doc.id, fbId: doc.id, internalInfo: doc.data()?.internalInfo })
        }
      })

      return filteredDocs
    } catch (error) {
      console.error("Error during loadTriviDocsByProject: ", error)
      throw error
    }
  }

  static async deleteTriviDocument(triviDocumentId) {
    try {
      await fb.apps[0]
        .firestore()
        .collection("triviAccountingDocuments")
        .doc(triviDocumentId).delete()
      return true
    } catch (error) {
      console.error("Error during deleteTriviDocument: ", error)
      throw error
    }
  }

  static async updateTriviAccountingDocs(updatedDocs) {
    try {
      for (const doc of updatedDocs) {
        const docId = doc.fbId
        delete doc.fbId
        await fb.apps[0].firestore().collection("triviAccountingDocuments").doc(docId).set(doc, { merge: true })
      }
    } catch (error) {
      console.error("Error during updateTriviAccountingDocs: ", error)
      throw error
    }
  }

  /**
   * @param {Function} next - callback in order to handle new version - triggered on startup
   * @returns {Object} new release object - { versions array, changelogs array }
   */
  static async appVersionOnSnapshot(next) {
    fb.apps[0]
      .firestore()
      .collection("release")
      .onSnapshot((snap) => {
        try {
          if (Constants.manifest.extra.environment !== environmentEnum.emu) {
            let releaseDocs = snap.docs

            const sortedAndMappedRelease = releaseDocs
              .map((doc) => ({
                ...doc.data(),
                created_at: doc.data().created_at.toDate(),
                version: Number(doc.data().version),
              }))
              .sort((a, b) => b.version - a.version)
            setTimeout(() => {
              next(sortedAndMappedRelease)
            }, 90000)
          }
        } catch (error) {
          console.error("Error during appVersionOnSnapshot: ", error)
          throw error
        }
      })
  }

  /**
   * @param {Function} next - callback in order to handle new version - triggered manually by user
   * @returns {Object} new release object - { versions array, changelogs array }
   */
  static async appVersionGet(next) {
    try {
      let releaseDocs = (await fb.apps[0].firestore().collection("release").get()).docs

      const sortedAndMappedRelease = releaseDocs
        .map((doc) => ({
          ...doc.data(),
          created_at: doc.data().created_at.toDate(),
          version: Number(doc.data().version),
        }))
        .sort((a, b) => b.version - a.version)

      next(sortedAndMappedRelease)
    } catch (error) {
      console.error("Error during appVersionGet: ", error)
      throw error
    }
  }

  /**
   * @param {Function} next - callback in order to handle new version - triggered on startup
   * @returns {Object} secret for payments - { accesstoken, authorization code, software data}
   */
  static async onSnapshotApiSecret(setter, domain) {
    fb.apps[0]
      .firestore()
      .collection("settings")
      .doc(domain)
      .onSnapshot((doc) => {
        try {
          setter(doc.data() || {})
        } catch (error) {
          console.error("Error during onSnapshotApiSecret: ", error)
          throw error
        }
      })
  }

  static async updateApiSecret(data, domain) {
    try {
      await fb.apps[0].firestore().collection("settings")
        .doc(domain)
        .set(data, { merge: true })
    } catch (error) {
      console.error("Error during updateApiSecret: ", error)
      throw error
    }
  }

  /**
   * @param {Object} batchPayment - {batch_payment_id, batchPayment data}
   */
  static async createNewBatchPayment(batchPayment) {
    try {
      await fb.apps[0].firestore().collection("payment").doc(batchPayment.batch_payment_id).set(batchPayment)
      console.debug("New batchPayment request has been created: ", batchPayment)
    } catch (error) {
      console.error("Error during createNewBatchPayment: ", error)
      throw error
    }
  }

  /**
   * @param {String} batchPaymentId - used for firestore doc id and as batch payment identifier
   * @param {Function} next - callback with payment data
   */
  static async onSnapshotBatchPayment(batchPaymentId, next) {
    fb.apps[0]
      .firestore()
      .collection("payment")
      .doc(batchPaymentId)
      .onSnapshot((snap) => {
        try {
          next(snap.data())
        } catch (error) {
          console.error("Error during onSnapshotBatchPayment: ", error)
          throw error
        }
      })
  }

  /**
   * @param {Function} next - callback with secret data
   */
  static onSnapshotAccessToken(configId, next) {
    fb.apps[0]
      .firestore()
      .collection("secret")
      .doc(`banking-kb-${configId}.cexbit.com`)
      .onSnapshot((snap) => {
        try {
          next({
            ...snap.data(),
            access_token_timestamp: snap.data()?.access_token_timestamp?.toDate() || new Date(null),
          })
        } catch (error) {
          console.error("Error during onSnapshotBatchPayment: ", error)
          throw error
        }
      })
  }
  static async getUserClosingItems(userEmail) {
    const docRef = await fb.apps[0].firestore().collection("attendances").doc(userEmail).get()
    return docRef.data()?.closing_items || {}
  }

  static async getTriviDocsForNotes(projects) {
    try {
      const syncingProjects = projects.filter((proj) => proj.isSyncing)
      const projectDocsRef = await fb.apps[0].firestore().collection("triviAccountingDocuments").get()
      const docs = []

      for (let i = 0; i < syncingProjects.length; i++) {
        const docsWithNotes = projectDocsRef.docs
          .filter((doc) => doc.data().preferredProject === syncingProjects[i].id)
          .filter((doc) => doc.data()?._extracting?.notes.length > 0)

        docsWithNotes.forEach((doc) => docs.push(doc.data()))
      }
      return docs || []
    } catch (error) {
      console.error("Error while getting trivi docs used for notes: ", JSON.stringify(error))
      return []
    }
  }

  static async setPredefinedLogs(log) {
    try {
      const docRef = await fb.apps[0].firestore().collection("settings").doc("logBook").get()
      const doc = docRef?.data()
      const predefinedDocs = doc.predefined_logs || []
      if (!log.value) {
        predefinedDocs.push({ ...log, distance: Number(log.distance), value: uuid.v4() })
      } else {
        const existingDocIndex = predefinedDocs.findIndex((item) => item.value === log.value)
        predefinedDocs[existingDocIndex] = { ...log, distance: Number(log.distance) }
      }
      await fb.apps[0]
        .firestore()
        .collection("settings")
        .doc("logBook")
        .set({ predefined_logs: predefinedDocs }, { merge: true })
    } catch (error) {
      console.error("Error during setPredefinedLogs: ", error)
    }
  }

  static async getExtraCashflowDocs() {
    try {
      const docRef = await fb.apps[0].firestore().collection("extraCashflow").get()
      const docs = docRef.docs.map((doc) => {
        const obj = doc.data()
        return {
          ...obj,
          id: doc.id,
          values: obj.values.map((el) => {
            return { ...el, from: el?.from.toDate(), to: el.to ? el?.to.toDate() : undefined }
          }),
        }
      })

      return docs
    } catch (error) {
      console.error("Error during getExtraCashflowDocs: ", error)
    }
  }

  static async saveExtraCashflowDoc(newDoc) {
    try {
      await fb.apps[0].firestore().collection("extraCashflow").add(newDoc)
    } catch (error) {
      console.error("Error during saveExtraCashflowDoc: ", error)
    }
  }

  static async setExtraCashflowDoc(newDoc, merge = true) {
    try {
      await fb.apps[0].firestore().collection("extraCashflow").doc(newDoc.id).set(newDoc, { merge: merge })
    } catch (error) {
      console.error("Error during setExtraCashflowDoc: ", error)
    }
  }

  static async deleteExtraCashflowDoc(docId) {
    try {
      await fb.apps[0].firestore().collection("extraCashflow").doc(docId).delete()
    } catch (error) {
      console.error("Error during setExtraCashflowDoc: ", error)
    }
  }

  static async setCashflowInitialValue(newVal) {
    try {
      await fb.apps[0].firestore().collection("settings").doc("cashflow").set(
        {
          starting_values: newVal,
        },
        { merge: true }
      )
    } catch (error) {
      console.error("Error durring setCashflowInitialValue: ", error)
    }
  }

  static async getCashflowInitialValue() {
    try {
      const docRef = await fb.apps[0].firestore().collection("settings").doc("cashflow").get()
      const docData = docRef?.data()
      console.debug("initialValue: ", docData)
      return docData
    } catch (error) {
      console.error("Error durring setCashflowInitialValue: ", error)
    }
  }

  static async getCashflowDocs() {
    try {
      const docRef = await fb.apps[0]
        .firestore()
        .collection("cashflowDocuments")
        .where("type", "not-in", [10, 11, 12])
        .get()
      console.debug("CASHFLOW DOC LENGTH: ", docRef.docs.length)
      const docs = docRef.docs.map((doc) => {
        const d = doc.data()
        return {
          dueDate: new Date(d.accounting_info.due_date),
          direction: d.direction,
          total: d.price,
        }
      }).filter( doc => doc.processingState !== 3 )

      return docs
    } catch (error) {
      console.error("Error during getCashflowDocs: ", error)
    }
  }

  static onSnapshotExtraCashflowDocs(callback) {
    try {
      const unsub = fb.apps[0]
        .firestore()
        .collection("extraCashflow")
        .onSnapshot((snap) => {
          console.debug("EXTRA CASHFLOW DOCS SNAP: ", snap.docs.length)
          const mappedDocs = snap.docs.map((doc) => {
            const obj = doc.data()
            return {
              ...obj,
              id: doc.id,
              values: obj.values.map((el) => {
                return { ...el, from: el?.from.toDate(), to: el.to ? el?.to.toDate() : undefined }
              }),
            }
          })
          let sortedDocs = mappedDocs.sort((a, b) => a.type === "ONE_TIME" ? (a.values[0].from - b.values[0].from) : (a.dateIndex - b.dateIndex))
          let sortedDocs1 = sortedDocs.sort((a, b) => a.values[0].indefinite - b.values[0].indefinite)
          console.log("sortedDocs: " + JSON.stringify(sortedDocs1))
          callback(sortedDocs1 || [])
        })
      return unsub
    } catch (error) {
      console.error("Error occured during extraCashFlowDocsSnapshot: ", error)
    }
  }
}
