import React, { createRef, useEffect, useRef, useState } from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import HeaderActionIcon from "../HeaderActionIcon"
import {
  Registration,
  HomeScreen,
  Users,
  TasksOverview,
  TasksBulkAction,
  AttendanceBulkAction,
  ProjectsOverview,
  AccountingDocumentsOverview,
  ApprovingAccountingDocumentsOverview,
  UserDetail,
  PasswordChange,
  CaptureImage,
  VerifyScan,
  AccountingDocumentInfo,
  VerifyUploadDocument,
  ProjectDetail,
  AccountingDocumentDetail,
  ProjectPeople,
  ProjectProperty,
  ProjectPremium,
  ApprovingAccountingDocumentDetail,
  ProjectEdit,
  DownloadAndroid,
  DownloadIOS,
  InfoScreen,
  Settings,
  TriviAPICredentials,
  ChatScreen,
  ChatsOverview,
  PasswordRenewal,
  CashDeskMonths,
  CashDeskMonthDetail,
  CashDeskOverview,
  CashDeskCharge,
  ReportIssueScreen,
  AttendanceOverview,
  PropertyOverview,
  PropertyDetail,
  PropertyUserDetail,
  AttendanceMonths,
  AttendanceMonthDays,
  AttendanceDayDetail,
  PremiumOverview,
  PremiumUserProjects,
  PremiumProjectDetail,
  LogBookMonthDetail,
  LogDetail,
  LogBookOverview,
  LogBookMonths,
  StatisticsOverview,
  DocumentsOverview,
  DocumentDetail,
  DocumentsUserOverview,
  TriviSync,
  CashDeskMonthBulkAction,
  ApprovingAccountingDocumentsBulkAction,
  TransactionsOverview,
  KBBankRegisterSoftware,
  BankRecurringAutomat,
  ScansOverview,
  LogBookExport,
  BatchPayments,
  AttendanceExport,
  DiaryOverview,
  ProjectDiaryDetail,
  DiaryTitleScreen,
  DiaryMainDirectory,
  DiaryContractOverview,
  DiaryEntryDetail,
  DiaryDocumentationOverview,
  DiaryDocumentDetail,
  PreviewScan,
  SignatureScreen,
  UsersPickerScreen,
  TemplatesOverview,
  TemplateDetail,
  WeatherAPICredentials,
  CompanyCatalogOverview,
  CompanyCatalogDetail,
  DiaryExport,
  DiaryBulkSignature,
  TriviDocsNotes,
  TriviDocNotesDetail,
  PredefinedLogs,
  PredefinedLogDetail,
  CashflowOverview,
  ExtraCashflowOverview,
  ExtraCashflowDetail,
  CashflowInitialValue,
} from "../../screens"
import { useNavigation } from "@react-navigation/native"
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import { View, StyleSheet, Text, Platform } from "react-native"
import { useStatus } from "../../providers/StatusProvider"
import Notification from "../Notification"
import FSStoreContent from "../../firebase/FSStoreContent"
import * as Linking from "expo-linking"
import {
  actionEnum,
  documentCategoryEnum,
  scanTypeEnum,
  documentTypeEnum,
  documentViewTypeEnum,
  propertyTypeEnum,
  scansOverviewViewTypeEnum,
  userStateEnum,
  usersViewTypeEnum,
  viewTypeEnum,
  userRoleEnum,
} from "../../data/enums"
import { useData } from "../../providers/DataProvider"
import {
  isAccountant,
  isAdmin,
  isExecutive,
  isOfficeManager,
  isProjectManager,
  isTechnicalDirector,
} from "../../utils/validation"
import AppReportIssueComponent from "../AppReportIssueComponent"
import ProjectDetailHeaderMenu from "../headerMenus/ProjectDetailHeaderMenu"
import AttendanceDaysHeaderMenu from "../headerMenus/AttendanceDaysHeaderMenu"
import { getProjectName, getUserName } from "../../utils/general"
import { monthNameLabels, roleLabels } from "../../data/labels"
import PropertyOverviewHeaderMenu from "../headerMenus/PropertyOverviewHeaderMenu"
import CashDeskMonthDetailHeaderMenu from "../headerMenus/CashDeskMonthDetailHeaderMenu"
import TasksOverviewHeaderMenu from "../headerMenus/TasksOverviewHeaderMenu"
import { VIEW_PARAMS_HEADER_LEFT } from "../../data/params"
import LogBookMonthDetailHeaderMenu from "../headerMenus/LogBookMonthDetailHeaderMenu"
import ProjectsOverviewHeaderMenu from "../headerMenus/ProjectsOverviewHeaderMenu"
import AppReportIssueComponentWeb from "../AppReportIssueComponentWeb"
import DashboardScreen from "../../screens/DashboardScreen"
import ApprovingAccDocsOverviewHeaderMenu from "../headerMenus/ApprovingAccDocsOverviewHeaderMenu"
import LogBookOverviewHeaderMenu from "../headerMenus/LogBookOverviewHeaderMenu"
import UserOverviewHeaderMenu from "../headerMenus/UserOverviewHeaderMenu"
import AwesomeUpdateAlert from "../AwesomeUpdateAlert"
import TransactionsHeaderMenu from "../headerMenus/TransactionsHeaderMenu"
import AttendanceOverviewHeaderMenu from "../headerMenus/AttendanceOverviewHeaderMenu"
import ProjectDiaryDetailHeaderMenu from "../headerMenus/ProjectDiaryDetailHeaderMenu"
import DiaryDocumentOverview from "../../screens/Diary/DiaryDocumentOverview"
import DiaryEntryDetailHeaderMenu from "../headerMenus/DiaryEntryDetailHeaderMenu"
import FSContactCompanies from "../../firebase/FSContactCompanies"
import DashboardHeaderMenu from "../headerMenus/DashboardHeaderMenu"
import AccountingDocumentsHeaderMenu from "../headerMenus/AccountingDocumentsHeaderMenu"
import ContactScreen from "../../screens/User/ContactScreen"
import TriviScreen from "../../screens/Trivi/TriviScreen"
import RBTransactionList from "../../screens/Bank/RBTransactionList"
import TransactionListHeaderMenu from "../headerMenus/TransactionListHeaderMenu"
import RBBankRegister from "../../screens/Settings/RBBankRegister"
import RBBatchPaymentList from "../../screens/Bank/RBBatchPaymentList"

export default function StackNavigator(props) {
  const Stack = createNativeStackNavigator()
  const navigation = useNavigation()

  const imageRef = createRef(null)

  const { setStatus, statusEnum, setTitle, setAction, setOnConfirm, setToScreen } = useStatus()
  const { currentUser, users, projects } = useData()

  return (
    <View style={{ flex: 1 }} ref={imageRef}>
      <Notification navigation={navigation} />
      {/* TEMPORARY REPORTING START */}
      {Platform.OS === "web" ? (
        <AppReportIssueComponentWeb navigation={navigation} imageRef={imageRef} />
      ) : (
        <AppReportIssueComponent navigation={navigation} />
      )}
      {/* TEMPORARY REPORTING END */}
      <Stack.Navigator
        initialRouteName={props.initialScreen}
        screenOptions={{ headerStatusBarHeight: 0 }} // TEMPORARY REPORTING with the reporting header
      >
        <Stack.Screen
          name="CashDeskMonthDetail"
          component={CashDeskMonthDetail}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: route.params?.monthTitle,
            headerRight: () => <CashDeskMonthDetailHeaderMenu route={route} navigation={navigation} />,
          })}
        />
        <Stack.Screen
          name="CashDeskMonthBulkAction"
          component={CashDeskMonthBulkAction}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: route.params?.monthTitle,
          })}
        />
        <Stack.Screen
          name="CashDeskCharge"
          component={CashDeskCharge}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `Dotace ${route.params.user.email} ${route.params.monthTitle}`,
          })}
        />
        <Stack.Screen
          name="PremiumOverview"
          component={PremiumOverview}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: "Přehled prémií",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
          })}
        />
        <Stack.Screen
          name="PremiumUserProjects"
          component={PremiumUserProjects}
          options={({ route }) => {
            if (!route.params?.title) {
              return {
                ...DefaultTheme.headerStyle,
                title: "Moje prémie",
                headerLeft: () => (
                  <HeaderActionIcon
                    onPress={() => navigation.openDrawer()}
                    icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
                  />
                ),
              }
            } else {
              return {
                ...DefaultTheme.headerStyle,
                title: `Prémie ${route.params?.user?.name || ""}`,
              }
            }
          }}
        />
        <Stack.Screen
          name="PremiumProjectDetail"
          component={PremiumProjectDetail}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `Prémie [${route.params?.project?.id || ""}] ${route.params?.user?.name || ""}`,
          })}
        />
        <Stack.Screen
          name="AttendanceOverview"
          component={AttendanceOverview}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: "Přehled docházky",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
            headerRight: () => <AttendanceOverviewHeaderMenu />,
          })}
        />
        <Stack.Screen
          name="AttendanceMonths"
          component={AttendanceMonths}
          options={({ route }) => {
            if (!route.params?.title) {
              return {
                ...DefaultTheme.headerStyle,
                title: "Moje docházka",
                headerLeft: () => (
                  <HeaderActionIcon
                    onPress={() => navigation.openDrawer()}
                    icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
                  />
                ),
              }
            } else {
              return {
                ...DefaultTheme.headerStyle,
                title: route.params.title,
              }
            }
          }}
        />
        <Stack.Screen
          name="AttendanceMonthDays"
          component={AttendanceMonthDays}
          options={({ route }) => {
            const userName = users.find((u) => u.email === route.params?.userEmail)?.name || "meno"
            const monthTitle = route.params?.monthTitle || "Detail měsíce"
            const freeDaysCount = route.params?.freeDaysCount || 0

            return {
              ...DefaultTheme.headerStyle,
              title: `${monthTitle} ${userName}`,
              headerRight: () => (
                <AttendanceDaysHeaderMenu
                  params={route.params}
                  navigation={navigation}
                  title={`${monthTitle} ${userName}`}
                  freeDaysCount={freeDaysCount}
                />
              ),
            }
          }}
        />
        <Stack.Screen
          name="AttendanceDayDetail"
          component={AttendanceDayDetail}
          options={({ route }) => {
            const userName = users.find((u) => u.email === route.params?.userEmail)?.name || "user name"
            const dateTitle = route.params?.dateTitle || "Detail dne"
            return {
              ...DefaultTheme.headerStyle,
              title: `${dateTitle} ${userName}`,
            }
          }}
        />
        <Stack.Screen
          name="AttendanceBulkAction"
          component={AttendanceBulkAction}
          options={({ route }) => ({
            title: `Hromadné akce ${route.params.title}`,
          })}
        />
        <Stack.Screen
          name="LogBookOverview"
          component={LogBookOverview}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: "Přehled knihy jízd",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
            headerRight: () => <LogBookOverviewHeaderMenu />,
          })}
        />
        <Stack.Screen
          name="LogBookMonths"
          component={LogBookMonths}
          options={({ route }) => {
            if (!route.params?.title) {
              return {
                ...DefaultTheme.headerStyle,
                title: "Moje kniha jízd",
                headerLeft: () => (
                  <HeaderActionIcon
                    onPress={() => navigation.openDrawer()}
                    icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
                  />
                ),
              }
            } else {
              return {
                ...DefaultTheme.headerStyle,
                title: `Kniha jízd ${getUserName(users, route.params?.user.email) || ""}`,
              }
            }
          }}
        />
        <Stack.Screen
          name="LogBookMonthDetail"
          component={LogBookMonthDetail}
          options={({ route }) => {
            if (isAdmin(currentUser) || isExecutive(currentUser) || isAccountant(currentUser)) {
              return {
                ...DefaultTheme.headerStyle,
                title: `Jízdy ${monthNameLabels[route.params.monthLogBook.id.split("_")[1]]} ${getUserName(
                  users,
                  route.params.monthLogBook.user
                )}`,
                headerRight: () => <LogBookMonthDetailHeaderMenu params={route.params} navigation={navigation} />,
              }
            } else {
              return {
                ...DefaultTheme.headerStyle,
                title: `Jízdy ${monthNameLabels[route.params.monthLogBook.id.split("_")[1]]} ${getUserName(
                  users,
                  route.params.monthLogBook.user
                )}`,
              }
            }
          }}
        />
        <Stack.Screen
          name="LogDetail"
          component={LogDetail}
          options={({ route }) => {
            if (route.params?.log) {
              return {
                ...DefaultTheme.headerStyle,
                title: `Jízda ${route.params.vehicle?.licence_plate_editable || route.params.vehicle?.licence_plate}`,
                headerRight: () => (
                  <HeaderActionIcon
                    onPress={() => {
                      setTitle("Opravdu chcete smazat jízdu?")
                      setStatus(statusEnum.PROGRESS_DECIDE)
                      setAction({
                        type: actionEnum.DELETE_LOG_BOOK_ITEM,
                        params: {
                          logItemId: route.params.log.id,
                          monthLogBook: route.params.monthLogBook,
                          toScreen: { screen: "LogBookMonthDetail", params: route.params },
                        },
                      })
                      navigation.navigate("StatusScreen")
                    }}
                    disabled={route.params?.disabled}
                    icon={
                      <FontAwesome
                        name="trash"
                        size={DefaultTheme.icons.headerSizeRight}
                        color={route.params?.disabled ? DefaultTheme.colors.disabled : DefaultTheme.colors.danger}
                      />
                    }
                  />
                ),
              }
            } else {
              return {
                ...DefaultTheme.headerStyle,
                title: `Nová jízda ${monthNameLabels[route.params?.monthLogBook?.id.split("_")[1]]} ${getUserName(
                  users,
                  route.params?.monthLogBook?.user
                )}`,
              }
            }
          }}
        />
        <Stack.Screen
          name="LogBookExport"
          component={LogBookExport}
          options={{
            title: "Export knihy jízd",
          }}
        />
        <Stack.Screen
          name="AttendanceExport"
          component={AttendanceExport}
          options={{
            title: "Export docházky",
          }}
        />
        <Stack.Screen
          name="PropertyUserDetail"
          component={PropertyUserDetail}
          options={({ route }) => {
            if (!route.params?.title) {
              return {
                ...DefaultTheme.headerStyle,
                title: "Můj majetek",
                headerLeft: () => (
                  <HeaderActionIcon
                    onPress={() => navigation.openDrawer()}
                    icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
                  />
                ),
              }
            } else {
              return {
                ...DefaultTheme.headerStyle,
                title: `Majetek ${getUserName(users, route.params?.user.email) || ""}`,
              }
            }
          }}
        />
        <Stack.Screen
          name="PropertyOverview"
          component={PropertyOverview}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: "Přehled evidence majetku",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
            headerRight: () => <PropertyOverviewHeaderMenu params={route.params} navigation={navigation} />,
          })}
        />
        <Stack.Screen
          name="PropertyDetail"
          component={PropertyDetail}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: route.params?.prop ? `Detail majetku ${route.params.prop.name || ""}` : "Nový majetek",
            headerRight: () =>
              (isAdmin(currentUser) ||
                isExecutive(currentUser) ||
                isAccountant(currentUser) ||
                isOfficeManager(currentUser)) && (
                <HeaderActionIcon
                  onPress={() => {
                    const propertyItem = route.params?.prop
                    setTitle(`Doopravdy chcete smazat majetek ${propertyItem.name}?`)
                    setStatus(statusEnum.PROGRESS_DECIDE)
                    setAction({
                      type: actionEnum.DELETE_PROPERTY_ITEM,
                      params: { id: propertyItem.id, toScreen: route.params.toScreen },
                    })
                    navigation.navigate("StatusScreen")
                  }}
                  icon={
                    <FontAwesome
                      name="trash"
                      size={DefaultTheme.icons.headerSizeRight}
                      color={DefaultTheme.colors.danger}
                    />
                  }
                />
              ),
          })}
        />
        <Stack.Screen
          name="ReportIssueScreen"
          component={ReportIssueScreen}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: "Nahlásit",
          })}
        />
        <Stack.Screen
          name="CashDeskOverview"
          component={CashDeskOverview}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: "Přehled pokladen",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
          })}
        />
        <Stack.Screen
          name="CashDeskMonths"
          component={CashDeskMonths}
          options={({ route }) => {
            if (!route.params?.title) {
              return {
                ...DefaultTheme.headerStyle,
                title: "Moje pokladna",
                headerLeft: () => (
                  <HeaderActionIcon
                    onPress={() => navigation.openDrawer()}
                    icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
                  />
                ),
              }
            } else {
              return {
                ...DefaultTheme.headerStyle,
                title: route.params.title,
              }
            }
          }}
        />
        <Stack.Screen
          name="ChatScreen"
          component={ChatScreen}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `${route.params.name || ""}`,
          })}
        />
        <Stack.Screen
          name="ChatsOverview"
          component={ChatsOverview}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Zprávy",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
          }}
        />
        <Stack.Screen
          name="TriviAPICredentials"
          component={TriviAPICredentials}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Trivi API Credentials",
          }}
        />
        <Stack.Screen
          name="TriviSync"
          component={TriviSync}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Trivi synchronizace",
          }}
        />
        <Stack.Screen
          name="Settings"
          component={Settings}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Nastavení",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
          }}
        />
        <Stack.Screen
          name="InfoScreen"
          component={InfoScreen}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Informace",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
          }}
        />
        <Stack.Screen
          name="DownloadAndroid"
          component={DownloadAndroid}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Ke stažení Android",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
          }}
        />
        <Stack.Screen
          name="DownloadIOS"
          component={DownloadIOS}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Ke stažení IOS",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
          }}
        />
        <Stack.Screen
          name="ApprovingAccountingDocumentDetail"
          component={ApprovingAccountingDocumentDetail}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `Schválit doklad ${route.params?.document?.name || ""}`,
            // title: `${route.params.document.project ?? "Schválit doklad "} | ${route.params.document.name ?? ""}`,
          })}
        />
        <Stack.Screen
          name="ProjectPeople"
          component={ProjectPeople}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `Pracující na [${route.params.project.id} - ${route.params.project.name}]`,
          })}
        />
        <Stack.Screen
          name="ProjectProperty"
          component={ProjectProperty}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `Majetek na [${route.params.project.id} - ${route.params.project.name}]`,
          })}
        />
        <Stack.Screen
          name="ProjectPremium"
          component={ProjectPremium}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `Prémie [${route.params.project.id} - ${route.params.project.name}]`,
          })}
        />
        <Stack.Screen
          name="AccountingDocumentDetail"
          component={AccountingDocumentDetail}
          options={({ route }) => {
            const document = route.params.document
            return {
              ...DefaultTheme.headerStyle,
              title: `${
                document.name
                  ? document.name
                  : document.category === documentCategoryEnum.UBYTOVANI
                  ? "Ubytovací doklad"
                  : "Detail dokladu"
              }`,
              headerRight: () => (
                <View style={{ flexDirection: "row" }}>
                  {(isAdmin(currentUser) || isExecutive(currentUser)) && (
                    <HeaderActionIcon
                      onPress={async () => {
                        setTitle(
                          `Chcete označit doklad jako ${
                            document.hasOwnProperty("exec_labeled") && document.exec_labeled ? "nezaplacen" : "zaplacen"
                          }?`
                        )
                        setStatus(statusEnum.PROGRESS_DECIDE)
                        setAction({
                          type: actionEnum.EXEC_LABEL,
                          params: {
                            document,
                            toScreen: "AccountingDocumentsOverview",
                          },
                        })
                        navigation.navigate("StatusScreen")
                      }}
                      icon={
                        <MaterialCommunityIcons
                          name="check"
                          color={DefaultTheme.colors.primary}
                          size={DefaultTheme.icons.headerSizeRight}
                        />
                      }
                    />
                  )}
                  {document.filestore_uri_list?.length > 0 && (
                    <HeaderActionIcon
                      onPress={() => {
                        let offset = 0
                        for (const uri of document.filestore_uri_list) {
                          setTimeout(() => {
                            Linking.openURL(uri)
                          }, offset)
                          offset += 500
                        }
                      }}
                      icon={
                        <MaterialCommunityIcons
                          name="download"
                          color={DefaultTheme.colors.primary}
                          size={DefaultTheme.icons.headerSizeRight}
                        />
                      }
                    />
                  )}

                  {document.uploaded_to_trivi_at && (isAdmin(currentUser) || isExecutive(currentUser)) && (
                    <HeaderActionIcon
                      onPress={() => {
                        setTitle(`Opravdu chcete tento doklad opět zobrazit v hromadných platbách?`)
                        setStatus(statusEnum.PROGRESS_DECIDE)
                        setAction({
                          type: actionEnum.PAY_AGAIN,
                          params: { document, toScreen: route.params.toScreen },
                        })
                        navigation.navigate("StatusScreen")
                      }}
                      icon={
                        <MaterialCommunityIcons
                          name="credit-card-refund"
                          color={DefaultTheme.colors.primary}
                          size={DefaultTheme.icons.headerSizeRight}
                        />
                      }
                    />
                  )}
                  <HeaderActionIcon
                    onPress={() => {
                      setTitle(
                        `Opravdu chcete smazat doklad ${document.name || (documentViewTypeEnum.CASH_DESK ? "?" : "")}?`
                      )
                      setStatus(statusEnum.PROGRESS_DECIDE)
                      setAction({
                        type: actionEnum.DELETE_ACCOUNTING_DOCUMENT,
                        params: { document, toScreen: route.params.toScreen },
                      })
                      navigation.navigate("StatusScreen")
                    }}
                    disabled={document.locked}
                    icon={
                      <FontAwesome
                        name="trash"
                        size={DefaultTheme.icons.headerSizeRight}
                        color={document.locked ? DefaultTheme.colors.disabled : DefaultTheme.colors.danger}
                      />
                    }
                  />
                </View>
              ),
            }
          }}
        />
        <Stack.Screen
          name="Home"
          component={HomeScreen}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Domu",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
          }}
        />
        <Stack.Screen
          name="Users"
          component={Users}
          options={({ route }) => {
            const requiredProps =
              route.params?.viewType === usersViewTypeEnum.COMPANY
                ? {
                    headerLeft: () => (
                      <HeaderActionIcon
                        onPress={() => navigation.openDrawer()}
                        icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
                      />
                    ),
                  }
                : {
                    headerRight: () => <UserOverviewHeaderMenu navigation={navigation} />,
                  }
            const title = route.params?.viewType === usersViewTypeEnum.COMPANY ? "Adresář firmy" : "Uživatelé"

            return {
              ...DefaultTheme.headerStyle,
              ...requiredProps,
              title,
            }
          }}
        />
        <Stack.Screen
          name="TasksOverview"
          component={TasksOverview}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: "Úkoly",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
            headerRight: () => <TasksOverviewHeaderMenu navigation={navigation} />,
          })}
        />
        <Stack.Screen
          name="TasksBulkAction"
          component={TasksBulkAction}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Hromadné akce nad úkoly",
          }}
        />
        <Stack.Screen
          name="ProjectsOverview"
          component={ProjectsOverview}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Přehled projektů",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
            headerRight: () => <ProjectsOverviewHeaderMenu navigation={navigation} />,
          }}
        />
        <Stack.Screen
          name="AccountingDocumentsOverview"
          component={AccountingDocumentsOverview}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Přehled dokladů",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
            headerRight: () =>
              (currentUser.roles.includes(userRoleEnum.EXECUTIVE) ||
                currentUser.roles.includes(userRoleEnum.ADMIN)) && (
                <AccountingDocumentsHeaderMenu navigation={navigation} />
              ),
          }}
        />
        <Stack.Screen
          name="ScansOverview"
          component={ScansOverview}
          options={({ route }) => {
            const isCompanyDoc = route.params?.viewType === documentViewTypeEnum.COMPANY
            const isChangeDoc = route.params?.viewType === scansOverviewViewTypeEnum.DOC_CHANGE
            const isContract = route.params?.docType === documentTypeEnum.CONTRACT
            const isDocumentation = route.params?.docType === documentTypeEnum.DOCUMENTATION

            return {
              ...DefaultTheme.headerStyle,
              title: isCompanyDoc
                ? "Nový dokument"
                : isChangeDoc
                ? `Nové skeny ${isContract ? "smlouvy" : isDocumentation ? "dokumentace" : "dokladu"}`
                : `Skeny ${isContract ? "smlouvy" : isDocumentation ? "dokumentace" : "dokladu"}`,
            }
          }}
        />
        <Stack.Screen
          name="VerifyScan"
          component={VerifyScan}
          options={({ route }) => {
            const isContract = route.params?.docType === documentTypeEnum.CONTRACT
            const isDocumentation = route.params?.docType === documentTypeEnum.DOCUMENTATION

            return {
              ...DefaultTheme.headerStyle,
              title:
                route.params.viewType === documentViewTypeEnum.COMPANY
                  ? route.params.type === scanTypeEnum.FILE
                    ? "Nový dokument"
                    : "Je dokument čitelný? "
                  : `Je ${
                      isContract ? "smlouva čitelná" : isDocumentation ? "dokumentace čitelná" : "doklad čitelný"
                    }?`,
            }
          }}
        />
        <Stack.Screen
          name="PreviewScan"
          component={PreviewScan}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: route.params.title || "Detail skenu",
          })}
        />
        <Stack.Screen
          name="VerifyUploadDocument"
          component={VerifyUploadDocument}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Je vše správně?",
          }}
        />
        <Stack.Screen
          name="CaptureImage"
          component={CaptureImage}
          options={({ route }) => {
            const isContract = route.params?.docType === documentTypeEnum.CONTRACT
            const isDocumentation = route.params?.docType === documentTypeEnum.DOCUMENTATION

            return {
              ...DefaultTheme.headerStyle,
              title:
                route.params?.viewType === documentViewTypeEnum.COMPANY
                  ? "Nahrát dokument"
                  : `Nahrát ${isContract ? "smlouvu" : isDocumentation ? "dokumentaci" : "sken"}`,
            }
          }}
        />
        <Stack.Screen
          name="AccountingDocumentInfo"
          component={AccountingDocumentInfo}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Info o dokladu",
          }}
        />
        <Stack.Screen
          name="ProjectDetail"
          component={ProjectDetail}
          options={({ route, navigation }) => ({
            ...DefaultTheme.headerStyle,
            title: `Projekt [${route.params.project.id} - ${route.params.project.name}]`,
            headerRight: () => <ProjectDetailHeaderMenu params={route.params} navigation={navigation} />,
          })}
        />
        <Stack.Screen
          name="ProjectEdit"
          component={ProjectEdit}
          options={({ route, navigation }) => ({
            ...DefaultTheme.headerStyle,
            title: `Úprava [${route.params.project.id} - ${route.params.project.name}]`,
          })}
        />
        <Stack.Screen
          name="ApprovingAccountingDocumentsOverview"
          component={ApprovingAccountingDocumentsOverview}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Přehled schvalování",
            headerLeft: () => (
              <HeaderActionIcon
                navigation={navigation}
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
            headerRight: () => <ApprovingAccDocsOverviewHeaderMenu navigation={navigation} />,
          }}
        />
        <Stack.Screen
          name="ApprovingAccountingDocumentsBulkAction"
          component={ApprovingAccountingDocumentsBulkAction}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Hromadné schvalování",
          }}
        />
        <Stack.Screen
          name="UserDetail"
          component={UserDetail}
          options={({ route }) => {
            const user = route.params?.user || {}
            const isCompanyView = route.params?.viewType === usersViewTypeEnum.COMPANY

            const requiredProps = isCompanyView
              ? {}
              : {
                  headerRight: () =>
                    user?.state !== userStateEnum.DELETED && (
                      <HeaderActionIcon
                        disabled={user.email === currentUser.email}
                        onPress={() => {
                          setTitle(`Doopravdy chcete smazat uživatele  ${user.name}?`)
                          setStatus(statusEnum.PROGRESS_DECIDE)
                          setAction({
                            type: actionEnum.DELETE_USER,
                            params: { user, toScreen: "Users" },
                          })
                          navigation.navigate("StatusScreen")
                        }}
                        icon={
                          <FontAwesome
                            name="trash"
                            size={DefaultTheme.icons.headerSizeRight}
                            color={
                              user.email === currentUser.email
                                ? DefaultTheme.colors.disabled
                                : DefaultTheme.colors.danger
                            }
                          />
                        }
                      />
                    ),
                }

            return {
              ...DefaultTheme.headerStyle,
              ...requiredProps,
              title: route.params
                ? user.state === userStateEnum.DELETED
                  ? "Obnovit uživatele"
                  : `${user.name}`
                : "Vytvořit nového uživatele",
            }
          }}
        />
        <Stack.Screen
          name="PasswordChange"
          component={PasswordChange}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Změna hesla",
          }}
        />
        <Stack.Screen
          name="ForgottenPassword"
          component={PasswordRenewal}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Obnova hesla",
          }}
        />
        <Stack.Screen
          name="Registration"
          component={Registration}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Registrace",
          }}
        />
        <Stack.Screen
          name="StatisticsOverview"
          component={StatisticsOverview}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Statistika firmy",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
          }}
        />
        <Stack.Screen
          name="DocumentsOverview"
          component={DocumentsOverview}
          options={({ route }) => {
            if (route.params?.viewType === documentViewTypeEnum.COMPANY) {
              return {
                ...DefaultTheme.headerStyle,
                title: "Firemní dokumenty",
                headerLeft: () => (
                  <HeaderActionIcon
                    onPress={() => navigation.openDrawer()}
                    icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
                  />
                ),
              }
            } else if (route.params?.viewType === documentViewTypeEnum.USER) {
              if (!route.params?.title) {
                return {
                  ...DefaultTheme.headerStyle,
                  title: "Moje dokumenty",
                  headerLeft: () => (
                    <HeaderActionIcon
                      onPress={() => navigation.openDrawer()}
                      icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
                    />
                  ),
                }
              }
              return {
                ...DefaultTheme.headerStyle,
                title: route.params.title,
              }
            }
          }}
        />
        <Stack.Screen
          name="DocumentsUserOverview"
          component={DocumentsUserOverview}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Přehled dokumentů uživatelů",
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
          }}
        />
        <Stack.Screen
          name="DocumentDetail"
          component={DocumentDetail}
          options={({ route }) => {
            const document = route.params.document
            if (isExecutive(currentUser) || isAdmin(currentUser)) {
              return {
                ...DefaultTheme.headerStyle,
                title: route.params.document.name,
                headerRight: () => (
                  <View style={{ flexDirection: "row" }}>
                    {document.filestore_uri_list?.length > 0 && (
                      <HeaderActionIcon
                        onPress={() => {
                          let offset = 0
                          for (const uri of document.filestore_uri_list) {
                            setTimeout(() => {
                              Linking.openURL(uri)
                            }, offset)
                            offset += 500
                          }
                        }}
                        icon={
                          <MaterialCommunityIcons
                            name="download"
                            color={DefaultTheme.colors.primary}
                            size={DefaultTheme.icons.headerSizeRight}
                          />
                        }
                      />
                    )}
                    <HeaderActionIcon
                      onPress={() => {
                        setTitle(`Doopravdy chcete smazat dokument  ${document.name}?`)
                        setStatus(statusEnum.PROGRESS_DECIDE)
                        setAction({
                          type: actionEnum.DELETE_DOCUMENT,
                          params: { id: document.id, toScreen: "DocumentsOverview" },
                        })
                        navigation.navigate("StatusScreen")
                      }}
                      icon={
                        <FontAwesome
                          name="trash"
                          size={DefaultTheme.icons.headerSizeRight}
                          color={document.locked ? DefaultTheme.colors.disabled : DefaultTheme.colors.danger}
                        />
                      }
                    />
                  </View>
                ),
              }
            } else {
              return {
                ...DefaultTheme.headerStyle,
                title: route.params.document.name,
              }
            }
          }}
        />
        <Stack.Screen
          name="DashboardScreen"
          component={DashboardScreen}
          options={() => {
            const today = new Date()
            return {
              ...DefaultTheme.headerStyle,
              title: `Přehled ${monthNameLabels[today.getMonth()]} ${today.getFullYear()}`,
              headerLeft: () => (
                <HeaderActionIcon
                  onPress={() => navigation.openDrawer()}
                  icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
                />
              ),
              headerRight: () => (isExecutive(currentUser) || isAdmin(currentUser)) && <DashboardHeaderMenu />,
            }
          }}
        />
        <Stack.Screen
          name="TransactionsOverview"
          component={TransactionsOverview}
          options={() => ({
            ...DefaultTheme.headerStyle,
            title: `Přehled transakcí`,
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
            headerRight: () => <TransactionsHeaderMenu />,
          })}
        />
        <Stack.Screen
          name="BatchPayments"
          component={BatchPayments}
          options={() => ({
            ...DefaultTheme.headerStyle,
            title: `Dávkové platby`,
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
            headerRight: () => <TransactionsHeaderMenu />,
          })}
        />
        <Stack.Screen
          name="KBBankRegisterSoftware"
          component={KBBankRegisterSoftware}
          options={() => ({
            ...DefaultTheme.headerStyle,
            title: `KB banka API Credentials`,
          })}
        />
        <Stack.Screen
          name="BankRecurringAutomat"
          component={BankRecurringAutomat}
          options={() => ({
            ...DefaultTheme.headerStyle,
            title: `KB platební automat`,
          })}
        />

        <Stack.Screen
          name="RegisterBankClient"
          component={RBBankRegister}
          options={() => ({
            ...DefaultTheme.headerStyle,
            title: `Register Bank Client`,
          })}
        />
        <Stack.Screen
          name="BatchPaymentList"
          component={RBBatchPaymentList}
          options={() => ({
            ...DefaultTheme.headerStyle,
            title: `Dávkové platby RB`,
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
            headerRight: () => <TransactionListHeaderMenu />,
          })}
        />
        <Stack.Screen
          name="TransactionList"
          component={RBTransactionList}
          options={() => ({
            ...DefaultTheme.headerStyle,
            title: `Přehled transakcí v RB`,
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
            headerRight: () => <TransactionListHeaderMenu />,
          })}
        />

        <Stack.Screen
          name="DiaryOverview"
          component={DiaryOverview}
          options={() => ({
            ...DefaultTheme.headerStyle,
            title: `Stavební deník`,
            headerLeft: () => (
              <HeaderActionIcon
                onPress={() => navigation.openDrawer()}
                icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
              />
            ),
          })}
        />
        <Stack.Screen
          name="ProjectDiaryDetail"
          component={ProjectDiaryDetail}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `Deník ${getProjectName(projects, route.params?.projectId)}`,
            headerRight: () => <ProjectDiaryDetailHeaderMenu params={{ ...route.params, currentUser: currentUser }} />,
          })}
        />
        <Stack.Screen
          name="DiaryTitleScreen"
          component={DiaryTitleScreen}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `Titulní stránka ${getProjectName(projects, route.params?.projectId)}`,
          })}
        />
        <Stack.Screen
          name="DiaryMainDirectory"
          component={DiaryMainDirectory}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `Základní adresář ${getProjectName(projects, route.params?.projectId)}`,
          })}
        />
        <Stack.Screen
          name="DiaryDocumentOverview"
          component={DiaryDocumentOverview}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `${
              route.params?.viewType === documentTypeEnum.CONTRACT ? "Smlouvy" : "Dokumentace"
            } ${getProjectName(projects, route.params?.projectId)}`,
          })}
        />
        <Stack.Screen
          name="DiaryEntryDetail"
          component={DiaryEntryDetail}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `${route.params?.diaryEntryId ? "Zápis" : "Nový zápis"} ${getProjectName(
              projects,
              route.params?.projectId
            )}`,
            headerRight: () => <DiaryEntryDetailHeaderMenu params={route.params} />,
          })}
        />
        <Stack.Screen
          name="DiaryDocumentDetail"
          component={DiaryDocumentDetail}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title:
              route.params?.diaryDocument?.subject ||
              `Nová ${
                route.params?.viewType === documentTypeEnum.CONTRACT ? "smlouva" : "dokumentace"
              } ${getProjectName(projects, route.params.projectId)}`,
          })}
        />
        <Stack.Screen
          name="SignatureScreen"
          component={SignatureScreen}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `Podpis ${route.params?.title || ""}`,
          })}
        />
        <Stack.Screen
          name="UsersPickerScreen"
          component={UsersPickerScreen}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `${route.params?.title || "Vyberte uživatele"}`,
          })}
        />
        <Stack.Screen
          name="TemplatesOverview"
          component={TemplatesOverview}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `${route.params?.screenTitle || "Přehled šablon"}`,
          })}
        />
        <Stack.Screen
          name="TemplateDetail"
          component={TemplateDetail}
          options={({ route }) => ({
            ...DefaultTheme.headerStyle,
            title: `${route.params?.template?.title || "Nová šablona"}`,
            headerRight: () => (
              <HeaderActionIcon
                onPress={() => {
                  setTitle("Opravdu chcete smazat šablonu??")
                  setStatus(statusEnum.PROGRESS_DECIDE)
                  setAction({
                    type: actionEnum.DELETE_TEMPLATE,
                    params: { ...route.params, toScreen: { screen: "TemplatesOverview", params: route.params } },
                  })
                  navigation.navigate("StatusScreen")
                }}
                icon={
                  <FontAwesome
                    name="trash"
                    size={DefaultTheme.icons.headerSizeRight}
                    color={route.params?.disabled ? DefaultTheme.colors.disabled : DefaultTheme.colors.danger}
                  />
                }
              />
            ),
          })}
        />
        <Stack.Screen
          name="WeatherAPICredentials"
          component={WeatherAPICredentials}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Počasí API Credentials",
          }}
        />
        <Stack.Screen
          name="CompanyCatalogOverview"
          component={CompanyCatalogOverview}
          options={({ route }) => {
            const viewType = route.params?.viewType
            const requiredProps =
              viewType === viewTypeEnum.PICK
                ? {}
                : {
                    headerLeft: () => (
                      <HeaderActionIcon
                        onPress={() => navigation.openDrawer()}
                        icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
                      />
                    ),
                  }

            return { ...DefaultTheme.headerStyle, ...requiredProps, title: "Katalog firem" }
          }}
        />
        <Stack.Screen
          name="CompanyCatalogDetail"
          component={CompanyCatalogDetail}
          options={({ route }) => {
            const handleDelete = () => {
              setTitle(`Opravdu chcete smazat firmu ${route.params.title || ""}?`)
              setStatus(statusEnum.PROGRESS_DECIDE_SIMPLE)
              navigation.navigate("StatusScreen")
              setOnConfirm(() => async () => {
                try {
                  setStatus(statusEnum.PROGRESS_UNDETERMINED)

                  await FSContactCompanies.del(route.params?.companyId)

                  setStatus(statusEnum.SUCCESS)
                  setToScreen("CompanyCatalogOverview")
                } catch (error) {
                  console.error(error)
                  setStatus(statusEnum.ERROR)
                  setMessage(error.message)
                  navigation.navigate("StatusScreen")
                }
              })
            }

            const requiredProps = route.params?.title
              ? {
                  headerRight: () => (
                    <HeaderActionIcon
                      onPress={handleDelete}
                      icon={
                        <FontAwesome
                          name="trash"
                          size={DefaultTheme.icons.headerSizeRight}
                          color={route.params?.disabled ? DefaultTheme.colors.disabled : DefaultTheme.colors.danger}
                        />
                      }
                    />
                  ),
                }
              : {}

            return {
              ...DefaultTheme.headerStyle,
              ...requiredProps,
              title: route.params?.title || "Nová firma",
            }
          }}
        />
        <Stack.Screen
          name="DiaryExport"
          component={DiaryExport}
          options={{
            title: "Export deníku",
          }}
        />
        <Stack.Screen
          name="DiaryBulkSignature"
          component={DiaryBulkSignature}
          options={{
            title: "Hromadný podpis",
          }}
        />
        <Stack.Screen
          name="TriviDocsNotes"
          component={TriviDocsNotes}
          options={{
            title: "Poznámky Trivi dokumentů",
          }}
        />
        <Stack.Screen
          name="TriviDocNotesDetail"
          component={TriviDocNotesDetail}
          options={({ route }) => {
            return {
              title: `Poznámky ${route.params.docId}`,
            }
          }}
        />
        <Stack.Screen
          name="PredefinedLogs"
          component={PredefinedLogs}
          options={({ route }) => {
            return {
              title: `Předdefinované jízdy`,
            }
          }}
        />
        <Stack.Screen
          name="Cashflow"
          component={CashflowOverview}
          options={({ route }) => {
            return {
              ...DefaultTheme.headerStyle,
              title: `Cashflow`,
              headerRight: () => (
                <HeaderActionIcon
                  onPress={() => navigation.navigate("ExtraCashflowOverview")}
                  icon={<MaterialCommunityIcons name="cash-plus" size={DefaultTheme.icons.headerSizeRight} />}
                />
              ),
            }
          }}
        />
        <Stack.Screen
          name="Trivi"
          component={TriviScreen}
          options={({ route }) => {
            return {
              ...DefaultTheme.headerStyle,
              title: `Trivi Dokumenty`,
            }
          }}
        />
        <Stack.Screen
          name="ExtraCashflowOverview"
          component={ExtraCashflowOverview}
          options={({ route }) => {
            return {
              title: `Cashflow dokumenty`,
            }
          }}
        />
        <Stack.Screen
          name="ExtraCashflowDetail"
          component={ExtraCashflowDetail}
          options={({ route }) => {
            return {
              title: `Cashflow`,
              headerRight: () => (
                <HeaderActionIcon
                  onPress={() => {
                    const props = route.params
                    setTitle(`Doopravdy chcete smazat cashflow dokument?`)
                    setStatus(statusEnum.PROGRESS_DECIDE)
                    setAction({
                      type: actionEnum.DELETE_CASHFLOW_ITEM,
                      params: { document: props.document, toScreen: "ExtraCashflowOverview" },
                    })
                    navigation.navigate("StatusScreen")
                  }}
                  icon={
                    <FontAwesome
                      name="trash"
                      size={DefaultTheme.icons.headerSizeRight}
                      color={DefaultTheme.colors.danger}
                    />
                  }
                />
              ),
            }
          }}
        />
        <Stack.Screen
          name="PredefinedLogDetail"
          component={PredefinedLogDetail}
          options={({ route }) => {
            const label = route.params?.item.label || "Nová jízda"
            return {
              title: `${label}`,
            }
          }}
        />
        <Stack.Screen
          name="CashflowInitialValue"
          component={CashflowInitialValue}
          options={({ route }) => {
            return {
              title: `Nastavení zůstatku pro cashflow`,
            }
          }}
        />
        <Stack.Screen
          name="ContactScreen"
          component={ContactScreen}
          options={({ route }) => {
            return {
              title: `Kontakt`,
              headerLeft: () => (
                <HeaderActionIcon
                  onPress={() => navigation.openDrawer()}
                  icon={<FontAwesome {...VIEW_PARAMS_HEADER_LEFT["bars"]} />}
                />
              ),
            }
          }}
        />
      </Stack.Navigator>

      {/*<AwesomeUpdateAlert />*/}
    </View>
  )
}

const styles = StyleSheet.create({
  headerIcons: {
    flexDirection: "row",
    width: 100,
    justifyContent: "space-around",
  },
})
