import React, { useEffect, useRef, useState } from "react"
import { FlatList } from "react-native"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import Screen from "../../components/Screen"
import FSStoreContent from "../../firebase/FSStoreContent"
import FlatListLogBookMonthsItem from "../../components/FlatLists/FlatListLogBookMonthsItem"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"

export default function LogBookMonths({ navigation, route }) {
  const today = new Date()
  const user = route.params.user
  const refreshLogBook = useRef()

  const [logBooksMonthly, setLogBooksMonthly] = useState([])

  refreshLogBook.current = (newMonthlyLogBook) => {
    if (newMonthlyLogBook.length > 0) {
      console.debug("Found data: ", newMonthlyLogBook)
      const emptyYearMonths = []
      for (let i = today.getMonth() - 12; i < today.getMonth() + 2; i++) {
        const tempDate = new Date(today.getFullYear(), today.getMonth() + i)
        const tempYearMonth = `${tempDate.getFullYear()}_${tempDate.getMonth()}`

        if (!newMonthlyLogBook.some((monthlyLogBook) => monthlyLogBook.id === tempYearMonth)) {
          console.debug("test: ", tempYearMonth)
          emptyYearMonths.push({ closing_items: [], logs: [], id: tempYearMonth, user: user.email })
        }
      }

      newMonthlyLogBook = [...newMonthlyLogBook, ...emptyYearMonths]

      newMonthlyLogBook.sort((a, b) => {
        const aSplit = a.id.split("_")
        const bSplit = b.id.split("_")
        return new Date(bSplit[0], bSplit[1]) - new Date(aSplit[0], aSplit[1])
      })
      setLogBooksMonthly(newMonthlyLogBook)
    } else {
      console.debug("Found no data, showing empty data instead")
      const emptyYearMonths = []
      for (let i = today.getMonth() - 12; i < today.getMonth() + 2; i++) {
        const tempDate = new Date(today.getFullYear(), today.getMonth() + i)
        const tempYearMonth = `${tempDate.getFullYear()}_${tempDate.getMonth()}`
        emptyYearMonths.push({ closing_items: [], logs: [], id: tempYearMonth, user: user.email })
      }
      emptyYearMonths.reverse()
      setLogBooksMonthly(emptyYearMonths)
    }
  }

  useEffect(() => {
    FSStoreContent.onSnapshotLogBookUser(user.email, refreshLogBook)

    return () => {
      refreshLogBook.current = () => {}
    }
  }, [])

  return (
    <Screen>
      {logBooksMonthly.length > 0 ? (
        <FlatList
          data={logBooksMonthly}
          renderItem={({ item }) => <FlatListLogBookMonthsItem navigation={navigation} monthLogBook={item} />}
          keyExtractor={(item) => item.id}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="Žádné jízdy" />
      )}
    </Screen>
  )
}
