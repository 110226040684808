import React, { useEffect, useState } from "react"
import { View, StyleSheet, Text, Platform, FlatList } from "react-native"
import Screen from "../../components/Screen"
import AppDatePicker from "../../components/AppDatePicker"
import PrimButton from "../../components/PrimButton"
import NextButton from "../../components/NextButton"
import { useData } from "../../providers/DataProvider"
import FlatListProjectItem from "../../components/FlatLists/FlatListProjectItem"
import { viewTypeEnum, statusEnum } from "../../data/enums"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useStatus } from "../../providers/StatusProvider"
import Constants from "expo-constants"
import { firebase } from "../../firebase/config"
import { getSyncTriviAccountingDocsUrl } from "../../utils/backendUrls"

export default function TriviSync({ navigation }) {
  const { setStatus, setTitle, setPercentUploaded, setMessage, setToScreen } = useStatus()
  const { projects, settings, firebaseConfig } = useData()
  const today = new Date()
  const [syncDate, setSyncDate] = useState(settings.trivi?.sync_from?.toDate() || today)
  const [selectableProjects, setSelectableProjects] = useState([])

  useEffect(() => {
    setSelectableProjects(projects)
  }, [projects])

  useEffect(() => {
    setPercentUploaded(
      settings.trivi?.current_page <= settings.trivi?.pages_count
        ? Number(((settings.trivi?.current_page / settings.trivi?.pages_count) * 100).toFixed(0))
        : 100
    )
    setSyncDate(settings.trivi?.sync_from?.toDate() || today)
    setMessage(
      `${settings.trivi?.current_page <= settings.trivi?.pages_count ? settings.trivi?.current_page : 0} / ${
        settings.trivi?.pages_count
      } stránek`
    )
  }, [settings])

  useEffect(() => {
    setPercentUploaded(0)
    setMessage(`0 / - stránek`)

    return () => {
      setMessage("")
    }
  }, [])

  async function handleSyncProjects() {
    setTitle("Načítám nové projekty")
    setStatus(statusEnum.PROGRESS_DETERMINED)
    navigation.navigate("StatusScreen")

    try {
      const token = await firebase.apps[0].auth().currentUser.getIdToken()

      const url = getSyncTriviAccountingDocsUrl(firebaseConfig)

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          syncFrom: `${syncDate}`,
        }),
      })

      console.debug(await response.json())
      setStatus(statusEnum.SUCCESS)
      setMessage("Synchronizace s Trivi pro dané projekty, prebehne vždy automaticky o 2:00 AM")
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
  }

  function handleSaveProjects() {
    try {
      setTitle("Ukládám vybrané projekty")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      selectableProjects.forEach(async (proj) => {
        await FSStoreContent.saveProject(proj)
      })

      setStatus(statusEnum.SUCCESS)
      setMessage("Vybrané projekty se zobrazí ve vaší aplikaci")
      setToScreen("ProjectsOverview")
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
  }

  return (
    <Screen>
      <View style={{ flex: 1, paddingTop: 10 }}>
        {Platform.OS === "web" && <Text style={styles.alignText}>Načíst nové projekty od:</Text>}
        <AppDatePicker
          label="Načíst nové projekty od"
          webLabel={["Den", "Měsíc", "Rok"]}
          value={syncDate}
          onConfirm={(date) => setSyncDate(date || new Date())}
          disabled={false}
        />
        <View style={{ marginHorizontal: 10, height: 60 }}>
          <PrimButton text="Načíst nové projekty" width="100%" onPress={handleSyncProjects} />
        </View>

        {selectableProjects.length > 0 ? (
          <>
            <Text style={[styles.alignText, Platform.OS !== "web" && { alignSelf: "center", textAlign: "center" }]}>
              Synchronizované projekty od vybraného období (maximální doba synchronizace jsou 2 roky)
            </Text>
            <FlatList
              data={selectableProjects}
              renderItem={({ item }) => (
                <FlatListProjectItem
                  project={item}
                  navigation={navigation}
                  viewType={viewTypeEnum.SYNC}
                  isSyncing={item.isSyncing}
                  selectableProjects={selectableProjects}
                  setSelectableProjects={setSelectableProjects}
                />
              )}
              ItemSeparatorComponent={FlatListSeparator}
              extraData={selectableProjects}
            />
          </>
        ) : (
          <AppEmptyScreenView message="Nebyly nalezeny žádné projekty" />
        )}
      </View>
      <NextButton
        style={{ bottom: 0, marginTop: 10 }}
        text="Synchronizovat"
        visible={selectableProjects.length > 0}
        disabled={!selectableProjects.filter((proj) => proj.isSyncing === true).length > 0}
        onPress={handleSaveProjects}
      />
    </Screen>
  )
}

const styles = StyleSheet.create({
  alignText: {
    padding: 10,
    marginHorizontal: 10,
  },
})
