function Enum() {
  let obj = {}
  for (let arg of arguments) obj = { ...obj, [arg]: arg }
  return Object.freeze(obj)
}

export const statusEnum = new Enum(
  "NONE",
  "LOADING",
  "PROGRESS_UNDETERMINED",
  "PROGRESS_UNDETERMINED_CANCELABLE",
  "PROGRESS_DETERMINED",
  "PROGRESS_DECIDE",
  "PROGRESS_DECIDE_SIMPLE",
  "SUCCESS",
  "ERROR",
  "RELOG",
  "RELOG_END",
  "REGISTER"
)

export const actionEnum = new Enum(
  "NEW",
  "EDIT",
  "DELETE_ACCOUNTING_DOCUMENT",
  "DELETE_USER_FROM_PROJECT",
  "CASH_DESK_CLOSE_MONTH",
  "CASH_DESK_OPEN_MONTH",
  "LOG_BOOK_CLOSE_MONTH",
  "LOG_BOOK_OPEN_MONTH",
  "PREMIUM_REQUEST",
  "DELETE_DOCUMENT",
  "DELETE_LOG_BOOK_ITEM",
  "DELETE_PROPERTY_ITEM",
  "DELETE_USER",
  "DELETE_DIARY_ENTRY",
  "PAY_AGAIN",
  "DELETE_TEMPLATE",
  "ATTENDANCES_OPEN_MONTH",
  "ATTENDANCES_CLOSE_MONTH",
  "EXEC_LABEL",
  "DELETE_CASHFLOW_ITEM",
  "DELETE_TRIVI_DOCUMENT"
)

export const taskActionEnum = new Enum("APPROVE", "VIEW")

export const approveEnum = new Enum("ACCEPTED", "REJECTED", "PENDING")

export const documentStateEnum = new Enum("ACCEPTED", "REJECTED", "PENDING")

export const scanTypeEnum = new Enum("IMAGE", "FILE")
export const documentViewTypeEnum = new Enum("OVERVIEW", "CASH_DESK", "VEHICLE_LOG", "COMPANY", "USER", "BATCH")
export const documentCategoryEnum = new Enum("MATERIAL", "REZIE_A_SLUZBY", "ZBOZI", "OSTATNI", "SMISENE", "UBYTOVANI")
export const documentAccountingTypeEnum = new Enum("INVOICE", "ADVANCE")
export const documentBulkActionEnum = new Enum("DELETE")
export const documentTypeEnum = new Enum("CONTRACT", "DOCUMENTATION")

export const projectStateEnum = new Enum("NEW", "OPEN", "CLOSED")

export const userRoleEnum = new Enum(
  "ADMIN",
  "EXECUTIVE",
  "PROJECT_MANAGER",
  "TECHNICAL_DIRECTOR",
  "CONSTRUCTION_LEAD",
  "CONSTRUCTION_STAFF",
  "ACCOUNTANT",
  "OFFICE_MANAGER",
  "INVESTOR",
  "VIEWER"
)

export const taskBulkActionEnum = new Enum("DONE")

export const attendanceTypeEnum = new Enum("WORK", "HOLIDAY", "SICKDAY", "HOMEOFFICE", "NOT_STATED_PAST", "NOT_STATED_FUTURE")
export const covidResultEnum = new Enum("NEGATIVE", "POSITIVE", "INVALID")
export const attendancePlaceEnum = new Enum("CZ", "OTHER")
export const attendanceOperationTypeEnum = new Enum("HOLIDAY_APPROVE")
export const holidayStateEnum = new Enum("ACCEPTED", "REJECTED", "PENDING")
export const attendanceBulkActionEnum = new Enum(
  "HOLIDAY_APPROVE",
  "HOLIDAY_REJECT",
  "WORK",
  "HOLIDAY_CZ",
  "HOLIDAY_OTHER"
)

export const viewTypeEnum = new Enum(
  "BULK_ACTION",
  "SYNC",
  "EMPTY",
  "DIARY",
  "PICK",
  "STATISTICS",
  "CLOSINGS",
  "DASHBOARD",
  "CASHFLOW"
)
export const paymentEnum = new Enum("CASH", "DEBIT_CARD", "BANK_TRANSFER")
export const paymentTypeEnum = new Enum("ONLINE", "BATCH", "CONTINUOUS")
export const paymentStateEnum = new Enum("REQUEST", "PENDING", "OPEN", "FAIL")
export const environmentEnum = new Enum("emu", "dev", "uat", "test", "prod")
export const monthStateEnum = new Enum("OPEN", "CLOSED")
export const documentClosingItemsStateEnum = new Enum("OPEN", "CLOSED")
export const itemStateEnum = new Enum("OPEN", "CLOSED")
export const documentClosingStateEnum = new Enum("MATCHED", "NOT_MATCHED", "MISSED")
export const propertyTypeEnum = new Enum(
  "VEHICLE",
  "IT",
  "PHONE",
  "TOOLS",
  "FURNITURE",
  "OTHER",
  "ACCESSORIES",
  "NOT_STATED"
)
export const propertyStateEnum = new Enum("ASSIGNED", "NOT_ASSIGNED", "DISCARDED", "SHARED")
export const propertyViewTypeEnum = new Enum("USERS", "ALL", "PROJECT")
export const propertyOperationTypeEnum = new Enum("STATE_BORROWED", "STATE_RETURNED")

export const premiumActionTypeEnum = new Enum(
  "REQUEST",
  "REQUEST_PENDING",
  "DECISION",
  "DECISION_PENDING",
  "DECISION_ACCEPTED",
  "DECISION_REJECTED"
)

export const statisticsPeriodEnum = new Enum(
  "THIS_YEAR",
  "LAST_6_MONTHS",
  "LAST_12_MONTHS",
  "LAST_24_MONTHS",
  "LAST_4_QARTERS",
  "LAST_8_QARTERS",
  "LAST_2_YEARS",
  "LAST_5_YEARS"
)
export const statisticsViewPeriodEnum = new Enum("MONTHLY", "QUARTERLY", "YEARLY")
export const companyCostsEnum = new Enum("ALL")

export const appPickerViewType = new Enum("USER")

export const reportTypeEnum = new Enum("ERROR", "IMPROVEMENT")

export const scansOverviewViewTypeEnum = new Enum("DOC_CHANGE")

export const userStateEnum = new Enum("DELETED")

export const versionCheckTypeEnum = new Enum("AUTO", "MANUAL")
export const bankServiceStateEnum = new Enum("success", "fail")

export const modulesEnum = new Enum("diary")

export const weatherTypeEnum = new Enum("AUTOMATIC", "MANUAL")

export const usersViewTypeEnum = new Enum("COMPANY")

export const diaryFlatListItem = new Enum("BULK_SIGNATURE")

export const cashFlowTypeEnum = new Enum("ONE_TIME", "DAILY", "WEEKLY", "MONTHLY", "YEARLY", "QUARTERLY")
