import React, { useEffect, useState } from "react"
import { Platform, Text, View, TouchableOpacity, StyleSheet, ScrollView } from "react-native"
import { TextInput } from "react-native-paper"
import uuid from "uuid"

import NextButton from "../../components/NextButton"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import { useStatus } from "../../providers/StatusProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import FullWidthImagePhone from "../../components/FullWidthImagePhone"
import FullWidthImageWeb from "../../components/FullWidthImageWeb"
import {
  actionEnum,
  approveEnum,
  propertyStateEnum,
  propertyTypeEnum,
  statusEnum,
  userStateEnum,
  userRoleEnum,
} from "../../data/enums"
import FSStoreContent from "../../firebase/FSStoreContent"
import Validation, { isAdmin, isExecutive, isOfficeManager } from "../../utils/validation"
import AppPicker from "../../components/AppPicker"
import { propertyStateLabels, propertyTypeLabels } from "../../data/labels"
import ApproveButtons from "../../components/ApproveButtons"
import {
  formatDecimalNumber,
  isPropertyBorrowed,
  sanitizeNumber,
  toDate,
} from "../../utils/general"
import { uploadFileOnFirebase } from "../../firebase/FSGeneral"
import { openLibrary } from "../../actions/platformFeatureRequestor"

export default function PropertyDetail({ navigation, route }) {
  const { property, currentUser, users, projects, setProperty } = useData()
  const { setStatus, setTitle, setMessage, updatePercentage } = useStatus()

  const [prop, setProp] = useState(route.params?.prop || {})
  const [oldLicencePlate, setOldLicencePlate] = useState(route.params?.prop?.licence_plate)

  const hasEditPermission = isAdmin(currentUser) || isExecutive(currentUser) || isOfficeManager(currentUser)

  const [imageLoading, setImageLoading] = useState(true)
  console.debug("PROP DETAIL", prop)

  const appPickerTypeItems = Object.keys(propertyTypeLabels).map((key) => ({
    label: propertyTypeLabels[key],
    value: key,
  }))
  const appPickerStateItems = Object.keys(propertyStateLabels).map((key) => ({
    label: propertyStateLabels[key],
    value: key,
  }))
  const appPickerUserItems = users
    .filter((u) => u.state !== userStateEnum.DELETED && !u.roles.includes(userRoleEnum.INVESTOR))
    .map((user) => ({ label: user.name, value: user.email }))
  const appPickerProjectItems = projects.map((proj) => ({ label: `${proj.id} - ${proj.name}`, value: proj.id }))

  useEffect(() => {
    ;(async () => {
      const propTemp = property.find((p) => p.id === route.params?.id)
      const prop = await FSStoreContent.enrichProperty(propTemp)
      if (prop) {
        setOldLicencePlate(prop.licence_plate)
        setProp(prop)
      }
    })()
  }, [property])

  useEffect(() => {
    ;(async () => {
      await FSStoreContent.onSnapshotProperty(setProperty)
    })()
  }, [])

  async function handleOpenLibrary() {
    try {
      const localUri = await openLibrary()

      if (localUri) {
        setTitle("Nahrávám obrázek majetku")
        setStatus(statusEnum.PROGRESS_DETERMINED)
        navigation.navigate("StatusScreen")

        const remoteUri = await uploadFileOnFirebase(localUri, updatePercentage, "property")
        setStatus(statusEnum.SUCCESS)
        setMessage("Obrázek majetku byl úspěšně nahrán!")

        setProp({ ...prop, filestore_uri: remoteUri })
        setImageLoading(true)
      }
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  const handleCapture = async (result) => {
    setTitle("Nahrávám obrázek majetku")
    setStatus(statusEnum.PROGRESS_DETERMINED)
    navigation.navigate("StatusScreen")

    const remoteUri = await uploadFileOnFirebase(result, updatePercentage, "property")
    setStatus(statusEnum.SUCCESS)
    setMessage("Obrázek majetku byl úspěšně nahrán!")

    setProp({ ...prop, filestore_uri: remoteUri })
    setImageLoading(true)
  }

  function handleChangeType(newType) {
    if (newType === propertyTypeEnum.VEHICLE) {
      setProp({
        ...prop,
        type: newType,
        licence_plate: "",
        licence_plate_editable: "",
        combined_consumption: "",
        amortization: "",
        gas_price_per_liter: "",
      })
    } else {
      const newProp = { ...prop, type: newType, name: prop.name, price: prop.price, state: prop.state }
      if (prop.owner_approve_state) {
        newProp.owner_approve_state = prop.owner_approve_state
      }
      if (prop.owner) {
        newProp.owner = prop.owner
      }
      if (prop.note) {
        newProp.note = prop.note
      }
      if (prop.filestore_uri) {
        newProp.filestore_uri = prop.filestore_uri
      }
      setProp(newProp)
    }
  }

  function handleProjectChange(newProject) {
    if (isPropertyBorrowed(prop)) {
      setStatus(statusEnum.ERROR)
      setMessage("Majetek je přiřazen na jiném projektu. Před změnou projektu je nutně jej vrátit.")
      navigation.navigate("StatusScreen")
    } else {
      delete prop.operations
      console.debug("Property object: ", prop)
      const { assigned_project, ...rest } = { ...prop }
      newProject === " " ? setProp(rest) : setProp({ ...prop, assigned_project: newProject })
    }
  }

  async function handleSave() {
    setTitle("Nahrávám majetek")
    setStatus(statusEnum.PROGRESS_UNDETERMINED)
    navigation.navigate("StatusScreen")

    try {
      let sanitizeProp = { ...prop, price: Number(prop.price), created_at: new Date() }
      if (prop.type === propertyTypeEnum.VEHICLE) {
        sanitizeProp = {
          ...sanitizeProp,
          gas_price_per_liter: sanitizeNumber(prop.gas_price_per_liter),
          combined_consumption: sanitizeNumber(prop.combined_consumption),
          amortization: sanitizeNumber(prop.amortization),
        }
        if (!sanitizeProp.vehicle_id && sanitizeProp.licence_plate_editable) {
          sanitizeProp = { ...sanitizeProp, vehicle_id: uuid.v4() }
        }
      }
      await FSStoreContent.uploadProperty(sanitizeProp, false)
      if (prop.task_id && prop.owner_approve_state && prop.owner_approve_state !== approveEnum.PENDING) {
        await FSStoreContent.markTaskAsDone(prop.task_id)
      }
      setStatus(statusEnum.SUCCESS)
      navigation.goBack()
    } catch (error) {
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
    }
  }

  function isSaveDisabled() {
    if (prop.type === propertyTypeEnum.VEHICLE) {
      return !(
        prop.licence_plate_editable &&
        prop.name &&
        Validation.isNumberPositive(prop.price) &&
        (prop.state === propertyStateEnum.ASSIGNED ? prop.owner && prop.state : prop.state && !prop.owner) &&
        Validation.isNumberPositive(prop.amortization) &&
        Validation.isNumberPositive(prop.combined_consumption) &&
        Validation.isNumberPositive(prop.gas_price_per_liter) &&
        (hasEditPermission || prop.owner === currentUser.email)
      )
    } else {
      return !(
        prop.name &&
        Validation.isNumberPositive(prop.price) &&
        prop.type &&
        (prop.state === propertyStateEnum.ASSIGNED ? prop.owner && prop.state : prop.state && !prop.owner) &&
        (hasEditPermission || prop.owner === currentUser.email)
      )
    }
  }

  return (
    <Screen>
      <View style={{ flex: 1 }}>
        {(imageLoading || !prop.filestore_uri) && (
          <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
            <Text style={{ fontSize: DefaultTheme.fonts.regular }}>
              {prop.filestore_uri ? "Načítám obrázek majetku" : "Nahrajte prosím obrázek majetku"}
            </Text>
          </View>
        )}
        <View
          style={[
            { flex: 1, overflow: "hidden" },
            (imageLoading || !prop.filestore_uri) && { transform: [{ scale: 0 }], flex: 0 },
          ]}
        >
          <ScrollView>
            {Platform.OS !== "web" ? (
              <FullWidthImagePhone uri={prop.filestore_uri} onLoadEnd={() => setImageLoading(false)} width="95%" />
            ) : (
              <FullWidthImageWeb uri={prop.filestore_uri} onLoadEnd={() => setImageLoading(false)} width="100%" />
            )}
          </ScrollView>
        </View>
        {(!imageLoading || !prop.filestore_uri) && (
          <View style={{ position: "absolute", width: "100%" }}>
            <TouchableOpacity
              style={styles.imageRetake}
              onPress={() =>
                navigation.navigate("CaptureImage", {
                  prop,
                  action: actionEnum.EDIT,
                  uploadCapturedImage: (result) => handleCapture(result),
                })
              }
              disabled={!hasEditPermission}
            >
              <MaterialCommunityIcons
                name={prop.filestore_uri ? "camera-retake" : "camera"}
                size={DefaultTheme.icons.l}
                color={DefaultTheme.colors.primary}
              />
            </TouchableOpacity>
            <TouchableOpacity style={styles.libraryRetake} onPress={handleOpenLibrary} disabled={!hasEditPermission}>
              <MaterialCommunityIcons name="image" size={DefaultTheme.icons.l} color={DefaultTheme.colors.primary} />
            </TouchableOpacity>
          </View>
        )}
      </View>
      <View style={{ flex: 1 }}>
        <ScrollView>
          <AppPicker
            value={prop.type}
            descriptionLabel="Typ majetku"
            placeholder={{ label: "Vyberte typ majetku", value: "" }}
            onValueChange={(value) => handleChangeType(value)}
            items={appPickerTypeItems}
            disabled={!hasEditPermission}
          />
          <AppPicker
            value={prop.assigned_project}
            descriptionLabel="Přiřazený projekt"
            placeholder={{ label: "Žádný", value: " " }}
            onValueChange={(value) => handleProjectChange(value)}
            items={appPickerProjectItems}
            disabled={!hasEditPermission}
          />
          <TextInput
            error={!prop.name}
            style={DefaultTheme.input}
            label={
              prop.name
                ? `${prop.type === propertyTypeEnum.VEHICLE ? "Název vozidla" : "Název majetku"}`
                : `${prop.type === propertyTypeEnum.VEHICLE ? "Zadejte název vozidla" : "Zadejte název majetku"}`
            }
            onChangeText={(value) => setProp({ ...prop, name: value })}
            value={prop.name}
            disabled={!hasEditPermission}
          />
          <TextInput
            error={!Validation.isNumberPositive(prop.price)}
            style={DefaultTheme.input}
            keyboardType="numeric"
            label={
              Validation.isNumberPositive(prop.price)
                ? `${prop.type === propertyTypeEnum.VEHICLE ? "Cena vozidla Kč bez DPH" : "Cena majetku Kč bez DPH"}`
                : `${
                    prop.type === propertyTypeEnum.VEHICLE
                      ? "Zadejte cenu vozidla Kč bez DPH"
                      : "Zadejte cenu majetku Kč bez DPH"
                  }`
            }
            onChangeText={(value) => setProp({ ...prop, price: formatDecimalNumber(value) })}
            value={prop.price?.toString()}
            disabled={!hasEditPermission}
          />
          {prop.type === propertyTypeEnum.VEHICLE && (
            <View>
              {/* <TextInput
                error={!prop.licence_plate}
                style={DefaultTheme.input}
                label={prop.licence_plate ? "SPZ vozidla" : "Zadejte SPZ vozidla"}
                onChangeText={(value) => setProp({ ...prop, licence_plate: value })}
                value={prop.licence_plate}
                disabled={!hasEditPermission || oldLicencePlate}
              /> */}
              <TextInput
                error={!prop.licence_plate_editable}
                style={DefaultTheme.input}
                label={prop.licence_plate_editable ? "Upravitelná SPZ vozidla" : "Zadejte upravitelnou SPZ vozidla"}
                onChangeText={(value) => setProp({ ...prop, licence_plate_editable: value })}
                value={prop.licence_plate_editable}
                disabled={!hasEditPermission}
              />
              <TextInput
                error={!Validation.isNumberPositive(prop.actual_km)}
                style={DefaultTheme.input}
                keyboardType="numeric"
                label={
                  Validation.isNumberPositive(prop.actual_km)
                    ? "Aktuální nájezd vozidla KM "
                            + "(datum: " + toDate(prop.actual_km_date)
                            + " zdroj: " + prop.actual_km_source
                            + " vyvořil: " + prop.actual_km_created_by + ")"
                    : "Zadejte aktuální nájezd vozidla KM"
                }
                onChangeText={(value) => setProp({
                  ...prop,
                  actual_km: formatDecimalNumber(value),
                  actual_km_date: new Date(),
                  actual_km_created_by: currentUser.email,
                  actual_km_source: "Majetek Karta"
                })}
                value={prop.actual_km?.toString()}
                disabled={!hasEditPermission}
              />

              <TextInput
                error={!Validation.isNumberPositive(prop.amortization)}
                style={DefaultTheme.input}
                keyboardType="numeric"
                label={
                  Validation.isNumberPositive(prop.amortization)
                    ? "Amortizace vozidla v Kč s DPH / km"
                    : "Zadejte amortizaci vozidla v Kč s DPH / km"
                }
                onChangeText={(value) => setProp({ ...prop, amortization: formatDecimalNumber(value) })}
                value={prop.amortization?.toString()}
                disabled={!hasEditPermission}
              />
              <TextInput
                error={!Validation.isNumberPositive(prop.combined_consumption)}
                style={DefaultTheme.input}
                keyboardType="numeric"
                label={
                  Validation.isNumberPositive(prop.combined_consumption)
                    ? "Kombinovaná spotřeba v l / 100 km"
                    : "Zadejte kombinovanou spotřebu v l / 100 km"
                }
                onChangeText={(value) => setProp({ ...prop, combined_consumption: formatDecimalNumber(value) })}
                value={prop.combined_consumption?.toString()}
                disabled={!hasEditPermission}
              />
              <TextInput
                error={!Validation.isNumberPositive(prop.gas_price_per_liter)}
                style={DefaultTheme.input}
                keyboardType="numeric"
                label={
                  Validation.isNumberPositive(prop.gas_price_per_liter)
                    ? "Částka za benzín nebo naftu v Kč s DPH / l"
                    : "Zadejte částku za benzín nebo naftu v Kč s DPH / l"
                }
                onChangeText={(value) => setProp({ ...prop, gas_price_per_liter: formatDecimalNumber(value) })}
                value={prop.gas_price_per_liter?.toString()}
                disabled={!hasEditPermission}
              />
            </View>
          )}

          <AppPicker
            value={prop.state}
            descriptionLabel={prop.type === propertyTypeEnum.VEHICLE ? "Stav vozidla" : "Stav majetku"}
            placeholder={{
              label: prop.type === propertyTypeEnum.VEHICLE ? "Vyberte stav vozidla" : "Vyberte stav majetku",
              value: "",
            }}
            onValueChange={(value) => {
              if (value !== propertyStateEnum.ASSIGNED && (prop.owner || prop.owner_approve_state)) {
                delete prop.owner
                delete prop.owner_approve_state
              }
              setProp({ ...prop, state: value })
            }}
            items={appPickerStateItems}
            disabled={!hasEditPermission}
          />
          {prop.state === propertyStateEnum.ASSIGNED && (
            <AppPicker
              value={prop.owner}
              descriptionLabel={prop.type === propertyTypeEnum.VEHICLE ? "Vlastník vozidla" : "Vlastník majetku"}
              placeholder={{
                label:
                  prop.type === propertyTypeEnum.VEHICLE ? "Vyberte vlastníka vozidla" : "Vyberte vlastníka majetku",
                value: "",
              }}
              onValueChange={(value) => {
                delete prop.owner_approve_state
                setProp({ ...prop, owner: value })
              }}
              items={appPickerUserItems}
              disabled={!hasEditPermission}
            />
          )}
          <TextInput
            style={DefaultTheme.input}
            label={
              prop.note
                ? `${prop.type === propertyTypeEnum.VEHICLE ? "Poznámka k vozidlu" : "Poznámka k majetku"}`
                : `${
                    prop.type === propertyTypeEnum.VEHICLE ? "Zadejte poznámku k vozidlu" : "Zadejte poznámku k majetku"
                  }`
            }
            onChangeText={(value) => setProp({ ...prop, note: value })}
            value={prop.note}
            disabled={!hasEditPermission}
          />
        </ScrollView>
      </View>
      {prop.owner === currentUser.email && (
        <View
          style={{
            padding: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: DefaultTheme.fonts.regular, flex: 1, paddingRight: 5 }}>
            Potvrzení o vlastnictví majetku
          </Text>
          <ApproveButtons
            initialState={prop.owner_approve_state}
            action_type="owner_approve_state"
            changeValue={(action_type, value) => setProp({ ...prop, [action_type]: value })}
          />
        </View>
      )}
      <NextButton disabled={isSaveDisabled()} onPress={handleSave} text="Uložit" />
    </Screen>
  )
}

const styles = StyleSheet.create({
  imageRetake: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  libraryRetake: {
    position: "absolute",
    left: 10,
    top: 10,
  },
  fileSystemRetake: {
    position: "absolute",
    left: 10,
    top: 60,
  },
})
