import { firebase } from "./config"

export async function add(company) {
  try {
    await firebase.apps[0].firestore().collection("contactCompanies").add(company)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function get(companyId) {
  try {
    const companyRef = await firebase.apps[0].firestore().collection("contactCompanies").doc(companyId).get()
    return { ...companyRef.data(), id: companyRef.id }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function update(company) {
  try {
    const companyId = company.id
    delete company.id
    await firebase.apps[0].firestore().collection("contactCompanies").doc(companyId).update(company)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function del(companyId) {
  try {
    await firebase.apps[0].firestore().collection("contactCompanies").doc(companyId).delete()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function onSnapshotCompany(companyId, next) {
  firebase.apps[0]
    .firestore()
    .collection("contactCompanies")
    .doc(companyId)
    .onSnapshot((doc) => {
      try {
        if (doc.data()) {
          next({
            ...doc.data(),
            id: doc.id,
          })
        }
      } catch (error) {
        console.error(error)
        throw error
      }
    })
}

export function onSnapshotCompanyList(next) {
  firebase.apps[0]
    .firestore()
    .collection("contactCompanies")
    .onSnapshot((snap) => {
      try {
        console.info("length: ", snap.docs.length)
        next(
          snap.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        )
      } catch (error) {
        console.error("Error onSnapshotCompanyList: ", error)
        throw error
      }
    })
}

export default {
  add,
  get,
  update,
  del,
  onSnapshotCompany,
  onSnapshotCompanyList,
}
