import React, { useEffect, useRef, useState } from "react"
import { Image, Linking, Platform, Text, TouchableHighlight, TouchableOpacity, View } from "react-native"
import ProgressBar from "react-native-progress/Bar"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"

import PrimButton from "./PrimButton"
import { useStatus } from "../providers/StatusProvider"
import DefaultTheme from "../themes/DefaultTheme"
import { openFileStorage, openLibrary } from "../actions/platformFeatureRequestor"
import { deleteFileFromFirebase, getFileMetadata, uploadFileOnFirebase } from "../firebase/FSGeneral"
import { scanTypeEnum, statusEnum } from "../data/enums"
import { TextInput } from "react-native-paper"
import { isLink } from "../utils/validation"
import { getNameFromFirebaseUri } from "../utils/general"
import ItemsCounter from "./ItemsCounter"
import CloseButton from "./CloseButton"

export default function ScanList({ hasLink = false, uriList, addScan, deleteScan, disabled = false, extras }) {
  const navigation = useNavigation()
  const { setStatus, setMessage } = useStatus()
  const [uriMetadata, setUriMetadata] = useState([])
  const [percentUploaded, setPercentUploaded] = useState(0)
  const updatePercentage = useRef()

  updatePercentage.current = (snap) => {
    const p = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
    setPercentUploaded(p)
  }

  useEffect(() => {
    getUriMetadata()
  }, [uriList])

  async function getUriMetadata() {
    setUriMetadata(
      await Promise.all(uriList.map(async (uri) => (getNameFromFirebaseUri(uri) ? await getFileMetadata(uri) : {})))
    )
  }

  async function handleCaptureImage() {
    navigation.navigate("CaptureImage", {
      uploadCapturedImage: async (localUri) => {
        const storageUri = await uploadFileOnFirebase(localUri, updatePercentage, "diary-attachments")
        addScan(storageUri)
      },
    })
  }

  async function handleOpenLibrary() {
    try {
      const localUri = await openLibrary()
      if (localUri) {
        const storageUri = await uploadFileOnFirebase(localUri, updatePercentage, "diary-attachments")
        addScan(storageUri)
      }
    } catch (error) {
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  async function handleOpenPdf() {
    try {
      const { localUri } = await openFileStorage()
      if (localUri) {
        const storageUri = await uploadFileOnFirebase(localUri, updatePercentage, "diary-attachments")
        addScan(storageUri)
      }
    } catch (error) {
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  async function handleDeleteScan(uri, isLink) {
    try {
      // TODO we are not deleting images so far, because of simplicity
      // issue 1 - we would have to create new instances of firestorage images when creating new duplicity of diary entry
      // issue 2 - when firestorage uri deleted and go back without saving, the diary entry will include firestore uris which are already deleted from storage
      // ---------------------- KEEP THIS CODE ----------------------
      // if (!isLink) {
      //   deleteFileFromFirebase(uri)
      // }
      // ---------------------- KEEP THIS CODE ----------------------
      deleteScan(uri)
    } catch (error) {
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  function NoPreviewFile({ children, style }) {
    return (
      <View
        style={[
          {
            width: 96,
            height: 124,
            borderWidth: 1,
            borderRadius: 5,
            alignItems: "center",
            justifyContent: "center",
            marginHorizontal: 10,
          },
          style,
        ]}
      >
        {children}
      </View>
    )
  }

  return (
    <View>
      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <View>
          <Text>Přidejte přílohy</Text>
          {uriList.length > 0 && <ItemsCounter value={uriList.length} top={-5} right={-25} size="small" />}
        </View>
        <View style={{ flexDirection: "row" }}>
          <PrimButton
            disabled={disabled}
            icon={<MaterialCommunityIcons name="file-pdf-box" size={20} color={DefaultTheme.colors.white} />}
            onPress={handleOpenPdf}
            style={{ marginRight: 10 }}
            width={40}
          />
          <PrimButton
            disabled={disabled}
            icon={<MaterialCommunityIcons name="camera" size={20} color={DefaultTheme.colors.white} />}
            onPress={handleCaptureImage}
            style={{ marginRight: 10 }}
            width={40}
          />
          <PrimButton
            disabled={disabled}
            icon={<MaterialCommunityIcons name="image" size={20} color={DefaultTheme.colors.white} />}
            onPress={handleOpenLibrary}
            style={{ marginRight: 10 }}
            width={40}
          />
          {hasLink && <LinkComponent addScan={addScan} />}
        </View>
      </View>
      <View style={[{ flexWrap: "wrap", flexDirection: "row" }, uriList.length > 0 && { paddingTop: 20 }]}>
        {uriList.map((uri) => {
          const fileName = getNameFromFirebaseUri(uri)
          const isLink = !fileName
          const fileMetadata = uriMetadata.find((metaData) => metaData.name === fileName)
          const isPdf = fileMetadata?.contentType?.split("/")[1] === "pdf"

          function handlePreviewClick() {
            if (isLink) {
              Platform.OS === "web" ? window.open(uri, "_blank") : Linking.openURL(uri)
            } else {
              navigation.navigate("PreviewScan", {
                title: fileName,
                type: isPdf ? scanTypeEnum.FILE : scanTypeEnum.IMAGE,
                uri,
                extras,
              })
            }
          }

          return (
            <TouchableOpacity
              disabled={disabled}
              key={uri}
              onPress={handlePreviewClick}
              style={[
                { marginHorizontal: 10, marginBottom: 15, width: 96 },
                Platform.OS === "web" && { cursor: "pointer" },
              ]}
            >
              {isPdf || isLink ? (
                <NoPreviewFile>
                  <MaterialCommunityIcons
                    name={isPdf ? "file-pdf-box" : "link"}
                    size={30}
                    color={DefaultTheme.colors.black}
                  />
                </NoPreviewFile>
              ) : (
                <Image style={{ width: 96, height: 124, borderRadius: 5, marginHorizontal: 10 }} source={{ uri }} />
              )}
              <CloseButton disabled={disabled} onPress={() => handleDeleteScan(uri, isLink)} top={-10} right={-20} />
              <Text numberOfLines={1} ellipsizeMode="head" style={{ width: 96, overflow: "hidden", direction: "rtl" }}>
                {isLink ? uri : fileName}
              </Text>
            </TouchableOpacity>
          )
        })}
        {0 < percentUploaded && percentUploaded < 100 && (
          <NoPreviewFile style={{ marginLeft: 20 }}>
            <ProgressBar progress={percentUploaded / 100} width={80} height={15} />
          </NoPreviewFile>
        )}
      </View>
    </View>
  )
}

function LinkComponent({ addScan }) {
  const [link, setLink] = useState("")
  const [showLinkInput, setShowLinkInput] = useState(false)

  function handleConfirm() {
    setShowLinkInput(false)
    addScan(link)
    setLink("")
  }

  function LinkButton() {
    return (
      <PrimButton
        icon={
          <MaterialCommunityIcons
            name={`link${showLinkInput ? "-off" : ""}`}
            size={20}
            color={DefaultTheme.colors.white}
          />
        }
        onPress={() => setShowLinkInput(!showLinkInput)}
        width={40}
      />
    )
  }

  // "https://dasar.box.com/s/bp2de6l3g99fjzkcvv0m2gd15cuexgie"
  return (
    <View>
      <View
        style={[
          {
            padding: 5,
            borderRadius: 5,
            borderColor: DefaultTheme.colors.borderPrimary,
            borderWidth: 1,
            position: "absolute",
            backgroundColor: DefaultTheme.colors.background,
            right: -6,
            bottom: -20,
            flexDirection: "row",
            alignItems: "center",
            zIndex: 1,
          },
          !showLinkInput && { transform: [{ scale: 0 }], flex: 0, display: "none" },
        ]}
      >
        <LinkButton />
        <TextInput
          error={!isLink(link)}
          keyboardType="url"
          style={[DefaultTheme.input, Platform.OS !== "web" && { width: 150 }]}
          label={link ? "Link" : "Zadejte link"}
          value={link}
          autoCapitalize="none"
          onChangeText={setLink}
        />
        <PrimButton disabled={!isLink(link)} onPress={handleConfirm} text="OK" width={45} />
      </View>
      <LinkButton />
    </View>
  )
}
