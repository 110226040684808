import React from "react"
import { Image } from "react-native"
import { View, StyleSheet, Text, ScrollView } from "react-native"
import QRCode from "react-native-qrcode-svg"
import Screen from "../components/Screen"
import Constants from "expo-constants"
import { environmentEnum } from "../data/enums"

const environment = Constants.manifest.extra.environment

export default function DownloadIOS() {
  return (
    <Screen>
      <ScrollView>
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1, alignItems: "center" }}>
            <Text style={{ fontSize: 48, fontWeight: "bold" }}>1. Krok</Text>
            <Text style={{ fontSize: 32, paddingBottom: 20, textAlign: "center" }}>
              Stáhněte si Expo Go - pomocnou aplikaci na testování
            </Text>

            <View style={{ flex: 1, justifyContent: "center" }}>
              <QRCode value="https://apps.apple.com/us/app/expo-go/id982107779" size={300} />
            </View>
          </View>
          <View style={{ flex: 1, alignItems: "center" }}>
            <Text style={{ fontSize: 48, fontWeight: "bold" }}>2. Krok</Text>
            <Text style={{ fontSize: 32, paddingBottom: 20, textAlign: "center" }}>
              V této aplikaci je nutné se přihlásit
            </Text>
            <Image source={require("../assets/step2.png")} style={styles.imageStep2} />
          </View>
        </View>

        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1, alignItems: "center" }}>
            <Text style={{ fontSize: 48, fontWeight: "bold", paddingTop: 25 }}>3. Krok</Text>
            <Text style={{ fontSize: 32, paddingBottom: 20 }}>Zadejte tyto přihlašovací údaje:</Text>
            <Text style={{ fontSize: 24, paddingBottom: 10 }}>
              <strong>Uživatelské jméno:</strong>{" "}
              {environment === environmentEnum.test
                ? "dasar"
                : environment === environmentEnum.prod
                ? "dasar-prod"
                : "cexbit-dev"}
            </Text>
            <Text style={{ fontSize: 24 }}>
              <strong>Heslo:</strong>{" "}
              {environment === environmentEnum.test
                ? "DASARtest123"
                : environment === environmentEnum.prod
                ? "DASARprod123"
                : "H4c3-7ED"}
            </Text>
          </View>
          <View style={{ flex: 1, alignItems: "center" }}>
            <Text style={{ fontSize: 48, fontWeight: "bold" }}>4. Krok</Text>
            <Text style={{ fontSize: 32, paddingBottom: 20 }}>Otevřete si v ní DASAR!</Text>
            <Image source={require("../assets/step4.png")} style={styles.imageStep4} />
          </View>
        </View>
      </ScrollView>
    </Screen>
  )
}

const styles = StyleSheet.create({
  imageStep2: {
    width: 300,
    height: 700,
    resizeMode: "contain",
  },
  imageStep4: {
    width: 300,
    height: 700,
    resizeMode: "contain",
  },
})
