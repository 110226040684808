import { firebase as fb } from "./config"
import { cashFlowTypeEnum } from "../data/enums"

export const generatedExtraCashflowDocs = async () => {
  console.debug("Started generatedExtraCashflowDocs")
  const today = new Date()
  console.debug("Today:" + JSON.stringify(today))
  let year = today.getFullYear()
  console.debug("year:" + JSON.stringify(year))
  let monthIndex = today.getMonth()
  console.debug("monthIndex:" + JSON.stringify(monthIndex))
  let generatedExtraDocs = []
  do {
    const firstDayCurrentMonth = new Date(year, monthIndex)
    const firstDayNextMonth = new Date(year, monthIndex + 1)
    const daysInCurrentMonth = new Date(year, monthIndex + 1, 0).getDate()
    const extraDocs = (await getExtraCashflowDocs(monthIndex, year, firstDayCurrentMonth, firstDayNextMonth, daysInCurrentMonth)) || []
    generatedExtraDocs = [ ...generatedExtraDocs, ...extraDocs ]
    monthIndex--
    if (monthIndex === -1) {
      monthIndex = 11
      year--
    }
  } while (year >= 2022)
  return generatedExtraDocs
}

export const getExtraCashflowDocs = async(
  monthIndex,
  year,
  firstDayCurrentMonth,
  firstDayNextMonth,
  daysInCurrentMonth
) => {
  console.debug(
    "GETTING EXTRA CASHFLOW DOCS",
    monthIndex,
    year,
    firstDayCurrentMonth,
    firstDayNextMonth,
    daysInCurrentMonth
  )
  const extraDocs = await getDocs()
  const tempDocs = []

  extraDocs.forEach((el, index) => {
    if (el.type === "MONTHLY") {
      let docDate = el.dateIndex
      if (docDate > daysInCurrentMonth) {
        docDate = daysInCurrentMonth
      }
      const predefinedValue = el.values.find((val) => {
        if (
          firstDayCurrentMonth.getMonth() >= val.from.getMonth() &&
          firstDayCurrentMonth.getFullYear() >= val.from.getFullYear() &&
          (val.indefinite ||
            (!val.indefinite &&
              firstDayCurrentMonth.getMonth() <= val.to.getMonth() &&
              firstDayCurrentMonth.getFullYear() <= val.to.getFullYear()))
        )
          return val
        else return undefined
      })
      if (predefinedValue) {
        tempDocs.push({
          dueDate: new Date(firstDayCurrentMonth).setDate(docDate),
          direction: el.direction,
          label: el.label,
          project: el.project,
          type: el.type,
          total: predefinedValue.value,
          payment: {
            paid: true,
            paidConfirmed: true,
            payments: [],
            expectedPayment: [],
          },
        })
      }
    } else if (el.type === "WEEKLY") {
      const predefinedValue = el.values.find((val) => {
        //from before firstdaycurrent and indefinite or not indefinite and to after firstdaynext
        if (val.from <= firstDayCurrentMonth && ((val.to >= firstDayNextMonth && !val.indefinite) || val.indefinite))
          return val
        //from before firstdaycurrent and indefinite and to after firstdaycurrent but before firstdaynext
        else if (
          val.from <= firstDayCurrentMonth &&
          val.to >= firstDayCurrentMonth &&
          val.to <= firstDayNextMonth &&
          !val.indefinite
        )
          return val
        //from after firstdaycurrent and from before firstdaynext and
        //indefinite or not indefinite and to before/after firstdaynext
        else if (
          val.from >= firstDayCurrentMonth &&
          val.from <= firstDayNextMonth &&
          (val.indefinite ||
            (!val.indefinite && val.to <= firstDayNextMonth) ||
            (!val.indefinite && val.to >= firstDayNextMonth))
        )
          return val
        return undefined
      })
      if (predefinedValue) {
        //we look for all of the dates with el.dateIndex(dateIndex===day index, ie: monday=1,...)
        const day = el.dateIndex
        let firstDayOccurrence = 1
        const helperDate = new Date(year, monthIndex)
        //we find the first occurrence of specified day
        for (let i = 1; i <= daysInCurrentMonth; i++) {
          helperDate.setDate(i)
          if (helperDate.getDay() === day) {
            firstDayOccurrence = i
            break
          }
        }
        //we look for all the other occurrences in picked month
        // and we test if they
        helperDate.setDate(firstDayOccurrence)
        for (let i = firstDayOccurrence; i <= daysInCurrentMonth; i += 7) {
          const newDate = new Date(helperDate)
          newDate.setDate(i)
          if (
            newDate >= predefinedValue.from &&
            (predefinedValue.indefinite || (!predefinedValue.indefinite && newDate <= predefinedValue.to))
          ) {
            tempDocs.push({
              dueDate: newDate,
              direction: el.direction,
              label: el.label,
              project: el.project,
              type: el.type,
              total: predefinedValue.value,
              payment: {
                paid: true,
                paidConfirmed: true,
                payments: [],
                expectedPayment: [],
              },
            })
          }
        }
      }
    } else if (el.type === "DAILY") {
      const predefinedValue = el.values.find((val) => {
        if (val.from <= firstDayCurrentMonth && (val.indefinite || (!val.indefinite && val.to >= firstDayNextMonth)))
          return val
        else if (
          val.from >= firstDayCurrentMonth &&
          (val.indefinite || (!val.indefinite && val.from < firstDayNextMonth))
        )
          return val
        return undefined
      })
      if (predefinedValue) {
        let i, upperBound
        if (predefinedValue.from.getMonth() < monthIndex) i = 1
        else if (predefinedValue.from.getMonth() === monthIndex) i = predefinedValue.from.getDate()

        if (predefinedValue.to.getMonth() > monthIndex || predefinedValue.indefinite) upperBound = daysInCurrentMonth
        else if (predefinedValue.to.getMonth() === monthIndex) upperBound = predefinedValue.to.getDate()

        const helperDate = new Date(year, monthIndex, i)
        for (i; i <= upperBound; i++) {
          helperDate.setDate(i)
          if (![0, 6].includes(helperDate.getDay())) {
            tempDocs.push({
              dueDate: new Date(year, monthIndex, i),
              direction: el.direction,
              label: el.label,
              project: el.project,
              type: el.type,
              total: predefinedValue.value,
              payment: {
                paid: true,
                paidConfirmed: true,
                payments: [],
                expectedPayment: [],
              },
            })
          }
        }
      }
    } else if (el.type === cashFlowTypeEnum.ONE_TIME) {
      let docDate = el.values[0].from
      if (docDate.getMonth() === monthIndex && docDate.getFullYear() === year) {
        tempDocs.push({
          dueDate: el.values[0].from,
          direction: el.direction,
          label: el.label,
          project: el.project,
          type: el.type,
          total: el.values[0].value,
          payment: {
            paid: true,
            paidConfirmed: true,
            payments: [],
            expectedPayment: [],
          },
        })
      }
    }
  })
  return tempDocs
}

const getDocs = async () => {
  const docRef = await fb.apps[0].firestore().collection("extraCashflow").get()
  const docs = docRef.docs.map((doc) => {
    const obj = doc.data()
    return {
      ...obj,
      id: doc.id,
      values: obj.values.map((el) => {
        return { ...el, from: el?.from.toDate(), to: el.to ? el?.to.toDate() : undefined }
      }),
    }
  })
  console.debug("found", docs.length, " extra docs")

  return docs
}
