import { StatusBar, Platform } from "react-native"
import { DefaultTheme } from "react-native-paper"

const colors = {
  primary: "#788eec",
  success: "#8FEC78",
  danger: "#EC8E78",
  disabled: "grey",
  light: "#D3D3D3",
  black: "#2e2e2d",
  white: "white",
  placeholder: "#aaaaaa",
  borderPrimary: "#dedede",
  backgroundPrimary: "#ffffff",
  fabAdd: "rgba(0,198,80,0.8)",
  newItem: "#788eec",
}

const fonts = {
  small: 14,
  regular: 16,
  medium: 20,
  big: 32,
}

const padding = {
  s: 10,
  m: 20,
  l: 30,
}

const margin = {
  s: 10,
  m: 20,
  l: 30,
}

export default {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: colors.primary,
    disabled: colors.disabled,
    light: colors.light,
    black: colors.black,
    white: colors.white,
    placeholder: colors.placeholder,
    borderPrimary: colors.borderPrimary,
    backgroundPrimary: colors.backgroundPrimary,
    accent: colors.primary,
    fabAdd: colors.fabAdd,
    newItem: colors.newItem,
    headerPrimary: colors.white,
    headerSecondary: colors.black,
    danger: colors.danger,
    success: colors.success,
  },
  fab: {
    backgroundColor: "white",
    position: "absolute",
    margin: 32,
    right: 0,
    bottom: 0,
  },
  fabGroup: {
    position: "absolute",
    padding: 16,
    right: 0,
    bottom: 0,
  },
  fonts: {
    ...DefaultTheme.fonts,
    small: fonts.small,
    regular: fonts.regular,
    medium: fonts.medium,
    big: fonts.big,
  },
  hint: {
    fontStyle: "italic",
    color: colors.placeholder,
    paddingLeft: 10,
  },
  icons: {
    menuSize: 24,
    flatlistSize: 24,
    headerSizeLeft: 24,
    headerSizeRight: 30,
    s: 18,
    m: 24,
    l: 36,
  },
  input: {
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: colors.white,
    marginBottom: margin.s,
    marginHorizontal: margin.s,
    paddingLeft: padding.s,
    height: 65,
  },
  checkBoxesWrapper: {
    paddingVertical: 10,
    flexWrap: "wrap",
    flexDirection: Platform.OS === "web" ? "row" : "column",
  },
  checkBoxContainer: {
    backgroundColor: "transparent",
    margin: 2,
    padding: 5,
    borderWidth: 0,
  },
  link: {
    color: colors.primary,
  },
  margin: {
    s: margin.s,
    m: margin.m,
    l: margin.l,
  },
  padding: {
    s: padding.s,
    m: padding.m,
    l: padding.l,
  },
  safeTop: {
    marginTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
  },
  textInputHint: {
    position: "absolute",
    zIndex: 1,
    left: 32,
    top: 10,
    color: "#8f8f8f",
    fontSize: 12,
  },
  barStyle: {
    light: "light-content",
    dark: "dark-content",
  },
  headerStyle: {
    headerStyle: {
      backgroundColor: colors.white,
    },
    headerTintColor: colors.black,
  },
  card: {
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    paddingRight: 10,
    flexDirection: "row",
    backgroundColor: colors.white,
    borderLeftWidth: 10,
  },
}
