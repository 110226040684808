import React, { useEffect, useState } from "react"
import { FlatList, StyleSheet, Text, View } from "react-native"
import Screen from "../../components/Screen"
import RBTransactionDetail from "./RBTransactionDetail"
import { getAccountBalance, getTransactionList } from "../../actions/bankActions"
import { useData } from "../../providers/DataProvider"

export default function RBTransactionList({ navigation, route }) {
  const [transactionList, setTransactionList] = useState([])
  const [balance, setBalance] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const { firebaseConfig } = useData()

  console.log("TransactionList route.params", route.params)

  useEffect(() => {
    const getBalanceData = async () => {
      setIsLoading(true);
      try {
        const data = await getAccountBalance(firebaseConfig)
        console.log("getAccountBalance data", data)
        console.log("getAccountBalance balance", data.currencyFolders[0].balances[0].value)
        setBalance(data.currencyFolders[0].balances[0].value);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    getBalanceData().then(r => console.log("getBalanceData", r));
  }, []);

  useEffect(() => {
    const getTransactionListData = async () => {
      setIsLoading(true);
      try {
        const data = await getTransactionList(firebaseConfig)
        console.log("getTransactionList data", data)
        setTransactionList(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    getTransactionListData().then(r => console.log("getBalanceData", r));
  }, []);


  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error: {error}</Text>;
  }

  return (
    <Screen>
      <View style={styles.container}>
        <Text>Balance: {balance} CZK</Text>
        <FlatList
          data={transactionList}
          keyExtractor={item => item?.id?.toString()}
          renderItem={({ item }) => (
            <RBTransactionDetail transactionItem={item} />
          )}
        />
      </View>
    </Screen>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 50,
  },
  transactionItem: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  transactionText: {
    fontSize: 16,
  },
})
