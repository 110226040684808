import React, { useState, useEffect } from "react"
import Screen from "../../components/Screen"
import { FlatList, View, Text, TouchableOpacity, StyleSheet } from "react-native"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import { ActivityIndicator } from "react-native-paper"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useData } from "../../providers/DataProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"

export default function TriviDocsNotes({ navigation }) {
  const [docs, setDocs] = useState(null)
  const { projects } = useData()

  useEffect(() => {
    const getDocs = async () => {
      if (projects) {
        const triviDocs = await FSStoreContent.getTriviDocsForNotes(projects)
        setDocs(triviDocs)
        console.debug("triviDOcs: ", triviDocs)
      } else setDocs([])
    }
    getDocs()
  }, [projects])

  const renderItem = ({ item }) => {
    return (
      <TouchableOpacity
        style={styles.card}
        onPress={() => navigation.navigate("TriviDocNotesDetail", { docId: item.id, notes: item._extracting.notes })}
      >
        <View style={{ flexDirection: "column", justifyContent: "center" }}>
          <Text style={{ fontWeight: "bold" }}>{"ID Trivi dokumentu: " + item.id}</Text>
          <Text>{"Projekt: " + item.preferredProject}</Text>
        </View>
      </TouchableOpacity>
    )
  }

  return (
    <Screen>
      {!docs ? (
        <ActivityIndicator />
      ) : (
        <FlatList
          ListEmptyComponent={<AppEmptyScreenView message="Nebyly nalezeny žádné dokumenty" />}
          renderItem={renderItem}
          data={docs}
          ItemSeparatorComponent={FlatListSeparator}
        />
      )}
    </Screen>
  )
}

const styles = StyleSheet.create({
  card: {
    height: 100,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    paddingRight: 10,
    flexDirection: "row",
    backgroundColor: DefaultTheme.colors.white,
    borderLeftWidth: 10,
    borderLeftColor: DefaultTheme.colors.primary,
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
  name: {
    paddingLeft: 10,
    marginRight: "auto",
  },
  monthTitle: {
    paddingLeft: 10,
  },
})
