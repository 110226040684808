import React from "react"
import { TouchableOpacity, Text, View, StyleSheet } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import { FontAwesome5 } from "@expo/vector-icons"
import CurrencyFormatter from "../CurrencyFormatter"
import { isDocFinal } from "../../utils/general"

export default function FlatListProjectAccountingItem({ document, accDocs, setAccDocs, disabled }) {
  function handlePress() {
    setAccDocs(
      accDocs.map((doc) =>
        doc.id === document.id ? { ...doc, internalInfo: { isSyncing: !doc.internalInfo?.isSyncing } } : doc
      )
    )
  }

  return (
    <TouchableOpacity onPress={() => handlePress()} style={style.card} disabled={disabled}>
      <View style={{ justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
        <FontAwesome5
          name="file-invoice-dollar"
          size={DefaultTheme.icons.flatlistSize}
          color={document.internalInfo?.isSyncing ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled}
        />
        <Text style={{ textAlign: "center", paddingLeft: 10 }}>{document.accountingDocumentNo}</Text>
      </View>
      <Text>
        {CurrencyFormatter(
          (isDocFinal(document) ? document.total : document.totalVatExcl) +
            (document.totalVatExcl > 0 // temporary before user vies for custom action is created
              ? document.lineItems?.reduce((a, c) => (c.totalVatExcl < 0 ? c.totalVatExcl : 0) + a, 0) || 0
              : 0),
          document.price.currency,
          isDocFinal(document) ? 1 : 0
        )}
      </Text>
    </TouchableOpacity>
  )
}

const style = StyleSheet.create({
  card: {
    paddingHorizontal: 10,
    height: 45,
    backgroundColor: DefaultTheme.colors.backgroundPrimary,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
})
