import React, { useEffect, useState } from "react"
import { Image, ScrollView, Text, View } from "react-native"
import { TextInput } from "react-native-paper"

import Screen from "../../components/Screen"
import DefaultTheme from "../../themes/DefaultTheme"
import NextButton from "../../components/NextButton"
import FullWidthImage from "../../components/FullWidthImage"
import { useData } from "../../providers/DataProvider"
import AppPicker from "../../components/AppPicker"
import { projectStateEnum, statusEnum } from "../../data/enums"
import { useStatus } from "../../providers/StatusProvider"
import FSStoreContent from "../../firebase/FSStoreContent"

export default function DiaryTitleScreen({ navigation, route }) {
  const { setStatus, setTitle, setMessage } = useStatus()
  const { projects, settings } = useData()
  const [diary, setDiary] = useState({})
  const [isProjectClosed, setIsProjectClosed] = useState(false)
  const [templates, setTemplates] = useState([])
  const [templateUri, setTemplateUri] = useState("")
  const [loadingImage, setLoadingImage] = useState(true)
  const [projectName, setProjectName] = useState("")
  console.debug("DIARY", diary)

  useEffect(() => {
    const tempDiary = projects.find((p) => p.id === route.params.projectId).diary || {}
    const projectName = projects.find((p) => p.id === route.params.projectId)?.name
    setProjectName(projectName)
    setDiary({ contractor: "DASAR PR", title: projectName, title_template_id: "", ...tempDiary })
    setIsProjectClosed(projects.find((p) => p.id === route.params.projectId)?.state === projectStateEnum.CLOSED)
  }, [projects])

  useEffect(() => {
    const tempTemplates = settings.diary?.predefined_title_images || []
    setTemplates(tempTemplates)
    setTemplateUri(tempTemplates.find((t) => t.id === diary.title_template_id)?.filestore_uri)
  }, [settings])

  const pickerItems = templates.map((t) => ({ key: t.id, value: t.id, label: t.title }))

  console.debug("PROJECT ID", route.params.projectId)
  async function handleSave() {
    try {
      setTitle("Ukládám titulní stranu deníku")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      await FSStoreContent.updateProject({ diary, id: route.params?.projectId })

      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  return (
    <Screen>
      <View style={{ flex: 2, overflow: "hidden" }}>
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          {templateUri || !loadingImage ? (
            <FullWidthImage uri={templateUri} onLoadEnd={() => setLoadingImage(false)} />
          ) : (
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <Text style={{ fontSize: DefaultTheme.fonts.regular }}>
                {templateUri
                  ? "Šablona se načítá"
                  : templates.length > 0
                  ? "Vyberte prosím šablonu"
                  : "Neexistují žádné šablony. Nahrajte je prosím v nastavení aplikace."}
              </Text>
            </View>
          )}
        </ScrollView>
      </View>
      <View style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{ flex: 1 }} style={{ paddingVertical: 10 }}>
          <AppPicker
            descriptionLabel="Šablona"
            disabled={isProjectClosed}
            value={diary.title_template_id}
            placeholder={{ label: "Vyberte šablonu", value: "" }}
            onValueChange={(value) => {
              setDiary({ ...diary, title_template_id: value || "" })
              setTemplateUri(templates.find((t) => t.id === value)?.filestore_uri)
              if (!value) setLoadingImage(true)
            }}
            items={pickerItems}
          />
          <TextInput style={DefaultTheme.input} label="Zhotovitel" value={diary.contractor} disabled />
          <TextInput style={DefaultTheme.input} label="Název stavby" value={projectName} disabled />
          <View style={{ height: 10 }} />
        </ScrollView>
      </View>
      <NextButton
        disabled={!(diary.contractor && diary.title_template_id) || isProjectClosed}
        onPress={handleSave}
        text="Uložit"
      />
    </Screen>
  )
}
