import { Text, View, TouchableOpacity } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import { toCurrentDate } from "../../utils/general"
import CurrencyFormatter from "../CurrencyFormatter"
import { dayNameLabels } from "../../data/labels"

export default function FlatListExtraCashflow({ item, navigation }) {
  const itemTypes = {
    WEEKLY: "Týdně",
    DAILY: "Denně",
    MONTHLY: "Měsíčně",
    ONE_TIME: "Jednorázově",
  }
  console.debug("ITEM: ", item)
  return (
    <TouchableOpacity
      style={[
        DefaultTheme.card,
        {
          marginVertical: 5,
          height: 40,
          borderLeftColor: item.direction === 0 ? DefaultTheme.colors.danger : DefaultTheme.colors.success,
        },
      ]}
      onPress={() => navigation.navigate("ExtraCashflowDetail", { document: item, type: "EXISTING" })}
    >
      <View style={{ flexDirection: "column", alignItems: "left" }}>
        <Text>{ item.label }</Text>
        <Text>
          { itemTypes[item.type] }
          {} - [ {}
          { item.type === "ONE_TIME" ? toCurrentDate(item.values[0].from, "", "") : "" }
          { item.type === "WEEKLY" ? dayNameLabels[item?.dateIndex] : ""}
          { item.type === "MONTHLY" ? item.dateIndex : ""}
          { item.type === "DAILY" ? item.dateIndex : ""}
          {} ] {}
          { item.type !== "ONE_TIME" ?
              (item.values[0].indefinite === false ?
                  ("- DOČASNÁ od " + toCurrentDate(item.values[0].from) + " do " + toCurrentDate(item.values[0].to))
                : ("-  TRVALÁ od " + toCurrentDate(item.values[0].from))
              )
              : ( "" )
          }
        </Text>
      </View>
      <Text>{ (item.direction === 0 ? "- " : "+ ") + CurrencyFormatter(item?.values[item.values.length - 1].value, "Kč", -1)}</Text>
    </TouchableOpacity>
  )
}
