import React, { useEffect, useState } from "react"
import { useNavigation } from "@react-navigation/core"
import { FlatList } from "react-native"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import FlatListAccountingDocumentItem from "../../components/FlatLists/FlatListAccountingDocumentItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import Screen from "../../components/Screen"
import { documentBulkActionEnum, documentViewTypeEnum, paymentEnum, statusEnum, viewTypeEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"
import AppPicker from "../../components/AppPicker"
import { documentBulkActionLabels } from "../../data/labels"
import NextButton from "../../components/NextButton"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useStatus } from "../../providers/StatusProvider"

export default function CashDeskMonthBulkAction({ route }) {
  const { currentUser, accountingDocuments } = useData()
  const { setTitle, setMessage, setStatus } = useStatus()
  const navigation = useNavigation()
  const user = route.params?.user || currentUser
  const [filteredAccDocs, setFilteredAccDocs] = useState([])
  const [selectedAction, setSelectedAction] = useState(documentBulkActionEnum.DELETE)

  const appPickerActionOptions = [
    { label: documentBulkActionLabels[documentBulkActionEnum.DELETE], value: documentBulkActionEnum.DELETE },
  ]

  useEffect(() => {
    setFilteredAccDocs(getFilteredDocs())
  }, [selectedAction, accountingDocuments])

  function getFilteredDocs() {
    const monthIndex = route.params?.monthIndex || 0
    let cashDeskYearMonth = new Date()
    cashDeskYearMonth.setMonth(cashDeskYearMonth.getMonth() - monthIndex)
    cashDeskYearMonth = `${cashDeskYearMonth.getFullYear()}_${cashDeskYearMonth.getMonth()}`

    return accountingDocuments.filter((doc) => {
      const filterConditions =
        `${doc.date.toDate().getFullYear()}_${doc.date.toDate().getMonth()}` === cashDeskYearMonth &&
        user.email === doc.created_by &&
        doc.payment !== paymentEnum.BANK_TRANSFER

      return filterConditions && (selectedAction === documentBulkActionEnum.DELETE ? !doc.locked : true)
    })
  }

  async function handleSave() {
    try {
      setTitle("Vymazání dokladů")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      for (const doc of filteredAccDocs) {
        if (doc.isSelected) {
          await FSStoreContent.deleteDocument(doc.id)
        }
        // if another action then DELETE, the isSelected prop has to be deleted from doc before saving to firestore
      }
      setMessage("Doklady byly uspěšně vymazány!")
      setStatus(statusEnum.SUCCESS)
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
  }

  return (
    <Screen style={{ paddingTop: 10 }}>
      <AppPicker
        value={selectedAction}
        descriptionLabel="Akce"
        placeholder={{}}
        onValueChange={(action) => setSelectedAction(action)}
        items={appPickerActionOptions}
      />
      {filteredAccDocs.length > 0 ? (
        <FlatList
          data={filteredAccDocs}
          renderItem={({ item }) => (
            <FlatListAccountingDocumentItem
              document={item}
              navigation={navigation}
              toScreen="CashDeskMonthDetail"
              viewType={viewTypeEnum.BULK_ACTION}
              accDocs={filteredAccDocs}
              setAccDocs={setFilteredAccDocs}
            />
          )}
          keyExtractor={(item) => item.id.toString()}
          ItemSeparatorComponent={FlatListSeparator}
          extraData={filteredAccDocs}
        />
      ) : (
        <AppEmptyScreenView message="V tomto měsíci zatím nejsou žádné doklady" />
      )}
      <NextButton text="Smazat" onPress={handleSave} disabled={!filteredAccDocs.some((doc) => doc.isSelected)} />
    </Screen>
  )
}
