import { firebase } from "../firebase/config"
import Constants from "expo-constants"
import * as Linking from "expo-linking"
import { statusEnum } from "../data/enums"
import {
  exportDocsByProjectUrl,
  getBucketName,
  getExportAttendanceMonthUrl,
  getExportAttendanceUrl, getExportDiaryUrl,
  getExportLogBookMonthUrl,
  getExportLogBookVehiclesUrl,
  getExportPropertyUrl, getImportPropertyUrl,
} from "../utils/backendUrls"

export async function exportMonthAttendance(monthIndex, userEmail, configId) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()

    console.debug("TOKEN", token)

    const url = getExportAttendanceMonthUrl(configId)

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        monthIndex: monthIndex,
        userEmail,
        bucketName: getBucketName(configId),
      }),
    })

    if (response.status !== 200) {
      console.debug("RES", response.status)
      throw await response.json()
    }

    const { downloadURL } = await response.json()

    await Linking.openURL(downloadURL)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function exportDocsByProject({ project }, configId) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()

    console.debug("TOKEN", token)

    const url = exportDocsByProjectUrl(configId)
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        projectId: project.id,
        bucketName: getBucketName(configId),
      }),
    })

    const { downloadURL } = await response.json()
    console.debug("DOWNLOAD URI", downloadURL)
    await Linking.openURL(downloadURL)
  } catch (error) {
    throw error
  }
}

export async function exportMonthLogBook(monthLogBook, configId) {
  return new Promise(async (resolve) => {
    try {
      const token = await firebase.apps[0].auth().currentUser.getIdToken()

      const url = getExportLogBookMonthUrl(configId)

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          userLogBookEmail: monthLogBook.user,
          monthlyId: monthLogBook.id,
          bucketName: getBucketName(configId),
        }),
      })

      const { downloadURL } = await response.json()
      console.debug("DOWNLOAD URI", downloadURL)
      Linking.openURL(downloadURL)
      resolve({ message: "Export knihy jízd proběhl úspěšně!", status: statusEnum.SUCCESS })
    } catch (error) {
      console.error(error)
      resolve({ message: error.message, status: statusEnum.ERROR })
    }
  })
}

export async function exportLogBookVehicles(dateInterval, configId) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()

    const url = getExportLogBookVehiclesUrl(configId)

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        bucketName: getBucketName(configId),
        dateInterval,
      }),
    })

    const { downloadURL } = await response.json()
    console.debug("DOWNLOAD URI", downloadURL)
    Linking.openURL(downloadURL)
    return { message: "Export stavebního deníku proběhl úspěšně!", status: statusEnum.SUCCESS }
  } catch (error) {
    console.error(error)
    throw { message: error.message, status: statusEnum.ERROR }
  }
}

export async function exportAttendance(dateInterval, configId) {
  console.debug("exportAttendance dateInterval", dateInterval)
  console.debug("exportAttendance configId", configId)

  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()

    const url = getExportAttendanceUrl(configId)

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        bucketName: getBucketName(configId),
        dateInterval,
      }),
    })

    const { downloadURL } = await response.json()
    console.debug("DOWNLOAD URI", downloadURL)
    Linking.openURL(downloadURL)
    return { message: "Export docházky proběhl úspěšně!", status: statusEnum.SUCCESS }
  } catch (error) {
    console.error(error)
    throw { message: error.message, status: statusEnum.ERROR }
  }
}

export async function exportProperty(userEmail, configId) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()

    const url = getExportPropertyUrl(configId)

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        userEmail: userEmail,
        bucketName: getBucketName(configId),
      }),
    })

    if (response.status !== 200) {
      throw `Chyba při exportu majetku: ${response.status}`
    }

    const { downloadURL } = await response.json()
    console.debug("DOWNLOAD URI", downloadURL)

    await Linking.openURL(downloadURL)
    return { message: "Export majetku proběhl úspěšně!" }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function importProperty(fileName, configId) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()

    const url = getImportPropertyUrl(configId)
    console.debug("URL", url)

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        fileName,
        bucketName: getBucketName(configId),
      }),
    })
    const result = await response.json()

    if (response.status !== 200) {
      throw result
    }

    return result
  } catch (error) {
    console.error("ERR", error)
    throw error
  }
}

export async function exportDiary(projectId, dateInterval, configId) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()

    const url = getExportDiaryUrl(configId)
    console.debug("URL: ", url)

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        project: projectId,
        dateInterval,
        bucketName: getBucketName(configId),
      }),
    })

    if (response.status !== 200) {
      throw `Chyba při exportu stavebního deníku: ${response.status}`
    }

    const { downloadURL } = await response.json()
    console.debug("DOWNLOAD URI", downloadURL)

    await Linking.openURL(downloadURL)
    return {
      message: !dateInterval?.from
        ? "Export stavebního deníku proběhl úspěšně!"
        : "Export denních záznamů proběhl úspěšně!",
      status: statusEnum.SUCCESS,
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
