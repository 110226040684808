import React from "react"
import { View, StyleSheet } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"

export default function FlatListSeparator() {
  return <View style={styles.separator} />
}

const styles = StyleSheet.create({
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: DefaultTheme.colors.borderPrimary,
  },
})
