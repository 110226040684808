import React, { useState } from "react"
import { TouchableOpacity } from "react-native"
import { Menu } from "react-native-paper"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import { exportMonthLogBook } from "../../actions/headerItemActions"
import { useStatus } from "../../providers/StatusProvider"
import { statusEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"

export default function LogBookMonthDetailHeaderMenu({ params, navigation }) {
  const [showMenu, setShowMenu] = useState(false)
  const { setTitle, setStatus, setMessage } = useStatus()
  const { firebaseConfig } = useData()

  async function handleExport() {
    setTitle("Generuji jízdy do CSV souboru")

    if (params.monthLogBook.logs?.length > 0) {
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      const { message, status: exportStatus } = await exportMonthLogBook(params.monthLogBook, firebaseConfig)

      console.debug("EXPORTED STATUS", exportStatus)
      setStatus(exportStatus)
      setMessage(message)
    } else {
      setStatus(statusEnum.ERROR)
      setMessage("Měsíc neobsahuje žádnou jízdu!")
      navigation.navigate("StatusScreen")
    }
  }

  return (
    <Menu
      visible={showMenu}
      onDismiss={() => setShowMenu(false)}
      anchor={
        <TouchableOpacity onPress={() => setShowMenu(true)} style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.headerSecondary}
          />
        </TouchableOpacity>
      }
    >
      <Menu.Item
        style={{ direction: "ltr" }}
        onPress={() => {
          setShowMenu(false), handleExport()
        }}
        title="Export knihy jízd"
        icon={() => (
          <MaterialCommunityIcons
            name="file-export"
            size={DefaultTheme.icons.menuSize}
            color={DefaultTheme.colors.primary}
          />
        )}
      />
    </Menu>
  )
}
