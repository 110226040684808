import React, { useState } from "react"
import { TouchableOpacity, View } from "react-native"
import { Menu } from "react-native-paper"
import { MaterialCommunityIcons, FontAwesome5, FontAwesome, MaterialIcons, Ionicons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import { useNavigation } from "@react-navigation/native"
import { documentTypeEnum } from "../../data/enums"
import { exportDiary } from "../../actions/headerItemActions"
import { useStatus } from "../../providers/StatusProvider"
import { statusEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"

export default function ProjectDiaryDetailHeaderMenu({ params }) {
  const navigation = useNavigation()
  const [showMenu, setShowMenu] = useState(false)
  const { setTitle, setStatus, setMessage } = useStatus()

  const { firebaseConfig } = useData()
  const projectId = params.projectId

  async function handleExportDiary() {
    try {
      setTitle("Generuji stavební deník do PDF")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      const { message, status: exportStatus } = await exportDiary(projectId, {}, firebaseConfig)

      console.debug("EXPORTED STATUS", exportStatus)
      setStatus(exportStatus)
      setMessage(message)
      navigation.pop()
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
  }

  return (
    <Menu
      visible={showMenu}
      onDismiss={() => setShowMenu(false)}
      anchor={
        <TouchableOpacity onPress={() => setShowMenu(true)} style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.headerSecondary}
          />
        </TouchableOpacity>
      }
    >
      <Menu.Item
        onPress={() => {
          setShowMenu(false), navigation.navigate("DiaryTitleScreen", params)
        }}
        title="Titulní stránka"
        icon={() => (
          <MaterialIcons
            name="title"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.primary}
            style={{ alignSelf: "center" }}
          />
        )}
      />
      <Menu.Item
        onPress={() => {
          setShowMenu(false), navigation.navigate("DiaryMainDirectory", params)
        }}
        icon={() => (
          <FontAwesome
            name="address-book"
            size={DefaultTheme.icons.headerSizeRight - 3}
            color={DefaultTheme.colors.primary}
            style={{ alignSelf: "center" }}
          />
        )}
        title="Základní adresář"
      />
      <Menu.Item
        onPress={() => {
          setShowMenu(false),
            navigation.navigate("DiaryDocumentOverview", { ...params, viewType: documentTypeEnum.CONTRACT })
        }}
        icon={() => (
          <FontAwesome5
            name="file-contract"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.primary}
            style={{ alignSelf: "center" }}
          />
        )}
        title="Smlouvy"
      />
      <Menu.Item
        onPress={() => {
          setShowMenu(false),
            navigation.navigate("DiaryDocumentOverview", { ...params, viewType: documentTypeEnum.DOCUMENTATION })
        }}
        icon={() => (
          <Ionicons
            name="document-text"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.primary}
            style={{ alignSelf: "center" }}
          />
        )}
        title="Dokumentace"
      />
      <Menu.Item
        onPress={() => {
          setShowMenu(false), navigation.navigate("DiaryBulkSignature", { projectId, currentUser: params.currentUser })
        }}
        title="Hromadný podpis"
        icon={() => (
          <FontAwesome5
            name="file-signature"
            size={DefaultTheme.icons.headerSizeRight - 3}
            color={DefaultTheme.colors.primary}
            style={{ marginLeft: 10, alignSelf: "center" }}
          />
        )}
      />
      <Menu.Item
        onPress={() => {
          setShowMenu(false), navigation.navigate("DiaryExport", { projectId })
        }}
        icon={() => (
          <MaterialCommunityIcons
            name="file-export"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.primary}
            style={{ alignSelf: "center" }}
          />
        )}
        title="Export denních záznamů"
      />
      <Menu.Item
        onPress={() => {
          setShowMenu(false), handleExportDiary()
        }}
        icon={() => (
          <MaterialCommunityIcons
            name="file-export"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.primary}
          />
        )}
        title="Export deníku"
      />
    </Menu>
  )
}
