import DefaultTheme from "../themes/DefaultTheme"
import {
  documentStateEnum,
  monthStateEnum,
  statusEnum,
  attendanceTypeEnum,
  approveEnum,
  paymentStateEnum,
} from "./enums"

export const STATE_VIEW_PARAMS_DOCUMENT = {
  [documentStateEnum.ACCEPTED]: { name: "check", color: "green" },
  [documentStateEnum.REJECTED]: { name: "remove", color: "red" },
  [documentStateEnum.PENDING]: { name: "clock-o", color: "orange" },
  [`${documentStateEnum.ACCEPTED}_LOCKED`]: { name: "check", color: DefaultTheme.colors.disabled },
  [`${documentStateEnum.REJECTED}_LOCKED`]: { name: "remove", color: DefaultTheme.colors.disabled },
  [`${documentStateEnum.PENDING}_LOCKED`]: { name: "clock-o", color: DefaultTheme.colors.disabled },
  [statusEnum.ERROR]: { name: "remove", color: "red" },
  [statusEnum.SUCCESS]: { name: "check", color: "green" },
  [`${documentStateEnum.ACCEPTED}_OPERATIONS`]: { name: "check", color: "green" },
  [`${documentStateEnum.REJECTED}_OPERATIONS`]: { name: "remove", color: "red" },
  [`${documentStateEnum.PENDING}_OPERATIONS`]: { name: "clock-o", color: "orange" },
  [paymentStateEnum.COMPLETED]: { name: "check", color: "green" },
  [paymentStateEnum.FAILED]: { name: "remove", color: "red" },
  [paymentStateEnum.PENDING]: { name: "clock-o", color: "orange" },
}

export const STATE_VIEW_PARAMS_PREMIUM = {
  [approveEnum.ACCEPTED]: { name: "check", color: "green" },
  [approveEnum.REJECTED]: { name: "remove", color: "red" },
  [approveEnum.PENDING]: { name: "clock-o", color: "orange" },
  false: { name: "cash-multiple", color: DefaultTheme.colors.primary },
  true: { name: "cash-multiple", color: DefaultTheme.colors.disabled },
}

export const VIEW_PARAMS_HEADER_LEFT = {
  ["bars"]: {
    name: "bars",
    size: DefaultTheme.icons.headerSizeLeft,
    color: DefaultTheme.colors.headerSecondary,
  },
}

export const STATE_VIEW_PARAMS_ITEM = {
  [approveEnum.ACCEPTED]: { name: "check", color: "green" },
  [approveEnum.REJECTED]: { name: "remove", color: "red" },
  [approveEnum.PENDING]: { name: "clock-o", color: "orange" },
  false: { color: DefaultTheme.colors.primary },
  true: { color: DefaultTheme.colors.disabled },
}

export const STATE_VIEW_PARAMS_USER_CASH_DESK = {
  false: { name: "cash-register", color: DefaultTheme.colors.primary },
  true: { name: "cash-register", color: DefaultTheme.colors.disabled },
}

export const STATE_VIEW_PARAMS_USER_ATTENDANCE = {
  false: { name: "clock", color: DefaultTheme.colors.primary },
  true: { name: "clock", color: DefaultTheme.colors.disabled },
}

export const STATE_VIEW_PARAMS_LOG_BOOK = {
  false: { name: "suitcase", color: DefaultTheme.colors.primary },
  true: { name: "suitcase", color: DefaultTheme.colors.disabled },
}

export const STATE_VIEW_PARAMS_LOG_BOOK_CAR = {
  false: { name: "car", color: DefaultTheme.colors.primary },
  true: { name: "car", color: DefaultTheme.colors.disabled },
}

export const STATE_VIEW_PARAMS_PROPERTY = {
  false: { name: "clipboard-text", color: DefaultTheme.colors.primary },
  true: { name: "clipboard-text", color: DefaultTheme.colors.disabled },
}

export const STATE_VIEW_PARAMS_CLOSING_MONTH = {
  [monthStateEnum.OPEN]: { name: "lock-open-variant", color: DefaultTheme.colors.primary },
  [monthStateEnum.CLOSED]: { name: "lock", color: DefaultTheme.colors.disabled },
}

export const STATE_VIEW_PARAMS_MONTH = {
  [monthStateEnum.OPEN]: { color: DefaultTheme.colors.primary },
  [monthStateEnum.CLOSED]: { color: DefaultTheme.colors.disabled },
}

export const STATE_VIEW_PARAMS_USER = {
  false: { name: "person-sharp", color: DefaultTheme.colors.primary, size: DefaultTheme.icons.flatlistSize },
  true: { name: "person-sharp", color: DefaultTheme.colors.disabled, size: DefaultTheme.icons.flatlistSize },
  deleted: { name: "person-sharp", color: DefaultTheme.colors.danger, size: DefaultTheme.icons.flatlistSize },
}

export const STATE_VIEW_PARAMS_ATTENDANCE_DAY = {
  [attendanceTypeEnum.WORK]: { name: "hammer-wrench" },
  [attendanceTypeEnum.HOLIDAY]: { name: "white-balance-sunny" },
  [attendanceTypeEnum.SICKDAY]: { name: "hospital-box" },
  [attendanceTypeEnum.HOMEOFFICE]: { name: "home-account" },
  [attendanceTypeEnum.NOT_STATED_PAST]: { name: "" },
  [attendanceTypeEnum.NOT_STATED_FUTURE]: { name: "" },
}
