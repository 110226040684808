import React, { useEffect, useState } from "react"
import { StyleSheet, FlatList } from "react-native"
import Screen from "../../components/Screen"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import { FAB } from "react-native-paper"
import DefaultTheme from "../../themes/DefaultTheme"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import FlatListDocumentItem from "../../components/FlatLists/FlatListDocumentItem"
import { actionEnum, scanTypeEnum, documentViewTypeEnum, statusEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"
import { openFileStorage, openLibrary } from "../../actions/platformFeatureRequestor"
import { useStatus } from "../../providers/StatusProvider"

export default function DocumentsOverview({ navigation, route }) {
  const { setStatus, setMessage } = useStatus()
  const { companyDocuments, currentUser } = useData()
  const [isFABOpen, setIsFABOpen] = useState(false)
  const [filteredDocs, setFilteredDocs] = useState([])

  const viewType = route.params?.viewType
  const belongsTo = route.params?.belongsTo || ""
  const name = route.params?.name

  useEffect(() => {
    if (viewType === documentViewTypeEnum.COMPANY) {
      setFilteredDocs(companyDocuments.filter((doc) => !doc.belongs_to?.includes("@")))
    } else if (viewType === documentViewTypeEnum.USER) {
      setFilteredDocs(companyDocuments.filter((doc) => doc.belongs_to === belongsTo))
    }
  }, [companyDocuments, viewType])

  async function handleOpenPdf() {
    try {
      const { localUri, fileExt } = await openFileStorage()
      console.debug("LOCAL URI", localUri)

      if (localUri) {
        if (!(fileExt === "pdf" || fileExt == "PDF")) {
          throw { message: "Soubor musí být formátu pdf!" }
        }

        navigation.navigate("VerifyScan", {
          belongsTo,
          name,
          type: scanTypeEnum.FILE,
          uriList: [localUri],
          viewType: documentViewTypeEnum.COMPANY,
        })
      }
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  async function handleOpenLibrary() {
    try {
      const localUri = await openLibrary()
      console.debug("LOCAL URI", localUri)

      if (localUri) {
        navigation.navigate("ScansOverview", {
          type: scanTypeEnum.IMAGE,
          uriList: [localUri],
          viewType: documentViewTypeEnum.COMPANY,
          belongsTo,
          name,
        })
      }
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  return (
    <Screen>
      {filteredDocs.length > 0 ? (
        <FlatList
          data={filteredDocs}
          renderItem={({ item }) => <FlatListDocumentItem document={item} />}
          extraData={companyDocuments}
          ItemSeparatorComponent={FlatListSeparator}
          keyExtractor={(item) => item.id.toString()}
        />
      ) : (
        <AppEmptyScreenView message="Nemáte žádné dokumenty" />
      )}
      <FAB.Group
        style={DefaultTheme.fabGroup}
        color={DefaultTheme.colors.fabAdd}
        icon="plus"
        fabStyle={{ backgroundColor: DefaultTheme.colors.white }}
        visible={
          currentUser.roles.includes("EXECUTIVE") ||
          currentUser.roles.includes("OFFICE_MANAGER") ||
          currentUser.roles.includes("ADMIN")
        }
        loading={true}
        open={isFABOpen}
        onStateChange={(state) => setIsFABOpen(state.open)}
        actions={[
          {
            icon: "camera",
            onPress: () =>
              navigation.navigate("CaptureImage", {
                viewType: documentViewTypeEnum.COMPANY,
                belongsTo,
                action: actionEnum.NEW,
              }),
          },
          {
            icon: "image",
            onPress: handleOpenLibrary,
          },
          {
            icon: "file-pdf-box",
            onPress: handleOpenPdf,
          },
        ]}
      />
    </Screen>
  )
}
