import React, { useEffect, useState } from "react"
import { TouchableOpacity, StyleSheet, Text, View, Image, ActivityIndicator } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import { toCurrentDate } from "../../utils/general"
import { useData } from "../../providers/DataProvider"
import { STATE_VIEW_PARAMS_LOG_BOOK_CAR } from "../../data/params"
import { MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons"
import FSStoreContent from "../../firebase/FSStoreContent"
import NumberFormatter from "../NumberFormatter"

export default function FlatListLogBookItem({ log, navigation, disabled, monthLogBook }) {
  const { property, setProperty } = useData()
  const [vehicle, setVehicle] = useState({})
  const [isLoadingProp, setisLoadingProp] = useState(false)
  const [isAccommodation, setIsAccommodation] = useState(false)

  useEffect(() => {
    setisLoadingProp(true)
    FSStoreContent.onSnapshotProperty(setProperty)
    setisLoadingProp(false)
    ;(async () => setIsAccommodation(await FSStoreContent.loadDocument(log.document_id)))()
  }, [])

  useEffect(() => {
    setVehicle(
      property.find((p) => (log.vehicle_id ? p.vehicle_id === log.vehicle_id : p.licence_plate === log.licence_plate))
    )
  }, [log, property])

  return (
    <TouchableOpacity
      style={[styles.card, { borderLeftColor: disabled ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary }]}
      onPress={() => navigation.navigate("LogDetail", { log, disabled, monthLogBook, vehicle })}
    >
      {disabled && (
        <FontAwesome name="lock" color={DefaultTheme.colors.disabled} size={DefaultTheme.icons.flatlistSize} />
      )}
      <View style={{ flex: 1.5, paddingVertical: 5, justifyContent: "space-between" }}>
        {isLoadingProp ? (
          <ActivityIndicator color={DefaultTheme.colors.primary} />
        ) : vehicle?.filestore_uri ? (
          <Image source={{ uri: vehicle.filestore_uri }} style={{ height: 90 }} resizeMode="contain" />
        ) : (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <MaterialCommunityIcons
              {...STATE_VIEW_PARAMS_LOG_BOOK_CAR[disabled]}
              size={DefaultTheme.icons.flatlistSize}
              style={{ paddingRight: 10 }}
            />
            <Text>Nahrajte prosím obrázek vozidla</Text>
          </View>
        )}
      </View>
      <View style={{ flex: 2, alignItems: "flex-end", paddingHorizontal: 10 }}>
        <Text>
          SPZ:{" "}
          {isLoadingProp ? (
            <ActivityIndicator color={DefaultTheme.colors.primary} />
          ) : (
            <Text style={{ fontWeight: "bold" }}>{vehicle?.licence_plate_editable || vehicle?.licence_plate}</Text>
          )}
        </Text>
        <Text>{`${toCurrentDate(log.date)}`}</Text>
        <Text>{`Začátek:  ${NumberFormatter(log.start_km)} km`}</Text>
        <Text>{`Konec:  ${NumberFormatter(log.end_km)} km`}</Text>
        <Text>{`Rozdíl:  ${NumberFormatter(log.end_km - log.start_km)} km`}</Text>
        <Text>{`Ubytování:  ${isAccommodation ? "Ano" : "Ne"}`}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    height: 130,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    flexDirection: "row",
    borderLeftWidth: 10,
    borderLeftColor: DefaultTheme.colors.primary,
    backgroundColor: DefaultTheme.colors.white,
  },
})
