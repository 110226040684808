import React, { useState } from "react"
import { View, Text } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import Screen from "../../components/Screen"
import { scanTypeEnum } from "../../data/enums"
import DocumentImage from "../../components/DocumentImage"

export default function DocumentDetail({ route }) {
  const [document, setDocument] = useState(route.params.document || {})
  const [imageLoading, setImageLoading] = useState(route.params.document?.type === scanTypeEnum.IMAGE)

  console.debug("DOCUMENT", document)

  return (
    <Screen>
      {(imageLoading || document.filestore_uri_list?.length === 0) && (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <Text style={{ fontSize: DefaultTheme.fonts.regular }}>
            {document.filestore_uri_list?.length > 0
              ? "Načítám obrázek dokumentu"
              : "Nahrajte prosím obrázek dokumentu"}
          </Text>
        </View>
      )}
      <View
        style={[
          { flex: 1, overflow: "hidden" },
          (imageLoading || !document.filestore_uri_list?.length === 0) && { transform: [{ scale: 0 }], flex: 0 },
        ]}
      >
        <DocumentImage
          uriList={document.filestore_uri_list}
          type={document.type}
          onLoadEnd={() => setImageLoading(false)}
        />
      </View>
    </Screen>
  )
}
