import React, { useEffect, useState } from "react"
import { FlatList } from "react-native"
import { FAB } from "react-native-paper"

import Screen from "../../components/Screen"
import DefaultTheme from "../../themes/DefaultTheme"
import * as FSDiary from "../../firebase/FSDiary"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import FlatListProjectDiaryItem from "../../components/FlatLists/FlatListProjectDiaryItem"
import { useData } from "../../providers/DataProvider"
import { projectStateEnum } from "../../data/enums"
import { isOnlyInvestor } from "../../utils/validation"

export default function ProjectDiaryDetail({ route, navigation }) {
  const { projects, currentUser } = useData()
  const [diaryEntries, setDiaryEntries] = useState([])
  const [isProjectClosed, setIsProjectClosed] = useState(false)
  const projectId = route.params.projectId
  console.debug("PROJ ID", projectId)

  useEffect(() => {
    FSDiary.onSnapshotEntryList(projectId, (newDiaryEntries) =>
      setDiaryEntries(newDiaryEntries.sort((a, b) => b.date_interval.from - a.date_interval.from))
    )
  }, [])

  useEffect(() => {
    setIsProjectClosed(projects.find((proj) => proj.id === projectId)?.state === projectStateEnum.CLOSED)
  }, [projects])

  return (
    <Screen>
      {diaryEntries.length > 0 ? (
        <FlatList
          data={diaryEntries}
          renderItem={({ item }) => <FlatListProjectDiaryItem item={item} projectId={projectId} />}
          keyExtractor={(item) => item.id.toString()}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="Deník neobsahuje žádné denní zápisy" />
      )}
      <FAB
        visible
        disabled={isProjectClosed || isOnlyInvestor(currentUser)}
        style={DefaultTheme.fab}
        color={DefaultTheme.colors.fabAdd}
        icon="plus"
        onPress={() => navigation.navigate("DiaryEntryDetail", { projectId: projectId })}
      />
    </Screen>
  )
}
