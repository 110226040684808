import React, { useEffect, useState, useRef } from "react"
import { Text, FlatList, View, StyleSheet } from "react-native"
import FlatListAttendanceMonthItem from "../../components/FlatLists/FlatListAttendanceMonthItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import { firebase } from "../../firebase/config"
import { attendanceOperationTypeEnum, attendanceTypeEnum, holidayStateEnum } from "../../data/enums"
import {holidaysPerYearTotal, sickDaysPerYearTotal} from "../../data/companyConstants"
import { STATE_VIEW_PARAMS_ATTENDANCE_DAY } from "../../data/params"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { getDayDeclensionForm, getHolidayColor } from "../../utils/general"

export default function AttendanceMonths({ navigation, route }) {
  const { currentUser } = useData()
  const userEmail = route.params?.user.email || currentUser.email
  const [attendancesByMonths, setAttendancesByMonths] = useState([])
  const [approvedHolidaysThisYear, setApprovedHolidaysThisYear] = useState(0)
  //const freeDaysCount = holidaysPerYearTotal - approvedHolidaysThisYear
  const today = new Date()
  const [approvedHolidays, setApprovedHolidays] = useState({
    ...{},
    [`${today.getFullYear() - 1}`]: 0,
    [`${today.getFullYear()}`]: 0,
    [`${today.getFullYear() + 1}`]: 0,
  })
  const [sickDays, setSickDays] = useState(0)

  const closingItems = useRef({})

  const getDate = (index) => {
    const temp = new Date()
    temp.setDate(1)
    temp.setMonth(today.getMonth() - index)
    return temp.getFullYear()
  }

  //freeDaysCount for this year only, used for ui
  const freeDaysCount = () => {
    return holidaysPerYearTotal - approvedHolidays[`${today.getFullYear()}`]
  }

  const freeSickDaysCount = () => {
    return sickDaysPerYearTotal - sickDays
  }

  useEffect(() => {
    onSnapshotAttendances()
  }, [])

  function onSnapshotAttendances() {
    firebase.apps[0]
      .firestore()
      .collection("attendances")
      .doc(userEmail)
      .onSnapshot((doc) => {
        try {
          if (doc.data()?.closing_items) {
            closingItems.current = doc.data()?.closing_items
          }
          console.debug("closingItems: ", closingItems.current)
          setAttendancesByMonths(mapMonthIndex(doc.data()))

          const holidayCopy = { ...approvedHolidays }

          for (const actualYear of Object.keys(holidayCopy)) {
            console.debug(typeof actualYear)
            const monthlyThisYear = doc.data()?.[`monthly_${actualYear}`]
            let counter = 0
            for (const month in monthlyThisYear) {
              counter += monthlyThisYear[month].reduce((a, c) => {
                const holidayAprroveAction =
                  c.operations?.find((o) => o.action_type === attendanceOperationTypeEnum.HOLIDAY_APPROVE) || null

                return (
                  a +
                  (c.attendance_date.toDate().getFullYear() === Number.parseInt(actualYear) &&
                  holidayAprroveAction?.action_value === holidayStateEnum.ACCEPTED
                    ? 1
                    : 0)
                )
              }, 0)
            }
            //setApprovedHolidaysThisYear(counter)
            holidayCopy[`${actualYear}`] = counter
          }
          console.debug("holiday copy ", holidayCopy)
          setApprovedHolidays(holidayCopy)

          console.debug("calculate free sickdays per actual year:", today.getFullYear())
          const monthlyThisYear = doc.data()?.[`monthly_${today.getFullYear()}`]
          let counter = 0
          for (const month in monthlyThisYear) {
            counter += monthlyThisYear[month].reduce((a, c) => {
              return (a + (c.attendance_type === attendanceTypeEnum.SICKDAY ? 1 : 0))
            }, 0)
          }
          console.debug("sickdays sickdays per actual year:", counter)
          setSickDays(counter)

        } catch (error) {
          console.error(error)
          throw error
        }
      })
  }

  function mapMonthIndex(monthlyAttendancesList) {
    const mapped = []
    for (const yearMonthly in monthlyAttendancesList) {
      if (yearMonthly.startsWith("monthly_")) {
        for (const attendanceMonthIndex in monthlyAttendancesList[yearMonthly]) {
          const yearDiff = Number(yearMonthly.split("_")[1]) - today.getFullYear()
          console.debug("YEAR DIFF", yearDiff)
          const index = today.getMonth() - (Number(attendanceMonthIndex) + yearDiff * 12)
          console.debug("INDEX", index)
          mapped.push({
            monthIndex: index,
            monthAttendences: monthlyAttendancesList[yearMonthly][attendanceMonthIndex],
          })
        }
      }
    }

    for (let monthIndex = -7; monthIndex <= 6; monthIndex++) {
      if (!mapped.some((attendance) => attendance.monthIndex === monthIndex)) {
        mapped.push({ monthIndex: monthIndex, monthAttendences: [] })
      }
    }

    console.debug("MAPPED", mapped)

    mapped.sort((a, b) => a.monthIndex - b.monthIndex)
    return mapped
  }

  return (
    <Screen>
      <View style={styles.freeDaysCount}>
        <Text>Zbývající dovolená do konce roku:</Text>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={{ paddingRight: 5 }}>
            {freeDaysCount()} {getDayDeclensionForm(freeDaysCount())}
          </Text>
          <MaterialCommunityIcons
            {...STATE_VIEW_PARAMS_ATTENDANCE_DAY[attendanceTypeEnum.HOLIDAY]}
            color={getHolidayColor(freeDaysCount())}
            size={24}
          />
        </View>
      </View>
      <View style={styles.freeDaysCount}>
        <Text>Zbývající sick days do konce roku:</Text>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={{ paddingRight: 5 }}>
            {freeSickDaysCount()} {getDayDeclensionForm(freeSickDaysCount())}
          </Text>
          <MaterialCommunityIcons
              {...STATE_VIEW_PARAMS_ATTENDANCE_DAY[attendanceTypeEnum.SICKDAY]}
              color={getHolidayColor(freeSickDaysCount())}
              size={24}
          />
        </View>
      </View>
      {attendancesByMonths?.length > 0 && (
        <FlatList
          data={attendancesByMonths}
          renderItem={({ item }) => (
            <FlatListAttendanceMonthItem
              monthAttendences={item.monthAttendences}
              monthIndex={item.monthIndex}
              navigation={navigation}
              userEmail={userEmail}
              freeDaysCount={28 - approvedHolidays[`${getDate(item.monthIndex)}`]}
              closingItems={closingItems.current}
            />
          )}
          keyExtractor={(item) => item.monthIndex.toString()}
          ItemSeparatorComponent={FlatListSeparator}
        />
      )}
    </Screen>
  )
}

const styles = StyleSheet.create({
  freeDaysCount: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
})
