import React from "react"
import Screen from "../../components/Screen"
import { View, Text } from "react-native"
import { useData } from "../../providers/DataProvider"
import DefaultTheme from "../../themes/DefaultTheme"

export default function ContactScreen() {
  const { currentUser } = useData()
  return (
    <Screen>
      <View style={{ alignSelf: "center" }}>
        <Text>Vítejte v aplikaci, {currentUser?.email} </Text>
        <View style={{ flexDirection: "row" }}>
          <Text>Pro udělení práv na používání aplikace prosím kontaktujte naši podporu </Text>
          <Text style={{ color: DefaultTheme.colors.primary }}>info@cexbit.com</Text>
        </View>
      </View>
    </Screen>
  )
}
