import React from "react"
import { Platform, StyleSheet, View, Text } from "react-native"
import { Ionicons } from "@expo/vector-icons"

import DefaultTheme from "../../themes/DefaultTheme"
import { useData } from "../../providers/DataProvider"
import { userStateEnum } from "../../data/enums"
import { STATE_VIEW_PARAMS_USER } from "../../data/params"
import DeleteButton from "../DeleteButton"
import { getUserName } from "../../utils/general"

export default function FlatListUserPickerItem({ email, onDelete, disabled = false }) {
  const { users } = useData()
  const userDisabled = users.find((u) => u.email === email)?.disabled
  const userDeleted = users.find((u) => u.email === email)?.state === userStateEnum.DELETED

  return (
    <View
      style={[
        styles.card,
        {
          borderLeftColor: userDeleted
            ? DefaultTheme.colors.danger
            : userDisabled || disabled
            ? DefaultTheme.colors.disabled
            : DefaultTheme.colors.primary,
        },
      ]}
    >
      <Ionicons {...STATE_VIEW_PARAMS_USER[userDeleted ? "deleted" : userDisabled || disabled]} />
      <Text style={styles.userName}>{getUserName(users, email)}</Text>
      <DeleteButton onPress={() => onDelete(email)} absolute={false} />
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    width: "100%",
    paddingLeft: 15,
    paddingRight: 10,
    backgroundColor: DefaultTheme.colors.white,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderLeftWidth: 10,
    height: 80,
  },
  userName: {
    paddingLeft: 10,
    flex: Platform.OS === "web" ? 1 : 2.5,
  },
})
