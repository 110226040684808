import AppLink from "react-native-app-link"
import { Platform } from "react-native"
import * as Linking from "expo-linking"

export async function openTrivi() {
  if (Platform.OS !== "web") {
    AppLink.maybeOpenURL("trivi://1359230332", {
      appName: "Trivi",
      appStoreId: "1359230332",
      appStoreLocale: "cz",
      playStoreId: "com.trivi.app",
    })
  } else {
    window.open("https://my.trivi.com/")
  }
}

export async function openKbBank() {
  if (Platform.OS !== "web") {
    AppLink.maybeOpenURL("kb-banka://1359230332", {
      appName: "KB Mobilní banka",
      appStoreId: "447750755",
      appStoreLocale: "cz",
      playStoreId: "eu.inmite.prj.kb.mobilbank",
    })
  } else {
    window.open("https://www.kb.cz/")
  }
}

export async function openCompanyServer() {
  if (Platform.OS !== "web") {
    AppLink.maybeOpenURL("boxapp://file?id=[fileid]", {
      appName: "Box",
      appStoreId: "290853822",
      appStoreLocale: "cz",
      playStoreId: "com.box.android",
    })
  } else {
    window.open("https://dasar.account.box.com/login?redirect_url=%2Ffolder%2F0&logout=true")
  }
}

export async function openPlangrid() {
  if (Platform.OS !== "web") {
    AppLink.maybeOpenURL("plangrid://file?id=[fileid]", {
      appName: "PlanGrid Build Field App",
      appStoreId: "498795789",
      appStoreLocale: "cz",
      playStoreId: "com.plangrid.android",
    })
  } else {
    window.open("https://app.plangrid.com/login?loginRedirect=https%3A%2F%2Fapp.plangrid.com%2Fusers%2Faccount")
  }
}

export function openVideoTutorials() {
  Platform.OS === "web"
    ? window.open("https://drive.google.com/drive/folders/1nlMTIH8OllyeYdPHQRf27RpAMp_nG5O6?usp=sharing", "_blank")
    : Linking.openURL("https://drive.google.com/drive/folders/1nlMTIH8OllyeYdPHQRf27RpAMp_nG5O6?usp=sharing")
}
