import React, { useEffect, useState } from "react"
import { TouchableOpacity, StyleSheet, View, Text } from "react-native"
import { useData } from "../../providers/DataProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import { FontAwesome } from "@expo/vector-icons"
import { approveEnum, premiumActionTypeEnum, projectStateEnum, statusEnum, userRoleEnum } from "../../data/enums"
import FSStoreContent from "../../firebase/FSStoreContent"
import { getUserName, toCurrentDateTime } from "../../utils/general"
import { STATE_VIEW_PARAMS_DOCUMENT, STATE_VIEW_PARAMS_PREMIUM } from "../../data/params"
import { premiumLabels } from "../../data/labels"
import Screen from "../../components/Screen"
import CurrencyFormatter from "../../components/CurrencyFormatter"
import NextButton from "../../components/NextButton"
import ApproveButtons from "../../components/ApproveButtons"
import { isAdmin, isExecutive, isOfficeManager } from "../../utils/validation"
import { useStatus } from "../../providers/StatusProvider"

export default function PremiumProjectDetail({ route, navigation }) {
  const { projects, currentUser, users, premiums } = useData()
  const { setMessage, setStatus, setTitle } = useStatus()

  const hasEditPermission = isAdmin(currentUser) || isExecutive(currentUser) || isOfficeManager(currentUser)

  const user = route.params.user
  const [project, setProject] = useState(route.params.project)
  const [userPremium, setUserPremium] = useState(0)
  const task = route.params.task

  const [premiumPercentage, setPremiumPercentage] = useState(0)
  const [userPremiumActions, setUserPremiumActions] = useState({})
  const [lastPremiumAction, setLastPremiumAction] = useState(null)
  const [newOperation, setNewOperation] = useState(null)

  console.debug("LAST PREMIUM ACTION", lastPremiumAction)

  useEffect(() => {
    const userPremiums = premiums.find((p) => p.email === user.email)
    if (userPremiums?.[project.id]?.requesting_items) {
      setUserPremiumActions(userPremiums)

      setLastPremiumAction(
        userPremiums[project.id]?.requesting_items
          .map((item) => ({
            ...item,
            created_at: item.created_at.toDate(),
          }))
          .sort((a, b) => b.created_at - a.created_at)[0]
      )
    }
  }, [projects, project, premiums])

  useEffect(() => {
    const proj = projects.find((proj) => proj.id === project.id)

    const draftOverProfit = (proj.draft_income || 0) - (proj.draft_outcome || 0)
    const actualOverProfit =
      (proj.statistics?.income || 0) + (proj.statistics?.income_extra || 0) - (proj.statistics?.outcome || 0)

    const projectUser =
      proj.users.filter((u) => !u.roles.includes(userRoleEnum.INVESTOR)).find((u) => u.email === user.email) || {}

    setUserPremium((actualOverProfit - draftOverProfit) * (projectUser.premium || 0))
    setPremiumPercentage(projectUser.premium || 0)
    setProject(proj)
  }, [projects])

  function getLastAction() {
    return `${getUserName(users, lastPremiumAction.created_by)}\n${toCurrentDateTime(lastPremiumAction.created_at)}`
  }

  function getPremiumState() {
    if (lastPremiumAction?.action_type === premiumActionTypeEnum.DECISION) {
      return lastPremiumAction?.action_value
    }
    return approveEnum.PENDING
  }

  async function handleSavePremium() {
    if (userPremiumActions[project.id]?.requesting_items) {
      userPremiumActions[project.id].requesting_items.push(newOperation)
    } else {
      userPremiumActions[project.id] = {
        requesting_items: [newOperation],
      }
    }

    try {
      await FSStoreContent.updateUserPremiums(user, userPremiumActions)
      if (task?.id || userPremiumActions[project.id]?.task_id) {
        await FSStoreContent.markTaskAsDone(task?.id || userPremiumActions[project.id]?.task_id)
      }
      navigation.pop()
    } catch (error) {
      console.error(error)
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  return (
    <Screen>
      <View style={{ padding: 10, flex: 1 }}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text>Aktuální prémie z nad zisku</Text>
          <Text style={{ fontWeight: "bold" }}>{premiumPercentage * 100} %</Text>
        </View>
        <Text style={{ alignSelf: "flex-end", fontWeight: "bold" }}>{CurrencyFormatter(userPremium, "CZK", 0)}</Text>
        {lastPremiumAction?.created_at && (
          <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <View>
              <Text style={DefaultTheme.hint}>Poslední akce:</Text>
              <Text>{getLastAction()}</Text>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text style={{ fontWeight: "bold", paddingRight: 5 }}>
                {premiumLabels[`${lastPremiumAction.action_type}_${getPremiumState()}`]}
              </Text>

              {lastPremiumAction && project.state === projectStateEnum.CLOSED && (
                <FontAwesome
                  {...STATE_VIEW_PARAMS_PREMIUM[getPremiumState()]}
                  size={DefaultTheme.icons.m}
                  style={{ alignSelf: "center" }}
                />
              )}
            </View>
          </View>
        )}
        {(isAdmin(currentUser) || isOfficeManager(currentUser) || isExecutive(currentUser)) && lastPremiumAction && (
          <View style={styles.approveRow}>
            <Text style={{ paddingRight: 10, fontWeight: "bold", flex: 1 }}>Byla odměna vyplacena?</Text>
            <ApproveButtons
              initialState={getPremiumState()}
              action_type="DECISION"
              changeValue={(action_type, value) =>
                setNewOperation({
                  action_type,
                  action_value: value,
                  created_at: new Date(),
                  created_by: currentUser.email,
                })
              }
              disabled={project.state !== projectStateEnum.CLOSED}
            />
          </View>
        )}

        {hasEditPermission && project.state !== projectStateEnum.CLOSED && (
          <Text>Uzavřete projekt pro provádění úprav</Text>
        )}
      </View>

      {hasEditPermission && (
        <NextButton
          disabled={project.state !== projectStateEnum.CLOSED || !newOperation}
          text="Uložit"
          onPress={handleSavePremium}
        />
      )}
    </Screen>
  )
}

const styles = StyleSheet.create({
  approveRow: {
    flexDirection: "row",
    paddingHorizontal: DefaultTheme.padding.s,
    alignItems: "center",
    paddingVertical: DefaultTheme.margin.s,
  },
})
