import React, { useEffect, useState } from "react"
import { View, StyleSheet, Text, Image, SafeAreaView, FlatList, ScrollView } from "react-native"
import { PieChart } from "react-native-chart-kit"
import { MaterialCommunityIcons, FontAwesome5, FontAwesome } from "@expo/vector-icons"

import DefaultTheme from "../../themes/DefaultTheme"
import { companyCostsEnum, paymentEnum, projectStateEnum, viewTypeEnum } from "../../data/enums"
import PieChartLegendItem from "../PieChartLegendItem"
import { isAdmin, isDateInCurrentMonth, isExecutive } from "../../utils/validation"
import { useData } from "../../providers/DataProvider"
import FSStoreContent from "../../firebase/FSStoreContent"
import FlatListProjectItem from "../FlatLists/FlatListProjectItem"
import FlatListSeparator from "../FlatLists/FlatListSeparator"

export default function StatisticsDashboard() {
  const { settings, projects } = useData()
  const [actualIncome, setActualIncome] = useState(0)
  const [actualOutcome, setActualOutcome] = useState(0)
  const [statsMonthlyList, setStatsMonthlyList] = useState([])
  const [syncedProjects, setSyncedProjects] = useState([])
  const monthlyCosts = settings.company?.monthly_costs?.[companyCostsEnum.ALL] || 0

  const today = new Date()
  const actualProfit = Number((actualIncome - actualOutcome - monthlyCosts).toFixed(2)) || 0

  useEffect(() => {
    setSyncedProjects(
      settings.projects?.show_locked_projects
        ? projects.filter((proj) => proj.isSyncing)
        : projects.filter((proj) => proj.isSyncing && proj.state !== projectStateEnum.CLOSED)
    )
  }, [projects, settings])

  useEffect(() => {
    FSStoreContent.onSnapshotMonthlyStatistics(setStatsMonthlyList)
  }, [])

  useEffect(() => {
    if (statsMonthlyList.length > 0) {
      const monthStats = statsMonthlyList.find(
        (monthStats) =>
          monthStats.date.getFullYear() === today.getFullYear() && monthStats.date.getMonth() === today.getMonth()
      )

      setActualIncome(monthStats?.income || 0)
      setActualOutcome(monthStats?.outcome || 0)
    }
  }, [statsMonthlyList])

  const pieChartConfig = {
    color: (opacity = 1) => `rgba(205, 205, 205, ${opacity})`,
  }

  const statisticsPieChartData =
    actualProfit > 0
      ? [
          {
            sumOfEntry: actualProfit,
            color: DefaultTheme.colors.success,
          },
          {
            sumOfEntry: actualOutcome,
            color: DefaultTheme.colors.danger,
          },
          {
            sumOfEntry: monthlyCosts,
            color: "purple",
          },
        ]
      : [
          {
            sumOfEntry: actualOutcome + actualProfit,
            color: DefaultTheme.colors.danger,
          },
          {
            sumOfEntry: monthlyCosts,
            color: "purple",
          },
          { sumOfEntry: Math.abs(actualProfit), color: "red" },
        ]

  return (
    <View style={{ flex: 1 }}>
      {/* <View style={[styles.chartWrapper, styles.wrapper]}> */}
      {/* <Text style={styles.title}>Statistika</Text>
        {actualIncome > 0 || actualOutcome > 0 || monthlyCosts > 0 ? (
          <PieChart
            data={statisticsPieChartData}
            width={150}
            height={150}
            chartConfig={pieChartConfig}
            accessor="sumOfEntry"
            backgroundColor="transparent"
            hasLegend={false}
            paddingLeft={40}
          />
        ) : (
          <View style={styles.emptyGraphWrapper}>
            <View style={styles.emptyGraph} />
          </View>
        )}
        <View style={{ justifyContent: "center", minHeight: 150 }}>
          <PieChartLegendItem
            viewType={viewTypeEnum.DASHBOARD}
            description="Prodej"
            value={actualIncome}
            color={DefaultTheme.colors.primary}
          />
          <PieChartLegendItem
            viewType={viewTypeEnum.DASHBOARD}
            description="Měsíční náklady"
            value={monthlyCosts}
            color="purple"
          />
          <PieChartLegendItem
            viewType={viewTypeEnum.DASHBOARD}
            description="Náklady"
            value={actualOutcome}
            color={DefaultTheme.colors.danger}
          />
          <PieChartLegendItem
            viewType={viewTypeEnum.DASHBOARD}
            description="Zisk"
            value={actualProfit}
            color={actualProfit > 0 ? DefaultTheme.colors.success : "red"}
          />
        </View> */}

      <FlatList
        data={syncedProjects}
        renderItem={({ item }) => <FlatListProjectItem project={item} viewType={viewTypeEnum.DASHBOARD} />}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={FlatListSeparator}
      />
      {/* </View> */}
    </View>
  )
}

const styles = StyleSheet.create({
  emptyGraph: {
    borderWidth: 5,
    borderColor: DefaultTheme.colors.primary,
    height: 120,
    width: 120,
    borderRadius: 75,
  },
  emptyGraphWrapper: {
    alignItems: "center",
    justifyContent: "center",
    height: 150,
    width: 150,
  },
  chartWrapper: {
    justifyContent: "center",
    backgroundColor: "white",
  },
  row: {
    flexDirection: "row",
  },
  title: {
    width: "100%",
    textAlign: "center",
    fontSize: DefaultTheme.fonts.medium,
  },
  wrapper: {
    flex: 1,
    padding: 10,
    alignItems: "center",
  },
})
