//mail a heslo hesloimport React, { useState } from "react"
import React, { useState } from "react"
import {
  Text,
  SafeAreaView,
  View,
  StyleSheet,
  ScrollView,
  Image,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
} from "react-native"
import { TextInput } from "react-native-paper"
import Validation from "../../utils/validation"
import NextButton from "../../components/NextButton"
import DefaultTheme from "../../themes/DefaultTheme"
import Screen from "../../components/Screen"
import { useStatus } from "../../providers/StatusProvider"
import { statusEnum, userRoleEnum } from "../../data/enums"
import Constants from "expo-constants"
import { registerUser } from "../../utils/backendUrls"
import { useData } from "../../providers/DataProvider"
import { firebase } from "../../firebase/config"
import { useNavigation } from "@react-navigation/native"

export default function Registration({ navigation }) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { setTitle, setStatus, setMessage, setToScreen } = useStatus()
  const { firebaseConfig, setFirebaseConfig } = useData()

  const register = async () => {
    if (Validation.isEmailValid(email)) {
      try {
        setTitle("Vytvářím uživatele")
        setStatus(statusEnum.REGISTER)
        navigation.navigate("StatusScreen")
        const url = registerUser(firebaseConfig)

        const registerReq = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, password }),
        })
        const response = await registerReq.json()
        console.debug(response)
        if (registerReq.status !== 200 || !response.success) {
          throw new Error(response?.message || "Register error")
        }
        setMessage("Registrace byla úspěšná")
        // navigation.navigate("Registration")
        if (response.success) {
          setTimeout(async () => {
            await firebase.apps[0].auth().signInWithEmailAndPassword(email, password)
          }, 5000)
        }
      } catch (error) {
        console.error(error)
        setMessage(error.message)
        setStatus(statusEnum.ERROR)
      }
    }
  }
  return (
    <Screen>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Image style={styles.logo} source={require("../../assets/dasar_logo.png")} />
        <TextInput
          error={!Validation.shouldWarningEmail(email)}
          style={DefaultTheme.input}
          label={Validation.shouldWarningEmail(email) ? "Email" : "Vyplňte prosím validní email"}
          placeholderTextColor={DefaultTheme.colors.placeholder}
          placeholder="Váš email"
          onChangeText={(value) => setEmail(value)}
          value={email}
          autoCapitalize="none"
        />
        <TextInput
          style={DefaultTheme.input}
          placeholderTextColor={DefaultTheme.colors.placeholder}
          secureTextEntry
          placeholder="Vaše heslo"
          label="Heslo"
          onChangeText={(text) => setPassword(text)}
          value={password}
          autoCapitalize="none"
        />
      </ScrollView>
      <NextButton disabled={!(Validation.isEmailValid(email) && password)} text="Registrovat se" onPress={register} />
      <View style={styles.login}>
        <Text style={styles.row}>Už jste zaregistrován?</Text>
        <TouchableOpacity onPress={() => navigation.navigate("Login")} style={styles.row}>
          <Text style={DefaultTheme.link}>Přihlásit se</Text>
        </TouchableOpacity>
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    justifyContent: "center",
  },
  logo: {
    width: "100%",
    height: 120,
    resizeMode: "contain",
    marginVertical: DefaultTheme.padding.m,
  },
  footerText: {
    fontSize: DefaultTheme.fonts.regular,
    color: DefaultTheme.colors.black,
  },
  footerView: {
    alignItems: "center",
    marginVertical: DefaultTheme.margin.s,
  },
  login: {
    flex: 1,
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    margin: "2px",
    flexDirection: "row",
    flexWrap: "wrap",
  },
})
