import React, { useState } from "react"
import { TouchableOpacity, Text, StyleSheet, View } from "react-native"
import { FontAwesome } from "@expo/vector-icons"
import { documentStateEnum } from "../data/enums"
import DefaultTheme from "../themes/DefaultTheme"

export default function ApproveButtonsDocument({
  initialState,
  changeOperationsActionValue,
  action_type,
  disabled = false,
  style,
}) {
  const [state, setState] = useState(initialState)

  const handleStateChange = (value) => {
    setState(state === value ? documentStateEnum.PENDING : value)
    changeOperationsActionValue(action_type, state === value ? documentStateEnum.PENDING : value)
  }

  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity
        style={[
          styles.button,
          { backgroundColor: state === documentStateEnum.ACCEPTED ? "#788eec" : "white", marginRight: 10 },
        ]}
        onPress={() => handleStateChange(documentStateEnum.ACCEPTED)}
        disabled={disabled}
      >
        <Text style={styles.buttonTitle}>
          <FontAwesome name="check" size={24} color="green" />
        </Text>
      </TouchableOpacity>
      <View />
      <TouchableOpacity
        style={[styles.button, { backgroundColor: state === documentStateEnum.REJECTED ? "#788eec" : "white" }]}
        onPress={() => handleStateChange(documentStateEnum.REJECTED)}
        disabled={disabled}
      >
        <Text style={styles.buttonTitle}>
          <FontAwesome name="remove" size={24} color="red" />
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  button: {
    minWidth: 50,
    height: 50,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
})
