import React from "react"
import { Animated, Easing } from "react-native"
import { Platform } from "react-native"

const propsAreEqual = (prev, next) => {
  console.debug(prev.icon, next.icon)
  return prev.width === next.width
}

export default React.memo(function RotateIcon({ icon, width }) {
  const spinValue = new Animated.Value(0)

  // First set up animation
  Animated.loop(
    Animated.timing(spinValue, {
      toValue: 1,
      duration: 3000,
      easing: Easing.linear,
      useNativeDriver: !Platform.os === "web",
    })
  ).start()

  // Next, interpolate beginning and end values (in this case 0 and 1)
  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  })
  return (
    <Animated.View style={{ transform: [{ rotate: spin }], width: width, height: width, marginVertical: 35 }}>
      {icon}
    </Animated.View>
  )
}, propsAreEqual)
