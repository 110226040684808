import React, { useEffect, useState } from "react"
import { Platform, ScrollView, View } from "react-native"
import uuid from "uuid"

import Screen from "../../components/Screen"
import NextButton from "../../components/NextButton"
import { statusEnum } from "../../data/enums"
import { useStatus } from "../../providers/StatusProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import { TextInput } from "react-native-paper"
import { useData } from "../../providers/DataProvider"
import FSStoreContent from "../../firebase/FSStoreContent"
import { uploadFileOnFirebase } from "../../firebase/FSGeneral"
import FullWidthImageWeb from "../../components/FullWidthImageWeb"
import FullWidthImagePhone from "../../components/FullWidthImagePhone"

export default function TemplateDetail({ navigation, route }) {
  const { setTitle, setMessage, setStatus, updatePercentage, setToScreen } = useStatus()
  const { settings } = useData()
  const [templates, setTemplates] = useState([])
  const [template, setTemplate] = useState({})
  const [imageLoading, setImageLoading] = useState(!route.params.template?.localUri)
  const docId = route.params.docId
  const propName = route.params.propName
  console.debug("TEMPLATE", template)
  console.debug("ROUTE TEMPLATE", route.params.template)
  console.debug("DOC ID", docId)
  console.debug("PROP NAME", propName)

  useEffect(() => {
    const tempTemplates = settings[docId]?.[propName] || []
    setTemplates(tempTemplates)
    const tempTemplate = tempTemplates.find((t) => t.id === route.params?.template?.id)
    if (tempTemplate) {
      setTemplate(tempTemplate)
    } else {
      setTemplate({ ...route.params.template, title: "", filestore_uri: "", id: uuid.v4() })
    }
  }, [settings])

  async function handleSave() {
    try {
      setTitle("Upravuji šablonu")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      if (template.localUri) {
        setTitle("Nahrávám obrázek šablony")
        setStatus(statusEnum.PROGRESS_DETERMINED)
        navigation.navigate("StatusScreen")
        const remoteUri = await uploadFileOnFirebase(template.localUri, updatePercentage, "templates")
        delete template.localUri
        template.filestore_uri = remoteUri
      }
      await FSStoreContent.updateSettings({
        ...settings[docId],
        id: docId,
        [propName]: templates.some((t) => t.id === template.id)
          ? templates.map((t) => (t.id === template.id ? template : t))
          : [...templates, template],
      })

      setStatus(statusEnum.SUCCESS)
      setToScreen({ screen: "TemplatesOverview", params: route.params })
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  return (
    <Screen>
      <View
        style={[
          { flex: 1, overflow: "hidden" },
          imageLoading && {
            transform: [{ scale: 0 }],
            flex: 0,
          },
        ]}
      >
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          {Platform.OS === "web" ? (
            <FullWidthImageWeb
              uri={template.filestore_uri || template.localUri}
              onLoadEnd={() => setImageLoading(false)}
            />
          ) : (
            <FullWidthImagePhone
              uri={template.filestore_uri || template.localUri}
              onLoadEnd={() => setImageLoading(false)}
            />
          )}
        </ScrollView>
      </View>
      <TextInput
        error={!template.title}
        style={[DefaultTheme.input, { marginTop: 10 }]}
        label={template.title ? "Název šablony" : "Zadejte název šablony"}
        onChangeText={(value) => setTemplate({ ...template, title: value })}
        value={template.title}
      />
      <NextButton onPress={handleSave} text="Uložit" disabled={!template.title} />
    </Screen>
  )
}
