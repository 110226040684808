import DefaultTheme from "../../themes/DefaultTheme"
import React from "react"
import { Text, TouchableOpacity, View, StyleSheet } from "react-native"
import { toCurrentDateTime } from "../../utils/general"
import { Ionicons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/core"

export default function FlatListDocumentItem({ document }) {
  const navigation = useNavigation()

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate("DocumentDetail", {
          document,
        })
      }}
    >
      <View style={styles.card}>
        <Ionicons name="document-text" size={DefaultTheme.icons.flatlistSize} color={DefaultTheme.colors.primary} />
        <View style={{ alignItems: "center" }}></View>
        <View style={{ flex: 1.3, paddingRight: 10 }}>
          <Text style={styles.name}>{document.name}</Text>
          <Text style={styles.hint}>Vytvořený</Text>
          <Text style={styles.name}>
            {`${toCurrentDateTime(document.created_at)}`.slice(0, 24)} {document.created_by}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    height: 100,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    flexDirection: "row",
    borderLeftWidth: 10,
    backgroundColor: DefaultTheme.colors.white,
    borderLeftColor: DefaultTheme.colors.primary,
  },
  name: {
    paddingLeft: 10,
    marginRight: "auto",
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
})
