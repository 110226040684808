import React from "react"
import { View, Text } from "react-native"
import DefaultTheme from "../themes/DefaultTheme"

export default function AppEmptyScreenView({ message }) {
  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Text style={{ fontSize: DefaultTheme.fonts.regular, textAlign: "center" }}>{message}</Text>
    </View>
  )
}
