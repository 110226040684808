import React, { useState, useRef, useEffect } from "react"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import { FlatList, View, Text, ActivityIndicator } from "react-native"
import { useData } from "../../providers/DataProvider"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import FlatListPredefinedLogbook from "../../components/FlatLists/FlatListPredefinedLogbook"
import { FAB } from "react-native-paper"
import DefaultTheme from "../../themes/DefaultTheme"
import Screen from "../../components/Screen"

export default function PredefinedLogs({ navigation, route }) {
  const { settings } = useData()
  const [predefinedLogs, setPredefinedLogs] = useState(null)

  const itemOnPress = (item) => {
    navigation.navigate("PredefinedLogDetail", { item })
  }

  const renderItem = ({ item, index }) => (
    <FlatListPredefinedLogbook item={item} index={index} onPress={() => itemOnPress(item)} />
  )

  useEffect(() => {
    if (
      settings &&
      (!settings?.logBook || !settings?.logBook?.predefined_logs || !settings?.logBook?.predefined_logs.length > 0)
    ) {
      setPredefinedLogs([])
    } else if (settings && settings?.logBook?.predefined_logs) {
      setPredefinedLogs(settings?.logBook?.predefined_logs.filter((el) => el.value !== "NEW"))
    }
  }, [settings])

  return (
    <Screen>
      {!predefinedLogs ? (
        <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignSelf: "center" }}>
          <ActivityIndicator size="large" />
        </View>
      ) : (
        <>
          <FlatList
            data={predefinedLogs}
            ListEmptyComponent={<AppEmptyScreenView message="Nejsou nahrány žádné jízdy!" />}
            ItemSeparatorComponent={FlatListSeparator}
            renderItem={renderItem}
            keyExtractor={(item, index) => (item + index).toString()}
          />
          <FAB
            visible
            style={DefaultTheme.fab}
            color={DefaultTheme.colors.fabAdd}
            icon="plus"
            onPress={() => navigation.navigate("PredefinedLogDetail")}
          />
        </>
      )}
    </Screen>
  )
}
