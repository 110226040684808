import React from "react"
import { View, FlatList } from "react-native"
import FlatListChatItem from "../components/FlatLists/FlatListChatItem"
import FlatListSeparator from "../components/FlatLists/FlatListSeparator"
import { useData } from "../providers/DataProvider"

export default function ChatsOverview({ navigation }) {
  const { userMessages, setUserMessages, currentUser, users } = useData()
  const supportEmail = "info@cexbit.com"

  const flatListItems = [
    {
      avatar: require("../assets/avatar.jpg"),
      to: `${getUserName("info@cexbit.com")} - info@cexbit.com`,
      lastMessageTime: getLastMessageWithUser("info@cexbit.com").createdAt || "",
      lastMessage: getLastMessageWithUser("info@cexbit.com").text || "",
      onPress: () =>
        navigation.navigate("ChatScreen", {
          name: getUserName("info@cexbit.com"),
          to: "info@cexbit.com",
        }),
    },
    ...getArrayOfToUsers().map((email) => ({
      key: email,
      to: `${getUserName(email)} - ${email}`,
      lastMessageTime: getLastMessageWithUser(email).createdAt || "",
      lastMessage: getLastMessageWithUser(email).text || "",
      onPress: () =>
        navigation.navigate("ChatScreen", {
          name: getUserName(email),
          to: email,
        }),
    })),
  ]

  function getLastMessageWithUser(to) {
    const messages = userMessages.filter((m) => m.user.to === to || m.user._id === to)
    return messages[messages.length - 1] || ""
  }

  function getArrayOfToUsers() {
    const toUsers = []
    userMessages.map((m) => {
      if (!toUsers.includes(m.user.to) && m.user.to !== supportEmail) toUsers.push(m.user.to)
    })
    return toUsers
  }

  function getUserName(email) {
    for (let user of users) if (user.email === email) return user.name
  }

  return (
    <View>
      <FlatList
        data={flatListItems}
        renderItem={({ item }) => (
          <FlatListChatItem
            avatar={item.avatar}
            to={item.to}
            lastMessage={item.lastMessage}
            lastMessageTime={item.lastMessageTime}
            onPress={item.onPress}
          />
        )}
        keyExtractor={(item) => item.to}
        ItemSeparatorComponent={FlatListSeparator}
      />
    </View>
  )
}
