import React, { useEffect, useState } from "react"
import { Text, TouchableOpacity, TouchableWithoutFeedback, View, StyleSheet, Platform, Alert } from "react-native"
import { actionEnum, taskActionEnum, viewTypeEnum } from "../../data/enums"
import { monthNameLabels, taskTitleLabels } from "../../data/labels"
import DefaultTheme from "../../themes/DefaultTheme"
import { getUserName, toCurrentDateTime } from "../../utils/general"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useData } from "../../providers/DataProvider"
import { FontAwesome5 } from "@expo/vector-icons"
import { STATE_VIEW_PARAMS_ITEM } from "../../data/params"
import { isOnlyInvestor } from "../../utils/validation"

export default function FlatListTaskItem({ navigation, task, viewType, setBulkTasks, bulkTasks }) {
  const { users, currentUser } = useData()
  const styles = getStyles(viewType)

  function handleCheck() {
    setBulkTasks(
      bulkTasks.map((t) => {
        if (t.id === task.id) {
          task.isDone = !task.isDone
          return task
        }
        return t
      })
    )
  }

  async function handleTasksRedirection() {
    const splitArray = task.link.split("/")
    const taskType = splitArray[0]
    const isDiaryTask = splitArray.includes("dairyEntries")

    if (taskType === "projects" && !isDiaryTask) {
      // task is done by user click
      const projectId = task.link.split("/")[1]
      const project = await FSStoreContent.loadProject(projectId)
      if (isOnlyInvestor(currentUser)) {
        navigation.navigate("ProjectDiaryDetail", { projectId })
      } else {
        navigation.navigate("ProjectDetail", { project })
      }
    } else if (taskType === "accountingDocuments") {
      // done task will disappear (also when bulk action)
      const documentId = task.link.split("/")[1]
      navigation.navigate("ApprovingAccountingDocumentDetail", { documentId: documentId })
    } else if (taskType === "cashDesks") {
      // task is done by user click
      const cashDeskUser = task.link.split("/")[1]
      navigation.navigate("CashDeskMonths", {
        user: { email: cashDeskUser },
        title: `Pokladna ${getUserName(users, cashDeskUser)}`,
      })
    } else if (taskType === "attendances") {
      // done task will disappear
      const attendanceUserEmail = task.link.split("/")[1]
      const attendanceDate = task.attendance_date.toDate()
      const dateTitle = `${attendanceDate.getDate()}. ${
        monthNameLabels[attendanceDate.getMonth()]
      } ${attendanceDate.getFullYear()}`

      navigation.navigate("AttendanceDayDetail", {
        userEmail: attendanceUserEmail,
        dateTitle,
        dayAttendance: { attendance_date: attendanceDate },
        taskAttendanceDate: attendanceDate,
        task,
        freeDaysCount: 1,
      })
    } else if (taskType === "logBook") {
      // task is done by user click
      const logBookUserEmail = task.link.split("/")[1]
      const logBookUser = users.find((u) => u.email === logBookUserEmail)
      navigation.navigate("LogBookMonths", { user: logBookUser, title: `Kniha jízd ${logBookUser.name}` })
    } else if (taskType === "property") {
      // done task will disappear
      const propertyId = task.link.split("/")[2]
      navigation.navigate("PropertyDetail", { id: propertyId, task })
    } else if (taskType === "premiums") {
      // done task will disappear
      const premiumUserEmail = task.link.split("/")[1]
      const premiumUser = users.find((u) => u.email === premiumUserEmail)
      navigation.navigate("PremiumUserProjects", {
        user: premiumUser,
        title: `Prémie ${getUserName(users, premiumUserEmail)}`,
        task,
      })
    }

    if (task.action === taskActionEnum.VIEW) {
      FSStoreContent.updateTask({ ...task, isDone: true })
      //task.link for diary tasks looks like this projects/projectId/dairyEntries/diaryEntryId
      //==> splitArray[1]=projectId, splitArray[3]=diaryEntryId
      if (isDiaryTask) {
        navigation.navigate("DiaryEntryDetail", { projectId: splitArray[1], diaryEntryId: splitArray[3] })
      }
    }
  }

  if (viewType === viewTypeEnum.BULK_ACTION) {
    return (
      <TouchableWithoutFeedback onPress={handleCheck}>
        <View style={[styles.card, { borderColor: STATE_VIEW_PARAMS_ITEM[!task.isDone].color }]}>
          <View style={{ justifyContent: "center" }}>
            <FontAwesome5
              name="tasks"
              size={DefaultTheme.icons.flatlistSize}
              color={STATE_VIEW_PARAMS_ITEM[!task.isDone].color}
            />
          </View>
          <View style={{ flex: 1, justifyContent: "center", paddingLeft: 15 }}>
            <Text>{task.title}</Text>
            <Text>{`${toCurrentDateTime(task.created_at)}`.slice(0, 24)}</Text>
          </View>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Text>{task.desc}</Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
    )
  }

  if (viewType === viewTypeEnum.EMPTY) {
    return (
      <View style={[styles.card, { borderColor: STATE_VIEW_PARAMS_ITEM[!task.isDone].color }]}>
        <View style={{ justifyContent: "center" }}>
          <FontAwesome5
            name="tasks"
            size={DefaultTheme.icons.flatlistSize}
            color={STATE_VIEW_PARAMS_ITEM[!task.isDone].color}
          />
        </View>
        <View style={{ flex: 1, justifyContent: "center", paddingLeft: 15 }}>
          <Text>{task.title}</Text>
        </View>
      </View>
    )
  }

  return (
    <TouchableOpacity onPress={handleTasksRedirection} style={Platform.OS === "web" && { cursor: "pointer" }}>
      <View style={[styles.card, { borderColor: DefaultTheme.colors.primary }]}>
        <View style={{ justifyContent: "center" }}>
          <FontAwesome5 name="tasks" size={DefaultTheme.icons.flatlistSize} color={DefaultTheme.colors.primary} />
        </View>
        <View style={{ flex: 1, justifyContent: "center", paddingLeft: 15 }}>
          <Text>{task.title}</Text>
          <Text>{`${toCurrentDateTime(task.created_at)}`.slice(0, 24)}</Text>
        </View>
        <View style={{ flex: 1, justifyContent: "center" }}>
          <Text>{task.desc}</Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}

function getStyles(viewType) {
  return StyleSheet.create({
    card: {
      height: viewType === viewTypeEnum.EMPTY ? 40 : 100,
      justifyContent: "space-between",
      width: "100%",
      paddingLeft: 15,
      paddingRight: 10,
      flexDirection: "row",
      backgroundColor: DefaultTheme.colors.white,
      borderLeftWidth: 10,
    },
  })
}
