import React, { useEffect, useRef, useState } from "react"
import { Platform, Text, View } from "react-native"

import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import NextButton from "../../components/NextButton"
import { statusEnum } from "../../data/enums"
import { useStatus } from "../../providers/StatusProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import FSStoreContent from "../../firebase/FSStoreContent"
import { TextInput } from "react-native-paper"
import { uploadFileOnFirebase } from "../../firebase/FSGeneral"
import * as Device from "expo-device"
import { getRoutes } from "../../utils/general"
import Constants from "expo-constants"

export default function RBBankRegister({ navigation }) {
  const { setStatus, setMessage, setTitle } = useStatus()
  const [data, setData] = useState({})
  const domain = `rbbank`
  console.debug("DOMAIN", domain)

  useEffect(() => {
    try {
      FSStoreContent.onSnapshotApiSecret(
        setData,
        domain,
      )
    } catch (error) {
      console.error("Error on snapshot api secret: ", error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }, [])

  function changePropertyValue(prop, value) {
    setData({ ...data, [prop]: value })
  }

  async function handleSave() {
    console.debug("DATA", data)
    try {
      setTitle("Ukládám nastavení")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")
      await FSStoreContent.updateApiSecret(data, domain)
      setMessage(`IBAN - ${data.iban}`)
      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      console.error(error)
      setTitle("Ukládám nastavení")
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }
  //
  // const { updatePercentage } = useStatus()
  //
  // async function handleUploadKeystore(localUri) {
  //   setTitle("Nahrávám keystore")
  //   setStatus(statusEnum.PROGRESS_DETERMINED)
  //   navigation.navigate("StatusScreen")
  //
  //   try {
  //     const remoteUri = await uploadFileOnFirebase(localUri, updatePercentage, "keystore")
  //     changePropertyValue("keystore", remoteUri)
  //     navigation.pop()
  //     setStatus(statusEnum.SUCCESS)
  //   } catch (error) {
  //     console.error(error)
  //     setTitle("Chyba keystore")
  //     setMessage(error.message)
  //     setStatus(statusEnum.ERROR)
  //   }
  // }

  return (
    <Screen>
      <View style={{ flex: 1 }}>
        <Text style={{ padding: 10 }}>Bankovní účet</Text>
        <TextInput
          style={[DefaultTheme.input, { marginTop: 10 }]}
          label={data.iban ? "IBAN" : "Zadejte IBAN"}
          onChangeText={(value) => changePropertyValue("iban", value)}
          value={data.iban}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          style={[DefaultTheme.input, { marginTop: 10 }]}
          label={data.accountNumber ? "Číslo účtu" : "Zadejte číslo účtu"}
          onChangeText={(value) => changePropertyValue("accountNumber", value)}
          value={data.accountNumber}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          style={[DefaultTheme.input, { marginTop: 10 }]}
          label={data.accountNumberPrefix ? "Předčíslí účtu" : "Zadejte předčíslí účtu"}
          onChangeText={(value) => changePropertyValue("accountNumberPrefix", value)}
          value={data.accountNumberPrefix}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          style={[DefaultTheme.input, { marginTop: 10 }]}
          label={data.bankCode ? "Kod banky" : "Zadejte kod banky"}
          onChangeText={(value) => changePropertyValue("bankCode", value)}
          value={data.bankCode}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          style={DefaultTheme.input}
          label="Client id"
          onChangeText={(value) => changePropertyValue("clientId", value)}
          value={data.clientId}
        />
        <TextInput
          style={DefaultTheme.input}
          label="apiEnv = mock / api"
          onChangeText={(value) => changePropertyValue("apiEnv", value)}
          value={data.apiEnv}
        />
      </View>
      <NextButton text="Uložit" onPress={handleSave} />
    </Screen>
  )
}
