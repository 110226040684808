import React, { useState } from "react"
import { TouchableOpacity, Text, StyleSheet, View } from "react-native"
import { FontAwesome } from "@expo/vector-icons"

import { actionEnum, documentClosingItemsStateEnum, documentStateEnum, monthStateEnum, statusEnum } from "../data/enums"
import { firebase } from "../firebase/config"
import { useData } from "../providers/DataProvider"
import FSStoreContent from "../firebase/FSStoreContent"
import { getDocumentState } from "../utils/general"
import CurrencyFormatter from "./CurrencyFormatter"
import * as FSDiary from "../firebase/FSDiary"
import { deleteFileFromFirebase } from "../firebase/FSGeneral"

export default function StatusDecideActionButtons({ navigation, setStatus, action, setMessage, setToScreen }) {
  const { currentUser, settings } = useData()

  function getNextYearMonth(yearMonth) {
    const year = Number(yearMonth.split("_")[0])
    const month = Number(yearMonth.split("_")[1])

    if (month < 11) {
      return `${year}_${month + 1}`
    } else {
      return `${year + 1}_${0}`
    }
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={[styles.button, { backgroundColor: "white", marginRight: "auto" }]}
        onPress={() => navigation.pop()}
      >
        <FontAwesome name="remove" size={60} color="red" />
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.button, { backgroundColor: "white" }]}
        onPress={async () => {
          if (action.type === actionEnum.DELETE_USER) {
            try {
              const user = action.params.user
              console.info("User: ", user)
              delete user.name
              delete user.password
              user.state = "DELETED"
              await firebase.apps[0].firestore().collection("users").doc(user.email).set(user)
              setMessage("Uživatel byl úspěšně smazán!")
              setStatus(statusEnum.SUCCESS)
              setToScreen(action.params.toScreen)
            } catch (error) {
              setMessage("Vymazat uživatele se nepodařilo " + error)
              console.error("Error while deleting user: ", error)
              setStatus(statusEnum.ERROR)
            }
          }

          if (action.type === actionEnum.DELETE_PROPERTY_ITEM) {
            try {
              await firebase.apps[0].firestore().collection("property").doc(action.params.id).delete()
              setMessage("Majetek byl úspěšně smazán!")
              setStatus(statusEnum.SUCCESS)
              setToScreen(action.params.toScreen)
            } catch (error) {
              setMessage("Vymazat majetek se nepodařilo" + error.message)
              console.error("Error deleting propertyItem ", error)
              setStatus(statusEnum.ERROR)
            }
          }
          if (action.type === actionEnum.DELETE_LOG_BOOK_ITEM) {
            try {
              const monthLogBook = action.params.monthLogBook
              monthLogBook.logs = monthLogBook.logs.filter((el) => {
                if (el.id !== action.params.logItemId) {
                  return el
                }
              })
              await FSStoreContent.updateMonthLogBook(monthLogBook)
              setMessage("Jízda byla úspěšně smazána!")
              setStatus(statusEnum.SUCCESS)
              setToScreen(action.params.toScreen)
            } catch (error) {
              setMessage("Vymazat jízdu se nepodařilo" + error.message)
              console.error("Error deleting logBookItem ", error)
              setStatus(statusEnum.ERROR)
            }
          }
          if (action.type === actionEnum.DELETE_ACCOUNTING_DOCUMENT) {
            try {
              console.debug("Deleting accountingDocument " + action.params.document.id)

              // TODO delete with image from storage
              const document = action.params.document

              await firebase.apps[0].firestore().collection("accountingDocuments").doc(document.id).delete()
              console.debug("Document deleted " + action.params.document.id)

              if (document.task_id) {
                await FSStoreContent.deleteTask(document.task_id)
                if (document.unit_task_id) {
                  await FSStoreContent.deleteTask(document.unit_task_id)
                }
              }

              setMessage("Doklad byl úspěšně smazán!")
              setStatus(statusEnum.SUCCESS)
              setToScreen(action.params.toScreen)
            } catch (error) {
              setMessage("Vymazat doklad se nepodařilo" + error.message)
              console.error("Error deleting accountingDocument ", error)
              setStatus(statusEnum.ERROR)
            }
          } else if (action.type === actionEnum.DELETE_DOCUMENT) {
            try {
              await firebase.apps[0].firestore().collection("documents").doc(action.params.id).delete()

              console.debug("Document deleted " + action.params.id)
              setMessage("Dokument byl úspěšně smazán!")
              setStatus(statusEnum.SUCCESS)
              setToScreen(action.params.toScreen)
            } catch (error) {
              setMessage("Vymazat doklad se nepodařilo" + error.message)
              console.error("Error deleting companyDocumentP ", error)
              setStatus(statusEnum.ERROR)
            }
          } else if (action.type === actionEnum.DELETE_USER_FROM_PROJECT) {
            action.params.deleteUser()
            navigation.pop()
          } else if (action.type === actionEnum.CASH_DESK_CLOSE_MONTH) {
            setStatus(statusEnum.PROGRESS_UNDETERMINED)
            try {
              if (action.params.monthDocs.some((doc) => getDocumentState(doc) === documentStateEnum.PENDING)) {
                throw {
                  message: "Pokladna nesmí před uzavřením obsahovat doklad čekající na schválení.",
                }
              }

              let cashDesk = action.params.cashDesk
              const yearMonth = action.params.yearMonth
              const balance = action.params.balance || 0

              const closing_item = {
                closing_state: documentClosingItemsStateEnum.CLOSED,
                closing_at: new Date(),
                closing_by: currentUser.email,
              }
              if (!cashDesk[yearMonth]?.closing_items) {
                cashDesk = { ...cashDesk, [yearMonth]: { closing_items: [closing_item] } }
              } else {
                cashDesk[yearMonth].closing_items.push(closing_item)
              }
              cashDesk = {
                ...cashDesk,
                [getNextYearMonth(yearMonth)]: {
                  ...(cashDesk[getNextYearMonth(yearMonth)] || {}),
                  charges: { residual_charge: balance },
                },
              }

              await FSStoreContent.updateCashDesk(cashDesk)

              setStatus(statusEnum.SUCCESS)
              setMessage(
                `Měsíční uzávěrka byla provedena! Zůstatek ${CurrencyFormatter(
                  balance,
                  "CZK",
                  1
                )} byl automaticky převeden do dalšího měsíce.`
              )
            } catch (error) {
              setStatus(statusEnum.ERROR)
              setMessage(error.message)
            }
          } else if (action.type === actionEnum.CASH_DESK_OPEN_MONTH) {
            setStatus(statusEnum.PROGRESS_UNDETERMINED)
            try {
              let cashDesk = action.params.cashDesk
              const yearMonth = action.params.yearMonth

              const closing_item = {
                closing_state: documentClosingItemsStateEnum.OPEN,
                closing_at: new Date(),
                closing_by: currentUser.email,
              }
              if (!cashDesk[yearMonth]?.closing_items) {
                cashDesk = { ...cashDesk, [yearMonth]: { closing_items: [closing_item] } }
              } else {
                cashDesk[yearMonth].closing_items.push(closing_item)
              }

              await FSStoreContent.updateCashDesk(cashDesk)
              setStatus(statusEnum.SUCCESS)
              setMessage("Měsíc byl opět otevřen!")
            } catch (error) {
              setStatus(statusEnum.ERROR)
              setMessage(error.message)
            }
          } else if (action.type === actionEnum.LOG_BOOK_OPEN_MONTH) {
            setStatus(statusEnum.PROGRESS_UNDETERMINED)
            try {
              let monthLogBook = action.params.monthLogBook
              const closing_item = {
                closing_state: monthStateEnum.OPEN,
                closing_at: new Date(),
                closing_by: currentUser.email,
              }
              if (!monthLogBook.closing_items) {
                monthLogBook = { ...monthLogBook, closing_items: [closing_item] }
              } else {
                monthLogBook.closing_items.push(closing_item)
              }

              await FSStoreContent.setLogBookMonthDetail(monthLogBook)
              setStatus(statusEnum.SUCCESS)
              setMessage("Měsíc byl opět otevřen!")
            } catch (error) {
              setStatus(statusEnum.ERROR)
              setMessage(error.message)
            }
          } else if (action.type === actionEnum.LOG_BOOK_CLOSE_MONTH) {
            setStatus(statusEnum.PROGRESS_UNDETERMINED)
            try {
              let monthLogBook = action.params.monthLogBook
              const closing_item = {
                closing_state: monthStateEnum.CLOSED,
                closing_at: new Date(),
                closing_by: currentUser.email,
              }
              if (!monthLogBook.closing_items) {
                monthLogBook = { ...monthLogBook, closing_items: [closing_item] }
              } else {
                monthLogBook.closing_items.push(closing_item)
              }

              await FSStoreContent.setLogBookMonthDetail(monthLogBook)
              setStatus(statusEnum.SUCCESS)
              setMessage("Měsíc byl zavřen!")
            } catch (error) {
              setStatus(statusEnum.ERROR)
              setMessage(error.message)
            }
          } else if (action.type === actionEnum.PREMIUM_REQUEST) {
            setStatus(statusEnum.PROGRESS_UNDETERMINED)

            try {
              const user = action.params.user
              const userPremiumActions = action.params.userPremiumActions

              await FSStoreContent.updateUserPremiums(user, userPremiumActions)
              setStatus(statusEnum.SUCCESS)
              setMessage("O prémie bylo požádáno!")
            } catch (error) {
              setMessage(error.message)
              setStatus(statusEnum.ERROR)
            }
          } else if (action.type === actionEnum.PAY_AGAIN) {
            try {
              setStatus(statusEnum.PROGRESS_UNDETERMINED)
              const document = action.params.document

              const tempDocument = await FSStoreContent.loadDocument(document.id)

              const paymentInfo = tempDocument.payment_info || {}

              await FSStoreContent.updateAccountingDocument({
                ...tempDocument,
                payment_info: { ...paymentInfo, pay_again: true },
              })

              setMessage("")
              setStatus(statusEnum.SUCCESS)
              setToScreen(action.params.toScreen)
            } catch (error) {
              setMessage(error.message)
              console.error("Error updating document", error)
              setStatus(statusEnum.ERROR)
            }
          } else if (action.type === actionEnum.DELETE_DIARY_ENTRY) {
            try {
              const { projectId, diaryEntryId } = action.params

              setStatus(statusEnum.PROGRESS_UNDETERMINED)

              await FSDiary.deleteEntry(projectId, diaryEntryId)

              setStatus(statusEnum.SUCCESS)
              setToScreen(action.params.toScreen)
            } catch (error) {
              setMessage(error.message)
              console.error("Error deleting diary entry", error)
              setStatus(statusEnum.ERROR)
            }
          } else if (action.type === actionEnum.DELETE_TEMPLATE) {
            try {
              const {
                docId,
                propName,
                template: { id, filestore_uri },
              } = action.params

              setStatus(statusEnum.PROGRESS_UNDETERMINED)

              const templates = settings[docId]?.[propName] || []
              const newTemplates = templates.filter((t) => t.id !== id)

              await FSStoreContent.updateSettings({ ...settings[docId], id: docId, [propName]: newTemplates })
              await deleteFileFromFirebase(filestore_uri)

              setStatus(statusEnum.SUCCESS)
              setToScreen(action.params.toScreen)
            } catch (error) {
              setMessage(error.message)
              console.error("Error deleting template", error)
              setStatus(statusEnum.ERROR)
            }
          } else if (action.type === actionEnum.ATTENDANCES_CLOSE_MONTH) {
            try {
              const { closingItems, closingItemsIndex, updatedBy, user } = action.params
              const temp = { ...closingItems }
              if (!temp[closingItemsIndex]) {
                temp[closingItemsIndex] = []
              }
              temp[closingItemsIndex].push({
                closing_at: new Date(),
                closing_state: "CLOSED",
                closing_by: updatedBy,
              })

              await FSStoreContent.updateAttendance({ userEmail: user, closing_items: temp })
              setStatus(statusEnum.SUCCESS)
              setMessage("Měsíc byl uzamčen!")
            } catch (error) {
              setStatus(statusEnum.ERROR)
              setMessage(error.message)
            }
          } else if (action.type === actionEnum.ATTENDANCES_OPEN_MONTH) {
            try {
              const { closingItems, closingItemsIndex, updatedBy, user } = action.params
              const temp = { ...closingItems }
              if (!closingItems[closingItemsIndex]) {
                temp[closingItemsIndex] = []
              }
              temp[closingItemsIndex].push({
                closing_at: new Date(),
                closing_state: "OPEN",
                closing_by: updatedBy,
              })

              await FSStoreContent.updateAttendance({ userEmail: user, closing_items: temp })
              setStatus(statusEnum.SUCCESS)
              setMessage("Měsíc byl odemčen!")
            } catch (error) {
              setStatus(statusEnum.ERROR)
              setMessage(error.message)
            }
          } else if (action.type === actionEnum.EXEC_LABEL) {
            try {
              const { document, toScreen } = action.params
              await FSStoreContent.updateAccountingDocument({
                ...document,
                exec_labeled: document.hasOwnProperty("exec_labeled") ? !document.exec_labeled : true,
              })
              setStatus(statusEnum.SUCCESS)
              setMessage("Změna úspěšně provedena")
              setToScreen(toScreen)
            } catch (error) {
              setStatus(statusEnum.ERROR)
              setMessage(error.message)
            }
          } else if (action.type === actionEnum.DELETE_CASHFLOW_ITEM) {
            try {
              const { document, toScreen } = action.params
              await FSStoreContent.deleteExtraCashflowDoc(document.id)
              setStatus(statusEnum.SUCCESS)
              setMessage("Změna úspěšně provedena")
              setToScreen(toScreen)
            } catch (error) {
              setStatus(statusEnum.ERROR)
              setMessage(error.message)
            }
          } else if (action.type === actionEnum.DELETE_TRIVI_DOCUMENT) {
            const { triviDocumentId } = action.params
            console.log("Start deleting trivi document id:" + triviDocumentId)
            try {
              setStatus(statusEnum.PROGRESS_UNDETERMINED)
              await FSStoreContent.deleteTriviDocument(triviDocumentId)
              setStatus(statusEnum.SUCCESS)
              setToScreen(action.params.toScreen)
            } catch (error) {
              setMessage(error.message)
              console.error("Error deleting trivi document id:" + triviDocumentId + " " + error)
              setStatus(statusEnum.ERROR)
            }
          }
        }}
      >
        <FontAwesome name="check" size={60} color="green" />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    minWidth: 250,
    paddingBottom: 50,
  },
  button: {
    minWidth: 50,
    minHeight: 50,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
})
