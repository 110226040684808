import { useNavigation } from "@react-navigation/core"
import React, { useEffect, useState } from "react"
import { Text, View } from "react-native"
import { TextInput } from "react-native-paper"
import AppPicker from "../../components/AppPicker"
import NextButton from "../../components/NextButton"
import Screen from "../../components/Screen"
import { statusEnum } from "../../data/enums"
import { dayNameLabels } from "../../data/labels"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useData } from "../../providers/DataProvider"
import { useStatus } from "../../providers/StatusProvider"
import DefaultTheme from "../../themes/DefaultTheme"

export default function BankRecurringAutomat({ navigation }) {
  const { setTitle, setStatus, setMessage } = useStatus()
  const { settings } = useData()
  const [settingsData, setSettingsData] = useState({})
  const appPickerHourItems = []
  const appPickerMinuteItems = []
  const appPickerDayItems = Object.keys(dayNameLabels).map((key) => ({
    label: dayNameLabels[key],
    value: key,
    key: key,
  }))
  console.debug("RECURRING", settingsData)

  const sunday = appPickerDayItems.shift()
  appPickerDayItems.push(sunday)

  for (let i = 0; i < 60; i++) {
    if (0 <= i && i <= 23) {
      appPickerHourItems.push({ label: `${i}`, value: `${i}`, key: i })
    }
    appPickerMinuteItems.push({ label: `${i}`.length === 1 ? `0${i}` : `${i}`, value: `${i}`, key: i })
  }

  useEffect(() => {
    const tempSett = settings["kb_bank"] || {}
    setSettingsData(
      tempSett.recurring_at
        ? tempSett
        : { ...tempSett, id: "kb_bank", recurring_at: { day: "5", hour: "17", minute: "00" } }
    )
  }, [settings])

  function changePropertyValue(prop, value) {
    setSettingsData({ ...settingsData, [prop]: value })
  }

  async function handleSave() {
    try {
      setTitle("Ukládám nastavení")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      await FSStoreContent.updateSettings(settingsData)

      setMessage(`IBAN - ${settingsData.iban}`)
      // setMessage(
      //   `Opakované platby - ${dayNameLabels[settingsData.recurring_at.day]} [${settingsData.recurring_at.hour}:${
      //     settingsData.recurring_at.minute
      //   }]\nIBAN - ${settingsData.iban}`
      // )
      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      console.error(error)
      setTitle("Ukládám nastavení")
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  return (
    <Screen>
      <View style={{ flex: 1 }}>
        {/* <Text style={{ padding: 10 }}>Opakované platby</Text>
        <View style={{ flexDirection: "row" }}>
          <View style={{ width: 120 }}>
            <AppPicker
              descriptionLabel="Hodina"
              items={appPickerHourItems}
              onValueChange={(value) => changePropertyValue("hour", value)}
              placeholder={{}}
              value={settingsData.recurring_at?.hour}
            />
          </View>
          <View style={{ width: 120 }}>
            <AppPicker
              descriptionLabel="Minuta"
              items={appPickerMinuteItems}
              onValueChange={(value) => changePropertyValue("minute", value)}
              placeholder={{}}
              value={settingsData.recurring_at?.minute}
            />
          </View>
        </View>
        <AppPicker
          descriptionLabel="Den"
          items={appPickerDayItems}
          onValueChange={(value) => changePropertyValue("day", value)}
          placeholder={{}}
          value={settingsData.recurring_at?.day}
        /> */}
        <Text style={{ padding: 10 }}>Účet ze kterého budou odcházet peníze</Text>
        <TextInput
          style={[DefaultTheme.input, { marginTop: 10 }]}
          label={settingsData.iban ? "IBAN" : "Zadejte IBAN"}
          onChangeText={(value) => changePropertyValue("iban", value)}
          value={settingsData.iban}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
      </View>
      <NextButton text="Uložit" onPress={handleSave} />
    </Screen>
  )
}
