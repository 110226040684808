import React from "react"
import { TouchableOpacity, Text, View } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import { FontAwesome5 } from "@expo/vector-icons"
import CurrencyFormatter from "../CurrencyFormatter"
import { documentAccountingTypeEnum } from "../../data/enums"

export default function FlatListPickerItem({ document, setDocument, item, data, setData, disabled = false }) {
  function handlePickedDataChange() {
    setData(
      data.map((el) => {
        if (el.id === item.id) {
          return { ...el, isSelected: !el.isSelected }
        }
        return el
      })
    )

    const tempArr = document.accounting_info_internal?.advance_documents || []
    if (tempArr.includes(item.id) && tempArr.length > 0) {
      tempArr.splice(tempArr.indexOf(item.id), 1)
    } else {
      tempArr.push(item.id)
    }
    setDocument({
      ...document,
      accounting_info_internal: { type: documentAccountingTypeEnum.INVOICE, advance_documents: tempArr },
    })
  }

  return (
    <TouchableOpacity
      onPress={handlePickedDataChange}
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 5,
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
      disabled={disabled}
    >
      <View style={{ flexDirection: "row" }}>
        <FontAwesome5
          name="file-invoice-dollar"
          size={DefaultTheme.icons.s}
          color={item.isSelected ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled}
        />
        <Text style={{ paddingLeft: 10 }}>{item.name}</Text>
      </View>
      <Text>{CurrencyFormatter(item.price, "CZK", 1)}</Text>
    </TouchableOpacity>
  )
}
