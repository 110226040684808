import React, { useEffect, useState } from "react"
import {
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native"
import { TextInput } from "react-native-paper"
import { Ionicons, MaterialCommunityIcons, FontAwesome5 } from "@expo/vector-icons"

import DocumentImage from "../../components/DocumentImage"
import { openFileStorage } from "../../actions/platformFeatureRequestor"
import Screen from "../../components/Screen"
import {
  scanTypeEnum,
  documentTypeEnum,
  projectStateEnum,
  scansOverviewViewTypeEnum,
  statusEnum,
} from "../../data/enums"
import { uploadFileOnFirebase } from "../../firebase/FSGeneral"
import { useStatus } from "../../providers/StatusProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import AppDatePicker from "../../components/AppDatePicker"
import NextButton from "../../components/NextButton"
import { useData } from "../../providers/DataProvider"
import ItemsCounter from "../../components/ItemsCounter"
import PrimButton from "../../components/PrimButton"
import DeleteButton from "../../components/DeleteButton"
import { getUserName, toCurrentDateTime } from "../../utils/general"
import FSDiary from "../../firebase/FSDiary"
import { isLink, isOnlyInvestor } from "../../utils/validation"

export default function DiaryDocumentDetail({ route, navigation }) {
  const { projects, currentUser } = useData()
  const { setStatus, setTitle, setMessage, updatePercentage } = useStatus()
  const [imageLoading, setImageLoading] = useState(false)
  const viewType = route.params?.viewType
  const [diaryDocument, setDiaryDocument] = useState(
    route.params?.diaryDocument || {
      filestore_uri_list: [],
      date: new Date(),
      number: "",
      subject: "",
      link: "",
      ...(viewType === documentTypeEnum.CONTRACT ? { parties: [] } : { comments: [] }),
    }
  )
  const documentPropLabel = viewType === documentTypeEnum.CONTRACT ? "parties" : "comments"
  const [isUploadingNewImage, setIsUploadingNewImage] = useState(false)
  const [isProjectLocked, setIsProjectLocked] = useState(false)
  const projectId = route.params?.projectId

  console.debug("ROUTE", route)
  console.debug("PROJECT ID", projectId)
  console.debug("DIARY DOC", diaryDocument)

  useEffect(() => {
    setIsProjectLocked(projects.find((p) => p.id === projectId)?.state === projectStateEnum.CLOSED)
  }, [projects])

  useEffect(() => {
    FSDiary.onSnapshotDocument(projectId, route.params?.diaryDocument?.id, (newDiaryDocument) => {
      console.debug("NEW DIARY DOC", newDiaryDocument)
      setDiaryDocument(newDiaryDocument)
    })
  }, [route.params])

  async function handleChangePdf() {
    try {
      const { localUri, fileExt } = await openFileStorage()

      if (localUri) {
        if (!(fileExt === "pdf" || fileExt === "PDF")) {
          throw { message: "Soubor musí být formátu PDF!" }
        }
        setIsUploadingNewImage(true)
        setTitle(`Nahrávám pdf ${viewType === documentTypeEnum.CONTRACT ? "smlouvy" : "dokumentace"}`)
        setStatus(statusEnum.PROGRESS_DETERMINED)
        navigation.navigate("StatusScreen")

        const remoteUri = await uploadFileOnFirebase(
          localUri,
          updatePercentage,
          viewType === documentTypeEnum.CONTRACT ? "contracts" : "documentation"
        )

        setStatus(statusEnum.SUCCESS)
        setMessage(`Pdf ${viewType === documentTypeEnum.CONTRACT ? "smlouvy" : "dokumentace"} byl úspěšně nahrán!`)
        setDiaryDocument({ ...diaryDocument, type: scanTypeEnum.FILE, filestore_uri_list: [remoteUri] })
        setIsUploadingNewImage(false)
      }
    } catch (error) {
      console.error(error)
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  function changePropertyValue(prop, value) {
    setDiaryDocument({ ...diaryDocument, [prop]: value })
  }

  async function handleSave() {
    try {
      setTitle(`Nahrávám ${viewType === documentTypeEnum.CONTRACT ? "smlouvu" : "dokumentaci"}`)
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      const requiredData = {
        doc_type: viewType,
        updated_at: new Date(),
        updated_by: currentUser.email,
      }

      if (diaryDocument.id) {
        await FSDiary.updateDocument(projectId, { ...diaryDocument, ...requiredData })
      } else {
        await FSDiary.addDocument(projectId, { ...diaryDocument, ...requiredData })
      }

      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      console.error(error)
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  function handleAddListItem() {
    setDiaryDocument({
      ...diaryDocument,
      [documentPropLabel]: [
        ...diaryDocument[documentPropLabel],
        {
          key: diaryDocument[documentPropLabel].length + 1,
          value: "",
          created_by: currentUser.email,
          created_at: new Date(),
        },
      ],
    })
  }

  function isSaveDisabled() {
    return (
      !(diaryDocument.subject && diaryDocument.number) ||
      (diaryDocument[documentPropLabel]?.length > 0 &&
        !diaryDocument[documentPropLabel]?.every((prop) => prop.value)) ||
      isProjectLocked
    )
  }

  return (
    <Screen>
      <View style={{ flex: 1 }}>
        {(imageLoading || diaryDocument.filestore_uri_list?.length === 0) && (
          <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
            <Text style={{ fontSize: DefaultTheme.fonts.regular }}>
              {`${diaryDocument.filestore_uri_list?.length > 0 ? "Načítám obrázek" : "Nahrajte prosím obrázek"} ${
                viewType === documentTypeEnum.CONTRACT ? "smlouvy" : "dokumentace"
              }`}
            </Text>
          </View>
        )}
        <View
          style={[
            { flex: 1, overflow: "hidden" },
            (imageLoading || diaryDocument.filestore_uri_list?.length === 0) && {
              transform: [{ scale: 0 }],
              flex: 0,
            },
          ]}
        >
          {!isUploadingNewImage && (
            <DocumentImage
              onLoadEnd={() => setImageLoading(false)}
              type={diaryDocument.type}
              uriList={diaryDocument.filestore_uri_list}
            />
          )}
        </View>
        {!imageLoading && (
          <View style={{ position: "absolute", width: "100%" }}>
            <TouchableOpacity
              disabled={isProjectLocked || isOnlyInvestor(currentUser)}
              style={styles.imageRetake}
              onPress={() => {
                navigation.navigate("ScansOverview", {
                  docType: viewType,
                  document: diaryDocument,
                  projectId,
                  uriList: diaryDocument.type === scanTypeEnum.FILE ? [] : diaryDocument.filestore_uri_list,
                  viewType: scansOverviewViewTypeEnum.DOC_CHANGE,
                })
              }}
            >
              <Ionicons
                name="documents-sharp"
                size={DefaultTheme.icons.l}
                color={isProjectLocked ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary}
              />
            </TouchableOpacity>
            <TouchableOpacity
              disabled={isProjectLocked || isOnlyInvestor(currentUser)}
              style={styles.fileSystemRetake}
              onPress={handleChangePdf}
            >
              <MaterialCommunityIcons
                name="file-pdf-box"
                size={DefaultTheme.icons.l}
                color={isProjectLocked ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary}
              />
            </TouchableOpacity>
          </View>
        )}
      </View>
      <ScrollView style={{ flex: 1, paddingVertical: 10 }}>
        <AppDatePicker
          disabled={isProjectLocked || isOnlyInvestor(currentUser)}
          label={`Datum ${viewType === documentTypeEnum.CONTRACT ? "uzavření" : "převzetí"}`}
          webLabel={[
            `Den ${viewType === documentTypeEnum.CONTRACT ? "uzavření" : "převzetí"}`,
            `Měsíc ${viewType === documentTypeEnum.CONTRACT ? "uzavření" : "převzetí"}`,
            `Rok ${viewType === documentTypeEnum.CONTRACT ? "uzavření" : "převzetí"}`,
          ]}
          value={diaryDocument.date}
          onConfirm={(date) => changePropertyValue("date", date || new Date())}
        />
        <TextInput
          disabled={isProjectLocked || isOnlyInvestor(currentUser)}
          error={!diaryDocument.subject}
          style={[DefaultTheme.input, { marginTop: 10 }]}
          label={`${diaryDocument.subject ? "Předmět" : "Zadejte předmět"} ${
            viewType === documentTypeEnum.CONTRACT ? "smlouvy" : "revize"
          }`}
          onChangeText={(value) => changePropertyValue("subject", value)}
          value={diaryDocument.subject}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          disabled={isProjectLocked || isOnlyInvestor(currentUser)}
          error={!diaryDocument.number}
          style={[DefaultTheme.input, { marginTop: 10 }]}
          label={`${diaryDocument.number ? "Číslo" : "Zadejte číslo"} ${
            viewType === documentTypeEnum.CONTRACT ? "smlouvy" : "dokumentace"
          }`}
          onChangeText={(value) => changePropertyValue("number", value)}
          value={diaryDocument.number}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          disabled={isProjectLocked || isOnlyInvestor(currentUser)}
          error={!isLink(diaryDocument.link)}
          style={[DefaultTheme.input, { marginTop: 10 }]}
          label={diaryDocument.link ? "Link" : "Zadejte link"}
          onChangeText={(value) => changePropertyValue("link", value)}
          value={diaryDocument.link}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <InputList
          disabled={isProjectLocked || (viewType === documentTypeEnum.CONTRACT ? isOnlyInvestor(currentUser) : false)}
          addItem={handleAddListItem}
          inputLabel={{
            [true]: `${viewType === documentTypeEnum.CONTRACT ? "Smluvní strana" : "Připomínka"}`,
            [false]: `${viewType === documentTypeEnum.CONTRACT ? "Zadejte smluvní stranu" : "Zadejte připomínku"}`,
          }}
          items={diaryDocument[viewType === documentTypeEnum.CONTRACT ? "parties" : "comments"]}
          deleteItem={(key) => {
            let newKey = 1
            setDiaryDocument({
              ...diaryDocument,
              [documentPropLabel]: diaryDocument[documentPropLabel]
                .filter((item) => item.key !== key)
                .map((item) => {
                  const newItem = { ...item, key: newKey }
                  newKey += 1
                  return newItem
                }),
            })
          }}
          onChangeText={(key, value) =>
            setDiaryDocument({
              ...diaryDocument,
              [documentPropLabel]: diaryDocument[documentPropLabel].map((item) =>
                item.key === key ? { ...item, value } : item
              ),
            })
          }
          title={viewType === documentTypeEnum.CONTRACT ? "Smluvní strany" : "Seznam připomínek"}
        />
      </ScrollView>
      <NextButton text="Uložit" onPress={handleSave} disabled={isSaveDisabled()} />
    </Screen>
  )
}

function InputList({ addItem, onChangeText, deleteItem, disabled = false, icon, inputLabel, items = [], title }) {
  const [pressed, setPressed] = useState(true)
  const { users } = useData()

  return (
    <React.Fragment>
      <View style={[pressed && styles.pressedItem, { marginHorizontal: 10 }]}>
        <TouchableWithoutFeedback onPress={() => setPressed(!pressed)}>
          <View style={[styles.listItem, Platform.OS === "web" && { cursor: "pointer" }]}>
            <View style={{ flexDirection: "row" }}>
              {icon}
              <Text style={styles.title}>{title}</Text>
              {items.length > 0 && <ItemsCounter value={items.length} top={-5} right={-40} />}
            </View>
            <MaterialCommunityIcons name={`chevron-${pressed ? "up" : "down"}`} size={18} color="black" />
          </View>
        </TouchableWithoutFeedback>
      </View>
      {items.map(({ key, value, created_by, created_at }) => (
        <View
          key={key}
          style={[!pressed && { transform: [{ scale: 0 }], flex: 0, display: "none" }, styles.listSubItem]}
        >
          <Text style={{ paddingTop: 10, textAlign: "right" }}>
            {getUserName(users, created_by)} {toCurrentDateTime(created_at)}
          </Text>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TextInput
              error={!value}
              style={[DefaultTheme.input, { flex: 1 }]}
              label={inputLabel[!!value]}
              onChangeText={(value) => onChangeText(key, value)}
              value={value}
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              disabled={disabled}
              numberOfLines={2}
            />
            <DeleteButton disabled={disabled} absolute={false} onPress={() => deleteItem(key)} />
          </View>
        </View>
      ))}
      <View
        style={[
          !pressed ? { transform: [{ scale: 0 }], flex: 0, display: "none" } : { padding: 10, marginBottom: 10 },
          { alignItems: "center", overflow: "hidden", marginHorizontal: 10 },
        ]}
      >
        <View style={styles.addItemBackground} />
        <PrimButton
          disabled={disabled}
          icon={<FontAwesome5 name="plus" size={18} color={DefaultTheme.colors.white} />}
          onPress={addItem}
          width={40}
        />
      </View>
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  addItemBackground: {
    backgroundColor: DefaultTheme.colors.white,
    width: Dimensions.get("window").width,
    height: 100,
    position: "absolute",
    bottom: 0,
  },
  fileSystemRetake: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  imageRetake: {
    position: "absolute",
    left: 10,
    top: 10,
  },
  listItem: {
    padding: 15,
    borderTopWidth: 1,
    borderTopColor: DefaultTheme.colors.borderPrimary,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listSubItem: {
    backgroundColor: DefaultTheme.colors.white,
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.borderPrimary,
    marginHorizontal: 10,
    paddingHorizontal: 10,
  },
  pressedItem: {
    backgroundColor: DefaultTheme.colors.white,
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.borderPrimary,
  },
  title: {
    paddingLeft: 10,
    fontWeight: "bold",
    fontSize: DefaultTheme.fonts.regular,
  },
})
