import React, { useEffect, useRef, useState } from "react"
import { FlatList, Text, View } from "react-native"
import AppPicker from "../../components/AppPicker"
import { statisticsPeriodEnum, statisticsViewPeriodEnum } from "../../data/enums"
import Screen from "../../components/Screen"
import FlatListStatisticsOverviewItem from "../../components/FlatLists/FlatListStatisticsOverviewItem"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import FSStoreContent from "../../firebase/FSStoreContent"

export default function StatisticsOverview() {
  const [period, setPeriod] = useState(statisticsPeriodEnum.THIS_YEAR)
  const [viewPeriod, setViewPeriod] = useState(statisticsViewPeriodEnum.MONTHLY)
  const [monthlyStatistics, setMonthlyStatistics] = useState([])
  const [filteredStatistics, setFilteredStatistics] = useState([])

  let today = new Date()
  const appPickerMonthlyPeriodItems = [
    { label: `Aktuální rok`, value: statisticsPeriodEnum.THIS_YEAR },
    { label: `Posl. 6 měsíců`, value: statisticsPeriodEnum.LAST_6_MONTHS },
    { label: `Posl. 12 měsíců`, value: statisticsPeriodEnum.LAST_12_MONTHS },
    { label: `Posl. 24 měsíců`, value: statisticsPeriodEnum.LAST_24_MONTHS },
  ]
  const appPickerQuarterlyPeriodItems = [
    { label: `Aktuální rok`, value: statisticsPeriodEnum.THIS_YEAR },
    { label: `Posl. 4 kvartály`, value: statisticsPeriodEnum.LAST_4_QARTERS },
    { label: `Posl. 8 kvartálů`, value: statisticsPeriodEnum.LAST_8_QARTERS },
  ]
  const appPickerYearlyPeriodItems = [
    { label: `Posl. 2 roky`, value: statisticsPeriodEnum.LAST_2_YEARS },
    { label: `Posl. 5 let`, value: statisticsPeriodEnum.LAST_5_YEARS },
  ]

  const appPickerPeriodItems = {
    [statisticsViewPeriodEnum.MONTHLY]: appPickerMonthlyPeriodItems,
    [statisticsViewPeriodEnum.QUARTERLY]: appPickerQuarterlyPeriodItems,
    [statisticsViewPeriodEnum.YEARLY]: appPickerYearlyPeriodItems,
  }

  const appPickerViewPeriodItems = [
    { label: `Měsíčně`, value: statisticsViewPeriodEnum.MONTHLY },
    { label: `Čtvrtletně`, value: statisticsViewPeriodEnum.QUARTERLY },
    { label: `Ročně`, value: statisticsViewPeriodEnum.YEARLY },
  ]

  useEffect(() => {
    FSStoreContent.onSnapshotMonthlyStatistics(setMonthlyStatistics)
  }, [])

  useEffect(() => {
    if (monthlyStatistics.length > 0) {
      filterStatistics()
    }
  }, [monthlyStatistics])

  function filterStatistics() {
    const statisticsList = []
    let monthIndexMax = 0
    let monthIndexMin = 0

    switch (period) {
      case statisticsPeriodEnum.LAST_6_MONTHS:
        monthIndexMax = 5
        break
      case statisticsPeriodEnum.LAST_4_QARTERS:
      case statisticsPeriodEnum.LAST_12_MONTHS:
        monthIndexMax = 11
        break
      case statisticsPeriodEnum.LAST_8_QARTERS:
      case statisticsPeriodEnum.LAST_24_MONTHS:
        monthIndexMax = 23
        break
      case statisticsPeriodEnum.THIS_YEAR:
        monthIndexMax = today.getMonth()
        monthIndexMin = today.getMonth() - 11
        break
      case statisticsPeriodEnum.LAST_2_YEARS:
        monthIndexMax = today.getMonth() + 12
        monthIndexMin = today.getMonth() - 11
        break
      case statisticsPeriodEnum.LAST_5_YEARS:
        monthIndexMax = today.getMonth() + 48
        monthIndexMin = today.getMonth() - 11
        break
    }
    if (viewPeriod === statisticsViewPeriodEnum.MONTHLY) {
      for (let monthIndex = monthIndexMin; monthIndex <= monthIndexMax; monthIndex++) {
        const monthStats = []

        monthlyStatistics.forEach((stats) => {
          if (checkInterval(stats.date, monthIndex)) {
            monthStats.push(stats)
          }
        })
        statisticsList.push({
          stats: monthStats,
          periodIndex: monthIndex,
          periodType: statisticsViewPeriodEnum.MONTHLY,
          // id: viewPeriod + period + monthIndex,
        })
      }
    } else if (viewPeriod === statisticsViewPeriodEnum.YEARLY) {
      for (let monthIndex = monthIndexMin; monthIndex <= monthIndexMax; monthIndex += 12) {
        const monthStats = []
        for (let i = monthIndex; i < monthIndex + 12; i++) {
          monthlyStatistics.forEach((stats) => {
            if (checkInterval(stats.date, i)) {
              monthStats.push(stats)
            }
          })
        }
        statisticsList.push({
          stats: monthStats,
          periodIndex: monthIndex,
          periodType: statisticsViewPeriodEnum.YEARLY,
          // id: viewPeriod + period + monthIndex,
        })
      }
    } else if (viewPeriod === statisticsViewPeriodEnum.QUARTERLY) {
      if (period === statisticsPeriodEnum.THIS_YEAR) {
        let quarter = 4
        let yearQuarter = today.getFullYear()
        for (let monthIndex = monthIndexMin; monthIndex <= monthIndexMax; monthIndex += 3) {
          const quarterStats = []
          monthlyStatistics.forEach((stats) => {
            if (
              checkInterval(stats.date, monthIndex) ||
              checkInterval(stats.date, monthIndex + 1) ||
              checkInterval(stats.date, monthIndex + 2)
            ) {
              quarterStats.push(stats)
            }
          })

          statisticsList.push({
            stats: quarterStats,
            periodIndex: { quarter, yearQuarter },
            periodType: statisticsViewPeriodEnum.QUARTERLY,
            // id: viewPeriod + period + quarter + yearQuarter,
          })
          quarter--
        }
      } else {
        let yearQuarter = today.getFullYear()
        let quarter = Math.floor((today.getMonth() + 3) / 3)
        if ((today.getMonth() + 1) % 3 !== 0) {
          const quartalStats = []
          if ((1 + today.getMonth()) % 3 === 2) {
            monthlyStatistics.forEach((stats) => {
              if (checkInterval(stats.date, 0) || checkInterval(stats.date, 1)) {
                quartalStats.push(stats)
              }
            })
          } else if ((1 + today.getMonth()) % 3 === 1) {
            monthlyStatistics.forEach((stats) => {
              if (checkInterval(stats.date, 0)) {
                quartalStats.push(stats)
              }
            })
          }
          statisticsList.push({
            stats: quartalStats,
            periodIndex: { quarter, yearQuarter },
            periodType: statisticsViewPeriodEnum.QUARTERLY,
            // id: viewPeriod + period + quarter + yearQuarter,
          })
          monthIndexMin = 1 + (today.getMonth() % 3)
          yearQuarter = quarter === 1 ? yearQuarter - 1 : yearQuarter
          quarter = quarter === 1 ? 4 : quarter - 1
        }
        const quarterBound = period === statisticsPeriodEnum.LAST_8_QARTERS ? 23 : 11
        for (let quartIndex = monthIndexMin; quartIndex < quarterBound; quartIndex += 3) {
          const quartStats = []
          monthlyStatistics.forEach((stats) => {
            if (
              checkInterval(stats.date, quartIndex) ||
              checkInterval(stats.date, quartIndex + 1) ||
              checkInterval(stats.date, quartIndex + 2)
            ) {
              quartStats.push(stats)
            }
          })

          statisticsList.push({
            stats: quartStats,
            periodIndex: { quarter, yearQuarter },
            periodType: statisticsViewPeriodEnum.QUARTERLY,
            // id: viewPeriod + period + quarter + yearQuarter,
          })
          yearQuarter = quarter === 1 ? yearQuarter - 1 : yearQuarter
          quarter = quarter === 1 ? 4 : quarter - 1
        }
      }
    }
    console.debug("FILT STATS", statisticsList)
    setFilteredStatistics(statisticsList)
  }

  function checkInterval(date, monthIndex) {
    const checkDate = new Date(today.getFullYear(), today.getMonth() - monthIndex)
    return date.getFullYear() === checkDate.getFullYear() && date.getMonth() === checkDate.getMonth()
  }

  function handleChangeViewPeriod(value) {
    if (!value) {
      setPeriod("")
    } else if (!period && value === statisticsViewPeriodEnum.MONTHLY) {
      setPeriod(statisticsPeriodEnum.THIS_YEAR)
    } else if (value === statisticsViewPeriodEnum.QUARTERLY) {
      setPeriod(statisticsPeriodEnum.THIS_YEAR)
    } else if (value === statisticsViewPeriodEnum.YEARLY) {
      setPeriod(statisticsPeriodEnum.LAST_2_YEARS)
    }
    setViewPeriod(value)
    filterStatistics()
  }

  function handlePeriodChange(value) {
    setPeriod(value)
    filterStatistics()
  }

  return (
    <Screen>
      <View style={{ flexDirection: "row", paddingTop: 10 }}>
        <View style={{ flex: 1 }}>
          <AppPicker
            value={period}
            descriptionLabel="Období"
            placeholder={{ label: "Vyberte období", value: "" }}
            onValueChange={(value) => handlePeriodChange(value)}
            items={viewPeriod ? appPickerPeriodItems[viewPeriod] : appPickerMonthlyPeriodItems}
          />
        </View>
        <View style={{ flex: 1 }}>
          <AppPicker
            value={viewPeriod}
            descriptionLabel="Zobrazení"
            placeholder={{ label: "Vyberte zobrazení", value: "" }}
            onValueChange={(value) => handleChangeViewPeriod(value)}
            items={appPickerViewPeriodItems}
          />
        </View>
      </View>

      {filteredStatistics.length > 0 ? (
        <>
          <Text style={{ textAlign: "right", padding: 5, fontWeight: "bold" }}>Ceny jsou uvedeny v CZK bez DPH</Text>
          <FlatList
            data={filteredStatistics}
            renderItem={({ item }) => (
              <FlatListStatisticsOverviewItem
                stats={item.stats}
                periodIndex={item.periodIndex}
                periodType={item.periodType}
              />
            )}
            ItemSeparatorComponent={FlatListSeparator}
            keyExtractor={(item, index) => index.toString()}
          />
        </>
      ) : (
        <AppEmptyScreenView message={monthlyStatistics ? "Statistiky se načítají" : "Neexistují žádné statistiky"} />
      )}
    </Screen>
  )
}
