import React from "react"
import { Text, TouchableOpacity, View, StyleSheet } from "react-native"
import { STATE_VIEW_PARAMS_LOG_BOOK, STATE_VIEW_PARAMS_USER } from "../../data/params"
import DefaultTheme from "../../themes/DefaultTheme"
import { FontAwesome, Ionicons } from "@expo/vector-icons"

export default function FlatListLogBookOverviewItem({ navigation, user }) {
  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate("LogBookMonths", {
          user,
          title: true,
        })
      }
      style={[styles.card, { borderLeftColor: STATE_VIEW_PARAMS_LOG_BOOK[user.disabled].color }]}
    >
      <View style={{ paddingRight: 15 }}>
        <Ionicons {...STATE_VIEW_PARAMS_USER[user.disabled]} style={{ paddingLeft: 1 }} />
        <FontAwesome {...STATE_VIEW_PARAMS_LOG_BOOK[user.disabled]} size={DefaultTheme.icons.flatlistSize} />
      </View>
      <View style={{ flex: 1 }}>
        <Text>{user.name}</Text>
        <Text>{user.email}</Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={{ textAlign: "right" }}></Text>
      </View>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  card: {
    height: 100,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    paddingRight: 15,
    flexDirection: "row",
    borderLeftWidth: 10,
    backgroundColor: DefaultTheme.colors.white,
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
})
