import React, { useEffect } from "react"
import { Text, TouchableOpacity, View, StyleSheet } from "react-native"
import { approveActiontypeLabels, paymentLabels, roleLabels } from "../../data/labels"
import { STATE_VIEW_PARAMS_DOCUMENT } from "../../data/params"
import DefaultTheme from "../../themes/DefaultTheme"
import {
  changeDocumentOperationsActionValue,
  getDocumentApprovingInitialState,
  getDocumentState,
  getProjectName,
  toCurrentDateTime,
} from "../../utils/general"
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons"
import { useData } from "../../providers/DataProvider"
import { documentStateEnum, viewTypeEnum } from "../../data/enums"
import CurrencyFormatter from "../CurrencyFormatter"
import { isRowDisabled } from "../../utils/validation"
import ApproveButtonsDocument from "../ApproveButtonsDocument"

export default function FlatListAccountingDocumentApproveItem({
  navigation,
  document,
  viewType,
  accDocs,
  setAccDocs,
  setIsDocsChanged,
}) {
  const { users, projects, currentUser } = useData()

  function getUserRoles(operation) {
    const projUsers = projects.find((project) => project.id === document.project)?.users || []
    const userProjRoles = projUsers.find((projUser) => projUser.email === operation.email)?.roles || []

    let rolesString = "["
    userProjRoles.map((role, index) => {
      rolesString += `${roleLabels[role]}`
      if (index < userProjRoles.length - 1) {
        rolesString += ", "
      }
    })
    rolesString += "]"

    return rolesString
  }

  function getOperationState(operation) {
    if (operation.action_type !== "STATE_DOC_ACCEPTED") {
      return operation.action_value
    } else {
      return document.uploaded_to_trivi_at
        ? documentStateEnum.ACCEPTED
        : operation.action_value === documentStateEnum.REJECTED
        ? documentStateEnum.REJECTED
        : documentStateEnum.PENDING
    }
  }

  function changeOperationsActionValue(action_type, value) {
    setIsDocsChanged(true)
    setAccDocs(
      accDocs.map((doc) => {
        if (doc.id === document.id) {
          return changeDocumentOperationsActionValue(action_type, value, doc, currentUser)
        }
        return doc
      })
    )
  }

  if (viewType === viewTypeEnum.BULK_ACTION) {
    return (
      <View
        style={[
          styles.card,
          {
            borderLeftColor:
              STATE_VIEW_PARAMS_DOCUMENT[`${getDocumentState(document)}${document.locked ? "_LOCKED" : ""}`].color,
          },
        ]}
      >
        <View style={{ alignItems: "center" }}>
          <MaterialCommunityIcons
            name="clipboard-check"
            size={DefaultTheme.icons.flatlistSize}
            color={STATE_VIEW_PARAMS_DOCUMENT[`${getDocumentState(document)}${document.locked ? "_LOCKED" : ""}`].color}
          />
          <FontAwesome
            {...STATE_VIEW_PARAMS_DOCUMENT[`${getDocumentState(document)}${document.locked ? "_LOCKED" : ""}`]}
            size={DefaultTheme.icons.flatlistSize}
          />
          {document.locked && (
            <FontAwesome name="lock" color={DefaultTheme.colors.disabled} size={DefaultTheme.icons.flatlistSize} />
          )}
        </View>

        <View style={styles.contentWrapper}>
          <View style={{ flexDirection: "row", justifyContent: "space-between", paddingVertical: 5 }}>
            <View style={{ flexShrink: 1 }}>
              <Text style={{ fontWeight: "bold" }}>{document.name || "- - -"}</Text>
            </View>
            <View style={{ flexShrink: 1 }}>
              <Text style={{ fontWeight: "bold", paddingHorizontal: 10, textAlign: "right" }}>
                {CurrencyFormatter(document.price, "CZK", 1)}
              </Text>
              <Text style={{ paddingHorizontal: 10, textAlign: "right" }}>Projekt: {document.project || "- - -"}</Text>
            </View>
          </View>
          <View>
            <View
              style={[
                { flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
                isRowDisabled("STATE_DOC_ACCEPTED", document, projects, currentUser) && styles.approveRowDisabled,
              ]}
            >
              <Text style={{ flex: 1, flexWrap: "wrap", paddingHorizontal: 10 }}>
                Mohu poslat doklad do účetnictví?
              </Text>
              <View style={styles.approveButtonsWrapper}>
                <ApproveButtonsDocument
                  initialState={getDocumentApprovingInitialState("STATE_DOC_ACCEPTED", document)}
                  action_type="STATE_DOC_ACCEPTED"
                  changeOperationsActionValue={(action_type, value) => changeOperationsActionValue(action_type, value)}
                  disabled={isRowDisabled("STATE_DOC_ACCEPTED", document, projects, currentUser)}
                  style={{ padding: 5, backgroundColor: "#F8F8F8" }}
                />
              </View>
            </View>

            <View
              style={[
                { flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
                isRowDisabled("STATE_UNIT_TOTAL", document, projects, currentUser) && styles.approveRowDisabled,
              ]}
            >
              <Text style={{ flex: 1, flexWrap: "wrap", paddingHorizontal: 10 }}>Souhlasí položky na dokladu?</Text>
              <View style={styles.approveButtonsWrapper}>
                <ApproveButtonsDocument
                  initialState={getDocumentApprovingInitialState("STATE_UNIT_TOTAL", document)}
                  action_type="STATE_UNIT_TOTAL"
                  changeOperationsActionValue={(action_type, value) => changeOperationsActionValue(action_type, value)}
                  disabled={isRowDisabled("STATE_UNIT_TOTAL", document, projects, currentUser)}
                  style={{ padding: 5, backgroundColor: "#F8F8F8" }}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    )
  }

  return (
    <TouchableOpacity
      onPress={() => navigation.navigate("ApprovingAccountingDocumentDetail", { document })}
      disabled={document.locked}
    >
      <View
        style={[
          styles.card,
          {
            borderLeftColor:
              STATE_VIEW_PARAMS_DOCUMENT[`${getDocumentState(document)}${document.locked ? "_LOCKED" : ""}`].color,
          },
        ]}
      >
        <View style={{ alignItems: "center" }}>
          <MaterialCommunityIcons
            name="clipboard-check"
            size={DefaultTheme.icons.flatlistSize}
            color={STATE_VIEW_PARAMS_DOCUMENT[`${getDocumentState(document)}${document.locked ? "_LOCKED" : ""}`].color}
          />
          <FontAwesome
            {...STATE_VIEW_PARAMS_DOCUMENT[`${getDocumentState(document)}${document.locked ? "_LOCKED" : ""}`]}
            size={DefaultTheme.icons.flatlistSize}
          />
          {document.locked && (
            <FontAwesome name="lock" color={DefaultTheme.colors.disabled} size={DefaultTheme.icons.flatlistSize} />
          )}
        </View>

        <View style={styles.contentWrapper}>
          <View
            style={{
              paddingBottom: 10,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flexShrink: 1 }}>
              <Text style={{ fontWeight: "bold" }}>{document.name || "- - -"}</Text>
            </View>
            <View style={{ flexShrink: 1 }}>
              <Text style={{ fontWeight: "bold", paddingHorizontal: 10, textAlign: "right" }}>
                {CurrencyFormatter(document.price, "CZK", 1)}
              </Text>
              <Text style={{ paddingHorizontal: 10, textAlign: "right" }}>
                {document.project
                  ? `${document.project} - ${getProjectName(projects, document.project)}`
                  : "Projekt: - - -"}
              </Text>
            </View>
          </View>

          <Text style={styles.hint}>Poslední úprava</Text>
          {document.operations ? (
            document.operations
              .sort((a, b) => a.action_type > b.action_type)
              .map((operation, key) => {
                return (
                  <View key={key} style={{ justifyContent: "space-between", paddingBottom: 5 }}>
                    <View style={{ flexDirection: "row", alignItems: "center", flexShrink: 1 }}>
                      <Text style={{ fontWeight: "bold", paddingHorizontal: 5 }}>
                        {approveActiontypeLabels[operation.action_type]}
                      </Text>
                      <FontAwesome
                        {...STATE_VIEW_PARAMS_DOCUMENT[`${getOperationState(operation)}_OPERATIONS`]}
                        size={16}
                      />
                      <Text style={{ paddingHorizontal: 5 }}>{toCurrentDateTime(operation.created_at)}</Text>
                    </View>
                    <View style={{ flexShrink: 1 }}>
                      <Text style={{ paddingHorizontal: 5 }}>
                        {users.length > 0 ? users.find((user) => user.email === operation.email)?.name : ""}
                      </Text>
                      <Text style={{ paddingHorizontal: 5 }}>{users.length > 0 ? getUserRoles(operation) : ""}</Text>
                    </View>
                  </View>
                )
              })
          ) : (
            <Text>Ještě nebyla provedena</Text>
          )}
        </View>
      </View>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  approveButtonsWrapper: {
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: 8,
    overflow: "hidden",
    margin: 5,
  },
  approveRowDisabled: {
    backgroundColor: DefaultTheme.colors.disabled,
    opacity: 0.5,
  },
  card: {
    flex: 1,
    minHeight: 100,
    alignItems: "center",
    width: "100%",
    paddingLeft: 15,
    flexDirection: "row",
    borderLeftWidth: 10,
    backgroundColor: DefaultTheme.colors.white,
  },
  contentWrapper: {
    flex: 1,
    height: "100%",
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  name: {
    paddingLeft: 10,
    marginRight: "auto",
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
})
