import React from "react"
import { StyleSheet, Text, SectionList, View, Image } from "react-native"
import FlatListSettingsItem from "../../components/FlatLists/FlatListSettingsItem"
import { MaterialCommunityIcons, Ionicons, MaterialIcons, FontAwesome } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import Screen from "../../components/Screen"
import { isAccountant, isAdmin, isExecutive, isOfficeManager, isTechnicalDirector } from "../../utils/validation"
import { useData } from "../../providers/DataProvider"

export default function Settings({ navigation }) {
  const { currentUser } = useData()

  const flatListItems =
    isAdmin(currentUser) || isExecutive(currentUser)
      ? [
          {
            title: "Trivi",
            data: [
              {
                title: "API credentials",
                icon: <Ionicons name="settings" size={DefaultTheme.icons.menuSize} />,
                onPress: () => navigation.navigate("TriviAPICredentials"),
              },
              {
                title: "API synchronizace",
                icon: <MaterialCommunityIcons name="file-sync" size={DefaultTheme.icons.menuSize} />,
                onPress: () => navigation.navigate("TriviSync"),
              },
            ],
          },
          {
            image: require("../../assets/kb-bank.png"),
            title: "KB banka",
            data: [
              {
                title: "Registrovat aplikaci",
                icon: <Ionicons name="settings" size={DefaultTheme.icons.menuSize} />,
                onPress: () => navigation.navigate("KBBankRegisterSoftware"),
              },
              {
                title: "Platební automat",
                image: require("../../assets/recurring-payment-icon.png"),
                onPress: () => navigation.navigate("BankRecurringAutomat"),
              },
            ],
          },
          {
            image: require("../../assets/rb-bank.png"),
            title: "RB banka",
            data: [
              {
                title: "Nastavení RB banky",
                icon: <Ionicons name="settings" size={DefaultTheme.icons.menuSize} />,
                onPress: () => navigation.navigate("RegisterBankClient"),
              },
            ],
          },
          {
            icon: <FontAwesome name="calendar" size={DefaultTheme.icons.menuSize} />,
            title: "Stavební deník",
            data: [
              {
                title: "Titulní strana",
                icon: <MaterialIcons name="title" size={DefaultTheme.icons.menuSize} />,
                onPress: () =>
                  navigation.navigate("TemplatesOverview", {
                    screenTitle: "Titulní obrázky deníku",
                    docId: "diary",
                    propName: "predefined_title_images",
                  }),
              },
              {
                title: "Počasí API credentials",
                icon: <MaterialCommunityIcons name="weather-sunny" size={DefaultTheme.icons.menuSize} />,
                onPress: () => navigation.navigate("WeatherAPICredentials"),
              },
            ],
          },
          {
            title: "Cashflow",
            icon: <MaterialCommunityIcons name="clipboard-flow" size={DefaultTheme.icons.menuSize} />,
            data: [
              {
                title: "Nastavení zůstatku",
                icon: <MaterialCommunityIcons name="account-cash" size={DefaultTheme.icons.menuSize} />,
                onPress: () => navigation.navigate("CashflowInitialValue"),
              },
            ],
          },
          {
            title: "",
            data: [
              {
                title: "Změna hesla",
                icon: <MaterialCommunityIcons name="key-variant" size={DefaultTheme.icons.menuSize} />,
                onPress: () => navigation.navigate("PasswordChange"),
              },
            ],
          },
        ]
      : [
          {
            title: "",
            data: [
              {
                title: "Změna hesla",
                icon: <MaterialCommunityIcons name="key-variant" size={DefaultTheme.icons.menuSize} />,
                onPress: () => navigation.navigate("PasswordChange"),
              },
            ],
          },
        ]

  if (
    isAdmin(currentUser) ||
    isExecutive(currentUser) ||
    isAccountant(currentUser) ||
    isOfficeManager(currentUser) ||
    isTechnicalDirector(currentUser)
  ) {
    flatListItems.unshift({
      title: "",
      data: [
        {
          title: "Uživatelé",
          icon: <Ionicons name="people" size={DefaultTheme.icons.menuSize} />,
          onPress: () => navigation.navigate("Users"),
        },
        {
          title: "Jízdy",
          icon: <FontAwesome name="suitcase" size={DefaultTheme.icons.menuSize} />,
          onPress: () => navigation.navigate("PredefinedLogs"),
        },
      ],
    })
  }

  // const HeaderItem = ({ section: { icon } }) => console.log(icon)
  const HeaderItem = ({ section: { title, image, icon } }) =>
    title ? (
      <View style={styles.headerWrapper}>
        {title === "Trivi" && (
          <View style={styles.triviLogo}>
            <Text style={{ fontWeight: "bold" }}>T</Text>
          </View>
        )}
        {image && <Image source={image} style={{ width: 25, height: 25 }} />}
        {icon}
        <Text style={styles.headerText}>{title}</Text>
      </View>
    ) : (
      <View style={styles.headerWrapper} />
    )

  return (
    <Screen>
      <SectionList
        sections={flatListItems}
        keyExtractor={(item, index) => item + index}
        renderItem={({ item }) => (
          <FlatListSettingsItem
            icon={item.icon}
            image={item.image}
            title={item.title}
            onPress={item.onPress}
            type={item?.type}
          />
        )}
        renderSectionHeader={HeaderItem}
        ItemSeparatorComponent={FlatListSeparator}
      />
    </Screen>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerText: {
    paddingLeft: 10,
    fontSize: 18,
    fontWeight: "bold",
  },
  headerWrapper: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 25,
    paddingBottom: 15,
    paddingLeft: 15,
  },
  triviLogo: {
    width: 25,
    height: 25,
    borderWidth: 2,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
  },
})
