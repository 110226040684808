import React, { useEffect, useState } from "react"
import { ScrollView, Text, View } from "react-native"
import { TextInput } from "react-native-paper"
import AddressAutocomplete from "../../components/AddressAutocomplete"
import AppPicker from "../../components/AppPicker"
import ComponentList from "../../components/ComponentList"
import DeleteButton from "../../components/DeleteButton"
import NextButton from "../../components/NextButton"
import Screen from "../../components/Screen"
import { statusEnum } from "../../data/enums"
import pickerItems from "../../data/pickerItems"
import FSContactCompanies from "../../firebase/FSContactCompanies"
import { useStatus } from "../../providers/StatusProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import { isEmailValid } from "../../utils/validation"

const filterCategories = pickerItems.filterCategories
const categoryItems = Object.keys(filterCategories).map((cat) => ({ key: cat, value: cat, label: cat }))

export default function CompanyCatalogDetail({ route, navigation }) {
  const { setTitle, setMessage, setStatus } = useStatus()
  const [company, setCompany] = useState({
    users: [],
    main_category: "Stavba",
    sub_category: "Stavebniny",
  })
  const [subCategoryItems, setSubCategoryItems] = useState(
    filterCategories["Stavba"].map((cat) => ({ key: cat, value: cat, label: cat }))
  )
  const companyId = route.params?.companyId
  const generalDisable = false // TODO add general disable

  console.debug("COMPANY", company)

  useEffect(() => {
    if (companyId) {
      FSContactCompanies.onSnapshotCompany(companyId, (c) => setCompany({ ...company, ...c }))
    }
  }, [])

  const companyProps = [
    { value: "name", label: "Název", emptyLabel: "Zadejte název" },
    { value: "email", label: "Email", emptyLabel: "Zadejte email", error: (val) => isEmailValid(val) },
    { value: "address", label: "Adresa", emptyLabel: "Zadejte adresu", isAddress: true },
    { value: "ico", label: "IČO", emptyLabel: "Zadejte IČO" },
    { value: "dic", label: "DIČ", emptyLabel: "Zadejte DIČ" },
    { value: "iban", label: "IBAN", emptyLabel: "Zadejte IBAN" },
    { value: "executive", label: "Jednatel", emptyLabel: "Zadejte jednatele" },
    { value: "phone", label: "Telefonní číslo", emptyLabel: "Zadejte telefonní číslo" },
  ]

  const userProps = [
    { value: "name", label: "Jméno", emptyLabel: "Zadejte jméno" },
    { value: "email", label: "Email", emptyLabel: "Zadejte email", error: (val) => isEmailValid(val) },
    { value: "phone", label: "Telefonní číslo", emptyLabel: "Zadejte telefonní číslo" },
    { value: "role", label: "Role", emptyLabel: "Zadejte roli ve firmě" },
  ]

  function changePropertyValue(prop, value) {
    setCompany({ ...company, [prop]: value })
  }

  async function handleCompanySave() {
    try {
      setTitle("Ukládám firmu")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      if (companyId) {
        await FSContactCompanies.update(company)
      } else {
        await FSContactCompanies.add(company)
      }
      navigation.pop()
      setStatus(statusEnum.SUCCESS)
    } catch (error) {
      console.error("Error saving contact company: ", error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  function handleAddContactUser() {
    setCompany({
      ...company,
      users: [...company.users, { key: company.users.length, name: "", email: "", phone: "", role: "" }],
    })
  }

  function handleDeleteContactUser(key) {
    setCompany({ ...company, users: company.users.filter((u) => u.key !== key) })
  }

  function isSaveDisabled() {
    return (
      generalDisable ||
      !company.name ||
      (company.email ? !isEmailValid(company.email) : false) ||
      !company.users.every((u) => (u.email ? isEmailValid(u.email) : true)) ||
      !company.main_category ||
      !company.sub_category
    )
  }

  return (
    <Screen>
      <ScrollView>
        <View style={{ marginTop: 10, flexDirection: "row" }}>
          <View style={{ flex: 1 }}>
            <AppPicker
              value={company.main_category}
              descriptionLabel="Hlavní kategorie"
              placeholder={{}}
              onValueChange={(main) => {
                setCompany({ ...company, main_category: main, sub_category: filterCategories[main][0] })
                setSubCategoryItems(filterCategories[main].map((cat) => ({ key: cat, value: cat, label: cat })))
              }}
              items={categoryItems}
            />
          </View>
          <View style={{ flex: 1 }}>
            <AppPicker
              value={company.sub_category}
              descriptionLabel="Subkategorie"
              placeholder={{}}
              onValueChange={(sub) => setCompany({ ...company, sub_category: sub })}
              items={subCategoryItems}
            />
          </View>
        </View>
        {companyProps.map(({ value, label, emptyLabel, disabled = false, isAddress, error }) =>
          isAddress ? (
            <AddressAutocomplete
              key={value}
              error={!company[value]}
              address={company[value]}
              onAddressChange={(textValue) => changePropertyValue(value, textValue)}
              onAddressPick={(address) => changePropertyValue(value, address)}
            />
          ) : (
            <TextInput
              key={value}
              error={error ? !error(company[value]) : !company[value]}
              style={[DefaultTheme.input, { marginTop: 10 }]}
              label={company[value] ? label : emptyLabel}
              onChangeText={(textValue) => changePropertyValue(value, textValue)}
              value={company[value]}
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              disabled={disabled || generalDisable}
            />
          )
        )}
        <ComponentList title="Kontaktní osoby" items={company.users} addItem={handleAddContactUser}>
          {company.users.map((user) => {
            const handleOnChangeText = (newText, prop) => {
              setCompany({
                ...company,
                users: company.users.map((u) => (u.key === user.key ? { ...u, [prop]: newText } : u)),
              })
            }

            return (
              <View
                key={user.key}
                style={[
                  { paddingRight: 35, user, padding: 10 },
                  user.key < company.users?.length - 1 && {
                    borderBottomColor: DefaultTheme.colors.borderPrimary,
                    borderBottomWidth: 1,
                  },
                ]}
              >
                {userProps.map(({ value, label, emptyLabel, error, disabled = false }) => (
                  <TextInput
                    key={value}
                    error={error ? !error(user[value]) : !user[value]}
                    style={[DefaultTheme.input, { marginTop: 10 }]}
                    label={user[value] ? label : emptyLabel}
                    onChangeText={(newText) => handleOnChangeText(newText, value)}
                    value={user[value]}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    disabled={disabled || generalDisable}
                  />
                ))}
                <DeleteButton disabled={generalDisable} onPress={() => handleDeleteContactUser(user.key)} />
              </View>
            )
          })}
        </ComponentList>
      </ScrollView>
      <NextButton text="Uložit" onPress={handleCompanySave} disabled={isSaveDisabled()} />
    </Screen>
  )
}
