import React, { useEffect, useRef, useState } from "react"
import { ActivityIndicator, Dimensions, Platform, Text, View } from "react-native"
import { TextInput } from "react-native-paper"
import Constants from "expo-constants"

import { firebase } from "../firebase/config"
import PrimButton from "./PrimButton"
import DefaultTheme from "../themes/DefaultTheme"
import { getAutocompleteData, getPlaceData } from "../actions/googleApiActions"
import { TouchableOpacity } from "react-native-gesture-handler"
import { useStatus } from "../providers/StatusProvider"
import { statusEnum } from "../data/enums"
import { useNavigation } from "@react-navigation/native"

export default function AddressAutocomplete({
  disabled = false,
  address = "",
  onAddressChange,
  onAddressPick,
  error,
  containerStyle,
}) {
  const { setStatus, setMessage, firebaseConfig } = useStatus()
  const navigation = useNavigation()
  const [token, setToken] = useState("")
  const [loadingAddress, setLoadingAddress] = useState(false)
  const [predictions, setPredictions] = useState([])
  const [showPredictions, setShowPredictions] = useState(false)

  useEffect(() => {
    if (address) {
      setLoadingAddress(true)
      const delayDebounceFn = setTimeout(() => {
        handleGetAutocompleteData()
      }, 1000)
      return () => clearTimeout(delayDebounceFn)
    }
  }, [address])

  console.debug("TOKEN", token)
  console.debug("ADDRESS", address)
  console.debug("ADDRESS PREDICTIONS", predictions)

  async function handleGetAutocompleteData() {
    try {
      if (token) {
        const { data } = await getAutocompleteData(address, token, firebaseConfig)

        if (data) {
          setPredictions(data.predictions)
        } else {
          setPredictions([])
        }
        setLoadingAddress(false)
      }
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  async function handleAddressPick(addressDescription, placeId) {
    try {
      const {
        data: { status, result: placeData },
      } = await getPlaceData(placeId, token, firebaseConfig)

      if (status !== "OK") {
        throw { message: status }
      }

      console.debug("PLACE DATA", placeData)

      onAddressPick(addressDescription, placeData)
      setShowPredictions(false)
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage("Error getting place by id: " + error.message)
      navigation.navigate("StatusScreen")
    }
  }

  return (
    <View style={containerStyle}>
      <TextInput
        error={error}
        disabled={disabled}
        label={address ? "Adresa" : "Zadejte adresu"}
        onChangeText={(value) => {
          if (value) setShowPredictions(true)
          else setShowPredictions(false)
          onAddressChange(value)
        }}
        onFocus={async () => setToken(await firebase.apps[0].auth().currentUser.getIdToken())}
        style={[DefaultTheme.input, { marginTop: 10 }]}
        value={address}
      />
      <View style={{ marginHorizontal: 10 }}>
        {showPredictions &&
          (loadingAddress ? (
            <ActivityIndicator color={DefaultTheme.colors.primary} />
          ) : predictions.length > 0 ? (
            predictions.map((p, index) => (
              <TouchableOpacity
                disabled={disabled}
                key={p.place_id}
                onPress={() => handleAddressPick(p.description, p.place_id, firebaseConfig)}
                style={[Platform.OS === "web" && { cursor: "pointer" }]}
              >
                <Text
                  style={[
                    index < predictions.length - 1 && {
                      borderBottomWidth: 1,
                      borderBottomColor: DefaultTheme.colors.borderPrimary,
                    },
                    { backgroundColor: DefaultTheme.colors.white, padding: 10 },
                  ]}
                >
                  {p.description}
                </Text>
              </TouchableOpacity>
            ))
          ) : (
            <Text style={{ alignSelf: "center" }}>Nenašli se žádné výsledky</Text>
          ))}
      </View>
    </View>
  )
}
