import React, { useEffect, useState } from "react"
import { Checkbox, TextInput } from "react-native-paper"
import { Text, TouchableOpacity, View, ActivityIndicator, FlatList, StyleSheet, ScrollView } from "react-native"
import { AntDesign } from "@expo/vector-icons"

import NextButton from "../../components/NextButton"
import DefaultTheme from "../../themes/DefaultTheme"
import Validation, {isAccountant, isAdmin, isExecutive, isTechnicalDirector, isYear} from "../../utils/validation"
import { useData } from "../../providers/DataProvider"
import Screen from "../../components/Screen"
import { useStatus } from "../../providers/StatusProvider"
import { projectStateEnum, statusEnum } from "../../data/enums"
import { formatDecimalNumber, isDocFinal } from "../../utils/general"
import FlatListProjectAccountingItem from "../../components/FlatLists/FlatListProjectAccountingItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import CurrencyFormatter from "../../components/CurrencyFormatter"
import FSStoreContent from "../../firebase/FSStoreContent"
import AppDatePicker from "../../components/AppDatePicker"

export default function ProjectEdit({ navigation, route }) {
  const { projects, currentUser, settings } = useData()
  const { setTitle, setStatus, setMessage } = useStatus()
  const [project, setProject] = useState(projects.find((project) => project.id === route.params.project.id) || {})
  const [incomeExtra, setIncomeExtra] = useState(project?.statistics?.income_extra || "0")
  const hasEditPermission =
    isAdmin(currentUser) || isExecutive(currentUser) || isTechnicalDirector(currentUser) || isAccountant(currentUser)
  const disabled = !(hasEditPermission && project.state !== projectStateEnum.CLOSED)
  const [isVisible, setIsVisible] = useState(disabled)
  const [isLoadingDocs, setIsLoadingDocs] = useState(disabled)
  const [projectAccountingDocs, setProjectAccountingDocs] = useState([])
  const syncId = settings.trivi?.sync_id
  const [administration, setAdministration] = useState(project.administration || { enabled: false, year: new Date().getFullYear(), margin: 0.0884 });

  console.debug("PROJECT TO EDIT", project)

  async function updateProject() {
    // Make sure we store numbers in the firestore
    let sanitizeProject = {
      ...project,
      administration: administration,
      draft_outcome: Number(project.draft_outcome),
      draft_income: Number(project.draft_income),
      draft_blocking_realisation_in_percentage: Number(project.draft_blocking_realisation_in_percentage) || 0,
      draft_blocking_guaranty_in_percentage: Number(project.draft_blocking_guaranty_in_percentage) || 0,
      statistics: { ...project.statistics, income_extra: Number(incomeExtra) || 0 },
    }
    try {
      setTitle("Nahrávám data projektu")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      if (!hasEditPermission) {
        throw { message: "Nemáte oprávnění pro úpravu projektu" }
      }

      await FSStoreContent.updateProject(sanitizeProject)
      await FSStoreContent.updateTriviAccountingDocs(projectAccountingDocs)

      setStatus(statusEnum.SUCCESS)
      setMessage("Změny dokladů aktuálního prodeje se ukáží druhý den od provedení.")
      navigation.pop()
    } catch (error) {
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  function changePropertyValue(propName, value) {
    setProject({ ...project, [propName]: value })
  }

  async function handleVisibilityChange() {
    setIsVisible(!isVisible)
    if (!isVisible) {
      setIsLoadingDocs(true)
      const docs = await FSStoreContent.loadTriviAccountingDocsByProject(
        1,
        syncId,
        project.id,
        project.start_date,
        project.end_date
      )
      setProjectAccountingDocs(docs)
      setIsLoadingDocs(false)
    }
  }

  function calculateFinal() {
    console.debug("TRIVI ACC DOCS", projectAccountingDocs)
    return (
      (projectAccountingDocs
        .filter((el) => el.internalInfo?.isSyncing)
        .reduce(
          (a, c) =>
            a +
            ((isDocFinal(c) ? c.total : c.totalVatExcl) +
              (c.totalVatExcl > 0 // temporary before user vies for custom action is created
                ? c.lineItems?.reduce((a, c) => (c.totalVatExcl < 0 ? c.totalVatExcl : 0) + a, 0) || 0
                : 0)), // we need to sum items which are negative for example: Odečet zálohy ZV2021300004 because they are some kind of correction from previous invoices
          0
        ) || 0) + (Number(incomeExtra) || 0)
    )
  }

  useEffect(() => {
    if (disabled) {
      ;(async () => {
        const docs = await FSStoreContent.loadTriviAccountingDocsByProject(
          1,
          syncId,
          project.id,
          project.start_date,
          project.end_date
        )
        setProjectAccountingDocs(docs)
        setIsLoadingDocs(false)
      })()
    }
  }, [])

  return (
    // <Screen barStyle="light-content">
    <Screen>
      {/* TEMPORARY REPORTING*/}
      <ScrollView>
        <Text style={[DefaultTheme.hint, { paddingLeft: 30, paddingVertical: 10 }]}>
          Z Trivi se budou brát v úvahu doklady od začátku po současnost/konec projektu
        </Text>
        <AppDatePicker
          label="Začátek projektu"
          webLabel={["Den zahájení projektu", "Měsíc zahájení projektu", "Rok zahájení projektu"]}
          value={project.start_date}
          onConfirm={(date) => changePropertyValue("start_date", date || new Date())}
          disabled={!hasEditPermission || project.state === projectStateEnum.CLOSED}
        />
        {!!project.end_date && (
          <AppDatePicker
            label="Konec projektu"
            webLabel={["Den ukončení projektu", "Měsíc ukončení projektu", "Rok ukončení projektu"]}
            value={project.end_date}
            onConfirm={(date) => changePropertyValue("end_date", date || new Date())}
            disabled={!hasEditPermission || project.state === projectStateEnum.CLOSED}
          />
        )}
        <TextInput
          error={!project.name}
          style={[DefaultTheme.input, { marginTop: 10 }]}
          label={project.name ? "Název" : "Zadejte název dokladu"}
          onChangeText={(value) => changePropertyValue("name", value)}
          value={project.name}
          disabled={disabled}
        />
        <View style={{ flexDirection: "row", padding: 10 }}>
          <Checkbox
            status={administration.enabled ? 'checked' : 'unchecked'}
            onPress={() => {
              setAdministration({ ...administration, enabled: !administration.enabled })
            }}
          />
          <Text>Projekt je režijního typu</Text>
          <TextInput
              error={disabled ? false : !Validation.isPercentage(administration.margin)}
              style={[DefaultTheme.input, { marginTop: 10 }]}
              label={administration.margin ? "Procento režie" : "Zadejte procento režie"}
              onChangeText={(value) => setAdministration({ ...administration, margin: Number(value) })}
              value={administration.margin}
              disabled={disabled || !administration.enabled}
              keyboardType="numeric"
          />
          <TextInput
              error={disabled ? false : !Validation.isYear(administration.year)}
              style={[DefaultTheme.input, { marginTop: 10 }]}
              label={administration.year ? "Režijní projekt roku" : "Zadejte rok režijního projektu"}
              onChangeText={(value) => setAdministration({ ...administration, year: Number(value) })}
              value={administration.year}
              disabled={disabled || !administration.enabled}
              keyboardType="numeric"
          />
        </View>

        {/* IMPLEMENT CURRENCY COMPONENT*/}
        <TextInput
          error={disabled ? false : !Validation.isNumberPositive(project.draft_outcome)}
          style={DefaultTheme.input}
          onBlur={() => Validation.isNumberPositive(project.draft_outcome)}
          label="Původní náklad v Kč bez DPH"
          keyboardType="numeric"
          onChangeText={(value) => changePropertyValue("draft_outcome", formatDecimalNumber(value))}
          value={project.draft_outcome?.toString() || ""}
          disabled={disabled}
        />
        <TextInput
          error={disabled ? false : !Validation.isNumberPositive(project.draft_income)}
          style={[DefaultTheme.input, { borderBottomColor: "black" }]}
          onBlur={() => Validation.isNumberPositive(project.draft_income)}
          label="Původní prodej v Kč bez DPH"
          keyboardType="numeric"
          onChangeText={(value) => changePropertyValue("draft_income", formatDecimalNumber(value))}
          value={project.draft_income?.toString() || ""}
          disabled={disabled}
        />
        <TextInput
          error={disabled ? false : !Validation.isNumberPositive(project.draft_blocking_realisation_in_percentage)}
          style={DefaultTheme.input}
          onBlur={() => Validation.isNumberPositive(project.draft_blocking_realisation_in_percentage)}
          label="Původní realizační zádržné v %"
          keyboardType="numeric"
          onChangeText={(value) =>
            changePropertyValue("draft_blocking_realisation_in_percentage", formatDecimalNumber(value))
          }
          value={project.draft_blocking_realisation_in_percentage?.toString() || ""}
          disabled={disabled}
        />
        <TextInput
          error={disabled ? false : !Validation.isNumberPositive(project.draft_blocking_guaranty_in_percentage)}
          style={DefaultTheme.input}
          onBlur={() => Validation.isNumberPositive(project.draft_blocking_guaranty_in_percentage)}
          label="Původní záruční zádržné v %"
          keyboardType="numeric"
          onChangeText={(value) =>
            changePropertyValue("draft_blocking_guaranty_in_percentage", formatDecimalNumber(value))
          }
          value={project.draft_blocking_guaranty_in_percentage?.toString() || ""}
          disabled={disabled}
        />
        <View>
          <View style={styles.handleVisibilityWrapper}>
            <TouchableOpacity
              onPress={() => handleVisibilityChange()}
              style={{ padding: 10, flexDirection: "row" }}
              disabled={disabled}
            >
              <Text>Upravit aktuální prodej</Text>
              {!disabled && (
                <AntDesign name={isVisible ? "up" : "down"} size={24} color="black" style={{ paddingLeft: 5 }} />
              )}
            </TouchableOpacity>
          </View>
          {isVisible &&
            (!isLoadingDocs ? (
              <>
                <View style={styles.projectAccDocsWrapper}>
                  <FlatList
                    data={projectAccountingDocs}
                    renderItem={({ item }) => (
                      <FlatListProjectAccountingItem
                        document={item}
                        accDocs={projectAccountingDocs}
                        setAccDocs={setProjectAccountingDocs}
                        disabled={disabled}
                      />
                    )}
                    keyExtractor={(item) => item.id.toString()}
                    ItemSeparatorComponent={FlatListSeparator}
                  />
                </View>
                <View style={styles.actualIncomeSum}>
                  <Text>Aktuální prodej</Text>
                  <Text style={{ fontWeight: "bold" }}>{CurrencyFormatter(calculateFinal(), "CZK", -1)}</Text>
                </View>
                <TextInput
                  error={isNaN(Number(incomeExtra))}
                  label="Upravit aktuální prodej o částku"
                  value={incomeExtra}
                  onChangeText={(value) => setIncomeExtra(value)}
                  style={DefaultTheme.input}
                  keyboardType="numeric"
                  disabled={disabled}
                />
                <Text style={[DefaultTheme.hint, { paddingLeft: 30 }]}>
                  Extra částka se započítává do statistiky projektu a výši prémií, avšak nezapočítává se k celkovým
                  statistikám
                </Text>
              </>
            ) : (
              <ActivityIndicator color={DefaultTheme.colors.primary} />
            ))}
        </View>
      </ScrollView>
      <NextButton
        disabled={
          !hasEditPermission ||
          project.name === "" ||
          project.state === projectStateEnum.CLOSED ||
          !Validation.isNumberPositive(project.draft_income) ||
          !Validation.isNumberPositive(project.draft_outcome) ||
          isNaN(Number(incomeExtra)) ||
          project.start_date > project.end_date
        }
        onPress={updateProject}
        text="Uložit"
      />
    </Screen>
  )
}

const styles = StyleSheet.create({
  actualIncomeSum: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginHorizontal: 10,
    marginBottom: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: DefaultTheme.colors.white,
  },
  handleVisibilityWrapper: {
    padding: 10,
    marginHorizontal: 10,
    backgroundColor: DefaultTheme.colors.backgroundPrimary,
  },
  projectAccDocsWrapper: {
    padding: 10,
    marginHorizontal: 10,
    backgroundColor: DefaultTheme.colors.backgroundPrimary,
  },
})
