import React, { useState } from "react"
import { Dimensions, Platform, StyleSheet, Text, TouchableWithoutFeedback, View } from "react-native"
import { MaterialCommunityIcons, FontAwesome5 } from "@expo/vector-icons"

import DefaultTheme from "../themes/DefaultTheme"
import PrimButton from "./PrimButton"
import ItemsCounter from "./ItemsCounter"

export default function ComponentList({ addItem, disabled = false, icon, items = [], title, children }) {
  const [pressed, setPressed] = useState(true)

  return (
    <React.Fragment>
      <View style={[pressed && styles.pressedItem, { marginHorizontal: 10 }]}>
        <TouchableWithoutFeedback onPress={() => setPressed(!pressed)}>
          <View style={[styles.listItem, Platform.OS === "web" && { cursor: "pointer" }]}>
            <View style={{ flexDirection: "row" }}>
              {icon}
              <Text style={styles.title}>{title}</Text>
              {items.length > 0 && <ItemsCounter value={items.length} top={-5} right={-40} />}
            </View>
            <MaterialCommunityIcons name={`chevron-${pressed ? "up" : "down"}`} size={18} color="black" />
          </View>
        </TouchableWithoutFeedback>
      </View>
      <View style={[!pressed && { transform: [{ scale: 0 }], flex: 0, display: "none" }, styles.listSubItem]}>
        {children}
      </View>
      <View
        style={[
          !pressed ? { transform: [{ scale: 0 }], flex: 0, display: "none" } : { padding: 10, marginBottom: 10 },
          { alignItems: "center", overflow: "hidden", marginHorizontal: 10 },
        ]}
      >
        <View style={styles.addItemBackground} />
        <PrimButton
          disabled={disabled}
          icon={<FontAwesome5 name="plus" size={18} color={DefaultTheme.colors.white} />}
          onPress={addItem}
          width={40}
        />
      </View>
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  addItemBackground: {
    backgroundColor: DefaultTheme.colors.white,
    width: Dimensions.get("window").width,
    height: 100,
    position: "absolute",
    bottom: 0,
  },
  listItem: {
    padding: 15,
    borderTopWidth: 1,
    borderTopColor: DefaultTheme.colors.borderPrimary,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listSubItem: {
    backgroundColor: DefaultTheme.colors.white,
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.borderPrimary,
    marginHorizontal: 10,
    paddingHorizontal: 10,
  },
  pressedItem: {
    backgroundColor: DefaultTheme.colors.white,
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.borderPrimary,
  },
  title: {
    paddingLeft: 10,
    fontWeight: "bold",
    fontSize: DefaultTheme.fonts.regular,
  },
})
