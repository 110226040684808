import React from "react"
import { FontAwesome } from "@expo/vector-icons"

import DefaultTheme from "../themes/DefaultTheme"
import PrimButton from "./PrimButton"

export default function DeleteButton({ onPress, absolute = true, disabled = false }) {
  return (
    <PrimButton
      disabled={disabled}
      icon={<FontAwesome name="trash" size={18} color={DefaultTheme.colors.white} />}
      onPress={onPress}
      style={[
        { backgroundColor: disabled ? DefaultTheme.colors.disabled : DefaultTheme.colors.danger },
        absolute && { position: "absolute", top: 5, right: 0 },
      ]}
      width={40}
    />
  )
}
