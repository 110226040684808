import { Text, View, TouchableOpacity } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import { toCurrentDate } from "../../utils/general"
import CurrencyFormatter from "../CurrencyFormatter"
import { dayNameLabels } from "../../data/labels"

export default function FlatListExtraCashflowItem({ item }) {
  const itemTypes = {
    WEEKLY: "Týdně",
    DAILY: "Denně",
    MONTHLY: "Měsíčně",
    ONE_TIME: "Jednorázově",
  }
  console.debug("ITEM: ", item)
  return (
    <TouchableOpacity
      style={[
        DefaultTheme.card,
        {
          marginVertical: 5,
          height: 40,
          //borderLeftColor: item.type === "ONE_TIME" ? DefaultTheme.colors.success : DefaultTheme.colors.danger,
        },
      ]}
      //onPress={() => navigation.navigate("ExtraCashflowDetail", { document: item, type: "EXISTING" })}
    >
      <View style={{ flexDirection: "column", alignItems: "left" }}>
        <Text>{ item.label }</Text>
        <Text>
          { toCurrentDate(item.dueDate) }
        </Text>
      </View>
      <Text>{ (item.direction === 0 ? "- " : "+ ") + CurrencyFormatter(item?.total, "Kč", -1)}</Text>
    </TouchableOpacity>
  )
}
