import React, { useState, useEffect, useMemo, useCallback } from "react"
import { View, ActivityIndicator, TouchableOpacity, Text } from "react-native"
import CashflowWrapper from "../../components/cashflow/CashflowWrapper"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useData } from "../../providers/DataProvider"
import { daysInMonth, toCurrentDate } from "../../utils/general"
import MonthYearPicker from "../../components/MonthYearPicker"
import { cashFlowTypeEnum } from "../../data/enums"
import Screen from "../../components/Screen"
import { getCashflowDocsUrl } from "../../utils/backendUrls"
import Constants from "expo-constants"

export default function CashflowOverview({ navigation, route }) {
  const today = new Date()
  const [pickedDate, setPickedDate] = useState(today)
  const [pickedMonthDays, setPickedMonthDays] = useState(null)
  const [initValues, setInitValues] = useState([])
  const { firebaseConfig } = useData()

  useEffect(() => {
    loadInitialValues()
  }, [])

  const loadInitialValues = async () => {
    const initialValues = await FSStoreContent.getCashflowInitialValue()

    if (initialValues && initialValues?.starting_values?.length > 0) {
      setInitValues(initialValues.starting_values)
    }
  }
  //incomeDocs => direction===1
  useEffect(() => {
    getMonthDays(pickedDate.getMonth(), pickedDate.getFullYear())
  }, [pickedDate])

  //using js month indexing jan-0
  const getMonthDays = async (monthIndex, year) => {
    const url = getCashflowDocsUrl(firebaseConfig)
    // const firstDayCurrentMonth = new Date(year, monthIndex)
    // firstDayCurrentMonth.setHours(3)
    const daysInCurrentMonth = daysInMonth(monthIndex + 1, year)

    // const firstDayNextMonth = new Date(year, monthIndex, daysInCurrentMonth, 23, 59)

    const docsRequest = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ monthIndex, year }),
      headers: { "Content-Type": "application/json" },
    })
    const response = await docsRequest.json()
    //console.log(response)

    const accDocs =
      response?.docs?.map((el) => {
        return { ...el, dueDate: new Date(el.dueDate) }
      }) || []
    //const accDocs = await FSStoreContent.getPaidDocsByDateInterval(firstDayCurrentMonth, firstDayNextMonth)
    // accDocs.forEach((el) => {
    //   const exists = fsDocs.find((d) => el.total === d.total)
    //   if (!exists) console.log(el)
    // })
    //const extraDocs = await FSStoreContent.getExtraCashflowDocs()
    console.log(accDocs)
    const dates = []

    //these will be a combination of predefined payments in settings and accounting docs
    //indexing dates[0] => 1, dates[1] => 2
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      dates.push({
        dayIndex: i,
        date: new Date(year, monthIndex, i),
        incDocs: [],
        outDocs: [],
      })
    }

    // console.debug(accDocs)
    // console.debug(extraDocs)

    accDocs.forEach((el) => {
      const datesArrayObj = dates[el?.dueDate?.getDate() - 1]
      datesArrayObj[el.direction === 0 ? "outDocs" : "incDocs"].push(el)
    })

    // extraDocs.forEach((el) => {
    //   if (el.type === cashFlowTypeEnum.MONTHLY) {
    //     let docDate = el.dateIndex
    //     if (docDate > daysInCurrentMonth) {
    //       docDate = daysInCurrentMonth
    //     }
    //     const datesArrayObj = dates[docDate - 1]
    //     const predefinedValue = el.values.find((val) => {
    //       if (
    //         pickedDate.getMonth() >= val.from.getMonth() &&
    //         pickedDate.getFullYear() >= val.from.getFullYear() &&
    //         (val.indefinite ||
    //           (!val.indefinite &&
    //             pickedDate.getMonth() <= val.to.getMonth() &&
    //             pickedDate.getFullYear() <= val.to.getFullYear()))
    //       )
    //         return val
    //       else return undefined
    //     })
    //     if (predefinedValue) {
    //       console.log("pushed", predefinedValue.value)
    //       datesArrayObj[el.direction === 0 ? "outDocs" : "incDocs"].push({
    //         dueDate: new Date(pickedDate).setDate(docDate),
    //         direction: el.direction,
    //         total: predefinedValue.value,
    //         payment: {
    //           paid: true,
    //           paidConfirmed: true,
    //           payments: [],
    //           expectedPayment: [],
    //         },
    //       })
    //     }
    //   } else if (el.type === cashFlowTypeEnum.WEEKLY) {
    //     const predefinedValue = el.values.find((val) => {
    //       //from before firstdaycurrent and indefinite or not indefinite and to after firstdaynext
    //       if (val.from <= firstDayCurrentMonth && ((val.to >= firstDayNextMonth && !val.indefinite) || val.indefinite))
    //         return val
    //       //from before firstdaycurrent and indefinite and to after firstdaycurrent but before firstdaynext
    //       else if (
    //         val.from <= firstDayCurrentMonth &&
    //         val.to >= firstDayCurrentMonth &&
    //         val.to <= firstDayNextMonth &&
    //         !val.indefinite
    //       )
    //         return val
    //       //from after firstdaycurrent and from before firstdaynext and
    //       //indefinite or not indefinite and to before/after firstdaynext
    //       else if (
    //         val.from >= firstDayCurrentMonth &&
    //         val.from <= firstDayNextMonth &&
    //         (val.indefinite ||
    //           (!val.indefinite && val.to <= firstDayNextMonth) ||
    //           (!val.indefinite && val.to >= firstDayNextMonth))
    //       )
    //         return val
    //       return undefined
    //     })
    //     if (predefinedValue) {
    //       //we look for all of the dates with el.dateIndex(dateIndex===day index, ie: monday=1,...)
    //       const day = el.dateIndex
    //       let firstDayOccurrence = 1
    //       const helperDate = new Date(year, monthIndex)
    //       //we find the first occurrence of specified day
    //       for (let i = 1; i <= daysInCurrentMonth; i++) {
    //         helperDate.setDate(i)
    //         if (helperDate.getDay() === day) {
    //           firstDayOccurrence = i
    //           break
    //         }
    //       }
    //       //we look for all the other occurrences in picked month
    //       // and we test if they
    //       helperDate.setDate(firstDayOccurrence)
    //       for (let i = firstDayOccurrence; i <= daysInCurrentMonth; i += 7) {
    //         const newDate = new Date(helperDate)
    //         newDate.setDate(i)
    //         if (
    //           newDate >= predefinedValue.from &&
    //           (predefinedValue.indefinite || (!predefinedValue.indefinite && newDate <= predefinedValue.to))
    //         ) {
    //           const datesArrayObj = dates[i - 1]
    //           console.log("pushed", predefinedValue.value)
    //           datesArrayObj[el.direction === 0 ? "outDocs" : "incDocs"].push({
    //             dueDate: newDate,
    //             direction: el.direction,
    //             total: predefinedValue.value,
    //             payment: {
    //               paid: true,
    //               paidConfirmed: true,
    //               payments: [],
    //               expectedPayment: [],
    //             },
    //           })
    //         }
    //       }
    //     }
    //   } else if (el.type === cashFlowTypeEnum.DAILY) {
    //     const predefinedValue = el.values.find((val) => {
    //       if (val.from <= firstDayCurrentMonth && (val.indefinite || (!val.indefinite && val.to >= firstDayNextMonth)))
    //         return val
    //       else if (
    //         val.from >= firstDayCurrentMonth &&
    //         (val.indefinite || (!val.indefinite && val.from < firstDayNextMonth))
    //       )
    //         return val
    //       return undefined
    //     })
    //     if (predefinedValue) {
    //       let i, upperBound
    //       if (predefinedValue.from.getMonth() < monthIndex) i = 1
    //       else if (predefinedValue.from.getMonth() === monthIndex) i = predefinedValue.from.getDate()

    //       if (predefinedValue.to.getMonth() > monthIndex || predefinedValue.indefinite) upperBound = daysInCurrentMonth
    //       else if (predefinedValue.to.getMonth() === monthIndex) upperBound = predefinedValue.to.getDate()

    //       const helperDate = new Date(year, monthIndex, i)
    //       for (i; i <= upperBound; i++) {
    //         helperDate.setDate(i)
    //         if (![0, 6].includes(helperDate.getDay())) {
    //           const datesArrayObj = dates[i - 1]
    //           console.log("pushed", predefinedValue.value)
    //           datesArrayObj[el.direction === 0 ? "outDocs" : "incDocs"].push({
    //             dueDate: new Date(year, monthIndex, i),
    //             direction: el.direction,
    //             total: predefinedValue.value,
    //             payment: {
    //               paid: true,
    //               paidConfirmed: true,
    //               payments: [],
    //               expectedPayment: [],
    //             },
    //           })
    //         }
    //       }
    //     }
    //   }
    // })

    const sectionListData = []
    if (dates[0].date.getDay() !== 1) {
      sectionListData.push({ data: [] })
    }

    for (let i = 0; i <= dates.length - 1; i++) {
      if (dates[i].date.getDay() === 1) {
        sectionListData.push({ data: [] })
      }
      sectionListData[sectionListData.length - 1].data.push(dates[i])
    }

    setPickedMonthDays(
      sectionListData.map((section) => {
        return {
          ...section,
          title:
            section.data.length > 1
              ? `${toCurrentDate(section.data[0].date, "", "")} - ${toCurrentDate(
                  section.data[section.data.length - 1].date,
                  "",
                  ""
                )}`
              : `${toCurrentDate(section.data[0].date, "", "")}`,
        }
      })
    )
  }

  const handlePickedDate = (newValue, isMonth) => {
    const dateCopy = new Date(pickedDate)
    if (newValue) {
      if (newValue !== "") {
        if (isMonth) {
          dateCopy.setMonth(Number.parseInt(newValue))
        } else {
          dateCopy.setFullYear(Number.parseInt(newValue))
        }
        setPickedDate(dateCopy)
      }
    }
  }

  const handleRefresh = () => {
    setPickedMonthDays(null)
    getMonthDays(pickedDate.getMonth(), pickedDate.getFullYear())
  }

  return (
    <Screen style={{ flex: 1 }}>
      <MonthYearPicker
        value={pickedDate}
        callback={handlePickedDate}
        style={{ marginHorizontal: 5, justifyContent: "space-between", alignItems: "center" }}
      />

      {!pickedMonthDays ? (
        <ActivityIndicator />
      ) : (
        <CashflowWrapper
          docs={pickedMonthDays}
          date={pickedDate}
          initValues={initValues}
          handleRefresh={handleRefresh}
        />
      )}
    </Screen>
  )
}
