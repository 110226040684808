import React from "react"
import { View, Text, StyleSheet, Platform } from "react-native"
import { dayNameLabels } from "../../data/labels"
import DefaultTheme from "../../themes/DefaultTheme"
import { toCurrentDate } from "../../utils/general"

export default function DailyComponent({ item, length, index }) {
  return (
    <View
      style={[
        {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100%",
          marginHorizontal: 10,
          flex: 1,
          borderRightColor: DefaultTheme.colors.borderPrimary,
        },
        Platform.OS !== "web"
          ? { paddingVertical: 5, width: "100%", borderBottomWidth: index === length - 1 ? 0 : 1 }
          : {
              borderRightWidth: index === length - 1 ? 0 : 1,
            },
      ]}
    >
      <Text style={{ textAlign: "center" }}>
        {dayNameLabels[item.date.getDay()]} - {toCurrentDate(item.date, "", "")}
      </Text>

      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 1, padding: 5, borderRightColor: DefaultTheme.colors.borderPrimary, borderRightWidth: 1 }}>
          <Text style={style.signText}>+</Text>
          {item.incDocs.map((el, index) => (
            <Text key={"indoc" + index + el.total} style={{ textAlign: "right", fontSize: 10 }}>
              {new Intl.NumberFormat("cs-CZ").format(Math.abs(el.total.toFixed(0)))}
            </Text>
          ))}
        </View>
        <View style={{ flex: 1, padding: 5 }}>
          <Text style={style.signText}>-</Text>
          {item.outDocs.map((el, index) => (
            <Text key={"outdoc" + index + el.total} style={{ textAlign: "right", fontSize: 10 }}>
              {new Intl.NumberFormat("cs-CZ").format(Math.abs(el.total.toFixed(0)))}
            </Text>
          ))}
        </View>
      </View>
    </View>
  )
}

const style = StyleSheet.create({
  signText: {
    textAlign: "center",
  },
})
