import React, { useState } from "react"
import { View, Text, Platform, TouchableOpacity } from "react-native"
import DailyComponent from "./DailyComponent"
import DefaultTheme from "../../themes/DefaultTheme"
import CurrencyFormatter from "../CurrencyFormatter"
import CurrencyWithLabelComponent from "./CurrencyWithLabelComponent"

export default function WeeklyComponent({ weekObject, weeklyTotal, totals, index, monthStart }) {
  let weekTotalStart = monthStart
  const [folded, setFolded] = useState(Platform.OS === "web" ? false : true)

  for (let i = 0; i <= index; i++) {
    weekTotalStart += totals[i].result
  }

  const ConditionalWrapper = ({ condition, children }) => {
    if (condition) {
      return <TouchableOpacity onPress={() => setFolded(!folded)}>{children}</TouchableOpacity>
    }
    return children
  }

  return (
    <View style={{ marginTop: 10, marginHorizontal: 5, padding: 5, backgroundColor: DefaultTheme.colors.white }}>
      <ConditionalWrapper
        condition={Platform.OS !== "web"}
        children={
          <>
            <Text
              style={{
                fontWeight: "500",
                marginBottom: 3,
                borderBottomColor: DefaultTheme.colors.borderPrimary,
                borderBottomWidth: 1,
                paddingBottom: 3,
              }}
            >
              {weekObject?.title || ""}
            </Text>
            {!folded || Platform.OS === "web" ? (
              <View
                style={{
                  flexDirection: Platform.OS === "web" ? "row" : "column",
                  alignItems: "center",
                  minHeight: 60,
                }}
              >
                {weekObject.data?.map((dayItem, index) => (
                  <DailyComponent
                    item={dayItem}
                    key={dayItem.date.toString()}
                    index={index}
                    length={weekObject?.data?.length}
                  />
                ))}
                <View style={{ flex: weekObject?.data?.length === 7 ? 0 : 7 - weekObject?.data?.length }}></View>
              </View>
            ) : (
              <></>
            )}
            <View
              style={{
                flexDirection: "column",
                borderTopColor: DefaultTheme.colors.borderPrimary,
                borderTopWidth: 1,
                marginTop: 3,
                paddingTop: 3,
                alignItems: "flex-end",
              }}
            >
              <CurrencyWithLabelComponent label={"Týdenní součet +"} value={weeklyTotal.income}/>
              <CurrencyWithLabelComponent label={"Týdenní součet -"} value={weeklyTotal.outcome}/>
              <CurrencyWithLabelComponent label={"Týdenní výsledek"} value={weeklyTotal.result}/>
              <CurrencyWithLabelComponent label={"Zůstatek na konci týdne"} value={weekTotalStart}/>
            </View>
          </>
        }
      />
    </View>
  )
}
