import React from "react"
import { View, Text, TouchableOpacity } from "react-native"
import { monthNameLabels } from "../../data/labels"
import DeleteButton from "../DeleteButton"
import { TextInput } from "react-native-paper"
import DefaultTheme from "../../themes/DefaultTheme"
import { Feather } from "@expo/vector-icons"

export default function CashflowInitialValueItem({ startingValues, setStartingValues, element, index, isNew = false }) {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: DefaultTheme.colors.white,
        justifyContent: "center",
        marginHorizontal: 15,
        borderBottomColor: DefaultTheme.colors.borderPrimary,
        borderRadius: 5,
        borderBottomWidth: 2,
        marginBottom: 10,
      }}
    >
      <Text style={{ width: 100, textAlign: "center" }}>
        {monthNameLabels[element?.month_index] || ""} {element?.year || ""}
      </Text>
      <TouchableOpacity
        style={{
          alignItems: "center",
          justifyContent: "center",
          backgroundColor:
            element?.type && element.type === "MANUAL" ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled,
          padding: 5,
          borderRadius: 5,
        }}
        onPress={() =>
          setStartingValues([
            ...startingValues.slice(0, index),
            { ...element, type: !element?.type || element.type === "CALCULATED" ? "MANUAL" : "CALCULATED" },
            ...startingValues.slice(index + 1),
          ])
        }
      >
        <Feather name="user-check" size={24} color={DefaultTheme.colors.white} />
      </TouchableOpacity>
      <View style={{ flex: 3 }}>
        <TextInput
          value={element.amount.toString()}
          autoFocus
          onChangeText={(text) =>
            setStartingValues([
              ...startingValues.slice(0, index),
              { ...element, amount: text },
              ...startingValues.slice(index + 1),
            ])
          }
          placeholder="Zadejte zůstatek"
          keyboardType="numeric"
          error={isNaN(Number.parseInt(element.amount))}
          style={[DefaultTheme.input]}
          //disabled={val.wasValueSet}
        />
      </View>
      <View style={{ alignItems: "center", justifyContent: "center", width: 60 }}>
        <DeleteButton
          absolute={false}
          key={index}
          onPress={() => setStartingValues([...startingValues.slice(0, index), ...startingValues.slice(index + 1)])}
          text="smazat"
        />
      </View>
    </View>
  )
}
