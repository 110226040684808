import React from "react"
import { TouchableOpacity, View, Text } from "react-native"
import { TextInput } from "react-native-paper"
import DefaultTheme from "../../themes/DefaultTheme"
import MonthYearPicker from "../MonthYearPicker"
import AppDatePicker from "../AppDatePicker"
import { cashFlowTypeEnum } from "../../data/enums"

const newDocument = {
  value: 0,
  from: new Date(),
  to: new Date(),
  indefinite: true,
}

export default function ExtraCashflowManager({ data, setData, type  = null }) {
  return (
    <View>
      {data?.map((el, index) => (
        <ExtraCashflowComponent
          key={(type + index).toString()}
          el={el}
          index={index}
          setData={setData}
          data={data}
          type={type}
        />
      ))}
    </View>
  )
}

const ExtraCashflowComponent = ({ el, index, data, setData, type }) => {
  console.debug("type", type)
  const handleMonthlyDateChange = (newValue, isMonth, extra) => {
    const element = data[extra.index]
    const dateCopy = new Date(element[extra.prop])
    if (newValue) {
      if (newValue !== "") {
        if (isMonth) {
          dateCopy.setMonth(Number.parseInt(newValue))
        } else {
          dateCopy.setFullYear(Number.parseInt(newValue))
        }
        setData(
          data.map((d, i) => {
            if (i === extra.index) return { ...d, [extra.prop]: dateCopy }
            return d
          })
        )
      }
    }
  }

  const handleDateChange = (value, prop) => {
    setData(
      data.map((d, i) => {
        if (i === index) return { ...d, [prop]: value }
        return d
      })
    )
  }

  return (
    <View key={"managerItem-" + index}>
      <TextInput
        error={isNaN(Number.parseInt(el.value))}
        style={[DefaultTheme.input, { marginTop: 10 }]}
        label={el.value !== undefined || null ? "Hodnota" : "Zadejte hodnotu"}
        onChangeText={(val) =>
          setData(
            data?.map((e, innerIndex) => {
              if (innerIndex === index) return { ...e, value: val }
              return e
            })
          )
        }
        keyboardType="number-pad"
        value={el?.value.toString() || "0"}
        underlineColorAndroid="transparent"
      />
      {type === cashFlowTypeEnum.MONTHLY ? (
        <MonthYearPicker callback={handleMonthlyDateChange} value={el.from} extra={{ index: index, prop: "from" }} />
      ) : (
        <AppDatePicker value={el.from} onConfirm={(value) => handleDateChange(value, "from")} />
      )}
      {type !== cashFlowTypeEnum.ONE_TIME ? (
        <TouchableOpacity
          onPress={() =>
            setData(
              data?.map((e, innerIndex) => {
                if (innerIndex === index) return { ...e, indefinite: !e.indefinite }
              })
            )
          }
          style={{
            backgroundColor: el.indefinite ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled,
            height: 40,
            padding: 10,
            margin: 10,
            justifyContent: "center",
            alignSelf: "center",
            borderRadius: 5,
          }}
        >
          <Text style={{ color: "white" }}>Opakovat do nekonečna</Text>
        </TouchableOpacity>
      ) : (
        <></>
      )}

      {type !== cashFlowTypeEnum.ONE_TIME  ? (
        type === cashFlowTypeEnum.MONTHLY ? (
          <MonthYearPicker
            callback={handleMonthlyDateChange}
            value={el.to}
            extra={{ index: index, prop: "to" }}
            disabled={el.indefinite}
          />
        ) : (
          <AppDatePicker value={el.to} onConfirm={(value) => handleDateChange(value, "to")} disabled={el.indefinite} />
        )
      ) : (
        <></>
      )}
    </View>
  )
}
