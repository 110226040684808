import React, { useEffect, useState } from "react"
import { FlatList } from "react-native"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import FlatListProjectItem from "../../components/FlatLists/FlatListProjectItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"

import Screen from "../../components/Screen"
import { projectStateEnum, viewTypeEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"

export default function DiaryOverview({ navigation }) {
  const { projects, settings } = useData()
  const [syncedProjects, setSyncedProjects] = useState([])

  useEffect(() => {
    setSyncedProjects(
      settings.projects?.show_locked_projects
        ? projects.filter((proj) => proj.isSyncing)
        : projects.filter((proj) => proj.isSyncing && proj.state !== projectStateEnum.CLOSED)
    )
  }, [projects, settings])

  return (
    <Screen>
      {syncedProjects.length > 0 ? (
        <FlatList
          data={syncedProjects}
          renderItem={({ item }) => <FlatListProjectItem project={item} viewType={viewTypeEnum.DIARY} />}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="Neexistuje žádný projekt nebo vy nejste přiřazen k projektu" />
      )}
    </Screen>
  )
}
