import React, { useEffect, useState } from "react"
import { TouchableOpacity } from "react-native"
import { Menu } from "react-native-paper"
import { Foundation, MaterialCommunityIcons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import { useStatus } from "../../providers/StatusProvider"
import { statusEnum } from "../../data/enums"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useData } from "../../providers/DataProvider"

export default function AccountingDocumentsHeaderMenu({ navigation }) {
  const [showMenu, setShowMenu] = useState(false)
  //const {} = useData()
  //const [projectSettings, setProjectSettings] = useState({})

  return (
    <Menu
      visible={showMenu}
      onDismiss={() => setShowMenu(false)}
      anchor={
        <TouchableOpacity onPress={() => setShowMenu(true)} style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.headerSecondary}
          />
        </TouchableOpacity>
      }
    >
      <Menu.Item
        style={{ direction: "ltr" }}
        onPress={() => {
          setShowMenu(false), navigation.navigate("TriviDocsNotes")
        }}
        title="Dokumenty s poznámkami"
        icon={() => (
          <Foundation name="clipboard-notes" size={DefaultTheme.icons.menuSize} color={DefaultTheme.colors.primary} />
        )}
      />
    </Menu>
  )
}
