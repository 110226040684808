import React, { useEffect, useState } from "react"
import { FlatList, Text, StyleSheet } from "react-native"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import FlatListDocumentsUserItem from "../../components/FlatLists/FlatListDocumentsUserItem"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import Constants from "expo-constants"
import { environmentEnum, userRoleEnum, userStateEnum } from "../../data/enums"

export default function DocumentsUserOverview({ navigation, route }) {
  const { users } = useData()
  const [filteredUsers, setFilteredUsers] = useState([])

  useEffect(() => {
    const activeUsers = users.filter(
      (u) => u.state !== userStateEnum.DELETED && !u.roles.includes(userRoleEnum.INVESTOR)
    )
    if (Constants.manifest.extra.environment !== environmentEnum.dev) {
      setFilteredUsers(activeUsers.filter((u) => !u.roles.includes(userRoleEnum.ADMIN)))
    } else {
      setFilteredUsers(activeUsers)
    }
  }, [users])

  return (
    <Screen>
      {filteredUsers.length > 0 ? (
        <FlatList
          data={filteredUsers}
          renderItem={({ item }) => <FlatListDocumentsUserItem navigation={navigation} user={item} />}
          keyExtractor={(item) => item.email.toString()}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="Neexistují žádní uživatelé" />
      )}
    </Screen>
  )
}
