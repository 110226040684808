import React from "react"
import { Text, View, StyleSheet, SafeAreaView, TouchableOpacity, Platform } from "react-native"

export default function HeaderActionIcon({ onPress, icon, disabled = false }) {
  return (
    <TouchableOpacity style={styles.pressArea} onPress={onPress} disabled={disabled}>
      <View>{icon ? icon : ""}</View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  pressArea: {
    paddingLeft: 16,
    paddingRight: Platform.OS === "web" ? 0 : 16,
    paddingBottom: 8,
    paddingTop: 8,
  },
})
