import React, { useEffect, useRef, useState } from "react"
import { FlatList, SafeAreaView, ScrollView, Text, View } from "react-native"
import FlatListCashDeskMonthItem from "../../components/FlatLists/FlatListCashDeskMonthItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import { paymentEnum } from "../../data/enums"

export default function CashDeskMonths({ navigation, route }) {
  const { accountingDocuments, currentUser } = useData()
  const userRef = useRef(route.params?.user || currentUser)
  const [paidDocuments, setPaidDocuments] = useState([])
  const today = new Date()

  useEffect(() => {
    setPaidDocuments(
      accountingDocuments.filter(
        (document) => document.created_by === userRef.current.email && document.payment !== paymentEnum.BANK_TRANSFER
      )
    )
  }, [accountingDocuments])

  function monthDiff(d1, d2) {
    let months

    if (d1 && d2) {
      months = (d2.getFullYear() - d1.getFullYear()) * 12
      months -= d1.getMonth()
      months += d2.getMonth()
    }
    return months <= 0 ? 0 : months
  }

  function checkMonthInterval(monthIndex, document) {
    const date = new Date()
    const from = new Date(date.getFullYear(), date.getMonth() - monthIndex, 1)
    const to = new Date(date.getFullYear(), date.getMonth() - monthIndex + 1)

    return from.getTime() < document.date.toDate().getTime() && document.date.toDate().getTime() < to.getTime()
  }

  function countMonthsToLastDocument(docs) {
    if (docs.length > 0) {
      return docs.reduce(
        (a, c) =>
          a > monthDiff((c.date || c.created_at).toDate(), today)
            ? a
            : monthDiff((c.date || c.created_at).toDate(), today),
        0
      )
    }
    return 0
  }

  let docsByMonths = []
  for (let monthIndex = -12; monthIndex <= countMonthsToLastDocument(paidDocuments) + 4; monthIndex++) {
    let monthDocs = []
    paidDocuments.forEach((document) => {
      if (checkMonthInterval(monthIndex, document)) {
        monthDocs.push(document)
      }
    })
    if (monthDocs.length > 0 || (-2 < monthIndex && monthIndex <= countMonthsToLastDocument(paidDocuments) + 4)) {
      docsByMonths.push({ monthIndex: monthIndex, monthDocs: monthDocs })
    }
  }

  return (
    <Screen>
      {docsByMonths.length > 0 ? (
        <>
          <View style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
            <Text style={{ textAlign: "right", padding: 5, fontWeight: "bold" }}>Ceny jsou uvedeny v CZK s DPH</Text>
          </View>
          <FlatList
            data={docsByMonths}
            renderItem={({ item }) => (
              <FlatListCashDeskMonthItem month={item} navigation={navigation} user={userRef.current} />
            )}
            keyExtractor={(item) => item.monthIndex.toString()}
            ItemSeparatorComponent={FlatListSeparator}
          />
        </>
      ) : (
        <AppEmptyScreenView message="Ve vaší pokladně zatím nejsou žádné doklady" />
      )}
    </Screen>
  )
}
