//const currencyCode = 'Rp '; // IDR or USD
let currencyPosition = "right" // left or right
const maxFractionDigits = 2
const decimalSeparator = " , "

function position(currencyPosition, value, currencyCode) {
  return currencyPosition === "left" ? `${currencyCode} ${value}` : `${value} ${currencyCode}`
}

const CurrencyFormatter = (value, currencyCode = "CZK", vat = 1, wrap = false) => {
  var string = "string"
  var result

  currencyPosition = currencyCode === "USD" ? "left" : "right"

  const thousandSeparator = currencyCode === "USD" ? "," : " "
  const vatString = vat === 0 ? "bez DPH" : vat === -1 ? "" : "s DPH"
  if (value === 0 || value === null || value === undefined || value === "0" || typeof value === string) {
    return `${position(currencyPosition, 0, currencyCode)}${wrap ? "\n" : " "}${vatString}`
  }

  //let currencyCheck = currencyCode.replace(/\s/g, '').toLowerCase();
  //if (currencyCheck === 'idr' || currencyCheck === 'rp') {
  // value = Math.ceil(value)
  //}
  const valueSplit = (
    String(value).includes(".") ? value.toFixed(maxFractionDigits).replace(".", ", ") : String(value)
  ).split(`${thousandSeparator}`)
  const firstvalue = valueSplit[0]
  const secondvalue = valueSplit[1]
  const valueReal = String(firstvalue).replace(/\B(?=(\d{3})+(?!\d))/g, `${thousandSeparator}`)

  if (Number(secondvalue) > 0) {
    result = position(currencyPosition, `${valueReal} ${secondvalue}`, currencyCode)
  } else {
    result = position(currencyPosition, `${valueReal}`, currencyCode)
  }
  return `${result}${wrap ? "\n" : " "}${vatString}`
}

export default CurrencyFormatter
