import React, { useEffect, useMemo, useState } from "react"
import { ActivityIndicator, Platform, ScrollView, Text, TouchableOpacity, View } from "react-native"
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"

import { useData } from "../../providers/DataProvider"
import { useStatus } from "../../providers/StatusProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import { approveEnum, monthStateEnum, statusEnum, userRoleEnum, userStateEnum } from "../../data/enums"
import FSStoreContent from "../../firebase/FSStoreContent"
import {
  getCashDeskMonthClosingState,
  getLogBookMonthClosingState,
  getAttendanceMonthClosingState,
} from "../../utils/general"
import { monthNameLabels } from "../../data/labels"
import PrimButton from "../PrimButton"
import AppEmptyScreenView from "../AppEmptyScreenView"

const pickStateEnum = { PREVIOUS: "PREVIOUS", CURRENT: "CURRENT" }

export default function ClosingsDashboard({ users }) {
  const filteredUsers = users

  const { setStatus, setMessage } = useStatus()
  const navigation = useNavigation()
  const [items, setItems] = useState([])
  const [isRefreshing, setIsRefreshing] = useState(true)

  const today = new Date()
  const zeroDay = new Date(today.getFullYear(), today.getMonth(), 1).getDate() - 1
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()

  const defaultClosingDay = 10

  const [pickedMonth, setPickedMonth] = useState(
    today.getDate() <= defaultClosingDay ? pickStateEnum.PREVIOUS : pickStateEnum.CURRENT
  )

  useMemo(() => {
    loadData(getClosingYearMonth(defaultClosingDay))
  }, [filteredUsers])

  async function loadData(closingYearMonth) {
    try {
      console.debug("Loading data for: ", closingYearMonth)
      setIsRefreshing(true)

      const cashDesks = await getCashDesks()
      console.debug("CASH DESKS", cashDesks)

      const attendanceClosings = await getAttendanceClosings(closingYearMonth)
      console.debug("ATTENDANCE CLOSINGS", attendanceClosings)

      const logBookClosings = await getLogBookClosings(closingYearMonth)
      console.debug("LOG BOOK CLOSINGS", logBookClosings)

      getItems(closingYearMonth, logBookClosings, attendanceClosings, cashDesks)
      setIsRefreshing(false)
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  function getItems(closingYearMonth, logBookClosings, attendanceClosings, cashDesks) {
    const tempItems = [
      { header: "Jméno a přijmení", data: filteredUsers.map((u) => u.name || u.email), type: "text" },
      // {
      //   header: "SR",
      //   // header: "Školení řidičů",
      //   data: users.map((u) =>
      //     u.driver_training === approveEnum.ACCEPTED
      //       ? { name: "check", color: DefaultTheme.colors.success }
      //       : { name: "remove", color: DefaultTheme.colors.danger }
      //   ),
      //   type: "icon",
      // },
      // {
      //   header: "LP",
      //   // header: "Lékařská prohlídka",
      //   data: users.map((u) =>
      //     u.medical_examination === approveEnum.ACCEPTED
      //       ? { name: "check", color: DefaultTheme.colors.success }
      //       : { name: "remove", color: DefaultTheme.colors.danger }
      //   ),
      //   type: "icon",
      // },
      // {
      //   header: "RE",
      //   // header: "Revize elektrozařízení",
      //   data: users.map((u) =>
      // u.el_equipment_revision === approveEnum.ACCEPTED
      //       ? { name: "check", color: DefaultTheme.colors.success }
      //       : { name: "remove", color: DefaultTheme.colors.danger }
      //   ),
      //   type: "icon",
      // },
      {
        header: <MaterialCommunityIcons name="cash-register" size={DefaultTheme.icons.menuSize} />,
        data: filteredUsers.map((u) => {
          const userCashDesk = cashDesks.find((cashDesk) => cashDesk.user === u.email)
          return monthStateEnum.CLOSED === getCashDeskMonthClosingState(userCashDesk, closingYearMonth)
            ? { name: "check", color: DefaultTheme.colors.success }
            : { name: "remove", color: DefaultTheme.colors.danger }
        }),
        type: "icon",
      },
      //checks closing items,
      //if empty => checks if clossingYearMonth represents last month => last month is autolocked if not locked manually by user
      {
        header: <MaterialCommunityIcons name="clock" size={24} />,
        data: attendanceClosings.map((userClosings) =>
          getAttendanceMonthClosingState(userClosings.closingItems) === monthStateEnum.CLOSED ||
          closingYearMonth === `${today.getFullYear()}_${today.getMonth() - 1}`
            ? { name: "check", color: DefaultTheme.colors.success }
            : { name: "remove", color: DefaultTheme.colors.danger }
        ),
        type: "icon",
      }, // TODO probably will have to be from created closing items
      {
        header: <FontAwesome name="suitcase" size={24} />,
        data: logBookClosings.map((userClosings) =>
          getLogBookMonthClosingState(userClosings) === monthStateEnum.CLOSED
            ? { name: "check", color: DefaultTheme.colors.success }
            : { name: "remove", color: DefaultTheme.colors.danger }
        ),
        type: "icon",
      },
    ]

    setItems(tempItems)
  }

  async function getCashDesks() {
    return await Promise.all(filteredUsers.map(async (u) => await FSStoreContent.getCashDesk(u.email)))
  }

  async function getAttendanceClosings(closingYearMonth) {
    return await Promise.all(
      filteredUsers.map(async (u) => {
        const items = await FSStoreContent.getUserClosingItems(u.email)
        return { user: u.email, closingItems: items[closingYearMonth] || [] }
      })
    )
  }

  async function getLogBookClosings(closingYearMonth) {
    return await Promise.all(
      filteredUsers.map(async (u) => {
        const monthLogBook = await FSStoreContent.getLogBookUser(u.email, closingYearMonth)
        return monthLogBook?.closing_items || []
      })
    )
  }

  function getClosingYearMonth(closingDay) {
    let closingMonth = today.getMonth() - (today.getDate() <= closingDay ? 1 : 0)
    closingMonth = closingMonth === -1 ? 11 : closingMonth
    const closingYear = today.getFullYear() - (closingMonth > today.getMonth() ? 1 : 0)

    console.debug("CLOSING YEAR_MONTH", `${closingYear}_${closingMonth}`)
    return `${closingYear}_${closingMonth}`
  }

  function handleClosingMonthPress(pickState) {
    const closingDay = pickState === pickStateEnum.CURRENT ? zeroDay : lastDay
    console.debug("CLOSING DAY", closingDay)
    const closingYearMonth = getClosingYearMonth(closingDay)

    loadData(closingYearMonth)

    setPickedMonth(pickState)
  }

  return (
    <>
      <View
        style={{
          padding: 5,
          backgroundColor: DefaultTheme.colors.white,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* {isRefreshing ? (
          <ActivityIndicator color={DefaultTheme.colors.primary} />
        ) : ( */}
        <View />
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={{ fontSize: 16 }}>Uzávěrky za </Text>
          <TouchableOpacity
            onPress={() => handleClosingMonthPress(pickStateEnum.PREVIOUS)}
            style={
              pickedMonth === pickStateEnum.PREVIOUS && {
                backgroundColor: DefaultTheme.colors.primary,
                padding: 5,
                borderRadius: 5,
              }
            }
          >
            <Text style={[{ fontSize: 16 }, pickedMonth === pickStateEnum.PREVIOUS && { color: "white" }]}>
              {monthNameLabels[today.getMonth() - 1 === -1 ? 11 : today.getMonth() - 1]}
            </Text>
          </TouchableOpacity>
          <Text style={{ fontSize: 16 }}> / </Text>
          <TouchableOpacity
            onPress={() => handleClosingMonthPress(pickStateEnum.CURRENT)}
            style={
              pickedMonth === pickStateEnum.CURRENT && {
                backgroundColor: DefaultTheme.colors.primary,
                padding: 5,
                borderRadius: 5,
              }
            }
          >
            <Text style={[{ fontSize: 16 }, pickedMonth === pickStateEnum.CURRENT && { color: "white" }]}>
              {monthNameLabels[today.getMonth()]}
            </Text>
          </TouchableOpacity>
        </View>

        <PrimButton
          icon={<MaterialCommunityIcons name="refresh" size={24} color={DefaultTheme.colors.white} />}
          onPress={() => handleClosingMonthPress(pickedMonth)}
          width={36}
          // disabled={tokenDuration > 0}
        />
        {/* )} */}
      </View>
      <View
        style={{
          padding: 5,
          backgroundColor: DefaultTheme.colors.white,
          flexDirection: "row",
        }}
      >
        {items.map(({ header, data, type }, index) => (
          <View
            key={index}
            style={{ flexGrow: 1, borderBottomWidth: 1, borderBottomColor: DefaultTheme.colors.borderPrimary }}
          >
            <View
              style={{
                alignItems: "center",
                padding: 10,
              }}
            >
              {type === "text" ? (
                <Text style={{ fontWeight: "bold", padding: 2.5 }} numberOfLines={1}>
                  {header}
                </Text>
              ) : (
                header
              )}
            </View>
          </View>
        ))}
      </View>
      <ScrollView
        contentContainerStyle={[
          {
            padding: 10,
            backgroundColor: DefaultTheme.colors.white,
            flexDirection: "row",
            flexGrow: 1,
          },
          isRefreshing && { alignItems: "center", justifyContent: "center" },
        ]}
      >
        {isRefreshing ? (
          <ActivityIndicator color={DefaultTheme.colors.primary} />
        ) : filteredUsers.length > 0 ? (
          items.map(({ header, data, type }, index) => (
            <View key={index} style={{ flexGrow: 1 }}>
              {/* <View
              style={{
                alignItems: "center",
                padding: 10,
                borderBottomWidth: 1,
                borderBottomColor: DefaultTheme.colors.borderPrimary,
              }}
            >
              <Text style={{ fontWeight: "bold" }} numberOfLines={1}>
                {header}
              </Text>
            </View> */}
              {data.map((item, index) => (
                <View key={index} style={[{ padding: 10, height: 40, alignItems: "center" }]}>
                  {type === "text" ? (
                    <Text style={{ paddingHorizontal: 7 }}>{item}</Text>
                  ) : (
                    <FontAwesome name={item?.name} size={24} color={item?.color} />
                  )}
                </View>
              ))}
            </View>
          ))
        ) : (
          <AppEmptyScreenView message="V nastavení - uživatelé vyberte, kteří uživatelé se mají zobrazit v uzávěrkách" />
        )}
      </ScrollView>
    </>
  )
}
