import { firebase } from "./config"

//const firestoreRef = firebase.apps[0].firestore().collection("projects")
const entriesCollection = "dairyEntries"
const documentsCollection = "diaryDocuments"

export async function addEntry(projectId, entry) {
  try {
    await firebase.apps[0].firestore().collection("projects").doc(projectId).collection(entriesCollection).add(entry)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getEntry(projectId, entryId) {
  try {
    return (
      await firebase.apps[0]
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection(entriesCollection)
        .doc(entryId)
        .get()
    ).data()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function updateEntry(projectId, entry) {
  try {
    const entryId = entry.id
    delete entry.id
    await firebase.apps[0]
      .firestore()
      .collection("projects")
      .doc(projectId)
      .collection(entriesCollection)
      .doc(entryId)
      .set(entry, { merge: false })
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function deleteEntry(projectId, entryId) {
  try {
    await firebase.apps[0]
      .firestore()
      .collection("projects")
      .doc(projectId)
      .collection(entriesCollection)
      .doc(entryId)
      .delete()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function duplicateEntry(projectId, entryId) {
  try {
    const defaultSignature = { investor: "", construction_lead: "" }
    const newEntry = await getEntry(projectId, entryId)
    newEntry.signature = defaultSignature

    delete newEntry.state
    await addEntry(projectId, newEntry)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function onSnapshotEntry(projectId, diaryEntryId, next) {
  firebase.apps[0]
    .firestore()
    .collection("projects")
    .doc(projectId)
    .collection(entriesCollection)
    .doc(diaryEntryId)
    .onSnapshot((doc) => {
      try {
        if (doc.data()) {
          next({
            ...doc.data(),
            date_interval: { from: doc.data().date_interval.from.toDate(), to: doc.data().date_interval.to.toDate() },
            id: doc.id,
          })
        }
      } catch (error) {
        console.error(error)
        throw error
      }
    })
}

export function onSnapshotEntryList(projectId, next) {
  firebase.apps[0]
    .firestore()
    .collection("projects")
    .doc(projectId)
    .collection(entriesCollection)
    .onSnapshot((snap) => {
      try {
        next(
          snap.docs.map((doc) => ({
            ...doc.data(),
            date_interval: { from: doc.data().date_interval.from.toDate(), to: doc.data().date_interval.to.toDate() },
            id: doc.id,
          }))
        )
      } catch (error) {
        console.error(error)
        throw error
      }
    })
}

export async function addDocument(projectId, document) {
  try {
    return await firebase.apps[0]
      .firestore()
      .collection("projects")
      .doc(projectId)
      .collection(documentsCollection)
      .add(document)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function updateDocument(projectId, document) {
  try {
    const documentId = document.id
    delete document.id
    await firebase.apps[0]
      .firestore()
      .collection("projects")
      .doc(projectId)
      .collection(documentsCollection)
      .doc(documentId)
      .update(document)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function onSnapshotDocument(projectId, documentId, next) {
  firebase.apps[0]
    .firestore()
    .collection("projects")
    .doc(projectId)
    .collection(documentsCollection)
    .doc(documentId)
    .onSnapshot((doc) => {
      try {
        if (doc.data()) {
          next({
            ...doc.data(),
            date: doc.data().date.toDate(),
            id: doc.id,
          })
        }
      } catch (error) {
        console.error(error)
        throw error
      }
    })
}

export function onSnapshotDocumentList(projectId, next) {
  firebase.apps[0]
    .firestore()
    .collection("projects")
    .doc(projectId)
    .collection(documentsCollection)
    .onSnapshot((snap) => {
      try {
        next(
          snap.docs.map((doc) => ({
            ...doc.data(),
            updated_at: doc.data().updated_at.toDate(),
            date: doc.data().date.toDate(),
            id: doc.id,
          }))
        )
      } catch (error) {
        console.error(error)
        throw error
      }
    })
}

/**
 *
 * @returns unsub method for snapshot listener
 */
export function onSnapshotEntryListUnsub(projectId, next) {
  const unsub = firebase.apps[0]
    .firestore()
    .collection("projects")
    .doc(projectId)
    .collection(entriesCollection)
    .onSnapshot((snap) => {
      try {
        next(
          snap.docs.map((doc) => ({
            ...doc.data(),
            date_interval: { from: doc.data().date_interval.from.toDate(), to: doc.data().date_interval.to.toDate() },
            id: doc.id,
          }))
        )
      } catch (error) {
        console.error(error)
        throw error
      }
    })
  return unsub
}

export default {
  addEntry,
  getEntry,
  updateEntry,
  deleteEntry,
  duplicateEntry,
  onSnapshotEntryList,
  onSnapshotEntry,
  addDocument,
  updateDocument,
  onSnapshotDocument,
  onSnapshotDocumentList,
  onSnapshotEntryListUnsub,
}
