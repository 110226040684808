const filterCategories = {
  Stavba: [
    "Stavebniny",
    "Suchá výstavba",
    "Stavební přípomoce",
    "Betony",
    "Zemní práce a přípojky",
    "Lité podlahy",
    "Obklady a dlažby",
    "Klempířské výrobky",
    "Zámečnické výrobky",
    "Střešní konstrukce",
    "Ploché střechy",
    "Komíny a kachle",
    "Kompletní stavební práce",
  ],
  TZB: ["Komplet", "ZTI", "RTCH", "ÚT", "VZT", "ZOTK", "SHZ", "Gastro"],
  Elektro: [
    "Komplet",
    "Silnoproud",
    "Slaboproud",
    "Inteligence",
    "Meření a regulace",
    "Optika + STA + satelit",
    "Osvětelní",
    "Audio a video systémy",
  ],
  Design: [
    "Stěrky",
    "Prosklenky",
    "Polepy",
    "Reklamní předměty",
    "Orientační systémy",
    "Designové výrobky",
    "Potisky",
    "Tapety",
    "Osvětlení",
    "3D tisk",
  ],
  Nábytek: ["Atypový nábytek", "Typový nábytek", "Kovářské výrobky"],
}

export default { filterCategories }
