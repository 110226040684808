import React, { useState } from "react"
import { TouchableOpacity } from "react-native"
import { Menu } from "react-native-paper"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import { exportMonthLogBook } from "../../actions/headerItemActions"
import { useStatus } from "../../providers/StatusProvider"
import { statusEnum, viewTypeEnum } from "../../data/enums"
import { useNavigation } from "@react-navigation/native"

export default function DashboardHeaderMenu({ params }) {
  const [showMenu, setShowMenu] = useState(false)
  const navigation = useNavigation()

  return (
    <Menu
      visible={showMenu}
      onDismiss={() => setShowMenu(false)}
      anchor={
        <TouchableOpacity onPress={() => setShowMenu(true)} style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.headerSecondary}
          />
        </TouchableOpacity>
      }
    >
      <Menu.Item
        style={{ direction: "ltr" }}
        onPress={() => {
          setShowMenu(false), navigation.navigate("DashboardScreen", { viewType: viewTypeEnum.CLOSINGS })
        }}
        title="Uzávěrky"
        icon={() => (
          <MaterialCommunityIcons
            name="calendar-multiple-check"
            size={DefaultTheme.icons.menuSize}
            color={DefaultTheme.colors.primary}
          />
        )}
      />
      <Menu.Item
        style={{ direction: "ltr" }}
        onPress={() => {
          setShowMenu(false), navigation.navigate("DashboardScreen", { viewType: viewTypeEnum.STATISTICS })
        }}
        title="Přehled"
        icon={() => (
          <MaterialCommunityIcons
            name="chart-line"
            size={DefaultTheme.icons.menuSize}
            color={DefaultTheme.colors.primary}
          />
        )}
      />
      <Menu.Item
        style={{ direction: "ltr" }}
        onPress={() => {
          setShowMenu(false), navigation.navigate("DashboardScreen", { viewType: viewTypeEnum.CASHFLOW })
        }}
        title="Cashflow"
        icon={() => (
          <MaterialCommunityIcons
            name="clipboard-flow-outline"
            size={DefaultTheme.icons.menuSize}
            color={DefaultTheme.colors.primary}
          />
        )}
      />
    </Menu>
  )
}
