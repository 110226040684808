import React, { useState, useContext, useRef } from "react"
import { statusEnum } from "../data/enums"

const StatusContext = React.createContext()

export const useStatus = () => {
  const {
    statusEnum,
    status,
    setStatus,
    title,
    setTitle,
    message,
    setMessage,
    percentUploaded,
    setPercentUploaded,
    action,
    setAction,
    toScreen,
    setToScreen,
    showAlert,
    setShowAlert,
    alertProgress,
    setAlertProgress,
    onConfirm,
    setOnConfirm,
    onCancel,
    setOnCancel,
    navStateMessage,
    setNavStateMessage,
  } = useContext(StatusContext)

  const updatePercentage = useRef()

  updatePercentage.current = (snap) => {
    const p = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
    setPercentUploaded(p)
  }

  return {
    statusEnum,
    status,
    setStatus,
    title,
    setTitle,
    message,
    setMessage,
    percentUploaded,
    setPercentUploaded,
    action,
    setAction,
    toScreen,
    setToScreen,
    updatePercentage,
    showAlert,
    setShowAlert,
    alertProgress,
    setAlertProgress,
    onConfirm,
    setOnConfirm,
    onCancel,
    setOnCancel,
    navStateMessage,
    setNavStateMessage,
  }
}

export default function StatusProvider(props) {
  const [status, setStatus] = useState(statusEnum.NONE)
  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")
  const [percentUploaded, setPercentUploaded] = useState(0)
  const [action, setAction] = useState({ type: {}, params: {} })
  const [toScreen, setToScreen] = useState("")
  const [showAlert, setShowAlert] = useState(false)
  const [alertProgress, setAlertProgress] = useState(false)
  const [onConfirm, setOnConfirm] = useState(() => () => {})
  const [onCancel, setOnCancel] = useState("")
  const [navStateMessage, setNavStateMessage] = useState("")

  return (
    <StatusContext.Provider
      value={{
        statusEnum,
        status,
        setStatus,
        title,
        setTitle,
        message,
        setMessage,
        percentUploaded,
        setPercentUploaded,
        action,
        setAction,
        toScreen,
        setToScreen,
        showAlert,
        setShowAlert,
        alertProgress,
        setAlertProgress,
        onConfirm,
        setOnConfirm,
        onCancel,
        setOnCancel,
        navStateMessage,
        setNavStateMessage,
      }}
    >
      {props.children}
    </StatusContext.Provider>
  )
}
