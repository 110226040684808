import React, { useState } from "react"
import { View, Text, Platform, TouchableOpacity } from "react-native"
import DailyComponent from "./DailyComponent"
import DefaultTheme from "../../themes/DefaultTheme"
import CurrencyFormatter from "../CurrencyFormatter"

export default function CurrencyWithLabelComponent({ value, label }) {
  return (
      <View style={{ flexDirection: "row", width: "100%" }}>
        <Text style={{ width: "50%" }}>{ label }</Text>
        <Text style={{ textAlign: "right", width: "50%", color: value > 0 ? DefaultTheme.colors.success : "red" }}>{CurrencyFormatter(Math.floor(value), "Kč", -1)}</Text>
      </View>
  )
}
