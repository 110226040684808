import React, { useEffect, useState } from "react"
import {
  ActivityIndicator,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native"
import { MaterialCommunityIcons, FontAwesome5, FontAwesome, MaterialIcons } from "@expo/vector-icons"
import { TextInput } from "react-native-paper"

import DateIntervalButtons from "../../components/DateIntervalButtons"
import Screen from "../../components/Screen"
import NextButton from "../../components/NextButton"
import { useData } from "../../providers/DataProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import FSStoreContent from "../../firebase/FSStoreContent"
import FlatListPropertyItem from "../../components/FlatLists/FlatListPropertyItem"
import PrimButton from "../../components/PrimButton"
import AppPicker from "../../components/AppPicker"
import { formatDecimalNumber, getDateSimpleString, getHourDeclensionForm, toCurrentDateTime } from "../../utils/general"
import * as Validation from "../../utils/validation"
import { isOnlyInvestor } from "../../utils/validation"
import ItemsCounter from "../../components/ItemsCounter"
import ScanList from "../../components/ScanList"
import { uploadFileOnFirebase } from "../../firebase/FSGeneral"
import Signature from "../../components/Signature"
import { useStatus } from "../../providers/StatusProvider"
import { itemStateEnum, projectStateEnum, statusEnum, viewTypeEnum, weatherTypeEnum } from "../../data/enums"
import * as FSDiary from "../../firebase/FSDiary"
import NavigationPicker from "../../components/NavigationPicker"
import DeleteButton from "../../components/DeleteButton"
import { weatherTypeLabels } from "../../data/labels"
import { getLocation } from "../../actions/platformFeatureRequestor"
import AppDatePicker from "../../components/AppDatePicker"
import { getWeather } from "../../actions/weatherActions"
import WeatherIcons from "../../utils/WeatherIcons"
import AddressAutocomplete from "../../components/AddressAutocomplete"

export default function DiaryEntryDetail({ route, navigation }) {
  const { setTitle, setStatus, setMessage, updatePercentage, setOnConfirm } = useStatus()
  const { projects, currentUser, firebaseConfig } = useData()
  const [diaryEntryInterval, setDiaryEntryInterval] = useState({ from: new Date(), to: new Date() })
  const [pressed, setPressed] = useState({ weather: true })
  const projectId = route.params?.projectId || "Z1"
  const diaryEntryId = route.params?.diaryEntryId
  console.debug("DIARY ENTRY ID", diaryEntryId)

  const [project, setProject] = useState({})
  const [isProjectClosed, setIsProjectClosed] = useState(false)
  const [isEntryClosed, setIsEntryClosed] = useState(false)

  const [weather, setWeather] = useState({ value: "", date: getDateSimpleString(new Date()) })
  const [location, setLocation] = useState({ street: "", latitude: "", longitude: "" })
  const [userItems, setUserItems] = useState([])
  const [mechanizationItems, setMechanizationItems] = useState([])
  const [materialItems, setMaterialItems] = useState([])
  const [constructionWorkItems, setConstructionWorkItems] = useState([])
  const [recordItems, setRecordItems] = useState([])
  const [attachmentItems, setAttachmentItems] = useState([])
  const [signature, setSignature] = useState({ investor: "", construction_lead: "" })
  console.debug("DIARY ENTRY", {
    diaryEntryInterval,
    weather,
    userItems,
    mechanizationItems,
    materialItems,
    constructionWorkItems,
    recordItems,
    attachmentItems,
  })
  console.debug("PROJ", project)
  console.debug("LOCATION", location)
  console.debug("WEATHER", weather)

  useEffect(() => {
    const tempProj = projects.find((proj) => proj.id === projectId)
    const tempAddress = tempProj?.diary?.address || {}
    setProject(tempProj)
    setLocation({ street: "", latitude: "", longitude: "", ...tempAddress })
    setIsProjectClosed(tempProj?.state === projectStateEnum.CLOSED)
  }, [projects])

  useEffect(() => {
    if (diaryEntryId) {
      FSDiary.onSnapshotEntry(
        projectId,
        diaryEntryId,
        ({
          date_interval,
          weather,
          users,
          mechanization,
          material,
          construction_work,
          records,
          attachments,
          signature,
          state,
        }) => {
          setDiaryEntryInterval({ from: date_interval.from, to: date_interval.to })
          setWeather(weather || { value: "", date: getDateSimpleString(new Date()) })
          setUserItems(users || [])
          setMechanizationItems(mechanization || [])
          setMaterialItems(material || [])
          setConstructionWorkItems(construction_work || [])
          setRecordItems(records || [])
          setAttachmentItems(attachments || [])
          setSignature(signature || {})
          setIsEntryClosed(state === itemStateEnum.CLOSED)
        }
      )
    }
  }, [])

  const listItems = [
    {
      key: "weather",
      title: "Počasí",
      ListComponent: Weather,
      itemsLength: 0,
      icon: <MaterialCommunityIcons name="weather-sunny" size={DefaultTheme.icons.flatlistSize} />,
    },
    {
      key: "users",
      title: "Osoby na staveništi",
      ListComponent: Users,
      itemsLength: userItems.length,
      icon: <FontAwesome5 name="people-carry" size={DefaultTheme.icons.flatlistSize} />,
    },
    {
      key: "mechanization",
      title: "Mechanizace",
      ListComponent: Mechanization,
      itemsLength: mechanizationItems.length,
      icon: <FontAwesome name="gears" size={DefaultTheme.icons.flatlistSize} />,
    },
    {
      key: "material",
      title: "Materiál",
      ListComponent: Material,
      itemsLength: materialItems.length,
      icon: <MaterialCommunityIcons name="layers-triple" size={DefaultTheme.icons.flatlistSize} />,
    },
    {
      key: "constructionWork",
      title: "Provedené práce",
      ListComponent: ConstructionWork,
      itemsLength: constructionWorkItems.length,
      icon: <MaterialCommunityIcons name="hammer-wrench" size={DefaultTheme.icons.flatlistSize} />,
    },
    {
      key: "record",
      title: "Záznamy",
      ListComponent: Record,
      itemsLength: recordItems.length,
      icon: <MaterialIcons name="list-alt" size={DefaultTheme.icons.flatlistSize} />,
    },
    {
      key: "attachment",
      title: "Přílohy",
      ListComponent: Attachment,
      itemsLength: attachmentItems.length,
      icon: <MaterialCommunityIcons name="attachment" size={DefaultTheme.icons.flatlistSize} />,
    },
  ]

  function Weather({ style }) {
    const [activeType, setActiveType] = useState(weatherTypeEnum.AUTOMATIC)
    const [loadingWeather, setLoadingWeather] = useState(false)

    async function handleGetLocation() {
      setTitle("Opravdu chcete opět vyžádat souřadnice podle vaší aktuální polohy?")
      setStatus(statusEnum.PROGRESS_DECIDE_SIMPLE)
      navigation.navigate("StatusScreen")
      setOnConfirm(() => async () => {
        try {
          setStatus(statusEnum.PROGRESS_UNDETERMINED)
          const { coords } = await getLocation()
          console.debug("NEW COORDS", coords)
          setLocation({ ...location, latitude: coords.latitude, longitude: coords.longitude })
          navigation.navigate("DiaryEntryDetail", route.params)
        } catch (error) {
          console.error(error)
          setStatus(statusEnum.ERROR)
          setTitle(error.title || "")
          setMessage(error.message)
          navigation.navigate("StatusScreen")
        }
      })
    }

    async function handleGetWeather() {
      // conditions - string
      // temp - number celsius
      // windspeed - number km/h
      // humidity - number percentage
      // precip - number mm
      setLoadingWeather(true)
      const { data, hours } = await getWeather(weather, location, firebaseConfig)
      console.debug("DATA", data)
      const filteredHours = hours.map(({ datetime, conditions, temp, windspeed, humidity, precip, icon }) => ({
        datetime,
        conditions,
        temp,
        windspeed,
        humidity,
        precip,
        icon,
      }))
      setWeather({
        ...weather,
        hours: filteredHours,
        updated_at: new Date(),
        latitude: data.latitude,
        longitude: data.longitude,
      })
      setLoadingWeather(false)
    }

    return (
      <View style={style}>
        <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
          {Object.keys(weatherTypeEnum).map((type) => (
            <TouchableOpacity
              key={type}
              onPress={() => setActiveType(type)}
              style={{
                padding: 10,
                flex: 1,
                alignItems: "center",
                backgroundColor: type === activeType ? DefaultTheme.colors.primary : DefaultTheme.colors.background,
              }}
            >
              <Text style={type === activeType && { color: DefaultTheme.colors.white }}>{weatherTypeLabels[type]}</Text>
            </TouchableOpacity>
          ))}
        </View>
        {activeType === weatherTypeEnum.AUTOMATIC && (
          <View style={{ paddingTop: 10 }}>
            <AddressAutocomplete
              disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
              address={location?.street}
              onAddressChange={(value) => setLocation({ ...location, street: value })}
              onAddressPick={(address, place) => {
                setLocation({
                  ...location,
                  street: address,
                  latitude: place.geometry.location.lat,
                  longitude: place.geometry.location.lng,
                })
              }}
            />
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View style={{ flex: 1, flexDirection: Platform.OS === "web" ? "row" : "column" }}>
                <TextInput
                  disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                  error={!Validation.isCoordinate(location.latitude)}
                  style={[DefaultTheme.input, { flex: 1 }]}
                  value={String(location.latitude)}
                  keyboardType="numeric"
                  label={Validation.isCoordinate(location.latitude) ? "Zeměpisná šířka" : "Zadejte zeměpisnou šířku"}
                  onChangeText={(value) => setLocation({ ...location, latitude: formatDecimalNumber(value) })}
                />
                <TextInput
                  disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                  error={!Validation.isCoordinate(location.longitude)}
                  style={[DefaultTheme.input, { flex: 1 }]}
                  value={String(location.longitude)}
                  keyboardType="numeric"
                  label={Validation.isCoordinate(location.longitude) ? "Zeměpisná délka" : "Zadejte zeměpisnou délku"}
                  onChangeText={(value) => setLocation({ ...location, longitude: formatDecimalNumber(value) })}
                />
              </View>
              <PrimButton
                disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                icon={<MaterialIcons name="my-location" size={18} color={DefaultTheme.colors.white} />}
                height={40}
                onPress={handleGetLocation}
                width={40}
              />
            </View>
          </View>
        )}
        {activeType === weatherTypeEnum.AUTOMATIC && (
          <View style={{ alignItems: "center" }}>
            <View style={{ minWidth: 200 }}>
              <AppDatePicker
                extra={2}
                disabled={
                  !(Validation.isCoordinate(location.latitude) && Validation.isCoordinate(location.longitude)) ||
                  isOnlyInvestor(currentUser) ||
                  isEntryClosed ||
                  isProjectClosed
                }
                label="Datum počasí"
                onConfirm={(date) =>
                  setWeather({
                    ...weather,
                    date: getDateSimpleString(date),
                  })
                }
                value={new Date(weather.date)}
                webLabel={["Den", "Měsíc", "Rok"]}
              />
            </View>
            <PrimButton
              disabled={
                !(Validation.isCoordinate(location.latitude) && Validation.isCoordinate(location.longitude)) ||
                isOnlyInvestor(currentUser) ||
                isEntryClosed ||
                isProjectClosed
              }
              text="Načíst počasí"
              onPress={handleGetWeather}
              width={130}
              height={45}
            />
          </View>
        )}
        {activeType === weatherTypeEnum.AUTOMATIC && weather.updated_at && !loadingWeather && (
          <View style={{ padding: 10 }}>
            <ScrollView horizontal style={{ flexDirection: "row" }}>
              {weather.hours.every((h) => h.conditions) ? (
                weather.hours.map(({ datetime, conditions, temp, windspeed, humidity, precip, icon }) => (
                  <View key={datetime} style={{ flexDirection: "row", paddingRight: 10 }}>
                    <View style={{ padding: 10 }}>
                      <Text style={{ textAlign: "center", fontWeight: "bold" }}>{datetime.slice(0, -3)}</Text>
                      <Image source={WeatherIcons[icon]} style={{ height: 64, width: 64 }} />
                    </View>
                    <View style={{ padding: 10 }}>
                      <Text>{conditions}</Text>
                      <Text>Teplota: {temp} °C</Text>
                      <Text>Vítr: {windspeed} km/h</Text>
                      <Text>Vlhkost: {humidity} %</Text>
                      <Text>Srážky: {precip} mm</Text>
                    </View>
                  </View>
                ))
              ) : (
                <Text style={{ padding: 10 }}>Pro zvolené datum počasí načíst nelze.</Text>
              )}
            </ScrollView>
            <Text>Načteno: {toCurrentDateTime(weather.updated_at)}</Text>
            <Text>Zeměpisná šířka: {weather.latitude}</Text>
            <Text>Zeměpisná délka: {weather.longitude}</Text>
          </View>
        )}
        {loadingWeather && <ActivityIndicator color={DefaultTheme.colors.primary} />}
        {activeType === weatherTypeEnum.MANUAL && (
          <TextInput
            disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
            error={!weather.value}
            value={weather.value}
            label={weather.value ? "Počasí" : "Zadejte počasí"}
            style={DefaultTheme.input}
            onChangeText={(value) => setWeather({ ...weather, value })}
          />
        )}
      </View>
    )
  }

  function Users({ style }) {
    return (
      <View style={style}>
        {userItems.map(({ key, users_count = "", profession = "", supplier = "", uri_list = [] }) => (
          <View key={key} style={styles.listInnerItem}>
            <Text style={styles.innerItemTitle}>Osoby na staveništi {key}</Text>
            <View style={{ justifyContent: "space-between", flexShrink: 1 }}>
              <View style={{ flexDirection: Platform.OS === "web" ? "row" : "column" }}>
                <TextInput
                  disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                  error={!Validation.isNumberPositive(users_count)}
                  style={DefaultTheme.input}
                  value={String(users_count)}
                  keyboardType="numeric"
                  label={Validation.isNumberPositive(users_count) ? "Počet osob" : "Zadejte počet osob"}
                  onChangeText={(value) =>
                    setUserItems(userItems.map((item) => (item.key === key ? { ...item, users_count: value } : item)))
                  }
                />
                <TextInput
                  disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                  error={!profession}
                  style={DefaultTheme.input}
                  value={profession}
                  label={profession ? "Profese" : "Zadejte profesi"}
                  onChangeText={(value) =>
                    setUserItems(userItems.map((item) => (item.key === key ? { ...item, profession: value } : item)))
                  }
                />
              </View>
              <TextInput
                disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                error={!supplier}
                style={DefaultTheme.input}
                value={supplier}
                label={supplier ? "Dodavatel" : "Zadejte dodavatele"}
                onChangeText={(value) =>
                  setUserItems(userItems.map((item) => (item.key === key ? { ...item, supplier: value } : item)))
                }
              />
              <ScanList
                disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                uriList={uri_list}
                addScan={(newUri) =>
                  setUserItems(
                    userItems.map((item) => (item.key === key ? { ...item, uri_list: [...uri_list, newUri] } : item))
                  )
                }
                deleteScan={(deletedUri) => {
                  setUserItems(
                    userItems.map((item) =>
                      item.key === key ? { ...item, uri_list: uri_list.filter((uri) => uri !== deletedUri) } : item
                    )
                  )
                }}
              />
            </View>
            <DeleteButton
              disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
              onPress={() => handleDeleteItem(key, userItems, setUserItems)}
            />
          </View>
        ))}
      </View>
    )
  }

  function Mechanization({ style }) {
    const pickerItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    function handlePropertyPress(key) {
      navigation.navigate("ProjectProperty", {
        viewType: viewTypeEnum.DIARY,
        project,
        onPress: (propertyId) => {
          setMechanizationItems(
            mechanizationItems.map((item) => (item.key === key ? { ...item, property_id: propertyId } : item))
          ),
            navigation.goBack()
        },
      })
    }

    return (
      <View style={style}>
        {mechanizationItems.map(
          ({
            key,
            machines_count = "",
            duration = 1,
            type = "",
            description = "",
            uri_list = [],
            property_id = "",
          }) => (
            <View key={key} style={styles.listInnerItem}>
              <Text style={styles.innerItemTitle}>Mechanizace {key}</Text>
              {property_id ? (
                <View style={{ paddingVertical: 10 }}>
                  <FlatListPropertyItem
                    propId={property_id}
                    onPress={() => handlePropertyPress(key)}
                    disabled={isOnlyInvestor(currentUser)}
                  />
                </View>
              ) : (
                <NavigationPicker
                  disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                  error
                  onPress={() => handlePropertyPress(key)}
                  label="Vyberte majetek"
                  style={{ width: "50%" }}
                />
              )}
              <View style={{ justifyContent: "space-between", flexShrink: 1 }}>
                <View style={{ flexDirection: Platform.OS === "web" ? "row" : "column" }}>
                  <TextInput
                    disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                    error={!Validation.isNumberPositive(machines_count)}
                    style={DefaultTheme.input}
                    keyboardType="numeric"
                    value={String(machines_count)}
                    label={Validation.isNumberPositive(machines_count) ? "Počet strojů" : "Zadejte počet strojů"}
                    onChangeText={(value) =>
                      setMechanizationItems(
                        mechanizationItems.map((item) => (item.key === key ? { ...item, machines_count: value } : item))
                      )
                    }
                  />
                  <AppPicker
                    disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                    descriptionLabel="Délka práce"
                    placeholder={{}}
                    items={pickerItems.map((num) => ({
                      value: num,
                      label: `${num} ${getHourDeclensionForm(num)}`,
                    }))}
                    value={duration}
                    onValueChange={(value) => {
                      setMechanizationItems(
                        mechanizationItems.map((item) =>
                          item.key === key ? { ...item, duration: Number(value) } : item
                        )
                      )
                    }}
                  />
                  <TextInput
                    disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                    error={!type}
                    style={DefaultTheme.input}
                    value={type}
                    label={type ? "Typ stroje" : "Zadejte typ stroje"}
                    onChangeText={(value) =>
                      setMechanizationItems(
                        mechanizationItems.map((item) => (item.key === key ? { ...item, type: value } : item))
                      )
                    }
                  />
                </View>
                <TextInput
                  disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                  error={!description}
                  style={DefaultTheme.input}
                  value={description}
                  label={description ? "Popis práce" : "Zadejte popis práce"}
                  onChangeText={(value) =>
                    setMechanizationItems(
                      mechanizationItems.map((item) => (item.key === key ? { ...item, description: value } : item))
                    )
                  }
                />
                <ScanList
                  disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                  uriList={uri_list}
                  addScan={(newUri) =>
                    setMechanizationItems(
                      mechanizationItems.map((item) =>
                        item.key === key ? { ...item, uri_list: [...uri_list, newUri] } : item
                      )
                    )
                  }
                  deleteScan={(deletedUri) => {
                    setMechanizationItems(
                      mechanizationItems.map((item) =>
                        item.key === key ? { ...item, uri_list: uri_list.filter((uri) => uri !== deletedUri) } : item
                      )
                    )
                  }}
                />
              </View>
              <DeleteButton
                disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                onPress={() => handleDeleteItem(key, mechanizationItems, setMechanizationItems)}
              />
            </View>
          )
        )}
      </View>
    )
  }

  function Material({ style }) {
    return (
      <View style={style}>
        {materialItems.map(({ key, description = "", state, uri_list = [] }) => (
          <View key={key} style={styles.listInnerItem}>
            <Text style={styles.innerItemTitle}>Materiál {key}</Text>
            <View style={{ justifyContent: "space-between" }}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TouchableOpacity
                  disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                  style={[{ backgroundColor: state === "OK" ? "green" : "red" }, styles.innerItemState]}
                  onPress={() =>
                    setMaterialItems(
                      materialItems.map((item) =>
                        item.key === key ? { ...item, state: item.state === "OK" ? "KO" : "OK" } : item
                      )
                    )
                  }
                >
                  <Text style={{ color: DefaultTheme.colors.white }}>{state}</Text>
                </TouchableOpacity>
                <TextInput
                  disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                  error={!description}
                  value={description}
                  label={description ? "Popis" : "Zadejte popis"}
                  style={[DefaultTheme.input, { width: "80%" }]}
                  onChangeText={(value) =>
                    setMaterialItems(
                      materialItems.map((item) => (item.key === key ? { ...item, description: value } : item))
                    )
                  }
                />
              </View>
              <ScanList
                disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                uriList={uri_list}
                addScan={(newUri) =>
                  setMaterialItems(
                    materialItems.map((item) =>
                      item.key === key ? { ...item, uri_list: [...uri_list, newUri] } : item
                    )
                  )
                }
                deleteScan={(deletedUri) => {
                  setMaterialItems(
                    materialItems.map((item) =>
                      item.key === key ? { ...item, uri_list: uri_list.filter((uri) => uri !== deletedUri) } : item
                    )
                  )
                }}
              />
            </View>
            <DeleteButton
              disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
              onPress={() => handleDeleteItem(key, materialItems, setMaterialItems)}
            />
          </View>
        ))}
      </View>
    )
  }

  function ConstructionWork({ style }) {
    const pickerItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    return (
      <View style={style}>
        {constructionWorkItems.map(({ state, title, key, duration = 1, description = "", uri_list = [] }) => (
          <View key={key} style={styles.listInnerItem}>
            <TextInput
              disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
              error={!title}
              value={title}
              label={title ? "Název" : "Zadejte název"}
              style={[DefaultTheme.input, { width: Platform.OS === "web" ? "30%" : "80%" }]}
              onChangeText={(value) =>
                setConstructionWorkItems(
                  constructionWorkItems.map((item) => (item.key === key ? { ...item, title: value } : item))
                )
              }
            />
            <View style={{ flexDirection: "column" }}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TouchableOpacity
                  disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                  style={[{ backgroundColor: state === "OK" ? "green" : "red" }, styles.innerItemState]}
                  onPress={() =>
                    setConstructionWorkItems(
                      constructionWorkItems.map((item) =>
                        item.key === key ? { ...item, state: item.state === "OK" ? "KO" : "OK" } : item
                      )
                    )
                  }
                >
                  <Text style={{ color: DefaultTheme.colors.white }}>{state}</Text>
                </TouchableOpacity>
                <AppPicker
                  descriptionLabel="Délka práce"
                  disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                  placeholder={{}}
                  items={pickerItems.map((num) => ({
                    value: num,
                    label: `${num} ${getHourDeclensionForm(num)}`,
                  }))}
                  value={duration}
                  onValueChange={(value) => {
                    setConstructionWorkItems(
                      constructionWorkItems.map((item) =>
                        item.key === key ? { ...item, duration: Number(value) } : item
                      )
                    )
                  }}
                />
              </View>
              <TextInput
                disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
                error={!description}
                value={description}
                label={description ? "Popis" : "Zadejte popis"}
                style={DefaultTheme.input}
                onChangeText={(value) =>
                  setConstructionWorkItems(
                    constructionWorkItems.map((item) => (item.key === key ? { ...item, description: value } : item))
                  )
                }
              />
            </View>
            <ScanList
              disabled={isEntryClosed || isProjectClosed}
              uriList={uri_list}
              addScan={(newUri) =>
                setConstructionWorkItems(
                  constructionWorkItems.map((item) =>
                    item.key === key ? { ...item, uri_list: [...uri_list, newUri] } : item
                  )
                )
              }
              deleteScan={(deletedUri) => {
                setConstructionWorkItems(
                  constructionWorkItems.map((item) =>
                    item.key === key ? { ...item, uri_list: uri_list.filter((uri) => uri !== deletedUri) } : item
                  )
                )
              }}
            />
            <DeleteButton
              disabled={isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed}
              onPress={() => handleDeleteItem(key, constructionWorkItems, setConstructionWorkItems)}
            />
          </View>
        ))}
      </View>
    )
  }

  function Record({ style }) {
    const pickerItems = ["TDI", "INV", "VCP", "MPC"]
    return (
      <View style={style}>
        {recordItems.map(({ state, key, type = "", description = "", uri_list = [] }) => (
          <View key={key} style={styles.listInnerItem}>
            <Text style={styles.innerItemTitle}>Záznam {key}</Text>
            <View style={{ justifyContent: "space-between", flexShrink: 1 }}>
              <View style={{ flexDirection: "column" }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TouchableOpacity
                    disabled={isEntryClosed || isProjectClosed}
                    style={[{ backgroundColor: state === "OK" ? "green" : "red" }, styles.innerItemState]}
                    onPress={() =>
                      setRecordItems(
                        recordItems.map((item) =>
                          item.key === key ? { ...item, state: item.state === "OK" ? "KO" : "OK" } : item
                        )
                      )
                    }
                  >
                    <Text style={{ color: DefaultTheme.colors.white }}>{state}</Text>
                  </TouchableOpacity>
                  <AppPicker
                    disabled={isEntryClosed || isProjectClosed}
                    descriptionLabel="Typ"
                    placeholder={{ label: "Vyberte typ záznamu", value: "" }}
                    items={pickerItems.map((value) => ({ value, label: value }))}
                    value={type}
                    onValueChange={(value) => {
                      setRecordItems(recordItems.map((item) => (item.key === key ? { ...item, type: value } : item)))
                    }}
                  />
                </View>
                <TextInput
                  multiline
                  disabled={isEntryClosed || isProjectClosed}
                  error={!description}
                  value={description}
                  label={description ? "Popis" : "Zadejte popis"}
                  style={DefaultTheme.input}
                  onChangeText={(value) =>
                    setRecordItems(
                      recordItems.map((item) => (item.key === key ? { ...item, description: value } : item))
                    )
                  }
                />
              </View>
              <ScanList
                disabled={isEntryClosed || isProjectClosed}
                hasLink
                uriList={uri_list}
                addScan={(newUri) =>
                  setRecordItems(
                    recordItems.map((item) => (item.key === key ? { ...item, uri_list: [...uri_list, newUri] } : item))
                  )
                }
                deleteScan={(deletedUri) => {
                  setRecordItems(
                    recordItems.map((item) =>
                      item.key === key ? { ...item, uri_list: uri_list.filter((uri) => uri !== deletedUri) } : item
                    )
                  )
                }}
              />
            </View>
            <DeleteButton
              disabled={isEntryClosed || isProjectClosed}
              onPress={() => handleDeleteItem(key, recordItems, setRecordItems)}
            />
          </View>
        ))}
      </View>
    )
  }

  function Attachment({ style }) {
    const pickerItems = ["TDI", "INV", "VCP", "MPC"]

    return (
      <View style={style}>
        {attachmentItems.map(({ key, type = "", description = "", uri_list = [] }) => (
          <View key={key} style={styles.listInnerItem}>
            <Text style={styles.innerItemTitle}>Příloha {key}</Text>
            <View style={{ justifyContent: "space-between", flexShrink: 1 }}>
              <View style={{ flexDirection: Platform.OS === "web" ? "row" : "column" }}>
                <View style={Platform.OS !== "web" && { width: "80%" }}>
                  <AppPicker
                    disabled={isEntryClosed || isProjectClosed}
                    descriptionLabel="Typ"
                    placeholder={{ label: "Vyberte typ přílohy", value: "" }}
                    items={pickerItems.map((value) => ({ value, label: value }))}
                    value={type}
                    onValueChange={(value) => {
                      setAttachmentItems(
                        attachmentItems.map((item) => (item.key === key ? { ...item, type: value } : item))
                      )
                    }}
                  />
                </View>
                <TextInput
                  disabled={isEntryClosed || isProjectClosed}
                  error={!description}
                  value={description}
                  label={description ? "Popis" : "Zadejte popis"}
                  style={DefaultTheme.input}
                  onChangeText={(value) =>
                    setAttachmentItems(
                      attachmentItems.map((item) => (item.key === key ? { ...item, description: value } : item))
                    )
                  }
                />
              </View>
              <ScanList
                disabled={isEntryClosed || isProjectClosed}
                hasLink
                uriList={uri_list}
                addScan={(newUri) =>
                  setAttachmentItems(
                    attachmentItems.map((item) =>
                      item.key === key ? { ...item, uri_list: [...uri_list, newUri] } : item
                    )
                  )
                }
                deleteScan={(deletedUri) => {
                  setAttachmentItems(
                    attachmentItems.map((item) =>
                      item.key === key ? { ...item, uri_list: uri_list.filter((uri) => uri !== deletedUri) } : item
                    )
                  )
                }}
              />
            </View>
            <DeleteButton
              disabled={isEntryClosed || isProjectClosed}
              onPress={() => handleDeleteItem(key, attachmentItems, setAttachmentItems)}
            />
          </View>
        ))}
      </View>
    )
  }

  async function handleSignatureSave(newSignature, role) {
    try {
      setTitle("Nahrávám podpis")
      setStatus(statusEnum.PROGRESS_DETERMINED)
      navigation.navigate("StatusScreen")

      const firebaseUri = await uploadFileOnFirebase(newSignature, updatePercentage, "signatures")
      setSignature({ ...signature, [role]: firebaseUri })

      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  function handlePartStateChange(partName, itemsLength) {
    if (!pressed[partName] && itemsLength === 0) {
      subItemAdd(partName)
    }
    setPressed({ ...pressed, [partName]: !pressed[partName] })
  }

  function subItemAdd(key) {
    if (key === "users") {
      setUserItems([...userItems, { key: userItems.length + 1 }])
    } else if (key === "mechanization") {
      setMechanizationItems([...mechanizationItems, { key: mechanizationItems.length + 1 }])
    } else if (key === "material") {
      setMaterialItems([...materialItems, { key: materialItems.length + 1, state: "OK" }])
    } else if (key === "constructionWork") {
      setConstructionWorkItems([
        ...constructionWorkItems,
        { key: constructionWorkItems.length + 1, title: `Práce ${constructionWorkItems.length + 1}`, state: "OK" },
      ])
    } else if (key === "record") {
      setRecordItems([...recordItems, { key: recordItems.length + 1, state: "OK" }])
    } else if (key === "attachment") {
      setAttachmentItems([...attachmentItems, { key: attachmentItems.length + 1 }])
    }
  }

  function handleDeleteItem(key, array, setArray) {
    // TODO we are not deleting images so far, because of simplicity
    // issue 1 - we would have to create new instances of firestorage images when creating new duplicity of diary entry
    // issue 2 - when firestorage uri deleted and go back without saving, the diary entry will include firestore uris which are already deleted from storage
    // ---------------------- KEEP THIS CODE ----------------------
    // if (array.find((item) => item.key === key)?.uri_list?.length > 0) {
    //   array
    //     .find((item) => item.key === key)
    //     .uri_list.forEach((uri) => (getNameFromFirebaseUri(uri) ? deleteFileFromFirebase(uri) : {}))
    // }
    // ---------------------- KEEP THIS CODE ----------------------

    let newKey = 1
    setArray(
      array
        .filter((item) => item.key !== key)
        .map((item) => {
          const newItem = { ...item, key: newKey }
          newKey += 1
          return newItem
        })
    )
  }

  async function handleSave() {
    try {
      setTitle("Ukládám denní zápis")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      let diaryEntry = { date_interval: diaryEntryInterval, weather, signature }

      if (userItems.length > 0) diaryEntry.users = userItems
      if (mechanizationItems.length > 0) diaryEntry.mechanization = mechanizationItems
      if (materialItems.length > 0) diaryEntry.material = materialItems
      if (constructionWorkItems.length > 0) diaryEntry.construction_work = constructionWorkItems
      if (recordItems.length > 0) diaryEntry.records = recordItems
      if (attachmentItems.length > 0) diaryEntry.attachments = attachmentItems

      if (signature.construction_lead) diaryEntry.state = itemStateEnum.CLOSED

      if (diaryEntryId) {
        if (userItems.length === 0) delete diaryEntry.users
        if (mechanizationItems.length === 0) delete diaryEntry.mechanization
        if (materialItems.length === 0) delete diaryEntry.material
        if (constructionWorkItems.length === 0) delete diaryEntry.construction_work
        if (recordItems.length === 0) delete diaryEntry.records
        if (attachmentItems.length === 0) delete diaryEntry.attachments

        await FSDiary.updateEntry(projectId, { ...diaryEntry, id: diaryEntryId, updated_by: currentUser.email })
      } else {
        await FSDiary.addEntry(projectId, diaryEntry)
      }
      await FSStoreContent.updateProject({ diary: { ...(project.diary || {}), address: location }, id: projectId })

      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  function isSaveDisabled() {
    return (
      // !(
      //   diaryEntryInterval?.from &&
      //   diaryEntryInterval?.to &&
      //   weather.value &&
      //   diaryEntryInterval.from <= diaryEntryInterval.to
      // ) ||
      // (userItems.length > 0 &&
      //   !userItems.every(
      //     (item) =>
      //       Validation.isNumberPositive(item.users_count) && item.users_count && item.profession && item.supplier
      //   )) ||
      // (mechanizationItems.length > 0 &&
      //   !mechanizationItems.every(
      //     (item) =>
      //       Validation.isNumberPositive(item.machines_count) &&
      //       item.machines_count &&
      //       item.type &&
      //       item.description &&
      //       item.property_id
      //   )) ||
      // (materialItems.length > 0 && !materialItems.every((item) => item.description)) ||
      // (constructionWorkItems.length > 0 && !constructionWorkItems.every((item) => item.description && item.title)) ||
      // (recordItems.length > 0 && !recordItems.every((item) => item.type && item.description)) ||
      // (attachmentItems.length > 0 && !attachmentItems.every((item) => item.type && item.description)) ||
      (userItems.length > 0 &&
        !userItems.every((item) => (item.users_count ? Validation.isNumberPositive(item.users_count) : true))) ||
      (mechanizationItems.length > 0 &&
        !mechanizationItems.every((item) =>
          item.machines_count ? Validation.isNumberPositive(item.machines_count) : true
        )) ||
      isProjectClosed ||
      isEntryClosed
    )
  }

  return (
    <Screen>
      <ScrollView contentContainerStyle={Platform.OS === "web" ? { flexShrink: 1 } : { flexGrow: 1 }}>
        <DateIntervalButtons
          dateInterval={diaryEntryInterval}
          disabled={isEntryClosed || isProjectClosed}
          setDateInterval={setDiaryEntryInterval}
        />
        {listItems.map(({ key, title, ListComponent, itemsLength, icon }) => (
          <React.Fragment key={key}>
            <View
              style={[
                (pressed[key] || isEntryClosed || isProjectClosed) && styles.pressedItem,
                { marginHorizontal: 10 },
              ]}
            >
              <TouchableWithoutFeedback
                disabled={isEntryClosed || isProjectClosed}
                onPress={() => handlePartStateChange(key, itemsLength)}
              >
                <View style={[styles.listItem, Platform.OS === "web" && { cursor: "pointer" }]}>
                  <View style={{ flexDirection: "row" }}>
                    {icon}
                    <Text style={styles.title}>{title}</Text>
                    {itemsLength > 0 && <ItemsCounter value={itemsLength} top={-5} right={-40} />}
                  </View>
                  <MaterialCommunityIcons name={`chevron-${pressed[key] ? "up" : "down"}`} size={18} color="black" />
                </View>
              </TouchableWithoutFeedback>
            </View>
            {ListComponent({
              style: [
                !(pressed[key] || isEntryClosed || isProjectClosed) && {
                  transform: [{ scale: 0 }],
                  flex: 0,
                  display: "none",
                },
                styles.component,
              ],
            })}
            <View
              style={[
                !pressed[key]
                  ? { transform: [{ scale: 0 }], flex: 0, display: "none" }
                  : { paddingVertical: 10, marginBottom: 10 },
                { alignItems: "center", overflow: "hidden", marginHorizontal: 10 },
              ]}
            >
              <View style={styles.addItemBackground} />
              {key !== "weather" && (
                <PrimButton
                  disabled={
                    key === "record" || key === "attachment"
                      ? isEntryClosed || isProjectClosed
                      : isOnlyInvestor(currentUser) || isEntryClosed || isProjectClosed
                  }
                  icon={<FontAwesome5 name="plus" size={18} color={DefaultTheme.colors.white} />}
                  onPress={() => subItemAdd(key)}
                  width={40}
                />
              )}
            </View>
          </React.Fragment>
        ))}
        <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 10 }}>
          <Signature
            disabled={isEntryClosed || isProjectClosed}
            title="Investor"
            onSave={(newSignature) => handleSignatureSave(newSignature, "investor")}
            uri={signature.investor}
          />
          <Signature
            disabled={isEntryClosed || isProjectClosed}
            title="Stavbyvedoucí"
            onSave={(newSignature) => handleSignatureSave(newSignature, "construction_lead")}
            uri={signature.construction_lead}
          />
        </View>
      </ScrollView>
      <NextButton text="Uložit" onPress={handleSave} disabled={isSaveDisabled()} />
    </Screen>
  )
}

const styles = StyleSheet.create({
  addItemBackground: {
    backgroundColor: DefaultTheme.colors.white,
    width: "100%",
    height: 100,
    position: "absolute",
    bottom: 0,
  },
  component: {
    marginHorizontal: 10,
    paddingHorizontal: 10,
    backgroundColor: DefaultTheme.colors.white,
  },
  innerItemState: {
    padding: 15,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    height: 50,
    width: 50,
  },
  innerItemTitle: {
    fontWeight: "bold",
  },
  listItem: {
    padding: 15,
    borderTopWidth: 1,
    borderTopColor: DefaultTheme.colors.borderPrimary,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listInnerItem: {
    paddingVertical: 15,
    paddingHorizontal: 5,
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.borderPrimary,
  },
  pressedItem: {
    backgroundColor: DefaultTheme.colors.white,
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.borderPrimary,
  },
  title: {
    paddingLeft: 10,
    fontWeight: "bold",
    fontSize: DefaultTheme.fonts.regular,
  },
})
