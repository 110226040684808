import React from "react"
import { View, TouchableOpacity, Text } from "react-native"
import DefaultTheme from "../themes/DefaultTheme"

export default function StateDependantButton({ text, state, onPress, style }) {
  return (
    <TouchableOpacity onPress={onPress} style={[style]}>
      <Text style={{ color: "white", textAlign: "center", fontWeight: "500", minWidth: 70 }}>{text}</Text>
    </TouchableOpacity>
  )
}
