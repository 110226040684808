import { Platform } from "react-native"
import uuid from "uuid"

import { firebase as fb } from "./config"

export async function uploadFileOnFirebase(
  localUri,
  updatePercentage,
  folderName,
  metadata = { contentDisposition: "attachment" }
) {
  try {
    const imageRef = fb.apps[0].storage().ref(`${folderName}/${uuid.v4()}`)

    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()

      xhr.onload = function () {
        resolve(xhr.response)
      }

      xhr.ontimeout = function (e) {
        // XMLHttpRequest timed out. Do something here.

        console.debug(e)
      }
      xhr.onerror = function (e) {
        console.debug(e)

        reject(new TypeError("Network request failed"))
      }
      xhr.responseType = "blob"
      xhr.open("GET", localUri, true)
      xhr.timeout = 1000 * 60
      xhr.send(null)
    })

    const uploadTask = imageRef.put(blob, metadata)

    return await new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => updatePercentage.current(snapshot),
        (error) => {
          console.error(error)
          if (Platform.OS !== "web") {
            blob.close()
          }
          reject(error)
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.debug("Image file save at:", downloadURL)
            resolve(downloadURL)
          })
          if (Platform.OS !== "web") {
            blob.close()
          }
        }
      )
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * @param {String} uri firebase full downloadable uri
 */
export async function deleteFileFromFirebase(uri) {
  try {
    const imageRef = fb.apps[0].storage().refFromURL(uri)
    await imageRef.delete()
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * @param {String} uri firebase full downloadable uri
 * @returns {Object} firebase file metadata
 */
export async function getFileMetadata(uri) {
  try {
    const imageRef = fb.apps[0].storage().refFromURL(uri)
    return await imageRef.getMetadata()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function getDocRef(collection) {
  try {
    return fb.apps[0].firestore().collection(collection).doc()
  } catch (error) {
    console.error(error)
    throw error
  }
}
