import React, { useEffect, useState } from "react"
import { StyleSheet, View, Text, Platform, Dimensions } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import NumberShortenFormatter from "../NumberShortenFormatter"
import { monthNameLabels } from "../../data/labels"
import { statisticsViewPeriodEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"
import { companyCostsEnum } from "../../data/enums"
import { PieChart } from "react-native-chart-kit"
import NumberFormatter from "../NumberFormatter"
import { loadTriviDocuments } from "../../utils/general"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/core"

export default function FlatListStatisticsOverviewItem({ stats, periodIndex, periodType }) {
  const navigation = useNavigation()
  const today = new Date()
  const { settings, accountingDocuments } = useData()
  const periodMonth = new Date(today.getFullYear(), today.getMonth() - periodIndex)
  let monthlyCosts = settings.company?.monthly_costs?.[companyCostsEnum.ALL] || 0

  if (periodType === statisticsViewPeriodEnum.QUARTERLY) {
    monthlyCosts = 3 * monthlyCosts
  }
  if (periodType === statisticsViewPeriodEnum.YEARLY) {
    monthlyCosts = 12 * monthlyCosts
  }

  let title = monthNameLabels[periodMonth.getMonth()] + " " + periodMonth.getFullYear()
  if (periodType === statisticsViewPeriodEnum.QUARTERLY) {
    title = "Q" + periodIndex.quarter + " " + periodIndex.yearQuarter
  } else if (periodType === statisticsViewPeriodEnum.YEARLY) {
    title = "Rok " + periodMonth.getFullYear()
  }

  const [actualOutcome, setActualOutcome] = useState(0)
  const [actualIncome, setActualIncome] = useState(0)
  const actualProfit = Number((actualIncome - actualOutcome - monthlyCosts).toFixed(2)) || 0
  const actualMargin = Number(((1 - (actualOutcome + monthlyCosts) / actualIncome) * 100).toFixed(2)) || 0

  const costsProfitSum =
    (actualProfit > 0 ? actualProfit : 0) +
    (monthlyCosts > 0 ? monthlyCosts : 0) +
    (actualOutcome > 0 ? actualOutcome : 0)
  const actualOutcomePercent = (actualOutcome / costsProfitSum) * 100
  const actualProfitPercent = (actualProfit / costsProfitSum) * 100
  const monthlyCostsPercent = (monthlyCosts / costsProfitSum) * 100

  const monthlyCostsMinusProfit = monthlyCosts + actualProfit < 0 ? 0 : monthlyCosts + actualProfit
  const actualOutcomeMinusProfit =
    monthlyCostsMinusProfit === 0
      ? actualOutcome + monthlyCosts + actualProfit < 0
        ? 0
        : actualOutcome + monthlyCosts + actualProfit
      : actualOutcome

  useEffect(() => {
    setActualIncome(stats.reduce((a, c) => (c.income || 0) + a, 0))
    setActualOutcome(stats.reduce((a, c) => (c.outcome || 0) + a, 0))
  }, [periodType, stats])

  const pieChartData =
    actualProfit > 0
      ? [
          {
            sumOfEntry: actualProfit,
            color: DefaultTheme.colors.success,
          },
          {
            sumOfEntry: Math.abs(actualOutcome),
            color: DefaultTheme.colors.danger,
          },
          {
            sumOfEntry: monthlyCosts,
            color: "purple",
          },
        ]
      : [
          {
            sumOfEntry: actualOutcomeMinusProfit,
            color: DefaultTheme.colors.danger,
          },
          {
            sumOfEntry: monthlyCostsMinusProfit,
            color: "purple",
          },
          {
            sumOfEntry: Math.abs(actualProfit),
            color: "red",
          },
        ]

  const pieChartConfig = {
    color: (opacity = 1) => `rgba(205, 205, 205, ${opacity})`,
  }

  return (
    <View
      style={[
        styles.card,
        { borderLeftColor: actualProfit > 0 ? DefaultTheme.colors.primary : DefaultTheme.colors.danger },
      ]}
    >
      <MaterialCommunityIcons
        name="chart-line"
        size={DefaultTheme.icons.flatlistSize}
        color={actualProfit > 0 ? DefaultTheme.colors.primary : DefaultTheme.colors.danger}
      />
      <View>
        <View style={{ flexDirection: "row", justifyContent: "space-between", paddingTop: 10, paddingBottom: 5 }}>
          <Text style={{ fontWeight: "bold" }}>{title}</Text>
          <Text>
            Marže:
            <Text style={actualMargin < 0 && { color: DefaultTheme.colors.danger }}>
              {actualMargin === -Infinity ? " -" : " " + actualMargin} %
            </Text>
          </Text>
        </View>

        <View style={{ flexDirection: Platform.OS === "web" ? "row" : "column-reverse" }}>
          <PieChart
            data={pieChartData}
            width={Dimensions.get("window").width / 2}
            height={180}
            chartConfig={pieChartConfig}
            accessor="sumOfEntry"
            backgroundColor="transparent"
            hasLegend={false}
            paddingLeft={Dimensions.get("window").width / 4 - 50}
          />
          <View style={{ alignContent: "center", justifyContent: "center" }}>
            <View style={{ flexDirection: "row", justifyContent: "space-between", paddingTop: 10 }}>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ paddingRight: 5, width: 85, textAlign: "right" }} />
                <View
                  style={{
                    backgroundColor: DefaultTheme.colors.primary,
                    width: 20,
                    height: 20,
                    borderRadius: 20 / 2,
                    marginBottom: 5,
                    marginRight: 5,
                  }}
                />
                <Text>Prodej</Text>
              </View>
              <Text style={{ paddingLeft: 20, textAlign: "right" }}>{NumberFormatter(actualIncome.toFixed(0))}</Text>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ paddingRight: 5, width: 85, textAlign: "right" }}>
                  {NumberFormatter(monthlyCostsPercent)} %
                </Text>
                <View
                  style={{
                    backgroundColor: "purple",
                    width: 20,
                    height: 20,
                    borderRadius: 20 / 2,
                    marginBottom: 5,
                    marginRight: 5,
                  }}
                />
                <Text>Měsíční náklady</Text>
              </View>
              <Text style={{ textAlign: "right", paddingLeft: 15 }}> {NumberFormatter(monthlyCosts.toFixed(0))}</Text>
            </View>

            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ paddingRight: 5, width: 85, textAlign: "right" }}>
                  {NumberFormatter(actualOutcomePercent)} %
                </Text>
                <View
                  style={{
                    backgroundColor: DefaultTheme.colors.danger,
                    width: 20,
                    height: 20,
                    borderRadius: 20 / 2,
                    marginBottom: 5,
                    marginRight: 5,
                  }}
                />
                <Text>Náklady</Text>
              </View>
              <Text style={{ textAlign: "right", paddingLeft: 15 }}>{NumberFormatter(actualOutcome.toFixed(0))}</Text>
            </View>

            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ paddingRight: 5, width: 85, textAlign: "right" }}>
                  {NumberFormatter(actualProfitPercent)} %
                </Text>
                <View
                  style={{
                    backgroundColor: actualProfit > 0 ? DefaultTheme.colors.success : "red",
                    width: 20,
                    height: 20,
                    borderRadius: 20 / 2,
                    marginBottom: 5,
                    marginRight: 5,
                  }}
                />
                <Text>Zisk</Text>
              </View>
              <Text style={{ textAlign: "right", paddingLeft: 15 }}>{NumberFormatter(actualProfit.toFixed(0))}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    minHeight: 100,
    alignItems: "center",
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 5,
    backgroundColor: DefaultTheme.colors.white,
    borderLeftWidth: 10,
    flexDirection: "row",
  },
  projectDescription: {
    alignSelf: Platform.OS === "web" ? "flex-end" : "flex-start",
  },
  graphLabel: {
    alignSelf: "flex-end",
    color: "white",
    padding: 5,
  },
  projectState: {
    color: DefaultTheme.colors.newItem,
  },
})
