import { configObj } from "../firebase/config"
import {
  attendanceBulkActionEnum,
  paymentTypeEnum,
  covidResultEnum,
  documentBulkActionEnum,
  holidayStateEnum,
  premiumActionTypeEnum,
  propertyStateEnum,
  propertyTypeEnum,
  taskBulkActionEnum,
  weatherTypeEnum,
  cashFlowTypeEnum,
} from "./enums"

export const roleLabels = {
  ADMIN: "Administrátor",
  EXECUTIVE: "Jednatel",
  PROJECT_MANAGER: "Projektový manažer",
  TECHNICAL_DIRECTOR: "Technický ředitel",
  CONSTRUCTION_LEAD: "Stavbyvedoucí",
  CONSTRUCTION_STAFF: "Připrava staveb",
  ACCOUNTANT: "Účetní",
  OFFICE_MANAGER: "Office manažer",
  INVESTOR: "Investor",
  VIEWER: "VIEWER",
}

export const paymentLabels = {
  CASH: "Hotovost",
  DEBIT_CARD: "Platební karta",
  BANK_TRANSFER: "Bankovní převod",
}

export const batchPaymentTypeLabels = {
  [paymentTypeEnum.ONLINE]: "Online",
  [paymentTypeEnum.BATCH]: "Batch",
  [paymentTypeEnum.CONTINUOUS]: "Continuous",
}

export const approveActiontypeLabels = {
  STATE_DOC_ACCEPTED: "Poslán do účetnictví",
  STATE_UNIT_TOTAL: "Položky na dokladu",
}

export const documentCategoryLabels = {
  MATERIAL: "Materiál",
  REZIE_A_SLUZBY: "Režie a služby",
  ZBOZI: "Zboží",
  OSTATNI: "Ostatní",
  SMISENE: "Smíšené",
  UBYTOVANI: "Ubytování",
}

export const propertyTypeLabels = {
  [propertyTypeEnum.VEHICLE]: "Vozidlo",
  [propertyTypeEnum.IT]: "IT",
  [propertyTypeEnum.PHONE]: "Telefon",
  [propertyTypeEnum.ACCESSORIES]: "Příslušenství",
  [propertyTypeEnum.TOOLS]: "Nářadí",
  [propertyTypeEnum.FURNITURE]: "Nábytek",
  [propertyTypeEnum.OTHER]: "Jiné",
  [propertyTypeEnum.NOT_STATED]: "Není určen",
}

export const propertyStateLabels = {
  [propertyStateEnum.ASSIGNED]: "Přiřazené",
  [propertyStateEnum.NOT_ASSIGNED]: "Nepřiřazené",
  [propertyStateEnum.DISCARDED]: "Vyřazené",
  [propertyStateEnum.SHARED]: "Sdílené",
}

export const logLabels = {
  OREA_RESORT_HORAL: "Kancelář - Orea resort Horal - kancelář",
  ZELENA_LHOTA: "Kancelář - Zelená Lhota - kancelář",
  CESKA_TREBOVA: "Kancelář - Česká Třebová - kancelář",
  DOLNI_BOJANOVICE: "Kancelář - Dolní Bojanovice - kancelář",
  PRAHA: "Kancelář - Praha - kancelář",
  SWEEPING_SIDEWALKS: "Zametání chodníků",
  SNOW_REMOVAL: "Odhrnování sněhu",
}

export const taskTitleLabels = {
  DOCUMENT_CREATED: "Nový doklad",
  DOCUMENT_UPDATED: "Upravený doklad",
}

export const taskBulkActionLabels = {
  [taskBulkActionEnum.DONE]: "Úkol je hotový",
}

export const attendanceBulkActionLabels = {
  [attendanceBulkActionEnum.WORK]: "Odpracovaných 8,5 h",
  [attendanceBulkActionEnum.HOLIDAY_CZ]: "Požádat o dovolenou v ČR",
  [attendanceBulkActionEnum.HOLIDAY_OTHER]: "Požádat o dovolenou mimo ČR",
  [attendanceBulkActionEnum.HOLIDAY_APPROVE]: "Schválit dovolenou",
  [attendanceBulkActionEnum.HOLIDAY_REJECT]: "Neschválit dovolenou",
}

export const documentBulkActionLabels = {
  [documentBulkActionEnum.DELETE]: "Smazat doklady",
}

export const monthNameLabels = {
  0: "Leden",
  1: "Únor",
  2: "Březen",
  3: "Duben",
  4: "Květen",
  5: "Červen",
  6: "Červenec",
  7: "Srpen",
  8: "Září",
  9: "Říjen",
  10: "Listopad",
  11: "Prosinec",
}

export const dayNameLabels = {
  1: "pondělí",
  2: "úterý",
  3: "středa",
  4: "čtvrtek",
  5: "pátek",
  6: "sobota",
  0: "neděle",
}

export const premiumLabels = {
  [premiumActionTypeEnum.REQUEST_PENDING]: "Žádost o vyplacení",
  [premiumActionTypeEnum.DECISION_ACCEPTED]: "Vyplacené",
  [premiumActionTypeEnum.DECISION_REJECTED]: "Nevyplacené",
  [premiumActionTypeEnum.DECISION_PENDING]: "Čekajíci",
}

export const holidayStateLabels = {
  [holidayStateEnum.ACCEPTED]: "schválena",
  [holidayStateEnum.REJECTED]: "neschválena",
  [holidayStateEnum.PENDING]: "čekající na schválení",
}

export const covidTestLabels = {
  [covidResultEnum.NEGATIVE]: "Negativní",
  [covidResultEnum.POSITIVE]: "Pozitivní",
  [covidResultEnum.INVALID]: "Neplatný",
}

export const weatherTypeLabels = {
  [weatherTypeEnum.AUTOMATIC]: "Automaticky",
  [weatherTypeEnum.MANUAL]: "Manuálně",
}

export const envLabels = {
  prod: "DASAR PR",
  "prod-1": "DASAR Invest",
  "prod-development": "DASAR Development",
  dev: "DEV",
  test: "TEST",
  uat: "UAT",
  emu: "EMULATORS",
}
