import { useNavigation } from "@react-navigation/core"
import React from "react"
import { Dimensions, Image, TouchableWithoutFeedback, View } from "react-native"

import { scanTypeEnum } from "../../data/enums"
import CloseButton from "../CloseButton"

export default function FlatListScanItem({ uriPreview, uriList, setLocalUriList, viewType }) {
  const navigation = useNavigation()
  const windowWidth = Dimensions.get("window").width

  return (
    <TouchableWithoutFeedback
      onPress={() =>
        navigation.push("VerifyScan", {
          uriPreview,
          uriList,
          type: scanTypeEnum.IMAGE,
          hideNextButton: true,
          viewType,
        })
      }
    >
      <View
        style={{
          marginVertical: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={{ uri: uriPreview }}
          style={{ width: windowWidth / 2.5, height: windowWidth / 2 }}
          resizeMode="contain"
        />
        <CloseButton onPress={() => setLocalUriList(uriList.filter((u) => u !== uriPreview))} top={-5} />
      </View>
    </TouchableWithoutFeedback>
  )
}
