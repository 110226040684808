import React from "react"
import { View, StyleSheet, Text, ScrollView } from "react-native"
import QRCode from "react-native-qrcode-svg"
import Screen from "../components/Screen"
import Constants from "expo-constants"
import { environmentEnum } from "../data/enums"

const environment = Constants.manifest.extra.environment

export default function DownloadAndroid() {
  return (
    <Screen>
      <ScrollView contentContainerStyle={{ width: "100%", flexGrow: 1, height: "100%", flexDirection: "row" }}>
        <View style={{ alignItems: "center", flex: 1 }}>
          <Text style={{ fontSize: 48, fontWeight: "bold" }}>1. Krok</Text>
          <Text style={{ fontSize: 32, paddingBottom: 20, textAlign: "center" }}>
            Stáhněte si Expo Go - pomocnou aplikaci na testování
          </Text>
          <QRCode value="https://play.google.com/store/apps/details?id=host.exp.exponent" size={300} />
        </View>
        <View style={{ alignItems: "center", flex: 1 }}>
          <Text style={{ fontSize: 48, fontWeight: "bold" }}>2. Krok</Text>
          <Text style={{ fontSize: 32, paddingBottom: 20, textAlign: "center" }}>Otevřete si v ní DASAR!</Text>
          <QRCode
            value={
              environment === environmentEnum.test
                ? "exp://exp.host/@dasar/dasar"
                : environment === environmentEnum.prod
                ? "exp://exp.host/@dasar-prod/dasar"
                : "exp://exp.host/@cexbit-dev/dasar"
            }
            size={300}
          />
        </View>
      </ScrollView>
    </Screen>
  )
}

const styles = StyleSheet.create({})
