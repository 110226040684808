import React from "react"
import { FlatList, View, Text, StyleSheet, ScrollView } from "react-native"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import DefaultTheme from "../../themes/DefaultTheme"
import { toCurrentDate, toCurrentDateTime } from "../../utils/general"

export default function TriviDocNotesDetail({ navigation, route }) {
  const notes = route.params.notes

  const renderItem = ({ item }) => {
    const createdTime = new Date(item.createdTime)
    const updatedTime = new Date(item.updatedTime)
    return (
      <View style={styles.card}>
        <View style={{ flexDirection: "column", justifyContent: "space-between", height: "100%", flex: 2, padding: 5 }}>
          <View style={{ flexDirection: "row" }}>
            <Text style={{ fontWeight: "bold" }}>{"Autor: "}</Text>
            <Text>{item.authorName}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={{ fontWeight: "bold" }}>{"Datum vytvoření: "}</Text>
            <Text>{toCurrentDate(createdTime) + " " + toCurrentDateTime(createdTime)}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={{ fontWeight: "bold" }}>{"Datum úpravy: "}</Text>
            <Text>{toCurrentDate(updatedTime) + " " + toCurrentDateTime(updatedTime)} </Text>
          </View>
        </View>
        <View style={{ height: "100%", flex: 3, padding: 5 }}>
          <Text style={{ fontWeight: "bold" }}>Text poznámky</Text>
          <ScrollView style={{ paddingVertical: 5 }}>
            <Text>{item.text}</Text>
          </ScrollView>
        </View>
      </View>
    )
  }

  return <FlatList renderItem={renderItem} ItemSeparatorComponent={FlatListSeparator} data={notes} />
}
const styles = StyleSheet.create({
  card: {
    height: 100,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    paddingRight: 10,
    flexDirection: "row",
    backgroundColor: DefaultTheme.colors.white,
    borderColor: DefaultTheme.colors.primary,
    borderLeftWidth: 10,
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
  name: {
    paddingLeft: 10,
    marginRight: "auto",
  },
  monthTitle: {
    paddingLeft: 10,
  },
})
