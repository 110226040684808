import React, {useState, useCallback, useEffect} from "react"
import {
  Text,
  View,
  StyleSheet,
  ScrollView,
  Image,
  TouchableOpacity,
  Dimensions,
  Platform,
  SafeAreaView,
} from "react-native"
import * as fb from "../../firebase/config"
import { TextInput } from "react-native-paper"
import Validation from "../../utils/validation"
import NextButton from "../../components/NextButton"
import DefaultTheme from "../../themes/DefaultTheme"
import Screen from "../../components/Screen"
import { useStatus } from "../../providers/StatusProvider"
import { environmentEnum, statusEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"
import Constants from "expo-constants"
import {loadItem, saveItem} from "../../actions/asyncLocalStorage"
import { envLabels } from "../../data/labels"
import { firebase } from "../../firebase/config"

export default function Login({ navigation, route }) {
  const { currentUser, firebaseConfig, setFirebaseConfig, backendDeployment } = useData()
  const [email, setEmail] = useState(currentUser.email)
  const [password, setPassword] = useState("")
  const { setTitle, setStatus, setMessage, setToScreen } = useStatus()
  const width = Dimensions.get("screen").width
  const [clickCount, setClickCount] = useState(0)
  const [storage, setStorage] = useState("")

  const handlePasswordForgotten = () => {
    navigation.navigate("ForgottenPassword")
  }
  const handleRegistration = () => {
    navigation.navigate("Registration")
  }

  const login = async () => {
    //if (maintenance !== null && !maintenance.ongoing) {
    try {
      if (Validation.isEmailValid(email)) {
        console.debug("firebase config: ", firebaseConfig)
        console.debug("firebase: ", firebase)
        console.debug("email: ",email)
        console.debug("password: ",password)
        if (firebaseConfig) {
          const result = await firebase.apps[0].auth().signInWithEmailAndPassword(email, password)
          if (result) {
            console.debug("login:" + JSON.stringify(result))
            await saveItem("DEFAULT_APP", firebaseConfig.toString())
            await saveItem("LOGIN_CREDENTIALS", JSON.stringify({ email, password }))
          }
        }
      }
    } catch (error) {
      console.debug("Error logging in: ", error)
      setTitle("Přihlášení se nepodařilo")
      setMessage(
        error.code === "auth/user-disabled"
          ? "Váš účet byl zablokován administrátorem"
          : "Heslo je neplatné nebo uživatel s tímto heslem neexistuje"
      )
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
    //}
  }

  const handleClick = () => {
    let clicks = clickCount
    clicks++
    setClickCount(clicks)
  }

  const handleFirebaseSwitch = async (configOption) => {
    //TODO add config for secondary prod app
    if (configOption !== firebaseConfig) {
      console.debug(firebase.apps[0], configOption)
      // await fb.firebase.apps[0].delete()
      // if (Constants.manifest.extra.environment === "prod" || Constants.manifest.extra.environment === "prod-1") {
      //   fb.firebase.initializeApp(
      //     configOption === "0" ? fb.firebaseConfigProd : fb.firebaseConfigProdInvest,
      //     configOption.toString()
      //   )
      // } else if (Constants.manifest.extra.environment === "dev") {
      //   fb.firebase.initializeApp(
      //     configOption === "0" ? fb.firebaseConfigDev : fb.firebaseConfigTest,
      //     configOption.toString()
      //   )
      // } else if (Constants.manifest.extra.environment === "uat") {
      //   fb.firebase.initializeApp(
      //     configOption === "0" ? fb.firebaseConfigUat : fb.firebaseConfigDev,
      //     configOption.toString()
      //   )
      // } else {
      //   fb.firebase.initializeApp(fb.firebaseConfigEmu, configOption.toString())
      // }

      setFirebaseConfig(configOption)
    }
    await loadCreds()
  }

  const FirebaseConfigSwitcher = () => {
    return (
      <View style={{ alignItems: "center" }}>
        <View
          style={{
            flexDirection: "row",
            width: Platform.OS === "web" ? 400 : width,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {Object.keys(fb.configObj)
            .filter((_, index) => (clickCount < 5 ? index < 3 : true))
            .map((el) => {
              return (
                <TouchableOpacity
                  key={el.toString()}
                  style={[
                    styles.switcherButton,
                    {
                      backgroundColor:
                        firebaseConfig === el ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled,
                    },
                  ]}
                  onPress={() => handleFirebaseSwitch(el)}
                >
                  <Text style={styles.switcherText}>{envLabels[el]}</Text>
                </TouchableOpacity>
              )
            })}
        </View>
      </View>
    )
  }

  const loadCreds = async () => {
    const credsObject = await loadItem("LOGIN_CREDENTIALS")
    const creds = JSON.parse(credsObject)
    setEmail(creds.email)
    setPassword(creds.password)
  }

  useEffect(()=> {
    loadCreds()
  },[])

  return (
    <Screen>
      <SafeAreaView
        style={{
          borderBottomColor: DefaultTheme.colors.primary,
          borderBottomWidth: 2,
          padding: 5,
          flexDirection: "row",
        }}
      >
        <Text>{`v${Constants.manifest.version}`}</Text>
        <Text
          style={{
            color:
              Constants.manifest.extra.commitHash?.substring(0, 8) === backendDeployment?.substring(0, 8)
                ? DefaultTheme.colors.primary
                : DefaultTheme.colors.danger,
          }}
        >
          {
          Constants.manifest.extra.commitHash?.substring(0, 8) === backendDeployment?.substring(0, 8)
            ? ` - ${Constants.manifest.extra.commitHash?.substring(0, 8)}`
            : ` - ${Constants.manifest.extra.commitHash?.substring(0, 8)}/${backendDeployment?.substring(0, 8)} `}
        </Text>
        <Text>{` |${Constants.manifest.extra.environment}|`}</Text>
        <TouchableOpacity onPress={handleClick}>
          <Text>{` |${fb.configNames[firebaseConfig]}|`}</Text>
        </TouchableOpacity>
      </SafeAreaView>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <FirebaseConfigSwitcher />
        <Image
          style={styles.logo}
          source={
            firebaseConfig === "prod" ? require("../../assets/dasar_logo.png") :
            firebaseConfig === "prod-1" ? require("../../assets/dasar_invest_logo.png") :
            firebaseConfig === "prod-development" ? require("../../assets/dasar_development_logo.png"):
            require("../../assets/dasar_logo.png")
          }
        />
        <TextInput
          error={!Validation.shouldWarningEmail(email)}
          style={DefaultTheme.input}
          label={Validation.shouldWarningEmail(email) ? "Email" : "Vyplňte prosím validní email"}
          placeholderTextColor={DefaultTheme.colors.placeholder}
          placeholder="Váš email"
          onChangeText={(value) => setEmail(value)}
          value={email}
          autoCapitalize="none"
        />
        <TextInput
          style={DefaultTheme.input}
          placeholderTextColor={DefaultTheme.colors.placeholder}
          secureTextEntry
          placeholder="Vaše heslo"
          label="Heslo"
          onChangeText={(text) => setPassword(text)}
          value={password}
          autoCapitalize="none"
        />
        <View style={styles.footerView}>
          <Text style={styles.footerText}>
            Zapomenuté heslo?&nbsp;
            <Text onPress={handlePasswordForgotten} style={DefaultTheme.link}>
              Obnovit heslo
            </Text>
          </Text>
          <Text style={styles.footerText}>
            Ještě nemáte účet?&nbsp;
            <Text onPress={handleRegistration} style={DefaultTheme.link}>
              Zaregistrovat se
            </Text>
          </Text>
          {/*<Text> storage: {storage}</Text>*/}
        </View>
      </ScrollView>
      <NextButton disabled={!(Validation.isEmailValid(email) && password)} text="Přihlásit se" onPress={login} />
    </Screen>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    justifyContent: "center",
  },
  logo: {
    width: "100%",
    height: 120,
    resizeMode: "contain",
    marginVertical: DefaultTheme.padding.m,
  },
  footerText: {
    fontSize: DefaultTheme.fonts.regular,
    color: DefaultTheme.colors.black,
  },
  footerView: {
    alignItems: "center",
    marginVertical: DefaultTheme.margin.s,
  },
  switcherButton: {
    width: 150,
    height: 60,
    margin: 5,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  switcherText: {
    color: "white",
    fontWeight: "bold",
  },
})
