import React from "react"
import { useNavigation } from "@react-navigation/native"
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { FontAwesome5 } from "@expo/vector-icons"

import DefaultTheme from "../themes/DefaultTheme"

export default function Signature({ title, onSave, uri, disabled = false }) {
  const navigation = useNavigation()

  return (
    <View>
      <TouchableOpacity
        disabled={disabled}
        onPress={() =>
          navigation.navigate("SignatureScreen", {
            onSave,
            title,
          })
        }
        style={styles.signatureWrapper}
      >
        {uri ? (
          <Image source={{ uri }} style={styles.signatureWrapper} />
        ) : (
          <Text style={{ textAlign: "center" }}>{title}</Text>
        )}
      </TouchableOpacity>

      <FontAwesome5
        style={{ fontSize: DefaultTheme.fonts.small, position: "absolute", top: 15, right: 5 }}
        name="signature"
        size={24}
        color={DefaultTheme.colors.primary}
      />
      {!!uri && (
        <Text
          style={[
            {
              fontSize: DefaultTheme.fonts.small,
              position: "absolute",
              top: 10,
              left: -5,
            },
            DefaultTheme.hint,
          ]}
        >
          {title}
        </Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  signatureWrapper: {
    marginVertical: 10,
    width: 150,
    height: 75,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: DefaultTheme.colors.borderPrimary,
    justifyContent: "center",
    alignItems: "center",
  },
})
