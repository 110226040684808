import React, { useEffect, useState } from "react"
import { TouchableOpacity, StyleSheet, View, Text } from "react-native"
import { useData } from "../../providers/DataProvider"
import { useStatus } from "../../providers/StatusProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import { MaterialCommunityIcons, FontAwesome, FontAwesome5 } from "@expo/vector-icons"
import PrimButton from "../PrimButton"
import { approveEnum, actionEnum, premiumActionTypeEnum, projectStateEnum, statusEnum } from "../../data/enums"
import { getUserName, toCurrentDateTime } from "../../utils/general"
import { STATE_VIEW_PARAMS_ITEM, STATE_VIEW_PARAMS_PREMIUM } from "../../data/params"
import { premiumLabels } from "../../data/labels"
import CurrencyFormatter from "../CurrencyFormatter"

export default function FlatListPremiumUserProjectsItem({ project, navigation, user, disabled = false, task }) {
  const { projects, currentUser, users, premiums } = useData()
  const { setTitle, setAction, setStatus, setMessage } = useStatus()
  const [userPremium, setUserPremium] = useState(0)
  const [lastPremiumAction, setLastPremiumAction] = useState({})
  const [userPremiumActions, setUserPremiumActions] = useState({})

  const projectUser = project.users.find((u) => u.email === user.email) || {}
  const premiumPercentage = projectUser.premium || 0

  useEffect(() => {
    const userPremiums = premiums.find((p) => p.email === user.email)
    if (userPremiums?.[project.id]?.requesting_items) {
      setUserPremiumActions(userPremiums)

      setLastPremiumAction(
        userPremiums[project.id]?.requesting_items
          .map((item) => ({
            ...item,
            created_at: item.created_at.toDate(),
          }))
          .sort((a, b) => b.created_at - a.created_at)[0]
      )
    }

    const draftOverProfit = (project.draft_income || 0) - (project.draft_outcome || 0)
    const actualOverProfit =
      (project.statistics?.income || 0) + (project.statistics?.income_extra || 0) - (project.statistics?.outcome || 0)

    setUserPremium((actualOverProfit - draftOverProfit) * premiumPercentage)
  }, [projects, project, premiums])

  function getLastAction() {
    return `${getUserName(users, lastPremiumAction.created_by)} ${toCurrentDateTime(lastPremiumAction.created_at)}`
  }

  console.debug("REQ ITEMS", userPremiumActions[project.id])
  function handleRequestPremium() {
    if (userPremiumActions[project.id]?.requesting_items) {
      userPremiumActions[project.id].requesting_items.push({
        action_type: premiumActionTypeEnum.REQUEST,
        created_at: new Date(),
        created_by: currentUser.email,
      })
    } else {
      userPremiumActions[project.id] = {
        requesting_items: [
          {
            action_type: premiumActionTypeEnum.REQUEST,
            created_at: new Date(),
            created_by: currentUser.email,
          },
        ],
      }
    }

    console.debug("USER PREMS SAVE", userPremiumActions)

    setTitle(`Opravdu chcete vyžádat prémie z projektu ${project.id}?`)
    setStatus(statusEnum.PROGRESS_DECIDE)
    setAction({
      type: actionEnum.PREMIUM_REQUEST,
      params: {
        toScreen: "PremiumUserProjects",
        user,
        userPremiumActions,
      },
    })
    navigation.navigate("StatusScreen")
  }

  function getPremiumState() {
    if (lastPremiumAction.action_type === premiumActionTypeEnum.REQUEST) {
      return approveEnum.PENDING
    } else if (lastPremiumAction.action_type === premiumActionTypeEnum.DECISION) {
      return lastPremiumAction.action_value
    }
  }

  return (
    <TouchableOpacity
      onPress={() => navigation.navigate("PremiumProjectDetail", { project, user, userPremium, task })}
      style={[
        styles.card,
        {
          borderLeftColor: STATE_VIEW_PARAMS_ITEM[project.state === projectStateEnum.CLOSED].color,
        },
      ]}
    >
      <View style={{ justifyContent: "center", alignItems: "center", paddingRight: 15 }}>
        <FontAwesome5
          name="drafting-compass"
          size={DefaultTheme.icons.flatlistSize}
          color={STATE_VIEW_PARAMS_ITEM[project.state === projectStateEnum.CLOSED].color}
        />
        {project.state === projectStateEnum.CLOSED && (
          <FontAwesome name="lock" color={DefaultTheme.colors.disabled} size={DefaultTheme.icons.flatlistSize} />
        )}
      </View>
      <View style={{ flex: 1, justifyContent: "center", paddingHorizontal: 10 }}>
        <Text style={{ paddingBottom: 10 }}>
          [{project.id} - {project.name}]
        </Text>
      </View>
      <View style={{ flex: 1.5, justifyContent: "center", alignItems: "flex-start", paddingRigth: 10 }}>
        <Text>Aktuální prémie z nad zisku</Text>
        <Text style={{ fontWeight: "bold" }}>{CurrencyFormatter(userPremium, "CZK", 0, true)}</Text>
        {lastPremiumAction.created_at && (
          <View>
            <Text style={DefaultTheme.hint}>Poslední akce:</Text>
            <Text>{getLastAction()}</Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text style={{ fontWeight: "bold", paddingRight: 5 }}>
                {premiumLabels[`${lastPremiumAction.action_type}_${getPremiumState()}`]}
              </Text>
              {lastPremiumAction && (
                <FontAwesome
                  {...STATE_VIEW_PARAMS_PREMIUM[getPremiumState()]}
                  size={DefaultTheme.icons.flatlistSize}
                  style={{ alignSelf: "center" }}
                />
              )}
            </View>
          </View>
        )}
      </View>
      <View style={{ flex: 0.5, justifyContent: "center", alignItems: "center" }}>
        {user.email === currentUser.email && (
          <PrimButton
            onPress={handleRequestPremium}
            disabled={
              project.state !== projectStateEnum.CLOSED ||
              premiumActionTypeEnum.DECISION_ACCEPTED ===
                premiumActionTypeEnum[`${lastPremiumAction.action_type}_${getPremiumState()}`]
            }
            width={48}
            icon={<MaterialCommunityIcons name="cash-refund" size={24} color={DefaultTheme.colors.white} />}
          />
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    minHeight: 100,
    justifyContent: "center",
    width: "100%",
    paddingLeft: 15,
    paddingRight: 10,
    borderLeftWidth: 10,
    flexDirection: "row",
    backgroundColor: DefaultTheme.colors.white,
    paddingVertical: 5,
  },
  projectState: {
    position: "absolute",
    top: 5,
    fontStyle: "italic",
    color: DefaultTheme.colors.newItem,
  },
})
