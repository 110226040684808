const icons = {
  "clear-day": require("../assets/weather-icons/clear-day.png"),
  "clear-night": require("../assets/weather-icons/clear-night.png"),
  cloudy: require("../assets/weather-icons/cloudy.png"),
  fog: require("../assets/weather-icons/fog.png"),
  hail: require("../assets/weather-icons/hail.png"),
  "partly-cloudy-day": require("../assets/weather-icons/partly-cloudy-day.png"),
  "partly-cloudy-night": require("../assets/weather-icons/partly-cloudy-night.png"),
  "rain-snow-showers-day": require("../assets/weather-icons/rain-snow-showers-day.png"),
  "rain-snow-showers-night": require("../assets/weather-icons/rain-snow-showers-night.png"),
  "rain-snow": require("../assets/weather-icons/rain-snow.png"),
  rain: require("../assets/weather-icons/rain.png"),
  "showers-day": require("../assets/weather-icons/showers-day.png"),
  "showers-night": require("../assets/weather-icons/showers-night.png"),
  sleet: require("../assets/weather-icons/sleet.png"),
  "snow-showers-day": require("../assets/weather-icons/snow-showers-day.png"),
  "snow-showers-night": require("../assets/weather-icons/snow-showers-night.png"),
  snow: require("../assets/weather-icons/snow.png"),
  "thunder-rain": require("../assets/weather-icons/thunder-rain.png"),
  "thunder-showers-day": require("../assets/weather-icons/thunder-showers-day.png"),
  "thunder-showers-night": require("../assets/weather-icons/thunder-showers-night.png"),
  thunder: require("../assets/weather-icons/thunder.png"),
  wind: require("../assets/weather-icons/wind.png"),
}

export default {
  "clear-day": icons["clear-day"],
  "clear-night": icons["clear-night"],
  cloudy: icons["cloudy"],
  fog: icons["fog"],
  hail: icons["hail"],
  "partly-cloudy-day": icons["partly-cloudy-day"],
  "partly-cloudy-night": icons["partly-cloudy-night"],
  "rain-snow-showers-day": icons["rain-snow-showers-day"],
  "rain-snow-showers-night": icons["rain-snow-showers-night"],
  "rain-snow": icons["rain-snow"],
  rain: icons["rain"],
  "showers-day": icons["showers-day"],
  "showers-night": icons["showers-night"],
  sleet: icons["sleet"],
  "snow-showers-day": icons["snow-showers-day"],
  "snow-showers-night": icons["snow-showers-night"],
  snow: icons["snow"],
  "thunder-rain": icons["thunder-rain"],
  "thunder-showers-day": icons["thunder-showers-day"],
  "thunder-showers-night": icons["thunder-showers-night"],
  thunder: icons["thunder"],
  wind: icons["wind"],
}
