import React, { useState } from "react"
import { TouchableOpacity } from "react-native"
import { Menu } from "react-native-paper"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import { useNavigation } from "@react-navigation/native"

export default function LogBookOverviewHeaderMenu() {
  const navigation = useNavigation()
  const [showMenu, setShowMenu] = useState(false)

  return (
    <Menu
      visible={showMenu}
      onDismiss={() => setShowMenu(false)}
      anchor={
        <TouchableOpacity onPress={() => setShowMenu(true)} style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.headerSecondary}
          />
        </TouchableOpacity>
      }
    >
      <Menu.Item
        style={{ direction: "ltr" }}
        onPress={() => {
          setShowMenu(false), navigation.navigate("LogBookExport")
        }}
        title="Export knihy jízd"
        icon={() => (
          <MaterialCommunityIcons
            name="file-export"
            size={DefaultTheme.icons.menuSize}
            color={DefaultTheme.colors.primary}
          />
        )}
      />
    </Menu>
  )
}
