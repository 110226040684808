import React, { useEffect, useState } from "react"
import { FlatList } from "react-native"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import FlatListCashDeskOverviewItem from "../../components/FlatLists/FlatListCashDeskOverviewItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import Screen from "../../components/Screen"
import { environmentEnum, userRoleEnum, userStateEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"
import Constants from "expo-constants"

export default function CashDeskOverview({ navigation }) {
  const { users } = useData()
  const [filteredUsers, setFilteredUsers] = useState([])

  useEffect(() => {
    const activeUsers = users.filter(
      (u) => !u.disabled && u.state !== userStateEnum.DELETED && !u.roles.includes(userRoleEnum.INVESTOR)
    )
    if (
      Constants.manifest.extra.environment === environmentEnum.dev ||
      Constants.manifest.extra.environment === environmentEnum.emu
    ) {
      setFilteredUsers(activeUsers)
    } else {
      setFilteredUsers(activeUsers.filter((u) => !u.roles.includes(userRoleEnum.ADMIN)))
    }
  }, [users])

  return (
    <Screen>
      {filteredUsers.length > 0 ? (
        <FlatList
          data={filteredUsers}
          renderItem={({ item }) => <FlatListCashDeskOverviewItem user={item} navigation={navigation} />}
          keyExtractor={(item) => item.email.toString()}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="Neexistují žádní uživatelé" />
      )}
    </Screen>
  )
}
