import { firebase } from "../firebase/config"
import {
  getAccountBalanceListUrl, getAccountBalanceUrl,
  getBatchPaymentPrepareDocsUrl,
  getBatchPaymentRequestUrl, getBatchPostUrl, getRbBatchPaymentPrepareDocsUrl,
  getTransactionHistoryUrl, getTransactionListUrl,
} from "../utils/backendUrls"

function formatDate(date) {
  const year = date.getFullYear(); // Gets the full year (e.g., 2024)
  const month = date.getMonth() + 1; // Gets the month (0-11, +1 to make it 1-12)
  const day = date.getDate(); // Gets the day of the month (1-31)

  // Pad the month and day with leading zeros if necessary
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  // Return the formatted date string
  return `${year}-${formattedMonth}-${formattedDay}`;
}

// Raiffeisen bank

export async function getAccountBalance(firebaseConfig) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()
    const url = getAccountBalanceUrl(firebaseConfig)

    const requestId = "123456789"

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        requestId: requestId,
      }),
    })

    if (response.status !== 200) {
      console.error("response", response.status)
    } else {
      console.debug("response", response.status)
      return await response.json()
    }
  } catch (error) {
    console.error(error)
    throw error
  }

}

export async function getTransactionList(firebaseConfig) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()
    const url = getTransactionListUrl(firebaseConfig)

    // Create a random ID using Math.random and current timestamp
    const requestId = "" + Math.random().toString(6).substr(4);
    console.log("requestId:" + requestId);

    const currentDate =  new Date();
    // Create a new date that is three months from the current date
    const threeMonthsFromNow = new Date(new Date().setMonth(new Date().getMonth() - 2));
    // Compare the dates
    const isNotLessThanThreeMonths = currentDate >= threeMonthsFromNow;

    console.log("currentDate:", currentDate.toISOString());
    console.log("Date Three Months from Now:", threeMonthsFromNow.toISOString());
    console.log("Is the adjusted date not less than three months from now?", isNotLessThanThreeMonths);

    const fromDate = formatDate(threeMonthsFromNow)
    const toDate = formatDate(currentDate)
    const page = 1

    const body = JSON.stringify({
      requestId: requestId,
      fromDate: fromDate,
      toDate: toDate,
      page: page
    });
    console.log("body:", body);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: body,
    })

    const responseJson = await response.json()
    console.log("responseJson", responseJson)

    if (response.status !== 200) {
      console.error("response", response.status)
    } else {
      console.debug("response  -", response.status)
      return responseJson
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}


export async function postBatch(firebaseConfig) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()
    const url = getBatchPostUrl(firebaseConfig)

    const requestId = "123456789"

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        requestId: requestId,
      }),
    })

    if (response.status !== 200) {
      console.error("response", response.status)
    } else {
      console.debug("response", response.status)
      return await response.json()
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}


// old kb banking

export async function createNewBatchPayment(accountingDocument, configId) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()

    const url = getBatchPaymentRequestUrl(configId)

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        accountingDocument,
      }),
    })

    if (response.status !== 200) {
      throw await response.json()
    }

    return await response.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getTransactionHistory(configId, fromDate, toDate) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()
    const url = getTransactionHistoryUrl(configId)
    const response = await fetch(`${url}?env=${configId}&fromDate=${fromDate}&toDate=${toDate}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })

    if (response.status !== 200) {
      throw await response.json()
    }

    return await response.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getAccountBalanceList(configId) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()

    const url = getAccountBalanceListUrl(configId)

    const response = await fetch(`${url}?env=${configId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })

    if (response.status !== 200) {
      throw await response.json()
    }

    return await response.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function batchPaymentRequest(id, configId) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()

    const url = getBatchPaymentRequestUrl(configId)

    const response = await fetch(`${url}?id=${id}&env=${configId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    console.debug("STATUS", response.status)

    if (response.status !== 200) {
      throw { status: response.status, statusText: response.statusText, ...(await response.json()) }
    }

    return await response.json()
  } catch (error) {
    console.error("Error fetching batchPaymentRequest cloud function uri: ", error)
    throw error
  }
}

export async function batchPaymentPrepareDocs(configId, dateInterval) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()

    const url = getBatchPaymentPrepareDocsUrl(configId)

    const response = await fetch(`${url}?env=${configId}&from=${dateInterval.from}&to=${dateInterval.to}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })

    if (response.status !== 200) {
      throw { status: response.status, statusText: response.statusText, ...(await response.json()) }
    }

    return await response.json()
  } catch (error) {
    console.error("Error fetching batchPaymentPrepareDocs cloud function uri: ", error)
    throw error
  }
}

export async function rbBatchPaymentPrepareDocs(configId, dateInterval) {
  try {
    const token = await firebase.apps[0].auth().currentUser.getIdToken()

    const url = getRbBatchPaymentPrepareDocsUrl(configId)

    const response = await fetch(`${url}?env=${configId}&from=${dateInterval.from}&to=${dateInterval.to}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })

    if (response.status !== 200) {
      throw { status: response.status, statusText: response.statusText, ...(await response.json()) }
    }

    return await response.json()
  } catch (error) {
    console.error("Error fetching batchPaymentPrepareDocs cloud function uri: ", error)
    throw error
  }
}
