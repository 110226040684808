import React, { useEffect, useState } from "react"
import { View, StyleSheet, Text, Image, SafeAreaView } from "react-native"
import { PieChart } from "react-native-chart-kit"
import { MaterialCommunityIcons, FontAwesome5, FontAwesome } from "@expo/vector-icons"

import DefaultTheme from "../../themes/DefaultTheme"
import { companyCostsEnum, paymentEnum, viewTypeEnum } from "../../data/enums"
import PieChartLegendItem from "../PieChartLegendItem"
import { isAdmin, isDateInCurrentMonth, isExecutive } from "../../utils/validation"
import { useData } from "../../providers/DataProvider"
import FSStoreContent from "../../firebase/FSStoreContent"

export default function DefaultDashboard() {
  const { currentUser, accountingDocuments, userTasks, cashDesks } = useData()
  const [cashDesk, setCashDesk] = useState(null)
  const [paidDocuments, setPaidDocuments] = useState([])
  const [lastTask, setLastTask] = useState(null)

  const today = new Date()
  const yearMonth = `${today.getFullYear()}_${today.getMonth()}`

  const residualCharge = cashDesk?.[yearMonth]?.charges?.residual_charge || 0
  const initialCharge = cashDesk?.[yearMonth]?.charges?.initial_charge || 0
  const charge = initialCharge + residualCharge
  const balance = charge - (getDocsPriceCashSum() || 0)

  useEffect(() => {
    const userCashDesk = cashDesks.find((cd) => cd.user === currentUser.email)
    setCashDesk(userCashDesk)
  }, [cashDesks])

  useEffect(() => {
    setPaidDocuments(
      accountingDocuments.filter(
        (document) =>
          document.created_by === currentUser.email &&
          document.payment !== paymentEnum.BANK_TRANSFER &&
          isDateInCurrentMonth(document.date)
      )
    )
  }, [accountingDocuments])

  useEffect(() => {
    let latestTask = null
    userTasks.forEach((t) => {
      if (t.created_at.seconds > lastTask?.created_at?.seconds || latestTask === null) latestTask = t
    })
    setLastTask(latestTask)
  }, [userTasks])

  function getDocsPriceCashSum() {
    return paidDocuments.reduce((a, c) => (c.payment === paymentEnum.CASH ? a + c.price : a), 0)
  }

  const pieChartConfig = {
    color: (opacity = 1) => `rgba(205, 205, 205, ${opacity})`,
  }

  const cashDeskPieChartData =
    balance > 0
      ? [
          {
            balance: balance,
            color: DefaultTheme.colors.success,
          },
          {
            balance: getDocsPriceCashSum(),
            color: DefaultTheme.colors.danger,
          },
        ]
      : [
          {
            balance: getDocsPriceCashSum(),
            color: DefaultTheme.colors.danger,
          },
          {
            balance: -balance,
            color: "red",
          },
        ]

  return (
    <View style={[styles.row, { flex: 1 }]}>
      <View style={[styles.chartWrapper, styles.wrapper]}>
        <Text style={styles.title}>Pokladna</Text>
        {charge > 0 ? (
          <PieChart
            data={cashDeskPieChartData}
            width={150}
            height={150}
            chartConfig={pieChartConfig}
            accessor="balance"
            backgroundColor="transparent"
            hasLegend={false}
            paddingLeft={40}
          />
        ) : (
          <View style={styles.emptyGraphWrapper}>
            <View style={styles.emptyGraph} />
          </View>
        )}
        <View style={{ justifyContent: "center", minHeight: 150 }}>
          <PieChartLegendItem
            viewType={viewTypeEnum.DASHBOARD}
            description="Dotace"
            value={charge}
            color={DefaultTheme.colors.primary}
          />
          <PieChartLegendItem
            viewType={viewTypeEnum.DASHBOARD}
            value={getDocsPriceCashSum()}
            color={DefaultTheme.colors.danger}
            icon={
              <MaterialCommunityIcons
                name="cash"
                color={DefaultTheme.colors.danger}
                size={DefaultTheme.icons.flatlistSize}
              />
            }
          />
          <PieChartLegendItem
            viewType={viewTypeEnum.DASHBOARD}
            description="Zůstatek"
            value={balance}
            color={balance > 0 ? DefaultTheme.colors.success : "red"}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  emptyGraph: {
    borderWidth: 5,
    borderColor: DefaultTheme.colors.primary,
    height: 120,
    width: 120,
    borderRadius: 75,
  },
  emptyGraphWrapper: {
    alignItems: "center",
    justifyContent: "center",
    height: 150,
    width: 150,
  },
  chartWrapper: {
    justifyContent: "center",
    backgroundColor: "white",
  },
  row: {
    flexDirection: "row",
  },
  title: {
    width: "100%",
    textAlign: "center",
    fontSize: DefaultTheme.fonts.medium,
  },
  wrapper: {
    flex: 1,
    padding: 10,
    alignItems: "center",
  },
})
