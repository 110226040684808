import React, { useEffect, useState } from "react"
import { Text, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native"
import { TextInput } from "react-native-paper"
import NextButton from "../../components/NextButton"
import { paymentLabels, documentCategoryLabels } from "../../data/labels"
import AppPicker from "../../components/AppPicker"
import Validation from "../../utils/validation"
import { useData } from "../../providers/DataProvider"
import Screen from "../../components/Screen"
import DefaultTheme from "../../themes/DefaultTheme"
import AppDatePicker from "../../components/AppDatePicker"
import { documentAccountingTypeEnum, documentCategoryEnum, documentViewTypeEnum, viewTypeEnum } from "../../data/enums"
import { formatDecimalNumber } from "../../utils/general"
import AppMultipleDataPicker from "../../components/AppMultipleDataPicker"
import PlusMinusButtons from "../../components/PlusMinusButtons"
import { CheckBox } from "react-native-elements"
import NavigationPicker from "../../components/NavigationPicker"

export default function AccountingDocumentInfo({ navigation, route }) {
  const { projects, currentUser, accountingDocuments } = useData()
  const [document, setDocument] = useState({
    name: "Doklad_" + Date.now(),
    price: "",
    project: "",
    supplier: "",
    category: "",
    payment: "",
    date: new Date(),
    type: route.params.type,
    uriList: route.params.uri ? [route.params.uri] : route.params.uriList,
    created_by: currentUser.email,
  })
  const [plusMinusValues, setPlusMinusValues] = useState(
    document?.accounting_info_internal?.direction === 1 ? [true, false] : [false, true] || []
  )
  const defaultPickedData = accountingDocuments
    .filter(
      (doc) =>
        doc.accounting_info_internal?.type === "ADVANCE" && doc.project === document.project && doc.id !== document.id
    )
    .map((doc) => {
      if (document.accounting_info_internal?.type === documentAccountingTypeEnum.INVOICE) {
        return {
          ...doc,
          isSelected: document.accounting_info_internal.advance_documents.includes(doc.id),
          // isSelected: document.accounting_info_internal.advance_documents.includes(doc.id) ? true : false, // TO REVIEW
        }
      } else return { ...doc, isSelected: false }
    })
  const [pickedData, setPickedData] = useState(defaultPickedData)
  const [isAdvance, setIsAdvance] = useState(
    document.accounting_info_internal?.type
      ? document?.accounting_info_internal?.type === documentAccountingTypeEnum.ADVANCE
        ? false
        : true
      : null
  )
  const [manualSupplier, setManualSupplier] = useState(false)

  console.debug("DOCUMENT INFO", document)
  console.debug("PARAMS", route.params)

  useEffect(() => {
    setDocument({ ...document, ...route.params })
  }, [route.params])

  const appPickerPaymentItems = Object.keys(paymentLabels).map((key) => {
    return { label: paymentLabels[key], value: key, key: key }
  })

  const appPickerProjectItems = projects.map((project) => {
    return { label: `[${project.id}]-[${project.name}]`, value: project.id, key: project.id }
  })

  const appPickerCategoryItems = Object.keys(documentCategoryLabels)
    .map((key) => {
      if (key !== documentCategoryEnum.UBYTOVANI) {
        return { label: documentCategoryLabels[key], value: key, key: key }
      }
    })
    .filter((key) => key)

  function changePropertyValue(propName, value) {
    setDocument({ ...document, [propName]: value })
  }

  function handleAdvanceCheck(value) {
    if (isAdvance === null) {
      if (value === 1) {
        setDocument({
          ...document,
          accounting_info_internal: { type: documentAccountingTypeEnum.INVOICE, advance_documents: [] },
        })
        setPickedData(defaultPickedData)
        setIsAdvance(true)
      } else {
        setDocument({
          ...document,
          accounting_info_internal: { type: documentAccountingTypeEnum.ADVANCE, direction: 1 },
        })
        // const tempObj = document
        // delete tempObj.accounting_info_internal
        // setDocument(tempObj)
        setPlusMinusValues(
          document?.accounting_info_internal
            ? document?.accounting_info_internal.direction === 0
              ? [false, true]
              : [true, false]
            : [false, false]
        )

        setIsAdvance(false)
      }
    } else if (isAdvance && value === 1) {
      setDocument({ ...document, accounting_info_internal: {} })

      setPlusMinusValues([])
      setIsAdvance(null)
    } else if (!isAdvance && value === 2) {
      setDocument({ ...document, accounting_info_internal: {} })
      setIsAdvance(null)
    } else {
      setPickedData(defaultPickedData)
      setPlusMinusValues([false, false])
      setDocument({
        ...document,
        accounting_info_internal: { type: documentAccountingTypeEnum.INVOICE, advance_documents: [] },
      })
      setIsAdvance(!isAdvance)
    }
  }

  function handleSupplierPick() {
    navigation.navigate("CompanyCatalogOverview", {
      onPickSupplier: (supplier) => {
        navigation.navigate("AccountingDocumentInfo", {
          ...route.params,
          supplier: supplier.name,
          supplier_id: supplier.id,
        })
      },
      viewType: viewTypeEnum.PICK,
    })
  }

  const isSaveDisabled = () => {
    if (document.locked) {
      if (document.accounting_info_internal && Object.keys(document.accounting_info_internal)?.length === 0) {
        return false
      } else {
        return (
          isAdvance === null || (isAdvance && document?.accounting_info_internal?.advance_documents?.length === 0)
          // (isAdvance !== null && !isAdvance && plusMinusValues[0] === plusMinusValues[1])
        )
      }
    } else {
      console.debug("IS ADVANCE", isAdvance)
      return (
        !(
          document.payment &&
          Validation.isNumber(document.price) &&
          document.name &&
          document.project &&
          document.supplier &&
          document.category &&
          !document.locked &&
          (typeof document.date.getFullYear === "function"
            ? document.date.getFullYear()
            : document.date.toDate().getFullYear()) > 1980 &&
          document.uriList.length > 0
        ) ||
        (isAdvance && document?.accounting_info_internal?.advance_documents?.length === 0)
        // (isAdvance !== null && !isAdvance && plusMinusValues[0] === plusMinusValues[1])
      )
    }
  }

  return (
    <Screen>
      <ScrollView>
        <TextInput
          error={!document.name}
          style={[styles.input, { marginTop: 10 }]}
          label={document.name ? "Název" : "Zadejte název dokladu"}
          onChangeText={(value) => changePropertyValue("name", value)}
          value={document.name}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          error={!Validation.isNumber(document.price)}
          style={styles.input}
          label={Validation.isNumber(document.price) ? "Cena v Kč s DPH" : "Zadejte cenu v Kč s DPH"}
          keyboardType="numeric"
          onChangeText={(value) => changePropertyValue("price", formatDecimalNumber(value))}
          value={document.price.toString()}
        />
        <AppDatePicker
          label="Datum dokladu"
          webLabel={["Den vystavení dokladu", "Měsíc vystavení dokladu", "Rok vystavení dokladu"]}
          value={document.date}
          onConfirm={(date) => changePropertyValue("date", date || new Date())}
        />
        <AppPicker
          value={document.project}
          descriptionLabel="Projekt"
          placeholder={{ label: "Vyberte projekt", value: "" }}
          onValueChange={(value) => changePropertyValue("project", value)}
          items={appPickerProjectItems}
        />

        <CheckBox
          containerStyle={DefaultTheme.checkBoxContainer}
          size={30}
          onPress={() => setManualSupplier(!manualSupplier)}
          title="Chci zadat dodavatele ručně"
          checkedColor={DefaultTheme.colors.primary}
          checked={manualSupplier}
        />
        {manualSupplier ? (
          <TextInput
            error={!document.supplier}
            style={styles.input}
            label={document.supplier ? "Dodavatel" : "Zadejte dodavatele"}
            onChangeText={(value) => {
              delete document.supplier_id
              changePropertyValue("supplier", value)
            }}
            value={document.supplier}
          />
        ) : (
          <NavigationPicker
            description={!!document.supplier && "Dodavatel"}
            onPress={handleSupplierPick}
            error={!document.supplier}
            label={document.supplier || "Vyberte dodavatele"}
          />
        )}
        <AppPicker
          value={document.category}
          descriptionLabel="Kategorie"
          placeholder={{ label: "Vyberte kategorii", value: "" }}
          onValueChange={(value) => changePropertyValue("category", value)}
          items={appPickerCategoryItems}
        />
        <AppPicker
          value={document.payment}
          descriptionLabel="Způsob platby"
          placeholder={{ label: "Vyberte způsob platby", value: "" }}
          onValueChange={(value) => changePropertyValue("payment", value)}
          items={appPickerPaymentItems}
        />
        <View style={styles.invoiceCheckWrapper}>
          <TouchableOpacity
            style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            onPress={() => handleAdvanceCheck(1)}
          >
            <Text>Připojit k dokladu zálohovou fakturu</Text>
            <CheckBox checked={isAdvance === null ? false : isAdvance} onPress={() => handleAdvanceCheck(1)} />
          </TouchableOpacity>
          {isAdvance !== null && isAdvance && (
            <AppMultipleDataPicker
              document={document}
              setDocument={setDocument}
              pickedData={pickedData}
              setPickedData={setPickedData}
            />
          )}
        </View>
        <View style={styles.invoiceCheckWrapper}>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onPress={() => handleAdvanceCheck(2)}
          >
            <Text>Označit jako zálohovou fakturu</Text>
            <CheckBox checked={isAdvance === null ? false : !isAdvance} onPress={() => handleAdvanceCheck(2)} />
          </TouchableOpacity>
          {/* {isAdvance !== null && !isAdvance && (
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", paddingBottom: 10 }}>
              <Text style={{ paddingRight: 10 }}>Výdejová</Text>
              <PlusMinusButtons
                plusMinusValues={plusMinusValues}
                setPlusMinusValues={setPlusMinusValues}
                document={document}
                setDocument={setDocument}
              />
              <Text style={{ paddingLeft: 10 }}>Příjmová</Text>
            </View>
          )} */}
        </View>
      </ScrollView>
      <NextButton
        text="Dále"
        disabled={isSaveDisabled()}
        onPress={() => {
          navigation.navigate("VerifyUploadDocument", {
            document: { ...document, price: Number(document.price) },
            action: "NEW",
            type: route.params.type,
          })
        }}
        showIcon
      />
    </Screen>
  )
}

const styles = StyleSheet.create({
  input: {
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
    height: 65,
  },
  invoiceCheckWrapper: {
    backgroundColor: DefaultTheme.colors.white,
    marginBottom: 10,
    paddingLeft: 20,
    minHeight: 70,
    marginHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.light,
    justifyContent: "center",
  },
})
