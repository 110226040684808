import React, { useCallback, useEffect } from "react"
import { View } from "react-native"
// import { GiftedChat, SystemMessage } from "react-native-gifted-chat"
import FSStoreContent from "../firebase/FSStoreContent"
import { useData } from "../providers/DataProvider"

export default function ChatScreen({ route }) {
  const to = route.params.to || "admin@dasar.cz"
  const { userMessages, currentUser, setUserMessages, users } = useData()

  // async function uploadMessages(message) {
  //   try {
  //     await firebase.firestore().collection("messages").doc(message._id).set(message)
  //     console.debug("Messages updated Succesfully")
  //     return
  //   } catch (error) {
  //     console.error("Error uploading Messages ", error)
  //     throw error
  //   }
  // }

  const onSend = useCallback((messages = []) => {
    setUserMessages((previousMessages) => GiftedChat.append(previousMessages, messages))
    uploadMessages(messages[0])
  }, [])

  return (
    <GiftedChat
      messages={userMessages.filter(
        (m) =>
          (m.user._id === currentUser.email && m.user.to === to) ||
          (m.user._id === to && m.user.to === currentUser.email)
      )}
      user={{
        _id: currentUser.email,
        name: currentUser.name,
        to: to,
        to_name: users.filter((user) => user.email === to)[0].name || "admin@dasar.cz",
      }}
      onSend={(messages) => onSend(messages)}
      renderAvatar={null}
      inverted={true}
      textInputStyle={{ backgroundColor: "white" }}
      keyboardShouldPersistTaps="never"
    />
  )
}
