import React from "react"
import { Platform, StyleSheet, Text, View } from "react-native"

import AppDatePicker from "./AppDatePicker"
import DefaultTheme from "../themes/DefaultTheme"

export default function DateIntervalButtons({
  fromLabel = "Od",
  toLabel = "Do",
  dateInterval,
  setDateInterval,
  fullScreen = false,
  disabled = false,
}) {
  const styles = getStyles(fullScreen)

  function handleChangeDate(type, value) {
    setDateInterval({ ...dateInterval, [type]: value })
  }

  return (
    <View style={[styles.contentWrapper, fullScreen && { flex: 1 }]}>
      <View style={styles.dateWrapper}>
        {Platform.OS === "web" && <Text style={styles.label}>{fromLabel}</Text>}
        <AppDatePicker
          disabled={disabled}
          label="Od"
          value={dateInterval.from}
          onConfirm={(value) => handleChangeDate("from", value)}
          style
          extra={2}
        />
      </View>
      <View style={styles.dateWrapper}>
        {Platform.OS === "web" && <Text style={styles.label}>{toLabel}</Text>}
        <AppDatePicker
          disabled={disabled}
          label="Do"
          value={dateInterval.to}
          onConfirm={(value) => handleChangeDate("to", value)}
          extra={2}
        />
      </View>
    </View>
  )
}

function getStyles(fullScreen) {
  return StyleSheet.create({
    contentWrapper: {
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    },
    dateWrapper: {
      flex: 1,
      borderWidth: 1,
      borderColor: DefaultTheme.colors.light,
      margin: 10,
      borderRadius: 5,
      backgroundColor: DefaultTheme.colors.surface,
      maxWidth: 450,
    },
    label: {
      fontSize: DefaultTheme.fonts.medium,
      alignSelf: "center",
      paddingTop: 10,
    },
  })
}
