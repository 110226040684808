import React, { useEffect, useState } from "react"
import { StyleSheet, View, FlatList, Platform } from "react-native"

import NextButton from "../components/NextButton"
import AppPicker from "../components/AppPicker"
import { useData } from "../providers/DataProvider"
import { getUserName } from "../utils/general"
import Screen from "../components/Screen"
import FlatListSeparator from "../components/FlatLists/FlatListSeparator"
import FlatListUserPickerItem from "../components/FlatLists/FlatListUserPickerItem"
import AppEmptyScreenView from "../components/AppEmptyScreenView"

export default function UsersPickerScreen({ route, navigation }) {
  const { projects, users } = useData()
  const [pickerUsers, setPickerUsers] = useState([])
  const [choosedUsers, setChoosedUsers] = useState([])
  const [picked, setPicked] = useState("")

  console.debug("CHOOSED USERS", choosedUsers)
  console.debug("PICKER USERS", pickerUsers)

  useEffect(() => {
    setChoosedUsers(route.params?.choosedUsers)
    const pickerUsers = projects
      .find((proj) => proj.id === route.params?.projectId)
      ?.users?.filter(
        (u) =>
          !route.params?.choosedUsers.includes(u.email) && u.roles?.some((role) => route.params?.roles?.includes(role))
      )
    setPickerUsers(pickerUsers)
  }, [])

  function addUser(email) {
    if (email) {
      setChoosedUsers([...choosedUsers, email])
      setPickerUsers(pickerUsers.filter((u) => u.email !== email))
      setPicked("")
    }
  }

  function deleteUser(delEmail) {
    setChoosedUsers(choosedUsers.filter((email) => email !== delEmail))
    setPickerUsers([...pickerUsers, { email: delEmail }])
  }

  const handleValueChange = (email) => {
    if (Platform.OS !== "ios" && !choosedUsers.includes(email)) {
      addUser(email)
    } else {
      setPicked(email)
    }
  }

  return (
    // <Screen barStyle="light-content">
    <Screen>
      {/* TEMPORARY REPORTING*/}
      <View style={{ flex: 1 }}>
        {choosedUsers.length > 0 ? (
          <FlatList
            data={choosedUsers}
            renderItem={({ item }) => (
              <FlatListUserPickerItem email={item} onDelete={(delEmail) => deleteUser(delEmail)} />
            )}
            keyExtractor={(item) => item}
            ItemSeparatorComponent={FlatListSeparator}
          />
        ) : (
          <AppEmptyScreenView
            message={
              pickerUsers?.length > 0
                ? "Nemáte vybrané žádné uživatele"
                : "Na projektu nejsou žádní uživatelé s těmito rolemi"
            }
          />
        )}
      </View>
      {pickerUsers?.length > 0 && (
        <AppPicker
          value={picked}
          style={appPickerStyles}
          placeholder={{ label: "--- Vyberte uživatele ---", value: "" }}
          onValueChange={(email) => handleValueChange(email)}
          onClose={() => addUser(picked)}
          items={pickerUsers.map((u) => ({ label: getUserName(users, u.email), value: u.email }))}
        />
      )}
      <NextButton
        onPress={() => {
          route.params.addUsers(choosedUsers)
          navigation.goBack()
        }}
        text="OK"
      />
    </Screen>
  )
}

const appPickerStyles = StyleSheet.create({
  inputIOS: {
    height: 100,
    width: "100%",
    paddingHorizontal: 15,
  },
  inputAndroid: {
    height: 100,
    paddingHorizontal: 15,
    paddingTop: 0,
  },
  inputWeb: {
    paddingLeft: 18,
    height: 65,
    color: "black",
  },
  placeholder: {
    color: "black",
  },
  iconContainer: {
    top: "45%",
    right: 20,
  },
})
