
export const exportDocsByProjectUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/exportDocsByProject"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/exportDocsByProject`
  }
}
export const getProcessBankStatementUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/processBankStatementCSV"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/processBankStatementCSV`
  }
}

export const getExportAttendanceMonthUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/exportMonthAttendance"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/exportMonthAttendance`
  }
}

export const getExportAttendanceUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/exportAttendance"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/exportAttendance`
  }
}

export const getExportLogBookMonthUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/exportLogBookMonth"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/exportLogBookMonth`
  }
}

export const getExportLogBookVehiclesUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/exportLogBookVehicles"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/exportLogBookVehicles`
  }
}

export const getExportPropertyUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/exportProperty"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/exportProperty`
  }
}

export const getExportDiaryUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/exportDiary"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/exportDiary`
  }
}

export const getImportPropertyUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/processImportProperty"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/processImportProperty`
  }
}

export const getSyncTriviAccountingDocsUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/syncTriviAccountingDocs"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/syncTriviAccountingDocs`
  }
}

export const getBatchPaymentRequestUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/batchPaymentRequest"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/batchPaymentRequest`
  }
}

export const getBatchPaymentPrepareDocsUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/batchPaymentPrepareDocs"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/batchPaymentPrepareDocs`
  }
}

export const getRbBatchPaymentPrepareDocsUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/rbBatchPaymentPrepareDocs"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/rbBatchPaymentPrepareDocs`
  }
}


export const getTransactionHistoryUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/getTransactionHistory"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/getTransactionHistory`
  }
}

export const getAccountBalanceListUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/getAccountBalanceList"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/getAccountBalanceList`
  }
}

export const getAccountBalanceUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/dasar-dev/europe-west3/rbgetaccountbalanceapi"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/rbgetaccountbalanceapi`
  }
}

export const getTransactionListUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/dasar-dev/europe-west3/rbgettransactionlistapi"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/rbgettransactionlistapi`
  }
}

export const getBatchPostUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/dasar-dev/europe-west3/rbpostbatchapi"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/rbpostbatchapi`
  }
}

export const getWeatherUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/getWeather"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/getWeather`
  }
}

export const getAutocompleteDataUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/getAutocompleteData"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/getAutocompleteData`
  }
}

export const getPlaceDataUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/getPlaceData"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/getPlaceData`
  }
}

export const getCashflowDocsUrl = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/getCashflowDocsInMonth"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/getCashflowDocsInMonth`
  }
}

export const getCashflowInitialValue = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/syncCashflowDocs"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/syncCashflowDocs`
  }
}

export const getCashflowChartData = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/getCashflowChartData"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/getCashflowChartData`
  }
}

export const getGeneratedExtraCashflowDocs = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/getGeneratedExtraCashflowDocs"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/getGeneratedExtraCashflowDocs`
  }
}

export const registerUser = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/registerUser"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/registerUser`
  }
}

export const getDeploymentEndpoint = (configId) => {
  if (configId === "emu") {
    return "http://localhost:5001/demo-dasar-dev/europe-west3/deploymentEndpoint"
  } else {
    return `https://europe-west3-dasar-${configId}.cloudfunctions.net/deploymentEndpoint`
  }
}



// banking
export const newBatchPaymentRequestUrl = (configId) => {
  if (configId === "emu") {
    return "https://banking-kb-dev.cexbit.com/payment"
  } else {
    return `https://banking-kb-${configId}.cexbit.com/payment`
  }
}

export const getKbBankRegisterSoftwareData = (configId) => {
  if (configId === "emu" || configId === "uat") {
    return { url: "https://banking-kb-dev.cexbit.com/client-register", domain: "banking-kb-dev.cexbit.com" }
  } else {
    return {
      url: `https://banking-kb-${configId}.cexbit.com/client-register`,
      domain: `banking-kb-${configId}.cexbit.com`,
    }
  }
}

export const getAccessTokenKbBankUrl = (configId) => {
  if (configId === "emu" || configId === "uat") {
    return "https://banking-kb-dev.cexbit.com/auth"
  } else {
    return `https://banking-kb-${configId}.cexbit.com/auth`
  }
}

//`dasar-${environment}.appspot.com`
export const getBucketName = (configId) => {
  if (configId === "emu") return "default-bucket"
  return `dasar-${configId}.appspot.com`
}
