import React from "react"
import { Text, View, StyleSheet, TouchableOpacity, Image } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"

export default function FlatListChatItem({ avatar, to, onPress, lastMessage, lastMessageTime }) {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Image style={styles.avatarWrapper} source={avatar} />
      <View>
        <Text style={{ fontSize: DefaultTheme.fonts.regular }}>{to}</Text>
        <Text style={{ fontSize: DefaultTheme.fonts.small, color: DefaultTheme.colors.light }}>
          {lastMessageTime.toString()}
        </Text>
        <Text style={{ fontSize: DefaultTheme.fonts.small, color: DefaultTheme.colors.light }}>{lastMessage}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: DefaultTheme.colors.white,
  },
  avatarWrapper: {
    height: 50,
    width: 50,
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    borderRadius: 25,
  },
})
