import React, { useState } from "react"
import { TouchableOpacity, View } from "react-native"
import { Menu, Divider } from "react-native-paper"
import { MaterialCommunityIcons, Ionicons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import { useStatus } from "../../providers/StatusProvider"
import { statusEnum } from "../../data/enums"
import { isAccountant, isAdmin, isExecutive, isTechnicalDirector } from "../../utils/validation"
import { useData } from "../../providers/DataProvider"
import { exportMonthAttendance, exportMonthLogBook } from "../../actions/headerItemActions"

export default function AttendanceDaysHeaderMenu({ params, navigation, title, freeDaysCount }) {
  const { currentUser, firebaseConfig } = useData()
  const [showMenu, setShowMenu] = useState(false)
  const { setTitle, setMessage, setStatus } = useStatus()

  const userEmail = params?.userEmail
  const monthIndex = params?.monthIndex || 0

  const hasEditPermission =
    isAdmin(currentUser) || isExecutive(currentUser) || isTechnicalDirector(currentUser) || isAccountant(currentUser)

  async function handleExportAttendance() {
    setShowMenu(false)
    setTitle("Generuji docházku do CSV souboru")
    setStatus(statusEnum.PROGRESS_UNDETERMINED)
    navigation.navigate("StatusScreen")

    try {
      await exportMonthAttendance(monthIndex, userEmail, firebaseConfig)
      setStatus(statusEnum.SUCCESS)
    } catch (error) {
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
    }
  }

  return (
    <Menu
      visible={showMenu}
      onDismiss={() => setShowMenu(false)}
      anchor={
        <TouchableOpacity onPress={() => setShowMenu(true)} style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.headerSecondary}
          />
        </TouchableOpacity>
      }
    >
      <Menu.Item
        style={{ direction: "ltr" }}
        title="Hromadná akce"
        onPress={() => {
          setShowMenu(false),
            navigation.navigate("AttendanceBulkAction", { monthIndex, userEmail, title, freeDaysCount })
        }}
        icon={() => (
          <MaterialCommunityIcons
            name="checkbox-multiple-marked-outline"
            size={DefaultTheme.icons.menuSize}
            color={DefaultTheme.colors.primary}
          />
        )}
      />
      {hasEditPermission && (
        <Menu.Item
          style={{ direction: "ltr" }}
          onPress={handleExportAttendance}
          title="Export do CSV"
          icon={() => (
            <MaterialCommunityIcons
              name="file-export"
              size={DefaultTheme.icons.menuSize}
              color={DefaultTheme.colors.primary}
            />
          )}
        />
      )}
    </Menu>
  )
}
