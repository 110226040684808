import React from "react"
import { View, Text } from "react-native"
import FlatListPickerItem from "./FlatLists/FlatListPickerItem"
import DefaultTheme from "../themes/DefaultTheme"
import FlatListSeparator from "../components/FlatLists/FlatListSeparator"

export default function AppMultipleDataPicker({ document, setDocument, pickedData, setPickedData, disabled = false }) {
  return (
    <View style={{ backgroundColor: DefaultTheme.colors.white, paddingLeft: 22, paddingRight: 30, paddingBottom: 10 }}>
      {pickedData.length > 0 ? (
        pickedData.map((item, index) => (
          <View key={index}>
            <FlatListPickerItem
              document={document}
              item={item}
              data={pickedData}
              setData={setPickedData}
              setDocument={setDocument}
              disabled={disabled}
            />
            {index < pickedData.length - 1 && <FlatListSeparator />}
          </View>
        ))
      ) : (
        <Text>Na tomto projektu neexistuje žádná zálohová faktura</Text>
      )}
    </View>
  )
}
