import React, { useEffect, useState } from "react"
import { FlatList } from "react-native"
import { useData } from "../../providers/DataProvider"
import Screen from "../../components/Screen"
import FlatListAccountingDocumentApproveItem from "../../components/FlatLists/FlatListAccountingDocumentApproveItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"

export default function ApprovingAccountingDocumentsOverview({ navigation }) {
  const { accountingDocuments } = useData()

  return (
    <Screen>
      {accountingDocuments.length > 0 ? (
        <FlatList
          data={accountingDocuments}
          renderItem={({ item }) => <FlatListAccountingDocumentApproveItem document={item} navigation={navigation} />}
          keyExtractor={(item) => item.id.toString()}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="Nemáte žádné doklady ke schválení" />
      )}
    </Screen>
  )
}
