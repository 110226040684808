import React, { useEffect } from "react"
import { Text, View, Platform } from "react-native"
import { statusEnum } from "../data/enums"
import { useStatus } from "../providers/StatusProvider"
import { useNavigation } from "@react-navigation/core"
import DrawerNavigator from "./Navigation/DrawerNavigator"
import * as Sentry from "sentry-expo"

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    const sentry = Platform.OS === "web" ? Sentry.Browser : Sentry.Native
    sentry.captureException(error, info)
    this.setState({ hasError: true, error, info })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryFunctionalComponent error={this.state.error} info={this.state.info} props={this.props} />
    }
    return this.props.children
  }
}

function ErrorBoundaryFunctionalComponent({ error, info, props }) {
  const { setTitle, setStatus, setMessage, setNavStateMessage } = useStatus()
  const navigation = useNavigation()

  useEffect(() => {
    setTitle("Něco se pokazilo")
    setStatus(statusEnum.ERROR)
    setMessage(error.message + info.componentStack?.split("\n")?.[1])
    const drawerRoute = navigation.getState().routes.find((el) => el.name === "DrawerNavigator")
    if (drawerRoute) {
      const stackNavRoute = drawerRoute?.state.routes.find((el) => el.name === "StackNavigator")
      if (stackNavRoute) {
        setNavStateMessage("Navigace: " + JSON.stringify(stackNavRoute?.state?.routes.map((route) => route?.name)))
      }
    }
    navigation.navigate("StatusScreen")
    console.debug("NAVIGATION STATE", JSON.stringify(navigation.getState()))
  }, [])

  // return <View />
  return <DrawerNavigator {...props} hasError />
}
