import React, { useEffect, useState } from "react"
import { StyleSheet, Text, FlatList, View, TouchableOpacity, Button, Platform } from "react-native"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import { FAB } from "react-native-paper"
import FlatListPropertyUserOverviewItem from "../../components/FlatLists/FlatListPropertyUserOverviewItem"
import FlatListPropertyItem from "../../components/FlatLists/FlatListPropertyItem"
import {
  environmentEnum,
  propertyTypeEnum,
  propertyViewTypeEnum,
  userRoleEnum,
  userStateEnum,
  propertyStateEnum,
} from "../../data/enums"
import { isAdmin, isExecutive, isOfficeManager } from "../../utils/validation"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import Constants from "expo-constants"
import { propertyTypeLabels } from "../../data/labels"
import { CheckBox } from "react-native-elements"
import { FontAwesome } from "@expo/vector-icons"
import FSStoreContent from "../../firebase/FSStoreContent"
import AppPicker from "../../components/AppPicker"

export default function PropertyOverview({ navigation, route }) {
  const { property, users, currentUser, setProperty, projects, settings } = useData()
  const viewType = route.params?.viewType
  const hasEditPermission = isAdmin(currentUser) || isExecutive(currentUser) || isOfficeManager(currentUser)
  const [filteredUsers, setFilteredUsers] = useState([])
  const [pickedFilter, setPickedFilter] = useState([])
  const [filteredProperty, setFilteredProperty] = useState([])
  const [filtersVisible, setFiltersVisible] = useState(true)
  const appPickerProjectItems = projects.map((proj) => ({ label: `${proj.id} - ${proj.name}`, value: proj.id }))
  const [pickedProject, setPickedProject] = useState(" ")
  console.debug("PICKED FILTER", pickedFilter)
  console.debug("PICKED PROJECT FILTER", pickedProject)

  useEffect(() => {
    ;(async () => {
      await FSStoreContent.onSnapshotProperty(setProperty)
    })()
  }, [])

  useEffect(() => {
    const activeUsers = users.filter(
      (u) => u.state !== userStateEnum.DELETED && !u.roles.includes(userRoleEnum.INVESTOR)
    )
    if (Constants.manifest.extra.environment !== environmentEnum.dev) {
      setFilteredUsers(activeUsers.filter((u) => !u.roles.includes(userRoleEnum.ADMIN)))
    } else {
      setFilteredUsers(activeUsers)
    }
  }, [users])

  const filterProperty = (property, settings) => {
    let tempProperty = property
    if (settings?.property?.show_discarded_property) {
      tempProperty = tempProperty.filter((p) => p.state !== propertyStateEnum.DISCARDED)
    }
    if (pickedFilter.length > 0) {
      setFilteredProperty(
        pickedProject === " "
          ? tempProperty.filter((prop) => pickedFilter.includes(prop.type))
          : tempProperty.filter((prop) => pickedFilter.includes(prop.type) && prop.assigned_project === pickedProject)
      )
    } else {
      setFilteredProperty(
        pickedProject === " " ? tempProperty : tempProperty.filter((prop) => prop.assigned_project === pickedProject)
      )
    }
  }

  useEffect(() => {
    filterProperty(property, settings)
  }, [property, settings])

  function handleFilterPick(key) {
    // console.debug("KEY", key)
    if (pickedFilter.includes(key)) {
      console.debug("INCLUDES")
      const tempArr = []
      for (let i = 0; i < pickedFilter.length; i++) {
        if (pickedFilter[i] !== key) {
          tempArr.push(pickedFilter[i])
        }
      }
      setPickedFilter(tempArr)
      if (tempArr.length > 0) {
        setFilteredProperty(
          pickedProject === " "
            ? property.filter((prop) => tempArr.includes(prop.type))
            : property.filter((prop) => tempArr.includes(prop.type) && prop.assigned_project === pickedProject)
        )
      } else {
        setFilteredProperty(
          pickedProject === " " ? property : property.filter((prop) => prop.assigned_project === pickedProject)
        )
      }
    } else {
      console.debug("NOT INCLUDES")
      const tempArr = pickedFilter
      tempArr.push(key)
      setPickedFilter(tempArr)

      if (pickedProject === " ") {
        setFilteredProperty(property.filter((prop) => tempArr.includes(prop.type)))
      } else {
        setFilteredProperty(
          property.filter((prop) => tempArr.includes(prop.type) && prop.assigned_project === pickedProject)
        )
      }
    }
  }

  function handleProjectPick(newPickedProject) {
    setPickedProject(newPickedProject)
    if (pickedFilter.length > 0) {
      setFilteredProperty(
        newPickedProject === " "
          ? property.filter((prop) => pickedFilter.includes(prop.type))
          : property.filter((prop) => pickedFilter.includes(prop.type) && prop.assigned_project === newPickedProject)
      )
    } else {
      setFilteredProperty(
        newPickedProject === " " ? property : property.filter((prop) => prop.assigned_project === newPickedProject)
      )
    }
  }

  const filterHeader = () => {
    return (
      <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around" }}>
        {Object.keys(propertyTypeLabels).map((key) => (
          <TouchableOpacity
            key={key}
            onPress={() => handleFilterPick(key)}
            style={[
              {
                flexDirection: "row",
                alignItems: "center",
                padding: 5,
              },
              Platform.OS !== "web" && { borderBottomColor: "white", borderBottomWidth: 1 },
            ]}
          >
            <Text>{propertyTypeLabels[key]}</Text>
            <CheckBox
              containerStyle={{ padding: 0 }}
              wrapperStyle={{ padding: 0 }}
              checked={pickedFilter.includes(key)}
              onPress={() => handleFilterPick(key)}
            />
          </TouchableOpacity>
        ))}
        <AppPicker
          value={pickedProject}
          descriptionLabel="Projekt"
          placeholder={{ label: "Žádný", value: " " }}
          onValueChange={(newPickedProject) => handleProjectPick(newPickedProject)}
          items={appPickerProjectItems}
        />
      </View>
    )
  }

  return (
    <Screen>
      {viewType === propertyViewTypeEnum.ALL ? (
        <>
          <TouchableOpacity
            onPress={() => setFiltersVisible(!filtersVisible)}
            style={{ justifyContent: "flex-end", padding: 5, flexDirection: "row" }}
          >
            <Text style={{ paddingRight: 5 }}>{!filtersVisible ? "Zobrazit filtery" : "Skrýt filtery"}</Text>
            <FontAwesome name="filter" size={DefaultTheme.icons.s} color={DefaultTheme.colors.primary} />
          </TouchableOpacity>
          {filtersVisible && filterHeader()}

          {filteredProperty.length > 0 ? (
            <FlatList
              data={filteredProperty}
              renderItem={({ item }) => <FlatListPropertyItem prop={item} />}
              keyExtractor={(item) => item.id.toString()}
              ItemSeparatorComponent={FlatListSeparator}
              extraData={property}
            />
          ) : (
            <AppEmptyScreenView message="Neexistuje žádný majetek" />
          )}

          {hasEditPermission && (
            <FAB
              visible={true}
              style={DefaultTheme.fab}
              color={DefaultTheme.colors.fabAdd}
              icon="plus"
              onPress={() => navigation.navigate("PropertyDetail")}
            />
          )}
        </>
      ) : filteredUsers.length > 0 ? (
        <FlatList
          data={filteredUsers}
          renderItem={({ item }) => <FlatListPropertyUserOverviewItem user={item} />}
          keyExtractor={(item) => item.email.toString()}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="Neexistují žádní uživatelé" />
      )}
    </Screen>
  )
}
