import React, { useEffect, useState, useRef } from "react"
import { useStatus } from "../../providers/StatusProvider"
import StatusScreen from "../../screens/StatusScreen"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import * as fb from "../../firebase/config"
import DrawerNavigator from "./DrawerNavigator"
import AuthStackNavigator from "./AuthStackNavigator"
import LoadingScreen from "../../screens/LoadingScreen"
import Login from "../../screens/User/Login"
import ErrorBoundary from "../ErrorBoundary"
import { useData } from "../../providers/DataProvider"
import Constants from "expo-constants"
import { useNavigation } from "@react-navigation/native"
import { getDeploymentEndpoint } from "../../utils/backendUrls"
import {loadItem, saveItem} from "../../actions/asyncLocalStorage";
import {firebase} from "../../firebase/config";

export default function RootStackNavigator() {
  const RootStack = createNativeStackNavigator()
  const { firebaseConfig, setFirebaseConfig, setBackendDeployment } = useData()
  const [user, setUser] = useState()
  const [initializing, setInitializing] = useState(true)
  const firebaseUnsub = useRef(null)
  const authStateUnsub = useRef(null)
  const { status, setTitle, statusEnum, setStatus, setMessage } = useStatus()

  const navigation = useNavigation()

  const switchConfig = async () => {
    const credsObject = await loadItem("LOGIN_CREDENTIALS")
    console.debug("creds:" + JSON.stringify(credsObject))
    //setMessage("credsLength:" + credsObject?.length)
    //setStatus(statusEnum.PROGRESS_UNDETERMINED)

    if (!firebaseConfig) {
      let defaultApp = await loadItem("DEFAULT_APP")
      console.debug("Default app:" + defaultApp + ",firebase config:" + firebaseConfig)
      if (defaultApp === null || defaultApp.length === 0) {
        console.debug("Default app before: ", defaultApp, " environment", Constants.manifest.extra.environment)
        defaultApp = fb.defaultConfigs[Constants.manifest.extra.environment].primary
        console.debug("Default app after: ", defaultApp, " environment", Constants.manifest.extra.environment)
        await loadItem("DEFAULT_APP", defaultApp)
      }
      //for old versions of the app
      const tempDefaultApp = ["0", "1"].includes(defaultApp) ? "prod" : defaultApp
      console.debug("Default tempDefaultApp app: ", tempDefaultApp, " defaultApp", defaultApp)
      setFirebaseConfig(tempDefaultApp)
    }

    console.debug("Here1")

    if (firebaseConfig) {
      if (firebaseUnsub.current) firebaseUnsub.current()
      if (authStateUnsub.current) authStateUnsub.current()
      if (firebase.default.apps.length > 0) {
        if (firebase.apps[0].auth().currentUser) await firebase.apps[0].auth().signOut()
        await firebase.apps[0].delete()
      }
      const config = firebaseConfig
      firebase.initializeApp(fb.configObj[config], config)
      console.debug("Firebase config was set: ", firebaseConfig)
      if (config === "emu") {
        firebase.apps[0].firestore().useEmulator("localhost", 8080)
        firebase.apps[0].auth().useEmulator("http://localhost:9099")
      }

      try {
        authStateUnsub.current = firebase.apps[0].auth().onAuthStateChanged(async (u) => {
          console.debug("auth state changed", u)
          if (u) {
            await firebase.apps[0].auth().currentUser.getIdToken(true)
            console.debug("getIdToken")
            await saveItem("DEFAULT_APP", firebaseConfig)
            console.debug("DEFAULT_APP")
          }
          setUser(u)
          console.debug("setUser")
          setInitializing(false)
          console.debug("status: ", status)
          if (u && firebase.apps[0].auth().name === firebaseConfig && status === statusEnum.RELOG) {
            console.log("navigation: " + JSON.stringify(navigation))
            setStatus(statusEnum.NONE)
          } else if (status !== statusEnum.RELOG && status !== statusEnum.REGISTER) {
            console.debug("setting status to none")
            setStatus(statusEnum.NONE)
          }
          navigation.reset({ index: 0, routes: [{ name: "DrawerNavigator" }] })

        })
        if (status === statusEnum.RELOG) {
          console.debug("do re-logging")
          const creds = JSON.parse(credsObject)
          await firebase.apps[0].auth().signInWithEmailAndPassword(creds.email, creds.password)
        }
      } catch (error) {
        console.error(error)
        setMessage(error.message)
        setStatus(statusEnum.ERROR)
      }
      const url = getDeploymentEndpoint(firebaseConfig)
      const request = await fetch(url, { method: "GET", headers: { "Content-Type": "text/html; charset=utf-8" } })
      const response = (await request.text()) || "Not found"
      const deploymentText = response.split("HEAD-")[1] || "Not found"
      setBackendDeployment(deploymentText)
    }
  }

  useEffect(() => {
    switchConfig()
  }, [firebaseConfig])

  if (initializing) {
    setTitle("Načítám aplikaci")
    setStatus(statusEnum.LOADING)
  }

  return (
    <RootStack.Navigator
      screenOptions={{ headerShown: false, presentation: "fullScreenModal", animation: "none" }}
      initialRouteName="LoadingScreen"
    >
      {status !== statusEnum.LOADING ? (
        user ? (
          <RootStack.Screen name="DrawerNavigator">
            {(props) => (
              <ErrorBoundary {...props}>
                <DrawerNavigator {...props} />
              </ErrorBoundary>
            )}
          </RootStack.Screen>
        ) : (
          <RootStack.Screen name="AuthStackNavigator" component={AuthStackNavigator} />
        )
      ) : (
        <RootStack.Screen name="LoadingScreen" component={LoadingScreen} />
      )}
      <RootStack.Screen name="StatusScreen" component={StatusScreen} options={{ animationEnabled: false }} />
      <RootStack.Screen
        name="Login"
        component={Login}
        options={{
          title: "Přihlášení",
          animationEnabled: false,
        }}
      />
    </RootStack.Navigator>
  )
}
