import React, { useEffect, useState } from "react"
import { Text, TouchableOpacity, View, StyleSheet, Platform, Dimensions } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import { monthNameLabels } from "../../data/labels"
import { MaterialCommunityIcons, FontAwesome5, FontAwesome } from "@expo/vector-icons"
import { STATE_VIEW_PARAMS_CLOSING_MONTH, STATE_VIEW_PARAMS_USER_CASH_DESK } from "../../data/params"
import { actionEnum, monthStateEnum, statusEnum, paymentEnum } from "../../data/enums"
import PrimButton from "../PrimButton"
import { useStatus } from "../../providers/StatusProvider"
import { isAccountant, isAdmin, isExecutive, isOfficeManager } from "../../utils/validation"
import { useData } from "../../providers/DataProvider"
import { getCashDeskMonthClosingState, toCurrentDateTime } from "../../utils/general"
import { PieChart } from "react-native-chart-kit"
import NumberFormatter from "../NumberFormatter"
import PieChartLegendItem from "../PieChartLegendItem"

export default function FlatListCashDeskMonthItem({ navigation, month, user }) {
  const { setTitle, setMessage, setStatus, setAction } = useStatus()
  const { currentUser, cashDesks } = useData()
  const [cashDesk, setCashDesk] = useState(null)
  const today = new Date()
  const seasonDate = new Date(today.getFullYear(), today.getMonth() - month.monthIndex)
  const monthTitle = `${monthNameLabels[seasonDate.getMonth()]} ${seasonDate.getFullYear()}`
  const yearMonth =
    month.monthDocs.length > 0
      ? `${month.monthDocs[0]?.date?.toDate().getFullYear()}_${month.monthDocs[0]?.date?.toDate().getMonth()}`
      : `${seasonDate.getFullYear()}_${seasonDate.getMonth()}`

  const residualCharge = cashDesk?.[yearMonth]?.charges?.residual_charge || 0
  const initialCharge = cashDesk?.[yearMonth]?.charges?.initial_charge || 0
  const charge = initialCharge + residualCharge
  const balance = charge - (getDocsPriceCashSum() || 0)

  const graphMax = charge > (getDocsPriceCashSum() || 0) ? charge : (getDocsPriceCashSum() || 0) - balance

  const balancePercentage = balance > 0 ? (balance / graphMax) * 100 : 0
  const cashPercentage = getDocsPriceCashSum() > 0 ? (getDocsPriceCashSum() / graphMax) * 100 : 0

  const hasEditPermission =
    isAdmin(currentUser) || isExecutive(currentUser) || isAccountant(currentUser) || isOfficeManager(currentUser)

  useEffect(() => {
    const userCashDesk = cashDesks.find((cd) => cd.user === user.email)
    setCashDesk(userCashDesk)
  }, [cashDesks])

  function getDocsPriceCashSum() {
    return month.monthDocs.reduce((a, c) => (c.payment === paymentEnum.CASH ? a + c.price : a), 0)
  }

  function getDocsPriceDebitCardSum() {
    return month.monthDocs.reduce((a, c) => (c.payment === paymentEnum.DEBIT_CARD ? a + c.price : a), 0)
  }

  function getUserCashDesk() {
    return (
      cashDesk || {
        [yearMonth]: { closing_items: [] },
        user: user.email,
      }
    )
  }

  function getLastUserClosing() {
    const closingItems = cashDesk?.[yearMonth]?.closing_items || []
    closingItems.sort(
      (a, b) =>
        (b.closing_at.seconds ? b.closing_at.toDate() : b.closing_at) -
        (a.closing_at.seconds ? a.closing_at.toDate() : a.closing_at)
    )

    return closingItems.length === 0
      ? "Ještě nebyla provedena"
      : `${closingItems[0]?.closing_by} \n${
          closingItems[0]?.closing_at.seconds
            ? toCurrentDateTime(closingItems[0]?.closing_at)
            : toCurrentDateTime(closingItems[0]?.closing_at)
        }`
  }

  const pieChartData =
    balance > 0
      ? [
          {
            balance: balance,
            color: DefaultTheme.colors.success,
          },
          {
            balance: getDocsPriceCashSum(),
            color: DefaultTheme.colors.danger,
          },
        ]
      : [
          {
            balance: getDocsPriceCashSum(),
            color: DefaultTheme.colors.danger,
          },
          {
            balance: -balance,
            color: "red",
          },
        ]

  const pieChartConfig = {
    color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
  }

  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate("CashDeskMonthDetail", {
          monthTitle,
          monthIndex: month.monthIndex,
          monthState: getCashDeskMonthClosingState(cashDesk, yearMonth),
          user,
          cashDeskDocs: month.monthDocs,
          yearMonth,
          cashDesk: getUserCashDesk(),
        })
      }
      style={[
        styles.card,
        {
          borderLeftColor: STATE_VIEW_PARAMS_CLOSING_MONTH[getCashDeskMonthClosingState(cashDesk, yearMonth)].color,
        },
      ]}
    >
      <View style={{ alignItems: "center" }}>
        <MaterialCommunityIcons
          {...STATE_VIEW_PARAMS_USER_CASH_DESK[
            getCashDeskMonthClosingState(cashDesk, yearMonth) === monthStateEnum.CLOSED
          ]}
          size={24}
        />
        {getCashDeskMonthClosingState(cashDesk, yearMonth) === monthStateEnum.CLOSED && (
          <FontAwesome name="lock" color={DefaultTheme.colors.disabled} size={24} />
        )}
      </View>

      <View style={{ flex: 2 }}>
        <Text style={styles.monthTitle}>{monthTitle}</Text>
        <View
          style={{
            paddingLeft: Dimensions.get("window").width / 12,

            flexDirection: Platform.OS === "web" ? "row" : "column",
          }}
        >
          {charge >= 0 ? (
            <PieChart
              data={pieChartData}
              width={Dimensions.get("window").width / 2}
              height={180}
              chartConfig={pieChartConfig}
              accessor="balance"
              backgroundColor="transparent"
              hasLegend={false}
              paddingLeft={Dimensions.get("window").width / 8}
            />
          ) : (
            <View style={{ flexDirection: Platform.OS === "web" ? "row" : "column" }}>
              <View
                style={{
                  alignItems: "center",
                  width: Dimensions.get("window").width / 2,
                  height: 180,
                }}
              >
                <View
                  style={{
                    borderWidth: 5,
                    borderColor: DefaultTheme.colors.primary,
                    height: 144,
                    width: 144,
                    borderRadius: 72,
                  }}
                />
              </View>
            </View>
          )}
          <View style={{ paddingRight: 10 }}>
            <View style={{ flexDirection: "row", justifyContent: "space-between", paddingTop: 10 }}>
              <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <View
                  style={{
                    backgroundColor: DefaultTheme.colors.primary,
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    marginBottom: 5,
                    marginRight: 5,
                  }}
                />
                <View style={{}}>
                  <Text style={{ paddingRight: 5 }}>Převedené</Text>
                  <Text style={{ paddingRight: 5 }}>Dotace</Text>
                </View>
              </View>
              <View>
                <Text style={{ paddingLeft: 20, textAlign: "right" }}>
                  {NumberFormatter(residualCharge.toFixed(0))}
                </Text>
                <Text style={{ paddingLeft: 20, textAlign: "right" }}>{NumberFormatter(initialCharge.toFixed(0))}</Text>
              </View>
            </View>
            <PieChartLegendItem
              value={getDocsPriceDebitCardSum()}
              color="purple"
              icon={
                <MaterialCommunityIcons
                  name="credit-card-outline"
                  color="purple"
                  size={DefaultTheme.icons.flatlistSize}
                />
              }
            />
            <PieChartLegendItem
              description="Zůstatek"
              value={balance}
              percentage={balancePercentage}
              color={balance > 0 ? DefaultTheme.colors.success : "red"}
            />
            <PieChartLegendItem
              value={getDocsPriceCashSum()}
              percentage={cashPercentage}
              color={DefaultTheme.colors.danger}
              icon={
                <MaterialCommunityIcons
                  name="cash"
                  color={DefaultTheme.colors.danger}
                  size={DefaultTheme.icons.flatlistSize}
                />
              }
            />
          </View>
        </View>
        <View style={{ paddingTop: 5, justifyContent: "space-between", flexDirection: "row" }}>
          <View>
            <Text style={DefaultTheme.hint}>Poslední uzávěrka</Text>
            <Text>{getLastUserClosing()}</Text>
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={{ paddingRight: 5 }}>{month.monthDocs.length} ks</Text>
            <FontAwesome5
              name="file-invoice-dollar"
              color={DefaultTheme.colors.primary}
              size={DefaultTheme.icons.flatlistSize}
            />
          </View>
        </View>
      </View>

      <View style={{ flex: 0.5, alignItems: "center" }}>
        {getCashDeskMonthClosingState(cashDesk, yearMonth) === monthStateEnum.CLOSED
          ? hasEditPermission && (
              <PrimButton
                width={48}
                icon={<MaterialCommunityIcons name="lock-open-variant" color={DefaultTheme.colors.white} size={24} />}
                onPress={() => {
                  setTitle(`Opravdu chcete otevřít měsíc ${monthTitle}?`)
                  setStatus(statusEnum.PROGRESS_DECIDE)
                  setAction({
                    type: actionEnum.CASH_DESK_OPEN_MONTH,
                    params: {
                      toScreen: "CashDeskMonths",
                      cashDesk: getUserCashDesk(),
                      monthDocs: month.monthDocs,
                      yearMonth,
                    },
                  })
                  navigation.navigate("StatusScreen")
                }}
              />
            )
          : (user.email === currentUser.email || hasEditPermission) && (
              <PrimButton
                width={48}
                disabled={getCashDeskMonthClosingState(cashDesk, yearMonth) === monthStateEnum.CLOSED}
                icon={<MaterialCommunityIcons name="lock" color={DefaultTheme.colors.white} size={24} />}
                onPress={() => {
                  setTitle(`Opravdu chcete uzavřít měsíc ${monthTitle}?`)
                  setMessage("Tato akce nejde zvrátit!")
                  setStatus(statusEnum.PROGRESS_DECIDE)
                  setAction({
                    type: actionEnum.CASH_DESK_CLOSE_MONTH,
                    params: {
                      toScreen: "CashDeskMonths",
                      cashDesk: getUserCashDesk(),
                      monthDocs: month.monthDocs,
                      yearMonth,
                      balance,
                    },
                  })
                  navigation.navigate("StatusScreen")
                }}
              />
            )}
      </View>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  card: {
    minHeight: 120,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    paddingRight: 10,
    paddingVertical: 10,
    flexDirection: "row",
    backgroundColor: DefaultTheme.colors.white,
    borderLeftWidth: 10,
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
  name: {
    paddingLeft: 10,
    marginRight: "auto",
  },
  monthTitle: {
    paddingLeft: 10,
    fontWeight: "bold",
  },
  cashAlign: {
    textAlign: "right",
    paddingLeft: 10,
  },
})
