import React, { useEffect, useState } from "react"
import { View, StyleSheet } from "react-native"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import { TextInput } from "react-native-paper"
import NextButton from "../../components/NextButton"
import DefaultTheme from "../../themes/DefaultTheme"
import { useStatus } from "../../providers/StatusProvider"
import { statusEnum } from "../../data/enums"
import FSStoreContent from "../../firebase/FSStoreContent"

export default function WeatherAPICredentials({ navigation }) {
  const { settings } = useData()
  const { setStatus, setTitle, setMessage } = useStatus()
  const [weatherApiCrenedtials, setWeatherApiCrendentials] = useState({ id: "weather", api_key: "" })

  const updateSettings = async () => {
    try {
      setTitle("Nahrávam API credentials")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      await FSStoreContent.updateSettings(weatherApiCrenedtials)

      setMessage("API credentials byly nahrané úspěšně!")
      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      console.error(error)
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  useEffect(() => {
    setWeatherApiCrendentials(settings.weather || { id: "weather", api_key: "" })
  }, [settings])

  return (
    <Screen>
      <View style={styles.inputsWrapper}>
        <TextInput
          error={!weatherApiCrenedtials.api_key}
          style={[DefaultTheme.input, { marginVertical: 10 }]}
          label={weatherApiCrenedtials.api_key ? "API key" : "Zadejte API key"}
          onChangeText={(value) => setWeatherApiCrendentials({ ...weatherApiCrenedtials, api_key: value })}
          value={weatherApiCrenedtials.api_key}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
      </View>
      <NextButton disabled={!weatherApiCrenedtials.api_key} text="Uložit" onPress={updateSettings} />
    </Screen>
  )
}

const styles = StyleSheet.create({
  inputsWrapper: {
    flex: 1,
  },
})
