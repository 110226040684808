import React, { useEffect, useState } from "react"
import { View, ScrollView, TouchableOpacity } from "react-native"
import { TextInput } from "react-native-paper"
import NextButton from "../../components/NextButton"
import {
  documentCategoryEnum,
  documentViewTypeEnum,
  propertyStateEnum,
  propertyTypeEnum,
  statusEnum,
} from "../../data/enums"
import Validation from "../../utils/validation"
import DefaultTheme from "../../themes/DefaultTheme"
import { useData } from "../../providers/DataProvider"
import Screen from "../../components/Screen"
import AppDatePicker from "../../components/AppDatePicker"
import AppPicker from "../../components/AppPicker"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useStatus } from "../../providers/StatusProvider"
import uuid from "uuid"
import { isDateInYearMonth, sanitizeNumber } from "../../utils/general"
import { propertyStateLabels } from "../../data/labels"
import { firebase } from "../../firebase/config"

export default function LogDetail({ navigation, route }) {
  const { currentUser, settings } = useData()
  const { setTitle, setMessage, setStatus } = useStatus()
  let monthLogBook = route.params.monthLogBook
  const [vehicle, setVehicle] = useState(route.params.vehicle)
  const [document, setDocument] = useState(null)
  const disabled = route.params.disabled
  const [log, setLog] = useState(
    route.params?.log || {
      licence_plate: route.params.licencePlate || "",
      start_km: "",
      end_km: "",
      date: route.params?.monthLogBook?.id
        ? new Date(Number(monthLogBook.id.split("_")[0]), Number(monthLogBook.id.split("_")[1]))
        : new Date(),
      created_by: currentUser.email,
      type: "",
      from_to: "",
    }
  )

  const [property, setProperty] = useState([])

  async function setPropertyEnriched(propertyList) {
    setProperty(await FSStoreContent.enrichPropertyList(propertyList))
  }

  useEffect(() => {
    ;(async () => {
      await FSStoreContent.onSnapshotProperty(setPropertyEnriched)
    })()
  }, [])

  useEffect(() => {
    setVehicle(
      property.find((p) => (log.vehicle_id ? p.vehicle_id === log.vehicle_id : p.licence_plate === log.licence_plate))
    )
  }, [log, property])

  console.debug("LOG", log)
  // console.debug("VEHICLE", vehicle)
  // console.debug("DOCUMENT", document)

  const appPickerVehiclesItem = property
    .filter(
      (p) =>
        p.type === propertyTypeEnum.VEHICLE &&
        (p.state === propertyStateEnum.SHARED || p.state === propertyStateEnum.ASSIGNED)
    )
    .map((p) => ({
      label: p.owner
        ? `${p.licence_plate_editable || p.licence_plate} - ${p.owner}`
        : p.state === propertyStateEnum.SHARED
        ? `${p.licence_plate_editable || p.licence_plate} - sdílené`
        : p.licence_plate_editable || p.licence_plate,
      value: p.vehicle_id,
    }))

  let appPickerLogItem
  let logDistance = {}
  const logItems = {}

  let appPickerLogItemInitial = { label: "Nová jízda", value: "NEW" }
  logDistance["NEW"] = 0
  let tempLogs = settings.logBook?.predefined_logs || []
  tempLogs = tempLogs.filter(
    (el) =>
      (route.params?.log && route.params.log.type === el.value) ||
      (el.hasOwnProperty("active") && el?.active) ||
      !el.hasOwnProperty("active")
  )
  tempLogs.forEach((el) => {
    logDistance[el.value] = Number(el.distance)
    logItems[el.value] = el.label
  })
  appPickerLogItem = tempLogs.map((el) => ({ label: el.label, value: el.value })) || []
  appPickerLogItem.unshift(appPickerLogItemInitial)
  console.debug("LogDistance", logDistance, logItems, appPickerLogItem)

  useEffect(() => {
    let subscribed = true
    if (subscribed) {
      setDocument(route.params.document)
      if (route.params.document?.id) {
        setLog({ ...log, document_id: route.params.document?.id })
      }
    }

    return () => {
      subscribed = false
    }
  }, [route.params])

  useEffect(() => {
    if (log.document_id) {
      ;(async () => {
        setDocument(await FSStoreContent.loadDocument(log.document_id))
      })()
    }
  }, [log])


  async function handleSaveLog() {
    setTitle("Ukládám jízdu")
    navigation.navigate("StatusScreen")

    if (isDateInYearMonth(log.date, monthLogBook.id)) {
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
    } else {
      setStatus(statusEnum.ERROR)
      setMessage("Datum jízdy neodpovídá danému měsíci!")
      return
    }

    if (document?.id) {
      log.document_id = document.id
    }

    if (!log.id) {
      log.id = uuid.v4()
      monthLogBook.logs.push(log)
    }

    monthLogBook.logs = monthLogBook.logs.map((l) => {
      if (l.id === log.id) {
        return { ...log, created_at: new Date(), end_km: Number(log.end_km), start_km: sanitizeNumber(log.start_km) }
      } else {
        return l
      }
    })

    try {
      await FSStoreContent.updateMonthLogBook(monthLogBook)
      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
  }

  function handleIncludesDoc() {
    navigation.navigate("AccountingDocumentDetail", {
      document: document || {
        category: documentCategoryEnum.UBYTOVANI,
        created_by: currentUser.email,
        created_at: new Date(),
        filestore_uri_list: [],
        date: new Date(),
      },
      viewType: documentViewTypeEnum.VEHICLE_LOG,
      toScreen: { screen: "LogDetail", params: { log, monthLogBook, disabled, vehicle } },
    })
  }

  return (
    <Screen>
      <ScrollView>
        <View style={{ flex: 1 }}>
          <AppPicker
            value={vehicle?.vehicle_id || ""}
            descriptionLabel="SPZ vozidla"
            placeholder={{ label: "Vyberte vozidlo", value: "" }}
            onValueChange={(value) => {
              const foundProp = property.find((p) => p.vehicle_id === value)
              setLog({
                ...log,
                vehicle_id: foundProp?.vehicle_id,
                start_km: foundProp?.actual_km || "",
                end_km: Number(foundProp?.actual_km) + Number(logDistance[log.type]),
              })
            }}
            items={appPickerVehiclesItem}
            disabled={disabled}
          />
          {!!(log.vehicle_id || log.licence_plate) && (
            <View>
              <AppPicker
                value={log.type}
                descriptionLabel="Jízda"
                placeholder={{ label: "Vyberte Jízdu", value: "" }}
                onValueChange={(value) => {
                  const newLog = {
                    ...log,
                    end_km: value === "NEW" ? "" : sanitizeNumber(log.start_km) + sanitizeNumber(logDistance[value]),
                    type: value,
                  }

                  if (value && value !== "NEW") {
                    newLog.from_to = logItems[value]
                  } else {
                    newLog.from_to = ""
                  }

                  setLog(newLog)
                }}
                items={appPickerLogItem}
                disabled={disabled}
              />
              {log.type === "NEW" && (
                <TextInput
                  error={!log.from_to}
                  style={DefaultTheme.input}
                  label={Validation.isNumberPositive(log.from_to) ? "Odkud - kam" : "Zadejte odkud - kam"}
                  onChangeText={(value) => setLog({ ...log, from_to: value })}
                  value={log.from_to.toString()}
                  disabled={disabled}
                />
              )}
              {!!log.type && (
                <View>
                  <TextInput
                    error={!Validation.isNumberPositive(log.start_km)}
                    style={DefaultTheme.input}
                    label={
                      Validation.isNumberPositive(log.start_km)
                        ? "Začátek jízdy - nájezd auta KM"
                        : "Zadejte začátek - jízdy nájezd auta KM"
                    }
                    keyboardType="number-pad"
                    onChangeText={(value) => setLog({ ...log, start_km: value ? sanitizeNumber(value) : "" })}
                    value={Number(log.start_km) ? log.start_km.toString() : "" }
                    disabled={disabled}
                  />
                  <TextInput
                    error={!Validation.isNumberPositive(log.end_km) || log.end_km < log.start_km}
                    style={DefaultTheme.input}
                    label={
                      Validation.isNumberPositive(log.end_km)
                        ? "Konec jízdy - nájezd auta KM"
                        : "Zadejte konec jízdy - nájezd auta KM"
                    }
                    keyboardType="number-pad"
                    onChangeText={(value) => setLog({ ...log, end_km: sanitizeNumber(value) || "" })}
                    value={log.end_km.toString()}
                    disabled={disabled}
                  />
                  <TextInput
                    style={DefaultTheme.input}
                    editable={false}
                    label={"Jízda v KM - rozdíl mezi koncem a začátkem KM"}
                    value={sanitizeNumber(Number(log.end_km) - Number(log.start_km)).toString()}
                    disabled={true}
                  />
                  <AppDatePicker
                    label="Datum jízdy"
                    webLabel={["Den jízdy", "Měsíc jízdy", "Rok jízdy"]}
                    value={log.date}
                    onConfirm={(date) => setLog({ ...log, date: date || new Date() })}
                    disabled={disabled}
                  />

                  <TouchableOpacity onPress={handleIncludesDoc} disabled={disabled}>
                    <TextInput
                      style={DefaultTheme.input}
                      label={log.document_id ? "Ubytovací doklad název" : "Vložte ubytovací doklad, pokud je potřeba"}
                      value={document ? document.name : ""}
                      disabled
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>
          )}
        </View>
      </ScrollView>
      <NextButton
        text="Uložit"
        onPress={handleSaveLog}
        disabled={
          !(
            log.end_km &&
            log.start_km &&
            (log.vehicle_id || log.licence_plate) &&
            log.date &&
            log.end_km >= log.start_km &&
            log.from_to &&
            log.end_km > 0 &&
            log.start_km > 0
          ) || disabled
        }
      />
    </Screen>
  )
}
