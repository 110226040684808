import React, { useEffect, useState, useRef } from "react"
import { Camera } from "expo-camera"
import * as MediaLibrary from "expo-media-library"
import { SafeAreaView, Text, View, StyleSheet, TouchableOpacity, Modal, Image, StatusBar, Platform } from "react-native"
import { Button, FAB } from "react-native-paper"
import { MaterialIcons, FontAwesome } from "@expo/vector-icons"
import Spinner from "react-native-loading-spinner-overlay"
import { actionEnum, scanTypeEnum, statusEnum } from "../../data/enums"
import { useStatus } from "../../providers/StatusProvider"
import { takePicture } from "../../actions/platformFeatureRequestor"

export default function CaptureImage({ navigation, route }) {
  const { setStatus, setMessage } = useStatus()
  const camRef = useRef(null)
  const [hasPermission, setHasPermission] = useState(null)
  const [captured, setCaptured] = useState(false)
  const action = route.params?.action
  const belongsTo = route.params?.belongsTo
  const uriList = route.params?.uriList || []
  const viewType = route.params?.viewType
  const docType = route.params?.docType
  const previousScreen = route.params?.previousScreen || "DashboardScreen"

  console.debug("CAPTURE IMAGE", route.params)

  useEffect(() => {
    ;(async () => {
      const { status } = await Camera.requestCameraPermissionsAsync()
      console.debug("CAMERA STATUS", status)
      setHasPermission(status === "granted")
    })()
  }, [])

  if (hasPermission === false || hasPermission === null) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Text>{hasPermission === false ? "Žádný přístup ke kameře" : "Čekání na přístup ke kameře"}</Text>
      </View>
    )
  }

  async function handleCapture() {
    try {
      const localUri = await takePicture(camRef, setCaptured)

      if (Platform.OS !== "web") {
        await MediaLibrary.saveToLibraryAsync(localUri)
      }

      if (route.params?.onCapture) {
        route.params?.onCapture(localUri)
      } else if (action === actionEnum.NEW)
        navigation.navigate("VerifyScan", {
          type: scanTypeEnum.IMAGE,
          uriPreview: localUri,
          uriList: [...uriList, localUri],
          viewType,
          isPreview: true,
          belongsTo,
          docType,
        })
      else if (action === actionEnum.EDIT) {
        route.params.uploadCapturedImage(localUri)
        navigation.goBack()
      } else {
        route.params.uploadCapturedImage(localUri)
        navigation.goBack()
      }
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.SUCCESS)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  return (
    <>
      <View style={styles.container}>
        <Camera style={styles.camera} type={Camera.Constants.Type.back} ref={camRef}>
          <View style={styles.buttonContainer}></View>
        </Camera>
        <Spinner visible={captured} overlayColor="rgba(0, 0, 0, 0.5)" />
      </View>
      <FAB color="#FFFFFF" style={styles.fabRight} icon="camera-enhance" onPress={handleCapture} />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  camera: {
    flex: 1,
  },
  button: {
    position: "absolute",
    bottom: 0,
    height: 70,
    width: "100%",
  },
  fabRight: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
  },
})
