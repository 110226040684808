import React from "react"
import { Platform } from "react-native"

import FullWidthImagePhone from "./FullWidthImagePhone"
import FullWidthImageWeb from "./FullWidthImageWeb"

export default function FullWidthImage({ uri, width, onLoadEnd, style }) {
  return Platform.OS === "web" ? (
    <FullWidthImageWeb uri={uri} width={width} onLoadEnd={onLoadEnd} style={style} />
  ) : (
    <FullWidthImagePhone uri={uri} onLoadEnd={onLoadEnd} style={style} />
  )
}
