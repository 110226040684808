import React from "react"
import { Text, View, StyleSheet, TouchableOpacity, Image } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import { AntDesign } from "@expo/vector-icons"

export default function FlatListSettingsItem({ icon, image, title, onPress, openable = true }) {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <View style={styles.iconWrapper}>
        {icon || (
          <Image
            source={image}
            resizeMode="contain"
            style={{ width: DefaultTheme.icons.menuSize + 5, height: DefaultTheme.icons.menuSize + 5 }}
          />
        )}
      </View>
      <Text style={{ fontSize: DefaultTheme.fonts.regular, marginRight: "auto" }}>{title}</Text>
      {openable && <AntDesign style={{ padding: 20 }} name="right" size={14} color="black" />}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: DefaultTheme.colors.white,
  },
  iconWrapper: {
    height: 50,
    width: 50,
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
  },
})
