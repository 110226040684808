import React from "react"
import { Platform, Text, View } from "react-native"
import { TouchableWithoutFeedback } from "react-native-gesture-handler"
import { MaterialCommunityIcons } from "@expo/vector-icons"

import DefaultTheme from "../themes/DefaultTheme"

export default function NavigationPicker({ description, label, onPress, style = {}, error = false, disabled = false }) {
  return (
    <TouchableWithoutFeedback onPress={onPress} disabled={disabled}>
      <View style={[{ marginBottom: 10 }, style]}>
        <View
          style={{
            height: 65,
            justifyContent: "center",
            backgroundColor: DefaultTheme.colors.white,
            marginHorizontal: DefaultTheme.margin.s,
          }}
        >
          {description && (
            <Text
              style={{
                position: "absolute",
                color: DefaultTheme.colors.placeholder,
                fontSize: 12,
                left: 22,
                top: 10,
              }}
            >
              {description}
            </Text>
          )}
          <Text
            style={[
              {
                fontSize: DefaultTheme.fonts.regular,
                marginHorizontal: DefaultTheme.margin.m,
              },
              error && { color: "rgb(176, 0, 32)" },
              description && { paddingTop: 18 },
            ]}
          >
            {label}
          </Text>
        </View>
        <View
          style={{
            marginHorizontal: DefaultTheme.margin.s,
            borderBottomWidth: 1,
            borderBottomColor: error ? "rgb(176, 0, 32)" : "rgb(128, 128, 128)",
          }}
        />
        <MaterialCommunityIcons
          name="chevron-right"
          size={20}
          color={error ? "rgb(176, 0, 32)" : "rgb(128, 128, 128)"}
          style={{ position: "absolute", right: Platform.OS === "web" ? 10 : 20, top: 24 }}
        />
      </View>
    </TouchableWithoutFeedback>
  )
}
