import React, { useState, useRef, useEffect } from "react"
import { View, Platform, Dimensions } from "react-native"
import Signature from "react-native-signature-canvas"
import SignatureCanvas from "react-signature-canvas"
import { FontAwesome } from "@expo/vector-icons"

import NextButton from "../components/NextButton"
import PrimButton from "../components/PrimButton"
import Screen from "../components/Screen"
import DefaultTheme from "../themes/DefaultTheme"

export default function SignatureScreen({ navigation, route }) {
  const [signature, setSignature] = useState(null)
  const signatureRef = useRef("")

  function TrashButton({ iconStyle, onPress, style }) {
    return (
      <PrimButton
        icon={<FontAwesome name="trash" size={18} color={DefaultTheme.colors.white} style={iconStyle || {}} />}
        style={[style, { backgroundColor: DefaultTheme.colors.danger }]}
        onPress={onPress}
        width={40}
      />
    )
  }

  return (
    <Screen>
      {Platform.OS === "web" ? (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <View>
            <SignatureCanvas
              penColor="black"
              backgroundColor="white"
              canvasProps={{ width: 600, height: 300 }}
              ref={signatureRef}
              onEnd={() => setSignature(signatureRef.current.getCanvas().toDataURL("image/png"))}
            />
            <TrashButton
              onPress={() => signatureRef.current?.clear()}
              style={{ position: "absolute", top: -20, right: -20 }}
            />
          </View>
        </View>
      ) : (
        <View style={[{ flex: 1, padding: 10 }, Platform.isPad && { alignItems: "center", justifyContent: "center" }]}>
          <View style={Platform.isPad ? { width: 700, height: 400 } : { flex: 1 }}>
            <Signature
              ref={signatureRef}
              rotated={!Platform.isPad}
              onEnd={() => signatureRef.current.readSignature()}
              onOK={setSignature}
              descriptionText=""
              webStyle={
                Platform.isPad &&
                `.m-signature-pad--footer
              .save {
                  display: none;
              }
              .clear {
                  display: none;
              }

            .m-signature-pad {
              width: "100%";
              height: "100%";
              margin-left: 0;
              margin-top: 0;
              margin-bottom: 0;
              padding-bottom: 0;
            }

            body {
              width: 700px;
              height: 400px;
            }
        `
              }
            />
            <TrashButton
              iconStyle={{ transform: [{ rotate: Platform.isPad ? "0deg" : "90deg" }] }}
              onPress={() => signatureRef.current?.clearSignature()}
              style={[{ position: "absolute", right: 20 }, Platform.isPad ? { top: 20 } : { bottom: 20 }]}
            />
          </View>
        </View>
      )}
      <NextButton text="Uložit" onPress={() => route.params.onSave(signature)} />
    </Screen>
  )
}
