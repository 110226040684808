import React, { useState } from "react"
import { TouchableOpacity, View } from "react-native"
import { Menu, Divider } from "react-native-paper"
import { MaterialCommunityIcons, Ionicons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"

export default function TasksOverviewHeaderMenu({ navigation }) {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <Menu
      visible={showMenu}
      onDismiss={() => setShowMenu(false)}
      anchor={
        <TouchableOpacity onPress={() => setShowMenu(true)} style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={DefaultTheme.icons.headerSizeRight}
            color={DefaultTheme.colors.headerSecondary}
          />
        </TouchableOpacity>
      }
    >
      <Menu.Item
        style={{ direction: "ltr" }}
        onPress={() => {
          setShowMenu(false), navigation.navigate("TasksBulkAction")
        }}
        title="Hromadná akce"
        icon={() => (
          <MaterialCommunityIcons
            name="checkbox-multiple-marked-outline"
            size={DefaultTheme.icons.menuSize}
            color={DefaultTheme.colors.primary}
          />
        )}
      />
    </Menu>
  )
}
