import React, { useEffect, useState } from "react"
import { View, Text, StyleSheet, Platform } from "react-native"
import NextButton from "../../components/NextButton"
import ApproveButtonsDocument from "../../components/ApproveButtonsDocument"
import DefaultTheme from "../../themes/DefaultTheme"
import { documentStateEnum, scanTypeEnum, userRoleEnum, statusEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"
import FSStoreContent from "../../firebase/FSStoreContent"
import Screen from "../../components/Screen"
import {
  changeDocumentOperationsActionValue,
  getDocumentApprovingInitialState,
  getDocumentState,
} from "../../utils/general"
import { useStatus } from "../../providers/StatusProvider"
import { isRowDisabled } from "../../utils/validation"
import DocumentImage from "../../components/DocumentImage"
import { useNavigation } from "@react-navigation/core"

export default function ApprovingAccountingDocumentDetail({ navigation, route }) {
  const { currentUser, projects, accountingDocuments } = useData()
  const [imageLoading, setImageLoading] = useState(route.params.document?.type === scanTypeEnum.IMAGE)
  const [document, setDocument] = useState(route.params.document || {})
  console.debug("AccountingDocumentDetail: " + JSON.stringify(document))

  useEffect(() => {
    ;(async () => {
      const tempDoc = await FSStoreContent.loadDocument(route.params.documentId || route.params.document.id)
      setDocument(tempDoc)
      if (!route.params.document?.id) {
        setImageLoading(tempDoc.type === scanTypeEnum.IMAGE)
      }
    })()
  }, [accountingDocuments])

  return (
    <Screen>
      {(imageLoading || document.filestore_uri_list?.length === 0) && (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <Text style={{ fontSize: DefaultTheme.fonts.regular }}>
            {document.filestore_uri_list?.length > 0
              ? "Načítám obrázek dokladu"
              : "Ještě nebyla nahrána všechna data dokladu"}
          </Text>
        </View>
      )}
      <View
        style={[
          { flex: 1, overflow: "hidden" },
          (imageLoading || document.filestore_uri_list?.length === 0) && { transform: [{ scale: 0 }], flex: 0 },
        ]}
      >
        {Object.keys(document).length > 0 && (
          <DocumentImage
            type={document.type}
            uriList={document.filestore_uri_list}
            onLoadEnd={() => setImageLoading(false)}
          />
        )}
      </View>

      {Object.keys(document).length > 0 && (
        <ApproveRowsWithSave document={document} projects={projects} currentUser={currentUser} />
      )}
    </Screen>
  )
}

function ApproveRowsWithSave({ document: tempDoc, projects, currentUser }) {
  const [document, setDocument] = useState(tempDoc) // own state inside in order to image not rerender on set state
  const { setTitle, setStatus, setMessage } = useStatus()
  const navigation = useNavigation()

  async function updateDocument() {
    try {
      if (
        document?.operations?.some(
          (operation) => operation.action_type === "STATE_UNIT_TOTAL" && operation.action_value === "ACCEPTED"
        ) &&
        document.unit_task_id
      ) {
        await FSStoreContent.markTaskAsDone(document.unit_task_id)
      }
      if (getDocumentState(document) !== documentStateEnum.PENDING && document.task_id) {
        await FSStoreContent.markTaskAsDone(document.task_id)
        if (document.unit_task_id) {
          await FSStoreContent.markTaskAsDone(document.unit_task_id)
        }
      }
      await FSStoreContent.updateAccountingDocument({
        ...document,
        locked: getDocumentState(document) === documentStateEnum.ACCEPTED,
      })

      navigation.goBack()
    } catch (error) {
      setTitle("Chyba při schvalování dokladu")
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      console.error(error)
      navigation.navigate("StatusScreen")
    }
  }

  return (
    <>
      <View
        style={[
          styles.approveRow,
          isRowDisabled("STATE_DOC_ACCEPTED", document, projects, currentUser) && styles.approveRowDisabled,
        ]}
      >
        <Text style={{ flex: 1, paddingRight: 10, fontWeight: "bold", flexWrap: "wrap" }}>
          Mohu poslat doklad do účetnictví?
        </Text>
        {Object.keys(document).length > 0 && (
          <ApproveButtonsDocument
            initialState={getDocumentApprovingInitialState("STATE_DOC_ACCEPTED", document)}
            action_type="STATE_DOC_ACCEPTED"
            changeOperationsActionValue={(action_type, value) =>
              setDocument(changeDocumentOperationsActionValue(action_type, value, document, currentUser))
            }
            disabled={isRowDisabled("STATE_DOC_ACCEPTED", document, projects, currentUser)}
          />
        )}
      </View>

      <View
        style={[
          styles.approveRow,
          isRowDisabled("STATE_UNIT_TOTAL", document, projects, currentUser) && styles.approveRowDisabled,
        ]}
      >
        <Text style={{ flex: 1, paddingRight: 10, fontWeight: "bold", flexWrap: "wrap" }}>
          Souhlasí položky na dokladu?
        </Text>
        {Object.keys(document).length > 0 && (
          <ApproveButtonsDocument
            initialState={getDocumentApprovingInitialState("STATE_UNIT_TOTAL", document)}
            action_type="STATE_UNIT_TOTAL"
            changeOperationsActionValue={(action_type, value) =>
              setDocument(changeDocumentOperationsActionValue(action_type, value, document, currentUser))
            }
            disabled={isRowDisabled("STATE_UNIT_TOTAL", document, projects, currentUser)}
          />
        )}
      </View>
      <NextButton
        text="Uložit"
        onPress={updateDocument}
        disabled={
          document.locked ||
          (isRowDisabled("STATE_UNIT_TOTAL", document, projects, currentUser) &&
            isRowDisabled("STATE_DOC_ACCEPTED", document, projects, currentUser))
        }
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
    width: "100%",
  },
  approveRow: {
    flexDirection: "row",
    paddingHorizontal: DefaultTheme.padding.s,
    alignItems: "center",
    paddingVertical: DefaultTheme.margin.s,
  },
  approveRowDisabled: {
    backgroundColor: DefaultTheme.colors.disabled,
    opacity: 0.5,
  },
  input: {
    width: "60%",
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginVertical: 5,
    paddingLeft: 10,
  },
})
