import React, { useEffect, useState } from "react"
import { FlatList } from "react-native"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import FlatListAccountingDocumentItem from "../../components/FlatLists/FlatListAccountingDocumentItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import Screen from "../../components/Screen"
import { documentViewTypeEnum, paymentEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"

export default function CashDeskMonthDetail({ navigation, route }) {
  const { currentUser, accountingDocuments } = useData()
  const user = route.params?.user || currentUser
  const [filteredAccDocs, setFilteredAccDocs] = useState([])

  useEffect(() => {
    setFilteredAccDocs(getFilteredDocs())
  }, [accountingDocuments])

  function getFilteredDocs() {
    const monthIndex = route.params?.monthIndex || 0
    let cashDeskYearMonth = new Date()
    cashDeskYearMonth.setMonth(cashDeskYearMonth.getMonth() - monthIndex)
    cashDeskYearMonth = `${cashDeskYearMonth.getFullYear()}_${cashDeskYearMonth.getMonth()}`

    return accountingDocuments.filter(
      (doc) =>
        `${doc.date.toDate().getFullYear()}_${doc.date.toDate().getMonth()}` === cashDeskYearMonth &&
        user.email === doc.created_by &&
        doc.payment !== paymentEnum.BANK_TRANSFER
    )
  }

  return (
    <Screen>
      {filteredAccDocs.length > 0 ? (
        <FlatList
          data={filteredAccDocs}
          renderItem={({ item }) => (
            <FlatListAccountingDocumentItem
              document={item}
              navigation={navigation}
              toScreen={{ screen: "CashDeskMonthDetail", params: route.params }}
              viewType={documentViewTypeEnum.CASH_DESK}
            />
          )}
          keyExtractor={(item) => item.id.toString()}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="V tomto měsíci zatím nejsou žádné doklady" />
      )}
    </Screen>
  )
}
