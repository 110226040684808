import React from "react"
import { Platform, StyleSheet, View, Text } from "react-native"
import { FontAwesome, Ionicons } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"
import { roleLabels } from "../../data/labels"
import { CheckBox } from "react-native-elements"
import { useData } from "../../providers/DataProvider"
import { useStatus } from "../../providers/StatusProvider"
import { actionEnum, projectStateEnum, statusEnum, userRoleEnum, userStateEnum } from "../../data/enums"
import { isAdmin, isExecutive, isTechnicalDirector } from "../../utils/validation"
import { getUserName } from "../../utils/general"
import PrimButton from "../PrimButton"
import { STATE_VIEW_PARAMS_USER } from "../../data/params"

export default function FlatListProjectPeopleItem({ user, navigation, project, setProject }) {
  const { users, currentUser } = useData()
  const { setStatus, setTitle, setAction } = useStatus()
  const hasEditPermission = isAdmin(currentUser) || isExecutive(currentUser) || isTechnicalDirector(currentUser)
  const disabled = !(hasEditPermission && project.state !== projectStateEnum.CLOSED)
  const userObject = users.find((u) => u.email === user.email)
  const userDisabled = userObject ? userObject?.disabled : true
  const userDeleted = userObject ? userObject?.state === userStateEnum.DELETED : false
  console.debug("userdis", userDisabled, disabled, userDeleted)

  function getUserProperty(userEmail, property) {
    const userRef = users.length > 0 ? users.find((user) => user.email === userEmail) : ""
    if (!userRef) return userRef
    if (userRef) return userRef[property] || ""
  }

  function deleteUser(userEmail) {
    console.debug("handleDelete: delete: " + userEmail)
    const projectUsers = project.users ? project.users : []
    const filteredProjectUsers = projectUsers.filter((user) => user !== getProjectUser(userEmail))
    console.debug("handleDelete: filteredProjectUsers:" + filteredProjectUsers.join(","))
    setProject({ ...project, users: filteredProjectUsers ?? [] })
  }

  // function handleDelete(userEmail) {
  //   setTitle(`Opravdu chcete smazat uživatele ${getUserName(users, userEmail)} ze projektu ${project.name}?`)
  //   setStatus(statusEnum.PROGRESS_DECIDE)
  //   navigation.navigate("StatusScreen")
  //   setAction({ type: actionEnum.DELETE_USER_FROM_PROJECT, params: { deleteUser: () => deleteUser(userEmail) } })
  // }

  function getProjectUser(email) {
    return project.users.filter((user) => user.email === email)[0]
  }
  console.debug("USER!'!!: ", user)
  function handleCheckBox(key, userEmail) {
    const roles = getProjectUser(userEmail).roles
    const newRoles = roles.includes(key) ? roles.filter((role) => role !== key) : roles.concat(key)

    setProject({
      ...project,
      users: project.users.map((user) => {
        if (user.email !== userEmail) return user
        return { ...user, roles: newRoles }
      }),
    })
  }

  return (
    <View
      style={[
        styles.card,
        {
          borderLeftColor: userDeleted
            ? DefaultTheme.colors.danger
            : userDisabled || disabled
            ? DefaultTheme.colors.disabled
            : DefaultTheme.colors.primary,
        },
      ]}
    >
      <Ionicons {...STATE_VIEW_PARAMS_USER[userDeleted ? "deleted" : userDisabled || disabled]} />
      <Text style={styles.userName}>{getUserProperty(user.email, "name")}</Text>
      <View style={[DefaultTheme.checkBoxesWrapper, styles.checkBoxesWrapper]}>
        {Object.keys(roleLabels).map((roleKey) => {
          if (
            !(
              roleKey === userRoleEnum.ADMIN ||
              roleKey === userRoleEnum.ACCOUNTANT ||
              roleKey === userRoleEnum.OFFICE_MANAGER
            )
          )
            return (
              <CheckBox
                containerStyle={DefaultTheme.checkBoxContainer}
                key={roleKey}
                size={30}
                onPress={() => handleCheckBox(roleKey, user.email)}
                title={roleLabels[roleKey]}
                checkedColor={disabled ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary}
                checked={user.roles.includes(roleKey)}
                disabled={disabled}
              />
            )
        })}
      </View>
      <PrimButton
        width={48}
        icon={<FontAwesome style={styles.deleteIcon} name="trash" color={DefaultTheme.colors.white} size={24} />}
        disabled={disabled}
        onPress={() => deleteUser(user.email)}
        style={{ backgroundColor: DefaultTheme.colors.danger }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  icon: {
    padding: 25,
    flex: 1,
  },
  card: {
    width: "100%",
    paddingLeft: 15,
    paddingRight: 10,
    backgroundColor: DefaultTheme.colors.white,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderLeftWidth: 10,
  },
  checkBoxesWrapper: {
    flex: 8,
  },
  userName: {
    paddingLeft: 10,
    flex: Platform.OS === "web" ? 1 : 2.5,
  },
  userDescriptionWrapper: {
    flex: 1,
  },
  icon: {
    padding: 25,
  },
})
