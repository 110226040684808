import React, { useState, useEffect, useRef } from "react"
import { Dimensions, Image, ScrollView, Platform, StyleSheet } from "react-native"
import Animated from "react-native-reanimated"

const { useCode, call, set, min, max, Value, divide, multiply, add, sub, cond, and, eq, greaterOrEq, lessOrEq } =
  Animated

export default function FullWidthImagePhone({ uri, onLoadEnd, style }) {
  if (Platform.OS !== "web") {
    const { PinchGestureHandler, PanGestureHandler, State } = require("react-native-gesture-handler")
    const {
      pinchGestureHandler,
      panGestureHandler,
      useValue,
      vec,
      transformOrigin,
    } = require("react-native-redash/lib/module/v1")
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
    const defaultImageWidth = useValue(0)
    const defaultImageHeight = useValue(0)
    const maxScale = useValue(1)
    const [viewboxSize, setViewboxSize] = useState({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    })
    useEffect(() => {
      if (uri) {
        Image.getSize(uri, (width, height) => {
          setImageSize({ width, height })
        })
      }
    }, [uri])
    useEffect(() => {
      if (viewboxSize.width > 0 && imageSize.width > 0) {
        const width = Math.min(viewboxSize.width * 0.8, imageSize.width)
        const height = Math.min(viewboxSize.width * 0.8, imageSize.width)
        const ratio = imageSize.width / imageSize.height
        if (width > height) {
          defaultImageWidth.setValue(width)
          defaultImageHeight.setValue(width / ratio)
          maxScale.setValue(imageSize.width / width)
        } else {
          defaultImageHeight.setValue(height)
          defaultImageWidth.setValue(height * ratio)
          maxScale.setValue(imageSize.height / height)
        }
      }
    }, [imageSize, viewboxSize])

    const {
      gestureHandler: pinchHandler,
      scale: pinchScale,
      focal: pinchFocal,
      state: pinchState,
    } = pinchGestureHandler()
    const scale = useValue(1)

    const panGestureRef = useRef()
    const { gestureHandler: panHandler, translation, state: panState } = panGestureHandler()
    const translateX = useValue(0)
    const translateY = useValue(0)

    useCode(() => {
      const withScaleOffset = (defaultValue, value, state, offset = new Value(1), fn = (v) => v) =>
        cond(eq(state, State.END), [set(offset, defaultValue), offset], fn(multiply(offset, value)))
      const withOffset = (defaultValue, value, state, fn = (v) => v, validatorFn = (_v) => true) => {
        const offset = new Value(0)
        return [
          cond(
            eq(state, State.END),
            [set(offset, defaultValue), defaultValue],
            cond(validatorFn(add(offset, fn(value))), add(offset, fn(value)), defaultValue)
          ),
        ]
      }
      return [
        set(
          scale,
          withScaleOffset(scale, pinchScale, pinchState, undefined, (v) => max(1, min(maxScale, v)))
        ),
        set(
          translateX,
          withOffset(
            translateX,
            translation.x,
            panState,
            (v) => divide(v, scale),
            (v) =>
              and(
                greaterOrEq(v, sub(0, multiply(defaultImageWidth, 0.5))),
                lessOrEq(v, multiply(defaultImageWidth, 0.5))
              )
          )
        ),
        set(
          translateY,
          withOffset(
            translateY,
            translation.y,
            panState,
            (v) => divide(v, scale),
            (v) =>
              and(
                greaterOrEq(v, sub(0, multiply(defaultImageHeight, 0.5))),
                lessOrEq(v, multiply(defaultImageHeight, 0.5))
              )
          )
        ),
      ]
    }, [])

    return (
      <PinchGestureHandler {...pinchHandler} waitFor={panGestureRef}>
        <Animated.View style={styles.box} onLayout={(event) => setViewboxSize(event.nativeEvent.layout)}>
          <PanGestureHandler {...panHandler} ref={panGestureRef} maxPointers={1}>
            <Animated.View style={styles.subBox}>
              <Animated.Image
                style={[
                  {
                    width: defaultImageWidth,
                    height: defaultImageHeight,
                  },
                  {
                    transform: [
                      { scaleX: scale, scaleY: scale },
                      { translateX, translateY },
                      style?.image ? style?.image : {},
                    ],
                  },
                  style,
                ]}
                source={{ uri }}
                onLoadEnd={onLoadEnd}
              />
            </Animated.View>
          </PanGestureHandler>
        </Animated.View>
      </PinchGestureHandler>
    )
  }
}

const styles = StyleSheet.create({
  box: {
    flex: 1,
  },
  subBox: { flex: 1, justifyContent: "center", alignItems: "center" },
})
