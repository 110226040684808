import AsyncStorageLib from "@react-native-async-storage/async-storage"
import * as SecureStore from "expo-secure-store"
import {Platform} from "react-native"


const loadItem = async (key) => {
  let item
  if (Platform.OS ==="web") {
    item = await AsyncStorageLib.getItem(key)
  } else {
    item = await SecureStore.getItemAsync(key)
  }
  return item
}

const saveItem = (key, value) => {
  return new Promise(async (resolve, reject) => {
    if (Platform.OS ==="web") {
       await AsyncStorageLib.setItem(key, value, (error) => {
         if (error) reject(error)
         resolve("ITEM_SET")
       })
    } else {
      await SecureStore.setItemAsync(key, value)
      resolve("ITEM_SET")
    }
  })
}





export { loadItem, saveItem }
