import React, { useEffect, useRef, useState } from "react"
import { View, StyleSheet, Platform } from "react-native"
import { WebView } from "react-native-webview"
import Constants from "expo-constants"

import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import NextButton from "../../components/NextButton"
import { bankServiceStateEnum, statusEnum } from "../../data/enums"
import { useStatus } from "../../providers/StatusProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import FSStoreContent from "../../firebase/FSStoreContent"
import { TextInput } from "react-native-paper"
import PrimButton from "../../components/PrimButton"
import { getKbBankRegisterSoftwareData } from "../../utils/backendUrls"

export default function KBBankRegisterSoftware({ navigation }) {
  const { setStatus, setMessage } = useStatus()
  const [bankSecret, setBankSecret] = useState({})
  const { firebaseConfig } = useData()
  console.debug("BANK SECRET", bankSecret)

  let URL = getKbBankRegisterSoftwareData(firebaseConfig).url
  console.debug("URL", URL)

  useEffect(() => {
    try {
      FSStoreContent.onSnapshotApiSecret(
        handleSecretOnSnap,
        getKbBankRegisterSoftwareData(firebaseConfig).domain
      )
    } catch (error) {
      console.error("Error on snapshot kb api secret: ", error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }, [])

  function handleSecretOnSnap({ oauth_client_id, oauth_client_secret }) {
    setBankSecret({ clientId: oauth_client_id, clientSecret: oauth_client_secret })
  }

  return (
    <Screen>
      {bankSecret.clientId && bankSecret.clientSecret ? (
        <View style={{ flex: 1, paddingTop: 10 }}>
          <TextInput disabled style={DefaultTheme.input} value={bankSecret.clientId} label="Client id" />
          <TextInput disabled style={DefaultTheme.input} value={bankSecret.clientSecret} label="Client secret" />
        </View>
      ) : Platform.OS === "web" ? (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <PrimButton
            width={200}
            height={70}
            text="Registrovat aplikaci"
            onPress={() => window.open(URL, "_blank")}
          />
        </View>
      ) : (
        <WebView
          useWebkit
          style={{ flex: 1 }}
          source={{ uri: URL }}
        />
      )}
    </Screen>
  )
}
