import React, { useEffect, useState } from "react"
import { Text, TouchableOpacity, View, StyleSheet } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import { monthNameLabels } from "../../data/labels"
import { MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons"
import {
  STATE_VIEW_PARAMS_ATTENDANCE_DAY,
  STATE_VIEW_PARAMS_MONTH,
  STATE_VIEW_PARAMS_USER_ATTENDANCE,
} from "../../data/params"
import {
  monthStateEnum,
  attendanceTypeEnum,
  holidayStateEnum,
  attendanceOperationTypeEnum,
  actionEnum,
  statusEnum,
} from "../../data/enums"
import { isAccountant, isAdmin, isExecutive, isOfficeManager } from "../../utils/validation"
import { useData } from "../../providers/DataProvider"
import { getAttendanceMonthClosingState, getDayDeclensionForm, getHolidayColor } from "../../utils/general"
import PrimButton from "../PrimButton"
import { useStatus } from "../../providers/StatusProvider"

export default function FlatListAttendanceMonthItem({
  navigation,
  userEmail,
  monthAttendences,
  monthIndex,
  isHeader = false,
  freeDaysCount,
  closingItems,
}) {
  const { currentUser } = useData()
  console.debug("ATTENDANCES: ", monthAttendences)
  const { setTitle, setStatus, setAction } = useStatus()

  const hasEditPermission =
    isAdmin(currentUser) ||
    isExecutive(currentUser) ||
    isAccountant(currentUser) ||
    isOfficeManager(currentUser) ||
    monthIndex <= 0
  //const isForeignUser = currentUser.email !== userEmail && hasEditPermission && monthIndex > 0
  const isForeignUser = currentUser.email !== userEmail && monthIndex > 0
  // if (isHeader) {
  //   return (
  //     <TouchableOpacity
  //       style={[
  //         styles.card,
  //         {
  //           borderLeftColor: STATE_VIEW_PARAMS_MONTH[monthStateEnum.CLOSED].color,
  //         },
  //       ]}
  //     >
  //       <MaterialCommunityIcons {...STATE_VIEW_PARAMS_USER_ATTENDANCE[true]} size={24} />
  //       <View style={{ flex: 1 }}>
  //         <Text style={styles.monthTitle}>{monthTitle}</Text>
  //       </View>
  //       <View style={{ flex: 2, alignItems: "flex-end", paddingHorizontal: 10 }}>
  //         <Text>{`dny v práci: ${sumWorkDays}`}</Text>
  //         <Text>{`dny na dovolené: ${sumHolidays}`}</Text>
  //       </View>
  //     </TouchableOpacity>
  //   )
  // }

  const today = new Date()
  const seasonDate = new Date(today.getFullYear(), today.getMonth() - monthIndex)
  const closingItemsIndex = `${seasonDate.getFullYear()}_${seasonDate.getMonth()}`
  const closedState =
    closingItems[closingItemsIndex]?.length > 0
      ? getAttendanceMonthClosingState(closingItems[closingItemsIndex])
      : isForeignUser || !hasEditPermission
      ? monthStateEnum.CLOSED
      : monthStateEnum.OPEN
  console.debug(
    "LENGTH :",
    closingItems[closingItemsIndex]?.length,
    "closedState: ",
    closedState,
    "seasonDate: ",
    seasonDate
  )
  const monthTitle = `${monthNameLabels[seasonDate.getMonth()]} ${seasonDate.getFullYear()}`

  const sumWorkDays = monthAttendences.reduce((a, c) => (c.attendance_type === attendanceTypeEnum.WORK ? a + 1 : a), 0)
  const sumHolidays = monthAttendences.reduce(
    (a, c) =>
      c.attendance_type === attendanceTypeEnum.HOLIDAY &&
      c.operations?.find((o) => o.action_type === attendanceOperationTypeEnum.HOLIDAY_APPROVE).action_value ===
        holidayStateEnum.ACCEPTED
        ? a + 1
        : a,
    0
  )
  const sumHomeOffice = monthAttendences.reduce((a, c) => c.attendance_type === attendanceTypeEnum.HOMEOFFICE ? a + 1 : a, 0)
  const sumSickDays = monthAttendences.reduce((a, c) => c.attendance_type === attendanceTypeEnum.SICKDAY ? a + 1 : a, 0)


  return (
    <TouchableOpacity
      style={[
        styles.card,
        {
          borderLeftColor: STATE_VIEW_PARAMS_MONTH[closedState].color,
        },
      ]}
      onPress={() =>
        navigation.navigate("AttendanceMonthDays", {
          monthTitle,
          monthIndex,
          userEmail,
          freeDaysCount,
          closedState,
        })
      }
    >
      <View style={{ alignItems: "center" }}>
        <MaterialCommunityIcons {...STATE_VIEW_PARAMS_USER_ATTENDANCE[closedState !== monthStateEnum.OPEN]} size={24} />
        {closedState === monthStateEnum.CLOSED && (
          <FontAwesome name="lock" color={DefaultTheme.colors.disabled} size={DefaultTheme.icons.flatlistSize} />
        )}
      </View>
      <View style={{ flex: 1 }}>
        <Text style={styles.monthTitle}>{monthTitle}</Text>
      </View>
      <View style={{ flex: 1.5, alignItems: "flex-end", paddingHorizontal: 10 }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text>{`${sumWorkDays} ${getDayDeclensionForm(sumWorkDays)} v `}</Text>
          <MaterialCommunityIcons
            {...STATE_VIEW_PARAMS_ATTENDANCE_DAY[attendanceTypeEnum.WORK]}
            color={DefaultTheme.colors.primary}
            size={DefaultTheme.icons.flatlistSize}
          />
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text>{`${sumHolidays} ${getDayDeclensionForm(sumHolidays)} na `}</Text>
          <MaterialCommunityIcons
            {...STATE_VIEW_PARAMS_ATTENDANCE_DAY[attendanceTypeEnum.HOLIDAY]}
            color={DefaultTheme.colors.primary}
            size={DefaultTheme.icons.flatlistSize}
          />
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text>{`${sumHomeOffice} ${getDayDeclensionForm(sumHomeOffice)} na `}</Text>
          <MaterialCommunityIcons
              {...STATE_VIEW_PARAMS_ATTENDANCE_DAY[attendanceTypeEnum.HOMEOFFICE]}
              color={DefaultTheme.colors.primary}
              size={DefaultTheme.icons.flatlistSize}
          />
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text>{`${sumSickDays} ${getDayDeclensionForm(sumSickDays)} na `}</Text>
          <MaterialCommunityIcons
              {...STATE_VIEW_PARAMS_ATTENDANCE_DAY[attendanceTypeEnum.SICKDAY]}
              color={DefaultTheme.colors.primary}
              size={DefaultTheme.icons.flatlistSize}
          />
        </View>
      </View>

      <View style={{ flex: 0.5, alignItems: "center" }}>
        {closedState === monthStateEnum.CLOSED ? (
          <PrimButton
            width={48}
            icon={<MaterialCommunityIcons name="lock-open-variant" color={DefaultTheme.colors.white} size={24} />}
            onPress={() => {
              setTitle(`Opravdu chcete otevřít docházku pro měsíc ${monthTitle}?`)
              setStatus(statusEnum.PROGRESS_DECIDE)
              setAction({
                type: actionEnum.ATTENDANCES_OPEN_MONTH,
                params: {
                  toScreen: "AttendanceMonths",
                  closingItems: closingItems,
                  closingItemsIndex,
                  updatedBy: currentUser.email,

                  user: userEmail,
                },
              })
              navigation.navigate("StatusScreen")
            }}
          />
        ) : (
          (userEmail === currentUser.email || hasEditPermission) && (
            <PrimButton
              width={48}
              disabled={closedState === monthStateEnum.CLOSED}
              icon={<MaterialCommunityIcons name="lock" color={DefaultTheme.colors.white} size={24} />}
              onPress={() => {
                setTitle(`Opravdu chcete uzavřít docházku pro měsíc ${monthTitle}?`)
                setStatus(statusEnum.PROGRESS_DECIDE)
                setAction({
                  type: actionEnum.ATTENDANCES_CLOSE_MONTH,
                  params: {
                    toScreen: "AttendanceMonths",
                    closingItems: closingItems,
                    closingItemsIndex,
                    updatedBy: currentUser.email,
                    user: userEmail,
                  },
                })
                navigation.navigate("StatusScreen")
              }}
            />
          )
        )}
      </View>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  card: {
    height: 100,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    paddingRight: 10,
    flexDirection: "row",
    backgroundColor: DefaultTheme.colors.white,
    borderLeftWidth: 10,
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
  name: {
    paddingLeft: 10,
    marginRight: "auto",
  },
  monthTitle: {
    paddingLeft: 10,
  },
})
