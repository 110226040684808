import React, { useEffect, useState } from "react"
import { FlatList } from "react-native"
import { useData } from "../../providers/DataProvider"
import Screen from "../../components/Screen"
import FlatListAccountingDocumentApproveItem from "../../components/FlatLists/FlatListAccountingDocumentApproveItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import { documentStateEnum, statusEnum, viewTypeEnum } from "../../data/enums"
import { getDocumentState } from "../../utils/general"
import NextButton from "../../components/NextButton"
import { useStatus } from "../../providers/StatusProvider"
import FSStoreContent from "../../firebase/FSStoreContent"

export default function ApprovingAccountingDocumentsBulkAction({ navigation }) {
  const { accountingDocuments } = useData()
  const { setTitle, setStatus, setMessage } = useStatus()
  const [filteredAccDocs, setFilteredAccDocs] = useState([])
  const [isDocsChanged, setIsDocsChanged] = useState(false)

  useEffect(() => {
    setFilteredAccDocs(accountingDocuments.filter((doc) => getDocumentState(doc) === documentStateEnum.PENDING))
  }, [accountingDocuments])

  async function handleAccDocsSave() {
    try {
      navigation.pop()
      setTitle("Schvalování dokladů")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      for (const doc of filteredAccDocs) {
        if (getDocumentState(doc) !== documentStateEnum.PENDING && doc.task_id) {
          await FSStoreContent.markTaskAsDone(doc.task_id)
        }
        await FSStoreContent.updateAccountingDocument({
          ...doc,
          locked: getDocumentState(doc) === documentStateEnum.ACCEPTED,
        })
      }

      setStatus(statusEnum.SUCCESS)
      setMessage("Všechny vybrané doklady byly schváleny úspěšně!")
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
  }

  return (
    <Screen>
      {filteredAccDocs.length > 0 ? (
        <FlatList
          data={filteredAccDocs}
          renderItem={({ item }) => (
            <FlatListAccountingDocumentApproveItem
              document={item}
              navigation={navigation}
              viewType={viewTypeEnum.BULK_ACTION}
              accDocs={filteredAccDocs}
              setAccDocs={setFilteredAccDocs}
              setIsDocsChanged={setIsDocsChanged}
            />
          )}
          keyExtractor={(item) => item.id.toString()}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="Nemáte žádné doklady ke schválení" />
      )}
      <NextButton text="Uložit" disabled={filteredAccDocs.length === 0 || !isDocsChanged} onPress={handleAccDocsSave} />
    </Screen>
  )
}
