import firebase from "firebase/compat/app"
import auth from "firebase/compat/auth"
import firestore from "firebase/compat/firestore"
import "firebase/compat/functions"
import "firebase/compat/database"
import storage from "firebase/compat/storage"

import Constants from "expo-constants"
import { environmentEnum } from "../data/enums"

const environment = Constants.manifest.extra.environment

const firebaseConfigDev = {
  apiKey: "AIzaSyCuLp6SedWwPc8b4h3foQiOJbPo4RpYG70",
  authDomain: "dasar-dev.firebaseapp.com",
  databaseURL: "https://dasar-dev-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dasar-dev",
  storageBucket: "dasar-dev.appspot.com",
  messagingSenderId: "987393111252",
  appId: "1:987393111252:web:14555dd13f1fa0e11c3741",
  measurementId: "G-6NDQ9900EP",
}

const firebaseConfigUat = {
  apiKey: "AIzaSyCFjTsKIRJwUGj_qvAQbbRjGTz8Pbd_We4",
  authDomain: "dasar-uat.firebaseapp.com",
  projectId: "dasar-uat",
  storageBucket: "dasar-uat.appspot.com",
  messagingSenderId: "774081843303",
  appId: "1:774081843303:web:26519cb52e082ad9eec4c2",
  measurementId: "G-M7X5V60E17",
  databaseURL: "https://dasar-uat-default-rtdb.europe-west1.firebasedatabase.app",
}

const firebaseConfigTest = {
  apiKey: "AIzaSyD3a9W4wgJCBvjwgbNj6SSvubf6MaK38Ck",
  authDomain: "dasar-test.firebaseapp.com",
  projectId: "dasar-test",
  storageBucket: "dasar-test.appspot.com",
  messagingSenderId: "319953659812",
  appId: "1:319953659812:web:bd20de49463ce5153d4331",
  measurementId: "G-RN504EGCG0",
  databaseURL: "https://dasar-test-default-rtdb.europe-west1.firebasedatabase.app",
}

const firebaseConfigProd = {
  apiKey: "AIzaSyDegXPTjjD2zGTsQGdsQt41F-eneawMNkA",
  authDomain: "dasar-prod.firebaseapp.com",
  projectId: "dasar-prod",
  storageBucket: "dasar-prod.appspot.com",
  messagingSenderId: "853038484335",
  appId: "1:853038484335:web:2f78a7ef7bbcb9fd3eb69a",
  measurementId: "G-TK6S9GVFTX",
  databaseURL: "https://dasar-prod-default-rtdb.europe-west1.firebasedatabase.app",
}

const firebaseConfigProdInvest = {
  apiKey: "AIzaSyAH-8PSQo3YJ0-ndyJMccydur0KbqARpkc",
  authDomain: "dasar-prod-1.firebaseapp.com",
  projectId: "dasar-prod-1",
  storageBucket: "dasar-prod-1.appspot.com",
  messagingSenderId: "793008963281",
  appId: "1:793008963281:web:9c486965d2dabddaf30ff0",
  measurementId: "G-ZVEDLMQPPE",
}

const firebaseConfigProdDevelopment = {
  apiKey: "AIzaSyCIKRgE9FGR1LTkQYSEEd31jH-2BfHbi8c",
  authDomain: "dasar-prod-development.firebaseapp.com",
  projectId: "dasar-prod-development",
  storageBucket: "dasar-prod-development.appspot.com",
  messagingSenderId: "597400285851",
  appId: "1:597400285851:web:c32f04ea7c199d63900c3e",
  measurementId: "G-9CDMV7NWKG",
}

const firebaseConfigEmu = {
  apiKey: "-------------------",
  authDomain: "http://localhost:9099",
  databaseURL: "http://localhost:9000?ns=demo-dasar-dev",
  projectId: "demo-dasar-dev",
  storageBucket: "default-bucket",
  messagingSenderId: "--------------",
  appId: "----------------------",
  measurementId: "-----------------",
}
const configNames = {
  prod: "DASAR PR",
  "prod-1": "DASAR Invest",
  "prod-development": "DASAR Development",
  dev: "DASAR Dev",
  test: "DASAR Test",
  uat: "DASAR UAT",
  emu: "DASAR EMU",
}

const configObj = {
  prod: firebaseConfigProd,
  "prod-1": firebaseConfigProdInvest,
  "prod-development": firebaseConfigProdDevelopment,
  dev: firebaseConfigDev,
  test: firebaseConfigTest,
  uat: firebaseConfigUat,
  emu: firebaseConfigEmu,
}

const defaultConfigs = {
  prod: {
    primary: "prod",
    secondary: "prod-1",
  },
  dev: {
    primary: "dev",
    secondary: "test",
  },
}

if (!firebase.apps.length) {
  if (environment === environmentEnum.dev) {
    console.log("DEV config")
    firebase.initializeApp(firebaseConfigDev)
  } else if (environment === environmentEnum.uat) {
    console.log("UAT config")
    firebase.initializeApp(firebaseConfigUat)
  } else if (environment === environmentEnum.test) {
    console.log("TEST config")
    firebase.initializeApp(firebaseConfigTest)
  } else if (environment === environmentEnum.prod) {
    console.log("PROD config")
    const app = firebase.initializeApp(firebaseConfigProd, environmentEnum.prod)
    console.log("PROD app:", app)
  } else if (environment === environmentEnum.emu) {
    // console.log("EMU DEVICE config") // I had to use my own ip
    // firebase.initializeApp({
    //   apiKey: "-------------------",
    //   authDomain: "http://195.22.50.44:9099",
    //   databaseURL: "http://195.22.50.44:9000?ns=dasar-dev-localhost",
    //   projectId: "dasar-dev-localhost",
    //   storageBucket: "default-bucket",
    //   messagingSenderId: "--------------",
    //   appId: "----------------------",
    //   measurementId: "-----------------",
    // })
    // firebase.firestore().useEmulator("localhost", 8080)
    // firebase.apps[0].auth().useEmulator("http://localhost:9099")
    // firebase.functions().useEmulator("localhost", 5001)
    // firebase.database().useEmulator("localhost", 9090)
    // firebase.storage().useEmulator("localhost", 9199)

    console.log("EMU config")
    firebase.initializeApp({
      apiKey: "-------------------",
      authDomain: "http://localhost:9099",
      databaseURL: "http://localhost:9000?ns=demo-dasar-dev",
      projectId: "demo-dasar-dev",
      storageBucket: "default-bucket",
      messagingSenderId: "--------------",
      appId: "----------------------",
      measurementId: "-----------------",
    })

    firebase.firestore().useEmulator("localhost", 8080)
    firebase.auth().useEmulator("http://localhost:9099")
    firebase.functions().useEmulator("localhost", 5001)
    firebase.database().useEmulator("localhost", 9090)
    firebase.storage().useEmulator("localhost", 9199)
  }
}

export {
  firebase,
  firebaseConfigDev,
  firebaseConfigProd,
  firebaseConfigUat,
  firebaseConfigTest,
  firebaseConfigProdInvest,
  firebaseConfigProdDevelopment,
  firebaseConfigEmu,
  configObj,
  configNames,
  defaultConfigs,
}
