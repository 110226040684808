import { firebase } from "../firebase/config"

export function removeDocumentUriAbstraction(document) {
  const filestore_uri = document.filestore_uri_list?.shift()
  const filestore_uri_extended = document.filestore_uri_list

  delete document.filestore_uri_list

  let tempDoc = {}
  if (document.abstracted_props?.includes("filestore_uri_extended") && filestore_uri_extended.length === 0) {
    tempDoc = { ...document, filestore_uri, filestore_uri_extended: firebase.firestore.FieldValue.delete() }
  } else if (filestore_uri_extended.length === 0) {
    tempDoc = { ...document, filestore_uri }
  } else {
    return { ...document, filestore_uri, filestore_uri_extended }
  }

  delete tempDoc.abstracted_props
  return tempDoc
}

export function createDocumentUriAbstraction(document) {
  const filestore_uri_list =
    document.filestore_uri_extended?.length > 0
      ? [document.filestore_uri, ...document.filestore_uri_extended]
      : document.filestore_uri
      ? [document.filestore_uri]
      : []

  if (document.filestore_uri_extended?.length > 0) {
    delete document.filestore_uri
    delete document.filestore_uri_extended
    document.abstracted_props = ["filestore_uri", "filestore_uri_extended"]
  } else {
    delete document.filestore_uri
    document.abstracted_props = ["filestore_uri"]
  }

  return { ...document, filestore_uri_list }
}
