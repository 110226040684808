import React, { useEffect, useRef, useState } from "react"
import * as Notifications from "expo-notifications"
import { firebase } from "../firebase/config"
import { View, Platform } from "react-native"
import { useData } from "../providers/DataProvider"
import { useStatus } from "../providers/StatusProvider"
import { handleConfigSwitch } from "../utils/general"
import Constants from "expo-constants"

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
})

export default function Notification({ navigation }) {
  const [expoPushToken, setExpoPushToken] = useState("")
  const [notification, setNotification] = useState(false)
  const notificationListener = useRef()
  const responseListener = useRef()
  const { setStatus } = useStatus()
  const { firebaseConfig, setFirebaseConfig, setRelogWith } = useData()

  async function uploadPushToken(token) {
    try {
      await firebase.apps[0]
        .firestore()
        .collection("users")
        .doc(firebase.apps[0].auth().currentUser.email)
        .set({ push_token: token }, { merge: true })
      console.debug("user push token is uploaded")
    } catch (err) {
      console.error("error push token is not uploaded")
    }
  }

  useEffect(() => {
    if (Platform.OS !== "web") {
      registerForPushNotificationsAsync().then((token) => {
        setExpoPushToken(token)
        uploadPushToken(token)
      })
      console.debug(expoPushToken)

      notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
        setNotification(notification)
      })

      responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
        console.debug("Received Notification Response", response)
        const data = response.notification.request.content.data

        // TODO notification view component with redirection on collection/item
        // data in notification payload are in format data: { reference: '/tasks/<taskId>' }
        // onclick on notification is opened screen tasks with taskId
        // console.debug("notification data: ".toUpperCase(), data)
        console.debug("Notification data: ", data)
        if (data.link.includes("accountingDocuments")) {
          const accountingDocumentId = data.link.split("/")[1]

          if (data?.env === firebaseConfig) {
            navigation.navigate("ApprovingAccountingDocumentDetail", { documentId: accountingDocumentId })
          } else {
            setRelogWith({
              toScreen: "ApprovingAccountingDocumentDetail",
              routeParams: { documentId: accountingDocumentId },
              configId: data.env,
            })
            handleConfigSwitch(
              setStatus,
              firebaseConfig,
              setFirebaseConfig,
              navigation,
              Constants.manifest.extra.environment
            )
          }
        } else {
          if (data.env === firebaseConfig) {
            navigation.navigate("TasksOverview")
          } else {
            setRelogWith({
              toScreen: "TaskOverview",
              routeParams: {},
              configId: data.env,
            })
            handleConfigSwitch(
              setStatus,
              firebaseConfig,
              setFirebaseConfig,
              navigation,
              Constants.manifest.extra.environment
            )
          }
        }
      })

      return () => {
        Notifications.removeNotificationSubscription(notificationListener)
        Notifications.removeNotificationSubscription(responseListener)
      }
    }
  }, [])

  return (
    <View />
    // <View>
    //     <Text>Your expo push token: {expoPushToken}</Text>
    //     <Text>Title: {notification?.request?.content?.title} </Text>
    //     <Text>Body: {notification?.request?.content?.body}</Text>
    //     <Text>Data: {JSON.stringify(notification?.request?.content?.data)}</Text>
    //     <Button
    //         title="Press to Send Notification"
    //         onPress={async () => {
    //             await sendPushNotification(expoPushToken);
    //         }}
    //     />
    // </View>
  )
}

// Can use this function below, OR use Expo's Push Notification Tool-> https://expo.io/notifications
async function sendPushNotification(expoPushToken, task) {
  console.debug(task)

  // const message = {
  //     to: expoPushToken,
  //     sound: 'default',
  //     title: 'Original Title',
  //     body: 'And here is the body!',
  //     data: { reference: '/tasks/1' },
  // };

  // await fetch('https://exp.host/--/api/v2/push/send', {
  //     method: 'POST',
  //     headers: {
  //         Accept: 'application/json',
  //         'Accept-encoding': 'gzip, deflate',
  //         'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(message),
  // });
}

async function registerForPushNotificationsAsync() {
  let token

  if (Platform.OS !== "web") {
    const { status: existingStatus } = await Notifications.getPermissionsAsync()
    let finalStatus = existingStatus

    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync()
      finalStatus = status
    }

    if (finalStatus !== "granted") {
      console.error("Failed to get push token for push notifications")
      return
    }

    token = (await Notifications.getExpoPushTokenAsync()).data
    console.debug(token)
  } else {
    console.error("You have to use physical device for push notifications")
  }

  if (Platform.OS === "android") {
    await Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    })
  }

  return token
}
