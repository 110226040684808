import React, { createRef } from "react"
import { Text, TouchableOpacity, SafeAreaView, Platform, View } from "react-native"
import { MaterialIcons } from "@expo/vector-icons"
import Constants from "expo-constants"
import DefaultTheme from "../themes/DefaultTheme"
import { captureScreen } from "react-native-view-shot"
import { useData } from "../providers/DataProvider"
import FSStoreContent from "../firebase/FSStoreContent"
import { checkAppVersion } from "../utils/general"
import { useStatus } from "../providers/StatusProvider"
import { statusEnum, versionCheckTypeEnum } from "../data/enums"
import { configNames } from "../firebase/config"

export default function AppReportIssueComponent({ navigation }) {
  const { setShowAlert, setAlertProgress, setStatus, setMessage } = useStatus()
  const { currentUser, setAlertData, firebaseConfig, backendDeployment } = useData()

  async function handleReportIssue() {
    const resultUri = await captureScreen({
      result: "tmpfile",
      quality: 1,
      format: "png",
    })
    navigation.navigate("ReportIssueScreen", { uri: resultUri })
  }

  return (
    <SafeAreaView
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <MaterialIcons
          name="system-update"
          size={24}
          color={DefaultTheme.colors.primary}
          style={{ padding: 10 }}
          onPress={() => {
            try {
              FSStoreContent.appVersionGet((updateData) =>
                checkAppVersion(updateData, setShowAlert, setAlertData, versionCheckTypeEnum.MANUAL, setAlertProgress)
              )
            } catch (error) {
              console.error(error)
              setStatus(statusEnum.ERROR)
              setMessage(error.message)
              navigation.navigate("StatusScreen")
            }
          }}
        />

        <View>
          <View style={{ flexDirection: "row" }}>
            <Text>{`v${Constants.manifest.version}`}</Text>
            <Text
              style={{
                color:
                  Constants.manifest.extra.commitHash?.substring(0, 8) === backendDeployment?.substring(0, 8)
                    ? DefaultTheme.colors.primary
                    : DefaultTheme.colors.danger,
              }}
            >
              {Constants.manifest.extra.commitHash?.substring(0, 8) === backendDeployment?.substring(0, 8)
                ? ` - ${Constants.manifest.extra.commitHash?.substring(0, 8)}`
                : ` - ${Constants.manifest.extra.commitHash?.substring(0, 8)}/${backendDeployment?.substring(0, 8)} `}
            </Text>
            <Text>{`|${Constants.manifest.extra.environment}| - |${configNames[firebaseConfig]}|`}</Text>
          </View>
          <Text>{currentUser.email}</Text>
        </View>
      </View>
      <TouchableOpacity
        style={{ flexDirection: "row", alignItems: "center", paddingRight: 10 }}
        onPress={handleReportIssue}
      >
        <Text>Nahlásit</Text>
        <MaterialIcons name="report-problem" size={24} color={DefaultTheme.colors.primary} />
      </TouchableOpacity>
    </SafeAreaView>
  )
}
