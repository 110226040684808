import React from "react"
import { TouchableHighlight } from "react-native"
import { MaterialCommunityIcons } from "@expo/vector-icons"

import DefaultTheme from "../themes/DefaultTheme"

export default function CloseButton({ onPress, top = 0, right = -10, disabled = false }) {
  return (
    <TouchableHighlight
      disabled={disabled}
      underlayColor={DefaultTheme.colors.primary}
      onPress={onPress}
      style={{
        position: "absolute",
        top,
        right,
        backgroundColor: "white",
        width: 25,
        height: 25,
        justifyContent: "center",
        alignItems: "center",
        borderWidth: 1,
        borderColor: DefaultTheme.colors.light,
        borderRadius: 15,
      }}
    >
      <MaterialCommunityIcons name="close" size={18} />
    </TouchableHighlight>
  )
}
