import React, { useState } from "react"
import { Text, TouchableOpacity, View } from "react-native"
import { TextInput } from "react-native-paper"
import NextButton from "../../components/NextButton"
import Screen from "../../components/Screen"
import FSStoreContent from "../../firebase/FSStoreContent"
import DefaultTheme from "../../themes/DefaultTheme"
import { useStatus } from "../../providers/StatusProvider"
import { statusEnum } from "../../data/enums"

export default function PredefinedLogDetail({ route, navigation }) {
  const { setStatus, setMessage, setTitle } = useStatus()

  const item = route.params?.item || {}
  const [itemState, setItemState] = useState(
    { ...item, active: item.hasOwnProperty("active") ? item.active : true } || { distance: 0, label: "", active: true }
  )

  const isButtonDisabled = () => {
    return !(!isNaN(itemState?.distance) && Number(itemState?.distance) > 0 && itemState?.label?.length > 0)
  }

  const changeProp = (prop, value) => {
    setItemState({ ...itemState, [prop]: value })
  }

  const handleSave = async () => {
    try {
      setTitle("Ukládám šablonu jízdy")
      navigation.navigate("StatusScreen")
      await FSStoreContent.setPredefinedLogs(itemState)
      setStatus(statusEnum.SUCCESS)
      navigation.pop()
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
  }

  return (
    <Screen>
      <TextInput
        style={DefaultTheme.input}
        value={itemState.label}
        placeholder="Zadejte název jízdy"
        onChangeText={(text) => changeProp("label", text)}
        keyboardType="default"
        error={!itemState.label}
        label="Název jízdy"
      />
      <TextInput
        style={DefaultTheme.input}
        value={itemState?.distance?.toString() || ""}
        placeholder="Zadejte vzdálenost"
        onChangeText={(text) => changeProp("distance", text)}
        keyboardType="numeric"
        error={!itemState.distance || isNaN(itemState.distance) || !(Number(itemState.distance) > 0)}
        label="Vzdálenost v km"
      />
      <TouchableOpacity
        onPress={() => changeProp("active", !itemState.active)}
        style={{
          marginLeft: 10,
          justifyContent: "center",
          alingItems: "center",
          backgroundColor: itemState.active ? DefaultTheme.colors.success : DefaultTheme.colors.danger,
          width: 100,
          height: 50,
          borderRadius: 5,
          padding: 10,
        }}
      >
        <Text style={{ textAlign: "center" }}>{itemState.active ? "Aktivní" : "Neaktivní"}</Text>
      </TouchableOpacity>
      <Text style={{ marginLeft: 10 }}>
        {"Neaktivní jízdy se nebudou ukazovat při tvorbě nové jízdy, budou se však ukazovat u starých záznamů"}
      </Text>
      <NextButton
        text="Uložit"
        onPress={handleSave}
        disabled={isButtonDisabled()}
        style={{ bottom: 0, position: "absolute" }}
      />
    </Screen>
  )
}
