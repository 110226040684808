import React from "react"
import { Text, TouchableOpacity, View, StyleSheet } from "react-native"
import { STATE_VIEW_PARAMS_USER, STATE_VIEW_PARAMS_USER_CASH_DESK } from "../../data/params"
import DefaultTheme from "../../themes/DefaultTheme"
import { MaterialCommunityIcons, Ionicons, FontAwesome5 } from "@expo/vector-icons"
import { useData } from "../../providers/DataProvider"
import { paymentEnum } from "../../data/enums"

export default function FlatListCashDeskOverviewItem({ navigation, user }) {
  const { accountingDocuments } = useData()
  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate("CashDeskMonths", {
          user: user,
          title: `Pokladna ${user.name}`,
        })
      }
      style={[styles.card, { borderLeftColor: STATE_VIEW_PARAMS_USER_CASH_DESK[user.disabled].color }]}
    >
      <View style={{ paddingRight: 15 }}>
        <Ionicons {...STATE_VIEW_PARAMS_USER[user.disabled]} style={{ paddingLeft: 1 }} />
        <MaterialCommunityIcons
          {...STATE_VIEW_PARAMS_USER_CASH_DESK[user.disabled]}
          size={DefaultTheme.icons.flatlistSize}
        />
      </View>
      <View style={{ flex: 1 }}>
        <Text>{user.name}</Text>
        <Text>{user.email}</Text>
      </View>
      <View style={{ flex: 1, justifyContent: "flex-end", alignItems: "center", flexDirection: "row" }}>
        <Text style={{ paddingRight: 5 }}>
          {`${
            accountingDocuments.filter(
              (document) => document.created_by === user.email && document.payment !== paymentEnum.BANK_TRANSFER
            ).length
          } ks`}
        </Text>
        <FontAwesome5
          name="file-invoice-dollar"
          color={user.disabled ? DefaultTheme.colors.disabled : DefaultTheme.colors.primary}
          size={DefaultTheme.icons.flatlistSize}
        />
      </View>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  card: {
    height: 100,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    paddingRight: 10,
    flexDirection: "row",
    borderLeftWidth: 10,
    backgroundColor: DefaultTheme.colors.white,
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
})
