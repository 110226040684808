import React, { useEffect, useState } from "react"
import { FlatList, Text, StyleSheet } from "react-native"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import FlatListPremiumOverviewItem from "../../components/FlatLists/FlatListPremiumOverviewItem"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import Constants from "expo-constants"
import { environmentEnum, userRoleEnum, userStateEnum } from "../../data/enums"

export default function PremiumOverview({ navigation, route }) {
  const { users } = useData()
  const [filteredUsers, setFilteredUsers] = useState([])

  useEffect(() => {
    const activeUsers = users.filter(
      (u) => !u.disabled && u.state !== userStateEnum.DELETED && !u.roles.includes(userRoleEnum.INVESTOR)
    )
    if (Constants.manifest.extra.environment !== environmentEnum.dev) {
      setFilteredUsers(
        activeUsers.filter(
          (u) =>
            u.roles.includes(userRoleEnum.CONSTRUCTION_STAFF) ||
            u.roles.includes(userRoleEnum.CONSTRUCTION_LEAD) ||
            u.roles.includes(userRoleEnum.PROJECT_MANAGER)
        )
      )
    } else {
      setFilteredUsers(activeUsers)
    }
  }, [users])

  return (
    <Screen>
      {filteredUsers.length > 0 ? (
        <FlatList
          data={filteredUsers}
          renderItem={({ item }) => <FlatListPremiumOverviewItem navigation={navigation} user={item} />}
          keyExtractor={(item) => item.email.toString()}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="Neexistují žádní uživatelé" />
      )}
    </Screen>
  )
}
