import React, { useState } from "react"
import { StyleSheet, Text, View } from "react-native"
import { exportAttendance } from "../../actions/headerItemActions"
import AppDatePicker from "../../components/AppDatePicker"
import DateIntervalButtons from "../../components/DateIntervalButtons"
import NextButton from "../../components/NextButton"

import Screen from "../../components/Screen"
import { statusEnum } from "../../data/enums"
import { useData } from "../../providers/DataProvider"
import { useStatus } from "../../providers/StatusProvider"
import DefaultTheme from "../../themes/DefaultTheme"

export default function AttendanceExport({ navigation }) {
  const { setTitle, setStatus, setMessage } = useStatus()
  const today = new Date()
  const [dateInterval, setDateInterval] = useState({
    from: new Date(today.getFullYear(), today.getMonth() - 1, 1),
    to: new Date(today.getFullYear(), today.getMonth(), 0),
  })

  const { firebaseConfig } = useData()

  async function handleExportLogBookVehicles() {
    try {
      setTitle("Generuji docházku do CSV souboru")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      navigation.navigate("StatusScreen")

      const { message, status: exportStatus } = await exportAttendance(dateInterval, firebaseConfig)

      console.debug("EXPORTED STATUS", exportStatus)
      setStatus(exportStatus)
      setMessage(message)
      navigation.pop()
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
    }
  }

  return (
    <Screen>
      <DateIntervalButtons dateInterval={dateInterval} fullScreen setDateInterval={setDateInterval} />
      <NextButton
        text="Exportovat"
        onPress={handleExportLogBookVehicles}
        disabled={dateInterval.from > dateInterval.to}
      />
    </Screen>
  )
}
