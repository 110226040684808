import React, { useEffect, useState } from "react"
import { ActivityIndicator, Text, View } from "react-native"
import FSStoreContent from "../../firebase/FSStoreContent"
import { useData } from "../../providers/DataProvider"
import Screen from "../../components/Screen"
import FlatListTriviDocumentItem from "../../components/FlatLists/FlatListTriviDocumentItem"
import AppPicker from "../../components/AppPicker"
import CurrencyFormatter from "../../components/CurrencyFormatter"

export default function TriviScreen({ navigation, route }) {
  console.log("route: " + JSON.stringify(route))

  const { projects } = useData()

  const projectLabels = projects.map((el) => {
    return { value: el.id.toString(), label: `${el.id.toString()} - ${el.name.toString()}` }
  })

  const [projectId, setProjectId] = useState(route?.params?.projectId ?? null)
  console.log("projectId " + projectId)

  const [triviDocs, setTriviDocs] = useState()

  const [stat, setStat] = useState()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (projectId) {
      console.log("TriviScreen loading projectId: " + projectId)
      ;(async () => {
        setIsLoading(true)
        const triviDocs = await FSStoreContent.loadTriviDocsByProject(projectId)
        setTriviDocs(triviDocs)
        setIsLoading(false)
      })()
    }
  }, [projectId, route?.params?.refresh])

  useEffect(() => {
    if (triviDocs) setStat(getStats(triviDocs))
  }, [triviDocs])

  function getStats(triviDocs) {
    const syncIds = triviDocs.map(doc => doc.syncId).filter((x, i, a) => a.indexOf(x) === i)
    const syncIdsStats = syncIds.map(
      syncId => ({ syncId,
        syncIdTotalIncome: triviDocs.filter(doc => doc.syncId === syncId && doc.direction === 1).reduce((a, c) => a + c.totalVatExcl, 0),
        syncIdTotalOutcome: triviDocs.filter(doc => doc.syncId === syncId && doc.direction === 0).reduce((a, c) => a + c.totalVatExcl, 0)
      })
    )
    return syncIdsStats
  }

  function handleProjectChange(id) {
    console.log("handleProjectChange: ", id)
    let project = projects.find((value) => id === value.id)
    console.log("handleProjectChange: project: ", project)
    if (project) setProjectId(id)
  }

  return (
    <Screen style={{ flex: 1 }}>
      <AppPicker
        descriptionLabel={"Projekty"}
        disabled={false}
        items={projectLabels}
        onValueChange={handleProjectChange}
        placeholder={"Musíte vybrat projekt"}
      />
      {stat?.map(stat =>
        <View><Text>
          syncId: {stat?.syncId} =>
          +{CurrencyFormatter(stat?.syncIdTotalIncome, "CZK", 0)}
          -{CurrencyFormatter(stat?.syncIdTotalOutcome, "CZK", 0)}
        </Text></View>
      )
      }

      {isLoading ? (
        <ActivityIndicator />
      ) : (
          triviDocs?.map(triviDoc =>
            <FlatListTriviDocumentItem projectId={projectId} document={triviDoc} />
          )
      )}
    </Screen>
  )
}
