import React, { useEffect, useState } from "react"
import { FlatList, Text, View } from "react-native"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import FlatListPremiumUserProjectsItem from "../../components/FlatLists/FlatListPremiumUserProjectsItem"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import { filterUserProjects } from "../../utils/general"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"

export default function PremiumUserProjects({ route, navigation }) {
  const { projects } = useData()
  const [filteredUserProjects, setFilteredUserProjects] = useState([])

  useEffect(() => {
    setFilteredUserProjects(filterUserProjects(projects, route.params.user))
  }, [projects])

  return (
    <Screen>
      {filteredUserProjects.length > 0 ? (
        <FlatList
          data={filteredUserProjects}
          renderItem={({ item }) => (
            <FlatListPremiumUserProjectsItem
              navigation={navigation}
              project={item}
              user={route.params.user}
              task={route.params?.task || null}
            />
          )}
          keyExtractor={(item) => item.id.toString()}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="Nejste přiřazen k žádnému projektu" />
      )}
    </Screen>
  )
}
