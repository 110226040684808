import React, { useEffect, useState, useRef, createRef } from "react"
import {
  View,
  Text,
  StyleSheet,
  BackHandler,
  Dimensions,
  ScrollView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native"
import ProgressBar from "react-native-progress/Bar"
import { FontAwesome, AntDesign } from "@expo/vector-icons"
import "../firebase/config"
import NextButton from "../components/NextButton"
import DefaultTheme from "../themes/DefaultTheme"
import { useStatus } from "../providers/StatusProvider"
import { STATE_VIEW_PARAMS_DOCUMENT } from "../data/params"
import StatusDecideActionButtons from "../components/StatusDecideActionButtons"
import AppReportIssueComponent from "../components/AppReportIssueComponent"
import RotateIcon from "../animations/RotateIcon"
import AppReportIssueComponentWeb from "../components/AppReportIssueComponentWeb"
import StatusDecideActionButtonsSimple from "../components/StatusDecideActionButtonsSimple"
import { firebase } from "../firebase/config"

const windowWidth = Dimensions.get("window").width
const windowHeight = Dimensions.get("window").height

export default function StatusScreen({ navigation, viewType }) {
  const {
    status,
    setStatus,
    setTitle,
    setMessage,
    setPercentUploaded,
    statusEnum,
    title,
    message,
    percentUploaded,
    action,
    setAction,
    setToScreen,
    toScreen,
    navStateMessage,
    setNavStateMessage,
  } = useStatus()

  const imageRef = createRef(null)
  const middleContentHeight = 150

  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", handleBackButton)

    return () => {
      BackHandler.removeEventListener("hardwareBackPress", handleBackButton)
      setStatus(statusEnum.NONE)
      setTitle("")
      setMessage("")
      setPercentUploaded(0)
      setAction({ type: {}, params: {} })
      setToScreen("")
    }
  }, [])

  function handleBackButton() {
    return true
  }

  const handlePress = () => {
    if (typeof toScreen === "string" && toScreen !== "") {
      navigation.navigate(toScreen)
    } else if (typeof toScreen === "object") {
      navigation.navigate(toScreen.screen, toScreen.params)
    } else {
      navigation.pop()
    }
  }

  return (
    <View style={{ flex: 1 }} ref={imageRef}>
      {Platform.OS === "web" ? (
        <AppReportIssueComponentWeb navigation={navigation} imageRef={imageRef} />
      ) : (
        <AppReportIssueComponent navigation={navigation} />
      )}
      <View style={[styles.overlayContainer]}>
        <View
          style={[
            DefaultTheme.safeTop,
            {
              flex: 1,
              justifyContent: "flex-start",
              alignItems: "center",
              paddingTop: windowHeight / 2 - middleContentHeight,
            },
          ]}
        >
          <Text style={{ fontSize: DefaultTheme.fonts.big, textAlign: "center", padding: DefaultTheme.padding.s }}>
            {title}
          </Text>

          <View
            style={{
              height:
                status === statusEnum.PROGRESS_DECIDE || status === statusEnum.PROGRESS_DECIDE_SIMPLE
                  ? 0
                  : middleContentHeight,
              justifyContent: "center",
              paddingVertical: DefaultTheme.padding.m,
            }}
          >
            {status === statusEnum.PROGRESS_DETERMINED && (
              <View>
                <Text style={{ fontWeight: "bold", alignSelf: "center", fontSize: DefaultTheme.fonts.big }}>
                  {percentUploaded} %
                </Text>
                <ProgressBar progress={percentUploaded / 100} width={250} height={25} />
              </View>
            )}

            {status === statusEnum.PROGRESS_UNDETERMINED ||
            status === statusEnum.PROGRESS_UNDETERMINED_CANCELABLE ||
            status === statusEnum.REGISTER ? (
              <ActivityIndicator />
            ) : (
              // <RotateIcon
              //   width={50}
              //   icon={<AntDesign name="loading1" size={50} color={DefaultTheme.colors.primary} />}
              // />
              <></>
            )}
            {(status === statusEnum.SUCCESS || status === statusEnum.ERROR) && (
              <FontAwesome {...STATE_VIEW_PARAMS_DOCUMENT[status]} size={60} />
            )}
          </View>
          {status === statusEnum.PROGRESS_DECIDE && (
            <StatusDecideActionButtons
              navigation={navigation}
              setStatus={setStatus}
              action={action}
              setMessage={setMessage}
              setToScreen={setToScreen}
            />
          )}
          {status === statusEnum.PROGRESS_DECIDE_SIMPLE && <StatusDecideActionButtonsSimple />}

          <ScrollView>
            <Text style={{ fontSize: 20, textAlign: "center", paddingHorizontal: 15 }}>{message}</Text>
            {/*<Text style={{ fontSize: 20, textAlign: "center", paddingHorizontal: 15 }}>{navStateMessage}</Text>*/}
          </ScrollView>
        </View>
        {title === "Načítám data aplikace" && (
          <TouchableOpacity
            onPress={() => {
              firebase.apps[0].auth().signOut()
              navigation.pop()
            }}
          >
            <Text style={{ fontWeight: "bold", color: "red" }}>Nouzové odhlášení</Text>
          </TouchableOpacity>
        )}
        <NextButton
          visible={
            status === statusEnum.SUCCESS ||
            status === statusEnum.ERROR ||
            status === statusEnum.PROGRESS_UNDETERMINED_CANCELABLE
          }
          disabled={
            !(
              status === statusEnum.SUCCESS ||
              status === statusEnum.ERROR ||
              status === statusEnum.PROGRESS_UNDETERMINED_CANCELABLE
            )
          }
          text={status === statusEnum.PROGRESS_UNDETERMINED_CANCELABLE ? "Zrušit" : "OK"}
          onPress={handlePress}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  overlayContainer: {
    zIndex: 999,
    flex: 1,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
  },
})
