import React, { useEffect, useState, memo } from "react"
import { View, FlatList } from "react-native"
import FSDiary from "../../firebase/FSDiary"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import Screen from "../../components/Screen"
import NextButton from "../../components/NextButton"
import FlatListProjectDiaryItem from "../../components/FlatLists/FlatListProjectDiaryItem"
import { diaryFlatListItem, itemStateEnum, statusEnum, userRoleEnum } from "../../data/enums"
import { useStatus } from "../../providers/StatusProvider"
import { uploadFileOnFirebase } from "../../firebase/FSGeneral"

export default function DiaryBulkSignature({ route, navigation }) {
  const { setTitle, setStatus, setMessage, updatePercentage, setToScreen } = useStatus()
  const [diaryEntries, setDiaryEntries] = useState([])
  const projectId = route?.params?.projectId

  const { roles } = route.params.currentUser
  const signatureProp = roles.includes(userRoleEnum.INVESTOR) ? "investor" : "construction_lead"

  useEffect(() => {
    const unsub = FSDiary.onSnapshotEntryListUnsub(projectId, (newDiaryEntries) =>
      setDiaryEntries(
        newDiaryEntries
          .filter((doc) => doc.state !== itemStateEnum.CLOSED && (!doc?.signature || !doc?.signature[signatureProp]))
          .sort((a, b) => b.date_interval.from - a.date_interval.from)
      )
    )
    return () => {
      unsub()
    }
  }, [])

  const handleButtonPress = async (signature) => {
    try {
      setTitle("Nahrávám podpis")
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      setToScreen("DiaryOverview")
      navigation.navigate("StatusScreen")

      const firebaseUri = await uploadFileOnFirebase(signature, updatePercentage, "signatures")
      console.debug("signature uri: ", firebaseUri)

      for (let i = 0; i < diaryEntries.length; i++) {
        const entry = { ...diaryEntries[i] }
        if (entry.selected) {
          if (entry.signature) {
            entry.signature[signatureProp] = firebaseUri
          } else {
            entry.signature = { [signatureProp]: firebaseUri }
          }
          delete entry.selected
          await FSDiary.updateEntry(projectId, entry)
        }
      }
      setStatus(statusEnum.SUCCESS)
    } catch (error) {
      console.error(error)
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }

  const MemoizedButton = memo(() => (
    <NextButton
      text="Podepsat"
      onPress={() => navigation.navigate("SignatureScreen", { onSave: (signature) => handleButtonPress(signature) })}
      disabled={!diaryEntries.some((doc) => doc?.selected)}
    />
  ))

  const handlePress = (item) => {
    setDiaryEntries(
      diaryEntries.map((el) => {
        if (item.id === el.id) {
          return { ...el, selected: !el.selected ?? true }
        }
        return el
      })
    )
  }

  const signatureFlatListItem = ({ item }) => {
    return (
      <FlatListProjectDiaryItem
        item={item}
        projectId={projectId}
        type={diaryFlatListItem.BULK_SIGNATURE}
        onPress={() => handlePress(item)}
        key={item.id.toString()}
      />
    )
  }

  return (
    <Screen>
      <FlatList
        data={diaryEntries}
        renderItem={signatureFlatListItem}
        ItemSeparatorComponent={FlatListSeparator}
        ListEmptyComponent={
          <AppEmptyScreenView
            message={`Všechny záznamy jsou rolí ${
              signatureProp === "investor" ? "investor" : "stavbyvedoucí"
            } podepsány, nebo žádné záznamy neexistují!`}
          />
        }
      />
      <MemoizedButton />
    </Screen>
  )
}
