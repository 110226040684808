import React, { useState, useEffect } from "react"
import { FlatList } from "react-native"
import { FAB } from "react-native-paper"

import Screen from "../../components/Screen"
import DefaultTheme from "../../themes/DefaultTheme"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import FlatListDiaryDocumentItem from "../../components/FlatLists/FlatListDiaryDocumentItem"
import FSDiary from "../../firebase/FSDiary"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import { documentTypeEnum } from "../../data/enums"

export default function DiaryDocumentOverview({ navigation, route }) {
  const [filteredDocuments, setFilteredDocuments] = useState([])
  const viewType = route.params?.viewType
  const projectId = route.params?.projectId

  console.debug("VIEW TYPE DOCUMENT OVERVIEW", viewType)

  useEffect(() => {
    FSDiary.onSnapshotDocumentList(projectId, (newDocuments) => {
      setFilteredDocuments(newDocuments.filter((doc) => doc.doc_type === viewType))
    })
  }, [])

  return (
    <Screen>
      {filteredDocuments.length > 0 ? (
        <FlatList
          data={filteredDocuments}
          renderItem={({ item }) => <FlatListDiaryDocumentItem item={item} projectId={projectId} />}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView
          message={viewType === documentTypeEnum.CONTRACT ? "Neexistují žádné smlouvy" : "Neexistuje žádná dokumentace"}
        />
      )}
      <FAB
        visible={true}
        style={DefaultTheme.fab}
        color={DefaultTheme.colors.fabAdd}
        icon="plus"
        onPress={() => navigation.navigate("DiaryDocumentDetail", { viewType, projectId })}
      />
    </Screen>
  )
}
