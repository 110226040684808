import React, { useEffect, useRef, useState } from "react"
import { ActivityIndicator, FlatList, Platform, Text, View } from "react-native"
import Constants from "expo-constants"
import FlatListAccountingDocumentItem from "../../components/FlatLists/FlatListAccountingDocumentItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import NextButton from "../../components/NextButton"
import { WebView } from "react-native-webview"
import { MaterialCommunityIcons } from "@expo/vector-icons"

import Screen from "../../components/Screen"
import {
  paymentTypeEnum,
  documentViewTypeEnum,
  environmentEnum,
  paymentEnum,
  paymentStateEnum,
  statusEnum,
} from "../../data/enums"
import { useData } from "../../providers/DataProvider"
import FSStoreContent from "../../firebase/FSStoreContent"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import { batchPaymentPrepareDocs, batchPaymentRequest } from "../../actions/bankActions"
import CurrencyFormatter from "../../components/CurrencyFormatter"
import { useStatus } from "../../providers/StatusProvider"
import PrimButton from "../../components/PrimButton"
import DefaultTheme from "../../themes/DefaultTheme"
import { getDuration, toCurrentDate } from "../../utils/general"
import CountDown from "../../components/CountDown"
import DateIntervalButtons from "../../components/DateIntervalButtons"
import { getAccessTokenKbBankUrl, newBatchPaymentRequestUrl } from "../../utils/backendUrls"
// import AppPicker from "../../components/AppPicker"
// import { batchPaymentTypeLabels } from "../../data/labels"

export default function BatchPayments({ navigation, route }) {
  const { setTitle, setMessage, setStatus } = useStatus()
  const { accountingDocuments, settings, firebaseConfig } = useData()
  const [accDocsToPay, setAccDocsToPay] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [showAuthorize, setShowAuthorize] = useState(false)
  const [tokenDuration, setTokenDuration] = useState(0)
  const [transactionCache, setTransactionCache] = useState(0)
  const [balance, setBalance] = useState()
  const webViewUriRef = useRef("")
  const paymentIdRef = useRef("")
  let alreadyRun = false
  const loadingAuthRef = useRef(false)
  const tokenValidTime = 120
  const transactionValidTime = 3600
  const today = new Date()
  const [dateInterval, setDateInterval] = useState({
    from: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    to: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7),
  })
  const lastPrepareDocsAt = settings.kb_bank?.last_prepare_docs_at?.toDate()
  const lastUploadPaymentAt = settings.kb_bank?.last_upload_payment_at?.toDate()

  console.log("route.params", route.params)


  useEffect(() => {
    setAccDocsToPay(
      accountingDocuments.filter(
        (doc) =>
          paymentEnum.BANK_TRANSFER === doc.payment &&
          doc.locked &&
          doc.payment_state === paymentStateEnum.REQUEST &&
          (doc.payment_info?.pay_again || !doc.payment_opened_at) &&
          doc.payment_info?.prepare_docs_id === settings.kb_bank?.prepare_docs_id &&
          doc.price > 0
      )
    )
    setIsLoading(false)
    getTransactionCacheTime()
  }, [accountingDocuments, settings])

  useEffect(() => {
    authorize()
  }, [])

  console.debug(
    "ACC DOS TO PAY",
    accDocsToPay.map((doc) => doc.payment_info?.requested_execution_date)
  )

  function getTransactionCacheTime() {
    const transactionLastApiCall = settings.kb_bank?.last_transactions_api_call?.toDate()

    if (transactionLastApiCall) {
      console.debug(
        "TRANSACTION CACHE DURATION",
        getDuration(transactionLastApiCall) > transactionValidTime
          ? 0
          : transactionValidTime - getDuration(transactionLastApiCall)
      )
      setTransactionCache(
        getDuration(transactionLastApiCall) > transactionValidTime
          ? 0
          : transactionValidTime - getDuration(transactionLastApiCall)
      )
    }
  }

  async function handleBatchPayments() {
    try {
      const batchPaymentId = Math.random().toString(36).substring(2, 14)
      paymentIdRef.current = batchPaymentId
      console.debug("BATCH PAYMENT ID", batchPaymentId)

      const batchPayment = {
        batch_payment_id: batchPaymentId,
        payment_request: {
          batchPaymentDataCollectionRequest: accDocsToPay
            .map((doc) => {
              const suffix = doc.payment_info?.pay_again ? Math.random().toString(36).substring(2, 5) : ""

              return doc.payment_state
                ? {
                    debtorAccount: {
                      identification: {
                        iban: doc.payment_info.debtor_iban,
                      },
                      currency: "CZK",
                    },
                    creditorAccount: {
                      identification: {
                        iban: doc.payment_info.creditor_iban,
                      },
                      currency: "CZK",
                    },
                    amount: {
                      instructedAmount: {
                        value: String(doc.payment_info.amount),
                        currency: "CZK",
                      },
                    },
                    requestedExecutionDate: doc.payment_info.requested_execution_date,
                    paymentIdentification: {
                      instructionIdentification: String(doc.accounting_info.accountingDocumentId) + "TID" + suffix, // throw bad request if not unique - also failed transaction attemps counts => the number is Trivi doc id
                    },
                    paymentTypeInformation: {
                      instructionPriority: "NORM",
                    },
                    remittanceInformation: {
                      unstructured: "",
                      structured: {
                        creditorReferenceInformation: {
                          reference: [`VS:${doc.payment_info.variable ? doc.payment_info.variable : ""}`],
                        },
                      },
                    },
                  }
                : null
            })
            .filter((doc) => doc),
        },
        payment_type: paymentTypeEnum.BATCH,
        updated_at: new Date(),
      }
      await FSStoreContent.createNewBatchPayment(batchPayment)
      console.debug("BATCH", batchPayment)

      FSStoreContent.onSnapshotBatchPayment(batchPaymentId, handleBatchPaymentSnap)
    } catch (error) {
      console.error("Error creating batch payments request: ", error)
      setTitle(`${error.status} ${error.statusText}`)
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  async function handleBatchPaymentSnap({ payment_response, result_state, result_message }) {
    console.debug("PAYMENT_RESPONSE", payment_response)
    let URL = `${newBatchPaymentRequestUrl(firebaseConfig)}/${paymentIdRef.current}`
    console.debug("URL", URL)

    if (payment_response) {
      if (payment_response.signInfo?.state === "OPEN" && !alreadyRun) {
        alreadyRun = true // in order to prevent multiple saves when new data comes to payment - throwed filestore uri errors
        for (const docToSave of accDocsToPay) {
          if (docToSave.payment_state) {
            await FSStoreContent.setAccountingDocument({
              ...docToSave,
              payment_opened_at: new Date(),
              payment_info: { ...docToSave.payment_info, pay_again: false },
            })
          } else {
            delete docToSave.payment_info
            await FSStoreContent.setAccountingDocument(docToSave)
          }
        }

        await FSStoreContent.updateSettings({ ...settings.kb_bank, last_upload_payment_at: new Date() })

        if (Platform.OS === "web") {
          setMessage("Platby byly nahrány úspěšně")
          setStatus(statusEnum.SUCCESS)
        } else {
          setShowAuthorize(false)
        }
      }
    } else if (result_state === "FAIL") {
      setTitle(result_message || "")
      setMessage(
        `Pravděpodobně se mezi platbami nachází duplicitní platba.\nZkuste ještě jednou: 1. Obnovit token pokud je potřeba 2. Načíst nové doklady (odstraní duplicity) 3. Nahrát platby\nPokud problém přetrvává, kontaktujte prosím support.`
      )
      setStatus(statusEnum.ERROR)
      if (Platform.OS !== "web") {
        setShowAuthorize(false)
        await navigation.navigate("StatusScreen")
      }
    } else if (!alreadyRun) {
      if (Platform.OS === "web") {
        setTitle("Čekám na sběhnutí platby")
        setStatus(statusEnum.PROGRESS_UNDETERMINED_CANCELABLE)
        await navigation.navigate("StatusScreen")

        try {
          if (tokenDuration > 0) {
            await batchPaymentRequest(paymentIdRef.current, firebaseConfig)
          } else {
            window.open(URL, "_blank")
          }
        } catch (error) {
          console.error("Error calling newBatchPaymentRequestUrl: ", error)
          setStatus(statusEnum.ERROR)
          setMessage(error.message)
        }
      } else {
        webViewUriRef.current = URL
        setShowAuthorize(true)
      }
    }
  }

  async function handleBatchPaymentPrepareDocs() {
    try {
      setStatus(statusEnum.PROGRESS_UNDETERMINED)
      setMessage(`Hledám doklady se splatností ${toCurrentDate(dateInterval.from)} - ${toCurrentDate(dateInterval.to)}`)
      await navigation.navigate("StatusScreen")

      await batchPaymentPrepareDocs(firebaseConfig, dateInterval)
      setStatus(statusEnum.SUCCESS)
    } catch (error) {
      console.error("Error fulfilling docs data for batch payments: ", error.message)
      setTitle(`${error.status} ${error.statusText}`)
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      await navigation.navigate("StatusScreen")
    }
  }

  function authorize(isPressed) {
    let URL = getAccessTokenKbBankUrl(firebaseConfig)
    console.debug("URL", URL)

    try {
      FSStoreContent.onSnapshotAccessToken(firebaseConfig,({ access_token_timestamp: timestamp }) => {
        console.debug(
          "TOKEN DURATION",
          getDuration(timestamp) > tokenValidTime ? 0 : tokenValidTime - getDuration(timestamp)
        )
        setTokenDuration(getDuration(timestamp) > tokenValidTime ? 0 : tokenValidTime - getDuration(timestamp))

        if (tokenValidTime - getDuration(timestamp) > 0 && (loadingAuthRef.current || showAuthorize)) {
          setStatus(statusEnum.SUCCESS)
          loadingAuthRef.current = false
          setShowAuthorize(false)
        }

        if (isPressed) {
          isPressed = false
          if (Platform.OS === "web") {
            setTitle("Čekám na autorizaci")
            setMessage("Autorizujte se prosím ve vaší bankovní aplikaci otevřené na nové kartě vašeho prohlížeče")
            setStatus(statusEnum.PROGRESS_UNDETERMINED_CANCELABLE)
            navigation.navigate("StatusScreen")
            loadingAuthRef.current = true

            window.open(`${URL}`, "_blank")
          } else {
            webViewUriRef.current = `${URL}`
            setShowAuthorize(true)
          }
        }
      })
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return (
    <Screen>
      {showAuthorize ? (
        <View style={{ flex: 1, paddingHorizontal: 5 }}>
          <WebView source={{ uri: webViewUriRef.current }} containerStyle={{ height: "100%", width: "100%" }} />
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          <View style={{ marginHorizontal: 10, flex: Platform.OS !== "web" && 1 }}>
            <Text style={{ paddingVertical: 10, fontSize: DefaultTheme.fonts.regular }}>
              {`Předchozí načítání dokladů: ${
                settings.kb_bank?.prepare_docs_from_date
                  ? `${toCurrentDate(settings.kb_bank?.prepare_docs_from_date.toDate())} - ${toCurrentDate(
                      settings.kb_bank?.prepare_docs_to_date.toDate()
                    )}`
                  : "ještě neproběhlo"
              }`}
            </Text>
            <DateIntervalButtons dateInterval={dateInterval} setDateInterval={setDateInterval} />
            {/* <AppPicker
              items={batchTypePickerItems}
              value={batchPaymentType}
              onValueChange={(value) => setBatchPaymentType(value)}
              descriptionLabel="Typ platby"
              placeholder={{}}
            /> */}
            <PrimButton
              onPress={handleBatchPaymentPrepareDocs}
              text="Načíst doklady k zaplacení"
              width="100%"
              height={60}
              disabled={tokenDuration <= 0 || dateInterval.from > dateInterval.to}
            />
            <Text style={{ paddingVertical: 5, fontWeight: "bold" }}>
              Postup: 1. Obnovit token pokud je potřeba 2. Načíst nové doklady 3. Nahrát platby
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 10,
              padddingVertical: 5,
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 20 }}>KB Transakce v paměti</Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <CountDown
                seconds={transactionCache}
                setSeconds={setTransactionCache}
                style={{ padding: 10, fontSize: DefaultTheme.fonts.regular }}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 10,
              padddingVertical: 5,
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 20 }}>Trvání tokenu</Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <CountDown
                seconds={tokenDuration}
                setSeconds={setTokenDuration}
                style={{ padding: 10, fontSize: DefaultTheme.fonts.regular }}
              />
              <PrimButton
                icon={<MaterialCommunityIcons name="refresh" size={24} color={DefaultTheme.colors.white} />}
                onPress={() => authorize(true)}
                width={36}
                disabled={tokenDuration > 0}
              />
            </View>
          </View>
          {accDocsToPay.length > 0 && (
            <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 10 }}>
              <Text style={{ fontSize: 20 }}>K zaplacení</Text>
              <Text style={{ fontSize: 20 }}>
                {CurrencyFormatter(
                  accDocsToPay.reduce((a, c) => (c.payment_state === paymentStateEnum.REQUEST ? c.price : 0) + a, 0),
                  balance?.amount?.currency || "CZK",
                  -1
                )}
              </Text>
            </View>
          )}
          {accDocsToPay.length > 0 ? (
            <FlatList
              style={{ flex: 1 }}
              data={accDocsToPay}
              ItemSeparatorComponent={FlatListSeparator}
              renderItem={({ item }) => (
                <FlatListAccountingDocumentItem
                  document={item}
                  viewType={documentViewTypeEnum.BATCH}
                  accDocs={accDocsToPay}
                  setAccDocs={setAccDocsToPay}
                />
              )}
            />
          ) : (
            <AppEmptyScreenView
              message={
                isLoading
                  ? "Doklady se načítají"
                  : "Je potřeba načíst doklady k zaplacení nebo všechny doklady jsou zaplaceny"
              }
            />
          )}
        </View>
      )}
      <NextButton
        text={showAuthorize ? "Zrušit" : "Nahrát platby"}
        onPress={showAuthorize ? () => setShowAuthorize(false) : handleBatchPayments}
        disabled={
          !lastPrepareDocsAt ||
          lastPrepareDocsAt < lastUploadPaymentAt ||
          (accDocsToPay.length === 0 && !showAuthorize) ||
          tokenDuration <= 0
        }
      />
    </Screen>
  )
}
