import React from "react"
import { StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { Ionicons, FontAwesome5 } from "@expo/vector-icons"

import DefaultTheme from "../../themes/DefaultTheme"
import { useData } from "../../providers/DataProvider"
import { getUserName, toCurrentDateTime } from "../../utils/general"
import getUserRolesString from "../../utils/userUtils"
import { documentTypeEnum } from "../../data/enums"

export default function FlatListDiaryDocumentItem({ item, projectId }) {
  const { users } = useData()
  const navigation = useNavigation()

  return (
    <TouchableOpacity
      style={styles.card}
      onPress={() =>
        navigation.navigate("DiaryDocumentDetail", { diaryDocument: item, viewType: item.doc_type, projectId })
      }
    >
      <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
        <View style={{ paddingRight: 15 }}>
          {item.doc_type === documentTypeEnum.CONTRACT ? (
            <FontAwesome5
              name="file-contract"
              size={DefaultTheme.icons.headerSizeRight}
              color={DefaultTheme.colors.primary}
            />
          ) : (
            <Ionicons
              name="document-text"
              size={DefaultTheme.icons.headerSizeRight}
              color={DefaultTheme.colors.primary}
            />
          )}
        </View>
        <View>
          <Text>{item.subject || "Předmět - - -"}</Text>
          <Text>{item.number || "Číslo - - -"}</Text>
        </View>
      </View>
      <View style={{ flex: 1, textAlign: "right" }}>
        <Text style={[styles.hint, styles.alignedText]}>Poslední úprava</Text>
        <Text style={{ ...styles.alignedText, paddingHorizontal: 5 }}>{toCurrentDateTime(item.updated_at)}</Text>
        <View style={{ flexShrink: 1 }}>
          {users.length > 0 && (
            <Text style={{ ...styles.alignedText, paddingHorizontal: 5 }}>{getUserName(users, item.updated_by)}</Text>
          )}
          {users.length > 0 && (
            <Text style={{ ...styles.alignedText, paddingHorizontal: 5 }}>{getUserRolesString(item.updated_by)}</Text>
          )}
        </View>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    minHeight: 100,
    maxHeight: 130,
    width: "100%",
    padding: 15,
    flexDirection: "row",
    borderLeftWidth: 10,
    borderLeftColor: DefaultTheme.colors.primary,
    backgroundColor: DefaultTheme.colors.white,
    justifyContent: "space-between",
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
  alignedText: {
    textAlign: "right",
  },
})
