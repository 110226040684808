import React, { useEffect, useRef } from "react"
import { Text } from "react-native"

export default function CountDown({ seconds, setSeconds, style }) {
  const secondsRef = useRef(seconds)

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsRef.current > 0) {
        setSeconds(secondsRef.current - 1)
      } else {
        setSeconds(0)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    secondsRef.current = seconds
  })

  return <Text style={style}>{seconds.toFixed(0)}s</Text>
}
