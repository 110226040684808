import React, { useEffect, useState } from "react"
import { FlatList, View } from "react-native"
import { FAB } from "react-native-paper"
import DefaultTheme from "../../themes/DefaultTheme"
import { useData } from "../../providers/DataProvider"
import Screen from "../../components/Screen"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import FlatListUserItem from "../../components/FlatLists/FlatListUserItem"
import { isAdmin, isExecutive, isOfficeManager, isOnlyInvestor } from "../../utils/validation"
import { userRoleEnum, userStateEnum, usersViewTypeEnum } from "../../data/enums"
import AppPicker from "../../components/AppPicker"
import { roleLabels } from "../../data/labels"
import { firebase as fb } from "../../firebase/config"

export default function Users({ navigation, route }) {
  const { users, currentUser, settings } = useData()
  const [filteredUsers, setFilteredUsers] = useState([])
  const [pickerRole, setpickerRole] = useState(" ")
  const isCompanyUsers = route.params?.viewType === usersViewTypeEnum.COMPANY
  const hasEditPermission = isAdmin(currentUser) || isExecutive(currentUser) || isOfficeManager(currentUser)

  useEffect(() => {
    filterUsers()
  }, [users, settings, pickerRole])

  function filterUsers() {
    if (pickerRole && pickerRole !== " ") {
      setFilteredUsers(users.filter((u) => u.state !== userStateEnum.DELETED && u.roles.includes(pickerRole)))
    } else if (isCompanyUsers) {
      setFilteredUsers(users.filter((user) => user.state !== userStateEnum.DELETED && !isOnlyInvestor(user)))
    } else {
      setFilteredUsers(
        settings?.users?.show_deleted_users ? users : users.filter((user) => user.state !== userStateEnum.DELETED)
      )
    }
  }

  const pickerItems = Object.keys(userRoleEnum)
    .filter((r) => r !== userRoleEnum.ADMIN && (isCompanyUsers ? r !== userRoleEnum.INVESTOR : true))
    .map((r) => ({ label: `${roleLabels[r]}`, value: r }))

  function FilterComponent() {
    return (
      <View style={{ marginVertical: 10 }}>
        <AppPicker
          value={pickerRole}
          descriptionLabel="Role"
          placeholder={{ label: "Všechny", value: " " }}
          onValueChange={(newRole) => setpickerRole(newRole)}
          items={pickerItems}
        />
      </View>
    )
  }

  return (
    <Screen>
      <FlatList
        data={filteredUsers}
        ListHeaderComponent={FilterComponent}
        renderItem={({ item }) => (
          <FlatListUserItem user={item} navigation={navigation} viewType={route.params?.viewType} />
        )}
        keyExtractor={(item) => item.email}
        ItemSeparatorComponent={FlatListSeparator}
      />
      {hasEditPermission && !isCompanyUsers &&
        fb.apps[0].name === "prod" &&
        (
        <FAB
          visible={true}
          style={DefaultTheme.fab}
          color={DefaultTheme.colors.fabAdd}
          icon="plus"
          onPress={() => navigation.navigate("UserDetail")}
        />
      )}
    </Screen>
  )
}
