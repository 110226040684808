import { userRoleEnum } from "../data/enums"

// Centralize global reusable functions
export default {
  isEmailValid,
  isPasswordValid,
  shouldWarningEmail,
  shouldWarningPassword,
  isNumberPositive,
  isNumber,
  numberOfPasswordChars,
  isYear,
  isPercentage,
}

export const numberOfPasswordChars = 6

// standard email reqex
export function isEmailValid(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export function isLink(value) {
  const re =
    /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/
  return re.test(value)
}
export function isCoordinate(value) {
  const re = /^(-?\d+(\.\d+)?).\s*(-?\d+(\.\d+)?)$/
  return re.test(value)
}

// minimal 6 characters for password && !/(?=\S*?[A-Z])(?=\S*?[0-9])\S{6,}/.test(password)
const regExpEmail = new RegExp(`(?=.{${numberOfPasswordChars},})`)

export function isPasswordValid(password) {
  return /(?=\S*?[A-Z])(?=\S*?[0-9])\S{6,}/.test(password)
}

export function isYear(year) {
  return /^202\d$/.test(year)
}

export function isPercentage(percentage) {
  return /^\d+(\.\d+)$/.test(percentage)
}

export function shouldWarningEmail(email) {
  // in case of not defined or empty string is valid - because of place holder
  if (!email) return true
  // than if is not empty test reqex
  return isEmailValid(email)
}

export function shouldWarningPassword(password) {
  if (!password) return true
  return isPasswordValid(password)
}

// number positive
export function isNumberPositive(number) {
  return !(!+number || Math.sign(number) === -1)
}

export function isNumber(number) {
  // "" is converted to 0 by js engine
  if (number === "") {
    return false
  }

  return !isNaN(+number)
}

export function isProjectManager(user) {
  return user?.roles.includes(userRoleEnum.TECHNICAL_DIRECTOR)
}

export function isTechnicalDirector(user) {
  return user?.roles.includes(userRoleEnum.TECHNICAL_DIRECTOR)
}

export function isExecutive(user) {
  return user?.roles.includes(userRoleEnum.EXECUTIVE)
}

export function isAdmin(user) {
  return user?.roles.includes(userRoleEnum.ADMIN)
}

export function isOfficeManager(user) {
  return user?.roles.includes(userRoleEnum.OFFICE_MANAGER)
}

export function isAccountant(user) {
  return user?.roles.includes(userRoleEnum.ACCOUNTANT)
}

export function isInvestor(user) {
  return user?.roles.includes(userRoleEnum.INVESTOR)
}

export function isOnlyInvestor(user) {
  return user?.roles.includes(userRoleEnum.INVESTOR) && user?.roles?.length === 1
}

export function isRowDisabled(actionType, document, projects, currentUser) {
  if (Object.keys(document).length > 0) {
    const projectUsers = projects.find((proj) => proj.id === document.project)?.users
    const userRolesInProject = projectUsers?.find((user) => user.email === currentUser.email)?.roles || []

    let enabled = false

    if (actionType === "STATE_DOC_ACCEPTED") {
      if (document.price >= 200000) {
        enabled = userRolesInProject.includes(userRoleEnum.EXECUTIVE) || userRolesInProject.includes(userRoleEnum.ADMIN)
      }
      if (document.price < 200000) {
        enabled =
          userRolesInProject.includes(userRoleEnum.EXECUTIVE) ||
          userRolesInProject.includes(userRoleEnum.ADMIN) ||
          userRolesInProject.includes(userRoleEnum.TECHNICAL_DIRECTOR)
      }
      if (document.price < 100000) {
        enabled =
          userRolesInProject.includes(userRoleEnum.EXECUTIVE) ||
          userRolesInProject.includes(userRoleEnum.ADMIN) ||
          userRolesInProject.includes(userRoleEnum.TECHNICAL_DIRECTOR) ||
          userRolesInProject.includes(userRoleEnum.PROJECT_MANAGER)
      }
      if (document.price < 10000) {
        enabled =
          userRolesInProject.includes(userRoleEnum.EXECUTIVE) ||
          userRolesInProject.includes(userRoleEnum.ADMIN) ||
          userRolesInProject.includes(userRoleEnum.TECHNICAL_DIRECTOR) ||
          userRolesInProject.includes(userRoleEnum.PROJECT_MANAGER) ||
          userRolesInProject.includes(userRoleEnum.CONSTRUCTION_LEAD) ||
          userRolesInProject.includes(userRoleEnum.CONSTRUCTION_STAFF)
      }
    } else if (actionType === "STATE_UNIT_TOTAL") {
      enabled =
        userRolesInProject.includes(userRoleEnum.EXECUTIVE) ||
        userRolesInProject.includes(userRoleEnum.ADMIN) ||
        userRolesInProject.includes(userRoleEnum.TECHNICAL_DIRECTOR) ||
        userRolesInProject.includes(userRoleEnum.PROJECT_MANAGER) ||
        userRolesInProject.includes(userRoleEnum.CONSTRUCTION_LEAD) ||
        userRolesInProject.includes(userRoleEnum.CONSTRUCTION_STAFF)
    }
    return document.locked || !enabled
  }
}

export function isDateInCurrentMonth(date) {
  const today = new Date()
  const tempDate = typeof date?.getMonth === "function" ? date : date.toDate()
  return today.getFullYear() === tempDate?.getFullYear() && today.getMonth() === tempDate?.getMonth()
}

/**
 * @param {Object} object
 * @returns {Boolean} check if object has some empty prop
 */
export function hasEmptyProp(object) {
  return Object.keys(object).some((prop) => !object[prop])
}
