import React, { useEffect, useMemo } from "react"
import { View, FlatList, Text, ActivityIndicator, Platform } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import WeeklyComponent from "./WeeklyComponent"
import CurrencyFormatter from "../CurrencyFormatter"
import PrimButton from "../PrimButton"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import CurrencyWithLabelComponent from "./CurrencyWithLabelComponent"

export default function CashflowWrapper({ docs, date, initValues, handleRefresh }) {
  const pickedMonthStartingValue =
    date && initValues?.length > 0
      ? initValues.find((el) => el.month_index === date.getMonth() && el.year === date.getFullYear())
          ?.month_start_amount || 0
      : 0

  const totals = docs.map((weekObject) => {
    const temp = { outcome: 0, income: 0 }
    weekObject.data.forEach((dayItem) => {
      dayItem.outDocs.forEach((doc) => {
        temp.outcome += doc.total
      })
      dayItem.incDocs.forEach((doc) => {
        temp.income += doc.total
      })
    })
    temp.result = temp.income - temp.outcome

    return temp
  })

  const getTotal = () => {
    const temp = totals.reduce(
      (prev, curr) => {
        return {
          income: prev.income + curr.income,
          outcome: prev.outcome + curr.outcome,
        }
      },
      { outcome: 0, income: 0 }
    )
    return pickedMonthStartingValue - temp.outcome + temp.income
  }
  return (
    <View style={{ flex: 1 }}>
      {date.getMonth() !== docs[0].data[0].date.getMonth() ? (
        <ActivityIndicator />
      ) : (
        <>
          <View
            style={{
              margin: 5,
              padding: 10,
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <View style={{ width: "100%", alignItems: "flex-end" }}>
              <PrimButton
                icon={<MaterialCommunityIcons name="refresh" size={24} color={DefaultTheme.colors.white} />}
                onPress={() => handleRefresh()}
                width={36}
              />
            </View>
            <View style={{ flexDirection: "column", width: "100%", }}>
              <CurrencyWithLabelComponent label={"Zůstatek z předchozího měsíce"} value={pickedMonthStartingValue}/>
              <CurrencyWithLabelComponent label={"Zůstatek na konci měsíce"} value={getTotal()}/>
            </View>
          </View>

          <FlatList
            data={docs}
            keyExtractor={(item, index) => item + index}
            renderItem={({ item, index }) => (
              <WeeklyComponent
                weekObject={item}
                weeklyTotal={totals[index]}
                totals={totals}
                index={index}
                monthStart={pickedMonthStartingValue}
              />
            )}
          />
        </>
      )}
    </View>
  )
}
