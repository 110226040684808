import React, { useEffect } from "react"
import { Image, Platform, SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { List } from "react-native-paper"
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem } from "@react-navigation/drawer"
import StackNavigator from "./StackNavigator"
import OuterDrawerItem from "./OuterDrawerItem"
import { firebase } from "../../firebase/config"
import {
  AntDesign,
  Entypo,
  FontAwesome,
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons"
import { roleLabels } from "../../data/labels"
import DefaultTheme from "../../themes/DefaultTheme"
import { useStatus } from "../../providers/StatusProvider"
import { useData } from "../../providers/DataProvider"
import FSStoreContent from "../../firebase/FSStoreContent"
import {
  isAccountant,
  isAdmin,
  isExecutive,
  isOfficeManager,
  isOnlyInvestor,
  isTechnicalDirector,
} from "../../utils/validation"
import Constants from "expo-constants"
import {
  documentViewTypeEnum,
  environmentEnum,
  modulesEnum,
  userRoleEnum,
  usersViewTypeEnum,
  versionCheckTypeEnum,
} from "../../data/enums"
import { openCompanyServer, openPlangrid } from "../../actions/companyItemActions"
import { checkAppVersion, handleConfigSwitch } from "../../utils/general"

export default function DrawerNavigator({ navigation, hasError = false }) {
  const Drawer = createDrawerNavigator()
  const { status, setTitle, setStatus, statusEnum, setMessage, setShowAlert, setAlertProgress } = useStatus()
  const {
    setProjects,
    setAccountingDocuments,
    setUsers,
    currentUser,
    setCurrentUser,
    setSettings,
    setUserTasks,
    userTasks,
    setCashDesks,
    setCompanyDocuments,
    setPremiums,
    setAlertData,
    firebaseConfig,
    setFirebaseConfig,
    relogWith,
    setRelogWith,
    setExtraDocs,
  } = useData()

  let InitialScreen = {}
  // if (Platform.OS === "web") {
  //   try {
  //     InitialScreen = require("./InitialScreen.json")
  //   } catch (error) {
  //     console.warn("Could not load InitialScreen.json - DashboardScreen is applied")
  //   }
  // }
  const handlePress = () => {
    handleConfigSwitch(setStatus, firebaseConfig, setFirebaseConfig, navigation, Constants.manifest.extra.environment)
  }

  useEffect(() => {
    try {
      ;(async () => {
        // hasError is because of the ErrorBoundary Component
        !hasError && setTitle("Načítám data aplikace")
        !hasError && setStatus(statusEnum.PROGRESS_UNDETERMINED)
        navigation.navigate("StatusScreen")
        const user = await FSStoreContent.onSnapshotUser(setCurrentUser)

        !hasError && setMessage("Načítám nastavení")
        await FSStoreContent.onSnapshotSettings(setSettings)

        !hasError && setMessage("Načítám projekty a doklady")
        await FSStoreContent.onSnapshotProjects(setProjects, setAccountingDocuments, user)

        !hasError && setMessage("Načítám pokladny")
        await FSStoreContent.onSnapshotCashDesks(setCashDesks, user)

        !hasError && setMessage("Načítám úkoly")
        await FSStoreContent.onSnapshotUserTasks(setUserTasks, user)

        !hasError && setMessage("Načítání uživatelů")
        await FSStoreContent.onSnapshotUsers(setUsers)

        !hasError && setMessage("Načítám firemní dokumenty")
        await FSStoreContent.onSnapshotCompanyDocuments(setCompanyDocuments)

        !hasError && setMessage("Načítám prémie")
        await FSStoreContent.onSnapshotUserPremiumActions(setPremiums, user)

        !hasError && setMessage("Načítám extra documenty")
        await FSStoreContent.onExtraDocument(setExtraDocs)

        await FSStoreContent.appVersionOnSnapshot((updateData) =>
          checkAppVersion(updateData, setShowAlert, setAlertData, versionCheckTypeEnum.AUTO, setAlertProgress),
        )

        !hasError && navigation.pop()
        if (relogWith?.toScreen) {
          if (relogWith?.configId === firebaseConfig) {
            navigation.navigate(relogWith?.toScreen, relogWith?.routeParams)
            setRelogWith(null)
          }
        }
      })()
    } catch (error) {
      setStatus(statusEnum.ERROR)
      setMessage(error.message)
      navigation.navigate("StatusScreen")
    }
  }, [])

  if (currentUser.roles?.length > 0) {
    return (
      <Drawer.Navigator
        screenOptions={{ gestureEnabled: status === statusEnum.NONE, headerShown: false }}
        drawerContent={(props) => {
          const myCompanyItems =
            isAdmin(currentUser) || isExecutive(currentUser)
              ? [
                {
                  key: 0,
                  style: styles.secondMenu,
                  label: "Statistika",
                  icon: <MaterialCommunityIcons name="chart-line" size={DefaultTheme.icons.menuSize} />,
                  onPress: () => navigation.navigate("StatisticsOverview"),
                },
                {
                  key: 1,
                  image: require("../../assets/kb-bank.png"),
                  label: "KB banka",
                  onPress: () => navigation.navigate("BatchPayments"),
                },
                {
                  key: 2,
                  image: require("../../assets/rb-bank.png"),
                  label: "RB banka",
                  onPress: () => navigation.navigate("BatchPaymentList"),
                },
              ]
              : []

          Platform.OS === "web"
            ? myCompanyItems.push(
              {
                key: 2,
                image: require("../../assets/boxlogo.png"),
                label: "Box",
                onPress: openCompanyServer,
              },
              {
                key: 3,
                image: require("../../assets/plangrid.png"),
                label: "Plangrid",
                onPress: openPlangrid,
              },
              {
                key: 4,
                style: styles.secondMenu,
                label: "Firemní dokumenty",
                icon: <Ionicons name="document-text" size={DefaultTheme.icons.menuSize} />,
                onPress: () => navigation.navigate("DocumentsOverview", { viewType: documentViewTypeEnum.COMPANY }),
              },
            )
            : myCompanyItems.push(
              {
                key: 2,
                image: require("../../assets/boxlogo.png"),
                label: "Box",
                onPress: openCompanyServer,
              },
              {
                key: 4,
                style: styles.secondMenu,
                label: "Firemní dokumenty",
                icon: <Ionicons name="document-text" size={DefaultTheme.icons.menuSize} />,
                onPress: () => navigation.navigate("DocumentsOverview", { viewType: documentViewTypeEnum.COMPANY }),
              },
            )
          if (isAdmin(currentUser) || isExecutive(currentUser)) {
            myCompanyItems.push({
              key: 5,
              icon: <FontAwesome5 name="money-bill-wave" size={DefaultTheme.icons.menuSize} color="black" />,
              style: styles.secondMenu,
              label: "Cashflow",
              onPress: () => navigation.navigate("Cashflow"),
            })
          }
          if (isAdmin(currentUser) || isExecutive(currentUser)) {
            myCompanyItems.push({
              key: 6,
              icon: <FontAwesome5 name="file-invoice-dollar" size={DefaultTheme.icons.menuSize} color="black" />,
              style: styles.secondMenu,
              label: "Trivi Doklady",
              onPress: () => navigation.navigate("Trivi"),
            })
          }
          const projectItems = [
            {
              style: styles.secondMenu,
              label: "Přehled projektů",
              icon: <Ionicons name="book" size={DefaultTheme.icons.menuSize} />,
              onPress: () => navigation.navigate("ProjectsOverview"),
              active: true,
            },
            {
              style: styles.secondMenu,
              label: "Deník",
              icon: <FontAwesome name="calendar" size={DefaultTheme.icons.menuSize} />,
              onPress: () => navigation.navigate("DiaryOverview"),
              active: Constants.manifest.extra.modules[modulesEnum.diary].includes(
                Constants.manifest.extra.environment,
              ),
            },
            {
              style: [styles.secondMenu, { paddingLeft: 23 }],
              label: "Doklady",
              icon: <FontAwesome5 name="file-invoice-dollar" size={DefaultTheme.icons.menuSize} />,
              onPress: () => navigation.navigate("AccountingDocumentsOverview"),
              active: true,
            },
            {
              style: styles.secondMenu,
              label: "Schvalování",
              icon: <MaterialCommunityIcons name="clipboard-check" size={DefaultTheme.icons.menuSize} />,
              onPress: () => navigation.navigate("ApprovingAccountingDocumentsOverview"),
              active: true,
            },
          ]

          const employeeItems =
            isAdmin(currentUser) ||
            isExecutive(currentUser) ||
            isAccountant(currentUser) ||
            isTechnicalDirector(currentUser) ||
            isOfficeManager(currentUser)
              ? [
                {
                  key: 0,
                  style: styles.secondMenu,
                  label: "Pokladna",
                  icon: <MaterialCommunityIcons name="cash-register" size={DefaultTheme.icons.menuSize} />,
                  onPress: () => navigation.navigate("CashDeskOverview"),
                },
                {
                  key: 1,
                  style: styles.secondMenu,
                  label: "Docházka",
                  icon: <MaterialCommunityIcons name="clock" size={DefaultTheme.icons.menuSize} />,
                  onPress: () => navigation.navigate("AttendanceOverview"),
                },
                {
                  key: 2,
                  style: styles.secondMenu,
                  label: "Kniha jízd",
                  icon: (
                    <FontAwesome name="suitcase" size={DefaultTheme.icons.menuSize - 3} style={{ paddingLeft: 2 }} />
                  ),
                  onPress: () => navigation.navigate("LogBookOverview"),
                },
                {
                  key: 3,
                  style: styles.secondMenu,
                  label: "Evidence majetku",
                  icon: <MaterialCommunityIcons name="clipboard-text" size={24} color="black" />,
                  onPress: () => navigation.navigate("PropertyOverview"),
                },
                {
                  key: 4,
                  style: styles.secondMenu,
                  label: "Prémie",
                  icon: <MaterialCommunityIcons name="cash-multiple" size={DefaultTheme.icons.menuSize} />,
                  onPress: () => navigation.navigate("PremiumOverview"),
                },
                {
                  key: 5,
                  style: styles.secondMenu,
                  label: "Dokumenty",
                  icon: <Ionicons name="document-text" size={DefaultTheme.icons.menuSize} />,
                  onPress: () =>
                    navigation.navigate("DocumentsUserOverview", { viewType: documentViewTypeEnum.USER }),
                },
              ]
              : [
                {
                  key: 0,
                  style: styles.secondMenu,
                  label: "Pokladna",
                  icon: <MaterialCommunityIcons name="cash-register" size={DefaultTheme.icons.menuSize} />,
                  onPress: () =>
                    navigation.navigate("CashDeskMonths", {
                      user: currentUser,
                    }),
                },
                {
                  key: 1,
                  style: styles.secondMenu,
                  label: "Docházka",
                  icon: <MaterialCommunityIcons name="clock" size={DefaultTheme.icons.menuSize} />,
                  onPress: () =>
                    navigation.navigate("AttendanceMonths", {
                      user: currentUser,
                    }),
                },
                {
                  key: 2,
                  style: styles.secondMenu,
                  label: "Kniha jízd",
                  icon: (
                    <FontAwesome name="suitcase" size={DefaultTheme.icons.menuSize - 3} style={{ paddingLeft: 2 }} />
                  ),
                  onPress: () => navigation.navigate("LogBookMonths", { user: currentUser }),
                },
                {
                  key: 3,
                  style: styles.secondMenu,
                  label: "Evidence majetku",
                  icon: <MaterialCommunityIcons name="clipboard-text" size={24} color="black" />,
                  onPress: () => navigation.navigate("PropertyUserDetail", { user: currentUser }),
                },
                {
                  key: 4,
                  style: styles.secondMenu,
                  label: "Prémie",
                  icon: <MaterialCommunityIcons name="cash-multiple" size={DefaultTheme.icons.menuSize} />,
                  onPress: () => navigation.navigate("PremiumUserProjects", { user: currentUser }),
                },
                {
                  key: 5,
                  style: styles.secondMenu,
                  label: "Dokumenty",
                  icon: <Ionicons name="document-text" size={DefaultTheme.icons.menuSize} />,
                  onPress: () =>
                    navigation.navigate("DocumentsOverview", {
                      viewType: documentViewTypeEnum.USER,
                      belongsTo: currentUser.email,
                    }),
                },
              ]

          const userItems = [
            {
              key: 0,
              style: styles.secondMenu,
              label: "Odhlásit se",
              icon: <MaterialIcons name="logout" size={DefaultTheme.icons.menuSize} />,
              onPress: () => {
                setCurrentUser({ name: "nepřihlášen", roles: [], email: "" })
                firebase.apps[0].auth().signOut()
              },
            },
          ]

          const contactsItem = {
            key: 1,
            style: styles.secondMenu,
            label: "Adresář firmy",
            icon: <AntDesign name="contacts" size={DefaultTheme.icons.menuSize} />,
            onPress: () => {
              navigation.navigate("Users", { viewType: usersViewTypeEnum.COMPANY })
            },
          }
          const contactItems = !isOnlyInvestor(currentUser)
            ? [
              {
                key: 0,
                style: styles.secondMenu,
                label: "Katalog firem",
                icon: <MaterialCommunityIcons name="office-building" size={DefaultTheme.icons.menuSize} />,
                onPress: () => {
                  navigation.navigate("CompanyCatalogOverview")
                },
              },
              contactsItem,
            ]
            : [contactsItem]

          return (
            <SafeAreaView style={{ flex: 1 }}>
              <DrawerContentScrollView {...props}>
                <Image
                  source={
                    firebaseConfig === "prod" ? require("../../assets/dasar_logo.png") :
                      firebaseConfig === "prod-1" ? require("../../assets/dasar_invest_logo.png") :
                        firebaseConfig === "prod-development" ? require("../../assets/dasar_development_logo.png") :
                          require("../../assets/dasar_logo.png")
                  }
                  style={[styles.logo, { width: firebaseConfig === "prod" ? 120 : 150 }]}
                />
                {currentUser?.roles?.includes(userRoleEnum.VIEWER) ? (
                  <>
                    <OuterDrawerItem
                      isCollapsible={false}
                      label="Deník"
                      icon={
                        <View style={styles.outerItemIconWrapper}>
                          <FontAwesome name="calendar" size={DefaultTheme.icons.menuSize} />
                        </View>
                      }
                      onPress={() => navigation.navigate("DiaryOverview")}
                    />
                  </>
                ) : (
                  <>
                    {!isOnlyInvestor(currentUser) && (
                      <OuterDrawerItem
                        isCollapsible={false}
                        label="Přehled"
                        icon={
                          <View style={styles.outerItemIconWrapper}>
                            <AntDesign name="dashboard" size={DefaultTheme.icons.menuSize} />
                          </View>
                        }
                        onPress={() => navigation.navigate("DashboardScreen")}
                      />
                    )}
                    <View>
                      <OuterDrawerItem
                        style={{ zIndex: 1 }}
                        isCollapsible={false}
                        icon={
                          <View style={styles.outerItemIconWrapper}>
                            <FontAwesome5 name="tasks" size={DefaultTheme.icons.menuSize} />
                          </View>
                        }
                        label="Úkoly"
                        onPress={() => navigation.navigate("TasksOverview")}
                      />
                      {userTasks.length > 0 && (
                        <View style={styles.tasksCountWrapper}>
                          <Text style={styles.tasksCount}>{userTasks.length}</Text>
                        </View>
                      )}
                    </View>

                    {!isOnlyInvestor(currentUser) && (
                      <List.Accordion
                        style={styles.listAccordion}
                        titleStyle={[styles.listAccordionTitle, { paddingLeft: 5 }]}
                        title="Moje firma"
                        left={() => (
                          <View style={styles.listAccIconWrapper}>
                            <FontAwesome name="home" size={DefaultTheme.icons.menuSize + 5} />
                          </View>
                        )}
                      >
                        {myCompanyItems.map((item) => {
                          if (item.key === 1 || item.key === 2 || item.key === 3) {
                            return (
                              <TouchableOpacity onPress={item.onPress} style={styles.imageItemWrapper} key={item.key}>
                                <Image source={item.image} style={{ width: 24, height: 24 }} />
                                <Text style={styles.imageItemText}>{item.label}</Text>
                              </TouchableOpacity>
                            )
                          }
                          return (
                            <DrawerItem
                              key={item.key}
                              style={item.style}
                              icon={() => item.icon}
                              label={item.label}
                              onPress={item.onPress}
                            />
                          )
                        })}
                      </List.Accordion>
                    )}

                    {!isOnlyInvestor(currentUser) ? (
                      <List.Accordion
                        style={styles.listAccordion}
                        titleStyle={[styles.listAccordionTitle, { paddingLeft: 5 }]}
                        title="Projekty"
                        left={() => (
                          <View style={[styles.listAccIconWrapper, { width: 40 }]}>
                            <FontAwesome5 name="drafting-compass" size={DefaultTheme.icons.menuSize - 2} />
                          </View>
                        )}
                        duration={0}
                      >
                        {projectItems.map((item, index) =>
                          item.active ? (
                            <DrawerItem
                              key={index}
                              style={item.style}
                              icon={() => item.icon}
                              label={item.label}
                              onPress={item.onPress}
                            />
                          ) : (
                            <View />
                          ),
                        )}
                      </List.Accordion>
                    ) : Constants.manifest.extra.modules[modulesEnum.diary].includes(
                      Constants.manifest.extra.environment,
                    ) ? (
                      <OuterDrawerItem
                        isCollapsible={false}
                        label="Deník"
                        icon={
                          <View style={styles.outerItemIconWrapper}>
                            <FontAwesome name="calendar" size={DefaultTheme.icons.menuSize} />
                          </View>
                        }
                        onPress={() => navigation.navigate("DiaryOverview")}
                      />
                    ) : (
                      <View />
                    )}

                    {!isOnlyInvestor(currentUser) && (
                      <List.Accordion
                        style={styles.listAccordion}
                        titleStyle={[styles.listAccordionTitle, { paddingLeft: 5 }]}
                        title="Zaměstnanec"
                        left={() => (
                          <View style={styles.listAccIconWrapper}>
                            <Ionicons name="person-sharp" size={DefaultTheme.icons.menuSize} />
                          </View>
                        )}
                        duration={0}
                      >
                        {employeeItems.map((item) => (
                          <DrawerItem
                            key={item.key}
                            style={item.style}
                            icon={() => item.icon}
                            label={item.label}
                            onPress={item.onPress}
                          />
                        ))}
                      </List.Accordion>
                    )}

                    <List.Accordion
                      style={styles.listAccordion}
                      titleStyle={[styles.listAccordionTitle, { paddingLeft: 5 }]}
                      title="Kontakty"
                      left={() => (
                        <View style={styles.listAccIconWrapper}>
                          <MaterialIcons name="contacts" size={DefaultTheme.icons.menuSize} />
                        </View>
                      )}
                      duration={0}
                    >
                      {contactItems.map((item) => (
                        <DrawerItem
                          key={item.key}
                          style={item.style}
                          icon={() => item.icon}
                          label={item.label}
                          onPress={item.onPress}
                        />
                      ))}
                    </List.Accordion>

                    {!isOnlyInvestor(currentUser) && (
                      <OuterDrawerItem
                        isCollapsible={false}
                        label="Nastavení"
                        icon={
                          <View style={styles.outerItemIconWrapper}>
                            <Ionicons name="settings" size={DefaultTheme.icons.menuSize} />
                          </View>
                        }
                        onPress={() => navigation.navigate("Settings")}
                      />
                    )}
                    {Platform.OS === "web" && (
                      <>
                        <OuterDrawerItem
                          isCollapsible={false}
                          icon={
                            <View style={styles.outerItemIconWrapper}>
                              <AntDesign name="download" size={DefaultTheme.icons.menuSize} />
                            </View>
                          }
                          label="Aplikace Android"
                          onPress={() => navigation.navigate("DownloadAndroid")}
                        />
                        <OuterDrawerItem
                          isCollapsible={false}
                          icon={
                            <View style={styles.outerItemIconWrapper}>
                              <AntDesign name="download" size={DefaultTheme.icons.menuSize} />
                            </View>
                          }
                          label="Aplikace IOS"
                          onPress={() => navigation.navigate("DownloadIOS")}
                        />
                      </>
                    )}
                    <OuterDrawerItem
                      isCollapsible={false}
                      icon={
                        <View style={styles.outerItemIconWrapper}>
                          <Ionicons name="ios-information-circle-outline" size={DefaultTheme.icons.menuSize} />
                        </View>
                      }
                      label="Informace"
                      onPress={() => navigation.navigate("InfoScreen")}
                    />
                  </>
                )}
              </DrawerContentScrollView>
              {currentUser?.roles?.includes(userRoleEnum.VIEWER) ? (
                <></>
              ) : (
                <TouchableOpacity
                  onPress={() => handlePress()}
                  style={{ flexDirection: "row", padding: 15, alignItems: "center" }}
                >
                  <Entypo name="login" size={24} color="black" />
                  <Text style={{ paddingLeft: 10 }}>Změna firmy</Text>
                </TouchableOpacity>
              )}
              <View style={styles.bottomDrawerSection}>
                <List.Accordion
                  style={styles.listAccordion}
                  titleStyle={styles.listAccordionTitle}
                  title={currentUser?.name + "(" + currentUser?.email + ")"}
                  left={() => (
                    <View style={styles.listAccIconWrapper}>
                      <FontAwesome name="user" size={DefaultTheme.icons.menuSize} />
                    </View>
                  )}
                >
                  {currentUser.roles.map((role) => (
                    <DrawerItem
                      key={role}
                      icon={() => <MaterialIcons name="person-outline" size={DefaultTheme.icons.menuSize} />}
                      label={roleLabels[role]}
                      style={styles.secondMenu}
                    />
                  ))}
                  {userItems.map((item) => (
                    <DrawerItem
                      key={item.key}
                      style={item.style}
                      icon={() => item.icon}
                      label={item.label}
                      onPress={item.onPress}
                    />
                  ))}
                </List.Accordion>
              </View>
            </SafeAreaView>
          )
        }}
      >
        <Drawer.Screen
          name="StackNavigator"
          children={() => (
            <StackNavigator
              initialScreen={
                currentUser.roles.includes(userRoleEnum.VIEWER)
                  ? "DiaryOverview"
                  : Constants.manifest.extra.environment === environmentEnum.dev ||
                  Constants.manifest.extra.environment === environmentEnum.emu
                    ? InitialScreen.default || "DashboardScreen"
                    : isOnlyInvestor(currentUser) || currentUser.roles.includes(userRoleEnum.VIEWER)
                      ? "DiaryOverview"
                      : "DashboardScreen"
              }
            />
          )}
          options={{
            title: "Domu",
            headerRight: () => <FontAwesome name="bars" size={DefaultTheme.icons.menuSize} />,
            drawerIcon: () => <Ionicons name="people" size={DefaultTheme.icons.menuSize} />,
          }}
        />
      </Drawer.Navigator>
    )
  }
  return <View />
}

const styles = StyleSheet.create({
  imageItemText: {
    color: "rgba(28, 28, 30, 0.68)",
    fontWeight: "500",
    marginLeft: 32,
    marginTop: 5,
    marginBottom: 5,
  },
  imageItemWrapper: {
    marginTop: 4,
    marginBottom: 4,
    marginRight: 10,
    marginLeft: 18,
    paddingLeft: 20,
    flexDirection: "row",
    padding: 8,
    alignItems: "center",
  },
  logo: {
    marginVertical: DefaultTheme.padding.s,
    height: 70,
    width: 120,
    alignSelf: "center",
  },
  secondMenu: {
    paddingLeft: DefaultTheme.padding.m,
  },
  bottomDrawerSection: {
    borderTopColor: DefaultTheme.colors.borderPrimary,
    borderTopWidth: 1,
  },
  listAccordion: {
    paddingLeft: DefaultTheme.padding.s,
    backgroundColor: DefaultTheme.colors.white,
  },
  listAccordionTitle: {
    paddingLeft: 10,
  },
  outerItemIconWrapper: {
    paddingLeft: 10,
    width: 43,
    justifyContent: "center",
  },
  listAccIconWrapper: {
    paddingLeft: 10,
    width: 40,
    justifyContent: "center",
  },
  role: {
    paddingVertical: DefaultTheme.padding.s / 2,
  },
  tasksCount: {
    color: DefaultTheme.colors.white,
    fontSize: DefaultTheme.fonts.regular,
  },
  tasksCountWrapper: {
    position: "absolute",
    right: 15,
    top: 10,
    width: 30,
    height: 30,
    backgroundColor: DefaultTheme.colors.primary,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
  },
})
