import React from "react"
import { StyleSheet, View, Text } from "react-native"

import DefaultTheme from "../themes/DefaultTheme"

export default function ItemsCounter({ value, top, bottom, left, right, size = "medium" }) {
  const styles = getStyles(size)

  return (
    <View style={[styles.counterWrapper, top && { top }, bottom && { bottom }, left && { left }, right && { right }]}>
      <Text style={styles.counter}>{value}</Text>
    </View>
  )
}

function getStyles(size) {
  return StyleSheet.create({
    counter: {
      color: DefaultTheme.colors.white,
      fontSize: DefaultTheme.fonts.regular,
      fontSize:
        size === "medium"
          ? DefaultTheme.fonts.regular
          : size === "small"
          ? DefaultTheme.fonts.small
          : DefaultTheme.fonts.medium + 4,
    },
    counterWrapper: {
      position: "absolute",
      width: size === "medium" ? 30 : size === "small" ? 20 : 45,
      height: size === "medium" ? 30 : size === "small" ? 20 : 45,
      backgroundColor: DefaultTheme.colors.primary,
      borderRadius: 30,
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "400",
    },
  })
}
