import React, { useEffect, useState } from "react"
import { StyleSheet, Text, FlatList } from "react-native"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import Screen from "../../components/Screen"
import { useData } from "../../providers/DataProvider"
import DefaultTheme from "../../themes/DefaultTheme"
import { FAB } from "react-native-paper"
import FlatListPropertyItem from "../../components/FlatLists/FlatListPropertyItem"
import { propertyStateEnum, propertyTypeEnum } from "../../data/enums"
import { isAdmin, isExecutive, isOfficeManager } from "../../utils/validation"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import FSStoreContent from "../../firebase/FSStoreContent"

export default function PropertyUserDetail({ navigation, route }) {
  const { property, currentUser, setProperty } = useData()
  const [userProperty, setUserProperty] = useState([])
  const hasEditPermission = isAdmin(currentUser) || isExecutive(currentUser) || isOfficeManager(currentUser)

  useEffect(() => {
    ;(async () => {
      await FSStoreContent.onSnapshotProperty(setProperty)
    })()
  }, [])

  useEffect(() => {
    setUserProperty(property.filter((p) => p.owner === route.params.user.email))
  }, [property])

  return (
    <Screen>
      {userProperty.length > 0 ? (
        <FlatList
          data={userProperty}
          renderItem={({ item }) => <FlatListPropertyItem prop={item} />}
          keyExtractor={(item) => item.id.toString()}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="Nevlastníte žádný majetek" />
      )}

      {hasEditPermission && (
        <FAB
          visible={true}
          style={DefaultTheme.fab}
          color={DefaultTheme.colors.fabAdd}
          icon="plus"
          onPress={() =>
            navigation.navigate("PropertyDetail", {
              prop: {
                created_at: new Date(),
                state: propertyStateEnum.ASSIGNED,
                type: propertyTypeEnum.OTHER,
                owner: route.params.user.email,
              },
            })
          }
        />
      )}
    </Screen>
  )
}
