import React, { useState } from "react"
import { Text, View, StyleSheet } from "react-native"
import { TextInput } from "react-native-paper"
import NextButton from "../../components/NextButton"
import Validation from "../../utils/validation"
import DefaultTheme from "../../themes/DefaultTheme"
import { useData } from "../../providers/DataProvider"
import { useStatus } from "../../providers/StatusProvider"
import FSStoreContent from "../../firebase/FSStoreContent"
import { statusEnum } from "../../data/enums"
import Screen from "../../components/Screen"

export default function PasswordChange({ navigation, route }) {
  const [password, setPassword] = useState("")
  const { currentUser } = useData()
  const { setStatus, setTitle, setMessage } = useStatus()

  const passwordChange = async () => {
    navigation.navigate("StatusScreen")
    setTitle("Změna hesla")
    setStatus(statusEnum.PROGRESS_UNDETERMINED)

    await FSStoreContent.updateUser({ ...currentUser, password: password })
      .then(() => {
        setMessage("Vaše heslo bylo úspěšně změněno!")
        setStatus(statusEnum.SUCCESS)
        navigation.pop()
      })
      .catch(function (error) {
        setMessage("Chyba při změně hesla: ", error.message)
        setStatus(statusEnum.ERROR)
      })
  }

  return (
    <Screen>
      <View style={styles.innerWrapper}>
        <Text style={styles.text}>Zadejte nové heslo</Text>
        <TextInput
          error={!Validation.isPasswordValid(password)}
          label={Validation.isPasswordValid(password) ? "Heslo" : "Heslo musí mít minimálně 6 znaků"}
          style={DefaultTheme.input}
          placeholderTextColor="#aaaaaa"
          placeholder="Nové heslo"
          onChangeText={(value) => setPassword(value)}
          value={password}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
      </View>
      <NextButton disabled={!Validation.isPasswordValid(password)} text="Změnit heslo" onPress={passwordChange} />
    </Screen>
  )
}

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    justifyContent: "center",
  },
  text: {
    marginHorizontal: DefaultTheme.padding.m,
    paddingVertical: DefaultTheme.padding.s,
  },
})
