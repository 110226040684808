import React from "react"
import { TouchableOpacity, Text, StyleSheet } from "react-native"
import DefaultTheme from "../themes/DefaultTheme"

export default function PrimButton({ onPress, text, icon, width = 70, height, disabled, style }) {
  return (
    <TouchableOpacity
      disabled={disabled}
      style={[disabled ? styles.buttonDisabled : styles.button, { width, height: height || width }, style]}
      onPress={onPress}
    >
      {icon ? icon : <Text style={styles.buttonTitle}>{text}</Text>}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: DefaultTheme.colors.primary,
    marginVertical: 5,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonDisabled: {
    backgroundColor: DefaultTheme.colors.disabled,
    marginVertical: 5,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonTitle: {
    color: DefaultTheme.colors.white,
    fontSize: DefaultTheme.fonts.regular,
    fontWeight: "bold",
    paddingHorizontal: 10,
  },
})
