import React, { useState, useRef } from "react"
import { Platform, ScrollView, Text, View, TouchableOpacity } from "react-native"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import NextButton from "../../components/NextButton"
import FullWidthImagePhone from "../../components/FullWidthImagePhone"
import FullWidthImageWeb from "../../components/FullWidthImageWeb"
import Screen from "../../components/Screen"
import { scanTypeEnum, statusEnum } from "../../data/enums"
import { previewPDF } from "../../utils/general"
import { manipulateAsync, FlipType, SaveFormat } from "expo-image-manipulator"
import { uploadFileOnFirebase } from "../../firebase/FSGeneral"
import { useStatus } from "../../providers/StatusProvider"
import { Feather } from "@expo/vector-icons"
import DefaultTheme from "../../themes/DefaultTheme"

const RotateButton = ({ rotateImage }) => (
  <TouchableOpacity
    onPress={() => rotateImage()}
    style={{
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: 100,
      height: 40,
      backgroundColor: DefaultTheme.colors.primary,
      borderRadius: 10,
    }}
  >
    <Feather name="rotate-cw" size={24} color="white" />
    <Text style={{ color: "white", fontWeight: "bold", paddingLeft: 5 }}>Otočit</Text>
  </TouchableOpacity>
)

const Scan = React.memo(
  ({ uri, type, rotationIndex, isLoading, setIsLoading, rotateImage }) => {
    if (type === scanTypeEnum.FILE) {
      setIsLoading(false)
      return <View style={{ flex: 1 }}>{previewPDF(uri)}</View>
    }

    if (Platform.OS !== "web") {
      return (
        <>
          <RotateButton rotateImage={rotateImage} />
          <FullWidthImagePhone uri={uri} onLoadEnd={() => setIsLoading(false)} />
        </>
      )
    } else {
      return (
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <View style={{ padding: 10, alignSelf: "flex-end" }}>
            <RotateButton rotateImage={rotateImage} />
          </View>
          <FullWidthImageWeb
            uri={uri}
            onLoadEnd={() => setIsLoading(false)}
            style={{ transform: [{ rotate: `${(rotationIndex % 4) * 90}deg` }] }}
          />
        </ScrollView>
      )
    }
  },
  (prev, next) => {
    for (const key of Object.keys(prev)) {
      if (!next[key] || prev[key] !== next[key]) {
        return false
      }
    }
    return true
  }
)

export default function PreviewScan({ route, navigation }) {
  const { setStatus, setMessage, setTitle, setPercentUploaded } = useStatus()

  const [isLoading, setIsLoading] = useState(true)
  const uri = route.params?.uri
  const type = route.params?.type
  const [rotationIndex, setRotationIndex] = useState(0)
  const replaceUri = route.params.extras
  const updatePercentage = useRef(0)
  updatePercentage.current = (snap) => {
    const p = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
    setPercentUploaded(p)
  }

  const handleImageChange = async () => {
    setTitle("Nahrávám nový scan")
    setStatus(statusEnum.PROGRESS_UNDETERMINED)
    navigation.navigate("StatusScreen")
    const res = await fetch(uri, {
      method: "GET",
    })
    const imageBlob = await res.blob()
    var reader = new FileReader()
    reader.readAsDataURL(imageBlob)
    reader.onloadend = async () => {
      const imageData = reader.result
      const transformedImage = await manipulateAsync(imageData, [{ rotate: ((4 - rotationIndex) % 4) * 90 }])
      console.debug(transformedImage)
      const newUri = await uploadFileOnFirebase(transformedImage.uri, updatePercentage, "diary-attachments")
      console.debug("NEW URI!!!!", newUri)
      replaceUri(newUri, uri)
      setStatus(statusEnum.SUCCESS)
      setMessage("Nový scan byl nahrán")
      navigation.pop()
    }
  }

  const rotateImage = async () => {
    setRotationIndex((rotationIndex) => rotationIndex + 1)
  }

  return (
    <Screen>
      {isLoading && type === scanTypeEnum.IMAGE && <AppEmptyScreenView message="Načítám sken" />}
      <View style={isLoading ? { transform: [{ scale: 0 }], flex: 0 } : { flex: 1 }}>
        <Scan
          uri={uri}
          rotationIndex={rotationIndex}
          type={type}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          rotateImage={rotateImage}
        />
      </View>
      <NextButton text="Uložit otočený obrázek" onPress={() => handleImageChange()} />
    </Screen>
  )
}
