import Constants from "expo-constants"
import { getAutocompleteDataUrl, getPlaceDataUrl } from "../utils/backendUrls"

export async function getAutocompleteData(input, token, configId) {
  try {
    const url = getAutocompleteDataUrl(configId)

    const response = await fetch(`${url}?input=${input}&env=${configId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return await response.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getPlaceData(placeId, token, configId) {
  try {
    const url = getPlaceDataUrl(configId)

    const response = await fetch(`${url}?placeId=${placeId}&env=${configId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response.status !== 200) {
      console.log("RES", response.status)
      throw await response.json()
    }

    return await response.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}
