import React from "react"
import DefaultTheme from "../../themes/DefaultTheme"
import { View, Text, TouchableOpacity, StyleSheet } from "react-native"
import { FontAwesome } from "@expo/vector-icons"

export default function FlatListPredefinedLogbook({ item, onPress, index }) {
  const stateColor =
    item?.active || !item.hasOwnProperty("active") ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled

  return (
    <TouchableOpacity
      style={[{ ...DefaultTheme.card, height: 60, borderLeftColor: stateColor }]}
      onPress={onPress}
      key={item.value}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <FontAwesome name="suitcase" size={24} color={stateColor} />
        <View style={{ flexDirection: "column", alignItems: "space-between", justifyContent: "center", marginLeft: 5 }}>
          <Text>{item.label}</Text>
          <Text>{item.distance} km</Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}
