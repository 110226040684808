import React, { useState, useContext } from "react"

const DataContext = React.createContext()

export const useData = () => {
  return useContext(DataContext)
}

export default function DataProvider(props) {
  const [relogWith, setRelogWith] = useState(null)
  const [firebaseConfig, setFirebaseConfig] = useState(null)
  const [maintenance, setMaintenance] = useState(null)
  const [projects, setProjects] = useState([])
  const [accountingDocuments, setAccountingDocuments] = useState([])
  const [currentUser, setCurrentUser] = useState({ name: "nepřihlášen", roles: [], email: "" })
  const [users, setUsers] = useState([])
  const [settings, setSettings] = useState([])
  const [userMessages, setUserMessages] = useState([])
  const [userTasks, setUserTasks] = useState([])
  const [cashDesks, setCashDesks] = useState([])
  const [property, setProperty] = useState([])
  const [logBook, setLogBook] = useState([])
  const [premiums, setPremiums] = useState([])
  const [companyDocuments, setCompanyDocuments] = useState([])
  const [alertData, setAlertData] = useState({})
  const [backendDeployment, setBackendDeployment] = useState(null)
  const [extraDocs, setExtraDocs] = useState([])

  return (
    <DataContext.Provider
      value={{
        backendDeployment,
        setBackendDeployment,
        relogWith,
        setRelogWith,
        maintenance,
        setMaintenance,
        firebaseConfig,
        setFirebaseConfig,
        projects,
        setProjects,
        accountingDocuments,
        setAccountingDocuments,
        users,
        setUsers,
        currentUser,
        setCurrentUser,
        settings,
        setSettings,
        userMessages,
        setUserMessages,
        userTasks,
        setUserTasks,
        cashDesks,
        setCashDesks,
        property,
        setProperty,
        logBook,
        setLogBook,
        companyDocuments,
        setCompanyDocuments,
        premiums,
        setPremiums,
        alertData,
        setAlertData,
        extraDocs,
        setExtraDocs,
      }}
    >
      {props.children}
    </DataContext.Provider>
  )
}
