import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { Login, PasswordRenewal, Registration } from "../../screens"
import DefaultTheme from "../../themes/DefaultTheme"
import { View, Text } from "react-native"
import Constants from "expo-constants"
import StatusScreen from "../../screens/StatusScreen"

export default function AuthStackNavigator() {
  const Stack = createNativeStackNavigator()
  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator>
        <Stack.Screen
          name="Login"
          component={Login}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Přihlášení",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ForgottenPassword"
          component={PasswordRenewal}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Obnova hesla",
          }}
        />
        <Stack.Screen
          name="Registration"
          component={Registration}
          options={{
            ...DefaultTheme.headerStyle,
            title: "Registrace",
          }}
        />
        <Stack.Screen
          name="StatusScreen"
          component={StatusScreen}
          options={{
            ...DefaultTheme.headerStyle,
            headerShown: false,
            title: "StatusScreen",
          }}
        />
      </Stack.Navigator>
    </View>
  )
}
