export default function NumberFormatter(number) {
  const thousandSeparator = " "

  const parts = String(number).split(".")
  if (parts.length > 1) {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, `${thousandSeparator}`)
    parts[1] = parts[1]?.slice(0, 2)
    return parts.join(".")
  } else {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, `${thousandSeparator}`)
  }
}
