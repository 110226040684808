import React, { useRef } from "react"
import { View, StyleSheet, ScrollView, Platform, TouchableOpacity, Text } from "react-native"
import NextButton from "../../components/NextButton"
import { TextInput } from "react-native-paper"
import { documentCategoryLabels, monthNameLabels, paymentLabels } from "../../data/labels"
import { useStatus } from "../../providers/StatusProvider"
import { useData } from "../../providers/DataProvider"
import FSStoreContent from "../../firebase/FSStoreContent"
import Screen from "../../components/Screen"
import { documentAccountingTypeEnum, scanTypeEnum, monthStateEnum } from "../../data/enums"
import { getCashDeskMonthClosingState } from "../../utils/general"
import AppMultipleDataPicker from "../../components/AppMultipleDataPicker"
import { CheckBox } from "react-native-elements"
import PlusMinusButtons from "../../components/PlusMinusButtons"
import DefaultTheme from "../../themes/DefaultTheme"
import DocumentImage from "../../components/DocumentImage"
import { uploadFileOnFirebase } from "../../firebase/FSGeneral"

export default function VerifyUploadDocument({ navigation, route }) {
  const { projects, cashDesks, currentUser, accountingDocuments } = useData()
  const { setStatus, statusEnum, setTitle, setMessage, updatePercentage, setToScreen } = useStatus()
  const document = route.params.document ?? {}
  const type = route.params.type
  // const [plusMinusValues, setPlusMinusValues] = useState(
  //   document?.accounting_info_internal?.direction === 1 ? [true, false] : [false, true] || []
  // )
  const defaultPickedData = accountingDocuments
    .filter(
      (doc) =>
        doc.accounting_info_internal?.type === "ADVANCE" && doc.project === document.project && doc.id !== document.id
    )
    .map((doc) => {
      if (document.accounting_info_internal?.type === documentAccountingTypeEnum.INVOICE) {
        return {
          ...doc,
          isSelected: document.accounting_info_internal.advance_documents.includes(doc.id),
          // isSelected: document.accounting_info_internal.advance_documents.includes(doc.id) ? true : false, // TO REVIEW
        }
      } else return { ...doc, isSelected: false }
    })
  const isAdvance = document.accounting_info_internal?.type
    ? document?.accounting_info_internal?.type === documentAccountingTypeEnum.ADVANCE
      ? false
      : true
    : null

  console.debug("DOC", route.params.document)

  const isMonthClosed = () => {
    const userCashDesk = cashDesks.find((cd) => cd.user === currentUser.email)
    const monthYear = `${document.date.getFullYear()}_${document.date.getMonth()}`
    const hasMonthDocuments = accountingDocuments.some(
      (doc) => `${doc.date?.toDate()?.getFullYear()}_${doc.date?.toDate()?.getMonth()}` === monthYear
    )
    return hasMonthDocuments && monthStateEnum.CLOSED === getCashDeskMonthClosingState(userCashDesk, monthYear)
  }

  async function handleUploading() {
    try {
      if (isMonthClosed()) {
        throw { message: "Pokoušíte se nahrát doklad do měsíce, který je již uzavřen" }
      }

      setTitle("Nahrávám doklad")
      setMessage("Nahrávám sken dokladu")
      setToScreen("AccountingDocumentsOverview")
      setStatus(statusEnum.PROGRESS_DETERMINED)
      navigation.navigate("StatusScreen")

      const remoteUriList = []
      for (let i = 0; i < document.uriList.length; i++) {
        setMessage(`Nahrávám sken dokladu ${i + 1} / ${document.uriList.length}`)
        const remoteUri = await uploadFileOnFirebase(document.uriList[i], updatePercentage, "accountingDocuments")
        remoteUriList.push(remoteUri)
      }

      delete document.uriList

      setMessage("Nahrávám data dokladu")
      await FSStoreContent.addDocument({ ...document, filestore_uri_list: remoteUriList })
      setStatus(statusEnum.SUCCESS)
      setMessage("Doklad byl úspěšně nahrán!")
    } catch (error) {
      console.error(error)
      setTitle("Chyba při nahrávání dokladu")
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  const getProjectName = (projectId) => {
    return projects.find((project) => project.id === projectId).name
  }

  return (
    <Screen>
      <View style={{ flex: 1, overflow: "hidden" }}>
        <DocumentImage type={type} uriList={document.uriList} />
      </View>
      <ScrollView style={{ flex: 1 }}>
        <TextInput
          style={[styles.input, { marginTop: 10 }]}
          label="Název dokladu"
          placeholder="Název dokladu"
          placeholderTextColor="#aaaaaa"
          value={document.name}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
          disabled={true}
        />
        <TextInput
          style={styles.input}
          label="Cena s DPH"
          keyboardType="numeric"
          value={document.price.toString()}
          disabled={true}
        />
        <TextInput
          style={styles.input}
          label="Datum dokladu"
          webLabel={["Den vystavení dokladu", "Měsíc vystavení dokladu", "Rok vystavení dokladu"]}
          value={`${document.date.getDate()}. ${
            monthNameLabels[document.date.getMonth()]
          } ${document.date.getFullYear()}`}
          disabled={true}
        />
        <TextInput
          style={styles.input}
          label="Projekt"
          value={`[${document.project}]-[${getProjectName(document.project)}]`}
          disabled={true}
        />
        <TextInput style={styles.input} label="Dodavatel" value={document.supplier} disabled={true} />
        <TextInput
          style={styles.input}
          label="Kategorie"
          value={documentCategoryLabels[document.category]}
          disabled={true}
        />
        <TextInput style={styles.input} label="Způsob platby" value={paymentLabels[document.payment]} disabled={true} />
        <View style={styles.invoiceCheckWrapper}>
          <TouchableOpacity
            style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            onPress={() => handleAdvanceCheck(1)}
            disabled
          >
            <Text style={{ color: DefaultTheme.colors.disabled }}>Připojit k dokladu zálohovou fakturu</Text>
            <CheckBox checked={isAdvance === null ? false : isAdvance} onPress={() => handleAdvanceCheck(1)} disabled />
          </TouchableOpacity>
          {isAdvance !== null && isAdvance && (
            <AppMultipleDataPicker document={document} pickedData={defaultPickedData} disabled />
          )}
        </View>
        <View style={styles.invoiceCheckWrapper}>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onPress={() => handleAdvanceCheck(2)}
            disabled={true}
          >
            <Text style={{ color: DefaultTheme.colors.disabled }}>Označit jako zálohovou fakturu</Text>
            <CheckBox
              checked={isAdvance === null ? false : !isAdvance}
              onPress={() => handleAdvanceCheck(2)}
              disabled
            />
          </TouchableOpacity>
          {/* {isAdvance !== null && !isAdvance && (
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", paddingBottom: 10 }}>
              <Text style={{ paddingRight: 10 }}>Výdejová</Text>
              <PlusMinusButtons
                plusMinusValues={plusMinusValues}
                setPlusMinusValues={setPlusMinusValues}
                document={document}
                setDocument={setDocument}
                disabled
              />
              <Text style={{ paddingLeft: 10 }}>Příjmová</Text>
            </View>
          )} */}
        </View>
      </ScrollView>
      <NextButton text="Nahrát doklad" onPress={handleUploading} />
    </Screen>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
  },
  invoiceCheckWrapper: {
    marginBottom: 10,
    paddingLeft: 20,
    minHeight: 70,
    marginHorizontal: 10,
    backgroundColor: DefaultTheme.colors.white,
    justifyContent: "center",
  },
})
