import React, { useState } from "react"
import { View } from "react-native"
import { useData } from "../../providers/DataProvider"
import { useStatus } from "../../providers/StatusProvider"
import FSStoreContent from "../../firebase/FSStoreContent"
import { TextInput } from "react-native-paper"
import { uploadFileOnFirebase } from "../../firebase/FSGeneral"
import DefaultTheme from "../../themes/DefaultTheme"
import { useNavigation } from "@react-navigation/core"
import NextButton from "../NextButton"
import { scanTypeEnum } from "../../data/enums"

export function DocumentDataWithSave({ belongsTo, type, uriList, handleButtonPress }) {
  const navigation = useNavigation()
  const { setStatus, setMessage, statusEnum, setToScreen, setTitle, updatePercentage } = useStatus()
  const { currentUser } = useData()
  const [document, setDocument] = useState({
    name: "",
    uriList,
    created_by: currentUser.email,
    type,
    belongs_to: belongsTo,
  })
  console.debug("COMPANY DOCUMENT", document)

  function changePropertyValue(value) {
    setDocument({ ...document, name: value })
  }

  async function uploadCompanyDocument() {
    try {
      setTitle("Nahrávám dokument")
      setMessage("Nahrávání dokumentu!")
      setToScreen("DocumentsOverview", { name: document.name })
      setStatus(statusEnum.PROGRESS_DETERMINED)
      navigation.navigate("StatusScreen")
      const remoteUri = await uploadFileOnFirebase(document.uriList[0], updatePercentage, "documents")
      console.debug("URI", remoteUri)
      delete document.uriList

      await FSStoreContent.uploadCompanyDocument({ ...document, filestore_uri_list: [remoteUri] })
      setStatus(statusEnum.SUCCESS)
    } catch (error) {
      console.error(error)
      setMessage(error.message)
      setStatus(statusEnum.ERROR)
      navigation.navigate("StatusScreen")
    }
  }

  function handlePressSave() {
    if (typeof handleButtonPress === "function") {
      handleButtonPress(document)
    } else {
      uploadCompanyDocument()
    }
  }

  return (
    <>
      <View>
        <TextInput
          error={!document.name}
          style={[DefaultTheme.input, { marginTop: 10 }]}
          label={document.name ? "Název" : "Zadejte název dokumentu"}
          onChangeText={(value) => changePropertyValue(value)}
          value={document.name}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
      </View>
      <NextButton text="Nahrát" disabled={!document.name} onPress={handlePressSave} />
    </>
  )
}
