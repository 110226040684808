import React, { useEffect, useState } from "react"
import { TouchableOpacity, StyleSheet, View, Text, Platform, Dimensions } from "react-native"
import { projectStateEnum, viewTypeEnum } from "../../data/enums"
import DefaultTheme from "../../themes/DefaultTheme"
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons"
import { STATE_VIEW_PARAMS_ITEM } from "../../data/params"
import { PieChart } from "react-native-chart-kit"
import NumberFormatter from "../NumberFormatter"
import { useNavigation } from "@react-navigation/native"
import { getGeneratedExtraCashflowDocs, getGeneratedExtraCashflowDocsCache } from "../../utils/backendUrls"
import Constants from "expo-constants";
import {useData} from "../../providers/DataProvider";

export default function FlatListProjectItem({
  project,
  disabled = false,
  isHeader = false,
  viewType,
  isSyncing = false,
  selectableProjects,
  setSelectableProjects,
}) {
  const navigation = useNavigation()
  const draftProfit = project?.draft_income - project?.draft_outcome || 0
  const margin = Number(((1 - project?.draft_outcome / project?.draft_income) * 100).toFixed(2)) || 0

  const { firebaseConfig, extraDocs } = useData()
  const [actualOutcome, setActualOutcome] = useState(0)
  const [actualIncome, setActualIncome] = useState(0)
  const actualProfit = Number((actualIncome - actualOutcome).toFixed(0)) || 0
  const actualMargin = Number(((1 - actualOutcome / actualIncome) * 100).toFixed(2)) || 0

  const draftCostsProfitSum =
    (draftProfit > 0 ? draftProfit : 0) + (project?.draft_outcome > 0 ? project?.draft_outcome : 0)

  const actualCostsProfitSum = (actualProfit > 0 ? actualProfit : 0) + (actualOutcome > 0 ? actualOutcome : 0)

  const draftOutcomePercent = (project.draft_outcome / draftCostsProfitSum) * 100
  const draftProfitPercent = (draftProfit / draftCostsProfitSum) * 100
  const draftIncomePercent = (project.draft_income / draftCostsProfitSum) * 100
  const actualOutcomePercent = (actualOutcome / actualCostsProfitSum) * 100
  const actualProfitPercent = (actualProfit / actualCostsProfitSum) * 100
  //const actualIncomePercent = (actualIncome / actualCostsProfitSum) * 100
  //console.debug("project", project)


  // load extra CashFlow docs
  const loadCashflow = async () => {

    const docs = extraDocs || []

    if (docs && docs.length > 0) {
      const docsOnProject = docs.filter((document) => document.project === project.id)
      //console.log("docsOnProject:" + JSON.stringify(docsOnProject))

      const docsOnProjectIncome = docsOnProject.filter((document) => document.direction === 1)  // příjem
      const docsOnProjectOutcome = docsOnProject.filter((document) => document.direction === 0) // výdej

      let sumCashflowIncomeOnProject = 0
      docsOnProjectIncome.forEach(doc => sumCashflowIncomeOnProject += doc.total)
      let sumCashflowOutcomeOnProject = 0
      docsOnProjectOutcome.forEach(doc => sumCashflowOutcomeOnProject += doc.total)

      const actualIncomeTemp = (project.statistics?.income || 0) + (project.statistics?.income_extra || 0)
      const actualOutcomeTemp = project.statistics?.outcome || 0
      //console.log("actualIncomeTemp:" + actualIncomeTemp)
      //console.log("actualOutcomeTemp:" + actualOutcomeTemp)

      const actualIncome = actualIncomeTemp + sumCashflowIncomeOnProject
      const actualOutcome = actualOutcomeTemp + sumCashflowOutcomeOnProject
      //console.log("actualIncome:" + actualIncome)
      //console.log("actualOutcome:" + actualOutcome)

      setActualIncome(actualIncome)
      setActualOutcome(actualOutcome)

    }
  }


  useEffect(() => {
    loadCashflow()
  }, [project])

  const pieChartData = [
    { sumOfEntry: Math.abs(actualProfit), color: actualProfit > 0 ? DefaultTheme.colors.success : "red" },
    { sumOfEntry: actualOutcome, color: DefaultTheme.colors.danger },
  ]

  function switchElements(arr, posA, posB) {
    let temp = arr[posA]
    arr[posA] = arr[posB]
    arr[posB] = temp
  }

  if (actualProfit < 0) {
    switchElements(pieChartData, 0, 1)
  }

  const pieChartDataDraft = [
    { sumOfEntry: Math.abs(draftProfit) || 0, color: draftProfit > 0 ? DefaultTheme.colors.success : "red" },
    { sumOfEntry: project?.draft_outcome, color: DefaultTheme.colors.danger },
  ]

  if (draftProfit < 0) {
    switchElements(pieChartDataDraft, 0, 1)
  }

  const pieChartConfig = {
    color: (opacity = 1) => `rgba(205, 205, 205, ${opacity})`,
  }

  function getProjectColor() {
    if (
      (project.draft_outcome > project.draft_income || actualOutcome > actualIncome) &&
      viewType !== viewTypeEnum.DIARY
    ) {
      return DefaultTheme.colors.danger
    } else {
      return STATE_VIEW_PARAMS_ITEM[project.state === projectStateEnum.CLOSED].color
    }
  }

  function handleSelect() {
    setSelectableProjects(
      selectableProjects.map((proj) => {
        if (proj.id === project.id) return { ...proj, isSyncing: !isSyncing }
        return proj
      })
    )
  }

  if (isHeader) {
    return (
      <TouchableOpacity
        disabled
        style={[styles.card, { borderLeftColor: DefaultTheme.colors.primary, flexDirection: "row" }]}
      >
        <View style={{ justifyContent: "center", alignItems: "center", paddingRight: 15 }}>
          <FontAwesome5
            name="drafting-compass"
            size={DefaultTheme.icons.flatlistSize}
            color={DefaultTheme.colors.primary}
          />
        </View>
        <View style={{ flex: 1, paddingVertical: 5 }}>
          <View style={{ paddingBottom: 10 }}>
            <Text>[ID - název]</Text>
          </View>
          <View style={{ justifyContent: "center" }}>
            <View style={{ backgroundColor: "orange" }}>
              <Text style={styles.graphLabel}>Původní náklad CZK bez DPH</Text>
            </View>
            <View style={{ backgroundColor: DefaultTheme.colors.primary }}>
              <Text style={styles.graphLabel}>Původní prodej CZK bez DPH</Text>
            </View>
            <View style={{ backgroundColor: "green" }}>
              <Text style={styles.graphLabel}>Původní zisk CZK bez DPH</Text>
            </View>
            <View style={{ backgroundColor: "black" }}>
              <Text style={styles.graphLabel}>Původní marže %</Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  if (viewType === viewTypeEnum.DASHBOARD) {
    const items = [
      { backgroundColor: DefaultTheme.colors.primary, label: "Prodej", value: actualIncome },
      { backgroundColor: DefaultTheme.colors.danger, label: "Náklady", value: actualOutcome },
      { backgroundColor: actualProfit < 0 ? "red" : DefaultTheme.colors.success, label: "Zisk", value: actualProfit },
    ]

    return (
      <TouchableOpacity
        style={[
          styles.card,
          {
            flexDirection: "row",
            borderLeftColor: getProjectColor(),
          },
        ]}
        onPress={() => navigation.navigate("ProjectDetail", { project })}
      >
        <View style={{ justifyContent: "center", alignItems: "center", paddingRight: 15 }}>
          <FontAwesome5 name="drafting-compass" size={DefaultTheme.icons.flatlistSize} color={getProjectColor()} />
          {project.state === projectStateEnum.CLOSED && (
            <FontAwesome name="lock" color={DefaultTheme.colors.disabled} size={DefaultTheme.icons.flatlistSize} />
          )}
        </View>
        <View style={{ flex: 1, paddingVertical: 5, justifyContent: "center" }}>
          <Text>
            [{project.id} - {project.name}]
          </Text>
        </View>
        <View style={{ flex: 1.5, paddingVertical: 5 }}>
          {items.map(({ backgroundColor, label, value }) => (
            <View
              key={label}
              style={{ flexDirection: "row", padding: 5, justifyContent: "space-between", maxWidth: 200 }}
            >
              <View style={{ flexDirection: "row" }}>
                <View style={{ width: 20, height: 20, borderRadius: 20, backgroundColor }} />
                <Text style={{ paddingLeft: 5 }}>{label}</Text>
              </View>
              <Text style={{ paddingLeft: 20, textAlign: "right" }}>{NumberFormatter(value?.toFixed(0))}</Text>
            </View>
          ))}
        </View>
      </TouchableOpacity>
    )
  }

  if (viewType === viewTypeEnum.DIARY) {
    return (
      <TouchableOpacity
        style={[
          styles.card,
          {
            flexDirection: "row",
            borderLeftColor: getProjectColor(),
          },
        ]}
        onPress={() => navigation.navigate("ProjectDiaryDetail", { projectId: project.id })}
      >
        <View style={{ justifyContent: "center", alignItems: "center", paddingRight: 15 }}>
          <FontAwesome5 name="drafting-compass" size={DefaultTheme.icons.flatlistSize} color={getProjectColor()} />
          {project.state === projectStateEnum.CLOSED && (
            <FontAwesome name="lock" color={DefaultTheme.colors.disabled} size={DefaultTheme.icons.flatlistSize} />
          )}
        </View>
        <View style={{ flex: 1, paddingVertical: 5, justifyContent: "center" }}>
          <Text>
            [{project.id} - {project.name}]
          </Text>
        </View>
      </TouchableOpacity>
    )
  }

  if (viewType === viewTypeEnum.SYNC) {
    return (
      <TouchableOpacity
        style={[
          styles.card,
          {
            borderLeftColor: isSyncing ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled,
            flexDirection: "row",
          },
        ]}
        onPress={handleSelect}
      >
        <View style={{ justifyContent: "center", alignItems: "center", paddingRight: 15 }}>
          <FontAwesome5
            name="drafting-compass"
            size={DefaultTheme.icons.flatlistSize}
            color={isSyncing ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled}
          />
        </View>
        <View style={{ flex: 1, paddingVertical: 5, justifyContent: "center" }}>
          <Text>
            [{project.id} - {project.name}]
          </Text>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
          <Text style={{ paddingRight: 5 }}>{project.trivi_accounting_docs_sum || 0}</Text>
          <FontAwesome5
            name="file-invoice-dollar"
            size={DefaultTheme.icons.flatlistSize}
            color={isSyncing ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled}
          />
        </View>
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity
      onPress={() => navigation.navigate("ProjectDetail", { project: project })}
      disabled={disabled}
      style={[
        styles.card,
        {
          borderLeftColor: getProjectColor(),
          flexDirection: "row",
        },
      ]}
    >
      <View style={{ justifyContent: "center", alignItems: "center", paddingRight: 15 }}>
        <FontAwesome5 name="drafting-compass" size={DefaultTheme.icons.flatlistSize} color={getProjectColor()} />
        {project.state === projectStateEnum.CLOSED && (
          <FontAwesome name="lock" color={DefaultTheme.colors.disabled} size={DefaultTheme.icons.flatlistSize} />
        )}
      </View>
      <View style={{ flex: 1, paddingVertical: 5 }}>
        <View style={{ paddingBottom: 10 }}>
          {project.state === projectStateEnum.NEW && <Text style={styles.projectState}>Nový projekt</Text>}
          <Text
            style={[
              { fontWeight: "bold" },
              (project.draft_outcome > project.draft_income || actualOutcome > actualIncome) && {
                color: DefaultTheme.colors.danger,
              },
            ]}
          >
            [{project.id} - {project.name}]
          </Text>
        </View>
        <View style={{ flex: 1, justifyContent: "center" }}>
          <View style={{ flexDirection: "row", justifyContent: "space-between", paddingBottom: 5 }}>
            <Text style={{ fontWeight: "bold" }}>Původně</Text>
            <Text>
              Marže: <Text style={margin < 0 && { color: DefaultTheme.colors.danger }}>{margin} %</Text>
            </Text>
          </View>
          <View style={{ justifyContent: "space-between" }}>
            {!!project.draft_income && !!project.draft_outcome ? (
              <View style={{ flexDirection: Platform.OS === "web" ? "row" : "column-reverse" }}>
                <PieChart
                  data={pieChartDataDraft}
                  width={Dimensions.get("window").width / 2}
                  height={180}
                  chartConfig={pieChartConfig}
                  accessor="sumOfEntry"
                  backgroundColor="transparent"
                  hasLegend={false}
                  paddingLeft={Dimensions.get("window").width / 8}
                />

                <View style={{ justifyContent: "center" }}>
                  <View style={{ flexDirection: "row", justifyContent: "space-between", paddingTop: 10 }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                      <Text style={{ paddingRight: 5, width: 85, textAlign: "right" }} />
                      <View
                        style={{
                          backgroundColor: DefaultTheme.colors.primary,
                          width: 20,
                          height: 20,
                          borderRadius: 20 / 2,
                          marginBottom: 5,
                          marginRight: 5,
                        }}
                      />
                      <Text>Prodej</Text>
                    </View>
                    <Text style={{ paddingLeft: 20, textAlign: "right" }}>
                      {NumberFormatter(project.draft_income?.toFixed(0))}
                    </Text>
                  </View>

                  <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                      <Text style={{ paddingRight: 5, width: 85, textAlign: "right" }}>
                        {NumberFormatter(draftOutcomePercent.toFixed(2))} %
                      </Text>
                      <View
                        style={{
                          backgroundColor: DefaultTheme.colors.danger,
                          width: 20,
                          height: 20,
                          borderRadius: 20 / 2,
                          marginBottom: 5,
                          marginRight: 5,
                        }}
                      />
                      <Text>Náklady</Text>
                    </View>
                    <Text style={{ paddingLeft: 20, textAlign: "right" }}>
                      {NumberFormatter(project.draft_outcome?.toFixed(0))}
                    </Text>
                  </View>

                  <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                      <Text style={{ paddingRight: 5, width: 85, textAlign: "right" }}>
                        {NumberFormatter(draftProfitPercent.toFixed(2))} %
                      </Text>

                      <View
                        style={{
                          backgroundColor: draftProfit > 0 ? DefaultTheme.colors.success : "red",
                          width: 20,
                          height: 20,
                          borderRadius: 20 / 2,
                          marginBottom: 5,
                          marginRight: 5,
                        }}
                      />
                      <Text>Zisk</Text>
                    </View>
                    <Text style={{ paddingLeft: 20, textAlign: "right" }}>
                      {NumberFormatter(draftProfit.toFixed(0))}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <View style={{ flexDirection: Platform.OS === "web" ? "row" : "column-reverse" }}>
                <View style={{ alignItems: "center", width: Dimensions.get("window").width / 2, height: 180 }}>
                  <View
                    style={{
                      borderWidth: 5,
                      borderColor: DefaultTheme.colors.primary,
                      height: 144,
                      width: 144,
                      borderRadius: 72,
                    }}
                  />
                </View>
                <View style={{ justifyContent: "center", width: 229.52, paddingVertical: 10 }}>
                  <Text style={{ textAlign: Platform.OS === "web" ? "right" : "center" }}>Data nejsou k dispozici</Text>
                </View>
              </View>
            )}
          </View>
          <View style={{ flexDirection: "row", justifyContent: "space-between", paddingTop: 10, paddingBottom: 5 }}>
            <Text style={{ fontWeight: "bold" }}>Aktuálně</Text>
            <Text>Marže: {actualMargin} %</Text>
          </View>
          {!(actualProfit === 0 && actualIncome === 0 && actualOutcome === 0) ? (
            <View style={{ flexDirection: Platform.OS === "web" ? "row" : "column-reverse" }}>
              <PieChart
                data={pieChartData}
                width={Dimensions.get("window").width / 2}
                height={180}
                chartConfig={pieChartConfig}
                accessor="sumOfEntry"
                backgroundColor="transparent"
                hasLegend={false}
                paddingLeft={Dimensions.get("window").width / 8}
              />

              <View style={{ justifyContent: "center" }}>
                <View style={{ flexDirection: "row", justifyContent: "space-between", paddingTop: 10 }}>
                  <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <Text style={{ paddingRight: 5, width: 85, textAlign: "right" }} />
                    <View
                      style={{
                        backgroundColor: DefaultTheme.colors.primary,
                        width: 20,
                        height: 20,
                        borderRadius: 20 / 2,
                        marginBottom: 5,
                        marginRight: 5,
                      }}
                    />
                    <Text>Prodej</Text>
                  </View>
                  <Text style={{ paddingLeft: 20, textAlign: "right" }}>
                    {NumberFormatter(actualIncome.toFixed(0))}
                  </Text>
                </View>

                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                  <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <Text style={{ paddingRight: 5, width: 85, textAlign: "right" }}>
                      {NumberFormatter(actualOutcomePercent.toFixed(2))} %
                    </Text>
                    <View
                      style={{
                        backgroundColor: DefaultTheme.colors.danger,
                        width: 20,
                        height: 20,
                        borderRadius: 20 / 2,
                        marginBottom: 5,
                        marginRight: 5,
                      }}
                    />
                    <Text>Náklady</Text>
                  </View>
                  <Text style={{ paddingLeft: 20, textAlign: "right" }}>
                    {NumberFormatter(actualOutcome.toFixed(0))}
                  </Text>
                </View>

                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                  <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <Text style={{ paddingRight: 5, width: 85, textAlign: "right" }}>
                      {NumberFormatter(actualProfitPercent.toFixed(2))} %
                    </Text>

                    <View
                      style={{
                        backgroundColor: actualProfit > 0 ? DefaultTheme.colors.success : "red",
                        width: 20,
                        height: 20,
                        borderRadius: 20 / 2,
                        marginBottom: 5,
                        marginRight: 5,
                      }}
                    />
                    <Text>Zisk</Text>
                  </View>
                  <Text style={{ paddingLeft: 20, textAlign: "right" }}>
                    {NumberFormatter(actualProfit.toFixed(0))}
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View style={{ flexDirection: Platform.OS === "web" ? "row" : "column-reverse" }}>
              <View style={{ alignItems: "center", width: Dimensions.get("window").width / 2, height: 180 }}>
                <View
                  style={{
                    borderWidth: 5,
                    borderColor: DefaultTheme.colors.primary,
                    height: 144,
                    width: 144,
                    borderRadius: 72,
                  }}
                />
              </View>
              <View style={{ justifyContent: "center", width: 229.52, paddingVertical: 10 }}>
                <Text style={{ textAlign: Platform.OS === "web" ? "right" : "center" }}>Data nejsou k dispozici</Text>
              </View>
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    minHeight: 100,
    justifyContent: "center",
    width: "100%",
    paddingHorizontal: 15,
    paddingVertical: 5,
    backgroundColor: DefaultTheme.colors.white,
    borderLeftWidth: 10,
  },
  projectDescription: {
    alignSelf: Platform.OS === "web" ? "flex-end" : "flex-start",
  },
  graphLabel: {
    alignSelf: "flex-end",
    color: "white",
    padding: 5,
  },
  projectState: {
    color: DefaultTheme.colors.newItem,
  },
})
