import React, { useEffect, useState } from "react"
import { View, StyleSheet, Text, Image, SafeAreaView, ScrollView } from "react-native"
import { useData } from "../providers/DataProvider"
import { PieChart } from "react-native-chart-kit"
import { MaterialCommunityIcons, FontAwesome5, FontAwesome } from "@expo/vector-icons"

import DefaultTheme from "../themes/DefaultTheme"
import Screen from "../components/Screen"
import { approveEnum, companyCostsEnum, paymentEnum, userRoleEnum, userStateEnum, viewTypeEnum } from "../data/enums"
import FlatListTaskItem from "../components/FlatLists/FlatListTaskItem"
import PieChartLegendItem from "../components/PieChartLegendItem"
import { isAdmin, isDateInCurrentMonth, isExecutive, isOfficeManager, isOnlyInvestor } from "../utils/validation"
import ClosingsDashboard from "../components/dashboard/ClosingsDashboard"
import DefaultDashboard from "../components/dashboard/DefaultDashboard"
import StatisticsDashboard from "../components/dashboard/StatisticsDashboard"
import CashflowDashboard from "../components/dashboard/CashflowDashboard"

export default function DashboardScreen({ route, navigation }) {
  const {
    currentUser,
    accountingDocuments,
    projects,
    userTasks,
    logBook,
    users,
    firebaseConfig,
    relogWith,
    setRelogWith,
  } = useData()
  const today = new Date()
  const [lastTask, setLastTask] = useState(null)
  const viewType = route.params?.viewType

  useEffect(() => {
    let latestTask = null
    userTasks.forEach((t) => {
      if (t.created_at.seconds > lastTask?.created_at?.seconds || latestTask === null) latestTask = t
    })
    setLastTask(latestTask)
  }, [userTasks])

  const isInvalidUser = (user) => {
    return (
      user?.dashboard_closings !== approveEnum.ACCEPTED ||
      user?.disabled ||
      user.state === userStateEnum.DELETED ||
      isOnlyInvestor(user) ||
      isAdmin(user) ||
      user?.admin
    )
  }

  const DashboardComponentWrapper = () => {
    if (isExecutive(currentUser) || isAdmin(currentUser)) {
      if (viewType === viewTypeEnum.CLOSINGS) {
        if (users) return <ClosingsDashboard users={users.filter((user) => !isInvalidUser(user))} />
        return <></>
      }
      return <StatisticsDashboard />
    } else if (isOfficeManager(currentUser)) {
      if (users) return <ClosingsDashboard users={users.filter((user) => !isInvalidUser(user))} />
    }
    return <DefaultDashboard />
  }

  return (
    <Screen>
      <SafeAreaView style={{ flex: 1 }}>
        {viewType === viewTypeEnum.CASHFLOW ? (
          <CashflowDashboard />
        ) : (
          <>
            <View style={styles.header}>
              <Image
                source={
                  firebaseConfig === "prod" ? require("./../assets/dasar_logo.png") :
                  firebaseConfig === "prod-1" ? require("./../assets/dasar_invest_logo.png") :
                  firebaseConfig === "prod-development" ? require("./../assets/dasar_development_logo.png"):
                  require("./../assets/dasar_logo.png")
                }
                style={styles.logo}
              />
              <View>
                <Text>Vítej {currentUser.name ? currentUser.name : currentUser.email}</Text>
                <Text>{`Dnes je ${today.getDate()}. ${today.getMonth() + 1}. ${today.getFullYear()}`}</Text>
              </View>
            </View>
            <DashboardComponentWrapper />
            <View>
              <View style={{ flexDirection: "row", padding: 10, justifyContent: "space-around" }}>
                <View style={styles.textWithIcon}>
                  <FontAwesome5
                    name="drafting-compass"
                    size={24}
                    style={styles.iconSmall}
                    color={DefaultTheme.colors.primary}
                  />
                  <Text>{projects.length}</Text>
                </View>

                <View style={styles.textWithIcon}>
                  <FontAwesome5
                    name="file-invoice-dollar"
                    size={24}
                    style={styles.iconSmall}
                    color={DefaultTheme.colors.primary}
                  />
                  <Text>{accountingDocuments.length}</Text>
                </View>
                <View style={styles.textWithIcon}>
                  <FontAwesome5 name="tasks" size={24} style={styles.iconSmall} color={DefaultTheme.colors.primary} />
                  <Text>{userTasks.length}</Text>
                </View>
                <View style={styles.textWithIcon}>
                  <FontAwesome name="suitcase" size={24} style={styles.iconSmall} color={DefaultTheme.colors.primary} />
                  <Text>{logBook.length}</Text>
                </View>
              </View>
            </View>
            {lastTask ? (
              <FlatListTaskItem task={lastTask || {}} navigation={navigation} />
            ) : (
              <FlatListTaskItem
                task={{ title: "Nemáte žádné úkoly" }}
                viewType={viewTypeEnum.EMPTY}
                navigation={navigation}
              />
            )}
          </>
        )}
      </SafeAreaView>
    </Screen>
  )
}

const styles = StyleSheet.create({
  logo: {
    marginRight: DefaultTheme.padding.m,
    width: 100,
    height: 60,
    resizeMode: "contain",
    alignSelf: "center",
  },
  row: {
    flexDirection: "row",
  },
  title: {
    width: "100%",
    textAlign: "center",
    fontSize: DefaultTheme.fonts.medium,
  },
  wrapper: {
    flex: 1,
    padding: 10,
    alignItems: "center",
  },
  header: {
    padding: DefaultTheme.padding.s,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  iconSmall: {
    paddingRight: 5,
  },
  textWithIcon: {
    flexDirection: "row",
    width: 40,
    justifyContent: "space-between",
    alignItems: "center",
  },
})
