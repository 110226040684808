import React, { useEffect, useState } from "react"
import { Text, TouchableOpacity, View, StyleSheet } from "react-native"
import DefaultTheme from "../../themes/DefaultTheme"
import { holidayStateLabels, monthNameLabels } from "../../data/labels"
import { MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons"
import { STATE_VIEW_PARAMS_ATTENDANCE_DAY, STATE_VIEW_PARAMS_ITEM } from "../../data/params"
import {
  attendanceBulkActionEnum,
  attendanceOperationTypeEnum,
  attendanceTypeEnum,
  covidResultEnum,
  holidayStateEnum,
  monthStateEnum,
  viewTypeEnum,
} from "../../data/enums"
import { dayNameLabels } from "../../data/labels"
import { changeHolidayState, getHolidayOperationBulkState, getHolidayState } from "../../utils/general"
import { isAccountant, isAdmin, isExecutive, isOfficeManager } from "../../utils/validation"
import { useData } from "../../providers/DataProvider"

export default function FlatListAttendanceDayItem({
  navigation,
  dayAttendance,
  userEmail,
  monthIndex,
  viewType,
  selectedAction,
  setAttendances,
  attendancesToChange,
  freeDaysCount,
  setFreeDaysCount,
  closedState,
}) {
  const { currentUser } = useData()

  const [isSelected, setIsSelected] = useState(false)
  const [oldAttendance, setOldAttendance] = useState(dayAttendance)

  useEffect(() => {
    setIsSelected(false)
  }, [])

  const hasEditPermission =
    isAdmin(currentUser) ||
    isExecutive(currentUser) ||
    isAccountant(currentUser) ||
    isOfficeManager(currentUser) ||
    monthIndex <= 0

  function handleCheck() {
    if (
      selectedAction.type !== dayAttendance.attendance_type ||
      getHolidayState(dayAttendance) !== getHolidayOperationBulkState(selectedAction.value)
    ) {
      setOldAttendance(dayAttendance)
    }

    const hasFreeDays = handleFreeDays()
    if (!hasFreeDays && !isSelected) {
      return
    }

    setAttendances(
      attendancesToChange.map((att) => {
        if (att.attendance_date === dayAttendance.attendance_date) {
          let newDayAttendance = {
            ...dayAttendance,
            isSelected: !isSelected,
            attendance_type: !isSelected ? selectedAction.type : oldAttendance.attendance_type,
          }

          if (selectedAction.value === attendanceTypeEnum.WORK) {
            newDayAttendance = { ...newDayAttendance, overtime_hours: !isSelected ? 0 : oldAttendance.overtime_hours }
          } else if (selectedAction.type === attendanceTypeEnum.HOLIDAY) {
            newDayAttendance = {
              ...newDayAttendance,
              attendance_place: !isSelected
                ? getAttendancePlace(selectedAction.value, dayAttendance)
                : oldAttendance.attendance_place,
              operations: changeHolidayState(
                newDayAttendance,
                attendanceOperationTypeEnum.HOLIDAY_APPROVE,
                !isSelected ? getHolidayOperationBulkState(selectedAction.value) : getHolidayState(oldAttendance),
                currentUser
              ),
            }
          }

          return newDayAttendance
        }
        return att
      })
    )

    setIsSelected(!isSelected)
  }

  function handleFreeDays() {
    if (
      selectedAction.value === attendanceBulkActionEnum.HOLIDAY_CZ ||
      selectedAction.value === attendanceBulkActionEnum.HOLIDAY_OTHER ||
      selectedAction.value === attendanceBulkActionEnum.HOLIDAY_APPROVE
    ) {
      if (freeDaysCount === 0) {
        setFreeDaysCount(!isSelected ? freeDaysCount - 0 : freeDaysCount + 1)
      }
      if (freeDaysCount > 0) {
        setFreeDaysCount(!isSelected ? freeDaysCount - 1 : freeDaysCount + 1)
      }
      if (freeDaysCount < 1) {
        return false
      }
    }
    return true
  }

  function getAttendancePlace(actionValue, attendance) {
    return actionValue === attendanceBulkActionEnum.HOLIDAY_APPROVE ||
      actionValue === attendanceBulkActionEnum.HOLIDAY_REJECT
      ? attendance.attendance_place
      : actionValue.split("_")[1]
  }

  function getAttendanceType() {
    if (dayAttendance.attendance_type) {
      const attendanceType = dayAttendance.attendance_type
      if (attendanceType === attendanceTypeEnum.WORK) {
        return attendanceTypeEnum.WORK
      } else if (attendanceType === attendanceTypeEnum.HOLIDAY) {
        return attendanceTypeEnum.HOLIDAY
      } else if (attendanceType === attendanceTypeEnum.SICKDAY) {
        return attendanceTypeEnum.SICKDAY
      } else if (attendanceType === attendanceTypeEnum.HOMEOFFICE) {
        return attendanceTypeEnum.HOMEOFFICE
      }
      // return attendanceTypeEnum.NOT_STATED_PAST
    }
    // return attendanceTypeEnum.NOT_STATED_FUTURE
  }

  const attendanceDescription = {
    [attendanceTypeEnum.WORK]: dayAttendance.overtime_hours
      ? `8,5h + ${dayAttendance.overtime_hours}h přesčas`
      : "8,5h",
    [attendanceTypeEnum.HOLIDAY]: `Dovolená ${
      holidayStateLabels[
        isSelected ? getHolidayOperationBulkState(selectedAction.value) : getHolidayState(dayAttendance)
      ]
    }`,
    [attendanceTypeEnum.SICKDAY]: "SickDay",
    [attendanceTypeEnum.HOMEOFFICE]: "Práce z domova",
    [attendanceTypeEnum.NOT_STATED_PAST]: "",
    [attendanceTypeEnum.NOT_STATED_FUTURE]: "Zatím neurčeno",
  }

  const dateTitle = `${dayAttendance.attendance_date.getDate()}. ${
    monthNameLabels[dayAttendance.attendance_date.getMonth()]
  } ${dayAttendance.attendance_date.getFullYear()}`

  if (viewType === viewTypeEnum.BULK_ACTION)
    return (
      <TouchableOpacity
        style={[
          styles.card,
          {
            borderLeftColor: isSelected ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled,
          },
        ]}
        onPress={handleCheck}
      >
        <View style={{ alignItems: "center", width: 30 }}>
          {
            <MaterialCommunityIcons
              {...STATE_VIEW_PARAMS_ATTENDANCE_DAY[isSelected ? selectedAction.type : getAttendanceType()]}
              color={isSelected ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled}
              size={DefaultTheme.icons.flatlistSize}
            />
          }
        </View>

        <View style={{ flex: 1 }}>
          <Text style={styles.dayTitle}>{`${dayAttendance.attendance_date.getDate()}. ${
            monthNameLabels[dayAttendance.attendance_date.getMonth()]
          } [${dayNameLabels[dayAttendance.attendance_date.getDay()]}]`}</Text>
        </View>
        <View style={{ flex: 1, paddingHorizontal: 10 }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {dayAttendance.attendance_type === attendanceTypeEnum.HOLIDAY && (
              <FontAwesome
                {...STATE_VIEW_PARAMS_ITEM[
                  isSelected ? getHolidayOperationBulkState(selectedAction.value) : getHolidayState(dayAttendance)
                ]}
                size={DefaultTheme.icons.flatlistSize}
                style={{ paddingRight: 10 }}
              />
            )}
            <Text>{attendanceDescription[isSelected ? selectedAction.type : getAttendanceType()]}</Text>
          </View>
          {dayAttendance.covid_test?.result && (
            <Text>
              Test:{" "}
              {dayAttendance.covid_test?.result === covidResultEnum.NEGATIVE
                ? "NEGATIVNÍ"
                : dayAttendance.covid_test?.result === covidResultEnum.POSITIVE
                ? "POZITIVNÍ"
                : "NEPLATNÝ"}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    )

  return (
    <TouchableOpacity
      style={[
        styles.card,
        {
          borderLeftColor:
            closedState === monthStateEnum.OPEN ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled,
        },
      ]}
      onPress={() =>
        navigation.navigate("AttendanceDayDetail", {
          dateTitle,
          userEmail,
          dayAttendance,
          monthIndex,
          freeDaysCount,
          closedState,
        })
      }
    >
      <View style={{ alignItems: "center", width: 30 }}>
        {
          <MaterialCommunityIcons
            {...STATE_VIEW_PARAMS_ATTENDANCE_DAY[getAttendanceType()]}
            color={closedState === monthStateEnum.OPEN ? DefaultTheme.colors.primary : DefaultTheme.colors.disabled}
            size={DefaultTheme.icons.flatlistSize}
          />
        }
        {closedState !== monthStateEnum.OPEN && (
          <FontAwesome name="lock" color={DefaultTheme.colors.disabled} size={DefaultTheme.icons.flatlistSize} />
        )}
      </View>

      <View style={{ flex: 1 }}>
        <Text style={styles.dayTitle}>{`${dayAttendance.attendance_date.getDate()}. ${
          monthNameLabels[dayAttendance.attendance_date.getMonth()]
        } [${dayNameLabels[dayAttendance.attendance_date.getDay()]}]`}</Text>
      </View>

      <View style={{ flex: 1, paddingHorizontal: 10 }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {dayAttendance.attendance_type === attendanceTypeEnum.HOLIDAY && (
            <FontAwesome
              {...STATE_VIEW_PARAMS_ITEM[getHolidayState(dayAttendance)]}
              size={DefaultTheme.icons.flatlistSize}
              style={{ paddingRight: 10 }}
            />
          )}
          <Text>{attendanceDescription[getAttendanceType()]}</Text>
        </View>
        {dayAttendance.covid_test?.result && (
          <Text>
            Test:{" "}
            {dayAttendance.covid_test?.result === covidResultEnum.NEGATIVE
              ? "NEGATIVNÍ"
              : dayAttendance.covid_test?.result === covidResultEnum.POSITIVE
              ? "POZITIVNÍ"
              : "NEPLATNÝ"}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  card: {
    minHeight: 60,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    paddingRight: 10,
    flexDirection: "row",
    backgroundColor: DefaultTheme.colors.white,
    borderLeftWidth: 10,
  },
  dayTitle: {
    paddingLeft: 10,
  },
})
