import React from "react"
import AppPicker from "./AppPicker"
import { dayNameLabels } from "../data/labels"
import { View } from "react-native"

export default function DayNamePicker({ value, setValue, weekDaysOnly = false }) {
  const dayNameItems = Object.keys(dayNameLabels)
    .filter((el) => (weekDaysOnly ? el > 0 && el < 6 : true))
    .map((key) => {
      return { value: Number.parseInt(key), label: dayNameLabels[key] }
    })

  return (
    <View>
      <AppPicker value={value} onValueChange={setValue} items={dayNameItems} descriptionLabel="Den" />
    </View>
  )
}
