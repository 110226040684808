import React, { useEffect, useState } from "react"
import { TouchableOpacity, StyleSheet, Text, View, Image } from "react-native"
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"

import DefaultTheme from "../../themes/DefaultTheme"
import { getUserName } from "../../utils/general"
import { useData } from "../../providers/DataProvider"
import { propertyStateLabels, propertyTypeLabels } from "../../data/labels"
import {
  approveEnum,
  projectStateEnum,
  propertyOperationTypeEnum,
  propertyStateEnum,
  propertyTypeEnum,
  propertyViewTypeEnum,
  viewTypeEnum,
} from "../../data/enums"
import { STATE_VIEW_PARAMS_ITEM, STATE_VIEW_PARAMS_PROPERTY } from "../../data/params"
import CurrencyFormatter from "../CurrencyFormatter"
import NumberFormatter from "../NumberFormatter"
import ApproveButtons from "../ApproveButtons"
import FSStoreContent from "../../firebase/FSStoreContent"

export default function FlatListPropertyItem({
  project,
  projectPropertyList,
  prop: argProp = {},
  propId,
  setProjectPropertyList,
  viewType,
  disabled = false,
  onPress,
}) {
  const { users, currentUser } = useData()
  const navigation = useNavigation()
  // const [prop, setProp] = useState({})
  const [prop, setProp] = useState(argProp)

  const propReturnedState = prop.operations?.find(
    (o) => o.action_type === propertyOperationTypeEnum.STATE_RETURNED
  )?.action_value
  const propBorrowedState = prop.operations?.find(
    (o) => o.action_type === propertyOperationTypeEnum.STATE_BORROWED
  )?.action_value

  useEffect(() => {
    getProp()
  }, [propId, argProp])

  async function getProp() {
    const newProp = await FSStoreContent.getProperty(propId || prop.id)
    const property = await FSStoreContent.enrichProperty(newProp)
    if (property) {
      setProp(property)
    }
  }

  function getPropertyStateColor() {
    if (prop.type === propertyTypeEnum.NOT_STATED) {
      return DefaultTheme.colors.danger
    } else if (prop.state === propertyStateEnum.ASSIGNED) {
      return DefaultTheme.colors.primary
    } else if (prop.state === propertyStateEnum.SHARED) {
      return DefaultTheme.colors.primary
    }
    return DefaultTheme.colors.disabled
  }

  function handleValueChange(actionType, value) {
    console.debug("ACT TYPE", actionType)
    console.debug("ACT VALUE", value)
    console.debug(
      "INCLUDES ACTION TYPE",
      argProp.operations?.some((o) => o.action_type === actionType)
    )
    const newOperation = {
      action_type: actionType,
      action_value: value,
      email: currentUser.email,
      created_at: new Date(),
    }
    let newOperations = []

    if (argProp.operations?.some((o) => o.action_type === actionType)) {
      newOperations = argProp.operations.map((o) => {
        if (o.action_type === actionType) {
          return newOperation
        }
        return o
      })
    } else {
      newOperations = [...(argProp.operations || []), newOperation]
    }

    setProjectPropertyList(
      projectPropertyList.map((p) => (p.id === argProp.id ? { ...p, operations: newOperations } : p))
    )
  }

  function handlePress() {
    if (onPress) {
      onPress(prop.id)
    } else {
      navigation.navigate("PropertyDetail", { prop, toScreen: "PropertyOverview" })
    }
  }

  return (
    <TouchableOpacity
      style={[styles.card, { borderLeftColor: getPropertyStateColor() }]}
      onPress={handlePress}
      disabled={disabled}
    >
      <View style={{ flex: 1 }}>
        {prop.filestore_uri ? (
          <View>
            {viewType === propertyViewTypeEnum.PROJECT && <Text style={{ padding: 5 }}>{prop.name}</Text>}
            <Image source={{ uri: prop.filestore_uri }} style={{ height: 80, width: "100%" }} resizeMode="contain" />
          </View>
        ) : (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <MaterialCommunityIcons
              name={STATE_VIEW_PARAMS_PROPERTY[true].name}
              size={DefaultTheme.icons.flatlistSize}
              color={getPropertyStateColor()}
              style={{ paddingRight: 10 }}
            />
            <View style={{ flex: 1, justifyContent: "space-between" }}>
              <Text>{viewType === propertyViewTypeEnum.PROJECT ? prop.name : "Nahrajte prosím obrázek majetku"}</Text>
            </View>
          </View>
        )}
      </View>
      {viewType === propertyViewTypeEnum.PROJECT ? (
        <View style={{ alignItems: "flex-end" }}>
          <View style={{ flexDirection: "row", alignItems: "center", padding: 5 }}>
            <Text style={{ padding: 5 }}>Převzato</Text>
            <ApproveButtons
              action_type={propertyOperationTypeEnum.STATE_BORROWED}
              changeValue={handleValueChange}
              disabled={project.state === projectStateEnum.CLOSED}
              initialState={
                prop.operations?.find((o) => o.action_type === propertyOperationTypeEnum.STATE_BORROWED)
                  ?.action_value || approveEnum.PENDING
              }
              style={{ padding: 5, backgroundColor: "#F8F8F8", borderRadius: 5 }}
            />
          </View>
          <View style={{ flexDirection: "row", alignItems: "center", padding: 5 }}>
            <Text style={{ padding: 5 }}>Vráceno</Text>
            <ApproveButtons
              action_type={propertyOperationTypeEnum.STATE_RETURNED}
              changeValue={handleValueChange}
              disabled={project.state === projectStateEnum.CLOSED}
              initialState={
                prop.operations?.find((o) => o.action_type === propertyOperationTypeEnum.STATE_RETURNED)
                  ?.action_value || approveEnum.PENDING
              }
              style={{ padding: 5, backgroundColor: "#F8F8F8", borderRadius: 5 }}
            />
          </View>
        </View>
      ) : (
        <View style={{ flex: 2, alignItems: "flex-end", paddingHorizontal: 10 }}>
          <Text style={{ textAlign: "right" }}>{`Název:  ${prop.name}`}</Text>
          {prop.type === propertyTypeEnum.VEHICLE && (
            <View>
              <Text style={{ textAlign: "right" }}>SPZ: {prop.licence_plate_editable || prop.licence_plate}</Text>
              <Text style={{ textAlign: "right" }}>Aktuální nájezd: {NumberFormatter(prop.actual_km)} KM</Text>
            </View>
          )}
          <Text
            style={[
              {
                textAlign: "right",
              },
              prop.type === propertyTypeEnum.NOT_STATED && {
                color: DefaultTheme.colors.danger,
              },
            ]}
          >{`Typ:  ${propertyTypeLabels[prop.type] || propertyTypeLabels[propertyTypeEnum.NOT_STATED]}`}</Text>
          <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
            <Text
              style={{
                textAlign: "right",
                paddingRight: propertyStateEnum.ASSIGNED ? 5 : 0,
              }}
            >
              {`Vlastník:  ${
                getUserName(users, prop.owner) ||
                (prop.state === propertyStateEnum.ASSIGNED ? "Není určen" : propertyStateLabels[prop.state])
              }`}
            </Text>
            {!!prop.owner && (
              <FontAwesome
                {...STATE_VIEW_PARAMS_ITEM[prop.owner_approve_state || approveEnum.PENDING]}
                size={DefaultTheme.icons.flatlistSize}
              />
            )}
          </View>
          <Text style={{ textAlign: "right" }}>Cena: {CurrencyFormatter(prop.price, "CZK", 0)}</Text>
          <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
            <Text style={{ textAlign: "right" }}>Přiřazený projekt: {prop.assigned_project || "Žádný"}</Text>
          </View>
          {prop.assigned_project ? (
            <View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={{ paddingRight: 5 }}>Převzato</Text>
                <FontAwesome
                  {...STATE_VIEW_PARAMS_ITEM[propBorrowedState || approveEnum.PENDING]}
                  size={DefaultTheme.icons.flatlistSize}
                />
              </View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={{ paddingRight: 5 }}>Vráceno</Text>
                <FontAwesome
                  {...STATE_VIEW_PARAMS_ITEM[propReturnedState || approveEnum.PENDING]}
                  size={DefaultTheme.icons.flatlistSize}
                />
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    minHeight: 100,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 15,
    borderLeftWidth: 10,
    paddingVertical: 10,
    flexDirection: "row",
    backgroundColor: DefaultTheme.colors.white,
  },
})
