import React, { useEffect, useState } from "react"
import { Text, View, FlatList } from "react-native"
import NextButton from "../../components/NextButton"
import { useData } from "../../providers/DataProvider"
import FSStoreContent from "../../firebase/FSStoreContent"
import { projectStateEnum, statusEnum } from "../../data/enums"
import { useStatus } from "../../providers/StatusProvider"
import { getUserName } from "../../utils/general"
import Screen from "../../components/Screen"
import { isAdmin, isExecutive, isTechnicalDirector } from "../../utils/validation"
import FlatListProjectPremiumItem from "../../components/FlatLists/FlatListProjectPremiumItem"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"

export default function ProjectPremium({ navigation, route }) {
  const { projects, users, currentUser } = useData()
  const { setStatus, setTitle, setMessage } = useStatus()
  const [project, setProject] = useState({})
  const premiumTotal = (project.users?.reduce((a, c) => (c.premium || 0) + a, 0) || 0).toFixed(4)
  const hasEditPermission = isAdmin(currentUser) || isExecutive(currentUser) || isTechnicalDirector(currentUser)

  useEffect(() => {
    const filteredProject = projects.filter((project) => project.id === route.params.project.id)[0]
    if (filteredProject) {
      filteredProject.users?.sort((a, b) => (getUserName(users, a.email) > getUserName(users, b.email) ? 1 : -1))
      setProject(filteredProject)
    }
  }, [projects])

  const updateProject = async () => {
    setStatus(statusEnum.PROGRESS_UNDETERMINED)
    setTitle("Ukládám rozdělení prémií")
    navigation.pop()
    navigation.navigate("StatusScreen")

    if (hasEditPermission) {
      await FSStoreContent.updateProject(project).then(() => {
        setMessage("Prémie byly uloženy")
        setStatus(statusEnum.SUCCESS)
      })
    } else {
      setTitle("Nemáte oprávnění pro úpravu projektu")
      setStatus(statusEnum.ERROR)
    }
  }

  return (
    // <Screen barStyle="light-content">
    <Screen>
      {/* TEMPORARY REPORTING*/}
      <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 10 }}>
        <View>
          <Text>Rozdělené prémie</Text>
          <Text>Prémie na rozdělení</Text>
        </View>
        <View>
          <Text>{(premiumTotal * 100).toFixed(2)} %</Text>
          <Text>{((1 - premiumTotal) * 100).toFixed(2)} %</Text>
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <FlatList
          data={project.users}
          renderItem={({ item }) => (
            <FlatListProjectPremiumItem
              projUser={item}
              navigation={navigation}
              project={project}
              setProject={setProject}
            />
          )}
          keyExtractor={(item) => item.email}
          ItemSeparatorComponent={FlatListSeparator}
        />
      </View>
      {!(0 <= premiumTotal && premiumTotal <= 1) && (
        <View style={{ alignItems: "center", padding: 10 }}>
          <Text style={{ color: "red" }}>Váš bonus přesáhl 100%</Text>
        </View>
      )}
      <NextButton
        disabled={
          !(hasEditPermission && project.state !== projectStateEnum.CLOSED && 0 <= premiumTotal && premiumTotal <= 1)
        }
        onPress={updateProject}
        text="Uložit"
      />
    </Screen>
  )
}
