import React, { useEffect, useState } from "react"
import { Text, TouchableOpacity, View, StyleSheet } from "react-native"
import { STATE_VIEW_PARAMS_PREMIUM, STATE_VIEW_PARAMS_USER } from "../../data/params"
import DefaultTheme from "../../themes/DefaultTheme"
import { MaterialCommunityIcons, Ionicons } from "@expo/vector-icons"
import { useData } from "../../providers/DataProvider"
import { filterUserProjects, getUserLastPremiumAction } from "../../utils/general"
import CurrencyFormatter from "../../components/CurrencyFormatter"
import { approveEnum, premiumActionTypeEnum } from "../../data/enums"

export default function FlatListPremiumOverviewItem({ navigation, user }) {
  const { projects, premiums } = useData()
  const [userPremiumTotal, setUserPremiumTotal] = useState(0)

  useEffect(() => {
    let userPremiumCounter = 0

    filterUserProjects(projects, user).forEach((proj) => {
      const projUser = proj.users.find((u) => u.email === user.email)
      const premiumPercentage = projUser.premium || 0

      const draftOverProfit = (proj.draft_income || 0) - (proj.draft_outcome || 0)
      const actualOverProfit =
        (proj.statistics?.income || 0) + (proj.statistics?.income_extra || 0) - (proj.statistics?.outcome || 0)

      const userPremiums = premiums.find((p) => p.email === user.email)
      if (
        userPremiums?.[proj.id]?.requesting_items &&
        isPremiumPaid(getUserLastPremiumAction(userPremiums?.[proj.id].requesting_items))
      ) {
        console.debug("PREMIUM IS PAID")
      } else {
        userPremiumCounter += (actualOverProfit - draftOverProfit) * premiumPercentage
      }
    })

    setUserPremiumTotal(userPremiumCounter)
  }, [projects, premiums])

  function isPremiumPaid(premiumAction) {
    return (
      premiumAction?.action_type === premiumActionTypeEnum.DECISION &&
      premiumAction?.action_value === approveEnum.ACCEPTED
    )
  }

  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate("PremiumUserProjects", {
          user,
          title: true,
        })
      }
      style={[styles.card, { borderLeftColor: STATE_VIEW_PARAMS_PREMIUM[user.disabled].color }]}
    >
      <View style={{ paddingRight: 15 }}>
        <Ionicons {...STATE_VIEW_PARAMS_USER[user.disabled]} style={{ paddingLeft: 1 }} />
        <MaterialCommunityIcons {...STATE_VIEW_PARAMS_PREMIUM[user.disabled]} size={DefaultTheme.icons.flatlistSize} />
      </View>
      <View style={{ flex: 1 }}>
        <Text>{user.name}</Text>
        <Text>{user.email}</Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={{ textAlign: "right" }}>Celkové prémie:</Text>
        <Text style={{ textAlign: "right", fontWeight: "bold" }}>{CurrencyFormatter(userPremiumTotal, "CZK", 0)}</Text>
      </View>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  card: {
    height: 100,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingHorizontal: 15,
    flexDirection: "row",
    borderLeftWidth: 10,
    backgroundColor: DefaultTheme.colors.white,
  },
  hint: {
    fontStyle: "italic",
    color: DefaultTheme.colors.placeholder,
    paddingLeft: 10,
  },
})
