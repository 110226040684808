import React from "react"
import { View, Text, StyleSheet } from "react-native"
import DefaultTheme from "../themes/DefaultTheme"
import Screen from "../components/Screen"
import Constants from "expo-constants"
import {firebase as fb} from "../firebase/config";

export default function InfoScreen({ navigation }) {
  return (
    <Screen>
      <View style={styles.companyWrapper}>
        <Text style={styles.header}>{ fb.apps[0].name === "prod" ? ("DASAR PR s.r.o.") : ("DASAR invest s.r.o.") }</Text>
        <View style={styles.innerWrapper}>
          <Text style={styles.title}>Adresa{"\n"}společnosti</Text>
          <Text style={styles.text}>Bulharská 996/20{"\n"}110 00 Praha 10</Text>
        </View>
        <View style={styles.innerWrapper}>
          <Text style={styles.title}>Kancelář a{"\n"}sklady</Text>
          <Text style={styles.text}>Holečkova 106/10{"\n"}150 00, Praha 5</Text>
        </View>
        <View style={styles.innerWrapper}>
          <Text style={styles.title}>E-mail</Text>
          <Text style={styles.text}>rihak@dasar.cz</Text>
        </View>
        <View style={styles.innerWrapper}>
          <Text style={styles.title}>Faktura</Text>
          { fb.apps[0].name === "prod" ?
              (
                  <Text style={styles.text}>
                    DASAR PR s.r.o.{"\n"}
                    IČO: 088 42 035{"\n"}
                    DIČ: CZ 088 42 035
                  </Text>
              ) : (
                  <Text style={styles.text}>
                    DASAR invest s.r.o.{"\n"}
                    IČO: 173 25 846{"\n"}
                    DIČ: CZ 173 25 846
                  </Text>
              )
          }
        </View>
        <View style={styles.innerWrapper}>
          <Text style={styles.title}>Telefon</Text>
          <Text style={styles.text}>+420 702 178 647</Text>
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={styles.header}>Support</Text>
        <Text style={{ fontSize: DefaultTheme.fonts.regular, paddingHorizontal: DefaultTheme.padding.m }}>
          Máte otázky? Rádi Vám na ně odpovíme
        </Text>
        <View style={styles.innerWrapper}>
          <Text style={styles.title}>E-mail</Text>
          <Text style={styles.text}>info@cexbit.com</Text>
        </View>
      </View>

      {/* <TouchableHighlight style={styles.iconWrapper} onPress={() => navigation.navigate('ChatsOverview')}>
                <AntDesign name="message1" size={DefaultTheme.icons.l} color="white" />
            </TouchableHighlight> */}
      <View style={{ paddingVertical: 15, borderTopWidth: 1 }}>
        <Text
          style={styles.bottomText}
        >{`v${Constants.manifest.version} - ${Constants.manifest.extra.commitHash}`}</Text>

        <Text style={styles.bottomText}>{`Environment  |${Constants.manifest.extra.environment}|`}</Text>
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  innerWrapper: {
    flexDirection: "row",
    paddingHorizontal: DefaultTheme.padding.m,
    paddingVertical: DefaultTheme.padding.s / 2,
    justifyContent: "space-between",
  },
  iconWrapper: {
    backgroundColor: DefaultTheme.colors.primary,
    width: 60,
    height: 60,
    position: "absolute",
    borderRadius: 35,
    justifyContent: "center",
    alignItems: "center",
    bottom: 50,
    right: 10,
  },
  bottomText: {
    textAlign: "center",
    fontSize: DefaultTheme.fonts.regular,
    width: "100%",
  },
  bubbleStyles: {
    position: "absolute",
    bottom: 55,
    right: 5,
  },
  companyWrapper: {
    flex: 1,
    paddingVertical: DefaultTheme.padding.s,
  },
  header: {
    alignSelf: "flex-start",
    fontSize: DefaultTheme.fonts.big,
    paddingBottom: DefaultTheme.padding.s,
    fontWeight: "bold",
    paddingLeft: DefaultTheme.padding.m,
  },
  text: {
    fontSize: DefaultTheme.fonts.regular,
    flex: 1.4,
    lineHeight: 20,
  },
  title: {
    fontSize: DefaultTheme.fonts.regular,
    textTransform: "uppercase",
    flex: 1,
    lineHeight: 20,
  },
})
