import React, { useState, useEffect } from "react"
import { Text, FlatList, View } from "react-native"
import { useData } from "../../providers/DataProvider"
import Screen from "../../components/Screen"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import FlatListProjectItem from "../../components/FlatLists/FlatListProjectItem"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import { projectStateEnum } from "../../data/enums"

export default function ProjectsOverview({ navigation }) {
  const { projects, settings } = useData()
  const [syncedProjects, setSyncedProjects] = useState([])

  useEffect(() => {
    setSyncedProjects(
      settings.projects?.show_locked_projects
        ? projects.filter((proj) => proj.isSyncing)
        : projects.filter((proj) => proj.isSyncing && proj.state !== projectStateEnum.CLOSED)
    )
  }, [projects, settings])

  return (
    // <Screen barStyle="light-content">
    <Screen>
      {/* TEMPORARY REPORTING*/}
      {syncedProjects.length > 0 ? (
        <>
          <View style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
            <Text style={{ textAlign: "right", padding: 5, fontWeight: "bold" }}>Ceny jsou uvedeny v CZK bez DPH</Text>
          </View>
          <FlatList
            data={syncedProjects}
            renderItem={({ item }) => <FlatListProjectItem project={item} navigation={navigation} />}
            keyExtractor={(item) => item.id}
            ItemSeparatorComponent={FlatListSeparator}
          />
        </>
      ) : (
        <AppEmptyScreenView message="Neexistuje žádný projekt nebo vy nejste přiřazen k projektu" />
      )}
    </Screen>
  )
}
