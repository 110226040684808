import React, { useState } from "react"
import { Platform, View } from "react-native"
import WebView from "react-native-webview"
import PdfReader from "rn-pdf-reader-js"
import Constants from "expo-constants"
import * as Updates from "expo-updates"

import {
  approveEnum,
  attendanceBulkActionEnum,
  documentClosingItemsStateEnum,
  documentStateEnum,
  holidayStateEnum,
  monthStateEnum,
  projectStateEnum,
  propertyOperationTypeEnum,
  versionCheckTypeEnum,
  statusEnum,
} from "../data/enums"
import DefaultTheme from "../themes/DefaultTheme"
import { defaultConfigs } from "../firebase/config"

export function formatDecimalNumber(number) {
  if (String(number).includes(",")) {
    const result = String(number).split(",")
    number = `${result[0]}.${result[1]}`
  }
  return number
}

export function sanitizeNumber(number, precision = 2) {
  return Number(Number(String(number).replace(",", ".")).toFixed(precision))
}

export function toCurrentDateTime(date, wrap = false) {
  let myDate = new Date(date)
  if (date?.seconds) myDate = new Date(date.seconds * 1000)

  return (
    "[" +
    myDate.getDate() +
    "." +
    (myDate.getMonth() + 1) +
    "." +
    myDate.getFullYear() +
    "]" +
    (wrap ? "\n" : " ") +
    (myDate.getHours() < 10 ? "0" + myDate.getHours() : myDate.getHours()) +
    ":" +
    (myDate.getMinutes() < 10 ? "0" + myDate.getMinutes() : myDate.getMinutes()) +
    ":" +
    (myDate.getSeconds() < 10 ? "0" + myDate.getSeconds() : myDate.getSeconds())
  )
}

export function toDate(date) {
  let myDate = new Date(date)
  if (date?.seconds) myDate = new Date(date.seconds * 1000)

  return (
    "[" +
    myDate.getDate() +
    "." +
    (myDate.getMonth() + 1) +
    "." +
    myDate.getFullYear() +
    "]"
  )
}
export function toCurrentDate(date, extra1 = "[", extra2 = "]") {
  let myDate = new Date(date)
  return extra1 + myDate.getDate() + "." + (myDate.getMonth() + 1) + "." + myDate.getFullYear() + extra2
}

export function getDocumentState(document) {
  if (!document.operations) {
    return documentStateEnum.PENDING
  }

  for (let operation of document.operations) {
    if (operation.action_type === "STATE_DOC_ACCEPTED" && operation.action_value === documentStateEnum.REJECTED) {
      return documentStateEnum.REJECTED
    } else if (operation.action_type === "STATE_DOC_ACCEPTED" && operation.action_value === documentStateEnum.PENDING) {
      return documentStateEnum.PENDING
    } else if (operation.action_type === "STATE_DOC_ACCEPTED") {
      return documentStateEnum.ACCEPTED
    }
  }
  return documentStateEnum.PENDING
}

/**
 * @param {Number} number - number of the days
 * @returns {String} days label in correct declesion form
 */
export function getDayDeclensionForm(number) {
  if (1 === number) {
    return "den"
  } else if (1 < number && number < 5) {
    return "dny"
  } else {
    return "dní"
  }
}

/**
 * @param {Number} number - number of the hours
 * @returns {String} hours label in correct declesion form
 */
export function getHourDeclensionForm(number) {
  if (1 === number) {
    return "hodina"
  } else if (1 < number && number < 5) {
    return "hodiny"
  } else {
    return "hodin"
  }
}

export function getLogBookMonthClosingState(closingItems) {
  closingItems?.sort(
    (a, b) =>
      (b.closing_at.seconds ? b.closing_at.toDate() : b.closing_at) -
      (a.closing_at.seconds ? a.closing_at.toDate() : a.closing_at)
  )

  return closingItems?.[0]?.closing_state || monthStateEnum.OPEN
}

export function getCashDeskMonthClosingState(cashDesk, yearMonth) {
  const closingItems = cashDesk?.[yearMonth]?.closing_items || []
  closingItems.sort(
    (a, b) =>
      (b.closing_at.seconds ? b.closing_at.toDate() : b.closing_at) -
      (a.closing_at.seconds ? a.closing_at.toDate() : a.closing_at)
  )

  return closingItems[0]?.closing_state || monthStateEnum.OPEN
}

export function getDocumentClosingState(closing_items) {
  if (closing_items && closing_items.length > 0) {
    closing_items.sort((a, b) => b.closing_at.seconds - a.closing_at.seconds)
  }

  if (closing_items && closing_items.length > 0) {
    if (closing_items[0].closing_state === documentClosingItemsStateEnum.OPEN) return documentClosingItemsStateEnum.OPEN
  } else {
    return documentClosingItemsStateEnum.OPEN
  }
  return documentClosingItemsStateEnum.CLOSED
}

export function getDocumentApprovingInitialState(actionType, document) {
  if (
    document.operations?.length === 0 ||
    !document.operations?.find((operation) => operation.action_type === actionType)
  ) {
    return documentStateEnum.PENDING
  } else {
    return document.operations.find((operation) => operation.action_type === actionType).action_value
  }
}

export function changeDocumentOperationsActionValue(action_type, value, document, currentUser) {
  if (!document.operations) {
    document.operations = []
  }

  if (!document.operations.some((operation) => operation.action_type === action_type)) {
    document.operations.push({
      action_type: action_type,
      action_value: value,
      email: currentUser.email,
      created_at: new Date(),
    })
  }

  return {
    ...document,
    operations: document.operations.map((operation) => {
      if (operation.action_type === action_type)
        return {
          action_type: action_type,
          action_value: value,
          email: currentUser.email,
          created_at: new Date(),
        }
      return operation
    }),
  }
}

export function isPropertyBorrowed(prop) {
  const returnedState = prop.operations?.find(
    (o) => o.action_type === propertyOperationTypeEnum.STATE_RETURNED
  )?.action_value
  const borrowedState = prop.operations?.find(
    (o) => o.action_type === propertyOperationTypeEnum.STATE_BORROWED
  )?.action_value

  if (returnedState === approveEnum.ACCEPTED) {
    return false
  } else if (borrowedState !== approveEnum.ACCEPTED) {
    return false
  }

  return true
}

export function getProjectState(project) {
  if (project.state === projectStateEnum.CLOSED) {
    return projectStateEnum.CLOSED
  }
  if (project.draft_income && project.users) {
    // draft_income is enough for now, because we dont let user to save data until he has fulfiled every input
    return projectStateEnum.OPEN
  } else {
    return projectStateEnum.NEW
  }
}

export function getProjectName(projects, projectId) {
  return projects.find((proj) => proj.id === projectId)?.name || "- - -"
}

export function getUserName(users, email) {
  return users.find((user) => user.email === email)?.name || email
}

export function getUserPhone(users, email) {
  return users.find((user) => user.email === email)?.phone || "- - -"
}

export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate()
}

export function isDateInYearMonth(date, yearMonth) {
  const dateMonth = date.getMonth()
  const dateYear = date.getFullYear()

  const year = Number(yearMonth.split("_")[0])
  const month = Number(yearMonth.split("_")[1])

  return dateMonth === month && dateYear === year
}

export function isOldYearMonth(yearMonth) {
  const today = new Date()
  const year = Number(yearMonth.split("_")[0])
  const month = Number(yearMonth.split("_")[1])

  if (year < today.getFullYear()) {
    return true
  }

  if (year === today.getFullYear() && month < today.getMonth()) {
    return true
  }
  return false
}

export function getHolidayColor(freeHolidayDays) {
  if (freeHolidayDays > 15) {
    return DefaultTheme.colors.primary
  } else if (freeHolidayDays > 5) {
    return "orange"
  } else if (freeHolidayDays <= 5) {
    return "red"
  }
}

export function filterUserProjects(projs, user) {
  return projs.filter((project) => {
    if (project.users?.some((u) => u.email === user.email)) return project
  })
}

export function getHolidayState(dayAttendance) {
  if (!dayAttendance.operations) {
    return holidayStateEnum.PENDING
  } else {
    dayAttendance.operations.sort((a, b) => a.created_at - b.created_at)
    return dayAttendance.operations[0].action_value
  }
}

export function getHolidayOperationBulkState(actionValue) {
  if (actionValue === attendanceBulkActionEnum.HOLIDAY_APPROVE) {
    return holidayStateEnum.ACCEPTED
  } else if (actionValue === attendanceBulkActionEnum.HOLIDAY_REJECT) {
    return holidayStateEnum.REJECTED
  } else if (
    actionValue === attendanceBulkActionEnum.HOLIDAY_CZ ||
    actionValue === attendanceBulkActionEnum.HOLIDAY_OTHER
  ) {
    return holidayStateEnum.PENDING
  }
}

export function changeHolidayState(dayAttendance, actionType, actionValue, currentUser) {
  if (!dayAttendance.operations) {
    dayAttendance.operations = []
  }

  if (!dayAttendance.operations.some((item) => item.action_type === actionType)) {
    dayAttendance.operations.push({
      action_type: actionType,
      action_value: actionValue,
      created_at: new Date(),
      created_by: currentUser.email,
    })
  }

  return dayAttendance.operations.map((item) => {
    if (item.action_type === actionType)
      return {
        action_type: actionType,
        action_value: actionValue,
        created_at: new Date(),
        created_by: currentUser.email,
      }
    return item
  })
}

export function isDateDifferent(date1, date2) {
  return !(
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

export function getUserLastPremiumAction(requestingItems) {
  if (requestingItems.length > 0) {
    return requestingItems
      .map((item) => ({
        ...item,
        created_at: item.created_at.toDate(),
      }))
      .sort((a, b) => b.created_at - a.created_at)[0]
  }
  return null
}

export function previewPDF(uri) {
  const [numPages, setNumPages] = useState(null)

  if (Platform.OS === "android" && uri) {
    return <PdfReader source={{ uri }} />
  } else if (Platform.OS === "ios") {
    return <WebView originWhitelist={["http://", "https://", "file://", "data:"]} source={{ uri }} />
  } else if (uri) {
    const { Document, pdfjs, Page } = require("react-pdf")
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

    let isUriBase64 = true
    let uint

    try {
      // first try for base64 for uri from local storage
      uint = _base64ToArrayBuffer(uri.split(",")[1])
    } catch (error) {
      // on catch load the firestore uri
      isUriBase64 = false
    }

    return (
      <div style={{ overflowY: "auto", padding: 50 }}>
        <Document
          file={isUriBase64 ? { data: uint } : { url: uri }}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages)
          }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <View key={`page_${index + 1}`}>
              <Page pageNumber={index + 1} width={window.innerWidth - 100} />
              <div style={{ width: window.innerWidth - 100, height: 1, backgroundColor: DefaultTheme.colors.light }} />
            </View>
          ))}
        </Document>
      </div>
    )
  }

  return <View />
}

function _base64ToArrayBuffer(base64) {
  const binary_string = window.atob(base64)
  const len = binary_string.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes
}

/**
 * @param {Object} newVersionData - changelogs, versions
 * @param {Function} setShowAlert - set show version update alert
 * @param {Function<Object>} setAlertData - set alert data {hasNewVersion, newVersionNumber, newChangelog}
 * @param {boolean} checkType - in order to know if version check is on user demand or snapshot
 */
export async function checkAppVersion(newVersionData, setShowAlert, setAlertData, checkType, setAlertProgress) {
  const newVersionNumber = newVersionData?.sort((a, b) => b.created_at - a.created_at)?.[0]?.version

  if (newVersionNumber) {
    if (Platform.OS === "web") {
      if (Number(Constants.manifest.version) !== newVersionNumber) {
        console.debug("NEW VERSION DETECTED", newVersionNumber)
        setAlertData({ hasNewVersion: true, newVersionNumber, newVersionData })
        setShowAlert(true)
      } else {
        setAlertData({ hasNewVersion: false, newVersionNumber })
        setShowAlert(checkType === versionCheckTypeEnum.MANUAL)
      }
    } else {
      if (Number(Constants.manifest.version) !== newVersionNumber) {
        setShowAlert(checkType === versionCheckTypeEnum.MANUAL)
        setAlertData({})
        setAlertProgress(true)
        const result = await Updates.checkForUpdateAsync()
        setAlertProgress(false)
        if (result.isAvailable) {
          console.debug("NEW VERSION DETECTED", newVersionNumber)
          setAlertData({ hasNewVersion: true, newVersionNumber, newVersionData })
          setShowAlert(true)
        } else {
          setAlertData({ hasNewVersion: false, newVersionNumber })
        }
      }
    }
  }
}

/**
 * @param {Date} createdAtDate - date of creation
 * @returns {Number} lifecycle duration in seconds
 */
export function getDuration(createdAtDate) {
  const nowTime = new Date().getTime() / 1000
  // console.debug("DURATION seconds", nowTime - createdAtDate.getTime() / 1000)
  return nowTime - createdAtDate.getTime() / 1000
}

/**
 * @param {Object} doc - Trivi accounting document
 * @returns {Boolean} check doc type => true if type = 0
 */
export function isDocFinal(doc) {
  return doc.type === 0
}

/**
 * @param {String} uri - firebase storage download uri
 * @returns {String} parsed name from uri
 */
export function getNameFromFirebaseUri(uri) {
  // TODO create unit test for firebase storage uri
  return uri.split(RegExp(/\%2F(.*?)\?alt/))[1]
}

/**
 * @param {String} value - convert camelCase into db syntax - camel_case
 * @returns {String} converted String
 */
export function convertToDbSyntax(value) {
  return value.replace(/([A-Z])/g, "_$1")
}

/**
 * @param {Object} object - this object will be processed
 * @param {String} args - get rid of passed arguments
 * @returns {Object} object without empty fields (shallow processing), without props passed as argument
 */
export function removeEmptyFields(object, ...args) {
  args.forEach((arg) => delete object[arg])

  Object.keys(object).forEach((key) => {
    if (!object[key] || object[key].length === 0) {
      delete object[key]
    }
  })

  return object
}

/**
 * @param {Date} date
 * @returns {String} date in String format "yyyy-mm-dd"
 */
export function getDateSimpleString(date) {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(
    2,
    "0"
  )}`
}

export function getAttendanceMonthClosingState(closingItems) {
  closingItems.sort(
    (a, b) =>
      (b.closing_at.seconds ? b.closing_at.toDate() : b.closing_at) -
      (a.closing_at.seconds ? a.closing_at.toDate() : a.closing_at)
  )

  return closingItems[0]?.closing_state || monthStateEnum.OPEN
}

/**
 * filters out users that have been deleted but are still used in other documents
 * @param {[]} arr1 - users in documents, props,example: project.users
 * @param {[]} arr2 - users from DataProvider - useData()
 * @returns {[]} array of users that do exist in users collection
 */
export const getExistingUsers = (arr1, arr2) => {
  if (!arr1 || !arr2) return []
  return arr1.filter((u) => arr2.find((userObject) => u.email === userObject.email))
}

/**
 *
 */
export const isUserAllowed = (userRoles, allowedRoles) => {
  if (!userRoles || !allowedRoles) return false
  userRoles.forEach((role) => {
    if (allowedRoles.find((allowedRole) => allowedRole === role)) return true
  })
  return false
}

export const handleConfigSwitch = (setStatus, firebaseConfig, setFirebaseConfig, navigation, env) => {
  setStatus(statusEnum.RELOG)
  const defaultConfig = defaultConfigs[env]
  setFirebaseConfig(firebaseConfig === defaultConfig.primary ? defaultConfig.secondary : defaultConfig.primary)
  navigation.reset({
    index: 0,
    routes: [{ name: "StatusScreen" }],
  })
}

export const getRoutes = (navigation) => {
  const routeList = navigation.getState().routes || []
  let routes = ""
  routeList.forEach((route, index) => {
    if (index < routeList.length - 1) {
      routes += `${route.name} ${index < routeList.length - 2 ? "=>" : ""} `
    }
  })
  return routes
}
