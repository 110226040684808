import React, { useEffect, useState } from "react"
import { FlatList, Image, View, Text } from "react-native"
import FlatListSeparator from "../../components/FlatLists/FlatListSeparator"
import { firebase } from "../../firebase/config"
import Screen from "../../components/Screen"
import FlatListLogBookItem from "../../components/FlatLists/FlatListLogBookItem"
import { FAB } from "react-native-paper"
import DefaultTheme from "../../themes/DefaultTheme"
import { monthStateEnum, statusEnum } from "../../data/enums"
import { getLogBookMonthClosingState } from "../../utils/general"
import AppEmptyScreenView from "../../components/AppEmptyScreenView"
import { useStatus } from "../../providers/StatusProvider"

export default function LogBookMonthDetail({ route, navigation }) {
  const { setTitle, setStatus, setMessage } = useStatus()
  const [monthLogBook, setMonthLogBook] = useState(route.params?.monthLogBook || {})
  const disabled = getLogBookMonthClosingState(monthLogBook.closing_items) === monthStateEnum.CLOSED

  useEffect(() => {
    let subscribed = true
    if (subscribed) {
      try {
        ;(async () => {
          await onSnapshotLogBookMonthDetail()
        })()
      } catch (error) {
        console.error(error)
      }
    }

    return () => {
      subscribed = false
    }
  }, [])

  async function onSnapshotLogBookMonthDetail() {
    return await new Promise((resolve, reject) => {
      try {
        firebase.apps[0]
          .firestore()
          .collection("logBook")
          .doc(route.params.monthLogBook.user)
          .collection("logsMonthly")
          .doc(route.params.monthLogBook.id)
          .onSnapshot((doc) => {
            if (doc.data()) {
              const obj = doc.data()
              obj.id = doc.id
              obj.logs = doc
                .data()
                .logs.map((l) => ({ ...l, date: l.date.toDate(), created_at: l.created_at.toDate() }))
                .sort((a, b) => a.created_at - b.created_at)
              setMonthLogBook(obj)
            }
          })
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  function handleAddLog() {
    if (disabled) {
      setTitle("Nová jízda")
      setStatus(statusEnum.ERROR)
      setMessage("Na přidání nové jízdy musíte otevřít měsíc")
      navigation.navigate("StatusScreen")
    } else {
      navigation.navigate("LogDetail", {
        monthLogBook,
        disabled,
      })
    }
  }

  return (
    <Screen>
      {monthLogBook.logs.length > 0 ? (
        <FlatList
          data={monthLogBook.logs}
          renderItem={({ item }) => (
            <FlatListLogBookItem navigation={navigation} log={item} monthLogBook={monthLogBook} disabled={disabled} />
          )}
          keyExtractor={(item) => item.id.toString()}
          ItemSeparatorComponent={FlatListSeparator}
        />
      ) : (
        <AppEmptyScreenView message="V tomto měsíci zatím nejsou žádné jízdy" />
      )}
      <FAB
        visible={true}
        style={DefaultTheme.fab}
        color={DefaultTheme.colors.fabAdd}
        icon="plus"
        onPress={handleAddLog}
      />
    </Screen>
  )
}
